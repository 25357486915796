import {ConversationsFilterDto} from '../interfaces/dto/conversations-filter.dto';

export function buildUrlParamsFromConversationFilters(filters: ConversationsFilterDto): string {
  let str = '';

  if (filters.owner && filters.owner.length > 0) {
    str += `&owner=${JSON.stringify(filters.owner)}`;
  }
  if (filters.assignee && filters.assignee.length > 0) {
    str += `&assignee=${JSON.stringify(filters.assignee)}`;
  }
  if (filters.client) {
    str += `&client=${JSON.stringify(filters.client)}`;
  }
  if (filters.status) {
    str += `&status=${JSON.stringify(filters.status)}`;
  }
  if (filters.sortBy) {
    str += `&sortBy=${JSON.stringify(filters.sortBy)}`;
  }
  if (filters.sortDir) {
    str += `&sortDir=${JSON.stringify(filters.sortDir)}`;
  }
  if (filters.page) {
    str += `&page=${JSON.stringify(filters.page)}`;
  }
  if (filters.date) {
    if (typeof filters.date === 'string') {
      str += `&date=${JSON.stringify(filters.date)}`;
    } else {
      str += `&start=${JSON.stringify(filters.date.start)}&end=${JSON.stringify(filters.date.end)}`;
    }
  }

  return str;
}
