import {Injectable} from '@angular/core';
import {MessageAttachmentDto} from '../interfaces/dto/message-attachment.dto';
import {MessageAttachment} from '../models/MessageAttachment';

@Injectable({
  providedIn: 'root'
})
export class MessageAttachmentAdapter {
  constructor() {
  }

  run(dto: MessageAttachmentDto): MessageAttachment {
    return {
      ...dto,
      createdAt: new Date(dto.createdAt),
      updatedAt: new Date(dto.updatedAt),
    };
  }
}
