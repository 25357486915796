<div class="placeholders-dialog">
  <div class="edit-campaign-form-wrap">
    <div
        [ngClass]="device === 'desktop' ? 'edit-campaign-form' : device === 'mobile' && previewOpen == false ? 'mobile-edit-campaign-form' : 'mobile-edit-campaign-form-none'"
        [ngStyle]="{
          'width': (device === 'desktop' && template === null) ? '100%' : '',
          'padding-right': (device === 'desktop' && template === null) ? '0px' : '',
          'border-right': (device === 'desktop' && template === null) ? 'none' : ''
        }">
    <ng-container *ngIf="template">
      <div class="placeholders-dialog-prompt-title"><span>{{'ui.replace_placeholder.chosen_template' | translate}} </span>{{template.name}}
        <ng-container *ngIf="template.attachMedia">
          ({{template.mediaType}})
        </ng-container>
      </div>
    </ng-container>
    <div class="placeholders-dialog-prompt">{{'ui.replace_placeholder.placeholder_warning' | translate}}</div>
    <ng-container *ngIf="error">
      <div class="error">
        {{'ui.replace_placeholder.enter_values' | translate}}
      </div>
    </ng-container>
    <form [formGroup]="placeholdersForm">
      <ng-container *ngFor="let manualPlaceholder of manualPlaceholders">
        <div class="input-row placeholders-input-row">
          <label>{{manualPlaceholder.label | prettifyTemplateContent | translate}}</label>
          <input type="text" pInputText [formControlName]="manualPlaceholder.id">
        </div>
      </ng-container>
      <ng-container *ngIf="template && template.type === templateType.Standard && template.button">
        <form [formGroup]="buttonLinkForm">
          <div class="input-row placeholders-input-row">
            <label>{{'ui.replace_placeholder.button_link' | translate}}</label>
            <input type="text" pInputText formControlName="buttonLink" (change)="onButtonLinkChange($event)">
          </div>
        </form>
      </ng-container>
      <ng-container *ngIf="template && template.attachMedia">
        <div class="media-input-row placeholders-input-row">
          <label>{{template.mediaType ?  (template.mediaType | mediaTypeTranslationKey | translate) : ''}}:</label>
          <div class="media-row">
            <span class="p-button-label">
              <ng-container *ngIf="selectedMedia">
                <i class="pi pi-times" (click)="removeMedia()"></i>
              </ng-container>
              <span class="text">
                  {{ !mediaName ? ('ui.replace_placeholder.no_media' | translate) : mediaName }}
              </span>
            </span>
            <p-button class="media-button" [label]="'ui.replace_placeholder.select_media' | translate" (onClick)="selectMedia()" [styleClass]="buttonClass" iconPos="left" [icon]="
            template.mediaType === mediaType.Image
              ? 'pi pi-image'
              : template.mediaType === mediaType.Video
              ? 'pi pi-video'
              : 'pi pi-file-pdf'
          "></p-button>
          </div>
        </div>
      </ng-container>
    </form>
  </div>
  <ng-container *ngIf="template">
    <div
    [ngClass]="device === 'desktop' ? 'edit-campaign-preview' : device === 'mobile' && previewOpen == true ? 'mobile-edit-campaign-preview' : 'mobile-edit-campaign-preview-none'">
    <conversation-preview [selectedMedia]='selectedMedia' [previewType]='previewType'></conversation-preview>
    </div>
  </ng-container>
  </div>
    <div class="bottom-row">
      <div class="left"></div>
      <div class="center">
        <a [href]="helpLink + '/category/faq/post/how-do-i-add-client-message-templates'" target="_blank"><img [src]="'questionMarkIcon' | themeIconUrl: theme" height="20" class="help-icon" alt="" >{{'ui.replace_placeholder.need_help' | translate}}</a>
      </div>
      <div class="right">
        <button pButton [label]="'ui.replace_placeholder.next' | translate" (click)="submitManualPlaceholders()"></button>
      </div>
    </div>
  </div>
