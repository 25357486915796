import { Practice } from '../models/Practice';
import {Template} from '../models/Template';
import {Payment} from '../models/Payment';
import {MergeFieldContent} from '../enums/merge-field-content';
import {format} from 'date-fns';

export function generateTemplateBody(
  template: Template,
  practice?: Practice,
  paymentServiceId?: string,
  paymentAmount?: string,
  paymentAuthOnly?: boolean,
  paymentMessage?: string,
  paymentDescription?: string,
  paymentExpiresAt?: Date,
  paymentSentAt?: Date,
  paymentLink?: string,
  currencySymbol?: string,
): string {
  let body = template.body;

  template.mergeFields.forEach((mergeField) => {
    switch (mergeField.content) {
      case MergeFieldContent.PracticeName:
        if (practice) {
          body = body.replace(mergeField.placeholder, practice.name);
        }
        break;

      case MergeFieldContent.PaymentId:
        if (paymentServiceId) {
          body = body.replace(
            mergeField.placeholder,
            paymentServiceId.split('-')[0],
          );
        }
        break;

      case MergeFieldContent.PaymentAmount:
        if (paymentAmount) {
          body = body.replace(
            mergeField.placeholder,
            (currencySymbol || '£') + paymentAmount,
          );
        }
        break;

      case MergeFieldContent.PaymentType:
        if (paymentAuthOnly) {
          body = body.replace(mergeField.placeholder, 'pre-authorisation');
        } else {
          body = body.replace(mergeField.placeholder, 'payment');
        }
        break;

      case MergeFieldContent.PaymentMessage:
        body = body.replace(
          mergeField.placeholder,
          paymentMessage ? paymentMessage : (paymentDescription ?? ''),
        );
        break;

      case MergeFieldContent.PaymentLink:
        if (paymentLink) {
          body = body.replace(mergeField.placeholder, paymentLink);
        }
        break;

      case MergeFieldContent.PaymentExpiry:
        if (paymentExpiresAt) {
          body = body.replace(
            mergeField.placeholder,
            format(
              paymentExpiresAt,
              'dd/MM/yyyy @ HH:mm',
            ),
          );
        }
        break;

      case MergeFieldContent.PaymentSentAt:
        if (paymentSentAt) {
          body = body.replace(
            mergeField.placeholder,
            format(
              paymentSentAt,
              'dd/MM/yyyy',
            ),
          );
        }
        break;
    }
  });

  return body;
}
