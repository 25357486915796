<div class="form-builder" [formGroup]="form">
  <ng-container *ngIf="loading">
    <div class="loading">
      <p-progressSpinner [style]="{width: '32px', height: '32px'}" styleClass="dark-spinner"
        strokeWidth="6"></p-progressSpinner>
    </div>
  </ng-container>
  <ng-container *ngIf="!loading">
    <div class="constrainer">
      <div class="input-block">
        <div class="input-row">
          <div class="label">Form name:</div>
          <input pInputText type="text" formControlName="name" maxlength="60">
          <ng-container
            *ngIf="form.controls.name.invalid && (form.controls.name.dirty || form.controls.name.touched) && form.controls.name.errors?.['required']">
            <div class="error">You must enter a name for this form.</div>
          </ng-container>
        </div>
        <div class="input-row">
          <div class="label">Form intro:</div>
          <input pInputText type="text" formControlName="intro" maxlength="200">
          <ng-container
            *ngIf="form.controls.intro.invalid && (form.controls.intro.dirty || form.controls.intro.touched) && form.controls.intro.errors?.['required']">
            <div class="error">You must enter an intro for this form.</div>
          </ng-container>
        </div>
        <div class="input-row">
          <div class="label">Estimated duration: <div class="tooltip"><i class="pi pi-info-circle"
                tooltipPosition="right"
                [pTooltip]="'An estimate for how long this form takes to complete, e.g. 10 minutes'"></i></div>
          </div>
          <input pInputText type="text" maxlength="30" formControlName="estimatedCompletionDuration">
          <ng-container
            *ngIf="form.controls.estimatedCompletionDuration.invalid && (form.controls.estimatedCompletionDuration.dirty || form.controls.estimatedCompletionDuration.touched) && form.controls.estimatedCompletionDuration.errors?.['required']">
            <div class="error">You must enter a duration for this form.</div>
          </ng-container>
        </div>
      </div>
      <div class="pages" formArrayName="pages">
        <ng-container *ngFor="let page of pages().controls; let pageIndex=index" [formGroupName]="pageIndex">
          <div class="page">
            <div class="page-heading">
              Page #{{ pageIndex+1 }}
              <ng-container *ngIf="pages().length > 1">
                <i class="pi pi-minus-circle page-remover" (click)="removePage(pageIndex)"></i>
              </ng-container>
              <input type="hidden" formControlName="id">
            </div>

            <div class="input-row halfs">
              <div class="input">
                <div class="label">Heading:</div>
                <input pInputText type="text" formControlName="heading" maxlength="60">
                <ng-container
                  *ngIf="page.get('heading')?.invalid && (page.get('heading')?.dirty || page.get('heading')?.touched) && page.get('heading')?.errors?.['required']">
                  <div class="error">You must enter a heading for this page.</div>
                </ng-container>
              </div>
              <div class="input">
                <div class="label">Intro:</div>
                <input pInputText type="text" formControlName="intro" maxlength="200">
                <ng-container
                  *ngIf="page.get('intro')?.invalid && (page.get('intro')?.dirty || page.get('intro')?.touched) && page.get('intro')?.errors?.['required']">
                  <div class="error">You must enter an intro for this page.</div>
                </ng-container>
              </div>
            </div>

            <div class="page-fields" formArrayName="fields">
              <ng-container *ngFor="let field of fields(pageIndex).controls; let fieldIndex=index"
                [formGroupName]="fieldIndex">
                <div class="field">
                  <div class="field-heading">
                    Field #{{ fieldIndex+1 }}
                    <ng-container *ngIf="fields(pageIndex).length > 1">
                      <i class="pi pi-minus-circle field-remover" (click)="removeField(pageIndex, fieldIndex)"></i>
                    </ng-container>
                    <input type="hidden" formControlName="id">
                  </div>
                  <div class="input-row halfs">
                    <div class="input">
                      <div class="label">Type:</div>
                      <select class="primary" formControlName="type" (change)="typeChanged(pageIndex, fieldIndex)">
                        <ng-container *ngFor="let inputType of inputTypes">
                          <option [value]="inputType">{{ inputType | prettifyFormInputType }}</option>
                        </ng-container>
                      </select>
                    </div>
                    <div class="input">
                      <div class="label">Label:</div>
                      <input pInputText type="text" formControlName="label" maxlength="100">
                      <ng-container
                        *ngIf="field.get('label')?.invalid && (field.get('label')?.dirty || field.get('label')?.touched) && field.get('label')?.errors?.['required']">
                        <div class="error">You must enter a label for this field.</div>
                      </ng-container>
                    </div>
                  </div>
                  <ng-container *ngIf="field.get('type')?.value === formFieldType.AGREE_TO_TERMS">
                    <div class="input-row">
                      <div class="input">
                        <div class="label">Terms and Conditions Link:</div>
                        <input pInputText type="text" formControlName="url" maxlength="100" placeholder="https://www.example.com/terms">
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="field.get('type')?.value !== formFieldType.HIDDEN">
                    <div class="input-row">
                      <div class="input">
                        <div class="label">Description:</div>
                        <div *ngIf="field.get('type')?.value === formFieldType.AGREE_TO_TERMS  else description">
                          <quill-editor class="quill-editor-box-class" formControlName="description" 
                          [disabled]="true" [modules]="quillModules"
                            placeholder="Description"></quill-editor>
                        </div>
                        <ng-template #description>
                          <input pInputText type="text" formControlName="description" maxlength="100">   
                        </ng-template>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container
                    *ngIf="field.get('type')?.value !== formFieldType.DATE && field.get('type')?.value !== formFieldType.HIDDEN">
                    <div class="input-row halfs">
                      <ng-container *ngIf="field.get('type')?.value | fieldTypeHasPlaceholder">
                        <div class="input">
                          <div class="label">Placeholder:</div>
                          <input pInputText type="text" formControlName="placeholder" maxlength="80">
                        </div>
                      </ng-container>
                      <ng-container *ngIf="field.get('type')?.value | fieldTypeHasOptions">
                        <div class="input" formArrayName="options">
                          <div class="label">Options:</div>
                          <ng-container
                            *ngFor="let option of options(pageIndex, fieldIndex).controls; let optionIndex=index"
                            [formGroupName]="optionIndex">
                            <input type="hidden" formControlName="id">
                            <div class="option-row">
                              <div class="option-input">
                                <div class="label">Label:</div>
                                <input pInputText type="text" formControlName="label" maxlength="100">
                                <ng-container
                                  *ngIf="option.get('label')?.invalid && (option.get('label')?.dirty || option.get('label')?.touched) && option.get('label')?.errors?.['required']">
                                  <div class="error">You must enter a label for this option.</div>
                                </ng-container>
                              </div>
                              <div class="option-input">
                                <div class="label">Value:</div>
                                <input pInputText type="text" formControlName="value" maxlength="100">
                                <ng-container
                                  *ngIf="option.get('value')?.invalid && (option.get('value')?.dirty || option.get('value')?.touched) && option.get('value')?.errors?.['required']">
                                  <div class="error">You must enter a value for this option.</div>
                                </ng-container>
                              </div>
                              <div class="option-remover">
                                <ng-container *ngIf="options(pageIndex, fieldIndex).length > 1">
                                  <i class="pi pi-minus-circle option-remover"
                                    (click)="removeOption(pageIndex, fieldIndex, optionIndex)"></i>
                                </ng-container>
                              </div>
                            </div>
                          </ng-container>
                          <div class="btn-row">
                            <p-button styleClass="p-button-secondary p-button-fullwidth p-button-smalltext"
                              (click)="addOption(pageIndex, fieldIndex)" icon="pi pi-plus"
                              label="Add Option"></p-button>
                          </div>
                        </div>
                      </ng-container>
                      <div class="input">
                        <ng-container
                          *ngIf="field.get('type')?.value === formFieldType.CHECKBOX || field.get('type')?.value === formFieldType.AGREE_TO_TERMS">
                          <div class="checkbox">
                            <p-checkbox formControlName="default" [binary]="true"></p-checkbox>
                            <div class="label">Checked by default</div>
                          </div>
                        </ng-container>
                        <ng-container
                          *ngIf="field.get('type')?.value !== formFieldType.CHECKBOX && field.get('type')?.value !== formFieldType.AGREE_TO_TERMS">
                          <ng-container *ngIf="!(field.get('type')?.value | fieldTypeHasOptions)">
                            <div class="label">Default:</div>
                            <input pInputText type="text" formControlName="default" maxlength="100">
                          </ng-container>
                          <ng-container *ngIf="field.get('type')?.value | fieldTypeHasDefaultOption">
                            <div class="label">Default:</div>
                            <select class="primary" formControlName="default">
                              <option value="-1"></option>
                              <ng-container
                                *ngFor="let option of options(pageIndex, fieldIndex).controls; let optionIndex=index">
                                <option value="{{ optionIndex }}">{{ option.get('label')?.value }}</option>
                              </ng-container>
                            </select>
                            <ng-container
                              *ngIf="field.get('default')?.invalid && (field.get('default')?.dirty || field.get('default')?.touched) && field.get('default')?.errors?.['required']">
                              <div class="error">You must select a default option.</div>
                            </ng-container>
                          </ng-container>
                        </ng-container>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="field.get('type')?.value | fieldCanBePrefilled">
                    <div class="input-row halfs">
                      <ng-container *ngIf="field.get('type')?.value !== formFieldType.HIDDEN">
                        <div class="input">
                          <div class="checkbox">
                            <p-checkbox formControlName="prefill" [binary]="true"></p-checkbox>
                            <div class="label">Prefill this field</div>
                          </div>
                          <ng-container *ngIf="field.get('prefill')?.value === true">
                            <div class="checkbox space-above">
                              <p-checkbox formControlName="readonly" [binary]="true"></p-checkbox>
                              <div class="label">Make this field read-only</div>
                            </div>
                          </ng-container>
                        </div>
                      </ng-container>
                      <ng-container
                        *ngIf="field.get('prefill')?.value === true || field.get('type')?.value === formFieldType.HIDDEN">
                        <div class="input">
                          <div class="label">Prefill with:</div>
                          <select class="primary" formControlName="prefillWith">
                            <ng-container *ngFor="let prefillOption of prefillOptions">
                              <option value="{{ prefillOption }}">{{ prefillOption | prettifyFormFieldPrefillOption }}
                              </option>
                            </ng-container>
                          </select>
                        </div>
                      </ng-container>
                    </div>
                  </ng-container>
                  <div class="input-row">
                    <div class="input">
                      <div class="checkbox">
                        <p-checkbox formControlName="required" [binary]="true"></p-checkbox>
                        <div class="label">This field is required</div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>

            <div class="page-fields" formArrayName="contents">
              <ng-container *ngFor="let content of contents(pageIndex).controls; let contentIndex=index"
                [formGroupName]="contentIndex">
                <div class="field">
                  <div class="field-heading">
                    Content #{{ contentIndex+1 }}
                    <ng-container *ngIf="contents(pageIndex).length > 1">
                      <i class="pi pi-minus-circle field-remover" (click)="removeContent(pageIndex, contentIndex)"></i>
                    </ng-container>
                    <input type="hidden" formControlName="id">
                  </div>

                  <div class="input-row">
                    <div class="input">
                      <div class="label">Content:</div>
                      <quill-editor class="quill-editor-box-class" formControlName="content" 
                      [disabled]="true" [modules]="quillModules"
                      [placeholder]="''"
                        ></quill-editor>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="btn-row">
              <span class="btn-content">
                <span>
                  <p-button styleClass="p-button-secondary p-button-fullwidth p-button-smalltext" icon="pi pi-plus"
                    label="Add Content" (click)="addContent(pageIndex, true)"></p-button>
                </span>
              </span>
              <span class="btn-field">
                <span>
                  <p-button styleClass="p-button-secondary p-button-fullwidth p-button-smalltext" icon="pi pi-plus"
                    label="Add Field" (click)="addField(pageIndex, true)"></p-button>
                </span>
              </span>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="btn-row add-page">
        <p-button styleClass="p-button-secondary p-button-fullwidth p-button-smalltext" (click)="addPage(true)"
          icon="pi pi-plus" label="Add Page"></p-button>
      </div>
      <div class="payment-section">
        <div class="input-row">
          <div class="input checkbox">
            <p-checkbox formControlName="takePayment" [binary]="true" (onChange)="takePaymentToggled()"></p-checkbox>
            <div class="label">Take a payment when this form is completed</div>
          </div>
        </div>
        <ng-container *ngIf="form.controls.takePayment.value === true">
          <div class="input-row halfs">
            <div class="input">
              <div class="label">Payment amount:</div>
              <input pInputText type="text" formControlName="paymentAmount">
              <ng-container
                *ngIf="form.controls.paymentAmount.invalid && (form.controls.paymentAmount.dirty || form.controls.paymentAmount.touched)">
                <ng-container *ngFor="let err of ((form.controls.paymentAmount.errors || {}) | keyvalue)">
                  <div class="error">{{err.value.message}}</div>
                </ng-container>
              </ng-container>
            </div>
            <div class="input">
              <div class="label">Payment description:</div>
              <input pInputText type="text" formControlName="paymentDescription" maxlength="100">
              <ng-container
                *ngIf="form.controls.paymentDescription.invalid && (form.controls.paymentDescription.dirty || form.controls.paymentDescription.touched) && form.controls.paymentDescription.errors?.['required']">
                <div class="error">You must enter a payment description.</div>
              </ng-container>
            </div>
          </div>
          <div class="input-row halfs">
            <div class="input">
              <div class="label">Expires after:</div>
              <select class="primary" formControlName="paymentExpiresAfter">
                <ng-container *ngFor="let expiryOption of expiryOptions">
                  <option value="{{ expiryOption.amount }}">{{ expiryOption.label }}</option>
                </ng-container>
              </select>
            </div>
            <div class="input checkbox">
              <p-checkbox formControlName="paymentPreAuth" [binary]="true"></p-checkbox>
              <div class="label">Pre-authorise client's card</div>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="btn-row">
        <p-button styleClass="p-button-secondary p-button-fullwidth" (click)="submit()" label="Save Form"></p-button>
      </div>
    </div>
  </ng-container>
</div>