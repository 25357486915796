import {Component, OnDestroy, OnInit} from '@angular/core';
import {Client} from '../../../models/Client';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../state/reducers';
import {CloseNewClientDeposit, CreateNewClientDeposit} from '../../../clients/state/actions';
import {getNewClientDepositAlreadyExists} from '../../../clients/state/selectors';
import {isConversationPreviewEnabled} from '../../../state/selectors';
import {takeWhile} from 'rxjs/operators';
import {Router} from "@angular/router";

@Component({
  selector: 'new-client-deposit',
  templateUrl: './new-client-deposit.component.html',
  styleUrls: ['./new-client-deposit.component.scss']
})
export class NewClientDepositComponent implements OnInit, OnDestroy {
  alive = true;
  loading = false;
  step = 0;
  selectedClient: Client | null = null;
  confirmed = false;
  errorKey: string | null = null;
  depositAlreadyExistsForClient: Client | null = null;

  constructor(private store: Store<AppState>, private router: Router) {
  }

  ngOnInit(): void {
    this.subscribeToNewClientDepositAlreadyExists();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  subscribeToNewClientDepositAlreadyExists(): void {
    this.store.pipe(select(getNewClientDepositAlreadyExists))
      .pipe(takeWhile(() => this.alive))
      .subscribe((client) => this.depositAlreadyExistsForClient = client);
  }

  selectClient(client: Client): void {
    this.selectedClient = client;
    this.step++;
  }

  submit(): void {
    this.errorKey = null;

    if (!this.confirmed) {
      this.errorKey = 'ui.new_client_deposit.must_confirm_error';
      return;
    }

    if (!this.selectedClient) {
      this.errorKey = 'ui.new_client_deposit.must_select_error';
      return;
    }

    this.loading = true;

    this.store.dispatch(CreateNewClientDeposit({client: this.selectedClient}));
  }

  viewClient(): void {
    if (this.depositAlreadyExistsForClient) {
      this.store.dispatch(CloseNewClientDeposit());
      this.router.navigate([`clients/${this.depositAlreadyExistsForClient.id}`]);
    }
  }
}
