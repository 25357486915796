import { createAction, props } from '@ngrx/store';
import {Conversation} from '../../models/Conversation';
import {Payment} from '../../models/Payment';
import {ConversationsFilterDto} from '../../interfaces/dto/conversations-filter.dto';
import {PaymentFilterDto} from '../../interfaces/dto/payment-filter.dto';
import {SearchType} from '../../enums/search-type';

export const DoConversationSearch = createAction(
    '[Search] Do Conversation Search',
    props<{searchString: string, filters: ConversationsFilterDto}>(),
);

export const DoPaymentsSearch = createAction(
  '[Search] Do Payments Search',
  props<{searchString: string, filters: PaymentFilterDto}>(),
);

export const DoConversationsSearchSuccess = createAction(
  '[Search] Do Search Conversations - Success',
  props<{result: { conversations: Conversation[], total: number }}>(),
);

export const DoPaymentSearchSuccess = createAction(
  '[Search] Do Search Payments - Success',
  props<{result: { payments: Payment[], total: number }}>(),
);

export const UpdateConversationSearchFilters = createAction(
  '[Search] Change conversation search filters',
  props<{searchString: string, filters: ConversationsFilterDto}>(),
);

export const UpdatePaymentSearchFilters = createAction(
  '[Search] Change payment search filters',
  props<{searchString: string, filters: PaymentFilterDto}>(),
);
