import {Component, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {getCurrentPractice, getPracticeStaff} from '../../../practices/state/selectors';
import {takeWhile} from 'rxjs/operators';
import {first, Observable, Subscription} from 'rxjs';
import {User} from '../../../models/User';
import {AppState} from '../../../state/reducers';
import {subDays} from 'date-fns';
import {Practice} from '../../../models/Practice';
import {MarkConversationsAsUpdated, SearchConversations, UpdateConversationsFilters} from '../../state/actions';
import {
  getIsConversationPdfDownloadInProgress,
  getIsConversationSearchLoading,
  getSearchedConversations,
  getTotalSearchedConversationsCount
} from '../../state/selectors';
import {Conversation} from '../../../models/Conversation';
import {ConversationSortBy} from '../../../enums/ConversationSortBy';
import {SortDirection} from '../../../enums/sort-direction';
import {defaultConversationFilters} from '../../../constants/default-conversation-filters.constants';
import {ConversationsFilterDto} from '../../../interfaces/dto/conversations-filter.dto';
import {ActivatedRoute} from "@angular/router";
import {EnvironmentService} from "../../../services/environment.service";
import {Title} from "@angular/platform-browser";
import {Role} from "../../../enums/role";

@Component({
  selector: 'app-conversation-list',
  templateUrl: './conversation-list.component.html',
  styleUrls: ['./conversation-list.component.scss']
})
export class ConversationListPage implements OnInit, OnDestroy {
  alive = true;
  allUsers$?: Observable<User[]>;
  allUsers: User[] = [];
  practice$?: Observable<Practice | null>;
  practiceSub$?: Subscription;
  practice?: Practice | null;
  conversations$?: Observable<Conversation[]>;
  conversations?: Conversation[];
  conversationCount$?: Observable<number>;
  loading$?: Observable<boolean>;
  loading = true;
  pdfDownloading = false;
  statusFilter = defaultConversationFilters.status;
  dateFilter: {start: string, end: string} | string | null = defaultConversationFilters.date;
  ownersFilter: number[] = defaultConversationFilters.owner;
  assigneesFilter: number[] = defaultConversationFilters.assignee;
  sortBy: ConversationSortBy = defaultConversationFilters.sortBy;
  sortDir: SortDirection = defaultConversationFilters.sortDir;
  page = 1;
  totalConversationsCount = 0;
  conversationsPerPage = this.environmentService.get('conversationsPerPage');
  selectedConversations: Conversation[] = [];

  constructor(private store: Store<AppState>, private environmentService: EnvironmentService, private titleService: Title) {
    const title = 'Digital Practice | All Conversations';
    this.titleService.setTitle(title);
  }

  ngOnInit(): void {
    this.subscribeToCurrentPractice();
    this.subscribeToSearchedConversations();
    this.subscribeToConversationCount();
    this.subscribeToSearchLoading();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  subscribeToCurrentPractice(): void {
    this.practice$ = this.store.pipe(select(getCurrentPractice)).pipe(
      takeWhile(() => this.alive)
    );

    this.practiceSub$ = this.practice$.subscribe(practice => {
      this.practice = practice;
    });
  }

  subscribeToSearchedConversations(): void {
    this.conversations$ = this.store.pipe(select(getSearchedConversations)).pipe(takeWhile(() => this.alive));
    this.conversations$.subscribe(conversations => {
      if (this.practice && this.practice.userRole === Role.ANALYST) {
        this.conversations = conversations.filter((conversation) => !!conversation.resolvedAt);
      } else {
        this.conversations = conversations;
      }
    });
  }

  subscribeToSearchLoading(): void {
    this.loading$ = this.store.pipe(select(getIsConversationSearchLoading)).pipe(takeWhile(() => this.alive));
    this.loading$.subscribe(isLoading => {
      setTimeout(() => {
        this.loading = isLoading;
      }, 0);
    });
  }

  subscribeToConversationCount(): void {
    this.conversationCount$ = this.store.select(getTotalSearchedConversationsCount).pipe(
      takeWhile(() => this.alive)
    );

    this.conversationCount$.subscribe((count) => {
      this.totalConversationsCount = count;
    });
  }

  handleFetchConversations(filters: ConversationsFilterDto): void {
    this.store.dispatch(UpdateConversationsFilters({
      filters
    }));
  }
}
