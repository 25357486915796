import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Client} from '../../../models/Client';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../state/reducers';
import {ProductRequest} from '../../../models/ProductRequest';
import {GetClientProductRequests} from '../../../clients/state/actions';
import {takeWhile} from 'rxjs/operators';
import {getClientProductRequestHistory} from '../../../clients/state/selectors';
import {Router} from '@angular/router';

@Component({
  selector: 'product-request-history',
  templateUrl: './product-request-history.component.html',
  styleUrls: ['./product-request-history.component.scss']
})
export class ProductRequestHistoryComponent implements OnInit, OnDestroy {
  @Input() client?: Client;
  alive = true;
  modalActive = false;
  productRequests: ProductRequest[] = [];

  constructor(private store: Store<AppState>, private router: Router) { }

  ngOnInit(): void {
    this.subscribeToProductRequests();

    if (this.client && this.client.id !== '0') {
      this.store.dispatch(GetClientProductRequests({client: this.client}));
    }
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  showModal(): void {
    if (this.client && this.client.id !== '0') {
      this.store.dispatch(GetClientProductRequests({client: this.client}));
      this.modalActive = true;
    }
  }

  subscribeToProductRequests(): void {
    this.store.pipe(select(getClientProductRequestHistory)).pipe(
      takeWhile(() => this.alive)
    ).subscribe((productRequests) => {
      this.productRequests = productRequests;
    });
  }

  goToAllProductRequests(): void {
    if (this.client) {
      this.router.navigateByUrl(`/search?s=${this.client.pmsId}&searchType=product-requests`);
    }
  }
}
