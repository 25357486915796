<div class="block-sender">
  <ng-container *ngIf="loading">
    <div class="loading">
      <p-progressSpinner [style]="{width: '24px', height: '24px'}" styleClass="dark-spinner" strokeWidth="6"></p-progressSpinner>
    </div>
  </ng-container>
  <ng-container *ngIf="!loading">
    <p>
      All existing messages from this number will be hidden and new ones will be blocked.
      <a [href]="helpLink + '/category/faq/post/how-do-i-block-a-mobile-number-from-sending-further-messages-spam-or-phishing'" class="block-info" target="_blank"><img [src]="'questionMarkIcon' | themeIconUrl: theme" height="20" class="help-icon" alt="" ></a>
    </p>
    <div class="btn-row">
      <button pButton label="Cancel" class="p-button-third p-button-sm" (click)="closeBlockSender()"></button>
      <button pButton label="Block Sender" class="pm-button-secondary p-button-sm" (click)="blockSender()"></button>
    </div>
  </ng-container>
</div>
