export enum FormFieldType {
  TEXT = 'TEXT',
  EMAIL = 'EMAIL',
  TEXTAREA = 'TEXTAREA',
  SELECT = 'SELECT',
  RADIO = 'RADIO',
  CHECKBOX = 'CHECKBOX',
  MULTI_CHECKBOX = 'MULTI_CHECKBOX',
  AGREE_TO_TERMS = 'AGREE_TO_TERMS',
  DATE = 'DATE',
  HIDDEN = 'HIDDEN',
}
