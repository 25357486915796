import { FilterOptionType } from "../enums/filter-option-type";
import { ProductRequestApprovalStatus } from "../enums/product-request-approval-status";
import { prettifyProductRequestApprovalStatus } from "../helpers/prettify-product-request-approval-status";
import { FilterOption } from "../interfaces/filter-option.interface";

export const productRequestApprovalStatusFilterOptions: FilterOption[] = [
  {
    id: ProductRequestApprovalStatus.APPROVED,
    label: prettifyProductRequestApprovalStatus(ProductRequestApprovalStatus.APPROVED),
    type: FilterOptionType.STANDARD
  },
  {
    id: ProductRequestApprovalStatus.PENDING,
    label: prettifyProductRequestApprovalStatus(ProductRequestApprovalStatus.PENDING),
    type: FilterOptionType.STANDARD
  },
  {
    id: ProductRequestApprovalStatus.REJECTED,
    label: prettifyProductRequestApprovalStatus(ProductRequestApprovalStatus.REJECTED),
    type: FilterOptionType.STANDARD
  },
  {
    id: ProductRequestApprovalStatus.NOT_REQUIRED,
    label: prettifyProductRequestApprovalStatus(ProductRequestApprovalStatus.NOT_REQUIRED),
    type: FilterOptionType.STANDARD
  }
];