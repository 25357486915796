import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { getUser } from 'src/app/auth/state/selectors';
import { User } from 'src/app/models/User';
import { GetPracticeStaff } from 'src/app/practices/state/actions';
import { getPracticeStaff } from 'src/app/practices/state/selectors';
import { AppState } from 'src/app/state/reducers';

@Component({
  selector: 'watchers',
  templateUrl: './watchers.component.html',
  styleUrls: ['./watchers.component.scss']
})
export class WatchersComponent implements OnInit, OnChanges, OnDestroy {
  alive = true;
  @Input() watchers: User[] | undefined = [];
  @Output() watch = new EventEmitter<User>();
  @Output() unwatch = new EventEmitter<User>();
  flyoutOpen = false;
  authUser$?: Observable<User | null>;
  allUsers$?: Observable<User[]>;
  filteredUsers$ = new BehaviorSubject<User[]>([]);
  filteredUsers: User[] = [];
  allUsers: User[] = [];
  authUser?: User | null;

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
    this.subscribeToAuthUser();
    this.subscribeToPracticeStaff();
  }

  ngOnChanges(): void {
    this.filteredUsers = this.allUsers.filter(user => !this.watchers?.map(watcher => watcher.id).includes(user.id));
    this.filteredUsers$.next(this.filteredUsers);
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  subscribeToAuthUser(): void {
    this.authUser$ = this.store.pipe(select(getUser)).pipe(takeWhile(() => this.alive));
    this.authUser$.subscribe((authUser) => {
      this.authUser = authUser;
      this.store.dispatch(GetPracticeStaff());
    });
  }

  subscribeToPracticeStaff(): void {
    this.allUsers$ = this.store.pipe(select(getPracticeStaff)).pipe(takeWhile(() => this.alive));
    this.allUsers$.subscribe(users => {
      this.allUsers = users;
      this.filteredUsers = users.filter(user => !this.watchers?.map(watcher => watcher.id).includes(user.id));
      this.filteredUsers$.next(this.filteredUsers);
    });
  }

  openFlyout(): void {
    this.flyoutOpen = true;
  }

  closeFlyout(): void {
    this.flyoutOpen = false;
  }

  handleClickOutside(): void {
    if (this.flyoutOpen) {
      this.closeFlyout();
    }
  }

  handleWatch(user: User): void {
    this.watch.emit(user);
  }

  handleUnwatch(user: User): void {
    this.unwatch.emit(user);
  }

  handleFlyoutClick(event: Event): void {
    if ((event.target as HTMLElement).classList.contains('flyout')) {
      this.closeFlyout();
    }
  }

  authUserIsWatching(): boolean {
    if (this.authUser && this.watchers?.find((w) => w.id === this.authUser?.id)) {
      return true;
    }

    return false;
  }
}
