import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProductRequestsBoardPage } from './pages/product-requests-board/product-requests-board.component';
import {ProductRequestsListPage} from './pages/product-requests-list/product-requests-list.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'board',
    pathMatch: 'full'
  },
  {
    path: 'board',
    component: ProductRequestsBoardPage,
    canDeactivate: [],
    data: {
      title: 'Product Requests | Board'
    }
  },
  {
    path: 'list',
    component: ProductRequestsListPage,
    canDeactivate: [],
    data: {
      title: 'Product Requests | List'
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductRequestsRoutingModule { }
