import { createAction, props } from '@ngrx/store';
import {DashboardStats} from '../../interfaces/dashboard-stats.interface';

export const GetDashboardStats = createAction(
  '[Dashboard] Get Dashboard Stats'
);

export const GetDashboardStatsSuccess = createAction(
  '[Dashboard] Get Dashboard Stats Success',
  props<{stats: DashboardStats}>()
);
