import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState } from './reducer';
import { AppState, STATE_KEY } from '../../state/reducers';

export const getAuthState = createFeatureSelector<AppState, AuthState>(STATE_KEY.AUTH);

export const getUser = createSelector(
  getAuthState,
  (state: AuthState) => state.user,
);

export const getUnreadHelpPosts = createSelector(
  getAuthState,
  (state: AuthState) => state.unreadHelpPosts,
);
