import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HistoryItem } from 'src/app/models/HistoryItem';
import { Patient } from 'src/app/models/Patient';

@Component({
  selector: 'patient-careplans',
  templateUrl: './patient-careplans.component.html',
  styleUrls: ['./patient-careplans.component.scss']
})
export class PatientCareplansComponent implements OnInit {
  @Input() careplans?: string[];
  modalActive = false;

  constructor() { }

  ngOnInit(): void {
  }

  showModal(): void {
    this.modalActive = true;
  }

  hideModal(): void {
    this.modalActive = false;
  }
}
