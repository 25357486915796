<ng-container *ngIf="(loading$ | ngrxPush) && (foldersLoading$ | ngrxPush)">
  <div class="loading">
    <ng-container *ngIf="!uploadingVideo && !videoProcessing">
      <p-progressSpinner [style]="{width: '40px', height: '40px'}" styleClass="custom-spinner" strokeWidth="4"></p-progressSpinner>
    </ng-container>
  </div>
</ng-container>

<ng-container *ngIf="videoProcessing">
  <div class="loading">
    <div class="video-upload">
      <div class="heading">{{'media.media_browser.video_upload' | translate}}</div>
      <div class="body">
        <div class="video-upload-bar complete">
          <div class="label">
            <img src="/assets/images/spinner-white.svg" class="loading-spinner" alt="">
            {{'media.media_browser.processing' | translate}}
          </div>
          <div class="bar"></div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="uploadingVideo">
  <div class="loading">
    <div class="video-upload">
      <div class="heading">{{'media.media_browser.video_upload' | translate}}</div>
      <div class="body">
        <div class="video-upload-bar">
          <ng-container *ngIf="videoUploadPercentage > 50">
            <div class="label filled">{{ videoUploadPercentage }}%</div>
          </ng-container>
          <ng-container *ngIf="videoUploadPercentage <= 50">
            <div class="label">{{ videoUploadPercentage }}%</div>
          </ng-container>
          <div class="bar" [style]="{'width': videoUploadPercentage+'%'}"></div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="manageFolders || manageMedia || filters">
  <div class="header">
    <div class="media-filters">
      <ng-container *ngIf="filters">
        <div class="filter-item with-dropdown filter" [class.active]="typeFilterOpen" [class.highlight]="selectedTypes.length > 0" (clickOutside)="typeFilterOpen = false;">
          <div class="toggle" (click)="toggleTypeDropdown()">
            <ng-container *ngIf="selectedTypes.length == 0 || !this.selectedTypeLabel">
              <i class="pi pi-box"></i>
              {{'media.media_browser.type' | translate}}
            </ng-container>
            <ng-container *ngIf="selectedTypes.length > 0 && this.selectedTypeLabel">
              <i class="pi pi-check"></i>
              {{ selectedTypeLabel }}
            </ng-container>
            <dropdown-icon></dropdown-icon>
          </div>
          <div class="dropdown">
            <ng-container *ngFor="let mediaType of mediaTypes">
              <div class="dropdown-item">
                <label [for]="mediaType" class="label">{{ mediaType | mediaTypeTranslationKey | translate }}</label>
                <p-checkbox  name="type" (onChange)="typeChanged()" [(ngModel)]="selectedTypes" [value]="mediaType" [inputId]="mediaType"></p-checkbox>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="pipe"></div>
        <div class="filter-item" (click)="resetFilters()">{{'media.media_browser.reset' | translate}}</div>
      </ng-container>
    </div>
    <div class="action-buttons">
      <ng-container *ngIf="manageFolders">
        <button pButton [label]="'media.media_browser.add_folder' | translate" class="p-button-secondary" (click)="openNewFolder()"></button>
      </ng-container>
      <ng-container *ngIf="manageMedia">
        <button pButton [label]="'media.media_browser.add_media' | translate" class="p-button-secondary" (click)="openNewMedia()"></button>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="currentFolder">
  <div class="breadcrumbs">
    <div class="breadcrumb" (click)="handleBreadcrumbClick(null)">{{'media.media_browser.all_media' | translate}}</div>
    <ng-container *ngFor="let breadcrumb of breadcrumbs">
      <i class="pi pi-angle-right"></i>
      <div class="breadcrumb" (click)="handleBreadcrumbClick(breadcrumb)">
        <div class="name">{{ breadcrumb.name }}</div>
      </div>
    </ng-container>
    <i class="pi pi-angle-right"></i>
    <div class="breadcrumb current">
      {{ currentFolder.name }}
    </div>
  </div>
</ng-container>

<div class="folder-grid" [class.compact]="compact">
  <ng-container *ngFor="let folder of folders">
    <folder-grid-item [practice]="practice" [folder]="folder" [manage]="manageFolders" (move)="handleMoveFolder($event)" (rename)="handleRenameFolder($event)" (delete)="handleDeleteFolder($event)" (selected)="handleFolderClick($event)"></folder-grid-item>
  </ng-container>
</div>

<ng-container *ngIf="media.length > 0">
  <div class="media-grid" [class.compact]="compact">
    <ng-container *ngFor="let mediaItem of media">
      <media-grid-item [practice]="practice" [media]="mediaItem" [manage]="manageMedia" [selector]="selector" (selected)="handleSelected($event)" (rename)="handleRenameMedia($event)" (move)="handleMoveMedia($event)" (delete)="handleDeleteMedia($event)"></media-grid-item>
    </ng-container>
  </div>
</ng-container>

<ng-container *ngIf="media.length === 0">
  <div class="no-media">{{'media.media_browser.no_media' | translate}}</div>
</ng-container>

<div class="media-pagination">
  <ng-container *ngIf="showPreviousPageLink()">
    <div class="previous" (click)="previousPage()">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M447.1 256C447.1 273.7 433.7 288 416 288H109.3l105.4 105.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448s-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L109.3 224H416C433.7 224 447.1 238.3 447.1 256z"/></svg>
    </div>
  </ng-container>
  <ng-container *ngIf="showPreviousPageLink() || showNextPageLink()">
    <div class="page">
      {{ this.page }}
    </div>
  </ng-container>
  <ng-container *ngIf="showNextPageLink()">
    <div class="next" (click)="nextPage()">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M438.6 278.6l-160 160C272.4 444.9 264.2 448 256 448s-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L338.8 288H32C14.33 288 .0016 273.7 .0016 256S14.33 224 32 224h306.8l-105.4-105.4c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160C451.1 245.9 451.1 266.1 438.6 278.6z"/></svg>
    </div>
  </ng-container>
</div>
