<ng-container *ngIf="(loading$ | async)">
    <div class="loading">
        <p-progressSpinner [style]="{width: '16px', height: '16px'}" styleClass="custom-spinner" strokeWidth="4"></p-progressSpinner>
        <div class="label">Loading...</div>
    </div>
</ng-container>
<ng-container *ngIf="(error$ | async)">
    <div class="error">An error has occurred when trying to search. Please try again.</div>
</ng-container>
<ng-container *ngIf="!(loading$ | async) && !(error$ | async)">
    <ng-container *ngIf="clients && clients.length === 0">
        No clients could be found, <span (click)="cancelSearch()">please try again</span>
    </ng-container>
    <ng-container *ngIf="clients && clients.length > 0">
        <div class="list">
            <ng-container *ngFor="let client of clients">
                <div class="list-item">
                    <client-list-item [client]="client" (clientSelected)="handleClientSelected($event)"></client-list-item>
                </div>
            </ng-container>
        </div>
    </ng-container>
</ng-container>
