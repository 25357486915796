import { Pipe, PipeTransform } from '@angular/core';
import { getColumnMappingName } from '../helpers/get-column-mapping-name';
import { TranslateService } from '@ngx-translate/core';

@Pipe({name: 'columnMappingName'})
export class ColumnMappingNamePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: string, columnIndex: number, prefix?: string): string {
    return getColumnMappingName(this.translateService, value, columnIndex, prefix);
  }
}
