import { createFeatureSelector, createSelector } from '@ngrx/store';
import { STATE_KEY } from 'src/app/state/reducers';
import { DialogState } from './reducer';

export const getDialogState = createFeatureSelector<DialogState>(STATE_KEY.DIALOGS);

export const isNewConversationOpen = createSelector(
    getDialogState,
    (state: DialogState) => state.newConversationOpen,
);

export const getNewConversationPrefilledData = createSelector(
    getDialogState,
    (state: DialogState) => {
        return {
            client: state.newConversationPrefilledClient,
            contact: state.newConversationPrefilledContact,
            channel: state.newConversationPrefilledChannel
        };
    }
)