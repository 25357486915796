export enum PMS {
  RXWORKS = "RxWorks",
  ROBOVET = "Robovet",
  MERLIN = "Merlin",
  TELEOS = "Teleos",
  PROVET = "Provet",
  ASCEND = "Ascend",
  EZYVET = "ezyVet",
  VETIT = "VetIT",
}
