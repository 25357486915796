import {PaymentFilterDto} from '../interfaces/dto/payment-filter.dto';

export function buildUrlParamsFromPaymentFilters(filters: PaymentFilterDto): string {
  let str = '';

  if (filters.searchTerm && filters.searchTerm.length > 0) {
    str += `&searchTerm=${JSON.stringify(filters.searchTerm)}`;
  }

  if (filters.owner && filters.owner.length > 0) {
    str += `&owner=${JSON.stringify(filters.owner)}`;
  }
  if (filters.status) {
    str += `&status=${JSON.stringify(filters.status)}`;
  }
  if (filters.client) {
    str += `&client=${JSON.stringify(filters.client)}`;
  }
  if (filters.sortBy) {
    str += `&sortBy=${JSON.stringify(filters.sortBy)}`;
  }
  if (filters.sortDir) {
    str += `&sortDir=${JSON.stringify(filters.sortDir)}`;
  }
  if (filters.type) {
    str += `&type=${JSON.stringify(filters.type)}`;
  }
  if (filters.page) {
    str += `&page=${JSON.stringify(filters.page)}`;
  }
  if (filters.created) {
    if (typeof filters.created === 'string') {
      str += `&date=${JSON.stringify(filters.created)}`;
    } else {
      str += `&start=${JSON.stringify(filters.created.start)}&end=${JSON.stringify(filters.created.end)}`;
    }
  }

  return str;
}
