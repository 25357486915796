import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {AppState} from '../../state/reducers';
import {Router} from '@angular/router';
import * as MediaActions from './actions';
import {catchError, map, mergeMap, switchMap, withLatestFrom} from 'rxjs/operators';
import {getCurrentPractice} from '../../practices/state/selectors';
import {Noop} from '../../state/actions';
import {of} from 'rxjs';
import {MediaService} from '../media.service';
import {MessageService} from 'primeng/api';
import {Media} from '../../models/Media';
import {VideoService} from '../../video/video.service';
import {HttpEventType} from "@angular/common/http";
import { getCurrentMediaFolder, getMediaFilters, getMediaPage } from './selectors';
import { MediaFolder } from '../../models/MediaFolder';
import { mediaSettings } from '../../constants/media-settings';

@Injectable()
export class MediaEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private router: Router,
    private mediaService: MediaService,
    private messageService: MessageService,
    private videoService: VideoService,
  ) {}

  getMedia$ = createEffect(() => this.actions$.pipe(
    ofType(MediaActions.GetMedia),
    withLatestFrom(
      this.store.select(getCurrentPractice),
      this.store.select(getMediaFilters),
      this.store.select(getMediaPage),
      this.store.select(getCurrentMediaFolder)
    ),
    mergeMap(([action, practice, filters, page, folder]) => {
      return this.mediaService.getMedia(practice, folder ? folder.id : null, filters.types, mediaSettings.perPage, mediaSettings.perPage * (page - 1))
        .pipe(
          map((result: { media: Media[], total: number}) => {
            return MediaActions.GetMediaSuccess({media: result.media, total: result.total});
          }),
          catchError(() => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred when getting media',
              life: 5000
            });
            return of(Noop());
          })
        );
    })
  ));

  addMedia$ = createEffect(() => this.actions$.pipe(
    ofType(MediaActions.AddMedia),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      return this.mediaService.addMedia(action.media, practice)
        .pipe(
          map((result: Media) => {
            return MediaActions.AddMediaSuccess({media: result});
          }),
          catchError(() => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred uploading media',
              life: 5000
            });
            return of(Noop());
          })
        );
    })
  ));

  addVideo$ = createEffect(() => this.actions$.pipe(
      ofType(MediaActions.AddVideo),
      withLatestFrom(this.store.select(getCurrentPractice)),
      mergeMap(([action, practice]) => {
        return this.videoService.createMediaLibraryVideoCompressionJob(practice, action.media)
          .pipe(
            map((response: {id: string, url: string}) => {
              return MediaActions.UploadVideo({
                id: response.id,
                presignedUrl: response.url,
                media: action.media
              });
            })
          );
      })
    )
  );

  uploadVideo$ = createEffect(() => this.actions$.pipe(
      ofType(MediaActions.UploadVideo),
      mergeMap((action) => this.videoService.uploadVideo(action.presignedUrl, action.media.file)
        .pipe(
          map((event) => {
            if (event.type === HttpEventType.UploadProgress) {
              let percentage = 0;
              if (event.total) {
                percentage = Math.round((event.loaded / event.total) * 100);
              }

              this.store.dispatch(MediaActions.UpdateVideoUploadProgress({percentage}));
            }
            if (event.type === HttpEventType.Response) {
              this.store.dispatch(MediaActions.VideoUploaded({
                id: action.id
              }));
            }
          })
        ))
    ), {dispatch: false}
  );

  videoUploaded$ = createEffect(() => this.actions$.pipe(
      ofType(MediaActions.VideoUploaded),
      mergeMap((action) => this.videoService.notifyVideoUploadComplete(action.id)
        .pipe(
          map(() => {
            return Noop();
          })
        ))
    ), {dispatch: false}
  );

  deleteMedia$ = createEffect(() => this.actions$.pipe(
    ofType(MediaActions.DeleteMedia),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      return this.mediaService.deleteMedia(action.media, practice)
        .pipe(
          map((result: Media) => {
            return MediaActions.DeleteMediaSuccess({media: result});
          }),
          catchError(() => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred during deletion',
              life: 5000
            });
            return of(Noop());
          })
        );
    })
  ));

  getMediaFolders$ = createEffect(() => this.actions$.pipe(
    ofType(MediaActions.GetMediaFolders),
    withLatestFrom(this.store.select(getCurrentPractice), this.store.select(getCurrentMediaFolder)),
    mergeMap(([action, practice, folder]) => {
      return this.mediaService.getMediaFolders(practice, folder ? folder.id : null)
        .pipe(
          map((result: MediaFolder[]) => {
            return MediaActions.GetMediaFoldersSuccess({folders: result});
          }),
          catchError(() => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred when getting folders',
              life: 5000
            });
            return of(Noop());
          })
        );
    })
  ));

  addMediaFolder$ = createEffect(() => this.actions$.pipe(
    ofType(MediaActions.AddMediaFolder),
    withLatestFrom(this.store.select(getCurrentPractice), this.store.select(getCurrentMediaFolder)),
    mergeMap(([action, practice, folder]) => {
      return this.mediaService.addMediaFolder(action.folder, practice, folder)
        .pipe(
          map((result: MediaFolder) => {
            return MediaActions.AddMediaFolderSuccess({folder: result});
          }),
          catchError(() => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred adding folder',
              life: 5000
            });
            return of(Noop());
          })
        );
    })
  ));

  renameMediaFolder$ = createEffect(() => this.actions$.pipe(
    ofType(MediaActions.RenameMediaFolder),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      return this.mediaService.updateMediaFolder(action.folder, practice)
        .pipe(
          map((result: MediaFolder) => {
            return MediaActions.RenameMediaFolderSuccess({folder: result});
          }),
          catchError(() => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred renaming folder',
              life: 5000
            });
            return of(Noop());
          })
        );
    })
  ));

  deleteMediaFolder$ = createEffect(() => this.actions$.pipe(
    ofType(MediaActions.DeleteMediaFolder),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      return this.mediaService.deleteMediaFolder(action.id, practice)
        .pipe(
          map((result: boolean) => {
            return MediaActions.DeleteMediaFolderSuccess({id: action.id});
          }),
          catchError(() => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred deleting folder',
              life: 5000
            });
            return of(Noop());
          })
        );
    })
  ));

  getMediaFolderTree$ = createEffect(() => this.actions$.pipe(
    ofType(MediaActions.GetMediaFolderTree),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      return this.mediaService.getMediaFolderTree(practice)
        .pipe(
          map((result: MediaFolder[]) => {
            return MediaActions.GetMediaFolderTreeSuccess({folders: result});
          }),
          catchError(() => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred when getting folders',
              life: 5000
            });
            return of(Noop());
          })
        );
    })
  ));

  moveFolder$ = createEffect(() => this.actions$.pipe(
    ofType(MediaActions.MoveFolder),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      if (action.folder.parentId === action.destinationFolderId || action.folder.parentId === null && action.destinationFolderId === 0) {
        return of(Noop());
      }

      return this.mediaService.updateMediaFolder({
        ...action.folder,
        parentId: action.destinationFolderId
      }, practice)
        .pipe(
          map((result: MediaFolder) => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Your folder has been moved',
              life: 5000
            });
            return MediaActions.MoveFolderSuccess({folder: result});
          }),
          catchError(() => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred when moving folder',
              life: 5000
            });
            return of(Noop());
          })
        );
    })
  ));

  moveMedia$ = createEffect(() => this.actions$.pipe(
    ofType(MediaActions.MoveMedia),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      return this.mediaService.updateMedia({
        ...action.media,
        parentId: action.destinationFolderId
      }, practice)
        .pipe(
          map((result: Media) => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Your media has been moved',
              life: 5000
            });
            return MediaActions.MoveMediaSuccess({media: result});
          }),
          catchError(() => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred when moving media',
              life: 5000
            });
            return of(Noop());
          })
        );
    })
  ));

  renameMedia$ = createEffect(() => this.actions$.pipe(
    ofType(MediaActions.RenameMedia),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      return this.mediaService.updateMedia({
        ...action.media,
      }, practice)
        .pipe(
          map((result: Media) => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Your media has been renamed',
              life: 5000
            });
            return MediaActions.RenameMediaSuccess({media: result});
          }),
          catchError(() => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred when renaming this media',
              life: 5000
            });
            return of(Noop());
          })
        );
    })
  ));
}
