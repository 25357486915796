export enum TemplateSubmissionStatus {
  Submitted = 'submitted',
  Approved = 'approved',
  Rejected = 'rejected',
  Disabled = 'disabled',
  Pending = 'pending',
  Flagged = 'flagged',
  Paused = 'paused',
  In_Appeal = 'in_appeal',
}
