import {HelpPost} from '../interfaces/help-post.interface';
import {HelpPostDto} from '../interfaces/dto/help-post.dto';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelpPostAdapter {
  run(dto: HelpPostDto): HelpPost {
    return {
      ...dto,
      content: JSON.parse(dto.content),
      publishedAt: new Date(dto.published_at),
      createdAt: new Date(dto.created_at),
      updatedAt: new Date(dto.updated_at),
      deletedAt: new Date(dto.deleted_at),
      categoryId: dto.category_id,
      notifyUsers: dto.notify_users,
    };
  }
}
