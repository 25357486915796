import { createAction, props } from '@ngrx/store';
import { Channel } from '../../enums/channel';
import { Client } from '../../models/Client';
import { Contact } from '../../models/Contact';

export const OpenNewConversation = createAction(
    '[Dialog] Open New Conversation',
    props<{client?: Client, contact?: Contact, channel?: Channel}>()
);

export const CloseNewConversation = createAction(
    '[Dialog] Close New Conversation'
);
