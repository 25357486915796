import {CurrencyCode} from '../enums/currency-code';
import {CurrencyPipe} from '@angular/common';

export function getClientBalance(
  currencyPipe: CurrencyPipe,
  value: string,
  currencyCode: CurrencyCode = CurrencyCode.GBP,
  currencySymbol: string = '£',
): string {
    const amount = Number(value);

    if (amount > 0) {
      return currencyPipe.transform(value, currencyCode, 'symbol-narrow') + ' in debt';
    }

    if (amount < 0) {
      return currencyPipe.transform(value.replace('-', ''), currencyCode, 'symbol-narrow') + ' in credit';
    }

    return currencySymbol + '0.00';
}
