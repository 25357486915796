import {Component, Input, OnInit} from '@angular/core';
import {ProductRequest} from '../../../models/ProductRequest';
import {Store} from '@ngrx/store';
import {AppState} from '../../../state/reducers';
import {RejectProductRequest} from '../../state/actions';
import {ProductRequestApprovalStatus} from "../../../enums/product-request-approval-status";

@Component({
  selector: 'reject-product-request',
  templateUrl: './reject-product-request.component.html',
  styleUrls: ['./reject-product-request.component.scss']
})
export class RejectProductRequestComponent implements OnInit {
  @Input() productRequest?: ProductRequest;
  validationError: string | null = null;
  notifyClient = true;
  rejectionReason = '';

  constructor(private store: Store<AppState>) {
  }

  ngOnInit(): void {
    this.prefilRejectionReason();
  }

  submit(): void {
    this.validationError = null;
    const valid = this.validateReason();

    if (!valid) {
      this.validationError = 'You must enter a reason for rejection';
      return;
    }

    if (this.productRequest) {
      this.store.dispatch(RejectProductRequest({
        productRequest: this.productRequest,
        rejectionReason: this.rejectionReason,
        notifyClient: this.notifyClient
      }));
    }
  }

  validateReason(): boolean {
    return this.rejectionReason.trim().length > 0;
  }

  prefilRejectionReason(): void {
    if (this.productRequest) {
      this.rejectionReason = this.productRequest.items
        .filter((item) => item.approvalStatus === ProductRequestApprovalStatus.REJECTED && item.rejectionReason)
        .map(item => item.rejectionReason)
        .join(', ');
    }
  }
}
