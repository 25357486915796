import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { PaymentRequest } from 'src/app/interfaces/payment-request';
import { Store } from '@ngrx/store';
import { AppState } from '../../../state/reducers';
import {Channel} from "../../../enums/channel";
import {Client} from "../../../models/Client";
import {Patient} from "../../../models/Patient";

@Component({
  selector: 'payment-request',
  templateUrl: './payment-request.component.html',
  styleUrls: ['./payment-request.component.scss']
})
export class PaymentRequestComponent implements OnInit, OnDestroy {
  @Input() paymentRequestLoading = false;
  @Input() paymentRequestOpen = false;
  @Input() paymentRequestFailed = false;
  @Input() disabled = false;
  @Input() balanceEnabled = false;
  @Input() outstanding = '0';
  @Input() useInTour = true;
  @Input() channel: Channel = Channel.WHATSAPP;
  @Input() client?: Client;
  @Input() patient?: Patient;
  @Output() paymentRequest = new EventEmitter<PaymentRequest>();
  @Output() togglePaymentRequest = new EventEmitter<boolean>();
  @Output() stepToConversation = new EventEmitter();
  @Output() stepToCloseActionsPopup = new EventEmitter();
  alive = true;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  emitTogglePaymentRequest(open: boolean): void {
    if (!this.disabled) {
      this.togglePaymentRequest.emit(open);
      setTimeout(() => {
        // this.paymentDescriptionField?.nativeElement.focus();
      }, 10);
    }
  }

  handleStepToCloseActionsPopup(): void {
    this.stepToCloseActionsPopup.emit();
  }

  handleStepToConversationAndCloseActionsPopup(): void {
    this.stepToConversation.emit();
    this.stepToCloseActionsPopup.emit();
  }

  handlePaymentRequested($event: PaymentRequest): void {
    this.paymentRequest.emit($event);
  }
}
