import { Pipe, PipeTransform } from '@angular/core';
import { ThemeService } from '../services/theme.service';

@Pipe({
  name: 'themeIconUrl'
})
export class ThemeIconUrlPipe implements PipeTransform {

  constructor(private themeService: ThemeService) {}
  transform(iconName: string, theme: string): unknown {
    return this.themeService.getThemeIcons(iconName,theme);
  }

}
