<ng-container *ngIf="item">
    <div class="item">
        <div class="name">
            <span *ngIf="item.itemNumber">{{ item.itemNumber }}x </span> {{ item.itemName}} <span *ngIf="item.batchNumber" class="batch-number">({{ item.batchNumber }})</span>
        </div>
        <div class="price">
            {{ item.price | currency : practiceCurrency?.currencyCode : 'symbol-narrow' }}
        </div>
    </div>
    <ng-container *ngIf="item.itemLabel">
        <div class="label">
            {{ item.itemLabel }}
        </div>
    </ng-container>
</ng-container>
