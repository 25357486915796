import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MediaFolder } from '../../../models/MediaFolder';
import { Observable, Subscription, takeWhile } from 'rxjs';
import { Media } from '../../../models/Media';
import { ChangeMediaFolder, DeleteMedia, GetMedia, GetMediaFolderTree, GetMediaFolders, SetMediaFilters, SetMediaPage, VideoComplete } from '../../state/actions';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../state/reducers';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { WebsocketService } from '../../../conversation/websocket.service';
import {
  getCurrentMediaFolder,
  getMedia,
  getMediaFilters,
  getMediaFolderBreadcrumbs,
  getMediaFolders,
  getMediaFoldersLoading,
  getMediaLoading,
  getMediaPage,
  getTotalMediaCount,
  getVideoCompressionJobId,
  getVideoUploadPercentage,
  isUploadingVideo,
  isVideoProcessing
} from '../../state/selectors';
import { Practice } from '../../../models/Practice';
import { getCurrentPractice } from '../../../practices/state/selectors';
import { MediaType } from '../../../enums/media-type';
import { AddMediaComponent } from '../add-media/add-media.component';
import { AddMediaFolderComponent } from '../add-folder/add-folder.component';
import { RenameMediaFolderComponent } from '../rename-folder/rename-folder.component';
import { DeleteMediaFolderComponent } from '../delete-folder/delete-folder.component';
import { MoveMediaFolderComponent } from '../move-folder/move-folder.component';
import { MoveMediaComponent } from '../move-media/move-media.component';
import { mediaSettings } from '../../../constants/media-settings';
import { MediaAdapter } from '../../../adapters/media.adapter';
import {RenameMediaComponent} from "../rename-media/rename-media.component";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'media-browser',
  templateUrl: './media-browser.component.html',
  styleUrls: ['./media-browser.component.scss']
})
export class MediaBrowserComponent implements OnInit, OnDestroy {
  @Input() manageFolders = true;
  @Input() manageMedia = true;
  @Input() filters = true;
  @Input() compact = false;
  @Input() type?: MediaType = undefined;
  @Input() selector = false;
  @Output() selected = new EventEmitter<Media>();
  alive = true;
  socketConnected = false;
  practice?: Practice;
  currentFolder: MediaFolder | null = null;
  media$?: Observable<Media[]>;
  folders$?: Observable<MediaFolder[]>;
  breadcrumbs$?: Observable<MediaFolder[]>;
  socketVideoCompressionJobComplete$?: Subscription;
  media: Media[] = [];
  folders: MediaFolder[] = [];
  breadcrumbs: MediaFolder[] = [];
  loading$: Observable<boolean>;
  foldersLoading$: Observable<boolean>;
  uploadingVideo = false;
  videoProcessing = false;
  videoUploadPercentage = 0;
  videoCompressionJobId: null | string = null;
  typeFilterOpen = false;
  mediaTypes: MediaType[] = [
    MediaType.Image,
    MediaType.Video,
    MediaType.PDF,
  ];
  selectedTypes: MediaType[] = [];
  selectedTypeLabel = '';
  addMediaDialog?: DynamicDialogRef;
  addFolderDialog?: DynamicDialogRef;
  renameFolderDialog?: DynamicDialogRef;
  deleteFolderDialog?: DynamicDialogRef;
  moveFolderDialog?: DynamicDialogRef;
  moveMediaDialog?: DynamicDialogRef;
  renameMediaDialog?: DynamicDialogRef;
  perPage = mediaSettings.perPage;
  page = 1;
  totalMediaCount = 0;

  constructor(
    private store: Store<AppState>,
    public dialogService: DialogService,
    private websocketService: WebsocketService,
    private mediaAdapter: MediaAdapter,
    private translateService: TranslateService,
  ) {
    this.loading$ = this.store.pipe(select(getMediaLoading)).pipe(takeWhile(() => this.alive));
    this.foldersLoading$ = this.store.pipe(select(getMediaFoldersLoading)).pipe(takeWhile(() => this.alive));

    this.store.pipe(select(getCurrentMediaFolder)).pipe(takeWhile(() => this.alive)).subscribe(currentFolder => {
      this.currentFolder = currentFolder;
    });
  }

  ngOnInit(): void {
    this.subscribeToCurrentPractice();
    this.subscribeToMedia();
    this.subscribeToMediaCount();
    this.subscribeToMediaPage();
    this.subscribeToMediaFilters();
    this.subscribeToFolders();
    this.subscribeToBreadcrumbs();
    this.subscribeToUploadingVideo();
    this.subscribeToVideoProcessing();
    this.subscribeToUploadVideoPercentage();
    this.subscribeToVideoCompressionJobId();

    this.websocketService.onConnect$.subscribe(connected => {
      this.socketConnected = true;
      this.joinSocket();
    });

    this.socketVideoCompressionJobComplete$ = this.websocketService.getMediaLibraryVideoCompressionJobComplete().subscribe((data) => {
      if (data.jobId === this.videoCompressionJobId) {
        this.store.dispatch(VideoComplete({
          media: this.mediaAdapter.run(data.media)
        }));
      }
    });
  }

  ngOnDestroy(): void {
    this.alive = false;
    this.socketVideoCompressionJobComplete$?.unsubscribe();
  }

  subscribeToCurrentPractice(): void {
    this.store.pipe(select(getCurrentPractice)).pipe(
      takeWhile(() => this.alive)
    ).subscribe((practice) => {
      if (practice) {
        this.practice = practice;
        this.joinSocket();
        this.fetchMedia();
        this.fetchFolders();
      }
    });
  }

  subscribeToMedia(): void {
    this.store.pipe(select(getMedia)).pipe(
      takeWhile(() => this.alive)
    ).subscribe((media) => {
      if (this.type) {
        const mediaTypeMap = {
          [MediaType.PDF]: 'pdf',
          [MediaType.Video]: 'video',
          [MediaType.Image]: 'image'
        };

        const mediaType = mediaTypeMap[this.type] || '';

        this.media = mediaType ? media.filter((m) => m.mime.includes(mediaType)) : media;
      } else {
        this.media = media;
      }
    });
  }

  subscribeToMediaCount(): void {
    this.store.pipe(select(getTotalMediaCount)).pipe(
      takeWhile(() => this.alive)
    ).subscribe((count) => {
      this.totalMediaCount = count;
    });
  }

  subscribeToMediaPage(): void {
    this.store.pipe(select(getMediaPage)).pipe(
      takeWhile(() => this.alive)
    ).subscribe(currentPage => {
      this.page = currentPage;
    });
  }

  subscribeToMediaFilters(): void {
    this.store.pipe(select(getMediaFilters)).pipe(
      takeWhile(() => this.alive)
    ).subscribe(filters => {
      this.selectedTypes = filters.types;
    });
  }

  subscribeToFolders(): void {
    this.store.pipe(select(getMediaFolders)).pipe(
      takeWhile(() => this.alive)
    ).subscribe((folders) => {
      this.folders = folders;
    });
  }

  subscribeToBreadcrumbs(): void {
    this.store.pipe(select(getMediaFolderBreadcrumbs)).pipe(takeWhile(() => this.alive)).subscribe((breadcrumbs) => {
      this.breadcrumbs = breadcrumbs;
    });
  }

  subscribeToUploadingVideo(): void {
    this.store.pipe(select(isUploadingVideo)).pipe(takeWhile(() => this.alive)).subscribe((value) => {
      this.uploadingVideo = value;
    });
  }

  subscribeToVideoProcessing(): void {
    this.store.pipe(select(isVideoProcessing)).pipe(takeWhile(() => this.alive)).subscribe((value) => {
      this.videoProcessing = value;
    });
  }

  subscribeToUploadVideoPercentage(): void {
    this.store.pipe(select(getVideoUploadPercentage)).pipe(takeWhile(() => this.alive)).subscribe((value) => {
      this.videoUploadPercentage = value;
    });
  }

  subscribeToVideoCompressionJobId(): void {
    this.store.pipe(select(getVideoCompressionJobId)).pipe(takeWhile(() => this.alive)).subscribe((id: null | string) => {
      this.videoCompressionJobId = id;
    });
  }

  joinSocket(): void {
    if (this.practice && this.socketConnected) {
      this.websocketService.joinMediaLibrary(this.practice.id);
    }
  }

  fetchMedia(): void {
    this.store.dispatch(GetMedia());
  }

  fetchFolders(): void {
    this.store.dispatch(GetMediaFolders());
  }

  fetchFolderTree(): void {
    this.store.dispatch(GetMediaFolderTree());
  }

  openNewMedia(): void {
    this.translateService.get('media.media_browser.add_media').pipe(takeWhile(_ => this.alive)).subscribe(header => {
      this.addMediaDialog = this.dialogService.open(AddMediaComponent, {
        header,
        modal: true,
        width: '500px',
        baseZIndex: 10000,
        data: {}
      });
    })
  }

  openRenameMedia(media: Media): void {
    this.translateService.get('media.media_browser.rename_name', {name: media.name}).pipe(takeWhile(_ => this.alive)).subscribe(header => {
      this.renameMediaDialog = this.dialogService.open(RenameMediaComponent, {
        header,
        modal: true,
        width: '500px',
        baseZIndex: 10000,
        data: {
          media
        }
      });
    })
  }

  openNewFolder(): void {
    this.translateService.get('media.media_browser.add_folder').pipe(takeWhile(_ => this.alive)).subscribe(header => {
      this.addFolderDialog = this.dialogService.open(AddMediaFolderComponent, {
        header,
        modal: true,
        width: '500px',
        baseZIndex: 10000,
        data: {}
      });
    });
  }

  openRenameFolder(folder: MediaFolder): void {
    this.translateService.get('media.media_browser.rename_folder').pipe(takeWhile(_ => this.alive)).subscribe(header => {
      this.renameFolderDialog = this.dialogService.open(RenameMediaFolderComponent, {
        header,
        modal: true,
        width: '500px',
        baseZIndex: 10000,
        data: {
          folder
        }
      });
    })
  }

  openDeleteFolder(folder: MediaFolder): void {
    this.translateService.get('media.media_browser.delete_folder').pipe(takeWhile(_ => this.alive)).subscribe(header => {
      this.deleteFolderDialog = this.dialogService.open(DeleteMediaFolderComponent, {
        header,
        modal: true,
        width: '500px',
        baseZIndex: 10000,
        data: {
          folder
        }
      });
    });
  }

  openMoveFolder(folder: MediaFolder): void {
    this.translateService.get('media.media_browser.move_folder').pipe(takeWhile(_ => this.alive)).subscribe(header => {
      this.moveFolderDialog = this.dialogService.open(MoveMediaFolderComponent, {
        header,
        modal: true,
        width: '500px',
        baseZIndex: 10000,
        data: {
          folder
        }
      });
    });
  }

  openMoveMedia(media: Media): void {
    this.translateService.get('media.media_browser.move_media').pipe(takeWhile(_ => this.alive)).subscribe(header => {
      this.moveMediaDialog = this.dialogService.open(MoveMediaComponent, {
        header,
        modal: true,
        width: '500px',
        baseZIndex: 10000,
        data: {
          media
        }
      });
    })
  }

  toggleTypeDropdown(): void {
    this.typeFilterOpen = !this.typeFilterOpen;
  }

  typeChanged(): void {
    this.store.dispatch(SetMediaFilters({
      filters: {
        types: this.selectedTypes
      }
    }));
    this.generateTypeLabel();
    this.store.dispatch(SetMediaPage({page: 1}));
    this.fetchMedia();
  }

  generateTypeLabel(): void {
    if (this.selectedTypes.length === 0) {
      this.selectedTypeLabel = '';
    } else {
      this.selectedTypeLabel = 'Type: ' + this.selectedTypes[0];
    }

    if (this.selectedTypes.length > 1) {
      this.selectedTypeLabel = this.selectedTypeLabel + ' +' + (this.selectedTypes.length - 1);
    }
  }

  resetFilters(): void {
    this.store.dispatch(SetMediaFilters({filters: {types: []}}));
    this.store.dispatch(SetMediaPage({page: 1}));
    this.fetchMedia();
  }

  showPreviousPageLink(): boolean {
    return this.page > 1;
  }

  showNextPageLink(): boolean {
    return this.totalMediaCount > this.page * this.perPage;
  }

  previousPage(): void {
    this.store.dispatch(SetMediaPage({page: this.page - 1}));
    this.fetchMedia();
  }

  nextPage(): void {
    this.store.dispatch(SetMediaPage({page: this.page + 1}));
    this.fetchMedia();
  }

  handleBreadcrumbClick(folder: MediaFolder | null): void {
    this.store.dispatch(ChangeMediaFolder({folder, direction: 'up'}));
    this.fetchFolders();
    this.fetchMedia();
  }

  handleFolderClick(folder: MediaFolder | null): void {
    this.store.dispatch(ChangeMediaFolder({folder, direction: 'down'}));
    this.fetchFolders();
    this.fetchMedia();
  }

  handleRenameFolder(folder: MediaFolder): void {
    this.openRenameFolder(folder);
  }

  handleMoveFolder(folder: MediaFolder): void {
    this.fetchFolderTree();
    this.openMoveFolder(folder);
  }

  handleDeleteFolder(folder: MediaFolder): void {
    this.openDeleteFolder(folder);
  }

  handleMoveMedia(media: Media): void {
    this.fetchFolderTree();
    this.openMoveMedia(media);
  }

  handleDeleteMedia(media: Media): void {
    const confirmDeleteMessage = this.translateService.instant('media.media_browser.confirm_delete');
    const confirmed = confirm(confirmDeleteMessage);
    if (confirmed && media) {
      this.store.dispatch(DeleteMedia({media}));
    }
  }

  handleSelected(media: Media): void {
    this.selected.emit(media);
  }

  handleRenameMedia(media: Media): void {
    this.openRenameMedia(media);
  }
}
