<div *ngIf="hasMessagingEnable">
  <div *ngIf="hasUsableChannel" class="outer-row">
    <div class="left" [class.hide]="previewOpen">
      <div class="step step-1" [class.active]="step === 1">
        <div class="input-row">
          <p-dropdown [options]="channels" [(ngModel)]="selectedChannel" optionLabel="name" optionDisabled="inactive"
            class="channel-dropdown" [style]="{ width: '100%' }">
            <ng-template pTemplate="selectedItem">
              <div class="channel-item channel-item-selected" *ngIf="selectedChannel">
                <img [src]="'assets/images/icons/' + selectedChannel.icon" class="channel-icon" />
                <div>{{ selectedChannel.name }}</div>
              </div>
            </ng-template>
            <ng-template let-channel pTemplate="item">
              <div class="channel-item">
                <img [src]="'assets/images/icons/' + channel.icon" class="channel-icon" />
                <div>{{ channel.name }}</div>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
        <form [formGroup]="stepOneForm">
          <div class="input-row">
            <input pInputText type="text" formControlName="name" [placeholder]="'ui.campaign_add_form.broadcast_name' | translate" #firstInput />
            <ng-container *ngIf="
                stepOneForm.controls['name'].invalid &&
                (stepOneForm.controls['name'].dirty ||
                  stepOneForm.controls['name'].touched)
              ">
              <div class="error">{{'ui.campaign_add_form.enter_broadcast_name' | translate}}</div>
            </ng-container>
          </div>
          <div class="input-row">
            <p-calendar formControlName="sendAt" [placeholder]="'ui.campaign_add_form.send_date' | translate" [touchUI]="true" [showTime]="false"
              [minDate]="minDate" dateFormat="dd/mm/yy"></p-calendar>
            <ng-container *ngIf="
                stepOneForm.controls['sendAt'].invalid &&
                (stepOneForm.controls['sendAt'].dirty ||
                  stepOneForm.controls['sendAt'].touched)
              ">
              <div class="error">{{'ui.campaign_add_form.enter_send_date' | translate}}</div>
            </ng-container>
          </div>
          <div class="input-row">
            <p-calendar formControlName="sendAtTime" [placeholder]="'ui.campaign_add_form.send_time' | translate" [touchUI]="true" [timeOnly]="true"
              [showTime]="true"></p-calendar>
            <ng-container *ngIf="
                stepOneForm.controls['sendAtTime'].invalid &&
                (stepOneForm.controls['sendAtTime'].dirty ||
                  stepOneForm.controls['sendAtTime'].touched)
              ">
              <div class="error">
                {{'ui.campaign_add_form.enter_send_time' | translate}}
              </div>
            </ng-container>
          </div>
          <div class="input-row">
            <select class="primary" formControlName="message" (change)="updateTemplatePreview()">
              <ng-container *ngFor="let template of templates">
                <option [value]="template.id">{{ template.name }}</option>
              </ng-container>
            </select>
            <ng-container *ngIf="templates.length === 0">
              <div class="error">
                {{'ui.campaign_add_form.approved_template' | translate}}
              </div>
            </ng-container>
          </div>
          <ng-container *ngIf="
              currentTemplate &&
              currentTemplate.attachMedia &&
              currentTemplate.mediaType
            ">
            <div class="input-row">
              <div class="media-row">
                <p-button [label]="'ui.campaign_add_form.select_media' | translate" (onClick)="selectMedia()" [styleClass]="buttonClass" iconPos="left" [icon]="
                    currentTemplate.mediaType === mediaType.Image
                      ? 'pi pi-image'
                      : currentTemplate.mediaType === mediaType.Video
                      ? 'pi pi-video'
                      : 'pi pi-file-pdf'
                  "></p-button>
                <span class="p-button-label">
                  {{ !previewSrc ? ('ui.campaign_add_form.no_media' | translate) : mediaName }}
                  <ng-container *ngIf="previewSrc">
                    <i class="pi pi-times" (click)="removeMedia()"></i>
                  </ng-container>
                </span>
              </div>
              <ng-container *ngIf="mediaErrorKey">
                <div class="error">{{ mediaErrorKey | translate }}</div>
              </ng-container>
            </div>
          </ng-container>

          <div class="btn-row">
            <div class="buttons-left"></div>
            <div class="buttons-right">
              <ng-container *ngTemplateOutlet="previewButtons"></ng-container>
              <p-button (click)="goToStep(2)" [label]="'ui.campaign_add_form.next_step' | translate" styleClass="p-button-primary"></p-button>
            </div>
          </div>
        </form>
      </div>
      <div class="step step-2" [class.active]="step === 2">
        <form [formGroup]="stepTwoForm">
          <div class="row">
            <div class="label">{{'ui.campaign_add_form.import_csv' | translate}}</div>
            <ng-container *ngIf="currentTemplateFieldsString.length > 0">
              <div class="sub-label">
                {{'ui.campaign_add_form.required_fields' | translate}}
                <i>{{ currentTemplateFieldsString }}</i>
              </div>
            </ng-container>
            <p-button (click)="openFileBrowser()" [label]="'ui.campaign_add_form.browse' | translate" styleClass="p-button-primary"></p-button>
          </div>
          <ng-container *ngIf="importedFilename">
            <div class="row">
              <strong>{{'ui.campaign_add_form.file_uploaded' | translate}}</strong> {{ importedFilename }}
            </div>
          </ng-container>
          <ng-container *ngIf="fileUploaded && importedData.length === 0">
            <div class="row">
              <div class="error">{{'ui.campaign_add_form.csv_error' | translate}}</div>
            </div>
          </ng-container>
          <ng-container *ngIf="!fileUploaded && importedData.length === 0 && showMissingCsv">
            <div class="row">
              <div class="error">{{'ui.campaign_add_form.upload_csv' | translate}}</div>
            </div>
          </ng-container>
          <div class="row">
            <label for="import-column-headers"><input type="checkbox" id="import-column-headers"
                formControlName="includesColumnHeaders" />
                {{'ui.campaign_add_form.csv_headers_included' | translate}}</label>
          </div>
          <div class="input-row">
            <label for="permission"><input type="checkbox" id="permission" formControlName="permission" />
              {{'ui.campaign_add_form.permission' | translate}}</label>
            <ng-container *ngIf="
                stepTwoForm.controls['permission'].invalid &&
                (stepTwoForm.controls['permission'].dirty ||
                  stepTwoForm.controls['permission'].touched)
              ">
              <div class="error">{{'ui.campaign_add_form.must_confirm' | translate}}</div>
            </ng-container>
          </div>
          <ng-container *ngIf="
              currentTemplate &&
              currentTemplate.type === templateType.CampaignWithPayment
            ">
            <div class="warning">
              <div class="heading">{{'ui.campaign_add_form.warning' | translate}}</div>
              <div class="text">
                {{'ui.campaign_add_form.payments_warning' | translate}}
              </div>
            </div>
          </ng-container>
          <div class="hidden">
            <input type="file" name="recipientsCsv" #recipientsCsv accept=".csv, text/csv" />
          </div>
        </form>
        <div class="btn-row">
          <div class="buttons-left">
            <p-button (click)="goToPreviousStep()" [label]="'ui.campaign_add_form.back' | translate" styleClass="p-button-fourth"></p-button>
          </div>
          <div class="buttons-right">
            <ng-container *ngTemplateOutlet="previewButtons"></ng-container>
            <p-button (click)="goToStep(3)" [label]="'ui.campaign_add_form.next_step' | translate" styleClass="p-button-primary"></p-button>
          </div>
        </div>
      </div>
      <div class="step step-3" [class.active]="step === 3">
        <ng-container *ngIf="step3Loading">
          <div class="loading">
            <p-progressSpinner [style]="{width: '40px', height: '40px'}" styleClass="custom-spinner" strokeWidth="4"></p-progressSpinner>
          </div>
        </ng-container>
        <ng-container *ngIf="!step3Loading">
          <ng-container *ngIf="importedData.length > 0">
            <div class="field-mapping-section">
              <ng-container *ngIf="!mappedFieldsValid">
                <div class="row">
                  <div class="error">
                    {{'ui.campaign_add_form.all_fields' | translate}}
                  </div>
                </div>
              </ng-container>
              <div class="label">{{'ui.campaign_add_form.map_data' | translate}}</div>
              <form [formGroup]="stepThreeForm">
                <div class="field-map-row">
                  <div class="field">{{'ui.campaign_add_form.phone_number' | translate}}</div>
                  <div class="value">
                    <select class="primary" formControlName="phone">
                      <option value="">{{'ui.campaign_add_form.please_select' | translate}}</option>
                      <ng-container *ngFor="
                        let item of importedData[0];
                        let columnIndex = index
                      ">
                        <option [value]="columnIndex">
                          {{
                          item
                            | columnMappingName
                            : columnIndex
                              : (stepTwoForm.controls.includesColumnHeaders.value
                              ? undefined
                              : ('ui.campaign_add_form.e_g' | translate))
                          }}
                        </option>
                      </ng-container>
                    </select>
                  </div>
                </div>
                <ng-container *ngIf="
                  currentTemplate &&
                  currentTemplate.type === templateType.CampaignWithPayment
                ">
                  <ng-container *ngIf="sites && sites.length > 1">
                    <div class="field-map-row">
                      <div class="field">{{'ui.campaign_add_form.site' | translate}}</div>
                      <div class="value">
                        <select class="primary" formControlName="site">
                          <ng-container *ngFor="let site of sites">
                            <option value="{{ site.id }}">{{ site.name }}</option>
                          </ng-container>
                        </select>
                      </div>
                    </div>
                  </ng-container>
                  <div class="field-map-row">
                    <div class="field">{{'ui.campaign_add_form.description' | translate}}</div>
                    <div class="value">
                      <select class="primary" formControlName="description">
                        <option value="">{{'ui.campaign_add_form.please_select' | translate}}</option>
                        <option value="fixed">{{'ui.campaign_add_form.fixed_value' | translate}}</option>
                        <ng-container *ngFor="
                          let item of importedData[0];
                          let columnIndex = index
                        ">
                          <option [value]="columnIndex">
                            {{
                            item
                              | columnMappingName
                              : columnIndex
                                : (stepTwoForm.controls.includesColumnHeaders
                                .value
                                ? undefined
                                : "e.g.")
                            }}
                          </option>
                        </ng-container>
                      </select>
                      <ng-container *ngIf="stepThreeForm.get('description')?.value === 'fixed'">
                        <div class="fixed-value-holder">
                          <input pInputText type="text" formControlName="descriptionFixed"
                                 [placeholder]="'ui.campaign_add_form.enter_description' | translate" />
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <div class="field-map-row">
                    <div class="field">{{'ui.campaign_add_form.client_id' | translate}}</div>
                    <div class="value">
                      <select class="primary" formControlName="clientId">
                        <option value="">{{'ui.campaign_add_form.please_select' | translate}}</option>
                        <ng-container *ngFor="
                          let item of importedData[0];
                          let columnIndex = index
                        ">
                          <option [value]="columnIndex">
                            {{
                            item
                              | columnMappingName
                              : columnIndex
                                : (stepTwoForm.controls.includesColumnHeaders
                                .value
                                ? undefined
                                : ('ui.campaign_add_form.e_g' | translate))
                            }}
                          </option>
                        </ng-container>
                      </select>
                    </div>
                  </div>
                  <div class="field-map-row">
                    <div class="field">{{'ui.campaign_add_form.amount_to_pay' | translate}}</div>
                    <div class="value">
                      <select class="primary" formControlName="paymentAmount" (change)="validatePaymentAmountSelection()">
                        <option value="">{{'ui.campaign_add_form.please_select' | translate}}</option>
                        <option value="fixed">{{'ui.campaign_add_form.fixed_value' | translate}}</option>
                        <ng-container *ngFor="
                          let item of importedData[0];
                          let columnIndex = index
                        ">
                          <option [value]="columnIndex">
                            {{
                            item
                              | columnMappingName
                              : columnIndex
                                : (stepTwoForm.controls.includesColumnHeaders
                                .value
                                ? undefined
                                : "e.g.")
                            }}
                          </option>
                        </ng-container>
                      </select>
                      <ng-container *ngIf="
                        stepThreeForm.get('paymentAmount')?.value === 'fixed'
                      ">
                        <div class="fixed-value-holder">
                          <input pInputText type="text" formControlName="paymentAmountFixed"
                                 [placeholder]="'ui.campaign_add_form.enter_fixed_value_example' | translate" (keyup)="validatePaymentAmountSelection()" />
                        </div>
                      </ng-container>
                    </div>
                    <ng-container *ngIf="amountNumericError">
                      <div class="field-error">{{'ui.campaign_add_form.payment_amount_numeric' | translate}}</div>
                    </ng-container>
                  </div>
                </ng-container>
                <ng-container *ngIf="currentTemplate">
                  <div formArrayName="mappedFields">
                    <ng-container *ngFor="
                      let mappedField of mappedFields()?.controls;
                      let i = index
                    " [formGroupName]="i">
                      <div class="field-map-row">
                        <div class="field">
                          {{
                          mappedField.value.customName ??
                          mappedField.value.content | prettifyTemplateContent | translate
                          }}
                        </div>
                        <div class="value">
                          <select formControlName="mappedColumn" class="primary" (ngModelChange)="updateMapping()" [ngClass]="{'mapped-error': !mappedFieldsValid}">
                            <option value="">{{'ui.campaign_add_form.please_select' | translate}}</option>
                            <option value="fixed">{{'ui.campaign_add_form.fixed_value' | translate}}</option>
                            <ng-container *ngFor="
                              let item of importedData[0];
                              let columnIndex = index
                            ">
                              <option [value]="columnIndex">
                                {{
                                item
                                  | columnMappingName
                                  : columnIndex
                                    : (stepTwoForm.controls.includesColumnHeaders
                                    .value
                                    ? undefined
                                    : ('ui.campaign_add_form.e_g' | translate))
                                }}
                              </option>
                            </ng-container>
                          </select>
                          <ng-container *ngIf="mappedField.value.mappedColumn === 'fixed'">
                            <div class="fixed-value-holder">
                              <input pInputText type="text" formControlName="fixedValue" [placeholder]="'ui.campaign_add_form.enter_fixed_value' | translate"
                                     (ngModelChange)="updateMapping()" />
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
              </form>
            </div>
          </ng-container>
          <div class="btn-row">
            <div class="buttons-left">
              <p-button (click)="goToPreviousStep()" [label]="'ui.campaign_add_form.back' | translate" styleClass="p-button-fourth"></p-button>
            </div>
            <div class="buttons-right">
              <ng-container *ngTemplateOutlet="previewButtons"></ng-container>
              <p-button (click)="submitDraft()" [label]="'ui.campaign_add_form.save_draft' | translate" styleClass="p-button-fourth"></p-button>
              <p-button (click)="submit()" [label]="'ui.campaign_add_form.schedule' | translate" styleClass="p-button-primary"></p-button>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="step step-4" [class.active]="step === 4">
        <ng-container *ngIf="limitErrors.length > 0">
          <div class="erros">
            <ng-container *ngIf="limitErrors.length > 0">
              <ng-container *ngFor="let limitError of limitErrors">
                <div class="error-label">
                  {{limitError.error}}
                </div>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="limitErrors.length === 0">
          <div class="create-success-title">
            <ng-container *ngIf="createdCampaigns.length === 1">{{'ui.campaign_add_form.created_one_broadcast' | translate }}</ng-container>
            <ng-container *ngIf="createdCampaigns.length !== 1">{{'ui.campaign_add_form.created_count_broadcasts' | translate: {count: createdCampaigns.length} }}</ng-container>
          </div>
          <ng-container *ngIf="
              optedOutUsers.length > 0 ||
              dataErrors.length > 0 ||
              invalidNumbers.length > 0 ||
              duplicates.length > 0
            ">
            <div class="warning">
              <div class="heading">{{'ui.campaign_add_form.warning' | translate}}</div>
              <div class="text">
                <ng-container *ngIf="dataErrors.length > 0">
                  <ng-container *ngFor="let dataError of dataErrors">
                    <ng-container *ngIf="dataError.errors === 1">
                      <div class="warning-label">
                        {{'ui.campaign_add_form.missing_or_incorrect' | translate: {name: dataError.name | prettifyTemplateContent | translate} }}
                      </div>
                    </ng-container>
                    <ng-container *ngIf="dataError.errors > 1">
                      <div class="warning-label">
                        {{'ui.campaign_add_form.missing_or_incorrect_count_rows' | translate: {
                          name: dataError.name | prettifyTemplateContent | translate,
                          count: dataError.errors
                        } }}
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="optedOutUsers.length > 0">
                  <ng-container *ngIf="optedOutUsers.length === 1">
                    <div class="warning-label">
                      {{'ui.campaign_add_form.one_recipient_opted_out' | translate}}
                    </div>
                  </ng-container>
                  <ng-container *ngIf="optedOutUsers.length > 1">
                    <div class="warning-label">
                      {{'ui.campaign_add_form.count_recipients_opted_out' | translate: {count: optedOutUsers.length} }}
                    </div>
                  </ng-container>
                  <ng-container *ngFor="let user of optedOutUsers">
                    <div class="warning-line">{{ user.phone }}</div>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="invalidNumbers.length > 0">
                  <ng-container *ngIf="invalidNumbers.length === 1">
                    <div class="warning-label">
                      {{'ui.campaign_add_form.one_invalid_phone' | translate}}
                    </div>
                  </ng-container>
                  <ng-container *ngIf="invalidNumbers.length > 1">
                    <div class="warning-label">
                      {{'ui.campaign_add_form.count_invalid_phones' | translate: {count: invalidNumbers.length} }}
                    </div>
                  </ng-container>
                  <ng-container *ngFor="let invalidNumber of invalidNumbers">
                    <div class="warning-line">{{ invalidNumber }}</div>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="duplicates.length > 0">
                  <ng-container *ngIf="duplicates.length === 1">
                    <div class="warning-label">
                      {{'ui.campaign_add_form.one_duplicated_phone' | translate}}
                    </div>
                  </ng-container>
                  <ng-container *ngIf="duplicates.length > 1">
                    <div class="warning-label">
                      {{'ui.campaign_add_form.count_duplicated_phones' | translate: {count: duplicates.length} }}
                    </div>
                  </ng-container>
                  <ng-container *ngFor="let duplicate of duplicates">
                    <div class="warning-line">{{ duplicate }}</div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <div class="btn-row">
          <div class="buttons-left">
            <p-button *ngIf="limitErrors.length > 0" (click)="goToPreviousStep()" label="Back" styleClass="p-button-fourth"></p-button>
          </div>
          <div class="buttons-right">
            <p-button (click)="close()" [label]="'ui.campaign_add_form.finish' | translate" styleClass="p-button-primary"></p-button>
          </div>
        </div>
      </div>
    </div>
    <div class="right" [class.show]="previewOpen">
      <div class="preview-title">
        {{'ui.campaign_add_form.broadcast_preview' | translate}}
        <ng-container *ngIf="device === 'mobile' && previewOpen == true">
          <div class="preview-link" (click)="togglePreview()">{{'ui.campaign_add_form.close_preview' | translate}}</div>
        </ng-container>
      </div>
      <ng-container *ngIf="messagePreviewText && currentTemplate">
        <div class="message-preview-wrap">
          <div class="message-preview">
            <ng-container *ngIf="previewType === 'pdf'">
              <div class="pdf-preview">
                <i class="pi pi-file-pdf"></i><span class="link-text">{{ mediaName || ('ui.campaign_add_form.download_pdf' | translate) }}</span>
              </div>
            </ng-container>
            <ng-container *ngIf="previewType === 'image' && previewSrc">
              <div class="image-preview">
                <img [src]="previewSrc" />
              </div>
            </ng-container>
            <ng-container *ngIf="previewType === 'video' && previewSrc">
              <div class="video-preview">
                <video [controls]="false" preload="metadata">
                  <source [src]="previewSrc" />
                </video>
              </div>
            </ng-container>
            <p>
              {{ messagePreviewText }}
            </p>
          </div>
          <ng-container *ngIf="currentTemplate.paymentButton">
            <div class="message-button">
              <i class="pi pi-external-link"></i>{{ currentTemplate.paymentButtonText }}
            </div>
          </ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="currentTemplate && currentTemplate.type === templateType.CampaignWithPayment">
        <div class="preview-title">
          Payment screen preview
        </div>
        <payment-screen-preview
          [practice]="practice ?? undefined"
          [description]="getPaymentDescriptionString()"
          [practiceCurrency]="practiceCurrency"
          [amount]="getPaymentString()"
        ></payment-screen-preview>
      </ng-container>

    </div>
  </div>
  <span *ngIf="!hasUsableChannel" [innerHtml]="'ui.campaign_add_form.not_configured' | translate"></span>
</div>
<ng-template #previewButtons>
  <ng-container *ngIf="!previewOpen">
    <div class="preview-button">
      <p-button (click)="showPreview()" [label]="'ui.campaign_add_form.show_preview' | translate" styleClass="p-button-fourth"></p-button>
    </div>
  </ng-container>
</ng-template>
