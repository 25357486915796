import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {Injectable} from '@angular/core';
import {combineLatest, distinctUntilChanged, firstValueFrom, merge, Observable, of} from 'rxjs';
import {UserService} from '../users/user.service';
import {CookieService} from 'ngx-cookie-service';
import {map, tap} from 'rxjs/operators';
import {ConversationService} from '../conversation/conversation.service';
import {Role} from '../enums/role';

@Injectable({
  providedIn: 'root'
})
export class ConversationCanBeViewedByAnalysts  {
  constructor(
    private conversationService: ConversationService,
    private userService: UserService,
    private cookieService: CookieService,
    private router: Router
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const conversationId = route.paramMap.get('id');
    if (!conversationId || conversationId === '0') {
      return of(true);
    }

    const conversation = this.conversationService.getConversation(conversationId).pipe(map((conv) => {
      return conv;
    }));

    const userPractices = this.userService.getUserPractices().pipe(map((practices) => {
      return practices;
    }));

    return combineLatest([conversation, userPractices]).pipe(map(([conv, practices]) => {
      if (!conv.resolvedAt) {
        let practice;

        const cookiePractice = this.cookieService.get('messaging-selected-practice');

        if (cookiePractice) {
          practice = practices.find(actionPractice => actionPractice.id === cookiePractice);
        }

        if (!practice && practices.length > 0) {
          practice = practices[0];
        }

        if (practice && practice.userRole !== Role.ANALYST) {
          return true;
        }

        return false;
      } else {
        return true;
      }
    }));
  }
}
