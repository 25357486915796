import { Pipe, PipeTransform } from "@angular/core";

export enum TemplateArchiveStatus {
    NOT_ARCHIVED = 'Not Archived',
    ARCHIVED = 'Archived'
}

const enumToTranslation: Record<TemplateArchiveStatus, string> = {
  [TemplateArchiveStatus.ARCHIVED]: 'enums.template_archived_status.archived',
  [TemplateArchiveStatus.NOT_ARCHIVED]: 'enums.template_archived_status.not_archived',
}

@Pipe({name: 'templateArchiveStatusTranslationKey'})
export class TemplateArchiveStatusTranslationKeyPipe implements PipeTransform {
  transform(value: TemplateArchiveStatus): string {
    return enumToTranslation[value];
  }
}
