<div class="panel">
    <ng-container *ngIf="shareEnabled">
      <ng-container *ngIf="shared">
          <div class="share-complete">
            <i class="pi pi-check"></i>Client shared to {{ (practice$ | async)?.pms }}
          </div>
      </ng-container>
      <ng-container *ngIf="!shared">
          <p-button class="btn-pms-share" [pTooltip]="shareLabel" tooltipPosition="left" styleClass="p-button-fourth p-button-sm" [disabled]="!!disabled" (click)="emitShareToPms()" joyrideStep="step13" [stepContent]="step13Content" stepPosition="bottom" (prev)="handleStepToOpenActionPopup()" (next)="handleStepToConversation()">
              <ng-container *ngIf="!shareLoading">
                <img alt="logo" class="icon" src="../../../../assets/images/icons/icon-share.svg" />
              </ng-container>
              <ng-container *ngIf="shareLoading">
                <p-progressSpinner [style]="{width: '17px', height: '17px'}" styleClass="dark-spinner" strokeWidth="6"></p-progressSpinner>
              </ng-container>
              <span class="p-ml-2">{{ (practice$ | async)?.pms }}</span>
          </p-button>
      </ng-container>
    </ng-container>
    <div class="cols">
        <div class="details-col">
            <div class="details-holder">
                <client-details [disabled]="disabled" [client]="client" [changeDisabled]="changeDisabled" (clientChanged)="handleClientChanged()"></client-details>
            </div>
            <div class="actions-holder">
                <client-actions (paymentHistoryOpen)="paymentHistoryOpened()" [client]="client"></client-actions>
            </div>
        </div>
    </div>
</div>

<ng-template #step13Content>
  <div class="tour-item">
    <div class="top pink"><img src="/src/assets/images/icons/tour/share.svg" height="109" alt="" class="icon"></div>
    <div class="content">
      <div class="title">View in your PMS</div>
      <div class="description">Send a link to a folder in your PMS to view the client or patient file in more detail</div>
    </div>
  </div>
</ng-template>
