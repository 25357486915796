<ng-container *ngIf="(paymentRequestLoading$ | async)">
    <p-progressSpinner [style]="{width: '32px', height: '32px', margin: '42px auto', display: 'block'}" styleClass="custom-spinner" strokeWidth="4"></p-progressSpinner>
</ng-container>
<ng-container *ngIf="!(paymentRequestLoading$ | async)">
  <ng-container *ngIf="step === 0">
    <div class="subtitle">{{'ui.new_payment.search_client' | translate}}</div>
    <client-selector
        [noModal]="true"
        (clientSelected)="selectClient($event)"
    ></client-selector>
    </ng-container>

    <ng-container *ngIf="step === 1">
      <div class="subtitle">{{'ui.new_payment.select_contact' | translate}}</div>
        <ng-container *ngIf="selectedClient">
            <div class="contact-selector-wrap">
                <div class="client-details">
                    <div class="name">
                        {{ selectedClient.title }} {{ selectedClient.firstName }}
                        {{ selectedClient.lastName }} ({{
                            selectedClient.pmsDisplayId ?? selectedClient.pmsId
                        }})
                    </div>
                    <div class="address">
                        {{
                            selectedClient.address1
                                ? selectedClient.address1 + ","
                                : ""
                        }}
                        {{
                            selectedClient.address2
                                ? selectedClient.address2 + ","
                                : ""
                        }}
                        {{
                            selectedClient.address3
                                ? selectedClient.address3 + ","
                                : ""
                        }}
                        {{
                            selectedClient.postcode
                                ? selectedClient.postcode + ","
                                : ""
                        }}
                        {{ selectedClient.state }}
                    </div>
                </div>
                <ng-container
                    *ngFor="let contact of this.selectedClient.mobileContacts"
                >
                    <div class="contact-selector">
                        <div class="label">
                            {{ contact.name }}: {{ contact.value }}
                        </div>
                        <button
                            pButton
                            [label]="'ui.new_payment.select' | translate"
                            class="p-button-sm p-button-ghost"
                            (click)="selectContact(contact)"
                        ></button>
                    </div>
                </ng-container>
            </div>
        </ng-container>
        <div class="next-step">
            <div class="back" (click)="step = step - 1">
                <img src="assets/images/icons/back.svg" alt="" />
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="step === 2">
        <div class="step-2">
          <div class="subtitle">{{'ui.new_payment.select_channel' | translate}}</div>

            <p-dropdown
                [options]="channels"
                [ngModel]="selectedChannel"
                (ngModelChange)="onChannelChange($event)"
                optionLabel="name"
                optionDisabled="inactive"
                class="channel-dropdown"
            >
                <ng-template pTemplate="selectedItem">
                    <div
                        class="channel-item {{
                            selectedChannel.className
                        }} channel-item-selected"
                        [class.facebook-linked]="facebookLinked"
                        [class.instagram-linked]="instagramLinked"
                        *ngIf="selectedChannel"
                    >
                        <img
                            [src]="'assets/images/icons/' + selectedChannel.icon"
                            class="channel-icon"
                        />
                        <div>{{ selectedChannel.name }}</div>
                    </div>
                </ng-template>
                <ng-template let-channel pTemplate="item">
                    <div
                        class="channel-item {{ channel.className }}"
                        [class.facebook-linked]="facebookLinked"
                        [class.instagram-linked]="instagramLinked"
                    >
                        <img
                            [src]="'assets/images/icons/' + channel.icon"
                            class="channel-icon"
                        />
                        <div>{{ channel.name }}</div>
                    </div>
                </ng-template>
            </p-dropdown>

            <ng-container
                *ngIf="!facebookLinked && selectedChannel.code === channel.FACEBOOK"
            >
                <div class="no-facebook-link">
                  {{'ui.new_payment.no_facebook_link' | translate}}
                </div>
            </ng-container>

            <ng-container
                *ngIf="
                    !instagramLinked && selectedChannel.code === channel.INSTAGRAM
                "
            >
                <div class="no-facebook-link">
                  {{'ui.new_payment.no_instagram_Link' | translate}}
                </div>
            </ng-container>
        </div>
        <div class="next-step">
            <div class="back" (click)="step = step - 1">
                <img src="assets/images/icons/back.svg" alt="" />
            </div>
            <button
                pButton
                (click)="handleSelectedChannel()"
                [label]="'ui.new_payment.next' | translate"
                class="p-button-first"
            ></button>
        </div>
    </ng-container>


    <ng-container *ngIf="step === 3 && selectedClient">
      <payment-request-form
          [balanceEnabled]="balanceEnabled"
          [paymentRequestLoading]="(paymentRequestLoading$ | async) ?? false"
          [paymentRequestFailed]="(newPaymentFailed$ | async) ?? false"
          [client]="selectedClient"
          [disabled]="false"
          [outstanding]="selectedClient.accountStatus ? selectedClient.accountStatus : '0'"
          [channel]="selectedChannel.code"
          (paymentRequested)="paymentRequested($event)"
      ></payment-request-form>

        <ng-container *ngIf="!(paymentRequestLoading$ | async)">
            <div class="next-step">
                <div class="back" (click)="handleBackFromPaymentForm()">
                    <img src="assets/images/icons/back.svg" alt="" />
                </div>
            </div>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="step === 5">
      <div class="subtitle">{{'ui.new_payment.phone_invalid' | translate}}</div>
      <p class="descr">{{'ui.new_payment.select_valid_number' | translate}}</p>

      <div class="next-step">
          <div>
              <div class="back" (click)="step = step - 4">
                  <img src="assets/images/icons/back.svg" alt="" />
              </div>
          </div>
      </div>
  </ng-container>
</ng-container>


