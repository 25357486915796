import { Injectable } from '@angular/core';
import { Channel } from '../enums/channel';
import { CampaignDto } from '../interfaces/dto/campaign.dto';
import { Campaign } from '../models/Campaign';
import { TemplateAdapter } from './template.adapter';
import { WhatsappTemplateAdapter } from './whatsapp-template.adapter';

@Injectable({
  providedIn: 'root'
})
export class CampaignAdapter {
  constructor(private templateAdapter: WhatsappTemplateAdapter) {
  }

  run(dto: CampaignDto): Campaign {
    return {
        ...dto,
        template: dto.template ? this.templateAdapter.run(dto.template) : null,
        channel: dto.channel as Channel,
        sendAt: new Date(dto.sendAt),
        queuedAt: dto.queuedAt ? new Date(dto.queuedAt) : null,
        completedAt: dto.completedAt ? new Date(dto.completedAt) : null,
        createdAt: new Date(dto.createdAt),
        updatedAt: new Date(dto.updatedAt)
    };
  }
}
