import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {FormsPage} from './pages/forms/forms.component';
import {FormBuilderPage} from './pages/form-builder/form-builder.component';
import {FormSubmissionsPage} from './pages/form-submissions/form-submissions.component';
import { FormSubmissionPage } from './pages/form-submission/form-submission.component';
import {IsAdminGuard} from '../guards/is-admin.guard';

const routes: Routes = [
  {
    path: '',
    component: FormsPage,
    data: {
      title: 'Forms'
    },
    canActivate: [IsAdminGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'build',
    component: FormBuilderPage,
    data: {
      title: 'Form Builder'
    },
    canActivate: [IsAdminGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'edit/:id',
    component: FormBuilderPage,
    data: {
      title: 'Form Builder'
    },
    canActivate: [IsAdminGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'submissions',
    component: FormSubmissionsPage,
    data: {
      title: 'Form Submissions'
    }
  },
  {
    path: 'submissions/:uuid',
    component: FormSubmissionPage,
    data: {
      title: 'Form Submissions'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FormsRoutingModule { }
