import { Payment } from "../models/Payment";

export function getPaymentExpiryDate(payment: Payment): Date | null {
    if (payment.paidAt || payment.cancelledAt || payment.refundOfId) {
        return null;
    }

    if (payment.authorisedAt && payment.authorisationExpiresAt) {
        return payment.authorisationExpiresAt;
    }

    return payment.expiresAt;
}