<button pButton [label]="'patients.reminders.reminders' | translate" class="p-button-fourth p-button-sm p-button-fullwidth" [disabled]="!patient || !patient.reminders || patient.reminders.length === 0 || disabled" (click)="showModal()"></button>
<ng-container *ngIf="patient && patient.reminders && patient.reminders.length > 0">
    <p-dialog
        [header]="'patients.reminders.reminders' | translate"
        [(visible)]="modalActive"
        [modal]="true"
        [style]="{width: '500px', 'min-height': '30vh'}"
        [baseZIndex]="10000"
        [draggable]="false"
        [resizable]="false"
    >
        <div class="row headings">
            <div class="col left name">{{'patients.reminders.name' | translate}}</div>
            <div class="col right">{{'patients.reminders.created_date' | translate}}</div>
            <div class="col right">{{'patients.reminders.due_date' | translate}}</div>
        </div>
        <ng-container *ngFor="let reminder of patient.reminders">
            <div class="row">
                <div class="col left name">{{ reminder.name }}</div>
                <div class="col right">{{ reminder.createdAt ? (reminder.createdAt | dfnsFormat: 'dd/MM/yy') : '-' }}</div>
                <div class="col right">{{ reminder.dueDate | dfnsFormat: 'dd/MM/yy' }}</div>
            </div>
        </ng-container>
    </p-dialog>
</ng-container>
