import { enGB, es, fi, sv, nn, it, arSA, pt } from 'date-fns/locale';

export function getLocaleByLanguage(code: string) {
  switch (code) {
    case 'es':
      return es;
    case 'fi':
      return fi;
    case 'sv':
      return sv;
    case 'no':
      return nn;
    case 'it':
      return it;
    case 'ae':
      return arSA;
    case 'pt':
      return pt;
    default:
      return enGB;
  }
}
