<ng-container>
  <div class="wrapper">
    <div class="create-button button" [class.active]="showingCreateActions" [class.half-width]="showActionBar == actionBars.MANAGE_CONVERSATION">
      <p-button [label]="'ui.mobile_header_action_bar.create' | translate" styleClass="p-button-secondary p-button-left-aligned" icon="pi pi-plus" (onClick)="toggleCreateMenu()" [style]="{'width':'100%'}" (clickOutside)="showingCreateActions = false"></p-button>
        <ng-container *ngIf="showingCreateActions">
          <div class="create-actions">
            <create-actions
              [fullWidth]="true"
              (actionSelected)="handleActionSelected()"
            ></create-actions>
          </div>
        </ng-container>
    </div>
    <ng-container *ngIf="showActionBar == actionBars.MANAGE_CONVERSATION">
        <div class="resolve-button">
          <manage-conversation-action-bar [headerElement]="headerElement"></manage-conversation-action-bar>
        </div>
    </ng-container>
  </div>
</ng-container>
