export enum MessageType {
    STANDARD = 'STANDARD',
    AUTORESPONSE = 'AUTORESPONSE',
    PAYMENT_LINK = 'PAYMENT_LINK',
    PAYMENT_COMPLETE = 'PAYMENT_COMPLETE',
    INITIAL_CONSENT_REQUEST = 'INITIAL_CONSENT_REQUEST',
    REOPEN_CONSENT_REQUEST = 'REOPEN_CONSENT_REQUEST',
    VERIFICATION_REQUEST = 'VERIFICATION_REQUEST',
    VERIFICATION_COMPLETE = 'VERIFICATION_COMPLETE',
    ATTACHMENT_IMAGE = 'ATTACHMENT_IMAGE',
    ATTACHMENT_AUDIO = 'ATTACHMENT_AUDIO',
    ATTACHMENT_FILE = 'ATTACHMENT_FILE',
    ATTACHMENT_VIDEO = 'ATTACHMENT_VIDEO',
    WELCOME = 'WELCOME',
    PAYMENT_REFUNDED = 'PAYMENT_REFUNDED',
    PAYMENT_AUTHORISED = 'PAYMENT_AUTHORISED',
    PRODUCT_REQUEST_REJECTED = 'PRODUCT_REQUEST_REJECTED',
    PRODUCT_REQUEST_APPROVED = 'PRODUCT_REQUEST_APPROVED',
    PRODUCT_REQUEST_PARTIALLY_APPROVED = 'PRODUCT_REQUEST_PARTIALLY_APPROVED',
    PRODUCT_REQUEST_PAID = 'PRODUCT_REQUEST_PAID',
    PRODUCT_REQUEST_DISPENSED = 'PRODUCT_REQUEST_DISPENSED',
}
