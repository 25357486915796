import { Pipe, PipeTransform } from "@angular/core";

export enum MediaType {
  Image = 'Image',
  Video = 'Video',
  PDF = 'PDF',
}

const enumToTranslation: Record<MediaType, string> = {
  [MediaType.Image]: 'enums.media_type.image',
  [MediaType.PDF]: 'enums.media_type.pdf',
  [MediaType.Video]: 'enums.media_type.video',
}

@Pipe({name: 'mediaTypeTranslationKey'})
export class MediaTypeTranslationKeyPipe implements PipeTransform {
  transform(value: MediaType): string {
    return enumToTranslation[value];
  }
}
