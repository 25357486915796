import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OneSignal } from 'onesignal-ngx';
import {Observable, Subscription} from 'rxjs';
import { filter, map, takeWhile } from 'rxjs/operators';
import { AuthState } from 'src/app/auth/state/reducer';
import { getUser } from 'src/app/auth/state/selectors';
import { Notification } from 'src/app/models/Notification';
import { GetNotifications, ToggleToShowAllNotifications } from 'src/app/notifications/state/actions';
import {
  getNotificationShowAll,
  getNotifications,
  getOlderNotifications,
  getTodaysNotifications,
  getTotalNotificationCount,
  getYesterdaysNotifications
} from 'src/app/notifications/state/selectors';
import { AppState } from 'src/app/state/reducers';

@Component({
  selector: 'notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss']
})
export class NotificationsListComponent implements OnInit, OnDestroy {
  @Output() linkClicked = new EventEmitter();
  alive = true;
  subscriptions: Subscription[]=[];
  notifications: Notification[] = [];
  todaysNotifications: Notification[] = [];
  yesterdaysNotifications: Notification[] = [];
  olderNotifications: Notification[] = [];
  totalNotificationCount = 0;
  showAll: boolean = false;
  page = 0;
  perPage = 20;

  constructor(
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.subscribeToAuthUser();
    this.subscribeToNotifications();
  }

  ngOnDestroy(): void {
    this.alive = false;
    this.subscriptions.forEach((subscription)=>{
      subscription.unsubscribe();
    });
  }

  subscribeToNotifications(): void {
    this.subscribeToAllNotifications();
    this.subscribeToTodaysNotifications();
    this.subscribeToYeterdaysNotifications();
    this.subscribeToOlderNotifications();
    this.subscribeToTotalNotificationCount();
    this.subscribeToFetchShowAllNotificationFlag();
  }

  subscribeToAllNotifications(): void {
    this.subscriptions.push(this.store.pipe(select(getNotifications)).pipe(
      takeWhile(() => this.alive)
    ).subscribe((notifs) => {
      this.notifications = notifs;
    }));
  }

  subscribeToTodaysNotifications(): void {
    this.subscriptions.push(this.store.pipe(select(getTodaysNotifications)).pipe(
      takeWhile(() => this.alive)
    ).subscribe((notifs) => {
      this.todaysNotifications = notifs;
    }));
  }

  subscribeToYeterdaysNotifications(): void {
    this.subscriptions.push((this.store.pipe(select(getYesterdaysNotifications)).pipe(
      takeWhile(() => this.alive)
    ).subscribe((notifs) => {
      this.yesterdaysNotifications = notifs;
    })));
  }

  subscribeToOlderNotifications(): void {
    this.subscriptions.push(this.store.pipe(select(getOlderNotifications)).pipe(
      takeWhile(() => this.alive)
    ).subscribe((notifs) => {
      this.olderNotifications = notifs;
    }));
  }

  subscribeToTotalNotificationCount(): void {
    this.subscriptions.push(this.store.pipe(select(getTotalNotificationCount)).pipe(
      takeWhile(() => this.alive)
    ).subscribe((count) => {
      this.totalNotificationCount = count;
    }));
  }

  subscribeToFetchShowAllNotificationFlag(): void {
    this.subscriptions.push(this.store.pipe(select(getNotificationShowAll)).pipe(
      takeWhile(() => this.alive)
    ).subscribe((showAll) => {
      this.showAll = showAll;
      this.page = 0;
      this.getNotifications();
    }));
  }

  subscribeToAuthUser(): void {
    this.store.pipe(select(getUser)).pipe(
      filter((user) => user !== null),
      takeWhile(() => this.alive)
    ).subscribe(() => {
      this.store.dispatch(GetNotifications({page: this.page, perPage: this.perPage, unreadOnly: !this.showAll}));
      this.getNotifications();
    });
  }

  handleShowAllChange(): void {
    this.store.dispatch(ToggleToShowAllNotifications());
  }

  handleLinkClicked(): void {
    this.linkClicked.emit();
  }

  loadMore(): void {
    this.page = this.page + 1;
    this.getNotifications();
  }

  getNotifications(): void {
    this.store.dispatch(GetNotifications({page: this.page, perPage: this.perPage, unreadOnly: !this.showAll}));
  }
}
