<ng-container *ngIf="productRequest">
  <div class="product-request-payment">
    <payment-request-form
      [paymentRequestLoading]="false"
      [paymentRequestFailed]="false"
      [disabled]="false"
      [client]="productRequest.client"
      [patient]="productRequest.patient"
      [channel]="productRequest.channel"
      (paymentRequested)="handlePaymentRequested($event)"
      [showOptionalMessage]="false"
      [prefilledDescription]="paymentDescription"
      [showPreAuth]="false"
      [showAdvancedOptions]="false"
    ></payment-request-form>
  </div>
</ng-container>
