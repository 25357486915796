import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { generateColour } from 'src/app/helpers/user-color-generator';
import { Client } from 'src/app/models/Client';

@Component({
  selector: 'client-avatar',
  templateUrl: './client-avatar.component.html',
  styleUrls: ['./client-avatar.component.scss']
})
export class ClientAvatarComponent implements OnInit, OnChanges {
  @Input() client?: Client;
  @Input() size = 'medium';
  @Input() responsive = false;
  @Input() unsetInitials = '+';
  backgroundColor?: string;

  constructor() { }

  ngOnInit(): void {
    this.generateColor();
  }

  ngOnChanges(): void {
    this.generateColor();
  }

  generateColor(): void {
    let name = '';
    
    if (this.client) {
      name = this.client.lastName;
    }

    this.backgroundColor = generateColour(name);
  }
}
