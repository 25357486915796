import { Pipe, PipeTransform } from '@angular/core';
import { MessageStatus } from '../enums/message-status';
import { Message } from '../models/Message';


@Pipe({name: 'showConsentMessage'})
export class ShowConsentMessagePipe implements PipeTransform {
    transform(messages: Message[] | null): boolean {
        // No messages yet, don't show
        if (!messages || messages.length === 0) {
            return false;
        }

        // Show if there is a queued message
        if (messages.filter(message => message.status === MessageStatus.queued).length > 0) {
            return true;
        }

        return false;
    }
}
