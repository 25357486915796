import { Pipe, PipeTransform } from '@angular/core';
import {TemplateType} from '../enums/template-type';
import { prettifyTemplateType } from '../helpers/prettify-template-type';

@Pipe({name: 'prettifyTemplateType'})
export class PrettifyTemplateTypePipe implements PipeTransform {
  transform(value: TemplateType): string {
    return prettifyTemplateType(value);
  }
}
