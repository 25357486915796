import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, takeWhile } from 'rxjs';
import { ProductRequest } from '../../../models/ProductRequest';
import { getCurrentPractice } from '../../../practices/state/selectors';
import { AppState } from '../../../state/reducers';
import {GetProductRequestsForList, OpenProductRequestDetail} from '../../state/actions';
import {
  getProductRequestsList, getProductRequestsListLoading,
  getProductRequestsListTotalItems,
  getUpdatingProductRequests
} from '../../state/selectors';
import { SortByOption } from '../../../interfaces/sort-by-option.interface';
import { FilterSelection } from '../../../interfaces/filter-selection.interface';
import { Practice } from '../../../models/Practice';
import { defaultProductRequestFilters } from '../../../constants/default-product-request-filters.constants';
import { productRequestSortByOptions } from '../../../constants/product-request-sort-by-options.constants';
import { EnvironmentService } from '../../../services/environment.service';
import {ActivatedRoute} from "@angular/router";
import { getFilterClients } from '../../../clients/state/selectors';
import { Client } from '../../../models/Client';
import { FilterOptionType } from '../../../enums/filter-option-type';

@Component({
  selector: 'product-request-list',
  templateUrl: './product-request-list.component.html',
  styleUrls: ['./product-request-list.component.scss']
})
export class ProductRequestListComponent implements OnInit, OnDestroy, OnChanges {
  @Input() searchString: string | null = '';
  alive = true;
  productRequests: ProductRequest[] = [];
  practice: Practice | null = null;
  filters: FilterSelection = {
    ...defaultProductRequestFilters
  };
  sortBy: SortByOption = productRequestSortByOptions[0];
  page = 1;
  perPage = this.environmentService.get('productRequestsPerPage');
  totalItems = 0;
  updatingProductRequestIds: number[] = [];
  openId: number | null = null;
  productRequestsLoading$?: Observable<boolean>;
  loading = true;
  nextPageTimeout: NodeJS.Timeout | undefined = undefined;
  debounceDuration: number = 300;
  clients: Client[] = [];
  clientIds: string[] | null = null;

  constructor(private store: Store<AppState>, private environmentService: EnvironmentService, private route: ActivatedRoute) {
    this.store.pipe(select(getProductRequestsList)).pipe(takeWhile(() => this.alive)).subscribe(productRequests => {
      this.productRequests = productRequests;
      this.openFromQueryParam();
    });

    this.store.pipe(select(getCurrentPractice)).pipe(takeWhile(() => this.alive)).subscribe(practice => {
      this.practice = practice;
      this.fetchProductRequests();
    });

    this.store.pipe(select(getProductRequestsListTotalItems)).pipe(takeWhile(() => this.alive)).subscribe(total => {
      this.totalItems = total;
    });
  }

  ngOnInit(): void {
    this.subscribeToRouteParams();
    this.subscribeToProductRequestsLoading();
    this.subscribeToProductRequestsUpdating();
    this.subscribeToQueryParams();
    this.subscribeToFilterClients();
  }

  subscribeToRouteParams(): void {
    this.route.queryParams
      .subscribe(params => {
        if (params.client) {
          this.clientIds = JSON.parse(decodeURIComponent(params.client));
        }
      });
  }

  subscribeToFilterClients(): void {
    this.store.pipe(select(getFilterClients)).pipe(
      takeWhile(() => this.alive)
    ).subscribe(clients => {
      this.clients = clients;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.fetchProductRequests();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  subscribeToProductRequestsLoading(): void {
    this.productRequestsLoading$ = this.store.select(getProductRequestsListLoading).pipe(
      takeWhile(() => this.alive)
    );

    this.productRequestsLoading$.subscribe((loading) => {
      this.loading = loading;
    });
  }

  subscribeToQueryParams(): void {
    this.route.queryParams
      .subscribe(params => {
          if (params.id) {
            this.openId = params.id;
            this.openFromQueryParam();
          }
        }
      );
  }

  private openFromQueryParam(): void {
    if (this.openId) {
      let toOpen: ProductRequest | undefined;

      const matchingRequest = this.productRequests.find(request => request.id === Number(this.openId));
      if (matchingRequest) {
        toOpen = matchingRequest;
      }

      if (toOpen) {
        this.store.dispatch(OpenProductRequestDetail({productRequest: toOpen}));
        this.openId = null;
      }
    }
  }

  subscribeToProductRequestsUpdating(): void {
    this.store.pipe(select(getUpdatingProductRequests))
      .pipe(takeWhile(() => this.alive))
      .subscribe((requests) => {
        this.updatingProductRequestIds = requests.map(request => request.id);
      });
  }

  handleSortUpdated(sort: SortByOption): void {
    this.sortBy = sort;
    this.fetchProductRequests();
  }

  handleFiltersUpdated(filters: FilterSelection): void {
    this.filters = filters;
    this.page = 1;
    this.fetchProductRequests();
  }

  fetchProductRequests(): void {
    if (this.practice) {
      if (this.clients && this.clients.length > 0) {
        this.filters = {
          ...this.filters,
          client: [{id: this.clients[0].id, label: this.clients[0].fullName,  type: FilterOptionType.CLIENT}]
        };
      }

      if (this.clientIds && this.clientIds.length > 0) {
        this.filters = {
          ...this.filters,
          client: this.clientIds
          ? this.clientIds.map(id => ({
              id: id.toString(), 
              label: `Client ${id}`, 
              type: FilterOptionType.CLIENT, 
            })) : []
        };
      }

      this.store.dispatch(GetProductRequestsForList({
        practiceId: this.practice.id,
        searchString: this.searchString,
        filters: this.filters,
        sortBy: this.sortBy,
        page: this.page
      }));
    }
  }

  showPreviousPageLink(): boolean {
    return this.page > 1;
  }

  showNextPageLink(): boolean {
    return this.totalItems > this.page * this.perPage;
  }

  debounceFetchProductRequests(): void {
    // Clear any existing debounce timeout
    if (this.nextPageTimeout) {
      clearTimeout(this.nextPageTimeout);
    }
  
    this.nextPageTimeout = setTimeout(() => {
      this.fetchProductRequests();
    }, this.debounceDuration);
  }
  
  previousPage(): void {
    this.page--;
    this.debounceFetchProductRequests();
  }
  
  nextPage(): void {
    this.page++;
    this.debounceFetchProductRequests();
  }
}
