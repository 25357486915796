export enum ProductRequestStatus {
  AWAITING_APPROVAL = 'awaiting-approval',
  READY_TO_PAY = 'ready-to-pay',
  AWAITING_PAYMENT = 'awaiting-payment',
  PAID = 'paid',
  FULFILLED = 'fulfilled',
  COMPLETE = 'complete',
  CANCELLED = 'cancelled',
  REJECTED = 'rejected'
}
