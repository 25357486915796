import {Template} from '../models/Template';
import {TemplateArchiveStatus} from '../enums/template-archive-status';
import {TemplateType} from '../enums/template-type';
import {TemplateStatus} from '../enums/template-status';

export function canTemplateBeArchived(template: Template): boolean {
  if (template.archived === TemplateArchiveStatus.ARCHIVED) {
    return false;
  }

  const alwaysAllowedTypes = [
    TemplateType.Standard,
    TemplateType.Campaign,
    TemplateType.CampaignWithPayment
  ];

  if (
    alwaysAllowedTypes.includes(template.type)
  ) {
    return true;
  }

  if (
    template.active === TemplateStatus.INACTIVE
  ) {
    // not a standard type - but not an active template
    return true;
  }

  return false;
}
