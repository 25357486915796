import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  isProduction(): boolean {
    return environment.production;
  }

  get(key: any): any {
    const keyParts = key.split('.');

    let envItem: any;
    envItem = environment;
    keyParts.forEach((keyPart: string) => {
      envItem = envItem[keyPart];
    });

    // @ts-ignore
    return envItem;
  }
}
