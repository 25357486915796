import { TranslateService } from "@ngx-translate/core";

export function secondsToStr(input: number, translateService: TranslateService): string {
  const days = Math.floor((input %= 31536000) / 86400);

  if (days === 1) {
    return translateService.instant('helpers.seconds_to_string.day')
  }
  else if (days > 1) {
    return translateService.instant('helpers.seconds_to_string.days', {count: days})
  }

  const hours = Math.floor((input %= 86400) / 3600);
  if (hours === 1) {
    return translateService.instant('helpers.seconds_to_string.hour')
  }
  else if (hours > 1) {
    return translateService.instant('helpers.seconds_to_string.hours', {count: hours})
  }

  const minutes = Math.floor((input %= 3600) / 60);
  if (minutes === 1) {
    return translateService.instant('helpers.seconds_to_string.minute')
  }
  else if (minutes > 1) {
    return translateService.instant('helpers.seconds_to_string.minutes', {count: minutes})
  }

  const seconds = Math.floor(input % 60);
  if (seconds === 1) {
    return translateService.instant('helpers.seconds_to_string.second')
  }
  else if (seconds > 1) {
    return translateService.instant('helpers.seconds_to_string.seconds', {count: seconds})
  }

  return translateService.instant('helpers.seconds_to_string.second')
}

