import * as actions from './actions';
import * as conversationActions from '../../conversation/state/actions';
import { Action, createReducer, on } from '@ngrx/store';
import { Conversation } from '../../models/Conversation';
import { tourConversations } from '../../constants/tour.constants';
import {ReadStatus} from "../../enums/read-status";

export interface ConversationsState {
  conversations: Conversation[];
  resolvedConversations: Conversation[];
  collapsableSectionsOpen: boolean[],
  maxOtherConversations: number
}

export const initialState: ConversationsState = {
    conversations: [],
    resolvedConversations: [],
    collapsableSectionsOpen: [true, true, true],
    maxOtherConversations: 10
};

const conversationsReducer = createReducer(
  initialState,
  on(actions.GetConversationsSuccess, (state, payload) => {
    const tourConversation = state.conversations.find((conversation) => conversation.id === '0');
    const conversations = [
      ...payload.conversations,
    ];

    if (tourConversation) {
      conversations.push(tourConversation);
    }

    return ({
      ...state,
      conversations
    });
  }),
  on(actions.LoadMoreOtherConversations, (state) => ({
    ...state,
    maxOtherConversations: state.maxOtherConversations + 10
  })),
  on(actions.GetResolvedConversationsSuccess, (state, payload) => ({
    ...state,
    resolvedConversations: payload.conversations
  })),
  on(actions.AddTourConversations, (state, payload) => ({
    ...state,
    conversations: [
      ...tourConversations
    ]
  })),
  on(actions.ClearConversations, (state, payload) => ({
    ...state,
    conversations: [],
    resolvedConversations: []
  })),
  on(conversationActions.MarkConversationsAsUnreadSuccess, (state, payload) => {
    return {
      ...state,
      conversations: [
        ...state.conversations.map((conversation) => {
          if (payload.conversations.find((payloadConversation) => payloadConversation.id === conversation.id)) {
            return {
              ...conversation,
              readStatus: ReadStatus.UNREAD,
            };
          } else {
            return conversation;
          }
        })
      ],
    };
  }),
  on(conversationActions.MarkConversationsAsReadSuccess, (state, payload) => {
    return {
      ...state,
      conversations: [
        ...state.conversations.map((conversation) => {
          if (payload.conversations.find((payloadConversation) => payloadConversation.id === conversation.id)) {
            return {
              ...conversation,
              readStatus: ReadStatus.READ,
            };
          } else {
            return conversation;
          }
        })
      ],
    };
  }),
  on(actions.ToggleCollapsableSection, (state, payload) => {
    return {
      ...state,
      collapsableSectionsOpen: state.collapsableSectionsOpen.map((section, index) => {
        if (payload.index === index) {
          return !section;
        }
        return section;
      })
    };
  }),
);

export function reducer(state: ConversationsState | undefined, action: Action): ConversationsState {
  return conversationsReducer(state, action);
}
