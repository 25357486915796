<div class="resolve-holder" (clickOutside)="closeResolveActions()">
  <ng-container *ngIf="resolveActions.length == 0">
    <p-button [label]="'ui.conversation_resolver.resolve' | translate" (onClick)="resolve()" iconPos="right" [disabled]="disabled" [icon]="showingResolveActions ? 'pi pi-chevron-up' : 'pi pi-chevron-down'" [styleClass]="buttonClass"></p-button>
  </ng-container>
  <ng-container *ngIf="resolveActions.length > 0">
    <p-button [label]="'ui.conversation_resolver.resolve' | translate" (onClick)="toggleResolveActions()" iconPos="right" [disabled]="disabled" [icon]="showingResolveActions ? 'pi pi-chevron-up' : 'pi pi-chevron-down'" [styleClass]="buttonClass"></p-button>
    <ng-container *ngIf="showingResolveActions">
      <div class="resolve-actions">
        <ng-container *ngFor="let resolveAction of resolveActions">
          <div class="resolve-action" (click)="resolveAction.command()">{{ resolveAction.labelKey | translate: {pms: currentPractice?.pms} }}</div>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</div>

<p-dialog
  [(visible)]="confirmResolveOpen"
  [modal]="true"
  [style]="{width: '450px'}"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
>
  <p-header>
    <div class="dialog-header-notification">
      {{'ui.conversation_resolver.queued_messages' | translate}}
    </div>
  </p-header>
  <div class="dialog-content">
  <p>
    {{'ui.conversation_resolver.queued_messages_not_saved' | translate}}
  </p>
    <div class="btn-row">
      <div class="buttons-left">
        <p-button [label]="'ui.conversation_resolver.cancel' | translate" (click)="cancelResolve()" styleClass="p-button-secondary"></p-button>
      </div>
      <div class="buttons-right">
        <p-button [label]="'ui.conversation_resolver.resolve_anyway' | translate" (click)="confirmResolve(sendToPms, downloadPdf, sendTextToPms)" styleClass="p-button-primary"></p-button>
      </div>
    </div>
  </div>
</p-dialog>
