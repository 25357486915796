<div *ngIf="hasMessagingEnable">
  <div *ngIf="hasUsableChannel" class="outer-row">
    <div class="left" [class.hide]="previewOpen">
      <div class="step step-1" [class.active]="step === 1">
        <div class="input-row">
          <p-dropdown [options]="channels" [(ngModel)]="selectedChannel" optionLabel="name" optionDisabled="inactive"
            class="channel-dropdown" [style]="{ width: '100%' }">
            <ng-template pTemplate="selectedItem">
              <div class="channel-item channel-item-selected" *ngIf="selectedChannel">
                <img [src]="'assets/images/icons/' + selectedChannel.icon" class="channel-icon" />
                <div>{{ selectedChannel.name }}</div>
              </div>
            </ng-template>
            <ng-template let-channel pTemplate="item">
              <div class="channel-item">
                <img [src]="'assets/images/icons/' + channel.icon" class="channel-icon" />
                <div>{{ channel.name }}</div>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
        <form [formGroup]="stepOneForm">
          <div class="input-row">
            <input pInputText type="text" formControlName="name" placeholder="Broadcast Name*" #firstInput />
            <ng-container *ngIf="
                stepOneForm.controls['name'].invalid &&
                (stepOneForm.controls['name'].dirty ||
                  stepOneForm.controls['name'].touched)
              ">
              <div class="error">You must enter a name for this broadcast</div>
            </ng-container>
          </div>
          <div class="input-row">
            <p-calendar formControlName="sendAt" placeholder="Send Date*" [touchUI]="true" [showTime]="false"
              [minDate]="minDate" dateFormat="dd/mm/yy"></p-calendar>
            <ng-container *ngIf="
                stepOneForm.controls['sendAt'].invalid &&
                (stepOneForm.controls['sendAt'].dirty ||
                  stepOneForm.controls['sendAt'].touched)
              ">
              <div class="error">You must a date to send this broadcast</div>
            </ng-container>
          </div>
          <div class="input-row">
            <p-calendar formControlName="sendAtTime" placeholder="Send Time*" [touchUI]="true" [timeOnly]="true"
              [showTime]="true"></p-calendar>
            <ng-container *ngIf="
                stepOneForm.controls['sendAtTime'].invalid &&
                (stepOneForm.controls['sendAtTime'].dirty ||
                  stepOneForm.controls['sendAtTime'].touched)
              ">
              <div class="error">
                You must enter a time to send this broadcast
              </div>
            </ng-container>
          </div>
          <div class="input-row">
            <select class="primary" formControlName="message" (change)="updateTemplatePreview()">
              <ng-container *ngFor="let template of templates">
                <option [value]="template.id">{{ template.name }}</option>
              </ng-container>
            </select>
            <ng-container *ngIf="templates.length === 0">
              <div class="error">
                You need to create an approved template before sending a broadcast
              </div>
            </ng-container>
          </div>
          <ng-container *ngIf="
              currentTemplate &&
              currentTemplate.attachMedia &&
              currentTemplate.mediaType
            ">
            <div class="input-row">
              <div class="media-row">
                <p-button label="Select media" (onClick)="selectMedia()" [styleClass]="buttonClass" iconPos="left" [icon]="
                    currentTemplate.mediaType === mediaType.Image
                      ? 'pi pi-image'
                      : currentTemplate.mediaType === mediaType.Video
                      ? 'pi pi-video'
                      : 'pi pi-file-pdf'
                  "></p-button>
                <span class="p-button-label">
                  {{ !previewSrc ? "No media selected" : mediaName }}
                  <ng-container *ngIf="previewSrc">
                    <i class="pi pi-times" (click)="removeMedia()"></i>
                  </ng-container>
                </span>
              </div>
              <ng-container *ngIf="mediaError">
                <div class="error">{{ mediaError }}</div>
              </ng-container>
            </div>
          </ng-container>
          <div class="daily-limit-message">
            WhatsApp broadcasts to over {{ recipientLimit }} numbers will be automatically split over several days.
          </div>
          <div class="daily-limit-message">
            Please note: you can only have one active broadcast in a given day including those split from previous days. We will automatically choose the next clear day for you.
          </div>
          <div class="only-weekdays">
            <label for="weekdays-only"><input type="checkbox" id="weekdays-only" formControlName="weekdaysOnly" />
              Only schedule on weekdays</label>
          </div>
          <div class="btn-row">
            <div class="buttons-left"></div>
            <div class="buttons-right">
              <ng-container *ngTemplateOutlet="previewButtons"></ng-container>
              <p-button (click)="goToStep(2)" label="Next Step" styleClass="p-button-primary"></p-button>
            </div>
          </div>
        </form>
      </div>
      <div class="step step-2" [class.active]="step === 2">
        <form [formGroup]="stepTwoForm">
          <div class="row">
            <div class="label">Import a CSV of recipients</div>
            <ng-container *ngIf="currentTemplateFieldsString.length > 0">
              <div class="sub-label">
                This template requires the following fields:
                <i>{{ currentTemplateFieldsString }}</i>
              </div>
            </ng-container>
            <p-button (click)="openFileBrowser()" label="Browse" styleClass="p-button-primary"></p-button>
          </div>
          <ng-container *ngIf="importedFilename">
            <div class="row">
              <strong>File uploaded:</strong> {{ importedFilename }}
            </div>
          </ng-container>
          <ng-container *ngIf="fileUploaded && importedData.length === 0">
            <div class="row">
              <div class="error">There was an error processing your CSV</div>
            </div>
          </ng-container>
          <ng-container *ngIf="!fileUploaded && importedData.length === 0 && showMissingCsv">
            <div class="row">
              <div class="error">You need to upload a CSV</div>
            </div>
          </ng-container>
          <div class="row">
            <label for="import-column-headers"><input type="checkbox" id="import-column-headers"
                formControlName="includesColumnHeaders" />
              CSV includes column headers in first row</label>
          </div>
          <div class="input-row">
            <label for="permission"><input type="checkbox" id="permission" formControlName="permission" />
              I confirm that the recipients are existing active clients and that 
              recipients who have  opted-out of marketing have been removed 
              (if your broadcast contains  marketing content).</label>
            <ng-container *ngIf="
                stepTwoForm.controls['permission'].invalid &&
                (stepTwoForm.controls['permission'].dirty ||
                  stepTwoForm.controls['permission'].touched)
              ">
              <div class="error">You must confirm the tick box above</div>
            </ng-container>
          </div>
          <ng-container *ngIf="
              currentTemplate &&
              currentTemplate.type === templateType.CampaignWithPayment
            ">
            <div class="warning">
              <div class="heading">Warning!</div>
              <div class="text">
                Payments may be associated with the client IDs provided in the CSV
                within your PMS. Please ensure that client IDs are correct for
                each row before proceeding.
              </div>
            </div>
          </ng-container>
          <div class="hidden">
            <input type="file" name="recipientsCsv" #recipientsCsv accept=".csv, text/csv" />
          </div>
        </form>
        <div class="btn-row">
          <div class="buttons-left">
            <p-button (click)="goToPreviousStep()" label="Back" styleClass="p-button-fourth"></p-button>
          </div>
          <div class="buttons-right">
            <ng-container *ngTemplateOutlet="previewButtons"></ng-container>
            <p-button (click)="goToStep(3)" label="Next Step" styleClass="p-button-primary"></p-button>
          </div>
        </div>
      </div>
      <div class="step step-3" [class.active]="step === 3">
        <ng-container *ngIf="step3Loading">
          <div class="loading">
            <p-progressSpinner [style]="{width: '40px', height: '40px'}" styleClass="custom-spinner" strokeWidth="4"></p-progressSpinner>
          </div>
        </ng-container>
        <ng-container *ngIf="!step3Loading">
          <ng-container *ngIf="importedData.length > 0">
            <div class="field-mapping-section">
              <ng-container *ngIf="!mappedFieldsValid">
                <div class="row">
                  <div class="error">
                    All fields must be mapped before the broadcast can be scheduled
                  </div>
                </div>
              </ng-container>
              <div class="label">Map the imported data for each field</div>
              <form [formGroup]="stepThreeForm">
                <div class="field-map-row">
                  <div class="field">Phone number</div>
                  <div class="value">
                    <select class="primary" formControlName="phone">
                      <option value="">Please select...</option>
                      <ng-container *ngFor="
                        let item of importedData[0];
                        let columnIndex = index
                      ">
                        <option [value]="columnIndex">
                          {{
                          item
                            | columnMappingName
                            : columnIndex
                              : (stepTwoForm.controls.includesColumnHeaders.value
                              ? undefined
                              : "e.g.")
                          }}
                        </option>
                      </ng-container>
                    </select>
                  </div>
                </div>
                <ng-container *ngIf="
                  currentTemplate &&
                  currentTemplate.type === templateType.CampaignWithPayment
                ">
                  <ng-container *ngIf="sites && sites.length > 1">
                    <div class="field-map-row">
                      <div class="field">Site</div>
                      <div class="value">
                        <select class="primary" formControlName="site">
                          <ng-container *ngFor="let site of sites">
                            <option value="{{ site.id }}">{{ site.name }}</option>
                          </ng-container>
                        </select>
                      </div>
                    </div>
                  </ng-container>
                  <div class="field-map-row">
                    <div class="field">Description</div>
                    <div class="value">
                      <select class="primary" formControlName="description">
                        <option value="">Please select...</option>
                        <option value="fixed">Fixed value</option>
                        <ng-container *ngFor="
                          let item of importedData[0];
                          let columnIndex = index
                        ">
                          <option [value]="columnIndex">
                            {{
                            item
                              | columnMappingName
                              : columnIndex
                                : (stepTwoForm.controls.includesColumnHeaders
                                .value
                                ? undefined
                                : "e.g.")
                            }}
                          </option>
                        </ng-container>
                      </select>
                      <ng-container *ngIf="stepThreeForm.get('description')?.value === 'fixed'">
                        <div class="fixed-value-holder">
                          <input pInputText type="text" formControlName="descriptionFixed"
                                 placeholder="Enter description" />
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <div class="field-map-row">
                    <div class="field">Client ID</div>
                    <div class="value">
                      <select class="primary" formControlName="clientId">
                        <option value="">Please select...</option>
                        <ng-container *ngFor="
                          let item of importedData[0];
                          let columnIndex = index
                        ">
                          <option [value]="columnIndex">
                            {{
                            item
                              | columnMappingName
                              : columnIndex
                                : (stepTwoForm.controls.includesColumnHeaders
                                .value
                                ? undefined
                                : "e.g.")
                            }}
                          </option>
                        </ng-container>
                      </select>
                    </div>
                  </div>
                  <div class="field-map-row">
                    <div class="field">Amount to Pay</div>
                    <div class="value">
                      <select class="primary" formControlName="paymentAmount" (change)="validatePaymentAmountSelection()">
                        <option value="">Please select...</option>
                        <option value="fixed">Fixed value</option>
                        <ng-container *ngFor="
                          let item of importedData[0];
                          let columnIndex = index
                        ">
                          <option [value]="columnIndex">
                            {{
                            item
                              | columnMappingName
                              : columnIndex
                                : (stepTwoForm.controls.includesColumnHeaders
                                .value
                                ? undefined
                                : "e.g.")
                            }}
                          </option>
                        </ng-container>
                      </select>
                      <ng-container *ngIf="
                        stepThreeForm.get('paymentAmount')?.value === 'fixed'
                      ">
                        <div class="fixed-value-holder">
                          <input pInputText type="text" formControlName="paymentAmountFixed"
                                 placeholder="Enter fixed value... (e.g. 10.00)" (keyup)="validatePaymentAmountSelection()" />
                        </div>
                      </ng-container>
                    </div>
                    <ng-container *ngIf="amountNumericError">
                      <div class="field-error">Payment amount must be numeric.</div>
                    </ng-container>
                  </div>
                </ng-container>
                <ng-container *ngIf="currentTemplate">
                  <div formArrayName="mappedFields">
                    <ng-container *ngFor="
                      let mappedField of mappedFields()?.controls;
                      let i = index
                    " [formGroupName]="i">
                      <div class="field-map-row">
                        <div class="field">
                          {{
                          mappedField.value.customName ??
                          mappedField.value.content | prettifyTemplateContent
                          }}
                        </div>
                        <div class="value">
                          <select formControlName="mappedColumn" class="primary" (ngModelChange)="updateMapping()" [ngClass]="{'mapped-error': !mappedFieldsValid}">
                            <option value="">Please select...</option>
                            <option value="fixed">Fixed value</option>
                            <ng-container *ngFor="
                              let item of importedData[0];
                              let columnIndex = index
                            ">
                              <option [value]="columnIndex">
                                {{
                                item
                                  | columnMappingName
                                  : columnIndex
                                    : (stepTwoForm.controls.includesColumnHeaders
                                    .value
                                    ? undefined
                                    : "e.g.")
                                }}
                              </option>
                            </ng-container>
                          </select>
                          <ng-container *ngIf="mappedField.value.mappedColumn === 'fixed'">
                            <div class="fixed-value-holder">
                              <input pInputText type="text" formControlName="fixedValue" placeholder="Enter fixed value..."
                                     (ngModelChange)="updateMapping()" />
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
              </form>
            </div>
          </ng-container>
          <div class="btn-row">
            <div class="buttons-left">
              <p-button (click)="goToPreviousStep()" label="Back" styleClass="p-button-fourth"></p-button>
            </div>
            <div class="buttons-right">
              <ng-container *ngTemplateOutlet="previewButtons"></ng-container>
              <p-button (click)="submitDraft()" label="Save Draft" styleClass="p-button-fourth"></p-button>
              <p-button (click)="submit()" label="Schedule" styleClass="p-button-primary"></p-button>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="step step-4" [class.active]="step === 4">
        <div class="create-success-title">
          Successfully created
          {{ createdCampaigns.length }} broadcast<ng-container *ngIf="createdCampaigns.length > 1">s</ng-container>
        </div>
        <ng-container *ngIf="
            optedOutUsers.length > 0 ||
            dataErrors.length > 0 ||
            invalidNumbers.length > 0 ||
            duplicates.length > 0
          ">
          <div class="warning">
            <div class="heading">Warning:</div>
            <div class="text">
              <ng-container *ngIf="dataErrors.length > 0">
                <ng-container *ngFor="let dataError of dataErrors">
                  <ng-container *ngIf="dataError.errors === 1">
                    <div class="warning-label">
                      {{ dataError.name | prettifyTemplateContent }} was missing
                      or incorrect in 1 row
                    </div>
                  </ng-container>
                  <ng-container *ngIf="dataError.errors > 1">
                    <div class="warning-label">
                      {{ dataError.name | prettifyTemplateContent }} was missing
                      or incorrect in {{ dataError.errors }} rows
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="optedOutUsers.length > 0">
                <ng-container *ngIf="optedOutUsers.length === 1">
                  <div class="warning-label">
                    1 recipient has opted out of marketing and won't receive a
                    message
                  </div>
                </ng-container>
                <ng-container *ngIf="optedOutUsers.length > 1">
                  <div class="warning-label">
                    {{ optedOutUsers.length }} recipients have opted out of
                    marketing and won't receive a message
                  </div>
                </ng-container>
                <ng-container *ngFor="let user of optedOutUsers">
                  <div class="warning-line">{{ user.phone }}</div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="invalidNumbers.length > 0">
                <ng-container *ngIf="invalidNumbers.length === 1">
                  <div class="warning-label">
                    1 phone number won't receive a message due to invalid data
                  </div>
                </ng-container>
                <ng-container *ngIf="invalidNumbers.length > 1">
                  <div class="warning-label">
                    {{ invalidNumbers.length }} phone numbers won't receive a
                    message due to invalid data
                  </div>
                </ng-container>
                <ng-container *ngFor="let invalidNumber of invalidNumbers">
                  <div class="warning-line">{{ invalidNumber }}</div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="duplicates.length > 0">
                <ng-container *ngIf="duplicates.length === 1">
                  <div class="warning-label">
                    1 phone number was duplicated and has been included only once
                  </div>
                </ng-container>
                <ng-container *ngIf="duplicates.length > 1">
                  <div class="warning-label">
                    {{ duplicates.length }} phone numbers were duplicated and have
                    been included only once
                  </div>
                </ng-container>
                <ng-container *ngFor="let duplicate of duplicates">
                  <div class="warning-line">{{ duplicate }}</div>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </ng-container>
        <div class="btn-row">
          <div class="buttons-left"></div>
          <div class="buttons-right">
            <p-button (click)="close()" label="Finish" styleClass="p-button-primary"></p-button>
          </div>
        </div>
      </div>
    </div>
    <div class="right" [class.show]="previewOpen">
      <div class="preview-title">
        Broadcast preview
        <ng-container *ngIf="device === 'mobile' && previewOpen == true">
          <div class="preview-link" (click)="togglePreview()">Close Preview</div>
        </ng-container>
      </div>
      <ng-container *ngIf="messagePreviewText && currentTemplate">
        <div class="message-preview-wrap">
          <div class="message-preview">
            <ng-container *ngIf="previewType === 'pdf'">
              <div class="pdf-preview">
                <i class="pi pi-file-pdf"></i><span class="link-text">{{ mediaName || 'Download PDF' }}</span>
              </div>
            </ng-container>
            <ng-container *ngIf="previewType === 'image' && previewSrc">
              <div class="image-preview">
                <img [src]="previewSrc" />
              </div>
            </ng-container>
            <ng-container *ngIf="previewType === 'video' && previewSrc">
              <div class="video-preview">
                <video [controls]="false" preload="metadata">
                  <source [src]="previewSrc" />
                </video>
              </div>
            </ng-container>
            <p>
              {{ messagePreviewText }}
            </p>
          </div>
          <ng-container *ngIf="currentTemplate.paymentButton">
            <div class="message-button">
              <i class="pi pi-external-link"></i>{{ currentTemplate.paymentButtonText }}
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
  <span *ngIf="!hasUsableChannel">Your practice has not been configured to send broadcasts, please <a href="mailto:support@vethelpdirect.com">contact support</a> to resolve this issue.</span>
</div>
<ng-template #previewButtons>
  <ng-container *ngIf="!previewOpen">
    <div class="preview-button">
      <p-button (click)="showPreview()" label="Show Preview" styleClass="p-button-fourth"></p-button>
    </div>
  </ng-container>
</ng-template>
