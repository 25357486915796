import { Injectable } from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {Payment} from '../models/Payment';
import {PaymentDto} from '../interfaces/dto/payment.dto';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {PaymentAdapter} from '../adapters/payment.adapter';
import {EnvironmentService} from '../services/environment.service';
import {HttpClient} from '@angular/common/http';
import { PaymentFilterDto } from '../interfaces/dto/payment-filter.dto';
import { Practice } from '../models/Practice';
import { ClientAdapter } from '../adapters/client.adapter';
import { Channel } from '../enums/channel';
import {Client} from '../models/Client';
import {Contact} from '../models/Contact';
import { PaymentRequest } from 'src/app/interfaces/payment-request';
import { CashUpFilters } from '../interfaces/cash-up-filters.interface';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  constructor(
    private environmentService: EnvironmentService,
    private http: HttpClient,
    private paymentAdapter: PaymentAdapter,
    private clientAdapter: ClientAdapter,
    private translateService: TranslateService,
  ) { }

  createPayment(
    description: string,
    message: string,
    amount: number,
    expiresAfter: number,
    conversationId: string,
    clientId: string,
    patientId: string | undefined,
    practiceId: string | undefined,
    siteId: number,
    sendPaymentLink: boolean = true,
    authOnly = false,
    paymentReminderEnabled? : boolean,
    paymentInvoice?: string | null,
    paymentInvoiceNumber?: string | null
  ): Observable<Payment> {
    const url = this.environmentService.get('backendUrl') + `/payments`;
    return this.http.post<PaymentDto>(url, {
      description,
      message,
      amount,
      conversationId,
      clientId,
      patientId,
      practiceId,
      siteId,
      sendPaymentLink,
      authOnly,
      expiresAfter,
      paymentReminderEnabled,
      invoiceNumber: paymentInvoiceNumber,
      invoiceId: paymentInvoice,
    }, {withCredentials: true}).pipe(
      map((response: PaymentDto) => {
        const responseClient = response.client ? this.clientAdapter.run(response.client) : undefined;
        return this.paymentAdapter.run(response, responseClient);
      })
    );
  }

  getPayments(filters: PaymentFilterDto, practice: Practice | null): Observable<{ payments: Payment[], total: number }> {
    if (!practice) {
      return of({payments: [], total: 0});
    }

    const url = this.environmentService.get('backendUrl') + `/search/payments`;

    return this.http.post<{ payments: PaymentDto[], total: number }>(url, {
      filters,
      practiceId: practice.id
    }, {withCredentials: true}).pipe(
      map((response: { payments: PaymentDto[], total: number }) => {
        const payments = response.payments.map((payment) => {
          const client = payment.client ? this.clientAdapter.run(payment.client) : undefined;
          return this.paymentAdapter.run(payment, client);
        });

        return { payments, total: response.total };
      })
    );
  }

  getBaseUrl(practice: Practice ): Observable<{ base_url : string }> {
    const url = this.environmentService.get('backendUrl') + `/payments/${practice.id}/base-url`;
    return this.http.get<{ base_url: string }>(url, {withCredentials: true}).pipe(
      map((response: { base_url: string,  }) => {
        return response;
      })
    );
  }

  resendPayment(payment: Payment, practice: Practice | null, channel?: Channel): Observable<void> {
    if (!practice) {
      return of();
    }

    let url = this.environmentService.get('backendUrl') + `/practices/${practice.id}/payments/${payment.id}/resend`;

    if (channel) {
      url += `?channel=${channel}`;
    }

    return this.http.get<Response>(url, {withCredentials: true}).pipe(
      map(() => {
        return;
      })
    );
  }

  refundPayment(payment: Payment, practice: Practice | null): Observable<void> {
    if (!practice) {
      return of();
    }

    const url = this.environmentService.get('backendUrl') + `/practices/${practice.id}/payments/${payment.id}/refund`;
    return this.http.get<Response>(url, {withCredentials: true}).pipe(
      map(() => {
        return;
      })
    );
  }

  cancelPayments(payments: Payment[], practice: Practice | null): Observable<void> {
    if (!practice) {
      return of();
    }

    const url = this.environmentService.get('backendUrl') + `/practices/${practice.id}/payments/cancel`;

    return this.http.post<Response>(url, payments.map((payment) => Number(payment.id)), {withCredentials: true}).pipe(
      map(() => {
        return;
      })
    );
  }

  removePayments(payments: Payment[], practice: Practice | null): Observable<void> {
    if (!practice) {
      return of();
    }

    const url = this.environmentService.get('backendUrl') + `/practices/${practice.id}/payments/remove`;

    return this.http.post<Response>(url, payments.map((payment) => payment.id), {withCredentials: true}).pipe(
      map(() => {
        return;
      })
    );
  }

  retrySavePayment(payment: Payment, practice: Practice | null): Observable<Payment>  {
    if (!practice) {
      return of();
    }

    const url = this.environmentService.get('backendUrl') + `/practices/${practice.id}/payments/${payment.id}/save-to-pms`;

    return this.http.get<PaymentDto>(url, {withCredentials: true}).pipe(
      map((response) => {
          return this.paymentAdapter.run(response);
      }),
      catchError((e) => {
        throw new Error(e);
      })
    );
  }

  markAsComplete(payments: Payment[], practice: Practice | null): Observable<void> {
    if (!practice) {
      return of();
    }

    const url = this.environmentService.get('backendUrl') + `/practices/${practice.id}/payments/mark-complete`;

    return this.http.post<Response>(url, {
      payments: payments.map((payment) => payment.id)
    }, {withCredentials: true}).pipe(
      map(() => {
        return;
      }),
      catchError((e) => {
        throw new Error(e);
      })
    );
  }

  capturePayments(payments: Payment[], practice: Practice | null): Observable<void> {
    if (!practice) {
      return of();
    }

    const url = this.environmentService.get('backendUrl') + `/practices/${practice.id}/payments/capture`;

    return this.http.post<Response>(url, {
      payments: payments.map((payment) => Number(payment.id))
    }, {withCredentials: true}).pipe(
      map(() => {
        return;
      }),
      catchError((e) => {
        throw new Error(e);
      })
    );
  }

  createPaymentFromClient(request: PaymentRequest, client: Client, contact: Contact | null, channel: Channel, practice: Practice | null): Observable<Payment> {
    if (!practice) {
      return this.translateService.get('payments.service.could_not_create_payment').pipe(
        switchMap((translatedMessage: string) => throwError(() => new Error(translatedMessage)))
      );
    }

    const url = this.environmentService.get('backendUrl') + `/payments/createFromClient`;

    return this.http.post<PaymentDto>(url, {
      practiceId: practice.id,
      description: request.description,
      message: request.message,
      amount: request.amount,
      siteId: request.siteId,
      expiresAfter: request.expiresAfter,
      invoiceId: request?.paymentInvoice ? request.paymentInvoice : null,
      invoiceNumber: request?.paymentInvoiceNumber ? request.paymentInvoiceNumber : null,
      client,
      contact,
      channel,
      paymentReminderEnabled: request.paymentReminderEnabled,
      authOnly: request.authOnly
    }, {withCredentials: true}).pipe(
      map((response: PaymentDto) => {
        const responseClient = response.client ? this.clientAdapter.run(response.client) : undefined;
        return this.paymentAdapter.run(response, responseClient);
      })
    );
  }

  cashUp(filters: CashUpFilters, practice: Practice | null): Observable<Payment[]> {
    if (!practice) {
      return of();
    }

    const url = this.environmentService.get('backendUrl') + `/practices/${practice.id}/payments/cash-up`;

    return this.http.post<PaymentDto[]>(url, {
      filters
    }, {withCredentials: true}).pipe(
      map((response: PaymentDto[]) => {
        return response.map((res) => {
          const responseClient = res.client ? this.clientAdapter.run(res.client) : undefined;
          return this.paymentAdapter.run(res, responseClient);
        })
      }),
      catchError((e) => {
        throw new Error(e);
      })
    );
  }
}
