import { ProductRequestApprovalStatus } from "../enums/product-request-approval-status";

export function mapProductRequestApprovalStatus(status: string): ProductRequestApprovalStatus {
    switch (status) {
        case 'APPROVED':
            return ProductRequestApprovalStatus.APPROVED;
        case 'REJECTED':
            return ProductRequestApprovalStatus.REJECTED;
        case 'PENDING':
            return ProductRequestApprovalStatus.PENDING;
        case 'NOT_REQUIRED':
            return ProductRequestApprovalStatus.NOT_REQUIRED;
    }

    return ProductRequestApprovalStatus.PENDING;
}