import { Component, Input, OnInit } from '@angular/core';
import { Tag } from '../../../models/Tag';

@Component({
  selector: 'conversation-tags',
  templateUrl: './conversation-tags.component.html',
  styleUrls: ['./conversation-tags.component.scss']
})
export class ConversationTagsComponent implements OnInit {
  @Input() tags: Tag[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
