import * as actions from './actions';
import { Action, createReducer, on } from '@ngrx/store';
import { Campaign } from 'src/app/models/Campaign';
import {User} from '../../models/User';

export interface CampaignState {
  campaigns: Campaign[];
  newCampaignOpen: boolean;
  createdCampaigns: Campaign[] | null;
  invalidNumbers: string[];
  optedOutUsers: User[];
  duplicates: string[];
  dataErrors: {name: string, errors: number}[];
}

export const initialState: CampaignState = {
  campaigns: [],
  newCampaignOpen: false,
  createdCampaigns: null,
  invalidNumbers: [],
  optedOutUsers: [],
  duplicates: [],
  dataErrors: [],
};


const campaignReducer = createReducer(
  initialState,
  on(actions.GetCampaignsSuccess, (state, payload) => ({
    ...state,
    campaigns: payload.campaigns
  })),
  on(actions.AddCampaignSuccess, (state, payload) => ({
    ...state,
    campaigns: [
      ...payload.campaigns,
      ...state.campaigns
    ],
    createdCampaigns: payload.campaigns,
    invalidNumbers: payload.invalidNumbers,
    optedOutUsers: payload.optedOutUsers,
    duplicates: payload.duplicates,
    dataErrors: payload.dataErrors,
  })),
  on(actions.ClearCreatedCampaigns, (state, payload) => ({
    ...state,
    createdCampaigns: null,
    invalidNumbers: [],
    optedOutUsers: [],
    duplicates: [],
    dataErrors: [],
  })),
  on(actions.EditCampaignSuccess, (state, payload) => ({
    ...state,
    campaigns: [
      ...payload.campaigns,
      ...state.campaigns.filter(c => c.id.toString() !== payload.originalCampaignId)
    ]
  })),
  on(actions.DeleteCampaignSuccess, (state, payload) => ({
    ...state,
    campaigns: [
      ...state.campaigns.filter(campaign => campaign.id !== payload.campaignId)
    ]
  })),
  on(actions.OpenNewCampaign, (state, payload) => ({
    ...state,
    newCampaignOpen: true,
  })),
  on(actions.CloseNewCampaign, (state, payload) => ({
    ...state,
    newCampaignOpen: false,
  })),
);

export function reducer(state: CampaignState | undefined, action: Action): CampaignState {
  return campaignReducer(state, action);
}
