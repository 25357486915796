import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PracticeAdapter } from '../adapters/practice.adapter';
import { UserAdapter } from '../adapters/user.adapter';
import { PracticeDto } from '../interfaces/dto/practice.dto';
import { UserDto } from '../interfaces/dto/user.dto';
import { Practice } from '../models/Practice';
import { User } from '../models/User';
import { EnvironmentService } from '../services/environment.service';
import {GroupDto} from '../interfaces/dto/group.dto';
import {GroupAdapter} from '../adapters/group.adapter';
import {Group} from '../models/Group';
import {Template} from "../interfaces/template";
import {TemplateDto} from "../interfaces/dto/template.dto";
import {TemplateAdapter} from "../adapters/template.adapter";
import { PracticeConfig } from '../enums/practice-config';
import { PracticeConfigInterface } from '../interfaces/practice-config.interface';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private environmentService: EnvironmentService,
    private http: HttpClient,
    private userAdapter: UserAdapter,
    private practiceAdapter: PracticeAdapter,
    private groupAdapter: GroupAdapter,
    private templateAdapter: TemplateAdapter,
  ) { }

  getPracticeStaff(practiceId: string): Observable<User[]> {
    const url = this.environmentService.get('backendUrl') + `/practices/${practiceId}/staff`;
    return this.http.get<UserDto[]>(url, {
      withCredentials: true
    }).pipe(
      map((response: UserDto[]) => {
        return response.map(user => this.userAdapter.run(user));
      })
    );
  }

  getPracticeGroups(practiceId: string): Observable<Group[]> {
    const url = this.environmentService.get('backendUrl') + `/practices/${practiceId}/groups`;
    return this.http.get<GroupDto[]>(url, {
      withCredentials: true
    }).pipe(
      map((response: GroupDto[]) => {
        return response.map(group => this.groupAdapter.run(group));
      })
    );
  }

  getPracticeConfig(practiceId: string): Observable<PracticeConfigInterface> {
    const url = this.environmentService.get('backendUrl') + `/practices/${practiceId}/config`;
    return this.http.get<PracticeConfigInterface>(url, {
      withCredentials: true
    }).pipe(
      map((response: PracticeConfigInterface) => {
        return response;
      })
    );
  }

  getUsers(): Observable<User[]> {
    const url = this.environmentService.get('backendUrl') + `/users`;
    return this.http.get<UserDto[]>(url, {
      withCredentials: true
    }).pipe(
      map((response: UserDto[]) => {
        return response.map(user => this.userAdapter.run(user));
      })
    );
  }

  update(user: User): Observable<User> {
    const url = this.environmentService.get('backendUrl') + `/users/${user.id}`;
    return this.http.patch<UserDto>(url, {
      firstName: user.firstName,
      lastName: user.lastName
    }, {
      withCredentials: true
    }).pipe(
      map((response: UserDto) => {
        return this.userAdapter.run(response);
      })
    );
  }

  getUserPractices(): Observable<Practice[]> {
    const url = this.environmentService.get('backendUrl') + `/auth/user/practices`;
    return this.http.get<PracticeDto[]>(url, {
      withCredentials: true
    }).pipe(
      map((response: PracticeDto[]) => {
        return response.map(practice => this.practiceAdapter.run(practice));
      })
    );
  }

  completeTour(user: User): Observable<User> {
    const url = this.environmentService.get('backendUrl') + `/users/${user.id}`;
    return this.http.patch<UserDto>(url, {
      tourComplete: 1
    }, {
      withCredentials: true
    }).pipe(
      map((response: UserDto) => {
        return this.userAdapter.run(response);
      })
    );
  }

  getPracticeTemplates(practiceId: string): Observable<Template[]> {
    const url = this.environmentService.get('backendUrl') + `/practices/${practiceId}/templates`;
    return this.http.get<TemplateDto[]>(url, {
      withCredentials: true
    }).pipe(
      map((response: TemplateDto[]) => {
        return response.map((tpl) => this.templateAdapter.run(tpl));
      })
    );
  }
}
