import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HistoryItem } from 'src/app/models/HistoryItem';
import { Patient } from 'src/app/models/Patient';

@Component({
  selector: 'patient-history',
  templateUrl: './patient-history.component.html',
  styleUrls: ['./patient-history.component.scss']
})
export class PatientHistoryComponent implements OnInit {
  @Input() patient?: Patient;
  @Input() loading = true;
  @Input() patientHistory: HistoryItem[] = [];
  @Input() disabled: boolean = true;
  @Output() patientHistoryOpened = new EventEmitter<string>();
  modalActive = false;

  constructor() { }

  ngOnInit(): void {
  }

  showModal(): void {
    if (this.patient) {
      this.patientHistoryOpened.emit(this.patient.id);
    }
    
    this.modalActive = true;
  }

  hideModal(): void {
    this.modalActive = false;
  }
}
