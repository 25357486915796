
import { Action, createReducer, on } from '@ngrx/store';
import * as actions from './actions';
import {Client} from '../../models/Client';
import {Contact} from '../../models/Contact';
import {Channel} from '../../enums/channel';
import {Form} from '../interfaces/form';
import {Patient} from '../../models/Patient';
import {CreateFormSubmission, GetFormSubmissions, GetFormSubmissionsFailed, GetFormSubmissionsSuccess} from "./actions";
import {FormSubmission} from "../interfaces/form-submission";

export interface FormsState {
  newFormRequestOpen: boolean;
  formRequestClient: Client | null;
  formRequestPatients: Patient[];
  formRequestContact: Contact | null;
  formRequestChannel: Channel | null;
  patientsLoading: boolean;
  forms: Form[];
  currentForm: Form | null;
  formToSend: Form | null;
  createFormSubmissionInProgress: boolean;
  formSubmissions: FormSubmission[];
  formSubmissionsTotalCount: number;
  formSubmissionsLoading: boolean;
  currentFormSubmission: FormSubmission | null;
}

export const initialState: FormsState = {
  newFormRequestOpen: false,
  formRequestClient: null,
  formRequestPatients: [],
  formRequestContact: null,
  formRequestChannel: null,
  patientsLoading: false,
  forms: [],
  currentForm: null,
  formToSend: null,
  createFormSubmissionInProgress: false,
  formSubmissions: [],
  formSubmissionsTotalCount: 0,
  formSubmissionsLoading: true,
  currentFormSubmission: null,
};

const formsReducer = createReducer(
  initialState,
  on(actions.OpenFormRequest, (state, payload) => ({
    ...state,
    newFormRequestOpen: true
  })),
  on(actions.CloseFormRequest, (state, payload) => ({
    ...state,
    newFormRequestOpen: false,
    createFormSubmissionInProgress: false
  })),
  on(actions.SetFormRequestClient, (state, payload) => ({
    ...state,
    formRequestClient: payload.client ?? null,
    patientsLoading: true,
  })),
  on(actions.GetMoreSendFormPatients, (state, payload) => ({
    ...state,
    patientsLoading: true,
  })),
  on(actions.GetSendFormPatientsSuccess, (state, payload) => {
    if (payload.replace) {
      return {
        ...state,
        formRequestPatients: payload.patients,
        patientsLoading: false,
      };
    }

    return {
      ...state,
      formRequestPatients: [
        ...state.formRequestPatients,
        ...payload.patients
      ],
      patientsLoading: false,
    };
  }),
  on(actions.SetFormRequestContact, (state, payload) => ({
    ...state,
    formRequestContact: payload.contact ?? null,
  })),
  on(actions.SetFormRequestChannel, (state, payload) => ({
    ...state,
    formRequestChannel: payload.channel ?? null,
  })),
  on(actions.SearchFormsSuccess, (state, payload) => ({
    ...state,
    forms: payload.forms,
  })),
  on(actions.GetForm, (state, payload) => ({
    ...state,
    currentForm: null,
  })),
  on(actions.ClearCurrentForm, (state, payload) => ({
    ...state,
    currentForm: null,
  })),
  on(actions.GetFormSuccess, (state, payload) => ({
    ...state,
    currentForm: payload.form,
  })),
  on(actions.GetFormToSend, (state, payload) => ({
    ...state,
    formToSend: null,
  })),
  on(actions.GetFormToSendSuccess, (state, payload) => ({
    ...state,
    formToSend: payload.form,
  })),
  on(actions.ArchiveFormSuccess, (state, payload) => ({
    ...state,
    forms: [
      ...state.forms.map((form) => {
        if (form.id === payload.form.id) {
          return {
            ...form,
            archivedAt: payload.form.archivedAt,
          };
        }

        return form;
      })
    ],
  })),
  on(actions.CreateFormSubmission, (state, payload) => ({
    ...state,
    createFormSubmissionInProgress: true,
  })),
  on(actions.CreateFormSubmissionSuccess, (state, payload) => ({
    ...state,
    newFormRequestOpen: false,
    createFormSubmissionInProgress: false,
  })),
  on(actions.CreateFormSubmissionFailed, (state, payload) => ({
    ...state,
    newFormRequestOpen: false,
    createFormSubmissionInProgress: false,
  })),
  on(actions.GetFormSubmissions, (state, payload) => ({
    ...state,
    formSubmissionsLoading: true,
    formSubmissionsTotalCount: 0
  })),
  on(actions.GetFormSubmissionsSuccess, (state, payload) => ({
    ...state,
    formSubmissionsLoading: false,
    formSubmissions: payload.formSubmissions,
    formSubmissionsTotalCount: payload.totalCount
  })),
  on(actions.GetFormSubmissionsFailed, (state, payload) => ({
    ...state,
    formSubmissionsLoading: false,
    formSubmissionsTotalCount: 0
  })),
  on(actions.GetFormSubmission, (state, payload) => ({
    ...state,
    currentFormSubmission: null,
  })),
  on(actions.GetFormSubmissionSuccess, (state, payload) => ({
    ...state,
    currentFormSubmission: payload.formSubmission,
  })),
  on(actions.GetFormSubmissionFailed, (state, payload) => ({
    ...state,
    currentFormSubmission: null,
  })),
);

export function reducer(state: FormsState | undefined, action: Action): FormsState {
  return formsReducer(state, action);
}
