import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as AuthActions from './actions';
import { map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { WebsocketService } from 'src/app/conversation/websocket.service';
import { AppState } from 'src/app/state/reducers';
import { AuthService } from '../auth.service';
import {HelpPost} from "../../interfaces/help-post.interface";
import {HelpPostDto} from "../../interfaces/dto/help-post.dto";
import {HelpPostAdapter} from "../../adapters/help-post.adapter";
import {MarkHelpPostsAsSeen} from "./actions";
import {Noop} from "../../state/actions";

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private websocketService: WebsocketService,
    private authService: AuthService,
    private helpPostAdapter: HelpPostAdapter,
  ) {}

  logout$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.Logout),
    withLatestFrom(this.store),
    mergeMap(([action, data]) => {
      // TODO: Unsubscribe from other channels
      this.websocketService.leaveNotificationsChannel(Number(data.auth.user?.id));
      return of(AuthActions.RemoveUser());
    })
  ));

  getUnreadPosts$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.GetUnreadHelpPosts),
    mergeMap((action) => this.authService.getUserUnreadHelpPosts()
      .pipe(
        map((result: HelpPostDto[]) => {
          return AuthActions.StoreUnreadPosts({posts: result.map((post) => this.helpPostAdapter.run(post))});
        })
      )
    )
  ));

  markPostsSeen$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.MarkHelpPostsAsSeen),
    mergeMap((action) => this.authService.markHelpPostsSeen()
      .pipe(
        map((result: boolean) => {
          return Noop();
        })
      )
    )
  ));
}
