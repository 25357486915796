import { createAction, props } from "@ngrx/store";
import { Conversation } from "../models/Conversation";
import { Currency } from "../models/Currency";
import { Tag } from "../models/Tag";

export const Noop = createAction("[System] No operation");

export const AddWebsocketConnection = createAction(
  "[System] Add Websocket Connection",
  props<{ roomName: string }>(),
);

export const RemoveWebsocketConnection = createAction(
  "[System] Remove Websocket Connection",
  props<{ roomName: string }>(),
);

export const SetTourMode = createAction(
  "[Tour] Set Tour Mode",
  props<{ tourMode: boolean }>(),
);

export const OpenMainNav = createAction("[System] Open Main Nav");

export const CloseMainNav = createAction("[System] Close Main Nav");

export const ToggleMainNav = createAction("[System] Toggle Main Nav");

export const DoHealthCheck = createAction("[System] Do Health Check");

export const SetHealthy = createAction(
  "[System] Set Healthy",
  props<{ healthy: boolean }>(),
);

export const SetWebsocketHealthy = createAction(
  "[System] Set Websocket Health",
  props<{ healthy: boolean }>(),
);

export const SetOneSignalUserId = createAction(
  "[System] Set OneSignal User ID",
  props<{ userId: string }>(),
);

export const SetOneSignalNotificationsEnabled = createAction(
  "[System] Set OneSignal Notifications Enabled",
  props<{ enabled: boolean }>(),
);

export const GetCurrencies = createAction("[System] Get Currencies");

export const GetCurrenciesSuccess = createAction(
  "[System] Get Currencies Success",
  props<{ currencies: Currency[] }>(),
);

export const GetTags = createAction("[System] Get Tags");

export const GetTagsSuccess = createAction(
  "[System] Get Tags Success",
  props<{ tags: Tag[] }>(),
);

export const EnterFullScreen = createAction("[System] Enter Full Screen");

export const ExitFullScreen = createAction("[System] Exit Full Screen");

export const SetConversationPreviewOpen = createAction(
  "[System] Set Conversation Preview Open",
  props<{ open: boolean }>(),
);

export const SetPreviewConversationId = createAction(
  "[System] Set Conversation Preview Conversation ID",
  props<{ id: string | null }>(),
);

export const ToggleConversationPreviewEnabled = createAction(
  "[System] Toggle conversation preview enabled",
);
