import { Pipe, PipeTransform } from '@angular/core';
import { format } from 'date-fns';
import {Message} from '../models/Message';


@Pipe({name: 'showMessageDate'})
export class ShowMessageDatePipe implements PipeTransform {
  transform(date: Date, messages: Message[] | null | undefined, index: number): boolean {
    if (!messages || !messages[index + 1]) {
      return true;
    }

    if (format(date, 'dd-MM-yyyy') !== format(messages[index + 1].createdAt, 'dd-MM-yyyy')) {
      return true;
    }

    return false;
  }
}
