import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { UserAdapter } from '../adapters/user.adapter';
import { ViewerType } from '../enums/viewers-type';
import { UserDto } from '../interfaces/dto/user.dto';
import { User } from '../models/User';
import { EnvironmentService } from '../services/environment.service';

@Injectable({
  providedIn: 'root',
})
export class ViewersService {
  constructor(
    private environmentService: EnvironmentService,
    private http: HttpClient,
    private userAdapter: UserAdapter
  ) {}

  getViewers(id: string, modelType: ViewerType): Observable<User[]> {
    const url =
      this.environmentService.get('backendUrl') + `/viewers/${modelType}/${id}`;
    return this.http.get<UserDto[]>(url, { withCredentials: true }).pipe(
      map((response: UserDto[]) => {
        return response.map((user) => this.userAdapter.run(user));
      })
    );
  }
}
