import { Payment } from "../models/Payment";
import { hasPaymentAuthorisationExpired } from "./has-payment-authorisation-expired";

export function canPaymentBeCaptured(payment: Payment): boolean {
    if (payment.cancelledAt) {
        return false;
    }

    if (payment.syncedToPmsAt) {
        return false;
    }
    
    if (payment.paidAt) {
        return false;
    }

    if (payment.refundOfId) {
        return false;
    }

    if (payment.queued) {
        return false;
    }

    if (payment.authorisedAt && !hasPaymentAuthorisationExpired(payment)) {
        return true;
    }

    return false;
}