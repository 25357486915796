import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterOption } from '../../../interfaces/filter-option.interface';
import { FilterOptionType } from '../../../enums/filter-option-type';

@Component({
  selector: 'filter-item',
  templateUrl: './filter-item.component.html',
  styleUrls: ['./filter-item.component.scss']
})
export class FilterItemComponent implements OnInit {
  open = false;
  @Input() label?: string;
  @Input() icon: string = 'pi pi-check';
  @Input() options: FilterOption[] = [];
  @Input() selectedValue: FilterOption | null = null;
  @Output() optionSelected = new EventEmitter<FilterOption>();
  @Output() dateRangeSelected = new EventEmitter<{start: Date, end: Date}>();
  dateRangeOpen = false;
  rangeDates: Date[] = [new Date(), new Date()];

  ngOnInit(): void {
    if (this.selectedValue?.range) {
      this.rangeDates = [this.selectedValue.range.start, this.selectedValue.range.end];
    }
  }

  toggle(): void {
    this.open = !this.open;

    if (this.selectedValue?.range && this.open) {
      this.dateRangeOpen = true;
    }
  }

  update(option: FilterOption): void {
    this.optionSelected.emit(option);

    if (option.type === FilterOptionType.DATE_RANGE) {
      this.dateRangeOpen = true;
    } else {
      this.close();
    }
  }

  close(): void {
    this.open = false;
  }

  rangeSelected(): void {
    if (this.rangeDates[0] !== null && this.rangeDates[1] !== null) {
      this.dateRangeSelected.emit({
        start: new Date(this.rangeDates[0]),
        end: new Date(this.rangeDates[1])
      });

      this.dateRangeOpen = false;
      this.close();
    }
  }
}
