import { Pipe, PipeTransform } from '@angular/core';
import { getPaymentStatus } from '../helpers/get-payment-status';
import { IPaymentStatus } from '../interfaces/payment-status.interface';
import { Payment } from '../models/Payment';

@Pipe({name: 'getPaymentStatus'})
export class GetPaymentStatusPipe implements PipeTransform {
  transform(value: Payment): IPaymentStatus {
    return getPaymentStatus(value);
  }
}
