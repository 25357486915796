import {Template} from '../models/Template';
import {TemplateType} from '../enums/template-type';
import {TemplateSubmissionStatus} from '../enums/template-submission-status';
import {subDays, subMonths} from 'date-fns';
import { isStandardTemplate } from './is-standard-template';

export function canTemplateBeEdited(template: Template): boolean {
  // always allow editing of non-360dialog templates
  if (
    !template.externalId
  ) {
    return true;
  }

  // only allow editing of non-system templates
  if (isStandardTemplate(template)) {
      return true;
  }

  // 360 dialog stipulations
  if (
    template.status !== TemplateSubmissionStatus.Approved &&
    template.status !== TemplateSubmissionStatus.Rejected &&
    template.status !== TemplateSubmissionStatus.Paused
  ) {
    return false;
  }

  // 360 dialog stipulations - can only be edited once per day
  // (if the last edit is less that one day ago return false)
  if (
    template.edits.length > 0 &&
    template.edits[0].createdAt.getTime() > subDays(new Date(), 1).getTime()
  ) {
    return false;
  }

  // 360 dialog stipulations - can only be edited 10 times per month
  // (if the tenth edit back is less than a month before now return false)
  if (
    template.edits.length > 9 &&
    template.edits[9].createdAt.getTime() > subMonths(new Date(), 1).getTime()
  ) {
    return false;
  }

  return true;
}
