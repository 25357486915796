import { createFeatureSelector, createSelector } from '@ngrx/store';
import { isToday, isYesterday } from 'date-fns';
import { AppState, STATE_KEY } from '../../state/reducers';
import { NotificationsState } from './reducer';

export const getNotificationsState = createFeatureSelector<AppState, NotificationsState>(STATE_KEY.NOTIFICATIONS);

export const getNotifications = createSelector(
    getNotificationsState,
    (state: NotificationsState) => {
        const sortable = [...state.notifications];

        return sortable.sort((a, b) => {
            return b.createdAt.getTime() - a.createdAt.getTime();
        });
    },
);

export const getTodaysNotifications = createSelector(
    getNotificationsState,
    (state: NotificationsState) => {
        const sortable = [...state.notifications];

        return sortable.filter(notification => isToday(notification.createdAt)).sort((a, b) => {
            return b.createdAt.getTime() - a.createdAt.getTime();
        });
    },
);

export const getYesterdaysNotifications = createSelector(
    getNotificationsState,
    (state: NotificationsState) => {
        const sortable = [...state.notifications];

        return sortable.filter(notification => isYesterday(notification.createdAt)).sort((a, b) => {
            return b.createdAt.getTime() - a.createdAt.getTime();
        });
    },
);

export const getOlderNotifications = createSelector(
    getNotificationsState,
    (state: NotificationsState) => {
        const sortable = [...state.notifications];

        return sortable.filter(notification => !isToday(notification.createdAt) && !isYesterday(notification.createdAt)).sort((a, b) => {
            return b.createdAt.getTime() - a.createdAt.getTime();
        });
    },
);

export const getTotalNotificationCount = createSelector(
  getNotificationsState,
  (state: NotificationsState) => {
    return state.notificationsTotalCount
  },
);

export const getVisibleNotifications = createSelector(
    getNotificationsState,
    (state: NotificationsState) => state.visibleNotifications
);

export const getNotificationCount = createSelector(
    getNotificationsState,
    (state: NotificationsState) => state.notifications.length
);

export const getUnreadNotificationCount = createSelector(
    getNotificationsState,
    (state: NotificationsState) => state.notifications
        .filter(notification => !notification.read)
        .length
);

export const getNotificationShowAll = createSelector(
    getNotificationsState,
    (state: NotificationsState) => state.showAll
);
