import {Component, OnDestroy, OnInit} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { Practice } from '../../../models/Practice';
import { getUnreadNotificationCount } from '../../../notifications/state/selectors';
import { getCurrentPractice } from '../../../practices/state/selectors';
import { OpenMainNav } from '../../../state/actions';
import { AppState } from '../../../state/reducers';
import { EnvironmentService } from '../../../services/environment.service';
import {OpenNewPaymentRequest} from '../../../payments/state/actions';
import {OpenNewCampaign} from '../../../campaigns/state/actions';
import { isFullScreen } from '../../../state/selectors';
import { OpenNewConversation } from '../../../dialogs/state/actions';
import { practiceHasFeature } from '../../../helpers/practice-has-feature';
import { PracticeFeature } from '../../../enums/practice-feature';
import {pickTextColorBasedOnBgHelper} from "../../../helpers/pick-text-color-based-on-bg.helper";

@Component({
  selector: 'mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrls: ['./mobile-header.component.scss']
})
export class MobileHeaderComponent implements OnInit, OnDestroy {
  alive = true;
  currentPractice$?: Observable<Practice | null>;
  notificationCount$?: Observable<number>;
  notificationsOpen = false;
  wideLogo: string;
  settingsLink = '';
  showNewConversation = false;
  showingCreateActions = false;
  practice?: Practice | null;
  searchOpen = false;
  fullScreen$: Observable<boolean>;
  accentColor?: string;
  headerTextColor?: string;

  constructor(private store: Store<AppState>, private environmentService: EnvironmentService) {
    this.wideLogo = this.environmentService.get('appWideLogo');
    this.fullScreen$ = this.store.pipe(select(isFullScreen)).pipe(
      takeWhile(() => this.alive)
    );
   }

  ngOnInit(): void {
    this.subscribeToCurrentPractice();
    this.subscribeToNotificationCount();
    this.getSettingsLink();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  subscribeToNotificationCount(): void {
    this.notificationCount$ = this.store.pipe(select(getUnreadNotificationCount)).pipe(
      takeWhile(() => this.alive)
    );
  }

  subscribeToCurrentPractice(): void {
    this.currentPractice$ = this.store.pipe(select(getCurrentPractice)).pipe(takeWhile(() => this.alive));

    this.currentPractice$.subscribe((practice) => {
      if (practice) {
        this.practice = practice;
        this.accentColor = '#' + practice.accentColor;
        this.headerTextColor = pickTextColorBasedOnBgHelper(this.accentColor, '#FFFFFF', '#4F4F4F');
      }
    });
  }

  getSettingsLink(): void {
    this.settingsLink = `${this.environmentService.get('authUrl')}/user/notifications`;
  }

  openMainNav(): void {
    this.store.dispatch(OpenMainNav());
  }

  openNotifications(): void {
    this.notificationsOpen = true;
  }

  closeNotifications(): void {
    this.notificationsOpen = false;
  }

  toggleNotifications(): void {
    this.notificationsOpen = !this.notificationsOpen;
    this.closeSearch();
  }

  toggleSearch(): void {
    this.searchOpen = !this.searchOpen;
    this.closeNotifications();
  }

  closeSearch(): void {
    this.searchOpen = false;
  }

  toggleCreateMenu(): void {
    this.showingCreateActions = !this.showingCreateActions;
  }

  handleActionSelected(): void {
    this.showingCreateActions = false;
  }
}
