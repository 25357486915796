import { createAction, props } from '@ngrx/store';
import {Media} from '../../models/Media';
import {MediaAddDto} from '../../interfaces/dto/media-add.dto';
import {MediaType} from '../../enums/media-type';
import { MediaFolder } from '../../models/MediaFolder';
import { MediaFolderAddDto } from '../../interfaces/dto/media-folder-add.dto';
import { MediaFolderUpdateDto } from '../../interfaces/dto/media-folder-update.dto';
import { MediaBrowser } from '../../enums/media-browsers';
import {MediaUpdateDto} from "../../interfaces/dto/media-update.dto";

export const SetCurrentMediaBrowser = createAction(
  '[Media] Set Current Media Browser',
  props<{browser: MediaBrowser}>()
);

export const GetMedia = createAction(
  '[Media] Get Media'
);

export const GetMediaSuccess = createAction(
  '[Media] Get Media Success',
  props<{media: Media[], total: number}>()
);

export const AddMedia = createAction(
  '[Media] Add Media',
  props<{media: MediaAddDto}>()
);

export const AddMediaSuccess = createAction(
  '[Media] Add Media Success',
  props<{media: Media}>()
);

export const AddVideo = createAction(
  '[Media] Add Video',
  props<{media: MediaAddDto}>()
);

export const UploadVideo = createAction(
  '[Media] Upload Video',
  props<{id: string, presignedUrl: string, media: MediaAddDto}>()
);

export const VideoUploaded = createAction(
  '[Media] Video Uploaded',
  props<{id: string}>()
);

export const UpdateVideoUploadProgress = createAction(
  '[Media] Update Video Upload Progress',
  props<{percentage: number}>()
);

export const VideoComplete = createAction(
  '[Media] Video processing complete',
  props<{media: Media}>()
);

export const DeleteMedia = createAction(
  '[Media] Delete Media',
  props<{media: Media}>()
);

export const DeleteMediaSuccess = createAction(
  '[Media] Delete Media Success',
  props<{media: Media}>()
);

export const GetMediaFolders = createAction(
  '[Media] Get Media Folders'
);

export const GetMediaFoldersSuccess = createAction(
  '[Media] Get Media Folders Success',
  props<{folders: MediaFolder[]}>()
);

export const AddMediaFolder = createAction(
  '[Media] Add Media Folder',
  props<{folder: MediaFolderAddDto}>()
);

export const AddMediaFolderSuccess = createAction(
  '[Media] Add Media Folder Success',
  props<{folder: MediaFolder}>()
);

export const ChangeMediaFolder = createAction(
  '[Media] Change Media Folder',
  props<{folder: MediaFolder | null, direction: string}>()
);

export const RenameMediaFolder = createAction(
  '[Media] Rename Media Folder',
  props<{folder: MediaFolderUpdateDto}>()
);

export const RenameMediaFolderSuccess = createAction(
  '[Media] Rename Media Folder Success',
  props<{folder: MediaFolder}>()
);

export const DeleteMediaFolder = createAction(
  '[Media] Delete Media Folder',
  props<{id: number}>()
);

export const DeleteMediaFolderSuccess = createAction(
  '[Media] Delete Media Folder Success',
  props<{id: number}>()
);

export const GetMediaFolderTree = createAction(
  '[Media] Get Media Folder Tree'
);

export const GetMediaFolderTreeSuccess = createAction(
  '[Media] Get Media Folder Tree Success',
  props<{folders: MediaFolder[]}>()
);

export const SetFolderToMove = createAction(
  '[Media] Set Folder To Move',
  props<{folder: MediaFolder | null}>()
);

export const MoveFolder = createAction(
  '[Media] Move Folder',
  props<{folder: MediaFolder, destinationFolderId: number}>()
);

export const MoveFolderSuccess = createAction(
  '[Media] Move Folder Success',
  props<{folder: MediaFolder}>()
);

export const MoveMedia = createAction(
  '[Media] Move Media',
  props<{media: Media, destinationFolderId: number}>()
);

export const MoveMediaSuccess = createAction(
  '[Media] Move Media Success',
  props<{media: Media}>()
);

export const RenameMedia = createAction(
  '[Media] Rename Media',
  props<{media: MediaUpdateDto}>()
);

export const RenameMediaSuccess = createAction(
  '[Media] Rename Media Success',
  props<{media: Media}>()
);

export const SetMediaFilters = createAction(
  '[Media] Set Media Filters',
  props<{filters: {
    types: MediaType[]
  }}>()
);

export const SetMediaPage = createAction(
  '[Media] Set Media Page',
  props<{page: number}>()
);








