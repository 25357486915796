import * as actions from './actions';
import { Action, createReducer, on } from '@ngrx/store';
import { Message } from 'src/app/models/Message';
import { tourMessages } from '../../constants/tour.constants';

export interface MessagesState {
  messages: Message[] | null;
  fileSending: boolean;
  audioSending: boolean;
  videoSending: boolean;
  videoCompressionJobId: null | string;
  videoUploading: boolean;
  videoProcessing: boolean;
  videoUploadProgress: number;
}

export const initialState: MessagesState = {
  messages: null,
  fileSending: false,
  audioSending: false,
  videoSending: false,
  videoCompressionJobId: null,
  videoUploading: false,
  videoProcessing: false,
  videoUploadProgress: 0
};

const messagesReducer = createReducer(
  initialState,
  on(actions.GetMessagesSuccess, (state, payload) => ({
    ...state,
    messages: payload.messages
  })),
  on(actions.AddMessage, (state, payload) => {
    if (state.messages) {
      return {
        ...state,
        messages: [
          ...state.messages.filter(message => message.id !== '0' && message.id !== payload.message.id),
          payload.message
        ]
      };
    }

    return {...state};
  }),
  on(actions.UpdateMessage, (state, payload) => {
    if (state.messages) {
      return {
        ...state,
        messages: [
          ...state.messages.map((message) => {
            if (message.id === payload.message.id) {
              return {
                ...message,
                content: payload.message.content,
                status: payload.message.status,
                createdAt: payload.message.createdAt
              };
            }

            return message;
          }),
        ]
      };
    }

    return {...state};
  }),
  on(actions.AddTempMessage, (state, payload) => {
    if (state.messages) {
      return {
        ...state,
        messages: [
          ...state.messages,
          payload.message
        ]
      };
    }

    return {...state};
  }),
  on(actions.ClearMessages, (state, payload) => ({
    ...state,
    messages: null
  })),
  on(actions.DeleteMessageSuccess, (state, payload) => ({
    ...state,
    messages: state.messages ? [
      ...state.messages.filter((message) => message.id !== payload.messageId)
    ] : []
  })),
  on(actions.AddTourMessages, (state, payload) => ({
    ...state,
    messages: tourMessages
  })),
  on(actions.StartSendingFile, (state) => ({
    ...state,
    fileSending: true
  })),
  on(actions.StopSendingFile, (state) => ({
    ...state,
    fileSending: false
  })),
  on(actions.StartSendingAudio, (state) => ({
    ...state,
    audioSending: true
  })),
  on(actions.StopSendingAudio, (state) => ({
    ...state,
    audioSending: false
  })),
  on(actions.UploadVideo, (state, payload) => ({
    ...state,
    videoSending: true,
    videoCompressionJobId: payload.id,
    videoUploading: true
  })),
  on(actions.UpdateVideoUploadProgress, (state, payload) => ({
    ...state,
    videoUploadProgress: payload.percentage
  })),
  on(actions.VideoUploaded, (state) => ({
    ...state,
    videoUploading: false,
    videoProcessing: true
  })),
  on(actions.VideoCompressionJobComplete, (state) => ({
    ...state,
    fileSending: false,
    videoUploadProgress: 0,
    videoUploading: false,
    videoProcessing: false,
    videoCompressionJobId: null,
    videoSending: false,
  })),
  on(actions.ClearVideoSending, (state) => ({
    ...state,
    fileSending: false,
    videoUploading: false,
    videoProcessing: false,
    videoUploadProgress: 0,
    videoCompressionJobId: null,
    videoSending: false,
  })),
);

export function reducer(state: MessagesState | undefined, action: Action): MessagesState {
  return messagesReducer(state, action);
}
