import {ReadStatus} from '../../enums/read-status';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState, STATE_KEY } from '../../state/reducers';
import { ConversationsState } from './reducer';
import { User } from '../../models/User';
import { Conversation } from '../../models/Conversation';
import { sortConversations } from '../../helpers/sorters';
import { selectPractice } from '../../state/selectors';
import { Practice } from '../../models/Practice';
import { filterConversations } from '../../helpers/conversation-filter';
import {conversationHasUnreadMessages} from "../../helpers/conversation-has-unread-messages.helper";
import { isConversationAssignedToUser } from '../../helpers/is-conversation-assigned-to-user';

export const getConversationsState = createFeatureSelector<ConversationsState>(STATE_KEY.CONVERSATIONS);
export const selectUser = (state: AppState) => state.auth.user;
export const selectConversations = (state: AppState) => state.conversations.conversations;

export const getMaxOtherConversations = createSelector(
  getConversationsState,
  (state: ConversationsState) => state.maxOtherConversations
);

export const getAllConversations = createSelector(
  selectUser,
  selectConversations,
  selectPractice,
  (user: User | null, conversations: Conversation[], practice: Practice | null) => {
    return sortConversations(filterConversations(conversations, practice));
  }
);

export const getUnassignedConversations = createSelector(
  getConversationsState,
  selectPractice,
  (state: ConversationsState, practice: Practice | null) => {
    const conversations = filterConversations(state.conversations, practice);
    return sortConversations(conversations.filter((conversation) => {
      return conversation.assignee === undefined && conversation.owner === undefined;
    }));
  }
);

export const getMyConversations = createSelector(
  selectUser,
  selectConversations,
  selectPractice,
  (user: User | null, conversations: Conversation[], practice: Practice | null) => {
    conversations = filterConversations(conversations, practice);
    return sortConversations(conversations.filter((conversation) => {
      return isConversationAssignedToUser(conversation, user);
    }));
  }
);

export const getOtherConversations = createSelector(
  selectUser,
  selectConversations,
  selectPractice,
  getMaxOtherConversations,
  (user: User | null, conversations: Conversation[], practice: Practice | null, maxOtherConversations: number) => {
    conversations = filterConversations(conversations, practice);
    return sortConversations(conversations.filter((conversation) => {
      return (conversation.assignee !== undefined || conversation.owner !== undefined) && !isConversationAssignedToUser(conversation, user);
    })).slice(0, maxOtherConversations);
  }
);

export const getOtherConversationsCount = createSelector(
  selectUser,
  selectConversations,
  selectPractice,
  (user: User | null, conversations: Conversation[], practice: Practice | null) => {
    conversations = filterConversations(conversations, practice);
    return conversations.filter((conversation) => {
      return (conversation.assignee !== undefined || conversation.owner !== undefined) && !isConversationAssignedToUser(conversation, user);
    }).length;
  }
);

export const showLoadMoreOtherConversations = createSelector(
  getConversationsState,
  getOtherConversationsCount,
  (state: ConversationsState, otherConversationCount: number) => {
    return otherConversationCount >= state.maxOtherConversations;
  }
);

export const getResolvedConversations = createSelector(
  getConversationsState,
  selectPractice,
  (state: ConversationsState, practice: Practice | null) => {
    const conversations = filterConversations(state.resolvedConversations, practice);
    return sortConversations([...conversations]);
  }
);

export const getNewUnassignedConversations = createSelector(
  getUnassignedConversations,
  (conversations) => conversations.filter(conversation => conversationHasUnreadMessages(conversation))
);

export const getNewMyConversations = createSelector(
  getMyConversations,
  (conversations) => conversations.filter(conversation => conversationHasUnreadMessages(conversation))
);

export const getNewOtherConversations = createSelector(
  getOtherConversations,
  (conversations) => conversations.filter(conversation => conversationHasUnreadMessages(conversation))
);

export const getOpenConversations = createSelector(
  getConversationsState,
  selectPractice,
  (state: ConversationsState, practice: Practice | null) => {
    const conversations = filterConversations(state.conversations, practice);
    return conversations.filter((conversation) => {
      return !conversation.resolvedAt;
    });
  }
);

export const getOpenConversationClients = createSelector(
  getOpenConversations,
  (conversations) => conversations.map((conversation) => {
    return conversation.client;
  })
);

export const isNewConversationsOpen = createSelector(
  getConversationsState,
  (state: ConversationsState) => state.collapsableSectionsOpen[0]
);

export const isMyOwnedAssignedOpen = createSelector(
  getConversationsState,
  (state: ConversationsState) => state.collapsableSectionsOpen[1]
);

export const isEverythingElseOpen = createSelector(
  getConversationsState,
  (state: ConversationsState) => state.collapsableSectionsOpen[2]
);