import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ConversationPage } from './pages/conversation/conversation.component';
import { PendingChangesGuard } from '../guards/pending-changes.guard';
import {ConversationListPage} from './pages/conversation-list/conversation-list.component';
import {ConversationCanBeViewedByAnalysts} from '../guards/conversation-can-be-viewed-by-analysts.guard';

const routes: Routes = [
  {
    path: '',
    component: ConversationListPage,
    data: {
      title: 'All Conversations'
    }
  },
  {
    path: ':id',
    component: ConversationPage,
    canDeactivate: [PendingChangesGuard],
    data: {
      title: 'Conversation'
    },
    canActivate: [ConversationCanBeViewedByAnalysts],
    runGuardsAndResolvers: 'always'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ConversationRoutingModule { }
