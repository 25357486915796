import { Component, OnInit } from '@angular/core';
import {Event, NavigationEnd, Router} from '@angular/router';

@Component({
  selector: 'page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss']
})
export class PageTitleComponent implements OnInit {
  pageTitleKey = 'ui.page_title.';

  constructor(private router: Router) {
    this.subscribeToRouter();
  }

  ngOnInit(): void {
  }

  subscribeToRouter(): void {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('day-list')) {
          this.pageTitleKey = 'ui.page_title.day_list';
        } else if (event.url.includes('insights')) {
          this.pageTitleKey = 'ui.page_title.insights';
        } else if (event.url.includes('payments')) {
          this.pageTitleKey = 'ui.page_title.payments';
        } else if (event.url.includes('search')) {
          this.pageTitleKey = 'ui.page_title.search';
        } else if (event.url.includes('broadcasts')) {
          this.pageTitleKey = 'ui.page_title.broadcasts';
        } else if (event.url.includes('/conversations/')) {
          this.pageTitleKey = 'ui.page_title.conversation';
        } else if (event.url.includes('/conversations')) {
          this.pageTitleKey = 'ui.page_title.all_conversations';
        } else if (event.url.includes('/templates')) {
          this.pageTitleKey = 'ui.page_title.templates';
        } else if (event.url.includes('/media')) {
          this.pageTitleKey = 'ui.page_title.media_library';
        } else if (event.url.includes('/product-requests')) {
          this.pageTitleKey = 'ui.page_title.product_requests';
        } else if (event.url.includes('/setup-issue')) {
          this.pageTitleKey = 'ui.page_title.setup_issue';
        } else if (event.url.includes('/clients')) {
          this.pageTitleKey = 'ui.page_title.client_insights';
        } else if (event.url.includes('/forms/submissions')) {
          this.pageTitleKey = 'ui.page_title.form_submissions';
        } else if (event.url.includes('/forms')) {
          this.pageTitleKey = 'ui.page_title.forms';
        }
      }
    });
  }
}
