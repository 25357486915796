import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {LetDirective, PushPipe} from '@ngrx/component';

import { DateFnsModule } from 'ngx-date-fns';

import { ConversationRoutingModule } from './conversation-routing.module';
import { CommentsModule } from '../comments/comments.module';
import { UtilsModule } from '../utils/utils.module';
import { UiModule } from '../ui/ui.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { ConversationPage } from './pages/conversation/conversation.component';

import { ConversationDetails } from './components/conversation-details/conversation-details.component';
import { ConversationMessages } from './components/conversation-messages/conversation-messages.component';
import { ConversationStatusComponent } from './components/conversation-status/conversation-status.component';
import { ConversationListItem } from './components/conversation-list-item/conversation-list-item.component';
import { ConversationActionsComponent } from './components/conversation-actions/conversation-actions.component';
import { PatientsModule } from '../patients/patients.module';
import { DirectivesModule } from '../directives/directives.module';
import { JoyrideModule } from 'ngx-joyride';
import { ConversationListPage } from './pages/conversation-list/conversation-list.component';
import { ConversationListFiltersComponent } from './components/conversation-list-filters/conversation-list-filters.component';
import { ConversationListComponent } from './components/conversation-list/conversation-list.component';
import { ConversationPreviewComponent } from './components/conversation-preview/conversation-preview.component';
import { MediaSendComponent } from './components/media-send/media-send.component';
import { ConversationTagManagerComponent } from './components/conversation-tag-manager/conversation-tag-manager.component';
import { ConversationTagsComponent } from './components/conversation-tags/conversation-tags.component';

@NgModule({
  declarations: [
    ConversationPage,
    ConversationDetails,
    ConversationMessages,
    ConversationStatusComponent,
    ConversationListItem,
    ConversationActionsComponent,
    ConversationListPage,
    ConversationListFiltersComponent,
    ConversationListComponent,
    ConversationPreviewComponent,
    MediaSendComponent,
    ConversationTagManagerComponent,
    ConversationTagsComponent,
  ],
  exports: [
    ConversationListItem,
    ConversationListFiltersComponent,
    ConversationListComponent,
    ConversationPreviewComponent
  ],
  imports: [
    CommonModule,
    CommentsModule,
    ConversationRoutingModule,
    DirectivesModule,
    DateFnsModule,
    FormsModule,
    PatientsModule,
    PushPipe,
    LetDirective,
    UtilsModule,
    UiModule,
    JoyrideModule,
    ReactiveFormsModule
  ]
})
export class ConversationModule { }
