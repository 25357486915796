import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Client } from "../../../models/Client";
import { Contact } from "../../../models/Contact";
import { select, Store } from "@ngrx/store";
import { getCurrentPractice } from "../../../practices/state/selectors";
import { takeWhile } from "rxjs/operators";
import { AppState } from "../../../state/reducers";
import { Practice } from "../../../models/Practice";
import { phone } from "phone";
import {
  getProductRequestChannel,
  getProductRequestClient,
  getProductRequestContact,
  getProductRequestPatient,
  getProductRequestPatients,
  IsProductRequestPatientsLoading,
} from "../../state/selectors";
import { Patient } from "../../../models/Patient";
import {
  CreateProductRequest,
  GetMorePatients,
  SetProductRequestChannel,
  SetProductRequestClient,
  SetProductRequestContact,
  SetProductRequestPatient,
} from "../../state/actions";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { User } from "../../../models/User";
import { Channel } from "../../../enums/channel";
import { Group } from "../../../models/Group";
import { phoneValidator } from "../../../helpers/phone-validator";

interface ChannelOption {
  name: string;
  code: Channel;
  className: string;
  icon: string;
  inactive: boolean;
}

@Component({
  selector: "new-product-request",
  templateUrl: "./new-product-request.component.html",
  styleUrls: ["./new-product-request.component.scss"],
})
export class NewProductRequestComponent implements OnInit, OnDestroy {
  alive = true;
  step = 0;
  selectedClient: Client | null = null;
  selectedContact: Contact | null = null;
  selectedPatient: Patient | null = null;
  presetChannel: Channel | null = null;
  practice: Practice | null = null;
  patients: Patient[] = [];
  patientsLoading = false;
  patientsPage = 0;
  productForm = new UntypedFormGroup({
    items: this.formBuilder.array([]),
  });
  approvalRequiredOptions = [
    {
      label: "Yes",
      value: 1,
    },
    {
      label: "No",
      value: 0,
    },
    {
      label: "Not sure",
      value: 2,
    },
  ];
  selectedAssignee: User | Group | null = null;
  channels: ChannelOption[] = [
    {
      name: Channel.WHATSAPP,
      code: Channel.WHATSAPP,
      className: Channel.WHATSAPP.toLowerCase(),
      icon: "WhatsApp.svg",
      inactive: false,
    },
    {
      name: Channel.SMS,
      code: Channel.SMS,
      className: Channel.SMS.toLowerCase(),
      icon: "SMS.svg",
      inactive: false,
    },
  ];
  selectedChannel?: ChannelOption;
  currentChannelOption: ChannelOption = this.channels[0];
  phoneError: boolean = false;

  constructor(
    private store: Store<AppState>,
    private formBuilder: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {
    this.subscribeToCurrentPractice();
    this.subscribeToProductRequestClient();
    this.subscribeToProductRequestContact();
    this.subscribeToProductRequestPatient();
    this.subscribeToProductRequestChannel();
    this.subscribeToProductRequestPatients();
    this.subscribeToPatientsLoading();

    this.addItem();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  subscribeToCurrentPractice(): void {
    this.store
      .pipe(select(getCurrentPractice))
      .pipe(takeWhile(() => this.alive))
      .subscribe((practice) => {
        this.practice = practice;

        if (practice) {
          let channelCode = practice.whatsapp_channel;

          this.channels = [
            {
              name: Channel.WHATSAPP,
              code: channelCode,
              className: Channel.WHATSAPP.toLowerCase(),
              icon: "WhatsApp.svg",
              inactive: false,
            },
            ...this.channels.filter(
              (channelOpt) =>
                channelOpt.code !== Channel.WHATSAPP &&
                channelOpt.code !== Channel.WHATSAPP360 &&
                channelOpt.code !== Channel.WHATSAPP360CLOUD,
            ),
          ];
          this.currentChannelOption = this.channels[0];
        }
      });
  }

  subscribeToProductRequestClient(): void {
    this.store
      .pipe(select(getProductRequestClient))
      .pipe(takeWhile(() => this.alive))
      .subscribe((client) => {
        this.selectedClient = client;
        this.patientsPage = 0;
        this.goToNextStep();
      });
  }

  subscribeToProductRequestContact(): void {
    this.store
      .pipe(select(getProductRequestContact))
      .pipe(takeWhile(() => this.alive))
      .subscribe((contact) => {
        this.selectedContact = contact;
        this.goToNextStep();
      });
  }

  subscribeToProductRequestPatient(): void {
    this.store
      .pipe(select(getProductRequestPatient))
      .pipe(takeWhile(() => this.alive))
      .subscribe((patient) => {
        this.selectedPatient = patient;
        this.goToNextStep();
      });
  }

  subscribeToProductRequestChannel(): void {
    this.store
      .pipe(select(getProductRequestChannel))
      .pipe(takeWhile(() => this.alive))
      .subscribe((channel) => {
        this.presetChannel = channel;
        this.goToNextStep();
      });
  }

  subscribeToProductRequestPatients(): void {
    this.store
      .pipe(select(getProductRequestPatients))
      .pipe(takeWhile(() => this.alive))
      .subscribe((patients) => {
        this.patients = patients;
      });
  }

  subscribeToPatientsLoading(): void {
    this.store
      .pipe(select(IsProductRequestPatientsLoading))
      .pipe(takeWhile(() => this.alive))
      .subscribe((loading) => {
        this.patientsLoading = loading;
      });
  }

  selectClient(client: Client): void {
    this.store.dispatch(SetProductRequestClient({ client }));
  }

  selectContact(contact: Contact): void {
    const phoneNumber = contact.e164 ? contact.e164 : contact.value;

    let result;
    if (this.practice) {
      result = phoneValidator(phoneNumber, this.practice?.country);
    }

    if (result && phone(result).isValid) {
      this.store.dispatch(SetProductRequestContact({ contact }));
    } else {
      this.phoneError = true;
      this.goToNextStep();
    }
  }

  goToStep(step: number): void {
    this.step = step;
  }

  selectPatient(patient: Patient): void {
    this.store.dispatch(SetProductRequestPatient({ patient }));
  }

  goToNextStep(): void {
    let step = 0;

    if (this.selectedClient) {
      step = 1;
    }

    if (this.selectedClient && this.selectedContact) {
      step = 2;
    }

    if (
      this.selectedClient &&
      this.selectedContact &&
      (this.presetChannel || this.selectedChannel)
    ) {
      step = 3;
    }

    if (
      this.selectedClient &&
      this.selectedContact &&
      (this.presetChannel || this.selectedChannel) &&
      this.selectedPatient
    ) {
      step = 4;
    }

    if (this.phoneError) {
      step = 5;
    }

    this.step = step;
  }

  goToPatientStep(): void {
    this.selectedChannel = this.currentChannelOption;
    this.step = 3;
  }

  handleMorePatients(): void {
    this.patientsPage++;
    this.store.dispatch(GetMorePatients({ page: this.patientsPage }));
  }

  goBackTo(step: number): void {
    if (step === 0) {
      this.store.dispatch(SetProductRequestClient({}));
      this.step = 0;
    }

    if (step === 1) {
      this.store.dispatch(SetProductRequestContact({}));
    }

    if (step === 2) {
      if (this.presetChannel) {
        // go back past channel step if channel is preset
        this.goBackTo(1);
      } else {
        this.selectedChannel = undefined;
        this.step = 2;
      }
    }

    if (step === 3) {
      this.store.dispatch(SetProductRequestPatient({}));
    }

    this.phoneError = false;
  }

  handleAssigneeChange(changedTo: User | Group): void {
    this.selectedAssignee = changedTo;
  }

  items(): UntypedFormArray {
    return this.productForm.get("items") as UntypedFormArray;
  }

  addItem(): void {
    const items = this.items();
    if (items) {
      items.push(this.newItem());
    }
  }

  newItem(): UntypedFormGroup {
    return this.formBuilder.group({
      name: new UntypedFormControl("", Validators.required),
      count: new UntypedFormControl(1, [
        Validators.required,
        Validators.min(0),
        Validators.max(999),
      ]),
      approvalRequired: new UntypedFormControl(1),
    });
  }

  removeItem(index: number): void {
    const items = this.items();
    if (items) {
      items.removeAt(index);
    }
  }

  submit(): void {
    if (
      this.isFormValid() &&
      this.selectedClient &&
      this.selectedContact &&
      this.selectedPatient &&
      (this.presetChannel || this.selectedChannel)
    ) {
      this.store.dispatch(
        CreateProductRequest({
          client: this.selectedClient,
          contact: this.selectedContact,
          patient: this.selectedPatient,
          assignee: this.selectedAssignee,
          items: this.items().value.map(
            (item: {
              name: string;
              count: number;
              approvalRequired: number;
            }) => {
              return {
                name: item.name,
                count: item.count,
                approvalRequired: !!item.approvalRequired,
              };
            },
          ),
          channel:
            this.presetChannel ?? this.selectedChannel?.code ?? Channel.SMS,
        }),
      );
    }
  }

  private isFormValid(): boolean {
    const items = this.items();
    Object.keys(items.controls).forEach((field) => {
      const item = items.get(field) as UntypedFormArray;
      if (item) {
        Object.keys(item.controls).forEach((itemField) => {
          item.get(itemField)?.markAsTouched({ onlySelf: true });
        });
      }
    });

    if (!this.productForm.valid) {
      return false;
    }

    return true;
  }
}
