import { Component, Input, OnInit } from '@angular/core';
import { Patient } from 'src/app/models/Patient';


@Component({
  selector: 'reminders',
  templateUrl: './reminders.component.html',
  styleUrls: ['./reminders.component.scss']
})
export class RemindersComponent implements OnInit {
  @Input() patient?: Patient;
  @Input() disabled?: boolean;
  modalActive = false;

  constructor() { }

  ngOnInit(): void {
  }

  showModal(): void {
    this.modalActive = true;
  }

  hideModal(): void {
    this.modalActive = false;
  }
}
