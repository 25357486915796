<button pButton [label]="'ui.financial_notes.label' | translate" class="p-button-fourth p-button-sm" [disabled]="!client || !client.financialNotes || !enabled" (click)="showModal()"></button>
<ng-container *ngIf="client && client.financialNotes">
    <p-dialog
        [header]="'ui.financial_notes.header' | translate"
        [(visible)]="modalActive"
        [modal]="true"
        [style]="{width: '500px', 'min-height': '50vh'}"
        [baseZIndex]="10000"
        [draggable]="false"
        [resizable]="false"
    >
        <div [innerHtml]="client.financialNotes | nl2br"></div>
    </p-dialog>
</ng-container>
