import { Pipe, PipeTransform } from '@angular/core';
import {PageField} from '../forms/interfaces/page-field';
import {FormSubmissionData} from '../forms/interfaces/form-submission-data';
import {getSubmittedFieldValue} from "../helpers/get-submitted-field-value";
import { TranslateService } from '@ngx-translate/core';

@Pipe({name: 'getSubmittedFieldValue'})
export class GetSubmittedFieldValuePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: PageField, submissionData: FormSubmissionData[]): string {
    return getSubmittedFieldValue(value, submissionData, this.translateService);
  }
}
