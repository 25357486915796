<div class="forms-page">
  <div class="constrainer">
    <ng-container *ngIf="loading">
      <div class="loading">
        <p-progressSpinner [style]="{width: '40px', height: '40px'}" styleClass="custom-spinner" strokeWidth="4"></p-progressSpinner>
      </div>
    </ng-container>

    <div class="header">
      <button pButton label="New Form" class="p-button-secondary" (click)="newForm()"></button>
    </div>

    <div class="forms-list">
      <div class="headings">
        <div class="name">Name</div>
        <div class="take-payment">Take Payment?</div>
        <div class="actions"></div>
      </div>
      <ng-container *ngFor="let form of (forms$ | async)">
        <div class="form-list-item">
          <form-list-item [form]="form"></form-list-item>
        </div>
      </ng-container>
    </div>
  </div>
</div>
