import { TranslateService } from "@ngx-translate/core";

export function getColumnMappingName(translateService: TranslateService, value: string, columnIndex: number, prefix?: string): string {

  if (value === '') {
    const text = translateService.instant('helpers.get_column_mapping_name.empty_column', {
      index: columnIndex + 1,
      prefix: prefix ? prefix + ' ' : ''
    });
    return text;
  }

  const text = translateService.instant('helpers.get_column_mapping_name.value_column', {
    index: columnIndex + 1,
    prefix: prefix ? prefix + ' ' : '',
    value: value
  });
  return text;
}
