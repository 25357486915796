import { PracticeFeature } from '../enums/practice-feature';
import { Practice } from '../models/Practice';

export function practiceHasFeature(practice: Practice | null | undefined, featureSlug: PracticeFeature): boolean {
    if (!practice) {
      return false;
    }

    const feature = practice.features.find((feat) => feat.slug === featureSlug);

    if (feature && feature.enabled) {
      return true;
    } else {
      return false;
    }
}
