import { MergeFieldContent } from '../enums/merge-field-content';

export function prettifyTemplateContent(type: MergeFieldContent | string): string {
  switch (type) {
    case MergeFieldContent.PracticeName:
      return 'helpers.prettify_template_content.practice_name';
    case MergeFieldContent.ClientName:
      return 'helpers.prettify_template_content.client_name';
    case MergeFieldContent.ClientPostcode:
      return 'helpers.prettify_template_content.client_postcode';
    case MergeFieldContent.ClientBalance:
      return 'helpers.prettify_template_content.client_balance';
    case MergeFieldContent.ClientTitle:
        return 'helpers.prettify_template_content.client_title';
    case MergeFieldContent.ClientFirstName:
      return 'helpers.prettify_template_content.client_first_name';
    case MergeFieldContent.ClientLastName:
      return 'helpers.prettify_template_content.client_last_name';
    case MergeFieldContent.PatientName:
      return 'helpers.prettify_template_content.patient_name';
    case MergeFieldContent.PaymentAmount:
      return 'helpers.prettify_template_content.payment_amount';
    case MergeFieldContent.PaymentType:
      return 'helpers.prettify_template_content.payment_type';
    case MergeFieldContent.PaymentExpiry:
      return 'helpers.prettify_template_content.payment_expiry';
    case MergeFieldContent.PaymentId:
      return 'helpers.prettify_template_content.payment_id';
    case MergeFieldContent.PaymentLink:
      return 'helpers.prettify_template_content.payment_link';
    case MergeFieldContent.PaymentSentAt:
      return 'helpers.prettify_template_content.payment_sent_at';
    case MergeFieldContent.PaymentMessage:
      return 'helpers.prettify_template_content.payment_message';
    case MergeFieldContent.NotificationText:
      return 'helpers.prettify_template_content.notification_text';
    case MergeFieldContent.NotificationLink:
      return 'helpers.prettify_template_content.notification_link';
    case MergeFieldContent.FormName:
      return 'helpers.prettify_template_content.form_name';
    case MergeFieldContent.AppUrl:
      return 'helpers.prettify_template_content.app_url';
    case MergeFieldContent.Custom:
      return 'helpers.prettify_template_content.custom';
    default:
      return type;
  }
}
