<div class="search" (clickOutside)="searchFocused = false">
  <div class="search-input">
    <img [src]="'searchIcon' | themeIconUrl: theme" class="search-icon" (click)="doSearch(currentSearchType)">
    <input pInputText #searchInput (keyup)="$event.key == 'Enter' ? doSearch(currentSearchType) : void()" (focus)="searchFocused = true" type="text" [(ngModel)]="searchString" placeholder="Search Digital Practice..." class="p-inputtext-search" [style.width]="inputWidth">
    <ng-container *ngIf="searchString.length > 0">
      <img src="assets/images/icons/cross.svg" class="cross-icon" (click)="clearSearch()">
    </ng-container>
  </div>
  <ng-container *ngIf="searchString.length > 0 && searchFocused">
    <div class="search-helper">
      <div class="search-helper-heading">
        Search '<span class="search-helper-heading-searchstr">{{ searchString }}</span>' in...
      </div>
      <div class="search-helper-buttons">
        <ng-container *ngIf="messagingEnabled">
          <div class="search-helper-button" (click)="doSearch(searchType.CONVERSATIONS)">Conversations</div>
        </ng-container>
        <div class="search-helper-button" (click)="doSearch(searchType.CLIENTS)">Clients</div>
        <ng-container *ngIf="paymentsEnabled && paymentSearchToggle">
          <div class="search-helper-button" (click)="doSearch(searchType.PAYMENTS)">Payments</div>
        </ng-container>
        <ng-container *ngIf="productRequestsEnabled && messagingEnabled">
          <div class="search-helper-button" (click)="doSearch(searchType.PRODUCT_REQUESTS)">Product Requests</div>
        </ng-container>
        <ng-container *ngIf="formsEnabled && messagingEnabled">
          <div class="search-helper-button" (click)="doSearch(searchType.FORM_SUBMISSIONS)">Form Submissions</div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
