import { Pipe, PipeTransform } from '@angular/core';
import {secondsToStr} from '../helpers/seconds-to-string';
import { TranslateService } from '@ngx-translate/core';

@Pipe({name: 'secondsToStr'})
export class SecondsToStrPipe implements PipeTransform {
  constructor (private translateService: TranslateService) {}

  transform(value: number): string {
    return secondsToStr(value, this.translateService);
  }
}
