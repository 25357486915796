import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Site } from "../../../models/Site";
import { Practice } from "../../../models/Practice";
import { Currency } from "../../../models/Currency";
import {
  getCurrentPractice,
  getCurrentPracticeSites,
  getPracticeTemplates,
} from "../../../practices/state/selectors";
import { takeWhile } from "rxjs/operators";
import { getConversationClient } from "../../../conversation/state/selectors";
import { select, Store } from "@ngrx/store";
import { getCurrencies } from "../../../state/selectors";
import { AppState } from "../../../state/reducers";
import { PaymentRequest } from "../../../interfaces/payment-request";
import { practiceHasFeature } from "src/app/helpers/practice-has-feature";
import { PracticeFeature } from "src/app/enums/practice-feature";
import { User } from "../../../models/User";
import { MessageStatus } from "../../../enums/message-status";
import { MessageType } from "../../../enums/message-type";
import { addDays, format } from "date-fns";
import { addSignature } from "../../../helpers/add-signature";
import { getUser } from "../../../auth/state/selectors";
import { Observable } from "rxjs";
import { GetActivePaymentTemplate } from "../../../templates/state/actions";
import {
  getActivePaymentTemplate,
  getStandardTypeTemplates,
} from "../../../templates/state/selectors";
import { Template as TemplateModel } from "../../../models/Template";
import { generateTemplateBody } from "../../../helpers/generate-template-body";
import { Template } from "../../../interfaces/template";
import { Channel } from "../../../enums/channel";
import { UntypedFormControl } from "@angular/forms";
import { Client } from "../../../models/Client";
import { Patient } from "../../../models/Patient";
import { MessageButtonType } from "../../../enums/message-button-type";
import { Message } from "../../../models/Message";
import { DialogTemplateSelectorComponent } from "../dialog-template-selector/dialog-template-selector.component";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { Media } from "../../../models/Media";
import { ReminderType } from "../../../enums/reminder-type";

@Component({
  selector: "payment-request-form",
  templateUrl: "./payment-request-form.component.html",
  styleUrls: ["./payment-request-form.component.scss"],
  providers: [DialogService],
})
export class PaymentRequestFormComponent implements OnInit, OnDestroy {
  @Input() paymentRequestLoading = false;
  @Input() paymentRequestFailed = false;
  @Input() disabled = false;
  @Input() outstanding = "0";
  @Input() channel: Channel = Channel.WHATSAPP;
  @Input() client?: Client;
  @Input() patient?: Patient;
  @Input() showOptionalMessage = true;
  @Input() showDescription = true;
  @Input() showPreAuth = true;
  @Input() showAdvancedOptions = true;
  @Input() prefilledDescription = "";
  @Input() balanceEnabled = false;
  @Output() paymentRequested = new EventEmitter<PaymentRequest>();
  @ViewChild("paymentOptionalMessageField") paymentOptionalMessageField:
    | ElementRef
    | undefined;
  @ViewChild("paymentDescriptionField") paymentDescriptionField:
    | ElementRef
    | undefined;
  alive = true;
  selectTemplateDialog?: DynamicDialogRef;
  channels = Channel;
  paymentValue: null | number = null;
  paymentDescription = new UntypedFormControl("");
  paymentMessage = new UntypedFormControl("");
  paymentSite = 0;
  sites: Site[] = [];
  defaultSite = 0;
  paymentDescriptionError = false;
  paymentReminderEnable = false;
  paymentAmountError = false;
  errors: string[] = [];
  practice?: Practice | null;
  user$?: Observable<User | null>;
  user?: User | null;
  currencies: Currency[] = [];
  practiceCurrency?: Currency;
  paymentSyncingFeatureEnabled = false;
  authOnly = false;
  paymentReminderEnabled = true;
  holdAndCaptureEnabled = false;
  messagePreview: Message = {
    id: "0",
    attachments: [],
    content: "",
    outbound: true,
    status: MessageStatus.accepted,
    createdAt: new Date(),
    type: MessageType.PAYMENT_LINK,
    buttons: [],
  };
  activePaymentTemplate: TemplateModel | null = null;
  templates$?: Observable<TemplateModel[]>;
  templates: TemplateModel[] = [];
  showTemplateDialog = false;
  resizeTimeout: any;
  device = "desktop";
  previewOpen = false;
  requestFullAmount = false;
  advancedOpen = false;
  expiryOptions = [
    { amount: 1, label: "1 Day" },
    { amount: 3, label: "3 Days" },
    { amount: 7, label: "7 Days" },
    { amount: 14, label: "14 Days" },
    { amount: 30, label: "30 Days" },
  ];
  paymentExpiry = 3;
  maxOptionalMessageLength = 250;
  remainingOptionalMessageLength = 250;
  optionalMessageTooLong = false;
  maxPaymentDescriptionLength = 100;
  remainingPaymentDescriptionLength = 100;
  paymentDescriptionTooLong = false;
  paymentMessageError = false;
  customExpiryAllowed = false;
  descriptionError = false;

  constructor(
    private store: Store<AppState>,
    public dialogService: DialogService,
  ) {}

  ngOnInit(): void {
    this.device = this.getDevice();
    this.buildPaymentMessage();
    this.subscribeToPracticeSites();
    this.subscribeToConversationClient();
    this.subscribeToCurrencies();
    this.subscribeToCurrentPractice();
    this.subscribeToCurrentUser();
    this.subscribeToActivePaymentTemplate();
    this.subscribeToStandardTemplates();
    this.store.dispatch(GetActivePaymentTemplate());
    this.isPaymentReminderEnable()

    this.paymentDescription = new UntypedFormControl(this.prefilledDescription);

    this.paymentMessage.valueChanges.subscribe((value) => {
      this.remainingOptionalMessageLength =
        this.maxOptionalMessageLength - value.length;
      this.optionalMessageTooLong =
        value.length > this.maxOptionalMessageLength;
    });

    this.paymentDescription.valueChanges.subscribe((value) => {
      this.remainingPaymentDescriptionLength =
        this.maxPaymentDescriptionLength - value.length;
      this.paymentDescriptionTooLong =
        value.length > this.maxPaymentDescriptionLength;
    });
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  @HostListener("window:resize")
  handleResize(): void {
    clearTimeout(this.resizeTimeout);
    this.resizeTimeout = setTimeout(() => {
      this.device = this.getDevice();
    }, 100);
  }

  getDevice(): string {
    if (window.innerWidth <= 640) {
      return "mobile";
    }

    return "desktop";
  }

  optionalTextareaResize($event: Event): void {
    if (
      this.paymentOptionalMessageField &&
      this.paymentOptionalMessageField.nativeElement.offsetHeight > 40
    ) {
      // Grim hack to stop primeng input from showing scrollbar when auto resizing
      this.paymentOptionalMessageField.nativeElement.style.height =
        (
          this.paymentOptionalMessageField.nativeElement.offsetHeight + 6
        ).toString() + "px";
    }
  }

  isPaymentReminderEnable() {
    const paymentReminder = this.practice?.reminders?.find(reminder =>
        reminder.reminder_type === ReminderType.ReminderPayment && reminder.enabled === true
    );

    if (paymentReminder) {
       this.paymentReminderEnable = true
    } else {
      this.paymentReminderEnable = false
      this.paymentReminderEnabled = false
    }
  }

  descriptionTextareaResize($event: Event): void {
    if (
      this.paymentDescriptionField &&
      this.paymentDescriptionField.nativeElement.offsetHeight > 40
    ) {
      // Grim hack to stop primeng input from showing scrollbar when auto resizing
      this.paymentDescriptionField.nativeElement.style.height =
        (
          this.paymentDescriptionField.nativeElement.offsetHeight + 6
        ).toString() + "px";
    }
  }

  subscribeToPracticeSites(): void {
    this.store
      .select(getCurrentPracticeSites)
      .pipe(takeWhile(() => this.alive))
      .subscribe((sites) => {
        this.sites = sites;

        if (sites.length === 1) {
          this.defaultSite = Number(sites[0].id);

          if (this.paymentSite === 0) {
            this.paymentSite = Number(sites[0].id);
          }
        }
      });
  }

  subscribeToStandardTemplates(): void {
    this.templates$ = this.store
      .pipe(select(getStandardTypeTemplates))
      .pipe(takeWhile(() => this.alive));

    this.templates$.subscribe((templates) => {
      // Filter out templates with media for this use case
      this.templates = templates.filter((template) => !template.mediaType);
    });
  }

  subscribeToConversationClient(): void {
    this.store
      .select(getConversationClient)
      .pipe(takeWhile(() => this.alive))
      .subscribe((client) => {
        if (client && client.siteId && client.siteId !== "0") {
          const site = this.sites.find(
            (s) =>
              client.siteId &&
              s.vetbookerResourceId === client.siteId.toString(),
          );

          if (site) {
            this.defaultSite = Number(site.id);
            this.paymentSite = Number(site.id);
          }
        }
      });
  }

  subscribeToCurrentUser(): void {
    this.user$ = this.store
      .pipe(select(getUser))
      .pipe(takeWhile(() => this.alive));

    this.user$.subscribe((user) => {
      if (user) {
        this.user = user;
        this.buildPaymentMessage();
      }
    });
  }

  subscribeToCurrentPractice(): void {
    this.store
      .pipe(select(getCurrentPractice))
      .pipe(takeWhile(() => this.alive))
      .subscribe((practice) => {
        this.practice = practice;
        this.paymentExpiry = practice ? practice.paymentLinkExpiry : 3;
        this.setPracticeCurrency();
        this.updateEnabledFeatures();
        this.isPaymentReminderEnable();
      });
  }

  subscribeToActivePaymentTemplate(): void {
    this.store
      .pipe(select(getActivePaymentTemplate))
      .pipe(takeWhile(() => this.alive))
      .subscribe((template) => {
        this.activePaymentTemplate = template;
        this.buildPaymentMessage();
      });
  }

  updateEnabledFeatures(): void {
    this.updatePaymentSyncingFeatureEnabled();
    this.updateHoldAndCaptureFeatureEnabled();
    this.updateCustomExpiryFeatureEnabled();
  }

  updatePaymentSyncingFeatureEnabled(): void {
    if (this.practice) {
      if (practiceHasFeature(this.practice, PracticeFeature.PAYMENT_SYNCING)) {
        this.paymentSyncingFeatureEnabled = true;
        return;
      }
    }

    this.paymentSyncingFeatureEnabled = false;
  }

  updateHoldAndCaptureFeatureEnabled(): void {
    if (this.practice) {
      if (
        practiceHasFeature(
          this.practice,
          PracticeFeature.HOLD_AND_CAPTURE_PAYMENTS,
        )
      ) {
        this.holdAndCaptureEnabled = true;
        return;
      }
    }

    this.holdAndCaptureEnabled = false;
  }

  updateCustomExpiryFeatureEnabled(): void {
    if (this.practice) {
      if (practiceHasFeature(this.practice, PracticeFeature.CUSTOM_EXPIRY)) {
        this.customExpiryAllowed = true;
        return;
      }
    }

    this.customExpiryAllowed = false;
  }

  subscribeToCurrencies(): void {
    this.store
      .pipe(select(getCurrencies))
      .pipe(takeWhile(() => this.alive))
      .subscribe((currencies) => {
        this.currencies = currencies;
        this.setPracticeCurrency();
      });
  }

  setPracticeCurrency(): void {
    if (this.currencies.length && this.practice) {
      this.practiceCurrency = this.currencies.find(
        (currency) => currency.currencyCode === this.practice?.currency,
      );
      this.buildPaymentMessage();
    }
  }

  submitPaymentRequest(): void {
    if (
      !this.disabled &&
      this.practiceCurrency &&
      !this.optionalMessageTooLong &&
      !this.paymentDescriptionTooLong
    ) {
      this.errors = [];
      this.paymentDescriptionError = false;
      this.paymentMessageError = false;
      this.paymentAmountError = false;

      if (this.paymentDescription.value.replace(/ /g, "") === "") {
        this.errors.push("Payment description is a required field");
        this.paymentDescriptionError = true;
      }

      if (
        this.paymentDescription.value.length > this.maxPaymentDescriptionLength
      ) {
        this.errors.push(
          `Payment description must be ${this.maxPaymentDescriptionLength.toString()} characters or less`,
        );
        this.paymentDescriptionError = true;
      }

      if (this.paymentMessage.value.length > this.maxOptionalMessageLength) {
        this.errors.push(
          `Payment message must be ${this.maxOptionalMessageLength.toString()} characters or less`,
        );
        this.paymentMessageError = true;
      }

      if (/\r|\n/g.test(this.paymentMessage.value)) {
        this.errors.push(`Payment message must not contain any line breaks`);
        this.paymentMessageError = true;
      }

      if (!this.paymentValue && !this.requestFullAmount) {
        this.errors.push("Amount requested is a required field");
        this.paymentAmountError = true;
      }

      if (this.paymentValue) {
        if (this.paymentValue > 999999.99) {
          this.errors.push(
            `Amount requested cannot be more than ${this.practiceCurrency.currencySymbol}999,999.99`,
          );
          this.paymentAmountError = true;
        }

        if (this.paymentValue < this.practiceCurrency.minimumCharge) {
          this.errors.push(
            `Amount requested cannot be less than ${this.practiceCurrency.currencySymbol}${this.practiceCurrency.minimumCharge}`,
          );
          this.paymentAmountError = true;
        }

        if (this.sites.length > 1 && this.paymentSite === 0) {
          this.errors.push("You must select a site");
        }

        if (!this.isValidPaymentAmount()) {
          this.errors.push("Amount requested format is invalid");
          this.paymentAmountError = true;
        }
      }

      if (this.errors.length === 0) {
        this.paymentRequested.emit({
          description: this.paymentDescription.value,
          message: this.paymentMessage.value,
          amount: this.requestFullAmount
            ? Number(this.outstanding)
            : Number(this.paymentValue),
          siteId: this.paymentSite,
          authOnly: this.authOnly,
          expiresAfter: this.paymentExpiry,
          paymentReminderEnabled: this.paymentReminderEnabled,
        });

        this.paymentValue = null;
        this.paymentDescription.setValue("");
        this.paymentMessage.setValue("");
        this.paymentSite = this.defaultSite;
        this.authOnly = false;
        this.paymentReminderEnabled = true;
        this.buildPaymentMessage();
      }
    }
  }

  preventNonNumericChars($event: KeyboardEvent): void {
    if (
      ![
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        ".",
        "Delete",
        "Backspace",
        "ArrowLeft",
        "ArrowRight",
      ].includes($event.key)
    ) {
      $event.preventDefault();
    }
  }

  buildPaymentMessage(): void {
    let msg = "";

    if (
      this.activePaymentTemplate &&
      (this.channel === Channel.WHATSAPP360 ||
        this.channel === Channel.WHATSAPP360CLOUD ||
        this.channel === Channel.SMS ||
        this.channel === Channel.FACEBOOK)
    ) {
      msg = generateTemplateBody(
        this.activePaymentTemplate,
        this.practice ?? undefined,
        "ABCDEF",
        this.getPaymentString(),
        this.authOnly,
        this.paymentMessage.value,
        this.paymentDescription.value,
        addDays(new Date(), Number(this.paymentExpiry)),
        new Date(),
        `${this?.practice?.paymentLinkUrl}/abcdef`,
        this.practiceCurrency?.currencySymbol || "£",
      );
      if (
        (this.channel === Channel.SMS || this.channel === Channel.FACEBOOK) &&
        this.activePaymentTemplate.paymentButtonText
      ) {
        msg = msg + "\n\n" + `${this?.practice?.paymentLinkUrl}/abcdef`;
      }
    } else {
      if (this.paymentMessage.value) {
        msg += this.paymentMessage.value + "\n\n";
      }

      if (this.authOnly) {
        msg += `Pre-authorise a payment of ${
          this.practiceCurrency?.currencySymbol || "£"
        }${this.getPaymentString()} securely now`;

        msg +=
          "\n\n" +
          `This authorisation request will expire on ${format(
            addDays(new Date(), Number(this.paymentExpiry)),
            "dd/MM/yyyy @ HH:mm",
          )}`;

        msg += "\n\n" + `${this?.practice?.paymentLinkUrl}/abcdef`;
      } else {
        msg += `Pay ${
          this.practiceCurrency?.currencySymbol || "£"
        }${this.getPaymentString()} securely now`;

        msg +=
          "\n\n" +
          `This payment link will expire on ${format(
            addDays(new Date(), Number(this.paymentExpiry)),
            "dd/MM/yyyy @ HH:mm",
          )}`;

        msg += "\n\n" + `${this?.practice?.paymentLinkUrl}/abcdef`;
      }
    }

    let buttons = this.messagePreview.buttons;
    if (
      (this.channel === Channel.WHATSAPP360 ||
        this.channel === Channel.WHATSAPP360CLOUD) &&
      this.activePaymentTemplate &&
      this.activePaymentTemplate.paymentButtonText
    ) {
      buttons = [
        {
          text: this.activePaymentTemplate.paymentButtonText,
          type: MessageButtonType.Link,
          link: null,
        },
      ];
    } else {
      buttons = [];
    }

    this.messagePreview = {
      ...this.messagePreview,
      content: msg,
      buttons,
    };
  }

  getPaymentString(): string {
    if (this.requestFullAmount) {
      return Number(this.outstanding).toFixed(2);
    }

    return (
      this.paymentValue && this.isValidPaymentAmount()
        ? Number(this.paymentValue)
        : 0 / 100
    ).toFixed(2);
  }

  isValidPaymentAmount(): boolean {
    let isValidFormat = false;
    if (this.paymentValue) {
      const regex = new RegExp(/^\d+\.?\d{0,2}$/, "g");
      isValidFormat = regex.test(this.paymentValue.toString());
    }

    return isValidFormat;
  }
  handleTemplateSelected(event: {
    text: string;
    mergeFields: { placeholderId: string; content: string }[];
    template: Template;
    media?: Media;
    previewType?: string;
    buttonLink?: string;
  }): void {
    this.paymentMessage.setValue(event.text);
    this.buildPaymentMessage();
  }

  togglePreview(): void {
    this.previewOpen = !this.previewOpen;
  }

  selectTemplate(): void {
    this.selectTemplateDialog = this.dialogService.open(
      DialogTemplateSelectorComponent,
      {
        header: "Select a template",
        modal: true,
        width: "1000px",
        baseZIndex: 10000,
        data: {
          templates: this.templates,
          client: this.client,
          patient: this.patient,
          buttonClass: "p-button-success",
          includeMediaFilter: false,
        },
      },
    );

    this.selectTemplateDialog.onClose.subscribe((result) => {
      if (result) {
        this.handleTemplateSelected(result);
      }
    });
  }
}
