export enum PaymentStatus {
  EXPIRED = 'Expired',
  PENDING = 'Pending',
  QUEUED = 'Queued',
  PAID = 'Paid',
  COMPLETE = 'Complete',
  CANCELLED = 'Cancelled',
  REFUNDED = 'Refunded',
  REFUND = 'Refund',
  FUNDS_HELD = 'Funds Held',
  HOLD_EXPIRED = 'Hold Expired',
  NA = 'N/A'
}
