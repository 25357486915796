<ng-container *ngIf="productRequest && item">
  <div class="item">
    <div class="product-item-name-wrap">
      <div class="product-item-name">
        <ng-container *ngIf="isApproved && !userCanApprove">
          <div class="name">{{'product_requests.item_row.approved' | translate}}: <span class="requested">{{ item.approvedItem ?? item.description }} x{{ item.approvedQty }}</span></div>
          <div class="sub-name">{{'product_requests.item_row.requested' | translate}} <span class="requested">{{ item.description }} x{{ item.requestedQty }}</span></div>
        </ng-container>
        <ng-container *ngIf="!isApproved || userCanApprove">
          <div class="name" [class.grey]="!userCanApprove && isRejected">{{'product_requests.item_row.requested' | translate}}: <span class="requested">{{ item.description }} x{{ item.requestedQty }}</span></div>
          <ng-container *ngIf="item.approvalStatus !== approvalStatus.NOT_REQUIRED && !userCanApprove && isRejected">
            <div class="sub-name">{{'product_requests.item_row.reason' | translate}} <span class="requested">{{ rejectionReason }}</span></div>
          </ng-container>
        </ng-container>
      </div>
      <ng-container *ngIf="!userCanApprove && isRejected">
        <div class="rejected">
          {{'product_requests.item_row.items_rejected' | translate}}
        </div>
      </ng-container>
      <ng-container *ngIf="(isApproved || item.requiresApproval === false) && (productRequest | itemCanBeDispensed : item)">
        <div class="product-item-approval-count">
          <div class="label">{{'product_requests.item_row.dispensed' | translate}}</div>
          <p-inputNumber
            class="item-count"
            [(ngModel)]="fulfilledCount"
            [showButtons]="true"
            [min]="0"
            [max]="item.requiresApproval ? item.approvedQty : item.requestedQty"
            (ngModelChange)="handleFulfilledCountChange()"
            buttonLayout="horizontal"
            decrementButtonClass="p-button-info"
            incrementButtonClass="p-button-info"
            incrementButtonIcon="pi pi-plus"
            decrementButtonIcon="pi pi-minus">
          </p-inputNumber>
        </div>
      </ng-container>
      <ng-container *ngIf="productRequest | shouldShowDispensedCount : item">
        <div class="dispensed">
          {{'product_requests.item_row.x_items_dispensed' | translate: {count: item.fulfilledQty} }}
        </div>
      </ng-container>
      <ng-container *ngIf="item.approvalStatus !== approvalStatus.NOT_REQUIRED">
        <div class="approve-reject-toggle">
          <div class="togglers">
            <div *ngIf="userCanApprove; else noPermission" class="selector-pill approve clickable" (click)="handleApproveClicked(item)" [class.disabled]="isRejected">{{isApproved ? ('product_requests.item_row.approved' | translate) : ('product_requests.item_row.approve' | translate)}} <i class="pi pi-check"></i></div>
            <ng-template #noPermission>
              <div tooltipPosition="left" [pTooltip]="'product_requests.item_row.no_permission' | translate" class="selector-pill grey clickable" [class.disabled]="isRejected || isApproved">{{isApproved ? ('product_requests.item_row.approved' | translate) : ('product_requests.item_row.approve' | translate)}} <i class="pi pi-check"></i></div>
            </ng-template>

            <div *ngIf="userCanApprove; else noPermissionReject" class="selector-pill reject clickable" (click)="handleRejectClicked(item)" [class.disabled]="isApproved">{{isRejected ? ('product_requests.item_row.rejected' | translate) : ('product_requests.item_row.reject' | translate)}}<i class="pi pi-times"></i></div>
            <ng-template #noPermissionReject>
              <div tooltipPosition="right" [pTooltip]="'product_requests.item_row.no_permission' | translate" class="selector-pill grey clickable" [class.disabled]="isRejected || isApproved">{{isRejected ? ('product_requests.item_row.rejected' | translate) : ('product_requests.item_row.reject' | translate)}}<i class="pi pi-times"></i></div>
            </ng-template>
          </div>
        </div>
      </ng-container>
    </div>

    <ng-container *ngIf="item.approvalStatus !== approvalStatus.NOT_REQUIRED && userCanApprove">
      <div class="approve-reject-form">
        <ng-container *ngIf="isRejected">
          <div class="product-item-rejection-reason-form">
            <div class="label">{{'product_requests.item_row.reason_for_rejection' | translate}}</div>
            <input pInputText type="text" (ngModelChange)="handleRejectionReasonChange()" [(ngModel)]="rejectionReason">
          </div>
        </ng-container>
        <ng-container *ngIf="isApproved">
          <div class="approve-form">
            <div class="product-item-approval-name">
              <div class="label">{{'product_requests.item_row.approved_item' | translate}}</div>
              <input pInputText type="text" (ngModelChange)="handleApprovedItemChange()" [(ngModel)]="approvedItem">
            </div>
            <div class="product-item-approval-count">
              <div class="label">{{'product_requests.item_row.approve' | translate}}</div>
              <p-inputNumber
                class="item-count"
                [(ngModel)]="approvedCount"
                [showButtons]="true"
                [min]="0"
                (ngModelChange)="handleApprovedCountChange()"
                buttonLayout="horizontal"
                decrementButtonClass="p-button-info"
                incrementButtonClass="p-button-info"
                incrementButtonIcon="pi pi-plus"
                decrementButtonIcon="pi pi-minus">
              </p-inputNumber>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="(userCanApprove || (productRequest | itemCanBeDispensed : item)) && (isUpdating || errors.length > 0)">
      <div class="save-row">
        <div class="errors">
          <ng-container *ngIf="errors.length > 0">
            <ng-container *ngFor="let error of errors">
              <div class="error">{{ error }}</div>
            </ng-container>
          </ng-container>
        </div>
        <ng-container *ngIf="currentContext === null || (isUpdating) || currentContext === productRequestDetailContext.PAID">
          <div class="save-buttons">
            <p-button (click)="handleRevertItemChanges(item)" [label]="'product_requests.item_row.cancel' | translate" styleClass="p-button-third p-button-sm"></p-button>
            <p-button (click)="handleUpdateItem(item)" [label]="'product_requests.item_row.save' | translate" styleClass="p-button-success p-button-sm"></p-button>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-container>
