import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { getCurrentPractice } from '../../../practices/state/selectors';
import { takeWhile } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { Client } from '../../../models/Client';
import { Practice } from '../../../models/Practice';
import { AppState } from '../../../state/reducers';
import {getConversationClient, getConversationPatient} from '../../../conversation/state/selectors';
import {Patient} from '../../../models/Patient';
import {PMS} from '../../../enums/pms';
import { practiceHasFeature } from 'src/app/helpers/practice-has-feature';
import { PracticeFeature } from 'src/app/enums/practice-feature';
import confetti from 'canvas-confetti';
import { Message } from '../../../models/Message';
import { getMessagesReversed } from '../../../messages/state/selectors';
import { MessageStatus } from '../../../enums/message-status';
import { format, getDay, getMonth } from 'date-fns';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'conversation-resolver',
  templateUrl: './conversation-resolver.component.html',
  styleUrls: ['./conversation-resolver.component.scss']
})
export class ConversationResolverComponent implements OnInit, OnDestroy {
  @Input() disabled = false;
  @Input() buttonClass = '';
  @Output() resolveConversation = new EventEmitter<{
    sendToPms: boolean,
    downloadPdf: boolean,
    sendTextToPms: boolean
  }>();
  alive = true;
  resolveActions: {labelKey: string, command: () => any}[] = [];
  client$?: Observable<Client | null>;
  clientSub$?: Subscription;
  client?: Client | null;
  patient$?: Observable<Patient | null>;
  patientSub$?: Subscription;
  patient?: Patient | null;
  currentPractice$?: Observable<Practice | null>;
  currentPracticeSub$?: Subscription;
  confettiEffect = false;
  currentPractice?: Practice | null;
  showingResolveActions = false;
  messages$?: Observable<Message[] | null>;
  messages: Message[] | null = [];
  confirmResolveOpen = false;
  sendToPms = false;
  downloadPdf = false;
  sendTextToPms = false;
  month = Number(format(new Date(), 'MM'));
  dayOfMonth = Number(format(new Date(), 'dd'));

  constructor(
    private store: Store<AppState>,
    private renderer2: Renderer2,
    private elementRef: ElementRef,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.subscribeToCurrentPractice();
    this.subscribeToClient();
    this.subscribeToPatient();
    this.initialiseFeatures();
    this.subscribeToConversationMessages();
  }

  ngOnDestroy(): void {
    this.alive = false;
    this.currentPracticeSub$?.unsubscribe();
    this.clientSub$?.unsubscribe();
    this.patientSub$?.unsubscribe();
  }

  public surprise(): void {
    const canvas = this.renderer2.createElement('canvas');
    canvas.classList.add('confettiCanvas');

    this.renderer2.appendChild(this.elementRef.nativeElement, canvas);

    let holidayOptions: any = {};

    if (this.month === 2 && this.dayOfMonth >= 14) {
      // It's valentines
      holidayOptions = {
        shapes: [
          confetti.shapeFromText({
            text: '❤️',
            fontFamily: 'sans-serif',
          })
        ],
        scalar: 2,
        flat: true,
        particleCount: 150,
        startVelocity: 40,
      };
    }

    if (this.month === 10 && this.dayOfMonth >= 20) {
      // It's halloween
      holidayOptions = {
        shapes: [
          confetti.shapeFromText({
            text: '🎃',
            fontFamily: 'sans-serif',
          })
        ],
        scalar: 2,
        flat: true,
        particleCount: 150,
        startVelocity: 40,
      };
    }

    if (this.month === 12) {
      // It's december
      holidayOptions = {
        shapes: [
          confetti.shapeFromText({
            text: '❄️',
            fontFamily: 'sans-serif',
          })
        ],
        scalar: 2,
        flat: true,
        particleCount: 150,
        startVelocity: 40,
      };
    }

    const myConfetti = confetti.create(canvas, {
      resize: true
    });

    myConfetti({
      particleCount: 150,
      startVelocity: 30,
      spread: 300,
      origin: { x: 1, y: 0 },
      ...holidayOptions,
    })?.then(() => {
      document.querySelector('.confettiCanvas')?.remove();
    });

    myConfetti({
      particleCount: 150,
      startVelocity: 30,
      spread: 150,
      origin: { x: 0.9, y: 0 },
      ...holidayOptions,
    })?.then(() => {
      document.querySelector('.confettiCanvas')?.remove();
    });
  }

  subscribeToConversationMessages(): void {
    this.messages$ = this.store.pipe(select(getMessagesReversed)).pipe(
      takeWhile(() => this.alive)
    );

    this.messages$.subscribe(messages => {
      this.messages = messages;
    });
  }

  initialiseFeatures(): void {
    this.confettiEffect = practiceHasFeature(this.currentPractice, PracticeFeature.CONVERSATION_CONFETTI_EFFECT);
  }

  resolve(sendToPms: boolean = false, downloadPdf: boolean = false, sendTextToPms: boolean = false): void {
    if (this.messages) {
      const queuedMessages: Message[] | null = [];
      this.messages.forEach(message => {
        if (message.status === MessageStatus.queued) {
          queuedMessages.push(message);
        }
      });

      if (queuedMessages.length > 0) {
        this.toggleConfirmation();
      } else {
        this.confirmResolve(sendToPms, downloadPdf, sendTextToPms);
      }
    } else {
      this.confirmResolve(sendToPms, downloadPdf, sendTextToPms);
    }
  }
  confirmResolve(sendToPms: boolean = false, downloadPdf: boolean = false , sendTextToPms: boolean = false): void {
    if (this.confettiEffect) {
      this.surprise();
    }

    if (practiceHasFeature(this.currentPractice, PracticeFeature.CONVERSATION_SEND_TO_PMS)) {
      sendToPms = true;
    }
    this.resolveConversation.emit({
      sendToPms,
      downloadPdf,
      sendTextToPms
    });
    this.closeResolveActions();
    this.closeConfirmationModal();
  }


    cancelResolve(): void {
      this.toggleConfirmation();
      this.closeResolveActions();
    }

    toggleConfirmation(): void {
      this.confirmResolveOpen = !this.confirmResolveOpen;
    }

    closeConfirmationModal(): void {
      this.confirmResolveOpen = false;
    }


  subscribeToCurrentPractice(): void {
    this.currentPractice$ = this.store.pipe(select(getCurrentPractice)).pipe(takeWhile(() => this.alive));

    this.currentPracticeSub$ = this.currentPractice$.subscribe((practice) => {
      this.currentPractice = practice;
      this.setResolveActions();
    });
  }

  subscribeToClient(): void {
    this.client$ = this.store.pipe(select(getConversationClient)).pipe(takeWhile(() => this.alive));

    this.clientSub$ = this.client$.subscribe(client => {
      this.client = client;
      this.setResolveActions();
    });
  }

  subscribeToPatient(): void {
    this.patient$ = this.store.pipe(select(getConversationPatient)).pipe(takeWhile(() => this.alive));

    this.patientSub$ = this.patient$.subscribe(patient => {
      this.patient = patient;
      this.setResolveActions();
    });
  }

  setResolveActions(): void {

    if (this.currentPractice) {
      this.resolveActions = [
        {
          labelKey: 'ui.conversation_resolver.resolve_actions.resolve',
          command: () => {
            this.resolve(false);
          }
        }
      ];

      if (practiceHasFeature(this.currentPractice, PracticeFeature.CONVERSATION_SEND_TO_PMS) &&
        !practiceHasFeature(this.currentPractice, PracticeFeature.CONVERSATION_SEND_TEXT_TO_PMS)) {
        if (this.client && (this.patient || this.currentPractice.pms !== PMS.TELEOS)) {
          this.resolveActions.push({
            labelKey: 'ui.conversation_resolver.resolve_actions.resolve_and_save',
            command: () => {
              this.resolve(true);
              this.sendToPms = true;
            }
          });
        } else {
          let label = 'ui.conversation_resolver.resolve_actions.no_client';
          if (this.currentPractice.pms === PMS.TELEOS) {
            label = 'ui.conversation_resolver.resolve_actions.no_client_and_patient';
          }

          this.resolveActions.push({
            labelKey: label,
            command: () => {
              return;
            }
          });
        }
      }

      if (practiceHasFeature(this.currentPractice, PracticeFeature.CONVERSATION_SEND_TO_PMS) &&
        practiceHasFeature(this.currentPractice, PracticeFeature.CONVERSATION_SEND_TEXT_TO_PMS)) {
        if (this.client) {
          this.resolveActions.push({
            labelKey: `Resolve and save to ${this.currentPractice.pms}`,
            command: () => {
              this.resolve(true, false, true);
              this.sendTextToPms = true;
            }
          });
        }
      }

      if (!practiceHasFeature(this.currentPractice, PracticeFeature.CONVERSATION_SEND_TO_PMS) &&
        practiceHasFeature(this.currentPractice, PracticeFeature.CONVERSATION_SEND_TEXT_TO_PMS)){
        if (this.client) {
          this.resolveActions.push({
            labelKey: 'ui.conversation_resolver.resolve_actions.resolve_and_save',
            command: () => {
              this.resolve(false, false, true);
              this.sendTextToPms = true;
            }
          });
        }
      }

      if (practiceHasFeature(this.currentPractice, PracticeFeature.CONVERSATION_DOWNLOAD_PDF)) {
        if (this.client) {
          this.resolveActions.push({
            labelKey: 'ui.conversation_resolver.resolve_actions.resolve_download_pdf',
            command: () => {
              this.resolve(false, true);
              this.downloadPdf = true;
            }
          });
        } else {
          this.resolveActions.push({
            labelKey: 'ui.conversation_resolver.resolve_actions.cant_download_pdf',
            command: () => {
              return;
            }
          });
        }
      }
    }
  }

  toggleResolveActions(): void {
    this.showingResolveActions = !this.showingResolveActions;
  }

  closeResolveActions(): void {
    this.showingResolveActions = false;
  }
}
