import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Message, MessageService } from "primeng/api";

interface CustomMessage extends Message {
  summaryParams?: any,
  detailsParams?: any,
}

@Injectable({
  providedIn: 'root'
})
export class TranslatedMessageService {
  constructor(private messageService: MessageService, private translateService: TranslateService) {}

  addTranslated(message: CustomMessage): void {
    const keys = [message.summary || '', message.detail || '']
    const params = {
      ...message.summaryParams,
      ...message.detailsParams
    }

    if(message.summary || message.detail) {
      this.translateService.get(keys, params).subscribe((translations) => {
        this.messageService.add({
          ...message,
          summary: message.summary ? translations[message.summary] : '',
          detail: message.detail ? translations[message.detail] : ''
        })
      })
    }
  }
}
