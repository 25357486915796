import { Injectable } from '@angular/core';
import {Media} from '../models/Media';
import {MediaDto} from '../interfaces/dto/media.dto';

@Injectable({
  providedIn: 'root'
})
export class MediaAdapter {
  constructor() {
  }

  run(dto: MediaDto): Media {
    return {
        ...dto,
    };
  }
}
