import { Injectable } from '@angular/core';

import { map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as ConversationsActions from './actions';
import { WaitingRoomService } from '../waiting-room.service';
import { Conversation } from '../../models/Conversation';
import { getTourMode } from '../../state/selectors';
import { Store } from '@ngrx/store';
import { AppState } from '../../state/reducers';
import { Noop } from '../../state/actions';

@Injectable()
export class ConversationsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private waitingRoomService: WaitingRoomService
  ) {
  }

  getConversations$ = createEffect(() => this.actions$.pipe(
    ofType(ConversationsActions.GetConversations),
    withLatestFrom(this.store.select(getTourMode)),
    mergeMap(([action, tourMode]) => this.waitingRoomService.getConversations(action.practiceId)
      .pipe(
        map((result: Conversation[]) => {
          if (tourMode) {
            return Noop();
          }

          return ConversationsActions.GetConversationsSuccess({conversations: result});
        })
      ))
    )
  );

  getResolvedConversations$ = createEffect(() => this.actions$.pipe(
    ofType(ConversationsActions.GetResolvedConversations),
    mergeMap((action) => this.waitingRoomService.getResolvedConversations(action.practiceId)
      .pipe(
        map((result: Conversation[]) => {
          return ConversationsActions.GetResolvedConversationsSuccess({conversations: result});
        })
      ))
    )
  );
}
