import * as actions from './actions';
import { Action, createReducer, on } from '@ngrx/store';
import { ProductRequest } from '../../models/ProductRequest';
import {Contact} from '../../models/Contact';
import {Client} from '../../models/Client';
import {Patient} from '../../models/Patient';
import {Comment} from '../../models/Comment';
import {Channel} from '../../enums/channel';
import {ProductRequestDetailContext} from '../../enums/product-request-detail-context';
import {HistoryItem} from '../../models/HistoryItem';
import {AuditLog} from "../../models/AuditLog";
import {ProductRequestPaidContext} from "../../enums/product-request-paid-context.enum";

export interface ProductRequestState {
  productRequestOpen: boolean;
  productRequestClient: Client | null;
  productRequestContact: Contact | null;
  productRequestPatient: Patient | null;
  productRequestChannel: Channel | null;
  createInProgress: boolean;
  productRequestPatients: Patient[];
  patientsLoading: boolean;
  updatingProductRequests: ProductRequest[];
  productRequests: ProductRequest[];
  productRequestsList: ProductRequest[];
  productRequestsListLoading: boolean;
  productRequestsListTotalItems: number;
  productRequestComments: Comment[];
  productRequestCommentsLoading: boolean;
  productRequestHistory: AuditLog[];
  productRequestHistoryLoading: boolean;
  productRequestApprovalOpen: boolean;
  productRequestForApproval: ProductRequest | null;
  productRequestRejectionOpen: boolean;
  productRequestForRejection: ProductRequest | null;
  productRequestPaymentCreateOpen: boolean;
  productRequestForPaymentCreate: ProductRequest | null;
  productRequestPaidConfirmationOpen: boolean;
  productRequestPaidConfirmationContext: ProductRequestPaidContext | null;
  productRequestForPaidConfirmation: ProductRequest | null;
  productRequestDispenseOpen: boolean;
  productRequestForDispense: ProductRequest | null;
  productRequestDetailOpen: boolean;
  productRequestDetailContext: ProductRequestDetailContext | null;
  currentProductRequest: ProductRequest | null;
  currentProductRequestClient: Client | null;
  currentProductRequestClientLoading: boolean;
  currentProductRequestPatient: Patient | null;
  currentProductRequestPatientLoading: boolean;
  currentProductRequestPatientHistoryLoading: boolean;
  currentProductRequestPatientHistory: HistoryItem[];
}

export const initialState: ProductRequestState = {
  productRequestOpen: false,
  productRequestClient: null,
  productRequestContact: null,
  productRequestPatient: null,
  productRequestChannel: null,
  createInProgress: false,
  productRequestPatients: [],
  patientsLoading: false,
  updatingProductRequests: [],
  productRequests: [],
  productRequestsList: [],
  productRequestsListLoading: true,
  productRequestsListTotalItems: 0,
  productRequestComments: [],
  productRequestCommentsLoading: false,
  productRequestHistory: [],
  productRequestHistoryLoading: false,
  productRequestApprovalOpen: false,
  productRequestForApproval: null,
  productRequestRejectionOpen: false,
  productRequestForRejection: null,
  productRequestPaymentCreateOpen: false,
  productRequestForPaymentCreate: null,
  productRequestPaidConfirmationOpen: false,
  productRequestPaidConfirmationContext: null,
  productRequestForPaidConfirmation: null,
  productRequestDispenseOpen: false,
  productRequestForDispense: null,
  productRequestDetailOpen: false,
  productRequestDetailContext: null,
  currentProductRequest: null,
  currentProductRequestClient: null,
  currentProductRequestClientLoading: true,
  currentProductRequestPatient: null,
  currentProductRequestPatientLoading: true,
  currentProductRequestPatientHistoryLoading: false,
  currentProductRequestPatientHistory: [],
};

export function reducer(state: ProductRequestState | undefined, action: Action): ProductRequestState {
  const productRequestReducer = createReducer(
    initialState,
    on(actions.OpenProductRequest, (state, payload) => ({
      ...state,
      productRequestOpen: true,
    })),
    on(actions.CloseProductRequest, (state, payload) => ({
      ...state,
      productRequestOpen: false,
    })),
    on(actions.SetProductRequestClient, (state, payload) => ({
      ...state,
      productRequestClient: payload.client ?? null,
      patientsLoading: true,
    })),
    on(actions.SetProductRequestClientSuccess, (state, payload) => ({
      ...state,
      productRequestClient: payload.client,
    })),
    on(actions.GetMorePatients, (state, payload) => ({
      ...state,
      patientsLoading: true,
    })),
    on(actions.GetPatientsSuccess, (state, payload) => {
      if (payload.replace) {
        return {
          ...state,
          productRequestPatients: payload.patients,
          patientsLoading: false,
        };
      }

      return {
        ...state,
        productRequestPatients: [
          ...state.productRequestPatients,
          ...payload.patients
        ],
        patientsLoading: false,
      };
    }),
    on(actions.SetProductRequestContact, (state, payload) => ({
      ...state,
      productRequestContact: payload.contact ?? null,
    })),
    on(actions.SetProductRequestPatient, (state, payload) => ({
      ...state,
      productRequestPatient: payload.patient ?? null,
    })),
    on(actions.SetProductRequestChannel, (state, payload) => ({
      ...state,
      productRequestChannel: payload.channel ?? null,
    })),
    on(actions.CreateProductRequest, (state, payload) => {
      return ({
        ...state,
        createInProgress: true,
      });
    }),
    on(actions.CreateProductRequestSuccess, (state, payload) => {
      return ({
        ...state,
        createInProgress: false,
        productRequestOpen: false,
        productRequestClient: null,
        productRequestContact: null,
        productRequestPatient: null,
        productRequestChannel: null,
        productRequestPatients: [],
      });
    }),
    on(actions.CreateProductRequestFailure, (state, payload) => {
      return ({
        ...state,
        createInProgress: false,
        productRequestOpen: false,
        productRequestClient: null,
        productRequestContact: null,
        productRequestPatient: null,
        productRequestChannel: null,
        productRequestPatients: [],
      });
    }),
    on(actions.GetProductRequestsSuccess, (state, payload) => {
      return ({
        ...state,
        productRequests: payload.productRequests
      });
    }),
    on(actions.GetProductRequestsForList, (state, payload) => {
      return ({
        ...state,
        productRequestsListLoading: true,
      });
    }),
    on(actions.GetProductRequestsForListSuccess, (state, payload) => {
      return ({
        ...state,
        productRequestsList: payload.productRequests,
        productRequestsListLoading: false,
        productRequestsListTotalItems: payload.total
      });
    }),
    on(actions.OpenProductRequestDetail, (state, payload) => {
      return ({
        ...state,
        productRequestDetailOpen: true,
        productRequestDetailContext: payload.context ?? null,
        currentProductRequest: payload.productRequest,
      });
    }),
    on(actions.CloseProductRequestDetail, (state, payload) => {
      return ({
        ...state,
        productRequestDetailOpen: false,
        productRequestDetailContext: null,
        currentProductRequest: null,
        currentProductRequestClient: null,
        currentProductRequestPatient: null,
        currentProductRequestPatientHistoryLoading: false,
        currentProductRequestPatientHistory: [],
      });
    }),
    on(actions.GetProductRequestHistory, (state, payload) => {
      return ({
        ...state,
        productRequestHistoryLoading: true
      });
    }),
    on(actions.GetProductRequestHistorySuccess, (state, payload) => {
      if (state.currentProductRequest?.id === payload.productRequestId) {
        return ({
          ...state,
          productRequestHistory: payload.history,
          productRequestHistoryLoading: false
        });
      }

      return ({
        ...state,
      });
    }),
    on(actions.GetProductRequestComments, (state, payload) => {
      return ({
        ...state,
        productRequestCommentsLoading: true
      });
    }),
    on(actions.GetProductRequestCommentsSuccess, (state, payload) => {
      if (state.currentProductRequest?.id === payload.productRequestId) {
        return ({
          ...state,
          productRequestComments: payload.comments,
          productRequestCommentsLoading: false
        });
      }

      return ({
        ...state,
      });
    }),
    on(actions.AddProductRequestCommentSuccess, (state, payload) => ({
      ...state,
      productRequestComments: [
        payload.comment,
        ...state.productRequestComments.filter(c => c.id !== payload.comment.id),
      ]
    })),
    on(actions.OpenProductRequestApproval, (state, payload) => ({
      ...state,
      productRequestApprovalOpen: true,
      productRequestForApproval: payload.productRequest,
    })),
    on(actions.CloseProductRequestApproval, (state, payload) => ({
      ...state,
      productRequestApprovalOpen: false,
      productRequestForApproval: null,
    })),
    on(actions.ApproveProductRequest, (state, payload) => ({
      ...state,
      productRequestApprovalOpen: false,
      productRequestForApproval: null,
    })),
    on(actions.OpenProductRequestRejection, (state, payload) => ({
      ...state,
      productRequestRejectionOpen: true,
      productRequestForRejection: payload.productRequest,
    })),
    on(actions.CloseProductRequestRejection, (state, payload) => ({
      ...state,
      productRequestRejectionOpen: false,
      productRequestForRejection: null,
    })),
    on(actions.RejectProductRequest, (state, payload) => ({
      ...state,
      productRequestRejectionOpen: false,
      productRequestForRejection: null,
    })),
    on(actions.OpenProductRequestPaymentCreate, (state, payload) => ({
      ...state,
      productRequestPaymentCreateOpen: true,
      productRequestForPaymentCreate: payload.productRequest,
    })),
    on(actions.CloseProductRequestPaymentCreate, (state, payload) => ({
      ...state,
      productRequestPaymentCreateOpen: false,
      productRequestForPaymentCreate: null,
    })),
    on(actions.CreateProductRequestPayment, (state, payload) => ({
      ...state,
      productRequestPaymentCreateOpen: false,
      productRequestForPaymentCreate: null,
    })),
    on(actions.OpenProductRequestPaidConfirmation, (state, payload) => ({
      ...state,
      productRequestPaidConfirmationOpen: true,
      productRequestPaidConfirmationContext: payload.context,
      productRequestForPaidConfirmation: payload.productRequest,
    })),
    on(actions.CloseProductRequestPaidConfirmation, (state, payload) => ({
      ...state,
      productRequestPaidConfirmationOpen: false,
      productRequestPaidConfirmationContext: null,
      productRequestForPaidConfirmation: null,
    })),
    on(actions.ConfirmProductRequestPaid, (state, payload) => ({
      ...state,
      productRequestPaidConfirmationOpen: false,
      productRequestPaidConfirmationContext: null,
      productRequestForPaidConfirmation: null,
    })),
    on(actions.OpenDispenseProductRequest, (state, payload) => ({
      ...state,
      productRequestDispenseOpen: true,
      productRequestForDispense: payload.productRequest,
    })),
    on(actions.CloseDispenseProductRequest, (state, payload) => ({
      ...state,
      productRequestDispenseOpen: false,
      productRequestForDispense: null,
    })),
    on(actions.DispenseProductRequest, (state, payload) => ({
      ...state,
      productRequestDispenseOpen: false,
      productRequestForDispense: null,
    })),
    on(actions.StartUpdatingProductRequest, (state, payload) => ({
      ...state,
      updatingProductRequests: [
        ...state.updatingProductRequests,
        payload.productRequest
      ]
    })),
    on(actions.StopUpdatingProductRequest, (state, payload) => ({
      ...state,
      updatingProductRequests: [
        ...state.updatingProductRequests.filter(pr => pr.id !== payload.productRequest.id),
      ]
    })),
    on(actions.HandleProductRequestUpdate, (state, payload) => ({
      ...state,
      updatingProductRequests: [
        ...state.updatingProductRequests.filter(pr => pr.id !== payload.productRequest.id),
      ],
      currentProductRequest:
        state.currentProductRequest?.id === payload.productRequest.id ?
          payload.productRequest :
          state.currentProductRequest,
      productRequests: [
        ...state.productRequests.filter((request) => request.id !== payload.productRequest.id),
        payload.productRequest,
      ],
      productRequestsList: [
        ...state.productRequestsList.map((request) => {
          if (request.id === payload.productRequest.id) {
            return {
              ...payload.productRequest,
            };
          }

          return request;
        })
      ]
    })),
    on(actions.GetProductRequestClient, (state, payload) => {
      return {
        ...state,
        currentProductRequestClientLoading: true,
      };
    }),
    on(actions.GetProductRequestClientSuccess, (state, payload) => {
      if (payload.client.id === state.currentProductRequest?.client.id) {
        return {
          ...state,
          currentProductRequestClientLoading: false,
          currentProductRequestClient: payload.client
        };
      }
      return {
        ...state
      };
    }),
    on(actions.GetProductRequestClientFailed, (state, payload) => {
      return {
        ...state,
        currentProductRequestClientLoading: false,
      };
    }),
    on(actions.GetProductRequestClientPaymentHistorySuccess, (state, payload) => {
      if (state.currentProductRequestClient) {
        return {
          ...state,
          currentProductRequestClient: {
            ...state.currentProductRequestClient,
            paymentHistory: payload.payments
          },
        };
      }  else {
        return ({
          ...state
        });
      }
    }),
    on(actions.GetProductRequestPatient, (state, payload) => {
      return {
        ...state,
        currentProductRequestPatientLoading: true,
      };
    }),
    on(actions.GetProductRequestPatientSuccess, (state, payload) => {
      if (payload.patient.id === state.currentProductRequest?.patient.id) {
        return {
          ...state,
          currentProductRequestPatient: payload.patient,
          currentProductRequestPatientLoading: false,
        };
      }
      return {
        ...state
      };
    }),
    on(actions.GetProductRequestPatientFailed, (state, payload) => {
      return {
        ...state,
        currentProductRequestPatientLoading: false,
      };
    }),
    on(actions.GetProductRequestPatientHistory, (state, payload) => ({
      ...state,
      currentProductRequestPatientHistoryLoading: true,
    })),
    on(actions.GetProductRequestPatientHistorySuccess, (state, payload) => ({
      ...state,
      currentProductRequestPatientHistoryLoading: false,
      currentProductRequestPatientHistory: payload.historyItems
    })),
  );
  return productRequestReducer(state, action);
}
