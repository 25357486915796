import * as actions from './actions';
import { Action, createReducer, on } from '@ngrx/store';
import { Campaign } from 'src/app/models/Campaign';
import {User} from '../../models/User';
import { CampaignFilterSelection } from '../../interfaces/campaign-filter-selection';
import { CampaignFilterType } from '../../enums/campaign-filter-type';

export interface CampaignState {
  campaigns: Campaign[];
  newCampaignOpen: boolean;
  createdCampaigns: Campaign[] | null;
  invalidNumbers: string[];
  optedOutUsers: User[];
  duplicates: string[];
  dataErrors: {name: string, errors: number}[];
  filters: CampaignFilterSelection;
  limitErrors: {error: string}[];
}

export const initialState: CampaignState = {
  campaigns: [],
  newCampaignOpen: false,
  createdCampaigns: null,
  invalidNumbers: [],
  optedOutUsers: [],
  duplicates: [],
  dataErrors: [],
  filters: {
    [CampaignFilterType.SEARCH_TERM]: '',
    [CampaignFilterType.BROADCAST_STATUS]: [],
    [CampaignFilterType.CHANNEL]: [],
    [CampaignFilterType.TEMPLATE]: []
  },
  limitErrors: [],
};


const campaignReducer = createReducer(
  initialState,
  on(actions.GetCampaignsSuccess, (state, payload) => ({
    ...state,
    campaigns: payload.campaigns
  })),
  on(actions.AddCampaignSuccess, (state, payload) => ({
    ...state,
    campaigns: [
      ...payload.campaigns,
      ...state.campaigns
    ],
    createdCampaigns: payload.campaigns,
    invalidNumbers: payload.invalidNumbers,
    optedOutUsers: payload.optedOutUsers,
    duplicates: payload.duplicates,
    dataErrors: payload.dataErrors,
    limitErrors: payload.limitErrors
  })),
  on(actions.ClearCreatedCampaigns, (state, payload) => ({
    ...state,
    createdCampaigns: null,
    invalidNumbers: [],
    optedOutUsers: [],
    duplicates: [],
    dataErrors: [],
    limitErrors: [],
  })),
  on(actions.EditCampaignSuccess, (state, payload) => ({
    ...state,
    campaigns: [
      ...payload.campaigns,
      ...state.campaigns.filter(c => c.id.toString() !== payload.originalCampaignId)
    ]
  })),
  on(actions.DeleteCampaignSuccess, (state, payload) => ({
    ...state,
    campaigns: [
      ...state.campaigns.filter(campaign => campaign.id !== payload.campaignId)
    ]
  })),
  on(actions.OpenNewCampaign, (state, payload) => ({
    ...state,
    newCampaignOpen: true,
  })),
  on(actions.CloseNewCampaign, (state, payload) => ({
    ...state,
    newCampaignOpen: false,
  })),
  on(actions.SetCampaignSearchTerm, (state, {option}) => ({
    ...state,
    filters: {
      ...state.filters,
      [CampaignFilterType.SEARCH_TERM]: option
    }
  })),
  on(actions.UpdateCampaignFilterBroadCastStatus, (state, payload) => ({
    ...state,
    filters: {
      ...state.filters,
      [CampaignFilterType.BROADCAST_STATUS]: state.filters[CampaignFilterType.BROADCAST_STATUS].includes(payload.option) ? [
        ...state.filters[CampaignFilterType.BROADCAST_STATUS].filter((val) => val !== payload.option)
      ] : [
        ...state.filters[CampaignFilterType.BROADCAST_STATUS],
        payload.option
      ]
    }
  })),
  on(actions.UpdateCampaignFilterChannel, (state, payload) => ({
    ...state,
    filters: {
      ...state.filters,
      [CampaignFilterType.CHANNEL]: state.filters[CampaignFilterType.CHANNEL].includes(payload.option) ? [
        ...state.filters[CampaignFilterType.CHANNEL].filter((val) => val !== payload.option)
      ] : [
        ...state.filters[CampaignFilterType.CHANNEL],
        payload.option
      ]
    }
  })),
  on(actions.UpdateCampaignFilterTemplate, (state, payload) => ({
    ...state,
    filters: {
      ...state.filters,
      [CampaignFilterType.TEMPLATE]: state.filters[CampaignFilterType.TEMPLATE].includes(payload.option) ? [
        ...state.filters[CampaignFilterType.TEMPLATE].filter((val) => val !== payload.option)
      ] : [
        ...state.filters[CampaignFilterType.TEMPLATE],
        payload.option
      ]
    }
  })),
  on(actions.ClearCampaignFilters, (state) => ({
    ...state,
    filters: initialState.filters
  })),
);

export function reducer(state: CampaignState | undefined, action: Action): CampaignState {
  return campaignReducer(state, action);
}
