import { Injectable } from '@angular/core';
import { HistoryItemDto } from '../interfaces/dto/history-item.dto';
import { HistoryItem } from '../models/HistoryItem';
import { ProcedureAdapter } from './procedure.adapter';

@Injectable({
  providedIn: 'root'
})
export class HistoryItemAdapter {
    constructor(private procedureAdapter: ProcedureAdapter) {}

    run(dto: HistoryItemDto): HistoryItem {
        return {
            procedures: dto.procedures.map(item => this.procedureAdapter.run(item)),
            date: new Date(dto.date),
            vet: dto.vet,
            location: dto.location,
            invoiceText: dto.invoiceText,
            charge: dto.charge,
            history: dto.history,
            vitalSigns: dto.vitalSigns,
        };
    }
}
