import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { interval, Observable } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { Practice } from 'src/app/models/Practice';
import { getCurrentPractice } from 'src/app/practices/state/selectors';
import { Notification } from '../../../models/Notification';
import { MarkNotificationAsRead, MarkNotificationAsUnread } from '../../../notifications/state/actions';
import { AppState } from '../../../state/reducers';

@Component({
  selector: 'notifications-list-item',
  templateUrl: './notifications-list-item.component.html',
  styleUrls: ['./notifications-list-item.component.scss']
})
export class NotificationsListItemComponent implements OnInit, OnDestroy {
  alive = true;
  @Input() notification?: Notification;
  @Output() clickedLink = new EventEmitter();
  currentPractice$?: Observable<Practice | null>;
  refresh = new Date();

  constructor(private store: Store<AppState>, private router: Router) { }

  ngOnInit(): void {
    this.subscribeToCurrentPractice();

    interval(60000).pipe(takeWhile(() => this.alive)).subscribe(() => {
      this.refresh = new Date();
    });
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  subscribeToCurrentPractice(): void {
    this.currentPractice$ = this.store.pipe(select(getCurrentPractice)).pipe(takeWhile(() => this.alive));
  }

  handleReadToggle(): void {
    if (this.notification) {
      if (this.notification.read) {
        this.store.dispatch(MarkNotificationAsUnread({notification: this.notification}));
        return;
      }

      this.store.dispatch(MarkNotificationAsRead({notification: this.notification}));
      return;
    }
  }

  navigateToNotificationLink(notification: Notification): void {
    this.clickedLink.emit();
    this.router.navigateByUrl(notification.link);
    this.store.dispatch(MarkNotificationAsRead({notification: notification}));
  }
}
