import { createAction, props } from '@ngrx/store';
import { Message } from 'src/app/models/Message';
import {Media} from "../../models/Media";

export const GetMessages = createAction(
    '[Messages] Get Messages',
    props<{conversationId: string}>()
);

export const GetMessagesSuccess = createAction(
    '[Messages] Get Messages - Success',
    props<{messages: Message[]}>()
);

export const AddMessage = createAction(
  '[Messages] Add Message',
  props<{message: Message}>()
);

export const UpdateMessage = createAction(
  '[Messages] Update Message',
  props<{message: Message}>()
);

export const SendFile = createAction(
  '[Messages] Send File Message',
  props<{conversationId: string, file: File, caption: string}>()
);

export const SendAudio = createAction(
  '[Messages] Send Audio Message',
  props<{conversationId: string, file: File}>()
);

export const SendMedia = createAction(
  '[Messages] Send Media Message',
  props<{conversationId: string, media: Media, caption: string}>()
);

export const SendVideo = createAction(
  '[Messages] Send Video Message',
  props<{conversationId: string, file: File, caption: string}>()
);

export const UploadVideo = createAction(
  '[Messages] Upload Video',
  props<{id: string, presignedUrl: string, conversationId: string, file: File}>()
);

export const VideoUploaded = createAction(
  '[Messages] Video Uploaded',
  props<{id: string}>()
);

export const VideoCompressionJobComplete = createAction(
  '[Messages] Video Compression Job - Complete'
);

export const AddTempMessage  = createAction(
  '[Messages] Add Temp Message',
  props<{message: Message}>()
);

export const RemoveTempMessages  = createAction(
  '[Messages] Add Temp Messages'
);

export const ClearMessages = createAction(
  '[Messages] Clear Messages'
);

export const AddTourMessages = createAction(
  '[Messages] Add tour messages',
);

export const StartSendingFile = createAction(
  '[Messages] Start Sending File'
);

export const StopSendingFile = createAction(
  '[Messages] Stop Sending File'
);

export const StartSendingAudio = createAction(
  '[Messages] Start Sending Audio'
);

export const StopSendingAudio = createAction(
  '[Messages] Stop Sending Audio'
);

export const UpdateVideoUploadProgress = createAction(
  '[Messages] Update Video Upload Progress',
  props<{percentage: number}>()
);

export const ClearVideoSending = createAction(
  '[Messages] Clear video sending'
);

export const DeleteMessage = createAction(
  '[Messages] Delete Message',
  props<{messageId: string}>()
);

export const DeleteMessageSuccess = createAction(
  '[Messages] Delete Message Success',
  props<{messageId: string}>()
);
