import {AfterContentInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, takeWhile } from 'rxjs';
import {Tag} from '../../../models/Tag';
import { ConversationState } from '../../state/reducer';
import { isTagsLoading } from '../../state/selectors';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'conversation-tag-manager',
  templateUrl: './conversation-tag-manager.component.html',
  styleUrls: ['./conversation-tag-manager.component.scss']
})
export class ConversationTagManagerComponent implements OnInit, OnDestroy {
  @Input() allTags: Tag[] = [];
  @Input() selectedTags: Tag[] = [];
  @Output() tagsChanged = new EventEmitter<Tag[]>();
  alive = true;
  showManageTags = false;
  tagsLoading$: Observable<boolean>;

  constructor(private store: Store<ConversationState>,private translate: TranslateService) {
    this.tagsLoading$ = this.store.pipe(select(isTagsLoading)).pipe(takeWhile(() => this.alive));
  }

  ngOnInit(): void {

    this.allTags = this.allTags.map(tag => ({
      ...tag,
      translatedName: this.translate.instant(`tags.${this.formatTagsKey(tag.name)}`)
    }));
    this.selectedTags = this.selectedTags.map(selectedTag => ({
      ...selectedTag,
      translatedName: this.translate.instant(`tags.${this.formatTagsKey(selectedTag.name)}`)
    }));
  }

  ngOnDestroy(): void {
    this.alive = false;
  }
  formatTagsKey(tagsName?: string): string {
    if (!tagsName) return 'UNKNOWN_TAG'; // Default fallback
    return tagsName.toLowerCase().replace(/[^a-z0-9]+/g, '_'); // Converts "With client" → "WITH_CLIENT"
  }
  tagsChange(): void {
    this.tagsChanged.emit(this.selectedTags);
  }
}
