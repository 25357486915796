import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree, Router, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {TokenService} from '../services/token.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  constructor(private tokenService: TokenService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.tokenService.checkTokens().pipe(
      tap(response => {
        if (!response) {
          let returnUrl = '';
          if (!state.url.includes('auth')) {
            returnUrl = state.url;
          }
          this.router.navigate(['auth/login'], { queryParams: { returnUrl }});
        }
      })
    );
  }
}
