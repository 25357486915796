import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { select, Store } from '@ngrx/store';
import Delta from 'quill-delta';
import { Observable, Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { Comment } from 'src/app/models/Comment';
import { Conversation } from 'src/app/models/Conversation';
import { User } from 'src/app/models/User';
import { GetPracticeGroups, GetPracticeStaff } from 'src/app/practices/state/actions';
import { getCurrentPractice, getPracticeGroups, getPracticeStaff } from 'src/app/practices/state/selectors';
import { AppState } from 'src/app/state/reducers';
import { Group } from '../../../models/Group';
import { EnterFullScreen, ExitFullScreen } from '../../../state/actions';
import { isFullScreen } from '../../../state/selectors';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'comments-panel',
  templateUrl: './comments-panel.component.html',
  styleUrls: ['./comments-panel.component.scss']
})
export class CommentsPanelComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() conversation: Conversation | null = null;
  @Input() comments?: Comment[] | null;
  @Input() disabled = false;
  @Input() title?: string | null;
  @Output() commentSent = new EventEmitter<Delta>();
  alive = true;
  commentsSubscription?: Subscription;
  showTopShim = true;
  showBottomShim = false;
  handlingScroll = false;
  users$?: Observable<User[]>;
  groups$?: Observable<Group[]>;
  fullScreen$: Observable<boolean>;

  @ViewChild('commentContainer') private commentContainer: ElementRef | undefined;
  @ViewChildren('commentsList') commentsList: QueryList<ElementRef> | undefined;

  constructor(private store: Store<AppState>, private translateService: TranslateService) {
    this.fullScreen$ = this.store.pipe(select(isFullScreen)).pipe(
      takeWhile(() => this.alive)
    );
  }

  ngOnInit(): void {
    this.translateService.get('comments.panel.title').pipe(takeWhile(_ => this.alive)).subscribe(title => {
      if(!this.title) {
        this.title = title;
      }
    })
    this.subscribeToPracticeStaff();
    this.subscribeToPracticeGroups();
    this.subscribeToPractice();
  }

  ngAfterViewInit(): void {
    if (this.commentsList) {
      this.commentsList.changes.subscribe(() => {
        this.scrollToBottom();
      });
    }
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  handleCommentSent(message: Delta): void {
    this.commentSent.emit(message);
  }

  scrollToBottom(): void {
    if (this.commentContainer) {
      this.commentContainer.nativeElement.scrollTop = 1;
    }
  }

  handleScroll(): void {
    if (!this.handlingScroll) { // Save performance on scroll
      this.handlingScroll = true;
      setTimeout(() => {
        this.shimEnabler();
        this.handlingScroll = false;
      }, 50);
    }
  }

  shimEnabler(): void {
    if (this.commentContainer) {
      if (this.commentContainer.nativeElement.scrollTop >= 0) {
        this.showBottomShim = false;
      } else {
        this.showBottomShim = true;
      }

      if ((this.commentContainer.nativeElement.scrollTop - this.commentContainer.nativeElement.offsetHeight) <= 1 - this.commentContainer.nativeElement.scrollHeight) {
        this.showTopShim = false;
      } else {
        this.showTopShim = true;
      }
    }
  }

  subscribeToPracticeStaff(): void {
    this.users$ = this.store.pipe(select(getPracticeStaff)).pipe(
      takeWhile(() => this.alive)
    );
  }

  subscribeToPracticeGroups(): void {
    this.groups$ = this.store.pipe(select(getPracticeGroups)).pipe(
      takeWhile(() => this.alive)
    );
  }

  subscribeToPractice(): void {
    this.store.pipe(select(getCurrentPractice)).pipe(takeWhile(() => this.alive)).subscribe((practice) => {
      if (practice !== null) {
        this.store.dispatch(GetPracticeStaff());
        this.store.dispatch(GetPracticeGroups());
      }
    });
  }

  enterFullScreen(): void {
    this.store.dispatch(EnterFullScreen());
  }

  exitFullScreen(): void {
    this.store.dispatch(ExitFullScreen());
  }
}
