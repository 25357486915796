<ng-container *ngIf="conversation">
    <div
        class="conversation"
        [ngClass]="{'add-padding': viewers && viewers.length > 0 && showUsers}"
    >
        <ng-container *ngIf="viewers && viewers.length > 0 && showUsers">
            <top-bar-message
                [viewers]="viewers"
                [type]="'conversation'"
            ></top-bar-message>
        </ng-container>
        <div
            class="conversation__panel conversation__panel--nopad conversation__panel--nobg conversation__panel--left"
        >
            <div class="tabs">
                <div
                    class="tab"
                    [class.active]="activeTab == tabs.CHAT"
                    (click)="switchTab(tabs.CHAT)"
                >
                    <ng-container *ngIf="activeTab == tabs.CHAT">
                        <ng-container
                            *ngIf="conversation && conversation.channel === 'SMS'"
                        >
                            <img
                                class="icon sms-ipad"
                                src="/assets/images/icons/SMS-IPAD-blue.svg"
                                alt=""
                            />
                        </ng-container>
                        <ng-container
                            *ngIf="conversation && (conversation.channel === 'WhatsApp' || conversation.channel === 'WhatsApp360' || conversation.channel === 'WhatsApp360Cloud')"
                        >
                            <img
                                class="icon wa"
                                src="/assets/images/icons/WhatsApp-blue.svg"
                                alt=""
                            />
                        </ng-container>
                        <ng-container
                            *ngIf="conversation && conversation.channel === 'Facebook'"
                        >
                            <img
                                class="icon fb"
                                src="/assets/images/icons/facebook-messenger-blue.svg"
                                alt=""
                            />
                        </ng-container>
                        <ng-container
                            *ngIf="conversation && conversation.channel === 'Instagram'"
                        >
                            <i class="pi pi-instagram"></i>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="activeTab != tabs.CHAT">
                        <ng-container
                            *ngIf="conversation && conversation.channel === 'SMS'"
                        >
                            <img
                                class="icon sms-ipad"
                                src="/assets/images/icons/SMS-IPAD.svg"
                                alt=""
                            />
                        </ng-container>
                        <ng-container
                            *ngIf="conversation && (conversation.channel === 'WhatsApp' || conversation.channel === 'WhatsApp360' || conversation.channel === 'WhatsApp360Cloud')"
                        >
                            <img
                                class="icon wa"
                                src="/assets/images/icons/WhatsApp.svg"
                                alt=""
                            />
                        </ng-container>
                        <ng-container
                            *ngIf="conversation && conversation.channel === 'Facebook'"
                        >
                            <img
                                class="icon fb"
                                src="/assets/images/icons/facebook-messenger.svg"
                                alt=""
                            />
                        </ng-container>
                        <ng-container
                            *ngIf="conversation && conversation.channel === 'Instagram'"
                        >
                            <i class="pi pi-instagram"></i>
                        </ng-container>
                    </ng-container>
                    <div class="label">Client Chat</div>
                </div>
                <div
                    class="tab mobile"
                    [class.active]="activeTab == tabs.CLIENT"
                    [class.fullscreen]="fullScreen$ | ngrxPush"
                    (click)="switchTab(tabs.CLIENT)"
                >
                    <ng-container *ngIf="activeTab == tabs.CLIENT">
                        <img
                            class="icon first"
                            src="assets/images/icons/icon-client-blue.svg"
                        />
                        <img
                            class="icon"
                            src="assets/images/icons/icon-paw-blue.svg"
                        />
                    </ng-container>
                    <ng-container *ngIf="activeTab != tabs.CLIENT">
                        <img
                            class="icon first"
                            src="assets/images/icons/icon-client.svg"
                        />
                        <img
                            class="icon"
                            src="assets/images/icons/icon-paw.svg"
                        />
                    </ng-container>
                    <div class="label">Client/Patient</div>
                </div>
                <div
                    class="tab"
                    [class.active]="activeTab == tabs.TICKET"
                    [class.fullscreen]="fullScreen$ | ngrxPush"
                    (click)="switchTab(tabs.TICKET)"
                >
                    <ng-container *ngIf="activeTab == tabs.TICKET">
                        <i class="pi pi-info-circle active"></i>
                    </ng-container>
                    <ng-container *ngIf="activeTab != tabs.TICKET">
                        <i class="pi pi-info-circle"></i>
                    </ng-container>
                    <div class="label">
                        Conversation #{{ (conversation$ | async)?.id }}
                    </div>
                </div>
                <div
                    class="tab"
                    [class.active]="activeTab == tabs.TEAM_CHAT"
                    (click)="switchTab(tabs.TEAM_CHAT)"
                >
                    <ng-container *ngIf="activeTab == tabs.TEAM_CHAT">
                        <i class="pi pi-users active"></i>
                    </ng-container>
                    <ng-container *ngIf="activeTab != tabs.TEAM_CHAT">
                        <i class="pi pi-users"></i>
                    </ng-container>
                    <div class="label">Team Chat</div>
                </div>
            </div>
            <div
                class="tab-content chat"
                [class.active]="activeTab == tabs.CHAT || device == 'desktop'"
            >
                <conversation-messages
                    [conversation]="conversation$ | async"
                    [conversationContact]="contact || null"
                    [client]="client$ | async"
                    [messages$]="messages$"
                    [disabled]="chatIsDisabled"
                    [awaitingResponse]="chatIsAwaitingResponse"
                    [paymentsFailedToSync]="paymentsFailedToSync"
                    [showConversationActions]="true"
                    version="single"
                    (stepToClient)="handleStepToClient()"
                ></conversation-messages>
            </div>
            <div
                class="tab-content ticket"
                [class.active]="activeTab == tabs.TICKET"
            >
                <ng-container
                    *ngIf="device === 'desktop' else detailsTpl"
                ></ng-container>
            </div>
            <div
                class="tab-content client"
                [class.fixed]="client?.id === '0'"
                [class.active]="activeTab == tabs.CLIENT"
            >
                <div class="client-tab-section client">
                    <ng-container
                        *ngTemplateOutlet="clientPanel"
                    ></ng-container>
                </div>
                <ng-container
                    *ngIf="productRequestFeatureEnabled || paymentsFeatureEnabled"
                >
                    <div class="client-tab-section actions">
                        <ng-container
                            *ngTemplateOutlet="actionsTpl"
                        ></ng-container>
                    </div>
                </ng-container>
                <div class="client-tab-section" *ngIf="(client$ | async)">
                    <ng-container
                        *ngTemplateOutlet="patientPanel"
                    ></ng-container>
                </div>
            </div>
            <div
                class="tab-content team-chat"
                [class.active]="activeTab == tabs.TEAM_CHAT"
            >
                <ng-container
                    *ngIf="device === 'desktop' else teamChat"
                ></ng-container>
            </div>
        </div>
        <div
            class="conversation__panel conversation__panel--split"
            [class.conversation__panel--split--two-panel]="!productRequestFeatureEnabled && !paymentsFeatureEnabled"
            [class.fixed]="client?.id === '0'"
        >
            <div class="conversation__panel conversation__panel--split-inner">
                <ng-container
                    *ngIf="device === 'mobile' else clientPanel"
                ></ng-container>
            </div>
            <ng-container
                *ngIf="productRequestFeatureEnabled || paymentsFeatureEnabled"
            >
                <div
                    class="conversation__panel conversation__panel--split-inner conversation__panel--thin"
                >
                    <ng-container
                        *ngIf="device === 'mobile' else actionsTpl"
                    ></ng-container>
                </div>
            </ng-container>
            <div
                class="conversation__panel conversation__panel--split-inner"
                [class.hidden]="!(client$ | async)"
            >
                <ng-container
                    *ngIf="device === 'mobile' else patientPanel"
                ></ng-container>
            </div>
        </div>
        <div class="conversation__panel conversation__panel--right">
            <ng-container
                *ngIf="device !== 'desktop' else detailsTpl"
            ></ng-container>
        </div>
    </div>

    <ng-template #detailsTpl>
        <conversation-details
            [conversation]="conversation$ | async"
            [conversationStatuses]="(conversationStatuses$ | async) || []"
            [authUser]="authUser$ | async"
            [client$]="client$"
            (summaryChange)="handleSummaryChange($event)"
            (statusChange)="handleStatusChange($event)"
            (ownerChange)="handleOwnerChange($event)"
            (assigneeChange)="handleAssigneeChange($event)"
            (stepToClient)="handleStepToClient()"
            (stepToTeam)="handleStepToTeam()"
            (stepToClientAndOpenActions)="handleStepToClientAndOpenActions()"
            (watch)="handleWatch($event)"
            (unwatch)="handleUnwatch($event)"
        ></conversation-details>
        <div class="team-chat-desktop">
            <ng-container *ngTemplateOutlet="teamChat"></ng-container>
        </div>
    </ng-template>

    <ng-template #teamChat>
        <div
            class="comment-panel-wrapper"
            joyrideStep="step7"
            [stepContent]="step7Content"
            stepPosition="left"
            (next)="handleStepToConversation()"
            (prev)="handleStepToClient()"
        >
            <comments-panel
                [comments]="staffComments$ | async"
                [conversation]="conversation$ | async"
                (commentSent)="handleCommentSent($event)"
                [disabled]="!!(conversation$ | async)?.resolvedAt"
            ></comments-panel>
        </div>
    </ng-template>

    <ng-template #clientPanel>
        <div
            joyrideStep="step5"
            [stepContent]="step5Content"
            stepPosition="left"
            (prev)="handleStepToChat()"
        >
            <ng-container *ngIf="clientLoading$ | async">
                <div class="loading">
                    <p-progressSpinner
                        [style]="{width: '24px', height: '24px'}"
                        styleClass="custom-spinner"
                        strokeWidth="4"
                    ></p-progressSpinner>
                    <div class="label">Loading...</div>
                </div>
            </ng-container>
            <ng-container *ngIf="!(clientLoading$ | async)">
                <ng-container *ngIf="client">
                    <client-panel
                        [disabled]="!!(conversation$ | async)?.resolvedAt"
                        [client]="client"
                        [shared]="(clientShared$ | async) || false"
                        [shareLoading]="shareClientLoading"
                        (clientChanged)="handleClientChanged()"
                        (shareToPms)="handleShareClientToPms($event)"
                        (stepToConversation)="handleStepToConversation()"
                        (stepToOpenActionsPopup)="handleStepToOpenActionsPopup()"
                        (paymentHistoryOpen)="paymentHistoryOpened()"
                    ></client-panel>
                </ng-container>
                <ng-container *ngIf="!client">
                    <no-client-panel
                        [disabled]="!!(conversation$ | async)?.resolvedAt"
                        [couldntAttemptMatch]="!!(conversation$ | async)?.couldntAttemptMatch"
                        [multiplePotentialClients]="(conversation$ | async)?.multiplePotentialClients || 0"
                        [reloadingClient]="!!(isReloadingClient$ | async)"
                        [changingClient]="changingClient"
                        [user$]="externalUser$"
                        (clientUpdated)="handleClientSet($event)"
                        (clientRefresh)="handleClientRefresh()"
                        #noClientPanel
                    >
                    </no-client-panel>
                </ng-container>
            </ng-container>
        </div>
    </ng-template>

    <ng-template #patientPanel>
        <div
            joyrideStep="step6"
            [stepContent]="step6Content"
            stepPosition="left"
            (next)="handleStepToTeam()"
        >
            <ng-container *ngIf="patientLoading$ | async">
                <div class="loading">
                    <p-progressSpinner
                        [style]="{width: '24px', height: '24px'}"
                        styleClass="custom-spinner"
                        strokeWidth="4"
                    ></p-progressSpinner>
                    <div class="label">Loading...</div>
                </div>
            </ng-container>
            <ng-container *ngIf="!(patientLoading$ | async)">
                <ng-container *ngIf="patient$ | async">
                    <patient-panel
                        [disabled]="!!(conversation$ | async)?.resolvedAt"
                        [patient]="(patient$ | async) || undefined"
                        [shared]="(patientShared$ | async) || false"
                        [patientHistory]="(patientHistory$ | async) || []"
                        [patientHistoryLoading]="(patientHistoryLoading$ | async) || false"
                        [shareLoading]="sharePatientLoading"
                        (patientChanged)="handlePatientChanged()"
                        (shareToPms)="handleSharePatientToPms($event)"
                        (patientHistoryOpened)="handlePatientHistoryOpened($event)"
                    ></patient-panel>
                </ng-container>
                <ng-container *ngIf="!(patient$ | async)">
                    <no-patient-panel
                        [disabled]="!!(conversation$ | async)?.resolvedAt"
                        [patients]="(availablePatients$ | async) || []"
                        (patientSelected)="handlePatientSelected($event)"
                        (morePatients)="handleMorePatients()"
                    ></no-patient-panel>
                </ng-container>
            </ng-container>
        </div>
    </ng-template>

    <ng-template #actionsTpl>
        <div class="actions primary-layout">
            <ng-container
                *ngTemplateOutlet="conversationActions"
            ></ng-container>
        </div>
    </ng-template>

    <ng-template #step5Content>
        <div class="tour-item">
            <div class="top mint">
                <img
                    src="/assets/images/icons/tour/client.svg"
                    height="103"
                    alt=""
                    class="icon"
                />
            </div>
            <div class="content">
                <div class="title">View client info</div>
                <div class="description">
                    The most relevant information pulled directly from your PMS
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template #step6Content>
        <div class="tour-item">
            <div class="top pink">
                <img
                    src="/assets/images/icons/tour/paw.svg"
                    height="103"
                    alt=""
                    class="icon"
                />
            </div>
            <div class="content">
                <div class="title">View patient info</div>
                <div class="description">
                    Surfacing the most useful information allows you to help
                    your patients faster
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template #step7Content>
        <div class="tour-item">
            <div class="top blue">
                <img
                    src="/assets/images/icons/tour/ticket.svg"
                    height="78"
                    alt=""
                    class="icon"
                />
            </div>
            <div class="content">
                <div class="title">Team chat</div>
                <div class="description">
                    Use this section to discuss the issue with your team
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template #conversationActions>
        <conversation-actions
            [paymentRequestLoading]="(paymentRequestLoading$ | async) || false"
            [paymentRequestOpen]="(paymentRequestOpen$ | async) || false"
            [paymentRequestFailed]="paymentRequestFailed"
            [paymentRequestDisabled]="paymentsDisabled"
            [paymentsFeatureEnabled]="paymentsFeatureEnabled"
            [balanceEnabled]="balanceEnabled"
            [productRequestFeatureEnabled]="productRequestFeatureEnabled"
            [outstanding]="(client$ | async)?.accountStatus || ''"
            [channel]="conversation.channel ?? channels.WHATSAPP"
            [client]="client || undefined"
            [contact]="contact || undefined"
            [patient]="patient || undefined"
            (togglePaymentRequest)="togglePaymentRequested($event)"
            (paymentRequest)="paymentRequested($event)"
            (stepToConversation)="handleStepToConversation()"
            (stepToCloseActionsPopup)="handleStepToCloseActionsPopup()"
        ></conversation-actions>
    </ng-template>
</ng-container>
