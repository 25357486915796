import { Injectable } from '@angular/core';
import { PaymentAdapter } from './payment.adapter';
import {ClientInsightsDto} from '../interfaces/dto/client-insights.dto';
import {ClientInsights} from '../models/ClientInsights';
import {ConversationAdapter} from './conversation.adapter';
import {ProductRequestAdapter} from './product-request.adapter';
import {MessageAdapter} from "./message.adapter";
import {Payment} from "../models/Payment";
import {PracticeAdapter} from "./practice.adapter";
import {isConversationDto} from "../helpers/is-conversation-dto";
import {isCampaignMessageDto} from "../helpers/is-campaign-message-dto";
import {CampaignMessageAdapter} from "./campaign-message.adapter";
import {DepositHistoryAdapter} from "./deposit-history.adapter";

@Injectable({
  providedIn: 'root'
})
export class ClientInsightAdapter {
  constructor(
    private paymentAdapter: PaymentAdapter,
    private conversationAdapter: ConversationAdapter,
    private productRequestAdapter: ProductRequestAdapter,
    private messageAdapter: MessageAdapter,
    private practiceAdapter: PracticeAdapter,
    private campaignMessageAdapter: CampaignMessageAdapter,
    private depositHistoryAdapter: DepositHistoryAdapter,
  ) {
  }

  run(dto: ClientInsightsDto): ClientInsights {
    let fullName = '(no name)';
    if ((dto.firstName && dto.firstName.length > 0) || (dto.lastName && dto.lastName.length > 0)) {
      fullName =
        (dto.firstName ?? '') +
        (dto.firstName && dto.lastName ? ' ' : '') +
        (dto.lastName ?? '');
    }

    return {
      ...dto,
      fullName,
      fullNameWithPmsId: `${fullName} (${dto.pmsId})`,
      payments: dto.payments.map((item) => this.paymentAdapter.run(item)),
      depositPayment: dto.depositPayment ? this.paymentAdapter.run(dto.depositPayment) : null,
      conversations: dto.conversations.map((item) => this.conversationAdapter.run(item)),
      productRequests: dto.productRequests.map((item) => this.productRequestAdapter.run(item)),
      depositHistory: dto.depositHistory.map((item) => this.depositHistoryAdapter.run(item)),
      depositAddedManually: dto.depositAddedManually,
      automaticMessages: dto.automaticMessages.map((item) => {
        if (isCampaignMessageDto(item)) {
          return this.campaignMessageAdapter.run(item);
        } else {
          return this.messageAdapter.run(item);
        }
      }),
      nextAppointmentAt: dto.nextAppointmentAt ? new Date(dto.nextAppointmentAt) : undefined,
      lastTransactionAt: dto.lastTransactionAt ? new Date(dto.lastTransactionAt) : undefined,
      practice: this.practiceAdapter.run(dto.practice),
    };
  }
}
