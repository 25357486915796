import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Patient } from 'src/app/models/Patient';

@Component({
  selector: 'patient-list-item',
  templateUrl: './patient-list-item.component.html',
  styleUrls: ['./patient-list-item.component.scss']
})
export class PatientListItemComponent implements OnInit {
  @Input() disabled?: boolean;
  @Input() patient?: Patient;
  @Output() patientSelected = new EventEmitter<Patient>();

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
  }

  selectPatient(): void {
    if (this.patient) {
      this.patientSelected.emit(this.patient);
    }
  }
  getTranslatedAge(): string {
    if (!this.patient || !this.patient.age) return '';

    const age = this.patient.age; // Example: "4y 8m", "6y", "3m 15d", "5w", "2w 3d", "7d"

    // Extract different time units
    const yearsMatch = age.match(/(\d+)y/);
    const monthsMatch = age.match(/(\d+)m/);
    const weeksMatch = age.match(/(\d+)w/);
    const daysMatch = age.match(/(\d+)d/);

    const years = yearsMatch ? parseInt(yearsMatch[1], 10) : 0;
    const months = monthsMatch ? parseInt(monthsMatch[1], 10) : 0;
    const weeks = weeksMatch ? parseInt(weeksMatch[1], 10) : 0;
    const days = daysMatch ? parseInt(daysMatch[1], 10) : 0;

    let translatedAge = '';

    if (years) {
      translatedAge += this.translate.instant('patients.AGE.YEARS', { value: years }) + ' ';
    }
    if (months) {
      translatedAge += this.translate.instant('patients.AGE.MONTHS', { value: months }) + ' ';
    }
    if (weeks) {
      translatedAge += this.translate.instant('patients.AGE.WEEKS', { value: weeks }) + ' ';
    }
    if (days) {
      translatedAge += this.translate.instant('patients.AGE.DAYS', { value: days }) + ' ';
    }

    return translatedAge.trim(); // Remove extra spaces
  }
}
