import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'cash-register-icon',
  templateUrl: './cash-register-icon.component.html',
  styleUrls: ['./cash-register-icon.component.scss']
})
export class CashRegisterIconComponent implements OnInit {
  @Input() color = '#FFFFFF';

  constructor() { }

  ngOnInit(): void {
  }
}
