<form class="add-media" [formGroup]="form">
  <div class="input-row">
    <div class="label">{{'media.add_media.file' | translate}}</div>
    <p-fileUpload
      #fileUpload
      chooseIcon="pi-file"
      [chooseLabel]="'media.add_media.choose_label' | translate"
      class="input-upload"
      mode="basic"
      name="image"
      accept="image/jpeg,image/jpg,image/png,video/*,application/pdf"
      [invalidFileSizeMessageSummary]="'media.add_media.invalid_file_size' | translate"
      [invalidFileTypeMessageSummary]="'media.add_media.invalid_file_type' | translate"
      [invalidFileTypeMessageDetail]="'media.add_media.allowed_types' | translate : {types: 'jpeg, jpg, png, pdf, mp4, mov, wmv, 3gp, flv.'}"
      [auto]="true"
      [customUpload]="true"
      (uploadHandler)="uploadFile($event)"
    ></p-fileUpload>
    <ng-container *ngIf="form.controls.asset.invalid && (form.controls.asset.dirty || form.controls.asset.touched) && form.controls.asset.errors?.['required']">
      <div class="error">{{'media.add_media.must_upload' | translate}}</div>
    </ng-container>
    <ng-container *ngIf="previewSrc">
      <div class="image-preview">
        <img [src]="previewSrc" />
      </div>
    </ng-container>
    <ng-container *ngIf="!previewSrc && form.controls.asset && form.controls.asset.value && form.controls.asset.value.name">
      <div class="file-name">{{ form.controls.asset.value.name }}</div>
    </ng-container>
  </div>
  <div class="input-row">
    <div class="label">{{'media.add_media.name' | translate}}</div>
    <input pInputText class="p-inputtext" type="text" formControlName="name" maxlength="50">
    <ng-container *ngIf="form.controls.name.invalid && (form.controls.name.dirty || form.controls.name.touched) && form.controls.name.errors?.['required']">
      <div class="error">{{'media.add_media.must_enter_name' | translate}}</div>
    </ng-container>
  </div>
  <ng-container *ngIf="showVideoLengthWarning">
    <div class="warning">{{'media.add_media.videos_longer' | translate}}</div>
  </ng-container>
  <div class="btn-row">
    <p-button (click)="submit()" [label]="'media.add_media.add' | translate" styleClass="p-button-primary"></p-button>
  </div>
</form>
