import {Injectable} from '@angular/core';

import {catchError, map, mergeMap, switchMap, tap, withLatestFrom} from 'rxjs/operators';

import {Actions, createEffect, ofType} from '@ngrx/effects';

import * as ProductRequestActions from './actions';
import {ProductRequestService} from '../product-requests.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../state/reducers';
import {ProductRequest} from '../../models/ProductRequest';
import {Client} from '../../models/Client';
import {Patient} from '../../models/Patient';
import {of, takeUntil} from 'rxjs';
import {ClientService} from '../../clients/client.service';
import {getCurrentPractice, getCurrentPracticeConfig} from '../../practices/state/selectors';
import {getProductRequestClient} from './selectors';
import {Noop} from '../../state/actions';
import { TranslatedMessageService } from '../../helpers/translated-message-service';
import {Comment} from '../../models/Comment';
import {ProductRequestStatus} from '../../enums/product-request-status';
import {ProductRequestItem} from '../../models/ProductRequestItem';
import {ProductRequestApprovalStatus} from '../../enums/product-request-approval-status';
import {ProductRequestDetailContext} from '../../enums/product-request-detail-context';
import {prettifyProductRequestStatus} from '../../helpers/prettify-product-request-status';
import {getPatientPage} from '../../patients/state/selectors';
import * as PatientActions from '../../patients/state/actions';
import {FullPayment} from '../../models/FullPayment';
import {PatientService} from '../../patients/patients.service';
import {HistoryItem} from '../../models/HistoryItem';
import {AuditLog} from '../../models/AuditLog';
import {Router} from '@angular/router';
import {ProductRequestPaidContext} from '../../enums/product-request-paid-context.enum';
import { TranslateService } from '@ngx-translate/core';
import { SearchType } from '../../enums/search-type';

@Injectable()
export class ProductRequestEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private productRequestService: ProductRequestService,
    private clientService: ClientService,
    private patientService: PatientService,
    private messageService: TranslatedMessageService,
    private router: Router,
    private translateService: TranslateService,
  ) {
  }

  getProductRequests$ = createEffect(() => this.actions$.pipe(
    ofType(ProductRequestActions.GetProductRequests),
    mergeMap((action) => this.productRequestService.getProductRequests(action.practiceId)
      .pipe(
        map((result: ProductRequest[]) => {
          return ProductRequestActions.GetProductRequestsSuccess({productRequests: result});
        })
      ))
    )
  );

  getProductRequestsForList$ = createEffect(() => this.actions$.pipe(
    ofType(ProductRequestActions.GetProductRequestsForList),
    tap((action) => {
      if ( action.searchString && (action.searchString !== '') && action.filters ) {
        let url = `search?s=${encodeURIComponent(action.searchString)}&searchType=${SearchType.PRODUCT_REQUESTS}&practiceId=${action.practiceId}`;
        if (action.filters.client && action.filters.client.length > 0) {
          const clientData = JSON.stringify(action.filters.client.map((client) => client.id));
          url += `&client=${encodeURIComponent(clientData)}`;
        }
        this.router.navigateByUrl(url);
      }
    }),
    mergeMap((action) => this.productRequestService.getProductRequestList(action.practiceId, action.searchString, action.filters, action.sortBy, action.page)
      .pipe(
        map((result: {items: ProductRequest[], total: number}) => {
          return ProductRequestActions.GetProductRequestsForListSuccess({productRequests: result.items, total: result.total});
        })
      ))
    )
  );

  openProductRequest$ = createEffect(() => this.actions$.pipe(
    ofType(ProductRequestActions.OpenProductRequest),
    switchMap((action) => {
      return of(
        ProductRequestActions.SetProductRequestClient({client: action.client}),
        ProductRequestActions.SetProductRequestContact({contact: action.contact}),
        ProductRequestActions.SetProductRequestPatient({patient: action.patient}),
        ProductRequestActions.SetProductRequestChannel({channel: action.channel})
      );
    })
  ));

  closeProductRequest$ = createEffect(() => this.actions$.pipe(
    ofType(ProductRequestActions.CloseProductRequest),
    switchMap((action) => {
      return of(
        ProductRequestActions.SetProductRequestClient({}),
        ProductRequestActions.SetProductRequestContact({}),
        ProductRequestActions.SetProductRequestPatient({}),
        ProductRequestActions.SetProductRequestChannel({})
      );
    })
  ));

  createProductRequest = createEffect(() => this.actions$.pipe(
    ofType(ProductRequestActions.CreateProductRequest),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => this.productRequestService.createRequest(practice, action.client, action.contact, action.patient, action.assignee, action.items, action.channel)
      .pipe(
        map((result: ProductRequest) => {
          this.messageService.addTranslated({
            severity: 'success',
            summary: 'product_requests.effects.created.title',
            detail: 'product_requests.effects.created.detail',
            life: 10000
          });
          return ProductRequestActions.CreateProductRequestSuccess({productRequest: result});
        }),
        catchError((error) => {
          this.messageService.addTranslated({
            severity: 'error',
            summary: 'product_requests.effects.create_error.title',
            detail: 'product_requests.effects.create_error.detail',
            detailsParams: {error: error.error ? error.error.error : ''},
            life: 10000
          });
          return of(ProductRequestActions.CreateProductRequestFailure());
        })
      ))
    )
  );

  getPatients$ = createEffect(() => this.actions$.pipe(
    ofType(ProductRequestActions.SetProductRequestClient),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      if (action.client && practice) {
        return this.clientService.getClientByPmsId(action.client.pmsId, practice.id, 0).pipe(
          tap((result: { client: Client, patients: Patient[] }) => {
            this.store.dispatch(ProductRequestActions.SetProductRequestClientSuccess({client: result.client}));
          }),
          map((result: { client: Client, patients: Patient[] }) => {
            return ProductRequestActions.GetPatientsSuccess({patients: result.patients, replace: true});
          })
        );
      }

      return of(ProductRequestActions.GetPatientsSuccess({patients: [], replace: true}));
    })
  ));

  getMorePatients$ = createEffect(() => this.actions$.pipe(
    ofType(ProductRequestActions.GetMorePatients),
    withLatestFrom(this.store.select(getCurrentPractice), this.store.select(getProductRequestClient)),
    mergeMap(([action, practice, client]) => {
      if (client && practice) {
        return this.clientService.getClientByPmsId(client.pmsId, practice.id, action.page).pipe(
          map((result: { client: Client, patients: Patient[] }) => {
            return ProductRequestActions.GetPatientsSuccess({patients: result.patients, replace: false});
          })
        );
      }

      return of(ProductRequestActions.GetPatientsSuccess({patients: [], replace: true}));
    })
  ));

  goToProductRequestDetail$ = createEffect(() => this.actions$.pipe(
    ofType(ProductRequestActions.GoToProductRequestDetail),
    tap((action) => {
      this.router.navigate([], {
        queryParams: {
          id: action.productRequest.id
        },
        queryParamsHandling: 'merge'
      });
    }),
    map((action) => {
      return Noop();
    })
  ));

  closeProductRequestDetail$ = createEffect(() => this.actions$.pipe(
    ofType(ProductRequestActions.CloseProductRequestDetail),
    tap((action) => {
      this.router.navigate([], {
        queryParams: {
          id: null,
        },
        queryParamsHandling: 'merge'
      });
    }),
    map((action) => {
      return Noop();
    })
  ));

  moveProductRequest$ = createEffect(() => this.actions$.pipe(
    ofType(ProductRequestActions.MoveProductRequest),
    mergeMap((action) => {
      switch (action.status) {
        case ProductRequestStatus.READY_TO_PAY:
          // Start approval flow
          if (action.productRequest.items.find((item) => item.approvalStatus === ProductRequestApprovalStatus.PENDING)) {
            // there are still items that need approval updating
            return of(ProductRequestActions.OpenProductRequestDetail({productRequest: action.productRequest, context: ProductRequestDetailContext.APPROVE}));
          } else {
            return of(
              ProductRequestActions.OpenProductRequestApproval({productRequest: action.productRequest})
            );
          }

        case ProductRequestStatus.REJECTED:
          // Start rejection flow
          if (action.productRequest.items.find((item) => item.approvalStatus === ProductRequestApprovalStatus.PENDING)) {
            // there are still items that need approval updating
            return of(ProductRequestActions.OpenProductRequestDetail({productRequest: action.productRequest, context: ProductRequestDetailContext.REJECT}));
          } else {
            return of(
              ProductRequestActions.OpenProductRequestRejection({productRequest: action.productRequest})
            );
          }

        case ProductRequestStatus.AWAITING_PAYMENT:
          // Start create payment flow
          return of(
            ProductRequestActions.OpenProductRequestPaymentCreate({productRequest: action.productRequest})
          );

        case ProductRequestStatus.PAID:
          // Start confirm manually paid flow
          let context = ProductRequestPaidContext.PAID;
          if (action.productRequest.status === ProductRequestStatus.READY_TO_PAY) {
            context = ProductRequestPaidContext.SKIP_PAYMENT;
          }

          return of(
            ProductRequestActions.OpenProductRequestPaidConfirmation({productRequest: action.productRequest, context})
          );

        case ProductRequestStatus.FULFILLED:
          const productRequest = action.productRequest
          const itemStatuses = [ProductRequestApprovalStatus.APPROVED, ProductRequestApprovalStatus.NOT_REQUIRED];
          if (productRequest.items.filter(item => itemStatuses.includes(item.approvalStatus)).find(item => item.fulfilledQty === 0)) {
            // If there are no items that haven't been dispensed
            return of(
              ProductRequestActions.OpenProductRequestDetail({ productRequest, context: ProductRequestDetailContext.PAID })
            );
          }
          // Start mark items as dispensed flow
          return of(
            ProductRequestActions.OpenDispenseProductRequest({ productRequest })
          );

        case ProductRequestStatus.COMPLETE:
          // Start mark items as dispensed flow if we went straight to complete from paid (skipped fulfilled)
          if (action.productRequest.status === ProductRequestStatus.PAID) {
            return of(
              ProductRequestActions.OpenDispenseProductRequest({productRequest: action.productRequest})
            );
          }
          break;
      }

      return of(
        ProductRequestActions.CloseProductRequestDetail(),
        ProductRequestActions.HandleMoveProductRequest({productRequest: action.productRequest, status: action.status})
      );
    })
  ));

  startUpdatingOnHandleMoveProductRequest$ = createEffect(() => this.actions$.pipe(
    ofType(ProductRequestActions.HandleMoveProductRequest),
    mergeMap((action) => {
      return of(ProductRequestActions.StartUpdatingProductRequest({productRequest: action.productRequest}));
    })
  ));

  handleMoveProductRequest$ = createEffect(() => this.actions$.pipe(
    ofType(ProductRequestActions.HandleMoveProductRequest),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      return this.productRequestService.moveRequest(practice, action.productRequest, action.status)
        .pipe(
          map((result: ProductRequest) => {
            return ProductRequestActions.MoveProductRequestSuccess({productRequest: result});
          }),
          catchError((error) => {
            this.messageService.addTranslated({
              severity: 'error',
              summary: 'product_requests.effects.move_error.title',
              detail: 'product_requests.effects.move_error.detail',
              life: 10000
            });
            return of(ProductRequestActions.StopUpdatingProductRequest({productRequest: action.productRequest}));
          })
        );
    })
  ));

  handleCannotMoveProductRequest$ = createEffect(() => this.actions$.pipe(
    ofType(ProductRequestActions.HandleCannotMoveProductRequest),
    mergeMap((action) => {

      const status = this.translateService.instant(prettifyProductRequestStatus(action.status));
      this.messageService.addTranslated({
        severity: 'error',
        summary: 'product_requests.effects.move_to_error.title',
        detail: 'product_requests.effects.move_to_error.detail',
        detailsParams: {status: status.toLowerCase()},
        life: 10000
      });
      return of(Noop());
    })
  ));

  startUpdatingOnUpdateProductRequestApproval$ = createEffect(() => this.actions$.pipe(
    ofType(ProductRequestActions.UpdateProductRequestApproval),
    mergeMap((action) => {
      return of(
        ProductRequestActions.StartUpdatingProductRequest({productRequest: action.productRequest})
      );
    })
  ));

  updateProductRequestApproval$ = createEffect(() => this.actions$.pipe(
    ofType(ProductRequestActions.UpdateProductRequestApproval),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => this.productRequestService.updateProductRequestApproval(practice, action.productRequest, action.approvalCounts, action.rejectionReasons)
      .pipe(
        map((result: ProductRequest) => {
          return ProductRequestActions.UpdateProductRequestApprovalSuccess({productRequest: result});
        }),
        catchError((error) => {
          this.messageService.addTranslated({
            severity: 'error',
            summary: 'product_requests.effects.update_error.title',
            detail: 'product_requests.effects.update_error.detail',
            life: 10000
          });
          return of(ProductRequestActions.StopUpdatingProductRequest({productRequest: action.productRequest}));
        })
      )
    )
  ));

  processUpdateProductRequestApprovalSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(ProductRequestActions.UpdateProductRequestApprovalSuccess),
    mergeMap((action) => {
      let status = ProductRequestStatus.REJECTED;
      if (action.productRequest.items.find(item => item.approvalStatus === ProductRequestApprovalStatus.APPROVED)) {
        // at least one item is approved
        status = ProductRequestStatus.READY_TO_PAY;
      }

      return of(
        ProductRequestActions.StopUpdatingProductRequest({productRequest: action.productRequest}),
        ProductRequestActions.MoveProductRequest({
          productRequest: action.productRequest,
          status,
        })
      );
    })
  ));

  startUpdatingOnApproveProductRequest$ = createEffect(() => this.actions$.pipe(
    ofType(ProductRequestActions.ApproveProductRequest),
    mergeMap((action) => {
      return of(
        ProductRequestActions.CloseProductRequestDetail(),
        ProductRequestActions.StartUpdatingProductRequest({productRequest: action.productRequest})
      );
    })
  ));

  approveProductRequest$ = createEffect(() => this.actions$.pipe(
    ofType(ProductRequestActions.ApproveProductRequest),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => this.productRequestService.approveProductRequest(practice, action.productRequest, action.notifyClient, action.rejectionReason)
      .pipe(
        map((result: ProductRequest) => {
          return ProductRequestActions.ApproveProductRequestSuccess({productRequest: result});
        }),
        catchError((error) => {
          this.messageService.addTranslated({
            severity: 'error',
            summary: 'product_requests.effects.update_error.title',
            detail: 'product_requests.effects.update_error.detail',
            life: 10000
          });
          return of(ProductRequestActions.StopUpdatingProductRequest({productRequest: action.productRequest}));
        })
      )
    )
  ));

  updateProductRequestItemApproval$ = createEffect(() => this.actions$.pipe(
    ofType(ProductRequestActions.UpdateProductRequestItemApproval),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => this.productRequestService.updateProductRequestItemApproval(practice, action.productRequestItem, action.approvalStatus, action.approvedCount, action.rejectionReason, action.approvedItem)
      .pipe(
        map((result: ProductRequestItem) => {
          return ProductRequestActions.UpdateProductRequestItemApprovalSuccess({productRequestItem: result});
        }),
        catchError((error) => {
          this.messageService.addTranslated({
            severity: 'error',
            summary: 'product_requests.effects.update_error.title',
            detail: 'product_requests.effects.update_error.detail',
            life: 10000
          });
          return of(Noop());
        })
      )
    )
  ));

  updateProductRequestItemFulfilled$ = createEffect(() => this.actions$.pipe(
    ofType(ProductRequestActions.UpdateProductRequestItemFulfilled),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => this.productRequestService.updateProductRequestItemFulfilled(practice, action.productRequestItem, action.fulfilledCount)
      .pipe(
        map((result: ProductRequestItem) => {
          return ProductRequestActions.UpdateProductRequestItemFulfilledSuccess({productRequestItem: result});
        }),
        catchError((error) => {
          this.messageService.addTranslated({
            severity: 'error',
            summary: 'product_requests.effects.update_error.title',
            detail: 'product_requests.effects.update_error.detail',
            life: 10000
          });
          return of(Noop());
        })
      )
    )
  ));

  startUpdatingOnRejectProductRequest$ = createEffect(() => this.actions$.pipe(
    ofType(ProductRequestActions.RejectProductRequest),
    mergeMap((action) => {
      return of(
        ProductRequestActions.CloseProductRequestDetail(),
        ProductRequestActions.StartUpdatingProductRequest({productRequest: action.productRequest})
      );
    })
  ));

  rejectProductRequest$ = createEffect(() => this.actions$.pipe(
    ofType(ProductRequestActions.RejectProductRequest),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => this.productRequestService.rejectProductRequest(practice, action.productRequest, action.rejectionReason, action.notifyClient)
      .pipe(
        map((result: ProductRequest) => {
          return ProductRequestActions.RejectProductRequestSuccess({productRequest: result});
        }),
        catchError((error) => {
          this.messageService.addTranslated({
            severity: 'error',
            summary: 'product_requests.effects.update_error.title',
            detail: 'product_requests.effects.update_error.detail',
            life: 10000
          });
          return of(ProductRequestActions.StopUpdatingProductRequest({productRequest: action.productRequest}));
        })
      )
    )
  ));

  startUpdatingOnCreateProductRequestPayment$ = createEffect(() => this.actions$.pipe(
    ofType(ProductRequestActions.CreateProductRequestPayment),
    mergeMap((action) => {
      return of(
        ProductRequestActions.CloseProductRequestDetail(),
        ProductRequestActions.StartUpdatingProductRequest({productRequest: action.productRequest})
      );
    })
  ));

  createProductRequestPayment$ = createEffect(() => this.actions$.pipe(
    ofType(ProductRequestActions.CreateProductRequestPayment),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => this.productRequestService.createProductRequestPayment(practice, action.productRequest, action.paymentRequest)
      .pipe(
        map((result: ProductRequest) => {
          return ProductRequestActions.CreateProductRequestPaymentSuccess({productRequest: result});
        }),
        catchError((error) => {
          this.messageService.addTranslated({
            severity: 'error',
            summary: 'product_requests.effects.update_error.title',
            detail: 'product_requests.effects.update_error.detail',
            life: 10000
          });
          return of(ProductRequestActions.StopUpdatingProductRequest({productRequest: action.productRequest}));
        })
      )
    )
  ));

  startUpdatingOnConfirmProductRequestPaid$ = createEffect(() => this.actions$.pipe(
    ofType(ProductRequestActions.ConfirmProductRequestPaid),
    mergeMap((action) => {
      return of(
        ProductRequestActions.CloseProductRequestDetail(),
        ProductRequestActions.StartUpdatingProductRequest({productRequest: action.productRequest})
      );
    })
  ));

  confirmProductRequestPaid$ = createEffect(() => this.actions$.pipe(
    ofType(ProductRequestActions.ConfirmProductRequestPaid),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => this.productRequestService.confirmProductRequestPaid(practice, action.productRequest)
      .pipe(
        map((result: ProductRequest) => {
          return ProductRequestActions.ConfirmProductRequestPaidSuccess({productRequest: result});
        }),
        catchError((error) => {
          this.messageService.addTranslated({
            severity: 'error',
            summary: 'product_requests.effects.update_error.title',
            detail: 'product_requests.effects.update_error.detail',
            life: 10000
          });
          return of(ProductRequestActions.StopUpdatingProductRequest({productRequest: action.productRequest}));
        })
      )
    )
  ));

  startUpdatingOnDispenseProductRequest$ = createEffect(() => this.actions$.pipe(
    ofType(ProductRequestActions.DispenseProductRequest),
    mergeMap((action) => {
      return of(
        ProductRequestActions.CloseProductRequestDetail(),
        ProductRequestActions.StartUpdatingProductRequest({productRequest: action.productRequest})
      );
    })
  ));

  dispenseProductRequest$ = createEffect(() => this.actions$.pipe(
    ofType(ProductRequestActions.DispenseProductRequest),
    withLatestFrom(this.store.select(getCurrentPractice), this.store.select(getCurrentPracticeConfig)),
    mergeMap(([action, practice, config]) => this.productRequestService.dispenseProductRequest(practice, action.productRequest, action.notifyClient, config)
      .pipe(
        map((result: ProductRequest) => {
          return ProductRequestActions.DispenseProductRequestSuccess({productRequest: result});
        }),
        catchError((error) => {
          this.messageService.addTranslated({
            severity: 'error',
            summary: 'product_requests.effects.update_error.title',
            detail: 'product_requests.effects.update_error.detail',
            life: 10000
          });
          return of(ProductRequestActions.StopUpdatingProductRequest({productRequest: action.productRequest}));
        })
      )
    )
  ));

  getProductRequestHistory$ = createEffect(() => this.actions$.pipe(
      ofType(ProductRequestActions.GetProductRequestHistory),
      withLatestFrom(this.store.select(getCurrentPractice)),
      mergeMap(([action, practice]) => this.productRequestService.getHistory(practice, action.productRequestId)
        .pipe(
          map((result: AuditLog[]) => {
            return ProductRequestActions.GetProductRequestHistorySuccess({productRequestId: action.productRequestId, history: result});
          }),
          catchError((error) => {
            return of(Noop());
          })
        ))
    )
  );

  getProductRequestComments$ = createEffect(() => this.actions$.pipe(
      ofType(ProductRequestActions.GetProductRequestComments),
      withLatestFrom(this.store.select(getCurrentPractice)),
      mergeMap(([action, practice]) => this.productRequestService.getComments(practice, action.productRequestId)
        .pipe(
          map((result: Comment[]) => {
            return ProductRequestActions.GetProductRequestCommentsSuccess({productRequestId: action.productRequestId, comments: result});
          }),
          catchError((error) => {
            return of(Noop());
          })
        ))
    )
  );

  addProductRequestComment$ = createEffect(() => this.actions$.pipe(
      ofType(ProductRequestActions.AddProductRequestComment),
      withLatestFrom(this.store.select(getCurrentPractice)),
      mergeMap(([action, practice]) => this.productRequestService.addComment(practice, action.productRequestId, action.message)
        .pipe(
          map((result: Comment) => {
            return ProductRequestActions.AddProductRequestCommentSuccess({comment: result});
          }),
          catchError((error) => {
            this.messageService.addTranslated({
              severity: 'error',
              summary: 'product_requests.effects.add_comment_error.title',
              detail: 'product_requests.effects.add_comment_error.detail',
              life: 10000
            });
            return of(Noop());
          })
        ))
    )
  );

  getClient$ = createEffect(() => this.actions$.pipe(
    ofType(ProductRequestActions.GetProductRequestClient),
    withLatestFrom(this.store.select(getPatientPage)),
    mergeMap(([action, data]) => this.clientService.getClient(action.clientId, data).pipe(
      tap((result: { client: Client, patients: Patient[] }) => {
        this.store.dispatch(PatientActions.GetPatientsSuccess({patients: result.patients, replace: true}));
      }),
      map((result: { client: Client, patients: Patient[] }) => {
        return ProductRequestActions.GetProductRequestClientSuccess({client: result.client});
      }),
      takeUntil(this.actions$.pipe(ofType(PatientActions.ClearPatients))),
      catchError(() => {
        return of(ProductRequestActions.GetProductRequestClientFailed());
      })
    ))
  ));

  getClientPaymentHistory$ = createEffect(() => this.actions$.pipe(
    ofType(ProductRequestActions.GetProductRequestClientPaymentHistory),
    mergeMap((action) => this.clientService.getClientPaymentHistory(action.clientId).pipe(
      map((result: FullPayment[]) => {
        return ProductRequestActions.GetProductRequestClientPaymentHistorySuccess({payments: result});
      })
    ))
  ));

  getPatient$ = createEffect(() => this.actions$.pipe(
    ofType(ProductRequestActions.GetProductRequestPatient),
    mergeMap((action) => this.patientService.getPatient(action.patientId).pipe(
      map((result: Patient) => {
        return ProductRequestActions.GetProductRequestPatientSuccess({patient: result});
      }),
      catchError(() => {
        return of(ProductRequestActions.GetProductRequestPatientFailed());
      })
    ))
  ));

  getPatientHistory$ = createEffect(() => this.actions$.pipe(
      ofType(ProductRequestActions.GetProductRequestPatientHistory),
      mergeMap((action) => this.patientService.getHistory(action.patientId, 0, 10)
        .pipe(
          map((result: HistoryItem[]) => {
            return ProductRequestActions.GetProductRequestPatientHistorySuccess({historyItems: result});
          })
        ))
    )
  );

  updateProductRequestAssignee$ = createEffect(() => this.actions$.pipe(
    ofType(ProductRequestActions.UpdateProductRequestAssignee),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => this.productRequestService.updateProductRequestAssignee(practice, action.productRequest, action.assignee)
      .pipe(
        map((result: ProductRequest) => {
          return ProductRequestActions.UpdateProductRequestAssigneeSuccess({productRequest: result});
        }),
        catchError((error) => {
          this.messageService.addTranslated({
            severity: 'error',
            summary: 'product_requests.effects.update_assignee_error.title',
            detail: 'product_requests.effects.update_assignee_error.detail',
            life: 10000
          });
          return of(Noop());
        })
      )
    )
  ));

  updateProductRequestOwner$ = createEffect(() => this.actions$.pipe(
    ofType(ProductRequestActions.UpdateProductRequestOwner),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => this.productRequestService.updateProductRequestOwner(practice, action.productRequest, action.owner)
      .pipe(
        map((result: ProductRequest) => {
          return ProductRequestActions.UpdateProductRequestOwnerSuccess({productRequest: result});
        }),
        catchError((error) => {
          this.messageService.addTranslated({
            severity: 'error',
            summary: 'product_requests.effects.update_owner_error.title',
            detail: 'product_requests.effects.update_owner_error.detail',
            life: 10000
          });
          return of(Noop());
        })
      )
    )
  ));
}
