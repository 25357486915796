import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Observable, Subscription } from "rxjs";
import { takeWhile } from "rxjs/operators";
import { PracticeFeature } from "src/app/enums/practice-feature";
import { practiceHasFeature } from "src/app/helpers/practice-has-feature";
import { Client } from "src/app/models/Client";
import { Practice } from "src/app/models/Practice";
import { getCurrentPractice } from "src/app/practices/state/selectors";
import { AppState } from "src/app/state/reducers";
import { PMS } from "../../../enums/pms";

@Component({
  selector: "client-panel",
  templateUrl: "./client-panel.component.html",
  styleUrls: ["./client-panel.component.scss"],
})
export class ClientPanelComponent implements OnInit, OnDestroy {
  @Input() client?: Client;
  @Input() disabled?: boolean;
  @Input() shareDisabled = false;
  @Input() changeDisabled = false;
  @Input() shareLoading = false;
  @Input() shared = false;
  @Output() clientChanged = new EventEmitter();
  @Output() shareToPms = new EventEmitter<Client>();
  @Output() stepToConversation = new EventEmitter<Client>();
  @Output() stepToOpenActionsPopup = new EventEmitter<Client>();
  @Output() paymentHistoryOpen = new EventEmitter();
  alive = true;
  practice$?: Observable<Practice | null>;
  practiceSub$?: Subscription;
  practice?: Practice | null;
  shareEnabled = false;
  shareLabel = "Share client to PMS";

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    if (this.shareLoading === false) {
      this.shared = false;
    }
    this.subscribeToCurrentPractice();
  }

  ngOnDestroy(): void {
    this.alive = false;
    this.practiceSub$?.unsubscribe();
  }

  subscribeToCurrentPractice(): void {
    this.practice$ = this.store
      .pipe(select(getCurrentPractice))
      .pipe(takeWhile(() => this.alive));

    this.practiceSub$ = this.practice$.subscribe((practice) => {
      this.practice = practice;
      this.updateShareEnabled();
      this.updateShareLabel();
    });
  }

  handleClientChanged(): void {
    this.clientChanged.emit();
  }

  emitShareToPms(): void {
    if (this.client && !this.disabled) {
      this.shareToPms.emit(this.client);
    }
  }

  handleStepToConversation(): void {
    this.stepToConversation.emit();
  }

  handleStepToOpenActionPopup(): void {
    this.stepToOpenActionsPopup.emit();
  }

  updateShareEnabled(): void {
    this.shareEnabled = false;

    if (
      !this.shareDisabled &&
      this.practice &&
      practiceHasFeature(this.practice, PracticeFeature.CLIENT_SHARE_TO_PMS) &&
      ((this.practice.pms !== PMS.PROVET &&
        this.practice.pms !== PMS.ASCEND &&
        this.practice.pms !== PMS.EZYVET) ||
        ((this.practice.pms === PMS.PROVET ||
          this.practice.pms === PMS.ASCEND ||
          this.practice.pms === PMS.EZYVET) &&
          this.client?.url))
    ) {
      this.shareEnabled = true;
    }
  }

  updateShareLabel(): void {
    this.shareLabel = "Share client to PMS";

    if (this.practice) {
      switch (this.practice.pms) {
        case PMS.PROVET:
        case PMS.ASCEND:
        case PMS.EZYVET:
          this.shareLabel = "Open client in " + this.practice.pms;
          break;

        default:
          this.shareLabel = "Share client to " + this.practice.pms;
      }
    }
  }

  paymentHistoryOpened(): void {
    this.paymentHistoryOpen.emit();
  }
}
