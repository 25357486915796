import { createFeatureSelector, createSelector } from '@ngrx/store';
import { STATE_KEY } from 'src/app/state/reducers';
import { PatientState } from './reducer';

export const getPatientState = createFeatureSelector<PatientState>(STATE_KEY.PATIENTS);

export const getPatients = createSelector(
    getPatientState,
    (state: PatientState) => state.patients,
);

export const getPatientPage = createSelector(
    getPatientState,
    (state: PatientState) => state.page,
);
