import {createFeatureSelector, createSelector} from '@ngrx/store';
import {STATE_KEY} from '../../../app/state/reducers';
import {TemplateState} from './reducer';
import {TemplateFilterType} from '../../enums/template-filter-type';
import {TemplateFilterSelection} from '../../interfaces/template-filter-selection';
import {isTemplateSuitableForCampaigns} from '../../helpers/is-template-suitable-for-campaigns';
import {isSystemTemplate} from '../../helpers/is-system-template';
import {isStandardTemplate} from '../../helpers/is-standard-template';
import {TemplateArchiveStatus} from "../../enums/template-archive-status";
import {TemplateType} from "../../enums/template-type";

export const getTemplateState = createFeatureSelector<TemplateState>(STATE_KEY.TEMPLATES);

export const getTemplates = createSelector(
  getTemplateState,
  (state: TemplateState) => state.templates,
);

export const getTemplatesLoading = createSelector(
  getTemplateState,
  (state: TemplateState) => state.templatesLoading,
);

export const getDefaultTemplates = createSelector(
  getTemplateState,
  (state: TemplateState) => state.defaultTemplates,
);

export const getActivePaymentTemplate = createSelector(
  getTemplateState,
  (state: TemplateState) => state.activePaymentTemplate,
);

export const getActiveTemplateTab = createSelector(
  getTemplateState,
  (state: TemplateState) => state.activeTemplateTab,
);

export const getStandardTypeTemplates = createSelector(
  getTemplateState,
  (state: TemplateState) => state.templates.filter(t => t.type === TemplateType.Standard && t.archived === TemplateArchiveStatus.NOT_ARCHIVED),
);

export const getStandardTemplates = createSelector(
  getTemplateState,
  (state: TemplateState) => state.templates.filter(t => isStandardTemplate(t) && t.archived === TemplateArchiveStatus.NOT_ARCHIVED),
);

export const getSystemTemplates = createSelector(
  getTemplateState,
  (state: TemplateState) => state.templates.filter(t => isSystemTemplate(t) && t.archived === TemplateArchiveStatus.NOT_ARCHIVED).sort((a, b) => {
    return a.active.localeCompare(b.active);
  }),
);

export const getStandardTemplatesIncludingArchived = createSelector(
  getTemplateState,
  (state: TemplateState) => state.templates.filter(t => isStandardTemplate(t)),
);

export const getSystemTemplatesIncludingArchived = createSelector(
  getTemplateState,
  (state: TemplateState) => state.templates.filter(t => isSystemTemplate(t)).sort((a, b) => {
    return a.active.localeCompare(b.active);
  }),
);

export const getCampaignTemplates = createSelector(
  getTemplateState,
  (state: TemplateState) => state.templates.filter(template => isTemplateSuitableForCampaigns(template))
);

export const getStandardAndCampaignTemplate = createSelector(
  getTemplateState,
  (state: TemplateState) => state.templates.filter(template => (template.type === TemplateType.Campaign || template.type === TemplateType.Standard) && template.archived === TemplateArchiveStatus.NOT_ARCHIVED)
);

export const getTemplateFilterSelection = createSelector(
  getTemplateState,
  (state: TemplateState) => state.filters
);

export const getFilterSelectedTypes = createSelector(
  getTemplateFilterSelection,
  (state: TemplateFilterSelection) => state[TemplateFilterType.TYPE]
);

export const getFilterSelectedConsentRequired = createSelector(
  getTemplateFilterSelection,
  (state: TemplateFilterSelection) => state[TemplateFilterType.CONSENT_REQUIRED]
);

export const getFilterSelectedSubmissionStatuses = createSelector(
  getTemplateFilterSelection,
  (state: TemplateFilterSelection) => state[TemplateFilterType.SUBMISSION_STATUS]
);

export const getFilterSelectedStatuses = createSelector(
  getTemplateFilterSelection,
  (state: TemplateFilterSelection) => state[TemplateFilterType.STATUS]
);

export const getFilterSelectedArchiveStatuses = createSelector(
  getTemplateFilterSelection,
  (state: TemplateFilterSelection) => state[TemplateFilterType.ARCHIVE_STATUS]
);

export const getFilterSelectedCategoryStatuses = createSelector(
  getTemplateFilterSelection,
  (state: TemplateFilterSelection) => state[TemplateFilterType.CATERGORY]
);

export const getFilterTemplatesSearchTerm = createSelector(
  getTemplateFilterSelection,
  (state: TemplateFilterSelection) => state[TemplateFilterType.SEARCH_TERM]
);
