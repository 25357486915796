import { Injectable } from '@angular/core';
import {AuditLog} from '../models/AuditLog';
import {AuditLogDto} from '../interfaces/dto/audit-log.dto';
import {UserAdapter} from './user.adapter';

@Injectable({
  providedIn: 'root'
})
export class AuditLogAdapter {
  constructor(private userAdapter: UserAdapter) {
  }

  run(dto: AuditLogDto): AuditLog {
    return {
      ...dto,
      actioner: dto.actioner ? this.userAdapter.run(dto.actioner) : null,
      createdAt: new Date(dto.createdAt),
    };
  }

}
