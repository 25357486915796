const colours = [
    '#2F80ED',
    '#F72585',
    '#7209B7',
    '#3A0CA3',
    '#4361EE',
    '#03045E',
    '#0077B6',
    '#A5668B',
    '#F15BB5',
    '#586BA4',
    '#087E8B',
    '#540D6E',
    '#557571',
    '#324376',
    '#B5179E',
    '#3F37C9',
    '#8F2D56',
    '#D81159',
    '#4895EF',
    '#43AA8B',
    '#577590',
    '#8E7DBE',
    '#EF476F',
    '#00B2CA',
    '#1B9AAA',
];

function alphabetPosition(letter: string): number {
    return [...letter].map(a => parseInt(a, 36) - 10).filter(a => a >= 0)[0];
}

export function generateColour(lastName: string | undefined) {
  if (lastName) {
    const letter: string = lastName;
    const pos = alphabetPosition(letter);
    if (colours[pos]) {
      return colours[pos];
    }
  }

  return colours[0];
}