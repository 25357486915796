import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {FileUpload} from 'primeng/fileupload';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../state/reducers';
import {AddMedia, AddVideo} from '../../state/actions';
import { Observable, takeWhile } from 'rxjs';
import { MediaFolder } from '../../../models/MediaFolder';
import { getCurrentMediaFolder } from '../../state/selectors';

@Component({
  selector: 'app-add-media',
  templateUrl: './add-media.component.html',
  styleUrls: ['./add-media.component.scss']
})
export class AddMediaComponent implements OnInit, OnDestroy {
  alive = true;
  @ViewChild('fileUpload') fileUpload?: FileUpload;
  form = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    asset: new UntypedFormControl(null, Validators.required),
  });
  previewSrc: string | null = null;
  currentFolder: MediaFolder | null = null;
  showVideoLengthWarning = false;

  constructor(
    public ref: DynamicDialogRef,
    private store: Store<AppState>,
    ) {
      this.store.pipe(select(getCurrentMediaFolder)).pipe(takeWhile(() => this.alive)).subscribe(folder => this.currentFolder = folder);
    }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.alive = false;
  }
  uploadFile(event: {files: File[] }): void {
    this.showVideoLengthWarning = false;

    for (const file of event.files) {
      this.form.patchValue({ asset: file });
      this.form.get('asset')?.updateValueAndValidity();

      this.previewSrc = null;

      if (file.type.includes('image/')) {
        const reader = new FileReader();
        reader.onload = (e) => {
          if (typeof reader.result === 'string') {
            this.previewSrc = reader.result;
          }
        };
        reader.readAsDataURL(file);
      }

      if (file.type.includes('video/')) {
        this.validateDuration(file);
      }

      if (this.form.controls.asset.value.name) {
        let fileName;
        fileName = this.removeExtension(this.form.controls.asset.value.name);
        this.form.controls.name.setValue(fileName);
      }

      this.fileUpload?.clear();
    }
  }

  removeExtension(filename: string): string {
    return filename.substring(0, filename.lastIndexOf('.')) || filename;
  }

  submit(): void {
    Object.keys(this.form.controls).forEach(field => {
      const control = this.form.get(field);
      control?.markAsTouched({ onlySelf: true });
    });

    if (this.form.valid) {
      if (this.form.controls.asset?.value?.type?.includes('video/')) {
        this.store.dispatch(AddVideo({
          media: {
            name: this.form.controls.name.value,
            file: this.form.controls.asset.value,
            parentId: this.currentFolder ? this.currentFolder.id : null
          }
        }));
      } else {
        this.store.dispatch(AddMedia({
          media: {
            name: this.form.controls.name.value,
            file: this.form.controls.asset.value,
            parentId: this.currentFolder ? this.currentFolder.id : null
          }
        }));
      }
      this.ref.close();
    }
  }

  private validateDuration(file: File): void {
    const video = document.createElement('video');
    video.preload = 'metadata';

    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);

      if (video.duration > 60) {
        this.showVideoLengthWarning = true;
      } else {
        this.showVideoLengthWarning = false;
      }
    };

    video.src = URL.createObjectURL(file);
  }
}
