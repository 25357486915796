import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Patient } from 'src/app/models/Patient';

@Component({
  selector: 'no-patient-panel',
  templateUrl: './no-patient-panel.component.html',
  styleUrls: ['./no-patient-panel.component.scss']
})
export class NoPatientPanelComponent implements OnInit {
  @Input() disabled?: boolean;
  @Input() patients?: Patient[];
  @Output() patientSelected = new EventEmitter<Patient>();
  @Output() morePatients = new EventEmitter<null>();

  constructor() { }

  ngOnInit(): void {
  }

  handlePatientSelected(patient: Patient): void {
    this.patientSelected.emit(patient);
  }

  handleMorePatients(): void {
    this.morePatients.emit();
  }
}
