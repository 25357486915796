import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../models/User';
import { Group } from '../models/Group';

@Pipe({name: 'mentions'})
export class MentionsPipe implements PipeTransform {
  transform(value: string, users?: User[], groups?: Group[]): string {
    if (users && groups) {
      let mentions = value.match(/(@[^\s]+)/g)?.map((match) => match);

      // Remove duplicates
      if (mentions) {
        mentions = mentions.filter((item, index) => mentions && mentions.indexOf(item) === index);
      }

      mentions?.forEach(mention => {
        const filteredUsers = users.filter(user => `@${user.firstName}${user.lastName}` === mention);

        if (filteredUsers.length > 0) {
          filteredUsers.forEach(user => {
            const regex = new RegExp('@' + user.firstName + user.lastName, 'g');
            value = value.replace(regex, '<span class="mention-tag"><span>@' + user.firstName + user.lastName + '</span></span>');
          });
        } else {
          groups.filter(group => `@${group.name}` === mention).forEach(group => {
            const regex = new RegExp('@' + group.name, 'g');
            value = value.replace(regex, '<span class="mention-tag"><span>@' + group.name + '</span></span>');
          });
        }
      });
    }

    return value;
  }
}
