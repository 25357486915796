import { Component, EventEmitter, Input, OnInit, OnChanges, Output, OnDestroy } from '@angular/core';
import { Conversation } from 'src/app/models/Conversation';
import { ConversationStatus } from 'src/app/models/ConversationStatus';
import { User } from 'src/app/models/User';
import { Client } from '../../../models/Client';
import { Observable, Subscription } from 'rxjs';
import { Group } from '../../../models/Group';
import {select, Store} from '@ngrx/store';
import { AppState } from '../../../state/reducers';
import {
  SetConversationAsResolved,
  SetTagsOnConversation
} from '../../state/actions';
import {Tag} from '../../../models/Tag';
import {getTags} from '../../../state/selectors';
import {takeWhile} from 'rxjs/operators';
import {getCurrentPractice} from "../../../practices/state/selectors";
import {Practice} from "../../../models/Practice";
import {Role} from "../../../enums/role";

@Component({
  selector: 'conversation-details',
  templateUrl: './conversation-details.component.html',
  styleUrls: ['./conversation-details.component.scss']
})
export class ConversationDetails implements OnInit, OnChanges, OnDestroy {
  @Input() conversation: Conversation | null = null;
  @Input() conversationStatuses: ConversationStatus[] = [];
  @Input() authUser: User | null = null;
  @Input() client$?: Observable<Client | null>;
  @Output() statusChange = new EventEmitter<ConversationStatus>();
  @Output() ownerChange = new EventEmitter<User>();
  @Output() assigneeChange = new EventEmitter<User|Group>();
  @Output() summaryChange = new EventEmitter<string>();
  @Output() stepToClient = new EventEmitter<boolean>();
  @Output() stepToTeam = new EventEmitter<boolean>();
  @Output() stepToClientAndOpenActions = new EventEmitter<boolean>();
  @Output() watch = new EventEmitter<User>();
  @Output() unwatch = new EventEmitter<User>();
  superAdmin = Role.SUPER_ADMIN;
  analyst = Role.ANALYST;
  alive = true;
  detailsOpen = true;
  clientSub$?: Subscription;
  client?: Client | null;
  summary = '';
  previousSummary = '';
  summaryButtonsActive = false;
  allTags: Tag[] = [];
  selectedTags: Tag[] = [];
  showManageTags = false;
  practice: Practice | null = null;

  constructor(private store: Store<AppState>) {
  }

  ngOnInit(): void {
    this.subscribeToClient();
    this.subscribeToPractice();
    this.subscribeToTags();
    this.selectedTags = this.conversation?.tags ?? [];
  }

  ngOnChanges(): void {
    this.summary = this.conversation && this.conversation.summary ? this.conversation.summary : '';
    this.previousSummary = this.conversation && this.conversation.summary ? this.conversation.summary : '';
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  showSummaryButtons(): void {
    this.summaryButtonsActive = true;
  }

  revertSummaryChanges(): void {
    this.summary = this.previousSummary;
    this.summaryButtonsActive = false;
  }

  updateSummary(): void {
    this.summaryButtonsActive = false;
    this.summaryChange.emit(this.summary);
  }

  handleStatusChange(status: ConversationStatus): void {
    this.statusChange.emit(status);
  }

  handleOwnerChange(changedTo: User): void {
    this.ownerChange.emit(changedTo);
  }

  handleAssigneeChange(changedTo: User|Group): void {
    this.assigneeChange.emit(changedTo);
  }

  resolveConversation(settings: {sendToPms: boolean, downloadPdf: boolean }): void {
    if (this.conversation) {
      this.store.dispatch(SetConversationAsResolved({conversationId: this.conversation.id, saveToPms: settings.sendToPms, downloadPdf: settings.downloadPdf}));
    }
  }

  subscribeToClient(): void {
    if (this.client$) {
      this.clientSub$ = this.client$.subscribe((client) => {
        this.client = client;
      });
    }
  }

  subscribeToTags(): void {
    this.store.select(getTags).pipe(
      takeWhile(() => this.alive)
    ).subscribe((tags) => {
      this.allTags = tags;
    });
  }

  subscribeToPractice(): void {
    this.store.pipe(select(getCurrentPractice)).pipe(
      takeWhile(() => this.alive)
    ).subscribe(practice => {
      this.practice = practice;
    });
  }

  handleStepToClient(): void {
    this.stepToClient.emit();
  }

  handleStepToClientAndOpenActions(): void {
    this.stepToClientAndOpenActions.emit();
  }

  handleWatch(user: User): void {
    this.watch.emit(user);
  }

  handleUnwatch(user: User): void {
    this.unwatch.emit(user);
  }

  toggleDetailsOpen(): void {
    this.detailsOpen = !this.detailsOpen;
  }

  handleStepToTeam(): void {
    this.stepToTeam.emit();
  }

  setTags(tags: Tag[]): void {
    if (this.conversation) {
      this.store.dispatch(SetTagsOnConversation({tags, conversation: this.conversation}));
    }
  }

  handleTagsChanged(tags: Tag[]): void {
    this.selectedTags = tags;
    this.setTags(this.selectedTags);
  }
}
