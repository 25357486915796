import { Injectable } from '@angular/core';
import { Notification } from '../models/Notification';
import { NotificationDto } from '../notifications/dto/notification.dto';
import { PracticeAdapter } from './practice.adapter';
import { UserAdapter } from './user.adapter';

@Injectable({
  providedIn: 'root'
})
export class NotificationAdapter {
  constructor(
    private userAdapter: UserAdapter,
    private practiceAdapter: PracticeAdapter
  ) {}

  run(dto: NotificationDto): Notification {
    return {
        ...dto,
        actioner: dto.actioner ? this.userAdapter.run(dto.actioner) : null,
        practice: this.practiceAdapter.run(dto.practice),
        note: dto.note ? JSON.parse(dto.note) : null,
        createdAt: new Date(dto.createdAt),
        updatedAt: new Date(dto.updatedAt),
    };
  }
}
