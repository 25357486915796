import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { AppState } from 'src/app/state/reducers';
import { GetPracticeGroups, GetPracticeStaff } from 'src/app/practices/state/actions';
import {
  getPracticeGroups,
  getPracticeStaffWithoutUnassigned,
  getPracticeStaffWithUnassigned
} from 'src/app/practices/state/selectors';
import { User } from '../../../models/User';
import { Group } from '../../../models/Group';
import { getUser } from '../../../auth/state/selectors';

@Component({
  selector: 'user-changer',
  templateUrl: './user-changer.component.html',
  styleUrls: ['./user-changer.component.scss']
})
export class UserChangerComponent implements OnInit, OnDestroy {
  @Input() selected?: User | Group | null;
  @Input() withGroups = true;
  @Input() disabled?: boolean;
  @Input() size = 'medium';
  @Input() includeUnassigned = true;
  @Output() userChange = new EventEmitter<User|Group>();

  alive = true;
  authUser$?: Observable<User | null>;
  authUser?: User;
  authUserSub?: Subscription;
  users$?: Observable<User[]>;
  groups$?: Observable<Group[]>;
  flyoutActive = false;

  constructor(private store: Store<AppState>) {
  }

  ngOnInit(): void {
    this.subscribeToPracticeStaff();
    this.subscribeToAuthUser();

    if (this.withGroups) {
      this.subscribeToPracticeGroups();
    }
  }

  ngOnDestroy(): void {
    this.alive = false;
    this.authUserSub?.unsubscribe();
  }

  subscribeToAuthUser(): void {
    this.authUser$ = this.store.pipe(select(getUser)).pipe(
      takeWhile(() => this.alive)
    );

    this.authUserSub = this.authUser$.subscribe(user => {
      if (user) {
        this.authUser = user;
      }
    });
  }

  toggleFlyout(): void {
    if (!this.flyoutActive) {
      this.store.dispatch(GetPracticeGroups());
      this.store.dispatch(GetPracticeStaff());
    }

    this.flyoutActive = !this.flyoutActive;
  }

  subscribeToPracticeStaff(): void {
    if (this.includeUnassigned) {
      this.users$ = this.store.pipe(select(getPracticeStaffWithUnassigned)).pipe(
        takeWhile(() => this.alive)
      );
    } else {
      this.users$ = this.store.pipe(select(getPracticeStaffWithoutUnassigned)).pipe(
        takeWhile(() => this.alive)
      );
    }
  }

  subscribeToPracticeGroups(): void {
    this.groups$ = this.store.pipe(select(getPracticeGroups)).pipe(
      takeWhile(() => this.alive)
    );
  }

  handleUserChange(selected: User|Group): void {
    this.toggleFlyout();
    this.userChange.emit(selected);
  }

  /*
  * This handles when the user selects the currently selected user and closes the flyout
  */
  handleCurrentUserSelected(): void {
    this.toggleFlyout();
  }

  closeFlyout(): void {
    this.flyoutActive = false;
  }

  handleClickModalBackground(): void {
    this.flyoutActive = false;
  }
}
