import { createFeatureSelector, createSelector } from '@ngrx/store';
import { STATE_KEY } from 'src/app/state/reducers';
import {MediaState} from './reducer';

export const getMediaState = createFeatureSelector<MediaState>(STATE_KEY.MEDIA);

export const getMedia = createSelector(
  getMediaState,
  (state: MediaState) => state[state.currentBrowser].media,
);

export const getTotalMediaCount = createSelector(
  getMediaState,
  (state: MediaState) => state[state.currentBrowser].totalMediaCount,
);

export const getMediaLoading = createSelector(
  getMediaState,
  (state: MediaState) => state[state.currentBrowser].mediaLoading,
);

export const isUploadingVideo = createSelector(
  getMediaState,
  (state: MediaState) => state.videoUploading
);

export const isVideoProcessing = createSelector(
  getMediaState,
  (state: MediaState) => state.videoProcessing
);

export const getVideoUploadPercentage = createSelector(
  getMediaState,
  (state: MediaState) => state.videoUploadProgress
);

export const getVideoCompressionJobId = createSelector(
  getMediaState,
  (state: MediaState) => state.videoCompressionJobId
);

export const getCurrentMediaFolder = createSelector(
  getMediaState,
  (state: MediaState) => state[state.currentBrowser].currentFolder
);

export const getMediaFoldersLoading = createSelector(
  getMediaState,
  (state: MediaState) => state[state.currentBrowser].foldersLoading,
);

export const getMediaFolders = createSelector(
  getMediaState,
  (state: MediaState) => state[state.currentBrowser].folders,
);

export const getMediaFolderBreadcrumbs = createSelector(
  getMediaState,
  (state: MediaState) => state[state.currentBrowser].folderBreadcrumbs,
);

export const getMediaFolderTree = createSelector(
  getMediaState,
  (state: MediaState) => state.folderTree,
);

export const getMediaFolderTreeExcludingSelectedFoldersChildren = createSelector(
  getMediaState,
  (state: MediaState) => {
    return state.folderTree.filter((folder) => {
      let parentId: number | null = folder.parentId;
      while (parentId !== null) {
        if (parentId === state[state.currentBrowser].folderToMove?.id) {
          return false;
        }
        parentId = state.folderTree.find((f) => f.id === parentId)?.parentId ?? null;
      }

      return folder.id !== state[state.currentBrowser].folderToMove?.id;
    });
  }
);

export const getMediaFilters = createSelector(
  getMediaState,
  (state: MediaState) => state[state.currentBrowser].filters,
);

export const getMediaPage = createSelector(
  getMediaState,
  (state: MediaState) => state[state.currentBrowser].currentPage,
);