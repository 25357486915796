import { CurrencyDto } from '../interfaces/dto/currency.dto';
import { Currency } from '../models/Currency';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CurrencyAdapter {
  run(data: CurrencyDto): Currency {
    return {
      ...data
    };
  }
}
