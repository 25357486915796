import { Injectable } from '@angular/core';
import { ProcedureItemDto } from '../interfaces/dto/procedure-item.dto';
import { ProcedureItem } from '../models/ProcedureItem';

@Injectable({
  providedIn: 'root'
})
export class ProcedureItemAdapter {
    constructor() {}

    run(dto: ProcedureItemDto): ProcedureItem {
        return {
            itemName: dto.itemName,
            itemLabel: dto.itemLabel,
            batchNumber: dto.batchNumber,
            type: dto.type,
            itemNumber: dto.itemNumber,
            injectable: dto.injectable,
            price: dto.price,
        };
    }
}
