import {Conversation} from '../models/Conversation';
import {ReadStatus} from '../enums/read-status';

export function sortConversations(array: Conversation[]): Conversation[] {
  return array.sort((a, b) => {
    // Sort by lastResponseAt - newest first
    if (!a.lastResponseAt) {
      return -1;
    }
    if (!b.lastResponseAt) {
      return 1;
    }

    return b.lastResponseAt.getTime() - a.lastResponseAt.getTime();
  }).sort((a, b) => {
    // Sort by status - with clinic first
    if (!a.status) {
      return 1;
    }
    if (!b.status) {
      return -1;
    }

    return a.status.order - b.status.order;
  }).sort((a, b) => {
    // Sort by newMessage - put unread messages to the top of the list
    if (
      ((a.newMessage > 0 && a.readStatus === ReadStatus.UNSET) || a.readStatus === ReadStatus.UNREAD) &&
      ((b.newMessage > 0 && b.readStatus === ReadStatus.UNSET) || b.readStatus === ReadStatus.UNREAD)
    ) {
      // message a & message b are both unread
      return 0;
    }

    if (
      ((a.newMessage > 0 && a.readStatus === ReadStatus.UNSET) || a.readStatus === ReadStatus.UNREAD)
    ) {
      // message a is unread
      return -1;
    }

    return 1;
  });
}
