import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {getCurrentPractice} from '../../../practices/state/selectors';
import {takeWhile} from 'rxjs/operators';
import {practiceHasFeature} from '../../../helpers/practice-has-feature';
import {PracticeFeature} from '../../../enums/practice-feature';
import {AppState} from '../../../state/reducers';
import {Observable} from 'rxjs';
import {Practice} from '../../../models/Practice';
import {OpenNewCampaign} from '../../../campaigns/state/actions';
import {OpenNewPaymentRequest} from '../../../payments/state/actions';
import {OpenNewConversation} from '../../../dialogs/state/actions';
import {OpenProductRequest} from '../../../product-requests/state/actions';
import {isMessagingEnabled} from '../../../helpers/is-messaging-enabled';
import {OpenNewClientDeposit} from "../../../clients/state/actions";
import {OpenFormRequest} from "../../../forms/state/actions";

@Component({
  selector: 'create-actions',
  templateUrl: './create-actions.component.html',
  styleUrls: ['./create-actions.component.scss']
})
export class CreateActionsComponent implements OnInit, OnDestroy {
  @Input() fullWidth = false;
  @Output() actionSelected = new EventEmitter();
  alive = true;
  currentPractice$?: Observable<Practice | null>;
  practice?: Practice;

  createActions: { featureEnabled: boolean, slug: string, label: string, icon: string, disabled: boolean, command: () => any }[] = [
    {
      featureEnabled: false,
      icon: '/assets/images/icons/conversation-dark-blue.svg',
      label: 'Conversation',
      slug: 'conversation',
      disabled: false,
      command: () => {
        this.store.dispatch(OpenNewConversation({}));
        this.actionSelected.emit();
      }
    },
    {
      featureEnabled: false,
      icon: '/assets/images/icons/creditcard-dark-blue.svg',
      label: 'Payment request',
      slug: 'payment-request',
      disabled: false,
      command: () => {
        this.store.dispatch(OpenNewPaymentRequest());
        this.actionSelected.emit();
      }
    },
    {
      featureEnabled: false,
      icon: '/assets/images/icons/bullhorn-dark-blue.svg',
      label: 'Broadcast',
      slug: 'broadcast',
      disabled: false,
      command: () => {
        this.store.dispatch(OpenNewCampaign());
        this.actionSelected.emit();
      }
    },
    {
      featureEnabled: false,
      icon: '/assets/images/icons/icon-medicine-dark-blue.svg',
      label: 'Product request',
      slug: 'product-request',
      disabled: false,
      command: () => {
        this.store.dispatch(OpenProductRequest({}));
        this.actionSelected.emit();
      }
    },
    {
      featureEnabled: false,
      icon: '/assets/images/icons/icon-deposit.svg',
      label: 'Deposit',
      slug: 'deposit',
      disabled: false,
      command: () => {
        this.store.dispatch(OpenNewClientDeposit());
        this.actionSelected.emit();
      }
    },
    {
      featureEnabled: false,
      icon: '/assets/images/icons/icon-form-dark-blue.svg',
      label: 'Form request',
      slug: 'forms',
      disabled: false,
      command: () => {
        this.store.dispatch(OpenFormRequest({}));
        this.actionSelected.emit();
      }
    },
  ];

  constructor(
    private store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.subscribeToCurrentPractice();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  subscribeToCurrentPractice(): void {
    this.currentPractice$ = this.store.pipe(select(getCurrentPractice)).pipe(takeWhile(() => this.alive));

    this.currentPractice$.subscribe((practice) => {
      if (practice) {
        this.practice = practice;
        this.updateFeaturesEnabled();
      }
    });
  }

  updateFeaturesEnabled(): void {
    this.createActions = this.createActions.map((createAction) => {
      return {
        ...createAction,
        featureEnabled: false
      };
    });

    if (!this.practice) {
      return;
    }

    let action;

    if (isMessagingEnabled(this.practice)) {
      action = this.createActions.find((createAction) => createAction.slug === 'conversation');
      if (action) {
       action.featureEnabled = true;
      }

      if (practiceHasFeature(this.practice, PracticeFeature.PAYMENTS) && practiceHasFeature(this.practice, PracticeFeature.SHOW_PAYMENT_REQUEST_BUTTON)) {
        action = this.createActions.find((createAction) => createAction.slug === 'payment-request');
        if (action) {
          action.featureEnabled = true;
        }
      }

      if (practiceHasFeature(this.practice, PracticeFeature.CAMPAIGNS)) {
        action = this.createActions.find((createAction) => createAction.slug === 'broadcast');
        if (action) {
          action.featureEnabled = true;
        }
      }

      if (practiceHasFeature(this.practice, PracticeFeature.PRODUCT_REQUESTS)) {
        action = this.createActions.find((createAction) => createAction.slug === 'product-request');
        if (action) {
          action.featureEnabled = true;
        }
      }

      if (practiceHasFeature(this.practice, PracticeFeature.FORMS)) {
        action = this.createActions.find((createAction) => createAction.slug === 'forms');
        if (action) {
          action.featureEnabled = true;
        }
      }
    }

    if (practiceHasFeature(this.practice, PracticeFeature.DISPLAY_CLIENT_DEPOSIT)) {
      action = this.createActions.find((createAction) => createAction.slug === 'deposit');
      if (action) {
        action.featureEnabled = true;
      }
    }
  }
}
