import {Pipe, PipeTransform} from '@angular/core';

import {FormFieldType} from "../enums/form-field-type.enum";

@Pipe({name: 'fieldTypeHasPlaceholder'})
export class FieldTypeHasPlaceholderPipe implements PipeTransform {
  transform(value: string): boolean {
    const fieldType = value as FormFieldType;
    return fieldType === FormFieldType.TEXT || fieldType === FormFieldType.TEXTAREA;
  }
}
