import { Pipe, PipeTransform } from '@angular/core';
import {prettifyFormInputType} from '../helpers/prettify-form-input-type';
import {FormFieldType} from '../enums/form-field-type.enum';

@Pipe({name: 'prettifyFormInputType'})
export class PrettifyFormInputType implements PipeTransform {
  transform(value: string): string {
    return prettifyFormInputType(value as FormFieldType);
  }
}
