<form class="rename-folder" [formGroup]="form">
  <div class="input-row">
    <div class="label">Name:</div>
    <input #nameInput pInputText class="p-inputtext" type="text" formControlName="name" maxlength="50">
    <ng-container *ngIf="form.controls.name.invalid && (form.controls.name.dirty || form.controls.name.touched) && form.controls.name.errors?.['required']">
      <div class="error">You must enter a name for this file.</div>
    </ng-container>
  </div>
  <div class="btn-row">
    <p-button (click)="submit()" label="Rename" styleClass="p-button-primary"></p-button>
  </div>
</form>
