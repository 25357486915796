import { Pipe, PipeTransform } from '@angular/core';
import {Template} from '../models/Template';
import {canTemplateBeEdited} from '../helpers/can-template-be-edited';

@Pipe({name: 'canTemplateBeEditedPipe'})
export class CanTemplateBeEditedPipe implements PipeTransform {
  transform(template: Template): boolean {
    return canTemplateBeEdited(template);
  }
}
