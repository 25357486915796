<div class="wrap">
    <p-dialog
        [header]="'ui.dialog_no_connection.error' | translate"
        [visible]="true"
        [modal]="true"
        [style]="{width: '400px'}"
        [baseZIndex]="10000"
        [draggable]="false"
        [resizable]="false"
        styleClass="no-practice-dialog"
    >
        <div class="message" [innerHTML]="'ui.dialog_no_connection.message' | translate"></div>
        <div class="btn-row"><button pButton [label]="'ui.dialog_no_connection.reload_page' | translate" class="pm-button-primary" (click)="refresh()"></button></div>
    </p-dialog>
</div>
