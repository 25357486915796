<div class="conversations">
  <div class="constrainer">
    <conversation-list-component
      [loading]="loading"
      [conversations]="conversations"
      [totalConversationsCount]="totalConversationsCount"
      (getConversations)="handleFetchConversations($event)"
    ></conversation-list-component>
  </div>
</div>
