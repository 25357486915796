<ng-container *ngIf="unreadHelpPosts.length > 0">
  <p-dialog
    header="What's new?"
    [(visible)]="modalOpen"
    [modal]="true"
    [style]="{width: '600px'}"
    [baseZIndex]="10000"
    [draggable]="false"
    [resizable]="false"
    [closable]="false"
    [closeOnEscape]="false"
  >
    <div class="whats-new">
      <div class="help-posts">
        <ng-container *ngFor="let post of unreadHelpPosts">
          <div class="help-post">
            <div class="help-post-header">
              <div class="help-post-title">{{ post.title }}</div>
              <div class="help-post-date">{{ post.publishedAt | date : 'd MMM, yyyy' }}</div>
            </div>
            <div class="help-post-content">
              <quill-view [content]="post.content" format="object" theme="snow"></quill-view>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="whats-new-footer">
        <button pButton label="Dismiss" class="p-button-secondary" (click)="closeNewFeatures()"></button>
      </div>
    </div>
  </p-dialog>
</ng-container>
