import { Injectable } from '@angular/core';
import { ConversationStatusDto } from '../interfaces/dto/conversation-status.dto';
import { ConversationStatus } from '../models/ConversationStatus';

@Injectable({
  providedIn: 'root'
})
export class ConversationStatusAdapter {
  run(dto: ConversationStatusDto): ConversationStatus {
    return {
      ...dto,
      id: dto.id.toString(),
      order: dto.order,
      createdAt: new Date(dto.createdAt),
      updatedAt: new Date(dto.updatedAt),
    };
  }
}
