import {Component, Input} from '@angular/core';
import {Form} from '../../interfaces/form';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {ArchiveForm} from '../../state/actions';

@Component({
  selector: 'form-list-item',
  templateUrl: './form-list-item.component.html',
  styleUrls: ['./form-list-item.component.scss']
})
export class FormListItemComponent {
  @Input() form?: Form;
  actionsOpen = false;

  constructor(
    private router: Router,
    private store: Store,
  ) {}

  toggleActions(): void {
    this.actionsOpen = !this.actionsOpen;
  }

  editForm(): void {
    if (this.form) {
      this.router.navigateByUrl('forms/edit/' + this.form.id);
    }
  }

  archive(archived: boolean = true): void {
    if (this.form) {
      this.store.dispatch(ArchiveForm({formId: this.form.id, archived: archived}));
    }
  }
}
