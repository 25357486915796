export enum FormFieldPrefillOption {
  CLIENT_PMS_ID = 'CLIENT_PMS_ID',
  CLIENT_NAME = 'CLIENT_NAME',
  CLIENT_POSTCODE = 'CLIENT_POSTCODE',
  CLIENT_PHONE = 'CLIENT_PHONE',
  CLIENT_EMAIL = 'CLIENT_EMAIL',
  PATIENT_NAME = 'PATIENT_NAME',
  PRACTICE_NAME = 'PRACTICE_NAME',
  PATIENT_PMS_ID = 'PATIENT_PMS_ID',
}
