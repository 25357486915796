import { createAction, props } from '@ngrx/store';
import {Client} from '../../models/Client';
import {Contact} from '../../models/Contact';
import {Channel} from '../../enums/channel';
import {Practice} from "../../models/Practice";
import {Form} from "../interfaces/form";
import {FormCreateDto} from "../interfaces/dto/form-create.dto";
import {FormUpdateDto} from "../interfaces/dto/form-update.dto";
import {Patient} from "../../models/Patient";
import {FormSubmissionCreateDto} from "../interfaces/dto/form-submission-create.dto";
import {FormSubmission} from "../interfaces/form-submission";

export const OpenFormRequest = createAction(
  '[Forms] Open New Form Request',
  props<{client?: Client, contact?: Contact, channel?: Channel}>()
);

export const CloseFormRequest = createAction(
  '[Forms] Close New Form Request'
);

export const GetSendFormPatientsSuccess = createAction(
  '[Form] Form send get Patients Success',
  props<{patients: Patient[], replace: boolean}>()
);

export const GetMoreSendFormPatients = createAction(
  '[Form] Get More Form Send Patients',
  props<{page: number}>()
);

export const SetFormRequestClient = createAction(
  '[Forms] Set Form Request Client',
  props<{client?: Client}>()
);

export const SetFormRequestContact = createAction(
  '[Forms] Set Form Request Contact',
  props<{contact?: Contact}>()
);

export const SetFormRequestChannel = createAction(
  '[Forms] Set Form Request Channel',
  props<{channel?: Channel}>()
);

export const SearchForms = createAction(
  '[Forms] Search Form',
  props<{search?: string}>()
);

export const SearchFormsSuccess = createAction(
  '[Forms] Search Form Success',
  props<{forms: Form[]}>()
);

export const SearchFormsFailed = createAction(
  '[Forms] Search Form Failed'
);

export const GetForm = createAction(
  '[Forms] Get Form',
  props<{formId: number}>()
);

export const GetFormSuccess = createAction(
  '[Forms] Get Form Success',
  props<{form: Form}>()
);

export const GetFormFailed = createAction(
  '[Forms] Get Form Failed'
);

export const GetFormToSend = createAction(
  '[Forms] Get Form To Send',
  props<{formId: number}>()
);

export const GetFormToSendSuccess = createAction(
  '[Forms] Get Form To Send Success',
  props<{form: Form}>()
);

export const ClearCurrentForm = createAction(
  '[Forms] Clear Current Form'
);

export const CreateForm = createAction(
  '[Forms] Create Form',
  props<{dto: FormCreateDto}>()
);

export const CreateFormSuccess = createAction(
  '[Forms] Create Form Success',
  props<{form: Form}>()
);

export const CreateFormFailed = createAction(
  '[Forms] Create Form Failed'
);

export const UpdateForm = createAction(
  '[Forms] Update Form',
  props<{dto: FormUpdateDto}>()
);

export const UpdateFormSuccess = createAction(
  '[Forms] Update Form Success',
  props<{form: Form}>()
);

export const UpdateFormFailed = createAction(
  '[Forms] Update Form Failed'
);

export const ArchiveForm = createAction(
  '[Forms] Archive Form',
  props<{formId: number, archived: boolean}>()
);

export const ArchiveFormSuccess = createAction(
  '[Forms] Archive Form Success',
  props<{form: Form}>()
);

export const ArchiveFormFailed = createAction(
  '[Forms] Archive Form Failed'
);

export const CreateFormSubmission = createAction(
  '[Forms] Create Form Submission',
  props<{dto: FormSubmissionCreateDto, client: Client | null, contact: Contact | null, channel: Channel}>()
);

export const CreateFormSubmissionSuccess = createAction(
  '[Forms] Create Form Submission Success',
  props<{formSubmission: FormSubmission, client: Client | null, contact: Contact | null, channel: Channel}>()
);

export const CreateFormSubmissionFailed = createAction(
  '[Forms] Create Form Submission Failed'
);

export const SendFormSubmission = createAction(
  '[Forms] Send Form Submission',
  props<{formSubmission: FormSubmission, client?: Client | null, contact?: Contact | null, channel: Channel}>()
);

export const SendFormSubmissionSuccess = createAction(
  '[Forms] Send Form Submission Success'
);

export const SendFormSubmissionFailed = createAction(
  '[Forms] Send Form Submission Failed'
);

export const GetFormSubmissions = createAction(
  '[Forms] Get Form Submissions',
  props<{page: number, perPage: number, searchString: string | null, status?: string, saveToPms?: string, date?: string | object , clientPmsId?: string | undefined}>()
);

export const GetFormSubmissionsSuccess = createAction(
  '[Forms] Get Form Submissions Success',
  props<{formSubmissions: FormSubmission[], totalCount: number}>()
);

export const GetFormSubmissionsFailed = createAction(
  '[Forms] Get Form Submissions Failed'
);

export const GetFormSubmission = createAction(
  '[Forms] Get Form Submission',
  props<{uuid: string}>()
);

export const GetFormSubmissionSuccess = createAction(
  '[Forms] Get Form Submission Success',
  props<{formSubmission: FormSubmission}>()
);

export const GetFormSubmissionFailed = createAction(
  '[Forms] Get Form Submission Failed'
);
