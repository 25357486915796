import {DateFilterOption} from '../interfaces/date-filter-option.interface';
import {DateFilterShorthand} from '../enums/date-filter-shorthand';
import { FilterOptionType } from '../enums/filter-option-type';

export const dateFilterOptions: DateFilterOption[] = [
  {
    id: DateFilterShorthand.ANYTIME,
    label: 'Anytime',
    type: FilterOptionType.STANDARD
  },
  {
    id: DateFilterShorthand.ONE_DAY,
    label: 'Last 24 hours',
    type: FilterOptionType.STANDARD
  },
  {
    id: DateFilterShorthand.THREE_DAYS,
    label: 'Last 3 days',
    type: FilterOptionType.STANDARD
  },
  {
    id: DateFilterShorthand.SEVEN_DAYS,
    label: 'Last week',
    type: FilterOptionType.STANDARD
  },
  {
    id: DateFilterShorthand.FOURTEEN_DAYS,
    label: 'Last 2 weeks',
    type: FilterOptionType.STANDARD
  },
  {
    id: DateFilterShorthand.ONE_MONTH,
    label: 'Last month',
    type: FilterOptionType.STANDARD
  },
  {
    id: DateFilterShorthand.TWO_MONTHS,
    label: 'Last 2 months',
    type: FilterOptionType.STANDARD
  },
  {
    id: DateFilterShorthand.SIX_MONTHS,
    label: 'Last 6 months',
    type: FilterOptionType.STANDARD
  },
  {
    id: DateFilterShorthand.ONE_YEAR,
    label: 'Last year',
    type: FilterOptionType.STANDARD
  },
  {
    id: DateFilterShorthand.CUSTOM,
    label: 'Within range',
    type: FilterOptionType.DATE_RANGE,
  }
];
