import { TemplateType } from "../enums/template-type";

export function prettifyTemplateType(type: TemplateType): string {
  switch (type) {
    case TemplateType.Custom:
      return "helpers.prettify_template_type.custom";
    case TemplateType.Campaign:
      return "helpers.prettify_template_type.campaign";
    case TemplateType.CampaignWithPayment:
      return "helpers.prettify_template_type.campaign_with_payment";
    case TemplateType.NewTicket:
      return "helpers.prettify_template_type.new_ticket";
    case TemplateType.Reinitialise:
      return "helpers.prettify_template_type.reinitialise";
    case TemplateType.FormRequest:
      return "helpers.prettify_template_type.form_request";
    case TemplateType.PaymentRequest:
      return "helpers.prettify_template_type.payment_request";
    case TemplateType.PaymentPaid:
      return "helpers.prettify_template_type.payment_paid";
    case TemplateType.PaymentRefunded:
      return "helpers.prettify_template_type.payment_refunded";
    case TemplateType.PreAuthorisationSuccess:
      return "helpers.prettify_template_type.pre_authorisation_success";
    case TemplateType.Notification:
      return "helpers.prettify_template_type.notification";
    case TemplateType.ProductRequestUpdate:
      return "helpers.prettify_template_type.product_request_update";
    case TemplateType.Welcome:
      return "helpers.prettify_template_type.welcome";
    case TemplateType.Standard:
      return "helpers.prettify_template_type.standard";
    case TemplateType.ReminderSnoozeResponse:
      return "helpers.prettify_template_type.reminder_snooze_response";
    case TemplateType.ReminderAppointment:
      return "helpers.prettify_template_type.appointment_reminder";
    case TemplateType.ReminderVaccination:
      return "helpers.prettify_template_type.vaccination_reminder";
    case TemplateType.ReminderParasiticide:
      return "helpers.prettify_template_type.parasiticide_reminder";
    case TemplateType.ReminderPayment:
      return "helpers.prettify_template_type.payment_reminder";
    case TemplateType.ReminderPreOperation:
      return "helpers.prettify_template_type.pre_op_reminder";
    case TemplateType.ReminderPostOperation:
      return "helpers.prettify_template_type.post_op_reminder";
    case TemplateType.ReminderHealthCarePlan:
      return "helpers.prettify_template_type.healthcare_plan_reminder";
    case TemplateType.ReminderHealthCarePlanFollowUp:
      return "helpers.prettify_template_type.healthcare_plan_reminder_follow_up";
    case TemplateType.ManagePreferencesResponse:
      return "helpers.prettify_template_type.manage_preferences";
    case TemplateType.ExternalReminder:
      return "helpers.prettify_template_type.external_reminder";
    default:
      return "helpers.prettify_template_type.n_a";
  }
}
