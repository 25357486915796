import {AfterContentInit, AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Store} from '@ngrx/store';
import {AppState} from '../../../state/reducers';
import {RenameMediaFolder} from '../../state/actions';
import { MediaFolder } from '../../../models/MediaFolder';

@Component({
  selector: 'rename-folder',
  templateUrl: './rename-folder.component.html',
  styleUrls: ['./rename-folder.component.scss']
})
export class RenameMediaFolderComponent implements OnInit, AfterContentInit {
  @ViewChild('nameInput') nameInput?: ElementRef;
  form = new FormGroup({
    name: new FormControl('', Validators.required),
  });

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private store: Store<AppState>,
    ) { }

  ngOnInit(): void {
    this.form.controls.name.setValue(this.config.data.folder.name);    
  }

  ngAfterContentInit(): void {
    setTimeout(() => {
      if (this.nameInput) {
        this.nameInput.nativeElement.focus();
      }
    }, 50);
  }

  submit(): void {
    Object.keys(this.form.controls).forEach(field => {
      const control = this.form.get(field);
      control?.markAsTouched({ onlySelf: true });
    });

    if (this.config.data.folder.id && this.form.valid && this.form.controls.name.value) {
      this.store.dispatch(RenameMediaFolder({
        folder: {
          id: this.config.data.folder.id,
          name: this.form.controls.name.value,
        }
      }));
      
      this.ref.close();
    }
  }
}
