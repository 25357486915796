<ng-container *ngIf="productRequest">
  <div class="product-request-detail" [ngClass]="{'add-padding': viewers.length > 0 &&  showUsers}">
    <ng-container *ngIf="viewers.length > 0 && showUsers">
      <top-bar-message [viewers]="viewers" [type]="'product request'"></top-bar-message>
    </ng-container>
    <div class="tabs">
      <div class="tab" [class.active]="activeTab === 1" (click)="gotToTab(1)">{{'product_requests.detail.request_information' | translate}}</div>
      <div class="tab" [class.active]="activeTab === 2" (click)="gotToTab(2)">{{'product_requests.detail.team_chat_length' | translate: {length: comments.length} }}</div>
      <div class="tab" [class.active]="activeTab === 3" (click)="gotToTab(3)">{{'product_requests.detail.history' | translate}}</div>
      <div class="tab" [class.active]="activeTab === 4" (click)="gotToTab(4)">{{'product_requests.detail.client_patient' | translate}}</div>
    </div>
    <ng-container *ngIf="activeTab === 1">
      <div class="tab-contents">
        <div class="client-section">
          <div class="client-patient">
            <div class="client">
              <ng-container *ngIf="productRequest.client">
                <p (click)="redirectToClient(productRequest.client.id)">{{ productRequest.client.fullName }} (ID: {{
                  productRequest.client.pmsDisplayId ?? productRequest.client.pmsId  }})</p>
              </ng-container>
              <ng-container *ngIf="!productRequest.client">
                {{'product_requests.detail.not_set' | translate}}
              </ng-container>
            </div>
            <div class="patient">
              <ng-container *ngIf="productRequest.patient">
                {{ productRequest.patient.name }}
                <ng-container *ngIf="productRequest.patient.breed">
                  - {{ productRequest.patient.breed }}
                </ng-container>
                <ng-container *ngIf="productRequest.patient.species">
                  ({{ productRequest.patient.species }})
                </ng-container>
              </ng-container>
              <ng-container *ngIf="!productRequest.patient">
                {{'product_requests.detail.not_set' | translate}}
              </ng-container>
            </div>
          </div>

          <div class="owner-assignee">
            <div class="owner">
              <user-changer size="small" [includeUnassigned]="false" [disabled]="false"
                [selected]="productRequest.owner" [withGroups]="false" (userChange)="handleOwnerChange($event)">
              </user-changer>
              <div class="label">{{'product_requests.detail.owner' | translate}}</div>
            </div>
            <div class="assignee">
              <user-changer size="small" [disabled]="false" [selected]="productRequest.assignee"
                (userChange)="handleAssigneeChange($event)"></user-changer>
              <div class="label">{{'product_requests.detail.assignee' | translate}}</div>
            </div>
          </div>
        </div>

        <div class="product-items-block">
          <div class="product-items">
            <ng-container *ngFor="let item of productRequest.items; let i = index; trackBy: trackItem">
              <div class="item-wrap">
                <product-request-item-row [(fulfilledCount)]="fulfilledCount[i]" [(approvedCount)]="approvedCount[i]"
                  [(rejectionReason)]="rejectionReasons[i]" [(approvedItem)]="approvedItem[i]"
                  [isRejected]="rejectedItemIds.includes(item.id)" [isApproved]="approvedItemIds.includes(item.id)"
                  [userCanApprove]="userCanApprove" [productRequest]="productRequest" [currentContext]="currentContext"
                  [item]="item" [errors]="errors[i]" (approveClicked)="handleApproveClicked($event, i)"
                  (rejectClicked)="handleRejectClicked($event, i)"
                  (revertItemChanges)="handleRevertItemChanges($event, i)"
                  (updateItem)="handleUpdateItem($event, i)"
                  (saveOrCancelItem)="handleSaveOrCancelItem($event, i)"
                ></product-request-item-row>
              </div>
            </ng-container>
          </div>
        </div>

        <div class="bottom-row">
          <div class="dates">
            <div class="date">
              <span class="label">{{'product_requests.detail.last_updated' | translate}}</span>
              <span>{{ productRequest.updatedAt | dfnsFormat:'dd/MM/yyyy HH:mm' }}</span>
            </div>
            <div class="date">
              <span class="label">{{'product_requests.detail.created' | translate}}</span>
              <span>{{ productRequest.createdAt | dfnsFormat:'dd/MM/yyyy HH:mm' }}</span>
            </div>
          </div>

          <ng-container *ngIf="currentContext === null">
            <div class="status-updater" (clickOutside)="this.showingStatusActions = false">
              <ng-container *ngIf="statusOptions.length > 0">
                <p-button [label]="('product_requests.detail.status' | translate) + ' ' + (productRequest.status | prettifyProductRequestStatus | translate)"
                  (onClick)="toggleStatusActions()" iconPos="right"
                  [icon]="showingStatusActions ? 'pi pi-chevron-down' : 'pi pi-chevron-up'"
                  styleClass="p-button-success"></p-button>
                <ng-container *ngIf="showingStatusActions">
                  <div class="status-actions">
                    <ng-container *ngFor="let status of statusOptions">
                      <div class="status-action" (click)="moveToStatus(status)">{{ status | prettifyProductRequestStatus | translate
                        }}</div>
                    </ng-container>
                  </div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="statusOptions.length === 0">
                <p-button [label]="('product_requests.detail.status' | translate) + ' ' + (productRequest.status | prettifyProductRequestStatus | translate)"
                  styleClass="p-button-success" [disabled]="true"></p-button>
              </ng-container>
            </div>
          </ng-container>
          <ng-container *ngIf="currentContext !== null">
            <div class="submitter">
              <p-button (click)="submit()" [label]="'product_requests.detail.submit' | translate" styleClass="p-button-primary"></p-button>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="activeTab === 2">
      <div class="tab-contents">
        <div class="comments-panel-wrap">
          <ng-container *ngIf="commentsLoading">
            <div class="loading">
              <p-progressSpinner [style]="{width: '32px', height: '32px'}" styleClass="custom-spinner" strokeWidth="4">
              </p-progressSpinner>
            </div>
          </ng-container>
          <ng-container *ngIf="!commentsLoading">
            <comments-panel [title]="null" [comments]="comments" [conversation]="null"
              (commentSent)="handleCommentSent($event)" [disabled]="false"></comments-panel>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="activeTab === 3">
      <div class="tab-contents">
        <ng-container *ngIf="historyLoading">
          <div class="loading-client">
            <p-progressSpinner [style]="{width: '32px', height: '32px'}" styleClass="custom-spinner" strokeWidth="4">
            </p-progressSpinner>
          </div>
        </ng-container>
        <ng-container *ngIf="!historyLoading">
          <ng-container *ngFor="let historyItem of history">
            <div class="history-item">
              <div class="history-item-left">
                <div class="content">{{ historyItem.content }}</div>
              </div>
              <div class="history-item-right">
                {{'product_requests.detail.by_who' | translate: {
                  who: historyItem.actioner ? historyItem.actioner.fullName : ('product_requests.detail.system' | translate)
                } }}, {{ historyItem.createdAt |
                dfnsFormat:'dd/MM/yy HH:mm' }}
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="activeTab === 4">
      <div class="tab-contents">
        <ng-container *ngIf="clientLoading || patientLoading">
          <div class="loading-client">
            <p-progressSpinner [style]="{width: '32px', height: '32px'}" styleClass="custom-spinner" strokeWidth="4">
            </p-progressSpinner>
          </div>
        </ng-container>
        <ng-container *ngIf="client && !clientLoading && !patientLoading">
          <div class="client-block">
            <client-panel
            [changeDisabled]="true"
            [client]="client"
            [shared]="(clientShared$ | async) || false"
            [shareLoading]="shareClientLoading"
            (shareToPms)="handleShareClientToPms($event)"
          ></client-panel>
          </div>
        </ng-container>
        <ng-container *ngIf="patient && !clientLoading && !patientLoading">
          <div class="patient-block">
            <patient-panel [changeDisabled]="true" [patient]="patient" [shared]="(patientShared$ | async) || false"
              [patientHistory]="patientHistory" [patientHistoryLoading]="patientHistoryLoading"
              [shareLoading]="sharePatientLoading" (shareToPms)="handleSharePatientToPms($event)"
              (patientHistoryOpened)="handlePatientHistoryOpened($event)"></patient-panel>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-container>
