import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ConversationUser } from 'src/app/models/ConversationUser';
import { Client } from '../../../models/Client';
import { phone } from 'phone';
import { ClientSelectorComponent } from 'src/app/ui/components/client-selector/client-selector.component';

@Component({
  selector: 'no-client-panel',
  templateUrl: './no-client-panel.component.html',
  styleUrls: ['./no-client-panel.component.scss']
})
export class NoClientPanelComponent implements OnInit, OnDestroy {
  @Input() disabled?: boolean;
  @Input() multiplePotentialClients = 0;
  @Input() couldntAttemptMatch = false;
  @Input() user$?: Observable<ConversationUser | null>;
  @Input() reloadingClient = false;
  @Input() changingClient = false;
  @Output() clientUpdated = new EventEmitter<Client>();
  @Output() clientRefresh = new EventEmitter();
  @ViewChild('clientSelector') clientSelector?: any;
  userSub$?: Subscription;
  phoneStr = '';

  constructor() { }

  ngOnInit(): void {
    this.userSub$ = this.user$?.subscribe((user) => {
      if (user && user.phone) {
        const phoneResult = phone(user.phone);
        if (phoneResult.isValid) {
          this.phoneStr = phoneResult.phoneNumber.replace(phoneResult.countryCode, '0');
        } else {
          this.phoneStr = user.phone;
        }
      }
    });

    if (this.couldntAttemptMatch) {
      this.reloadClient();
    }

    if (this.changingClient) {
      this.focus();
    }
  }

  ngOnDestroy(): void {
    this.userSub$?.unsubscribe();
  }

  focus(): void {
    setTimeout(() => {
      this.clientSelector?.focus();
    }, 1);
  }

  updateClient(client: Client): void {
    this.clientUpdated.emit(client);
  }

  reloadClient(): void {
    this.clientRefresh.emit();
  }
}
