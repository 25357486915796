import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import { BehaviorSubject, Observable, takeWhile } from 'rxjs';
import { Conversation } from '../../../models/Conversation';
import {Media} from "../../../models/Media";
import { AppState } from '../../../state/reducers';
import { getConversation } from '../../state/selectors';

@Component({
  selector: 'app-media-send',
  templateUrl: './media-send.component.html',
  styleUrls: ['./media-send.component.scss']
})
export class MediaSendComponent implements OnInit, OnDestroy {
  @ViewChild('textAreaElement') textAreaElement: ElementRef | undefined;
  file: File | null = null;
  media: Media | null = null;
  caption = '';
  filename: string | null = null;
  previewSrc: string | null = null;
  maxMessageLength = 1450;
  remainingMessageLength = this.maxMessageLength;
  messageTooLong = false;
  messageInvalid = false;
  invalidReason = 'Over send limit';
  conversationId?: string | null;
  conversation$: Observable<Conversation | null> = new BehaviorSubject(null);
  conversation?: Conversation;
  alive = true;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig, private store: Store<AppState>) {
    this.file = this.config.data.file;
    this.media = this.config.data.media;

    if (this.media) {
      this.filename = this.media.name;
      this.previewSrc = this.media.mime.includes('image/') ? this.media.signedUrl : null;
    } else if (this.file) {
      this.filename = this.file.name;

      if (this.file.type.includes('image/')) {
        const reader = new FileReader();
        reader.onload = (e) => {
          if (typeof reader.result === 'string') {
            this.previewSrc = reader.result;
          }
        };
        reader.readAsDataURL(this.file);
      }
    }
  }

  ngOnInit(): void {
    this.subscribeToCurrentConversation();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  subscribeToCurrentConversation(): void {
    this.conversation$ = this.store.pipe(select(getConversation)).pipe(
      takeWhile(() => this.alive)
    );

    this.conversation$.subscribe(conversation => {
      if (conversation) {
        this.conversation = conversation;

        if (this.conversation.channel === 'SMS') {
          this.maxMessageLength = 400;
          this.remainingMessageLength = 400;
        }
      }
    });
  }


  onMessageInputChange(value: string): void {
    this.remainingMessageLength = this.maxMessageLength - value.length;

    if (this.remainingMessageLength < 0) {
      this.messageTooLong = true;
      this.messageInvalid = true;
    } else {
      this.messageTooLong = false;
      this.messageInvalid = false;
    }
  }
  

  textareaResize($event: Event): void {
    if (this.textAreaElement && this.textAreaElement.nativeElement.offsetHeight > 40) {
      // Grim hack to stop primeng input from showing scrollbar when auto resizing
      this.textAreaElement.nativeElement.style.height = (this.textAreaElement.nativeElement.offsetHeight + 6).toString() + 'px';
    }
  }

  submit(): void {
    if (!this.messageTooLong || !this.messageInvalid) {
      this.ref.close(this.caption);
    }
  }
}
