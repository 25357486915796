import {MessageType} from '../enums/message-type';

export function prettifyMessageType(type: MessageType): string {
  switch (type) {
    case MessageType.STANDARD:
      return 'helpers.prettify_message_type.standard';
    case MessageType.AUTORESPONSE:
      return 'helpers.prettify_message_type.automatic_reply';
    case MessageType.PAYMENT_LINK:
      return 'helpers.prettify_message_type.payment_link';
    case MessageType.PAYMENT_COMPLETE:
      return 'helpers.prettify_message_type.payment_complete';
    case MessageType.INITIAL_CONSENT_REQUEST:
      return 'helpers.prettify_message_type.initial_consent_request';
    case MessageType.REOPEN_CONSENT_REQUEST:
      return 'helpers.prettify_message_type.reinitialise_conversation';
    case MessageType.VERIFICATION_REQUEST:
      return 'helpers.prettify_message_type.verification_request';
    case MessageType.VERIFICATION_COMPLETE:
      return 'helpers.prettify_message_type.verification_complete';
    case MessageType.ATTACHMENT_IMAGE:
      return 'helpers.prettify_message_type.attachment_image';
    case MessageType.ATTACHMENT_AUDIO:
      return 'helpers.prettify_message_type.attachment_audio';
    case MessageType.ATTACHMENT_FILE:
      return 'helpers.prettify_message_type.attachment_file';
    case MessageType.ATTACHMENT_VIDEO:
      return 'helpers.prettify_message_type.attachment_video';
    case MessageType.WELCOME:
      return 'helpers.prettify_message_type.welcome';
    case MessageType.PAYMENT_REFUNDED:
      return 'helpers.prettify_message_type.refund_notification';
    case MessageType.PAYMENT_AUTHORISED:
      return 'helpers.prettify_message_type.payment_authorised';
    case MessageType.PRODUCT_REQUEST_REJECTED:
      return 'helpers.prettify_message_type.product_request_rejected';
    case MessageType.PRODUCT_REQUEST_APPROVED:
      return 'helpers.prettify_message_type.product_request_approved';
    case MessageType.PRODUCT_REQUEST_PARTIALLY_APPROVED:
      return 'helpers.prettify_message_type.product_request_partially_approved';
    case MessageType.PRODUCT_REQUEST_PAID:
      return 'helpers.prettify_message_type.product_request_paid';
    case MessageType.PRODUCT_REQUEST_DISPENSED:
      return 'helpers.prettify_message_type.product_request_dispensed';
    default:
      return 'helpers.prettify_message_type.n_a';
  }
}
