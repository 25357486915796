import { Pipe, PipeTransform } from '@angular/core';
import {Template} from '../models/Template';
import {canTemplateBeArchived} from '../helpers/can-template-be-archived';

@Pipe({name: 'canTemplateBeArchived'})
export class CanTemplateBeArchivedPipe implements PipeTransform {
  transform(template: Template): boolean {
    return canTemplateBeArchived(template);
  }
}
