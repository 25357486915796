import { Action, createReducer, on } from '@ngrx/store';
import * as actions from './actions';
import { MediaFolder } from '../../models/MediaFolder';
import { MediaBrowser } from '../../interfaces/media-browser.interface';
import { defaultMediaBrowser } from '../../constants/default-media-browser.constants';
import { MediaBrowser as MediaBrowserName } from '../../enums/media-browsers';

export interface MediaState {
  [MediaBrowserName.PAGE]: MediaBrowser;
  [MediaBrowserName.SELECTOR]: MediaBrowser;
  currentBrowser: MediaBrowserName;
  videoUploadProgress: number;
  videoUploading: boolean;
  videoProcessing: boolean;
  videoCompressionJobId: string | null;
  folderTree: MediaFolder[];
}

export const initialState: MediaState = {
  [MediaBrowserName.PAGE]: defaultMediaBrowser,
  [MediaBrowserName.SELECTOR]: defaultMediaBrowser,
  currentBrowser: MediaBrowserName.PAGE,
  videoUploadProgress: 0,
  videoUploading: false,
  videoProcessing: false,
  videoCompressionJobId: null,
  folderTree: []
};

const templateReducer = createReducer(
  initialState,
  on(actions.SetCurrentMediaBrowser, (state, payload) => ({
    ...state,
    currentBrowser: payload.browser
  })),
  on(actions.GetMedia, (state, payload) => ({
    ...state,
    [state.currentBrowser]: {
      ...state[state.currentBrowser],
      mediaLoading: true
    }
  })),
  on(actions.GetMediaSuccess, (state, payload) => ({
    ...state,
    [state.currentBrowser]: {
      ...state[state.currentBrowser],
      media: [
        ...payload.media
      ],
      totalMediaCount: payload.total,
      mediaLoading: false,
    }
  })),
  on(actions.AddMedia, (state, payload) => ({
    ...state,
    [state.currentBrowser]: {
      ...state[state.currentBrowser],
      mediaLoading: true,
    }
  })),
  on(actions.AddMediaSuccess, (state, payload) => ({
    ...state,
    [state.currentBrowser]: {
      ...state[state.currentBrowser],
      media: [
        ...state[state.currentBrowser].media,
        payload.media
      ].sort((a, b) => a.name.localeCompare(b.name)),
      mediaLoading: false,
    }
  })),
  on(actions.AddVideo, (state, payload) => ({
    ...state,
    [state.currentBrowser]: {
      ...state[state.currentBrowser],
      mediaLoading: true,
    }
  })),
  on(actions.UploadVideo, (state, payload) => ({
    ...state,
    videoCompressionJobId: payload.id,
    videoUploading: true
  })),
  on(actions.VideoUploaded, (state) => ({
    ...state,
    videoUploading: false,
    videoProcessing: true
  })),
  on(actions.UpdateVideoUploadProgress, (state, payload) => ({
    ...state,
    videoUploadProgress: payload.percentage
  })),
  on(actions.VideoComplete, (state, payload) => ({
    ...state,
    [state.currentBrowser]: {
      ...state[state.currentBrowser],
      media: [
        ...state[state.currentBrowser].media,
        payload.media
      ].sort((a, b) => a.name.localeCompare(b.name)),
      mediaLoading: false
    },
    videoUploadProgress: 0,
    videoUploading: false,
    videoProcessing: false,
    videoCompressionJobId: null
  })),
  on(actions.DeleteMedia, (state, payload) => ({
    ...state,
    [state.currentBrowser]: {
      ...state[state.currentBrowser],
      mediaLoading: true,
    }
  })),
  on(actions.DeleteMediaSuccess, (state, payload) => ({
    ...state,
    [state.currentBrowser]: {
      ...state[state.currentBrowser],
      media: state[state.currentBrowser].media.filter((m) => m.id !== payload.media.id),
      mediaLoading: false,
    }
  })),
  on(actions.GetMediaFolders, (state, payload) => ({
    ...state,
    [state.currentBrowser]: {
      ...state[state.currentBrowser],
      foldersLoading: true,
    }
  })),
  on(actions.GetMediaFoldersSuccess, (state, payload) => ({
    ...state,
    [state.currentBrowser]: {
      ...state[state.currentBrowser],
      folders: [
        ...payload.folders
      ],
      foldersLoading: false,
    }
  })),
  on(actions.AddMediaFolder, (state, payload) => ({
    ...state,
    [state.currentBrowser]: {
      ...state[state.currentBrowser],
      foldersLoading: true,
    }
  })),
  on(actions.AddMediaFolderSuccess, (state, payload) => ({
    ...state,
    [state.currentBrowser]: {
      ...state[state.currentBrowser],
      folders: [
        ...state[state.currentBrowser].folders,
        payload.folder
      ],
      foldersLoading: false,
    }
  })),
  on(actions.ChangeMediaFolder, (state, payload) => {
    let breadcrumbs: MediaFolder[] = [];

    if (payload.folder !== null) {
      for (const x in state[state.currentBrowser].folderBreadcrumbs) {
        if (payload.folder && state[state.currentBrowser].folderBreadcrumbs[x].id === payload.folder.id) {
          break;
        }

        breadcrumbs = [
          ...breadcrumbs,
          state[state.currentBrowser].folderBreadcrumbs[x]
        ];
      }

      // @ts-ignore has to be used here because Typescript is thinks state[state.currentBrowser].currentFolder may be null even though we specifically check it isn't
      if (payload.direction === 'down' && state[state.currentBrowser].currentFolder !== null) {
        breadcrumbs = [
          // @ts-ignore
          ...breadcrumbs,
          // @ts-ignore
          state[state.currentBrowser].currentFolder
        ];
      }
    }

    return {
      ...state,
      [state.currentBrowser]: {
        ...state[state.currentBrowser],
        folders: [],
        folderBreadcrumbs: [
          ...breadcrumbs
        ],
        currentFolder: payload.folder,
        foldersLoading: true,
        mediaLoading: true
      }
    };
  }),
  on(actions.RenameMediaFolderSuccess, (state, payload) => ({
    ...state,
    [state.currentBrowser]: {
      ...state[state.currentBrowser],
      folders: [
        ...state[state.currentBrowser].folders.map(folder => {
          if (folder.id === payload.folder.id) {
            return {
              ...folder,
              name: payload.folder.name
            };
          }
          return folder;
        })
      ],
    }
  })),
  on(actions.DeleteMediaFolderSuccess, (state, payload) => ({
    ...state,
    [state.currentBrowser]: {
      ...state[state.currentBrowser],
      folders: [
        ...state[state.currentBrowser].folders.filter(folder => folder.id !== payload.id)
      ],
    }
  })),
  on(actions.GetMediaFolderTreeSuccess, (state, payload) => ({
    ...state,
    folderTree: [
      ...payload.folders
    ]
  })),
  on(actions.SetFolderToMove, (state, payload) => ({
    ...state,
    [state.currentBrowser]: {
      ...state[state.currentBrowser],
      folderToMove: payload.folder
    }
  })),
  on(actions.MoveFolderSuccess, (state, payload) => {
    if (state[state.currentBrowser].currentFolder?.id === payload.folder.parentId) {
      return {
        ...state,
        [state.currentBrowser]: {
          ...state[state.currentBrowser],
          folders: [
            ...state[state.currentBrowser].folders,
            payload.folder
          ],
          folderToMove: null
        }
      };
    }

    return {
      ...state,
      [state.currentBrowser]: {
        ...state[state.currentBrowser],
        folders: state[state.currentBrowser].folders.filter(folder => folder.id !== payload.folder.id),
        folderToMove: null
      }
    };
  }),
  on(actions.MoveMediaSuccess, (state, payload) => {
    if (payload.media.folderId !== state[state.currentBrowser].currentFolder?.id) {
      return {
        ...state,
        [state.currentBrowser]: {
          ...state[state.currentBrowser],
          media: state[state.currentBrowser].media.filter(media => media.id !== payload.media.id)
        }
      };
    }

    return {
      ...state
    };
  }),
  on(actions.RenameMediaSuccess, (state, payload) => {
    return {
      ...state,
      [state.currentBrowser]: {
        ...state[state.currentBrowser],
        media: [
          ...state[state.currentBrowser].media.map(media => {
            if (media.id === payload.media.id) {
              return {
                ...media,
                name: payload.media.name
              };
            }

            return media;
          })
        ]
      }
    };
  }),
  on(actions.SetMediaFilters, (state, payload) => ({
    ...state,
    [state.currentBrowser]: {
      ...state[state.currentBrowser],
      filters: {
        ...payload.filters,
      }
    }
  })),
  on(actions.SetMediaPage, (state, payload) => ({
    ...state,
    [state.currentBrowser]: {
      ...state[state.currentBrowser],
      currentPage: payload.page
    }
  })),
);

export function reducer(state: MediaState | undefined, action: Action): MediaState {
  return templateReducer(state, action);
}
