import { PracticeFeature } from "../enums/practice-feature";
import { Conversation } from "../models/Conversation";
import { Practice } from "../models/Practice";
import { practiceHasFeature } from "./practice-has-feature";

export function filterConversations(conversations: Conversation[], practice: Practice | null): Conversation[] {
    // If the practice is set up to hide unverified conversations, remove them from results
    if (practice && !practiceHasFeature(practice, PracticeFeature.SHOW_UNVERIFIED_CONVERSATIONS)) {
        conversations = conversations.filter(conversation => conversation.users ? conversation.users.find(user => user.phone) : null);
    }
    return conversations;
}
