import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { generateColour } from 'src/app/helpers/user-color-generator';
import { User } from 'src/app/models/User';
import { Group } from '../../../models/Group';
import { isUser } from '../../../helpers/is-user';
import { Client } from 'src/app/models/Client';
import { isClient } from 'src/app/helpers/is-client';

@Component({
  selector: 'user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss']
})
export class UserAvatarComponent implements OnInit, OnChanges {
  @Input() user?: User | Group | null;
  @Input() size = 'medium';
  @Input() responsive = false;
  @Input() unsetInitials = '+';
  isUser = isUser;
  backgroundColor?: string;

  constructor() { }

  ngOnInit(): void {
    this.generateColor();
  }

  ngOnChanges(): void {
    this.generateColor();
  }

  generateColor(): void {
    if (this.user && this.user.id === '0') {
      this.backgroundColor = 'var(--fourth-color)';
      return;
    }

    let name = '';
    
    if (this.user && isUser(this.user)) {
      // Is User
      name = this.user.lastName;
    } else if (this.user) {
      // Is Group
      name = this.user.name;
    }

    this.backgroundColor = generateColour(name);
  }
}
