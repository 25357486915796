import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {EnvironmentService} from '../services/environment.service';
import {HttpClient} from '@angular/common/http';
import {Conversation} from '../models/Conversation';
import {Payment} from '../models/Payment';
import {ConversationDto} from '../interfaces/dto/conversation.dto';
import {map} from 'rxjs/operators';
import {PaymentDto} from '../interfaces/dto/payment.dto';
import {ConversationAdapter} from '../adapters/conversation.adapter';
import {PaymentAdapter} from '../adapters/payment.adapter';
import {ConversationsFilterDto} from '../interfaces/dto/conversations-filter.dto';
import {PaymentFilterDto} from '../interfaces/dto/payment-filter.dto';
import {ClientAdapter} from '../adapters/client.adapter';

@Injectable({
  providedIn: 'root'
})

export class SearchService {
  constructor(
    private environmentService: EnvironmentService,
    private http: HttpClient,
    private conversationAdapter: ConversationAdapter,
    private paymentAdapter: PaymentAdapter,
    private clientAdapter: ClientAdapter,
  ) { }

  doConversationSearch(
    searchString: string,
    filters: ConversationsFilterDto,
    practiceId?: string
  ): Observable<{ conversations: Conversation[], total: number }> {
    if (!practiceId) {
      return of({conversations: [], total: 0});
    }

    let url = this.environmentService.get('backendUrl') + '/search/conversations/';
    url += encodeURIComponent(searchString);

    return this.http.post<{ conversations: ConversationDto[], total: number }>(url, {practiceId, filters}, {withCredentials: true}).pipe(
      map((response: { conversations: ConversationDto[], total: number }) => {
        return {
          conversations: response.conversations.map((item) => this.conversationAdapter.run(item)),
          total: response.total
        };
      })
    );
  }

  doPaymentSearch(
    searchString: string,
    filters: PaymentFilterDto,
    practiceId?: string
  ): Observable<{ payments: Payment[], total: number }> {
    if (!practiceId) {
      return of({payments: [], total: 0});
    }

    let url = this.environmentService.get('backendUrl') + '/search/payments/';
    url += encodeURIComponent(searchString);

    return this.http.post<{ payments: PaymentDto[], total: number }>(url, {practiceId, filters}, {withCredentials: true}).pipe(
      map((response: { payments: PaymentDto[], total: number }) => {
        return {
          payments: response.payments.map((item) =>
            this.paymentAdapter.run(
              item,
              item.client ? this.clientAdapter.run(item.client) : undefined
            )
          ),
          total: response.total
        };
      })
    );
  }
}
