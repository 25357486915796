import { Pipe, PipeTransform } from '@angular/core';
import {Conversation} from '../models/Conversation';
import { TranslateService } from '@ngx-translate/core';

@Pipe({name: 'getConversationLatestMessagePipe'})
export class GetConversationLatestMessagePipe implements PipeTransform {
    constructor(private translateService: TranslateService) {}

    transform(conversation: Conversation): string {
      if (!conversation.latestMessage) {
        return this.translateService.instant('pipes.get_conversation_latest_message.no_messages');
      }

      let str = '';

      if (conversation.latestMessage.content.length > 0) {
        str = conversation.latestMessage.content;
      }

      conversation.latestMessage.attachments?.forEach((attachment) => {
        if (attachment.mime.includes('image')) {
          str += this.translateService.instant('pipes.get_conversation_latest_message.image_attached');
          return;
        }

        if (attachment.mime.includes('video')) {
          str += this.translateService.instant('pipes.get_conversation_latest_message.video_attached')
          return;
        }

        if (attachment.mime.includes('audio')) {
          str += this.translateService.instant('pipes.get_conversation_latest_message.audio_attached');
          return;
        }

        if (attachment.mime.includes('pdf')) {
          str += this.translateService.instant('pipes.get_conversation_latest_message.pdf_attached');
          return;
        }

        str += this.translateService.instant('pipes.get_conversation_latest_message.file_attached');
      });

      return str;
    }
}
