import { Injectable } from '@angular/core';
import { Group } from '../models/Group';
import { GroupDto } from '../interfaces/dto/group.dto';

@Injectable({
  providedIn: 'root'
})
export class GroupAdapter {

  run(dto: GroupDto): Group {
    return {
      ...dto,
      id: dto.id.toString(),
      practiceId: dto.practiceId.toString(),
    };
  }
}
