import { WhatsappTemplateCategory } from "../enums/whatsapp-template-category";

export function prettifyTemplateCategory(category: WhatsappTemplateCategory): string {
  switch (category) {
    case WhatsappTemplateCategory.MARKETING:
      return 'helpers.prettify_template_category.marketing';
    case WhatsappTemplateCategory.UTILITY:
      return 'helpers.prettify_template_category.utility';
    default:
      return 'helpers.prettify_template_category.n_a';
  }
}
