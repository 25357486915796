import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { FullPageSidebarComponent } from './layouts/full-page-sidebar/full-page-sidebar.component';
import { FullPageComponent } from './layouts/full-page/full-page.component';
import {PracticeHasMessagingGuard} from "./guards/practice-has-messaging.guard";
import {PracticeHasPaymentsGuard} from "./guards/practice-has-payments.guard";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth/login',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    component: FullPageComponent,
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'conversation/:id',
    redirectTo: 'conversations/:id',
  },
  {
    path: 'conversations',
    component: FullPageSidebarComponent,
    loadChildren: () => import('./conversation/conversation.module').then(m => m.ConversationModule),
    canActivate: [AuthGuard, PracticeHasMessagingGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'waiting-room',
    redirectTo: 'day-list',
    pathMatch: 'full'
  },
  {
    path: 'day-list',
    component: FullPageSidebarComponent,
    loadChildren: () => import('./waiting-room/waiting-room.module').then(m => m.WaitingRoomModule),
    canActivate: [AuthGuard, PracticeHasMessagingGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'insights',
    component: FullPageSidebarComponent,
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard, PracticeHasMessagingGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'payments',
    component: FullPageSidebarComponent,
    loadChildren: () => import('./payments/payments.module').then(m => m.PaymentsModule),
    canActivate: [AuthGuard, PracticeHasPaymentsGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'search',
    component: FullPageSidebarComponent,
    loadChildren: () => import('./search/search.module').then(m => m.SearchModule),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'broadcasts',
    component: FullPageSidebarComponent,
    loadChildren: () => import('./campaigns/campaigns.module').then(m => m.CampaignsModule),
    canActivate: [AuthGuard, PracticeHasMessagingGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'templates',
    component: FullPageSidebarComponent,
    loadChildren: () => import('./templates/templates.module').then(m => m.TemplatesModule),
    canActivate: [AuthGuard, PracticeHasMessagingGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'media',
    component: FullPageSidebarComponent,
    loadChildren: () => import('./media/media.module').then(m => m.MediaModule),
    canActivate: [AuthGuard, PracticeHasMessagingGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'product-requests',
    component: FullPageSidebarComponent,
    loadChildren: () => import('./product-requests/product-requests.module').then(m => m.ProductRequestsModule),
    canActivate: [AuthGuard, PracticeHasMessagingGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'setup-issue',
    component: FullPageSidebarComponent,
    loadChildren: () => import('./setup-issue/setup-issue.module').then(m => m.SetupIssueModule),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'clients',
    component: FullPageSidebarComponent,
    loadChildren: () => import('./clients/clients.module').then(m => m.ClientsModule),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'forms',
    component: FullPageSidebarComponent,
    loadChildren: () => import('./forms/forms.module').then(m => m.FormsModule),
    canActivate: [AuthGuard, PracticeHasMessagingGuard],
    runGuardsAndResolvers: 'always'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
