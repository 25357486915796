import { Pipe, PipeTransform } from '@angular/core';
import { canPaymentBeRefunded } from '../helpers/can-payment-be-refunded';
import { Payment } from '../models/Payment';
import { Practice } from '../models/Practice';

@Pipe({name: 'canPaymentBeRefunded'})
export class CanPaymentBeRefundedPipe implements PipeTransform {
  transform(payment: Payment, practice: Practice): boolean {
    return canPaymentBeRefunded(payment, practice);
  }
}
