import { Pipe, PipeTransform } from '@angular/core';
import { ThemeService } from '../services/theme.service';

@Pipe({
  name: 'themeSvgColor'
})
export class ThemeSvgColorPipe implements PipeTransform {

  constructor(private themeService: ThemeService) {}
  transform(theme: string): unknown {
    return this.themeService.getSvgIconColor(theme);
  }

}
