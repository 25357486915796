import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input, OnChanges,
  OnInit,
  Output,
  Renderer2, SimpleChanges,
  ViewChild
} from '@angular/core';
import {MessageAttachment} from '../../../models/MessageAttachment';

@Component({
  selector: 'message-attachment',
  templateUrl: './message-attachment.component.html',
  styleUrls: ['./message-attachment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageAttachmentComponent implements OnInit, AfterViewInit {
  revealed = false;
  @ViewChild('imageWrap') private imageWrap: ElementRef | undefined;
  @ViewChild('videoElement') private videoElement: ElementRef | undefined;
  @Input() attachment?: MessageAttachment;
  @Input() outbound = false;
  @Output() loadedAttachment = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  }

  toggleReveal(): void {
    this.revealed = !this.revealed;
    if (this.videoElement) {
      this.videoElement.nativeElement.pause();
    }
  }

  loaded(): void {
    this.loadedAttachment.emit();
  }
}
