<ng-container *ngIf="loading && !depositAlreadyExistsForClient">
  <p-progressSpinner [style]="{width: '40px', height: '40px', margin: '40px auto', display: 'block'}" styleClass="custom-spinner" strokeWidth="4"></p-progressSpinner>
</ng-container>
<ng-container *ngIf="!loading && !depositAlreadyExistsForClient">
  <ng-container *ngIf="step === 0">
    <div class="subtitle">Search for client in clinic system</div>
    <client-selector [noModal]="true" (clientSelected)="selectClient($event)"></client-selector>
  </ng-container>
  <ng-container *ngIf="step === 1">
    <ng-container *ngIf="selectedClient">
      <div class="client"><span class="client-label">Client:</span> {{ selectedClient.fullName }}</div>
    </ng-container>
    <label for="confirm" class="checkbox">
      <p-checkbox [(ngModel)]="confirmed" id="confirm" [binary]="true"></p-checkbox>
      Please confirm that this client has paid their deposit by other means, i.e. not a Digital Practice payment request.
    </label>
    <ng-container *ngIf="error">
      <div class="error">{{ error }}</div>
    </ng-container>
    <div class="btn-row">
      <p-button (click)="submit()" label="Add Deposit" styleClass="p-button-secondary p-button-smalltext"></p-button>
    </div>
  </ng-container>
</ng-container>
<ng-container *ngIf="depositAlreadyExistsForClient">
  <div class="subtitle">There is already a deposit on file for this client</div>
  <div class="btn-row">
    <p-button (click)="viewClient()" label="View Client" styleClass="p-button-secondary p-button-smalltext"></p-button>
  </div>
</ng-container>
