import {Injectable} from '@angular/core';
import {EnvironmentService} from '../services/environment.service';
import {HttpClient} from '@angular/common/http';
import {Practice} from '../models/Practice';
import {from, Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {Media} from '../models/Media';
import {MediaDto} from '../interfaces/dto/media.dto';
import {MediaAdapter} from '../adapters/media.adapter';
import {MediaAddDto} from '../interfaces/dto/media-add.dto';
import {resizeImage} from '../helpers/resize-image';
import {MediaType} from "../enums/media-type";
import { MediaFolder } from '../models/MediaFolder';
import { MediaFolderAddDto } from '../interfaces/dto/media-folder-add.dto';
import { MediaFolderUpdateDto } from '../interfaces/dto/media-folder-update.dto';
import { MediaUpdateDto } from '../interfaces/dto/media-update.dto';

@Injectable({
  providedIn: 'root'
})
export class MediaService {
  constructor(
    private environmentService: EnvironmentService,
    private http: HttpClient,
    private mediaAdapter: MediaAdapter,
  ) {
  }

  getMediaFolders(practice: Practice | null, rootFolderId: number | null): Observable<MediaFolder[]> {
    if (!practice) {
      throw new Error('Practice not set');
    }

    const url = this.environmentService.get('backendUrl') + `/practices/${practice.id}/media-folders`;

    return this.http.post<MediaFolder[]>(url, {
      rootFolderId: rootFolderId,
    }, {
      withCredentials: true,
    }).pipe(
      map((response: MediaFolder[]) => {
        return response;
      })
    );
  }

  getMediaFolderTree(practice: Practice | null): Observable<MediaFolder[]> {
    if (!practice) {
      throw new Error('Practice not set');
    }

    const url = this.environmentService.get('backendUrl') + `/practices/${practice.id}/media-folder-tree`;

    return this.http.get<MediaFolder[]>(url, {
      withCredentials: true,
    }).pipe(
      map((response: MediaFolder[]) => {
        return response;
      })
    );
  }

  getMedia(practice: Practice | null, folderId: number | null, mediaTypes: MediaType[], perPage: number, skip: number): Observable<{ media: Media[], total: number}> {
    if (!practice) {
      throw new Error('Practice not set');
    }

    const url = this.environmentService.get('backendUrl') + `/practices/${practice.id}/media`;

    return this.http.post<{ media: MediaDto[], total: number}>(url, {
      folderId,
      mediaTypes,
      perPage,
      skip
    }, {
      withCredentials: true,
    }).pipe(
      map((response: { media: MediaDto[], total: number}) => {
        return {
          media: response.media.map((m) => this.mediaAdapter.run(m)),
          total: response.total
        };
      })
    );
  }

  addMedia(media: MediaAddDto, practice: Practice | null): Observable<Media> {
    if (!practice) {
      throw new Error('Practice not set');
    }

    return from(resizeImage(media.file, this.environmentService.get('maxImageSize') || 800)).pipe(switchMap(resizedFile => {
      return this.doAddMedia(practice.id, media.name, media.parentId, resizedFile);
    }));
  }

  updateMedia(media: MediaUpdateDto, practice: Practice | null): Observable<Media> {
    if (!practice) {
      throw new Error('Practice not set');
    }

    const url = this.environmentService.get('backendUrl') + `/practices/${practice.id}/media/${media.id}`;

    return this.http.patch<Media>(url, media, {withCredentials: true}).pipe(
      map((response: Media) => {
        return response;
      })
    );
  }

  doAddMedia(practiceId: string, name: string, folderId: number | null, file: File): Observable<Media> {
    const url = this.environmentService.get('backendUrl') + `/practices/${practiceId}/media/new`;

    const formData: FormData = new FormData();
    formData.append('asset', file, name);
    
    if (folderId) {
      formData.append('folderId', folderId.toString());
    }

    return this.http.post<{ media: MediaDto }>(url, formData, {withCredentials: true}).pipe(
      map((response: { media: MediaDto }) => {
        return this.mediaAdapter.run(response.media);
      })
    );
  }

  deleteMedia(media: Media, practice: Practice | null): Observable<Media> {
    if (!practice) {
      throw new Error('Practice not set');
    }

    const url = this.environmentService.get('backendUrl') + `/practices/${practice.id}/media/${media.id}`;

    return this.http.delete<{ media: MediaDto }>(url, {
      withCredentials: true,
    }).pipe(
      map((response: { media: MediaDto }) => {
        return this.mediaAdapter.run(response.media);
      })
    );
  }

  addMediaFolder(folder: MediaFolderAddDto, practice: Practice | null, parent: MediaFolder | null): Observable<MediaFolder> {
    if (!practice) {
      throw new Error('Practice not set');
    }

    const url = this.environmentService.get('backendUrl') + `/practices/${practice.id}/media-folder/new`;

    return this.http.post<MediaFolder>(url, {
      name: folder.name,
      parent: parent ? parent.id : null
    }, {withCredentials: true}).pipe(
      map((response: MediaFolder) => {
        return response;
      })
    );
  }

  updateMediaFolder(folder: MediaFolderUpdateDto, practice: Practice | null): Observable<MediaFolder> {
    if (!practice) {
      throw new Error('Practice not set');
    }

    const url = this.environmentService.get('backendUrl') + `/practices/${practice.id}/media-folder/${folder.id}`;

    return this.http.patch<MediaFolder>(url, folder, {withCredentials: true}).pipe(
      map((response: MediaFolder) => {
        return response;
      })
    );
  }

  deleteMediaFolder(id: number, practice: Practice | null): Observable<boolean> {
    if (!practice) {
      throw new Error('Practice not set');
    }

    const url = this.environmentService.get('backendUrl') + `/practices/${practice.id}/media-folder/${id}`;

    return this.http.delete<MediaFolder>(url, {withCredentials: true}).pipe(
      map(() => {
        return true;
      })
    );
  }
}
