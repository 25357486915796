import { Injectable } from '@angular/core';
import { FacebookTemplates } from '../enums/facebook-templates';

@Injectable({
  providedIn: 'root'
})
export class FacebookTemplateService {
  constructor() {
  }

  newTicket(practiceName: string): string {
    return FacebookTemplates.new_ticket.replace('{{ practice }}', practiceName);
  }

  reinitialise(): string {
    return FacebookTemplates.reinitialise;
  }
}
