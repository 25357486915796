import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'lightswitch',
  templateUrl: './lightswitch.component.html',
  styleUrls: ['./lightswitch.component.scss']
})
export class LightswitchComponent implements OnInit {
  @Input() labelLeft?: string;
  @Input() labelRight?: string;
  @Input() status = false;
  @Output() changeStatus = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  toggle(): void {
    this.changeStatus.emit();
  }
}
