import { ProductRequestApprovalStatus } from "../enums/product-request-approval-status";
import { ProductRequestStatus } from "../enums/product-request-status";

export function prettifyProductRequestApprovalStatus(status: ProductRequestApprovalStatus): string {
    switch (status) {
        case ProductRequestApprovalStatus.APPROVED:
            return 'Approved';
        case ProductRequestApprovalStatus.REJECTED:
            return 'Rejected';
        case ProductRequestApprovalStatus.PENDING:
            return 'Pending';
        case ProductRequestApprovalStatus.NOT_REQUIRED:
            return 'Not Required';
    }
}