import { Pipe, PipeTransform } from '@angular/core';


@Pipe({name: 'minutesAndSeconds'})
export class MinutesAndSecondsPipe implements PipeTransform {
    transform(value: number): string {
      const minutes = Math.floor(value / 60);
      const seconds = value - (minutes * 60);

      let secondsString: string;
      if (seconds < 10) {
        secondsString =  '0' + seconds.toString();
      } else {
        secondsString =  seconds.toString();
      }

      return minutes.toString() + ':' + secondsString;
    }
}
