import {AfterContentInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, takeWhile } from 'rxjs';
import {Tag} from '../../../models/Tag';
import { ConversationState } from '../../state/reducer';
import { isTagsLoading } from '../../state/selectors';

@Component({
  selector: 'conversation-tag-manager',
  templateUrl: './conversation-tag-manager.component.html',
  styleUrls: ['./conversation-tag-manager.component.scss']
})
export class ConversationTagManagerComponent implements OnInit, OnDestroy {
  @Input() allTags: Tag[] = [];
  @Input() selectedTags: Tag[] = [];
  @Output() tagsChanged = new EventEmitter<Tag[]>();
  alive = true;
  showManageTags = false;
  tagsLoading$: Observable<boolean>;

  constructor(private store: Store<ConversationState>) {
    this.tagsLoading$ = this.store.pipe(select(isTagsLoading)).pipe(takeWhile(() => this.alive));
  }

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  tagsChange(): void {
    this.tagsChanged.emit(this.selectedTags);
  }
}
