import { Component, OnInit } from '@angular/core';
import {Event, NavigationEnd, Router} from '@angular/router';

@Component({
  selector: 'page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss']
})
export class PageTitleComponent implements OnInit {
  pageTitle = '';

  constructor(private router: Router) {
    this.subscribeToRouter();
  }

  ngOnInit(): void {
  }

  subscribeToRouter(): void {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('day-list')) {
          this.pageTitle = 'Day list';
        } else if (event.url.includes('insights')) {
          this.pageTitle = 'Insights';
        } else if (event.url.includes('payments')) {
          this.pageTitle = 'Payments';
        } else if (event.url.includes('search')) {
          this.pageTitle = 'Search';
        } else if (event.url.includes('broadcasts')) {
          this.pageTitle = 'Broadcasts';
        } else if (event.url.includes('/conversations/')) {
          this.pageTitle = 'Conversation';
        } else if (event.url.includes('/conversations')) {
          this.pageTitle = 'All Conversations';
        } else if (event.url.includes('/templates')) {
          this.pageTitle = 'Templates';
        } else if (event.url.includes('/media')) {
          this.pageTitle = 'Media Library';
        } else if (event.url.includes('/product-requests')) {
          this.pageTitle = 'Product Requests';
        } else if (event.url.includes('/setup-issue')) {
          this.pageTitle = 'Setup Issue';
        } else if (event.url.includes('/clients')) {
          this.pageTitle = 'Client Insights';
        } else if (event.url.includes('/forms/submissions')) {
          this.pageTitle = 'Form Submissions';
        } else if (event.url.includes('/forms')) {
          this.pageTitle = 'Forms';
        }
      }
    });
  }
}
