import { createFeatureSelector, createSelector } from "@ngrx/store";
import {
  isSendingAudio,
  isSendingFile,
  isSendingVideo,
} from "../messages/state/selectors";
import { Client } from "../models/Client";
import { Conversation } from "../models/Conversation";
import { Practice } from "../models/Practice";
import { AppState, STATE_KEY, SystemState } from "./reducers";

export const getSystemState = createFeatureSelector<SystemState>(
  STATE_KEY.SYSTEM,
);

export const selectPractice = (state: AppState) =>
  state.practices.currentPractice;
export const selectConversation = (state: AppState) =>
  state.conversation.conversation;
export const selectClient = (state: AppState) => state.conversation.client;

export const getCurrentConversationAndPractice = createSelector(
  selectPractice,
  selectConversation,
  (practice: Practice | null, conversation: Conversation | null) => {
    return {
      practice,
      conversation,
    };
  },
);

export const getCurrentConversationClientAndPractice = createSelector(
  selectPractice,
  selectConversation,
  selectClient,
  (
    practice: Practice | null,
    conversation: Conversation | null,
    client: Client | null,
  ) => {
    return {
      practice,
      conversation,
      client,
    };
  },
);

export const getTourMode = createSelector(
  getSystemState,
  (state: SystemState) => state.tourMode,
);

export const getMainNavState = createSelector(
  getSystemState,
  (state: SystemState) => state.mainNavOpen,
);

export const getHealth = createSelector(
  getSystemState,
  (state: SystemState) => state.healthy,
);

export const getWebsocketHealth = createSelector(
  getSystemState,
  (state: SystemState) => state.websocketHealthy,
);

export const getOneSignalUserId = createSelector(
  getSystemState,
  (state: SystemState) => state.oneSignalUserId,
);

export const getOneSignalNotificationsEnabled = createSelector(
  getSystemState,
  (state: SystemState) => state.oneSignalNotificationsEnabled,
);

export const getCurrencies = createSelector(
  getSystemState,
  (state: SystemState) => state.currencies,
);

export const isFullScreen = createSelector(
  getSystemState,
  (state: SystemState) => state.fullScreen,
);

export const isConversationPreviewOpen = createSelector(
  getSystemState,
  (state: SystemState) => state.conversationPreviewOpen,
);

export const getPreviewConversationId = createSelector(
  getSystemState,
  (state: SystemState) => state.previewConversationId,
);

export const canChangePage = createSelector(
  isSendingVideo,
  isSendingAudio,
  isSendingFile,
  (isSendingVideo, isSendingAudio, isSendingFile) =>
    !isSendingVideo && !isSendingAudio && !isSendingFile,
);

export const getTags = createSelector(
  getSystemState,
  (state: SystemState) => state.tags,
);

export const isConversationPreviewEnabled = createSelector(
  getSystemState,
  (state: SystemState) => state.conversationPreviewEnabled,
);
