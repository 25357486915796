import * as actions from './actions';
import { Action, createReducer, on } from '@ngrx/store';
import { User } from 'src/app/models/User';

export interface UsersState {
  
}

export const initialState: UsersState = {

};

const usersReducer = createReducer(
  initialState
);

export function reducer(state: UsersState | undefined, action: Action): UsersState {
  return usersReducer(state, action);
}
