import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { select, Store } from '@ngrx/store';
import { ProductRequest } from '../../../models/ProductRequest';
import { AppState } from '../../../state/reducers';
import {ProductRequestLayout} from '../../../enums/product-request-layout';
import {Practice} from '../../../models/Practice';
import {Subscription} from 'rxjs';
import {WebsocketService} from '../../../conversation/websocket.service';
import {getCurrentPractice} from '../../../practices/state/selectors';
import {takeWhile} from 'rxjs/operators';
import {HandleProductRequestUpdate} from '../../state/actions';
import {ProductRequestAdapter} from '../../../adapters/product-request.adapter';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'page-product-requests-list',
  templateUrl: './product-requests-list.component.html',
  styleUrls: ['./product-requests-list.component.scss']
})
export class ProductRequestsListPage implements OnInit, OnDestroy {
  alive = true;
  productRequests: ProductRequest[] = [];
  layout = ProductRequestLayout;
  currentPractice: Practice | null = null;
  websocketConnected = false;
  socketUpdates$?: Subscription;

  constructor(
    private store: Store<AppState>,
    private titleService: Title,
    private websocketService: WebsocketService,
    private productRequestAdapter: ProductRequestAdapter,
    private gaService: GoogleAnalyticsService,
  ) {
    const title = 'Digital Practice | Product Requests List';
    this.titleService.setTitle(title);
  }


  ngOnInit(): void {
    this.subscribeToCurrentPractice();

    this.websocketService.onConnect$.subscribe(connected => {
      this.websocketConnected = connected;
      this.joinRoom();
    });

    this.socketUpdates$ = this.websocketService.getProductRequestListUpdates().subscribe((data) => {
      this.store.dispatch(HandleProductRequestUpdate({productRequest: this.productRequestAdapter.run(data)}));
    });

    this.gaService.pageView(this.titleService.getTitle());
  }

  ngOnDestroy(): void {
    this.alive = false;

    this.socketUpdates$?.unsubscribe();

    if (this.currentPractice) {
      this.websocketService.leaveProductRequestList(this.currentPractice.id.toString());
    }
  }

  subscribeToCurrentPractice(): void {
    this.store.pipe(select(getCurrentPractice))
      .pipe(takeWhile(() => this.alive))
      .subscribe((practice) => {
        if (this.currentPractice) {
          this.websocketService.leaveProductRequestList(this.currentPractice.id.toString());
        }

        this.currentPractice = practice;
        this.joinRoom();
      });
  }

  joinRoom(): void {
    if (this.websocketConnected && this.currentPractice) {
      this.websocketService.joinProductRequestList(this.currentPractice.id.toString());
    }
  }
}
