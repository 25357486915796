import { Injectable } from '@angular/core';
import { UserAdapter } from './user.adapter';
import { ConversationUserDto } from '../interfaces/dto/conversation-user.dto';
import { ConversationUser } from '../models/ConversationUser';

@Injectable({
  providedIn: 'root'
})
export class ConversationUserAdapter {
    constructor(private userAdapter: UserAdapter) {}

    run(dto: ConversationUserDto): ConversationUser {
        let user = <ConversationUser>this.userAdapter.run(dto);
        user.channel = dto.channel;
        return user;
    }
}
