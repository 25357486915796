import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Practice} from '../../../models/Practice';
import {Role} from '../../../enums/role';
import {Store} from '@ngrx/store';
import {AppState} from '../../../state/reducers';
import { MediaFolder } from '../../../models/MediaFolder';
import { MenuItem } from 'primeng/api';
import { canDeleteMediaFolder } from '../../../helpers/can-delete-media-folder';
import { canRenameMediaFolder } from '../../../helpers/can-rename-media-folder';

@Component({
  selector: 'folder-grid-item',
  templateUrl: './folder-grid-item.component.html',
  styleUrls: ['./folder-grid-item.component.scss']
})
export class FolderGridItemComponent implements OnInit {
  @Input() folder?: MediaFolder;
  @Input() practice?: Practice;
  @Input() deletable = true;
  @Input() selector = false;
  @Input() manage = true;
  @Output() selected = new EventEmitter<MediaFolder>();
  @Output() rename = new EventEmitter<MediaFolder>();
  @Output() move = new EventEmitter<MediaFolder>();
  @Output() delete = new EventEmitter<MediaFolder>();
  systemAdmin = Role.SYSTEM_ADMIN;
  superAdmin = Role.SUPER_ADMIN;
  menuItems: MenuItem[] = [];
  
  constructor(private store: Store<AppState>) { }
  
  ngOnInit(): void {
    this.menuItems = [
      {
        label: 'Move',
        icon: 'pi pi-reply',
        command: (event) => {
          this.handleMove();
        }
      }
    ];

    if (this.practice && canRenameMediaFolder(this.practice.userRole)) {
      this.menuItems.push({
        label: 'Rename',
        icon: 'pi pi-pencil',
        command: (event) => {
          this.handleRename();
        }
      });
    }

    if (this.practice && canDeleteMediaFolder(this.practice.userRole)) {
      this.menuItems.push({
        label: 'Delete',
        icon: 'pi pi-trash',
        command: (event) => {
          this.handleDelete();
        }
      });
    }
  }

  handleClick(): void {
    if (this.folder) {
      this.selected.emit(this.folder);
    }
  }

  handleMove(): void {
    if (this.folder) {
      this.move.emit(this.folder);
    }
  }

  handleRename(): void {
    if (this.folder) {
      this.rename.emit(this.folder);
    }
  }

  handleDelete(): void {
    if (this.folder) {
      this.delete.emit(this.folder);
    }
  }
}
