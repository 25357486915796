import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FilterOption } from '../../../interfaces/filter-option.interface';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../state/reducers';
import { getCurrentPractice } from '../../../practices/state/selectors';
import { GetFilterClients } from '../../../clients/state/actions';
import { getFilterClients } from '../../../clients/state/selectors';
import { takeWhile } from 'rxjs';
import { FilterOptionType } from '../../../enums/filter-option-type';
import {Practice} from "../../../models/Practice";

@Component({
  selector: 'filter-client',
  templateUrl: './filter-client.component.html',
  styleUrls: ['./filter-client.component.scss']
})
export class FilterClientComponent implements OnInit, OnDestroy {
  @Input() preSelected: FilterOption[] = [];
  @Output() valueSelected = new EventEmitter<FilterOption[]>();
  selected: FilterOption[] = [];
  alive = true;
  open = false;
  options: FilterOption[] = [];
  practice: Practice | null = null;
  optionsSetOnLoad = false;

  constructor(private store: Store<AppState>) {
    this.store.pipe(select(getCurrentPractice)).subscribe(practice => {
      this.practice = practice;
      if (practice) {
        this.store.dispatch(GetFilterClients({practice}));
      }
    });

    this.store.pipe(select(getFilterClients)).pipe(takeWhile(() => this.alive)).subscribe(clients => {
      this.options = [...clients].map((client) => {
        return {
          id: client.id,
          label: client.fullName,
          sublabel: client.pmsId,
          type: FilterOptionType.CLIENT,
          model: client
        };
      });

      if (!this.optionsSetOnLoad) {
        this.optionsSetOnLoad = true;
        this.selected = this.options.filter(option => this.preSelected.find(item => item.id === option.id));
      }
    });
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  handleSelected(option: FilterOption): void {
    if (this.selected.find((item) => item.id === option.id)) {
      this.selected = this.selected.filter(selected => selected.id !== option.id);
    } else {
      this.selected = [
        ...this.selected,
        option
      ];
    }

    this.valueSelected.emit(this.selected);
  }

  handleFilterUpdated(filter: string): void {
    // if (this.practice) {
    //   this.store.dispatch(GetFilterClients({practice: this.practice, search: filter}));
    // }
  }
}
