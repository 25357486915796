<div class="wrap">
    <p-dialog
    [header]="'ui.dialog_no_practices.error' | translate"
        [visible]="true"
        [modal]="true"
        [style]="{width: '400px'}"
        [baseZIndex]="10000"
        [draggable]="false"
        [resizable]="false"
        styleClass="no-practice-dialog"
    >
        <div class="message" *ngIf="message"> {{message}}</div>
        <div class="message" *ngIf="!message">{{ 'ui.dialog_no_practices.message' | translate}}</div>
        <div class="btn-row"><button pButton [label]="'ui.dialog_no_practices.logout' | translate" class="pm-button-primary" (click)="logout()"></button></div>
    </p-dialog>
</div>
