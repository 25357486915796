<ng-container *ngIf="patients && patients.length > 0">
    <div class="patient-list-wrap">
        <div class="patient-list">
            <ng-container *ngFor="let patient of patients">
                <patient-list-item [disabled]="disabled" [patient]="patient" (patientSelected)="handlePatientSelected($event)"></patient-list-item>
            </ng-container>
        </div>
        <ng-container *ngIf="patients.length % 20 == 0 && !loading">
            <div class="btn-row">
                <button pButton class="pm-button-primary" [label]="'patients.patient_selector.show_more' | translate" (click)="showMorePatients()"></button>
            </div>
        </ng-container>
    </div>
</ng-container>
<ng-container *ngIf="!patients || patients.length === 0">
    {{'patients.patient_selector.no_patients' | translate}}
</ng-container>
