import { TemplateSubmissionStatus } from "../enums/template-submission-status";

export function prettifyTemplateSubmissionType(type: TemplateSubmissionStatus): string {
  switch (type) {
    case TemplateSubmissionStatus.Approved:
      return 'Approved';
    case TemplateSubmissionStatus.Disabled:
      return 'Disabled';
    case TemplateSubmissionStatus.Flagged:
      return 'Flagged';
    case TemplateSubmissionStatus.In_Appeal:
      return 'In Appeal';
    case TemplateSubmissionStatus.Pending:
      return 'Pending';
    case TemplateSubmissionStatus.Rejected:
      return 'Rejected';
    case TemplateSubmissionStatus.Submitted:
      return 'Submitted';
    case TemplateSubmissionStatus.Paused:
      return 'Paused';
    default:
      return 'N/A';
  }
}
