import { Pipe, PipeTransform } from '@angular/core';
import { canPaymentBeCaptured } from '../helpers/can-payment-be-captured';
import { Payment } from '../models/Payment';

@Pipe({name: 'canPaymentBeCaptured'})
export class CanPaymentBeCapturedPipe implements PipeTransform {
  transform(payment: Payment): boolean {
    return canPaymentBeCaptured(payment);
  }
}
