<div class="row">
    <!-- <div class="col icon">
        <img src="assets/images/icons/icon-paw.svg" alt="">
    </div> -->
    <div class="col">
        <div class="heading">
            <img src="assets/images/icons/icon-paw.svg" class="icon">
            <div class="text">Select Patient</div>
        </div>
        <patient-selector [disabled]="disabled" [patients]="patients" (patientSelected)="handlePatientSelected($event)" (morePatients)="handleMorePatients()"></patient-selector>
    </div>
</div>
