<div class="status-holder" (clickOutside)="closeFlyout()">
  <ng-container *ngIf="resolved">
    <capsule [small]="true" label="Resolved" backgroundColor="'var(--surface-500)'"></capsule>
  </ng-container>
  <ng-container *ngIf="!resolved">
    <ng-container *ngIf="currentStatus">
        <capsule [small]="true" class="clickable" (click)="toggleFlyout()" [label]="currentStatus.name" [backgroundColor]="currentStatus.color"></capsule>
    </ng-container>
    <ng-container *ngIf="!currentStatus">
        <capsule [small]="true" class="clickable" (click)="toggleFlyout()" label="Not set" [backgroundColor]="'var(--surface-400)'"></capsule>
    </ng-container>
    <p-listbox [class.active]="flyoutActive" [options]="statuses" [ngModel]="currentStatus" (ngModelChange)="onStatusChange($event)" (onClick)="handleStatusClick($event)" optionLabel="name">
        <ng-template let-status pTemplate="item">
            <div class="status-item">
                <div class="swatch" [style.background-color]="status.color"></div>
                <div class="label">{{ status.name }}</div>
            </div>
        </ng-template>
    </p-listbox>
  </ng-container>
</div>

