import { createAction, props } from '@ngrx/store';
import { Notification } from 'src/app/models/Notification';

export const GetNotifications = createAction(
    '[Notifications] Get Notifications',
    props<{page: number, perPage: number, unreadOnly: boolean}>()
);

export const GetNotificationsSuccess = createAction(
    '[Notifications] Get Notifications - Success',
    props<{notifications: Notification[], totalCount: number, replace: boolean}>()
);

export const AddNotification = createAction(
    '[Notifications] Add Notification',
    props<{notification: Notification}>()
);

export const ShowNotification = createAction(
    '[Notifications] Show Notification',
    props<{notification: Notification}>()
);

export const HideNotification = createAction(
    '[Notifications] Hide Notification',
    props<{notification: Notification}>()
);

export const MarkNotificationAsUnread = createAction(
    '[Notifications] Mark Notification As Unread',
    props<{notification: Notification}>()
);

export const MarkNotificationAsRead = createAction(
    '[Notifications] Mark Notification As Read',
    props<{notification: Notification}>()
);

export const MarkAllNotificationsAsRead = createAction(
    '[Notifications] Mark All Notifications As Read'
);

export const ToggleToShowAllNotifications = createAction(
    '[Notifications] Toggle To Show All Notifications'
);

