import { TranslateService } from "@ngx-translate/core";
import {FormSubmissionData} from "../forms/interfaces/form-submission-data";
import {PageField} from "../forms/interfaces/page-field";

export function getSubmittedFieldValue(field: PageField, submissionData: FormSubmissionData[], translateService: TranslateService): string {
  const matchingData = submissionData.find((datum) => datum.fieldId === field.id);

  const notProvidedText = translateService.instant('helpers.get_submitted_field_value.not_provided');
  return matchingData?.fieldValue || notProvidedText;
}
