import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { QuillEditorComponent, QuillModules } from 'ngx-quill';
import 'vhd-quill-mention-2';
import Delta from 'quill-delta';
import { takeWhile } from 'rxjs/operators';
import { Conversation } from 'src/app/models/Conversation';
import { User } from 'src/app/models/User';
import { GetPracticeGroups, GetPracticeStaff } from 'src/app/practices/state/actions';
import { getPracticeGroups, getPracticeStaff } from 'src/app/practices/state/selectors';
import { AppState } from 'src/app/state/reducers';
import 'vhd-quill-emoji/dist/quill-emoji.js';
import { Group } from '../../../models/Group';


enum MentionableType {
  USER = 'user',
  GROUP = 'group'
}

interface Mentionable {
  id: number;
  value: string;
  type: MentionableType;
}

const emojiIcon = '<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="11" cy="11" r="10" stroke="#4F4F4F" stroke-width="2"/><path d="M7.875 9.84C8.58 9.84 9.03 9.33 9.03 8.67C9.015 7.98 8.565 7.5 7.89 7.5C7.23 7.5 6.75 7.98 6.75 8.67C6.75 9.33 7.215 9.84 7.875 9.84Z" fill="#4F4F4F"/><path d="M14.125 9.84C14.83 9.84 15.28 9.33 15.28 8.67C15.265 7.98 14.815 7.5 14.14 7.5C13.48 7.5 13 7.98 13 8.67C13 9.33 13.465 9.84 14.125 9.84Z" fill="#4F4F4F"/><path d="M10.875 16.875C8.2355 16.875 6.26503 15.5143 5.25821 14.5465C5.08516 14.3802 5.24292 14.1179 5.47739 14.1692C6.86415 14.4727 9.13106 14.875 10.875 14.875C12.6161 14.875 15.0504 14.474 16.5677 14.1707C16.8103 14.1222 16.9609 14.4032 16.7721 14.5631C15.6307 15.5297 13.4995 16.875 10.875 16.875Z" fill="#4F4F4F"/></svg>';

@Component({
  selector: 'add-comment-form',
  templateUrl: './add-comment-form.component.html',
  styleUrls: ['./add-comment-form.component.scss']
})
export class AddCommentFormComponent implements OnInit, OnDestroy {
  @Input() conversation: Conversation | null = null;
  @Input() disabled = false;
  @Output() commentSent = new EventEmitter<Delta>();
  @ViewChild(QuillEditorComponent, { static: true }) editor?: QuillEditorComponent;
  users: User[] = [];
  groups: Group[] = [];
  alive = true;
  message?: Delta;
  messageRaw = '';
  messageHtml = '';
  messageLength: number | undefined = 0;
  quillModules: QuillModules = {
    magicUrl: true,
    toolbar: false,
    'emoji-toolbar': true,
    'emoji-shortname': true,
    'emoji-textarea': {
      buttonIcon: emojiIcon
    },
    mention: {
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      mentionDenotationChars: ['@'],
      dataAttributes: [
        'id',
        'value',
        'denotationChar',
        'link',
        'target',
        'disabled',
        'type',
        'image',
        'firstName',
        'lastName'
      ],
      source: async (searchTerm: string, renderList: any): Promise<void> => {
        const mentionables = await this.getMentionItems(searchTerm);
        renderList(mentionables);
      },
    },
  };

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
    this.subscribeToPracticeUsers();
    this.subscribeToPracticeGroups();
    this.store.dispatch(GetPracticeStaff());
    this.store.dispatch(GetPracticeGroups());
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  subscribeToPracticeUsers(): void {
    this.store.pipe(select(getPracticeStaff)).pipe(takeWhile(() => this.alive)).subscribe(users => this.users = users);
  }

  subscribeToPracticeGroups(): void {
    this.store.pipe(select(getPracticeGroups)).pipe(takeWhile(() => this.alive)).subscribe(groups => this.groups = groups);
  }

  handleMessageUpdate(quillData: {content: Delta, text: string}): void {
    this.message = quillData.content;
    this.messageRaw = quillData.text;
    this.messageLength = this.editor?.quillEditor.getLength();
  }

  send(): void {
    if (this.conversation && !!this.conversation.resolvedAt) {
      return;
    }

    const isEmpty = this.isMessageHtmlEmpty();

    if (
      this.messageLength &&
      this.messageLength > 1 &&
      !isEmpty
    ) {
      this.commentSent.emit(this.message);
      this.messageHtml = '';
    }
  }

  async getMentionItems(searchTerm: string): Promise<Mentionable[]> {
    const users =  this.users.filter(user => {
      const name = `${user.firstName} ${user.lastName}`.toLowerCase();
      const search = searchTerm.toLowerCase();
      return name.includes(search);
    }).map(user => {
      return {
        id: Number(user.id),
        value: `${user.firstName} ${user.lastName}`,
        type: MentionableType.USER,
        firstName: user.firstName,
        lastName: user.lastName,
        image: user.avatarUrl
      };
    });

    const groups =  this.groups.filter(group => {
      const search = searchTerm.toLowerCase();
      return group.name.toLowerCase().includes(search);
    }).map(group => {
      return {
        id: Number(group.id),
        value: group.name,
        type: MentionableType.GROUP,
        firstName: null,
        lastName: null,
        image: null
      };
    });

    return [
      ...groups,
      ...users
    ];
  }

  private isMessageHtmlEmpty(): boolean {
    if (this.messageHtml === null) {
      return true;
    } else {
      const strippedhtml = this.messageHtml
      .replace(/<p>/g, '')
      .replace(/<\/p>/g, '')
      .replace(/<br>/g, '')
      .replace(/<br\/>/g, '')
      .replace(/\s/g, '');

      return !(strippedhtml.length > 0);
    }
  }
}
