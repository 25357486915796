<div class="kanban">
  <ng-container *ngFor="let column of columns">
    <div class="kanban-column" [class.available]="availableColumns.includes(column.status)" pDroppable (onDrop)="drop(column.status)">
      <div class="column-heading">{{ column.status | prettifyProductRequestStatus | translate }}</div>

      <ng-container *ngFor="let productRequest of column.requests">
        <div class="product-request-card" pDraggable (onDragStart)="dragStart(productRequest)" (onDragEnd)="dragEnd(productRequest)">
          <product-request-card [productRequest]="productRequest" [updating]="this.updatingProductRequestIds.includes(productRequest.id)"></product-request-card>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
