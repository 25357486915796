import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { CurrencyCode } from '../enums/currency-code';
import {getClientBalance} from "../helpers/get-client-balance";
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'clientBalance'
})
export class ClientBalancePipe implements PipeTransform {

  constructor(public currencyPipe: CurrencyPipe, private translateService: TranslateService) {
  }

  transform(value: string, currencyCode: CurrencyCode = CurrencyCode.GBP, currencySymbol: string = '£'): string {
    return getClientBalance(this.currencyPipe, value, currencyCode, currencySymbol, this.translateService);
  }
}
