import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {AppState} from '../../state/reducers';
import {Router} from '@angular/router';
import * as DashboardActions from './actions';
import {map, mergeMap, withLatestFrom} from 'rxjs/operators';
import {getCurrentPractice} from '../../practices/state/selectors';
import {DashboardService} from '../dashboard.service';
import {DashboardStats} from '../../interfaces/dashboard-stats.interface';

@Injectable()
export class DashboardEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private router: Router,
    private dashboardService: DashboardService
  ) {
  }

  getPayments$ = createEffect(() => this.actions$.pipe(
    ofType(DashboardActions.GetDashboardStats),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      return this.dashboardService.getDashboardStats(practice)
        .pipe(
          map((result: DashboardStats) => {
            return DashboardActions.GetDashboardStatsSuccess({stats: result});
          })
        );
    })
  ));
}
