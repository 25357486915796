import * as actions from './actions';
import {Action, createReducer, on} from '@ngrx/store';
import {Conversation} from '../../models/Conversation';
import {Payment} from '../../models/Payment';

export interface SearchState {
  searchLoading: boolean;
  searchedConversations: Conversation[];
  totalConversationsCount: number;
  searchedPayments: Payment[];
  totalPaymentsCount: number;
}

export const initialState: SearchState = {
  searchLoading: false,
  searchedConversations: [],
  totalConversationsCount: 0,
  searchedPayments: [],
  totalPaymentsCount: 0,
};


const searchReducer = createReducer(
  initialState,
  on(actions.DoConversationSearch, (state, payload) => ({
    ...state,
    searchLoading: true,
  })),
  on(actions.DoPaymentsSearch, (state, payload) => ({
    ...state,
    searchLoading: true,
  })),
  on(actions.DoConversationsSearchSuccess, (state, payload) => ({
    ...state,
    searchLoading: false,
    searchedConversations: payload.result.conversations,
    totalConversationsCount: payload.result.total
  })),
  on(actions.DoPaymentSearchSuccess, (state, payload) => ({
    ...state,
    searchLoading: false,
    searchedPayments: payload.result.payments,
    totalPaymentsCount: payload.result.total
  })),
);

export function reducer(state: SearchState | undefined, action: Action): SearchState {
  return searchReducer(state, action);
}
