export function secondsToStr(input: number): string {
  const days = Math.floor((input %= 31536000) / 86400);
  if (days) {
    return days + ' day' + numberEnding(days);
  }

  const hours = Math.floor((input %= 86400) / 3600);
  if (hours) {
    return hours + ' hour' + numberEnding(hours);
  }

  const minutes = Math.floor((input %= 3600) / 60);
  if (minutes) {
    return minutes + ' minute' + numberEnding(minutes);
  }

  const seconds = Math.floor(input % 60);
  if (seconds) {
    return seconds + ' second' + numberEnding(seconds);
  }

  return '1 second';
}

function numberEnding(num: number): string {
  return (num !== 1) ? 's' : '';
}
