<div class="create-actions" [class.full-width]="fullWidth">
  <ng-container *ngFor="let createAction of createActions">
    <ng-container *ngIf="createAction.featureEnabled">
      <div class="create-action" [class.disabled]="createAction.disabled" (click)="createAction.command()">
        <div class="action-icon"><img [src]="createAction.icon" alt=""></div>
        {{ createAction.label }}
      </div>
    </ng-container>
  </ng-container>
</div>
