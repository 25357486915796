<div class="conversation-details" *ngIf="conversation">
  <div class="collapser">
    <div class="name">{{'conversation.components.details.name' | translate}} <span class="muted">#{{ conversation.id }}</span></div>
    <!-- <ng-container *ngIf="detailsOpen">
      <i class="pi pi-chevron-up"></i>
    </ng-container>
    <ng-container *ngIf="!detailsOpen">
      <i class="pi pi-chevron-down"></i>
    </ng-container> -->
  </div>
  <div class="collapsable" [class.active]="detailsOpen">
    <div class="wrapper">
      <div class="details-holder">
        <div class="top-row">
          <div class="icon">
            <i class="pi pi-info-circle"></i>
          </div>
          <div class="title">{{'conversation.components.details.name' | translate}} <span class="muted">#{{ conversation.id }}</span></div>
          <div class="watchers">
            <watchers [watchers]="conversation.watchers" (watch)="handleWatch($event)" (unwatch)="handleUnwatch($event)"></watchers>
          </div>
        </div>
        <div>
          <div class="watchers-mobile">
            <watchers [watchers]="conversation.watchers" (watch)="handleWatch($event)" (unwatch)="handleUnwatch($event)"></watchers>
          </div>
          <div class="date">
            <span class="label">{{'conversation.components.details.last_response' | translate}}</span>
            <span>{{ conversation.lastResponseAt | diffForHumans }}</span>
          </div>
          <div class="date">
            <span class="label">{{'conversation.components.details.created' | translate}}</span>
            <span>{{ conversation.createdAt | dfnsFormat:'dd/MM/yyyy HH:mm' }}</span>
          </div>
          <ng-container *ngIf="!!conversation.resolvedAt">
            <div class="date resolved">
              <span class="label">{{'conversation.components.details.resolved' | translate}}</span>
              <span>{{ conversation.resolvedAt | date:'dd/MM/yy HH:mm' }}</span>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="resolve-action-row" joyrideStep="step14" [stepContent]="step14Content" stepPosition="left" (prev)="handleStepToClient()">
        <conversation-resolver (resolveConversation)="resolveConversation($event)" [disabled]="!!conversation.resolvedAt" buttonClass="p-button-success"></conversation-resolver>
        <ng-container *ngIf="!!conversation.resolvedAt">
          <div class="date">
            <span class="label">{{'conversation.components.details.resolved' | translate}}</span>
            <span>{{ conversation.resolvedAt | date:'dd/MM/yy HH:mm' }}</span>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="summary-row">
      <div class="summary-holder" joyrideStep="step8" [stepContent]="step8Content" stepPosition="left" (prev)="handleStepToTeam()">
        <div class="section-title">{{'conversation.components.details.summary' | translate}}</div>
        <ng-container *ngIf="!conversation.resolvedAt">
          <div class="summary-box">
            <textarea type="text" pInputTextarea [placeholder]="'conversation.components.details.summary_placeholder' | translate" [(ngModel)]="summary" (focus)="showSummaryButtons()"></textarea>
          </div>
          <div class="save-btn-row" [class.active]="summaryButtonsActive">
            <p-button (click)="updateSummary()" [label]="'conversation.components.details.save' | translate" styleClass="p-button-success p-button-sm"></p-button>
            <p-button (click)="revertSummaryChanges()" [label]="'conversation.components.details.cancel' | translate" styleClass="p-button-third p-button-sm"></p-button>
          </div>
        </ng-container>
        <ng-container *ngIf="!!conversation.resolvedAt">
          <div class="summary-box">
            <div class="summary">{{ summary }}</div>
          </div>
        </ng-container>
      </div>

      <div class="assignee-and-actions">
        <div class="owner-assignee-holder">
          <div class="user-column" joyrideStep="step9" [stepContent]="step9Content" stepPosition="left">
            <div class="label">{{'conversation.components.details.owner' | translate}}</div>
            <user-changer [disabled]="!!conversation.resolvedAt" [selected]="conversation.owner" [withGroups]="false" (userChange)="handleOwnerChange($event)"></user-changer>
          </div>
          <div class="user-column" joyrideStep="step10" [stepContent]="step10Content" stepPosition="left">
            <div class="label">{{'conversation.components.details.assignee' | translate}}</div>
            <user-changer [disabled]="!!conversation.resolvedAt" [selected]="conversation.assignee" (userChange)="handleAssigneeChange($event)"></user-changer>
          </div>
        </div>
        <div class="actions">
          <div class="priority-status">
            <div class="status" joyrideStep="step11" [stepContent]="step11Content" stepPosition="left" (next)="handleStepToClientAndOpenActions()">
              <div class="label">{{'conversation.components.details.status' | translate}}

              </div>
              <conversation-status [resolved]="!!conversation.resolvedAt" [currentStatus]="conversation.status" [statuses]="conversationStatuses" (statusChange)="handleStatusChange($event)"></conversation-status>
            </div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="practice && (practice.userRole === superAdmin || practice.userRole === analyst)">
        <div class="tags">
          <div class="title-wrap">
            <conversation-tag-manager
              [allTags]="allTags"
              [selectedTags]="selectedTags"
              (tagsChanged)="handleTagsChanged($event)"
            ></conversation-tag-manager>
          </div>
          <ng-container *ngIf="conversation.tags.length > 0">
            <conversation-tags [tags]="conversation.tags"></conversation-tags>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #step8Content>
  <div class="tour-item">
    <div class="top lilac"><img src="/assets/images/icons/tour/bars.svg" height="64" alt="" class="icon"></div>
    <div class="content">
      <div class="title">{{'conversation.components.details.step8_title' | translate}}</div>
      <div class="description">{{'conversation.components.details.step8_description' | translate}}</div>
    </div>
  </div>
</ng-template>

<ng-template #step9Content>
  <div class="tour-item">
    <div class="top pink"><img src="/assets/images/icons/tour/owner.svg" height="117" alt="" class="icon"></div>
    <div class="content">
      <div class="title">{{'conversation.components.details.step9_title' | translate}}</div>
      <div class="description">{{'conversation.components.details.step9_description' | translate}}</div>
    </div>
  </div>
</ng-template>

<ng-template #step10Content>
  <div class="tour-item">
    <div class="top mint"><img src="/assets/images/icons/tour/staff.svg" height="95" alt="" class="icon"></div>
    <div class="content">
      <div class="title">{{'conversation.components.details.step10_title' | translate}}</div>
      <div class="description">{{'conversation.components.details.step10_description' | translate}}</div>
    </div>
  </div>
</ng-template>

<ng-template #step11Content>
  <div class="tour-item">
    <div class="top blue"><img src="/assets/images/icons/tour/clinic-client.svg" height="116" alt="" class="icon"></div>
    <div class="content">
      <div class="title">{{'conversation.components.details.step11_title' | translate}}</div>
      <div class="description">{{'conversation.components.details.step11_description' | translate}}</div>
    </div>
  </div>
</ng-template>

<ng-template #step14Content>
  <div class="tour-item">
    <div class="top mint"><img src="/assets/images/icons/tour/check.svg" height="83" alt="" class="icon"></div>
    <div class="content">
      <div class="title">{{'conversation.components.details.step14_title' | translate}}</div>
      <div class="description">{{'conversation.components.details.step14_description' | translate}}</div>
    </div>
  </div>
</ng-template>
