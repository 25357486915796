<div class="filter-item" [class.active]="open" [class.highlight]="selectedValues.length > 0" (clickOutside)="close()">
  <div class="toggle" (click)="toggle()">
    <ng-container *ngIf="selectedValues.length === 0">
      <i [class]="icon"></i>
    </ng-container>
    <ng-container *ngIf="selectedValues.length > 0">
      <i class="pi pi-check"></i>
    </ng-container>
    <span class="label">{{ selectedValues | getFilterLabel:label }}</span> <dropdown-icon></dropdown-icon>
  </div>
  <div class="dropdown low-padding mobile-modal">
    <p-listbox [options]="options" [filter]="true" styleClass="no-pad client-filter" optionLabel="label" [filterValue]="filterText" [listStyle]="{'max-height':'300px', 'min-width': '300px'}">
      <ng-template pTemplate="filter">
        <div class="p-listbox-header ng-star-inserted">
          <div class="p-listbox-filter-container ng-star-inserted">
            <input type="text" class="p-listbox-filter p-inputtext p-component" [placeholder]="searchLabel" [(ngModel)]="filterText" (keyup)="handleFilterChange()">
            <span class="p-listbox-filter-icon pi pi-search"></span>
          </div>
        </div>
      </ng-template>
      <ng-template let-option pTemplate="item">
        <div class="item">
          <label [for]="option.id" class="label">
            <ng-container *ngIf="option.type === filterOptionType.CLIENT && option.model">
              <client-avatar [client]="option.model" size="small"></client-avatar>
            </ng-container>
            <ng-container *ngIf="option.type === filterOptionType.USER && option.model">
              <user-avatar [user]="option.model" size="small"></user-avatar>
            </ng-container>
            <div>
              <div class="label-text">
                {{ option.label }}
                <ng-container *ngIf="option.type === filterOptionType.USER && authUser && authUser.id === option.id">
                  <span class="me">(me)</span>
                </ng-container>
              </div>
              <ng-container *ngIf="option.sublabel">
                <div class="sublabel">{{ option.sublabel }}</div>
              </ng-container>
            </div>
          </label>
          <div class="right">
            <p-checkbox name="clients" [ngModel]="selectedValues" (onChange)="update(option)" [value]="option" [inputId]="option.id"></p-checkbox>
          </div>
        </div>
      </ng-template>
    </p-listbox>
  </div>
</div>
