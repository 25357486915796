import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ConversationDto} from '../interfaces/dto/conversation.dto';
import {EnvironmentService} from '../services/environment.service';
import {HttpClient} from '@angular/common/http';
import {ConversationAdapter} from '../adapters/conversation.adapter';
import {Conversation} from '../models/Conversation';

@Injectable({
  providedIn: 'root'
})
export class WaitingRoomService {

  constructor(
    private environmentService: EnvironmentService,
    private http: HttpClient,
    private conversationAdapter: ConversationAdapter,
  ) { }

  getConversations(practiceId: string): Observable<Conversation[]> {
    const url = this.environmentService.get('backendUrl') + `/practices/${practiceId}/conversations`;
    return this.http.get<ConversationDto[]>(url, {withCredentials: true}).pipe(
      map((response: ConversationDto[]) => {
        return response.map(status => this.conversationAdapter.run(status));
      })
    );
  }

  getResolvedConversations(practiceId: string): Observable<Conversation[]> {
    const url = this.environmentService.get('backendUrl') + `/practices/${practiceId}/resolved-conversations`;
    return this.http.get<ConversationDto[]>(url, {withCredentials: true}).pipe(
      map((response: ConversationDto[]) => {
        return response.map(status => this.conversationAdapter.run(status));
      })
    );
  }
}
