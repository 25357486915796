import { Conversation } from '../models/Conversation';
import { User } from '../models/User';
import { isUser } from './is-user';

export function isConversationAssignedToUser(conversation: Conversation, user: User | null): boolean {
    if (!user) {
        return false;
    }

    if (conversation.owner === undefined && conversation.assignee === undefined) {
        return false;
    }

    return (conversation.assignee && !isUser(conversation.assignee) && user.groups?.map((group) => group.id).includes(conversation.assignee.id)) ||
    (conversation.assignee && isUser(conversation.assignee) && conversation.assignee.id === user.id) ||
    conversation.owner?.id === user.id;
}
