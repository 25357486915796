import { Injectable } from '@angular/core';
import { ConversationDto } from '../interfaces/dto/conversation.dto';
import { Conversation } from '../models/Conversation';
import { ConversationStatusAdapter } from './conversation-status.adapter';
import { ConversationUserAdapter } from './conversation-user.adapter';
import { PriorityAdapter } from './priority.adapter';
import { UserAdapter } from './user.adapter';
import { MessageAdapter } from './message.adapter';
import { ClientAdapter } from './client.adapter';
import { PatientAdapter } from './patient.adapter';
import { PracticeAdapter } from './practice.adapter';
import { GroupAdapter } from './group.adapter';
import { isUserDto } from '../helpers/is-user-dto';
import {TagAdapter} from "./tag.adapter";

@Injectable({
  providedIn: 'root'
})
export class ConversationAdapter {
  constructor(
    private userAdapter: UserAdapter,
    private priorityAdapter: PriorityAdapter,
    private conversationStatusAdapter: ConversationStatusAdapter,
    private messageAdapter: MessageAdapter,
    private conversationUserAdapter: ConversationUserAdapter,
    private clientAdapter: ClientAdapter,
    private patientAdapter: PatientAdapter,
    private groupAdapter: GroupAdapter,
    private practiceAdapter: PracticeAdapter,
    private tagAdapter: TagAdapter,
  ) {}

  run(dto: ConversationDto): Conversation {
    let assignee;
    if (dto.assignee) {
      if (isUserDto(dto.assignee)) {
        assignee = this.userAdapter.run(dto.assignee);
      } else {
        assignee = this.groupAdapter.run(dto.assignee);
      }
    }

    return {
      id: dto.id.toString(),
      summary: dto.summary,
      assignee,
      owner: dto.owner ? this.userAdapter.run(dto.owner) : undefined,
      convertedBy: dto.convertedBy ? this.userAdapter.run(dto.convertedBy) : undefined,
      priority: dto.priority ? this.priorityAdapter.run(dto.priority) : undefined,
      status: dto.status ? this.conversationStatusAdapter.run(dto.status) : undefined,
      watchers: dto.watchers ? dto.watchers.map(watcher => this.userAdapter.run(watcher)) : undefined,
      labels: [],
      users: dto.users ? dto.users.map(conversationUser => this.conversationUserAdapter.run(conversationUser)) : undefined,
      channel: dto.channel,
      client: dto.client ? this.clientAdapter.run(dto.client) : undefined,
      patient: dto.patient ? this.patientAdapter.run(dto.patient) : undefined,
      createdAt: dto.createdAt ? new Date(dto.createdAt) : undefined,
      updatedAt: dto.updatedAt ? new Date(dto.updatedAt) : undefined,
      lastResponseAt: dto.lastResponseAt ? new Date(dto.lastResponseAt) : undefined,
      lastClientResponseAt: dto.lastClientResponseAt ? new Date(dto.lastClientResponseAt) : undefined,
      lastClinicResponseAt: dto.lastClinicResponseAt ? new Date(dto.lastClinicResponseAt) : undefined,
      timeSinceLastClientResponse: dto.lastClientResponseAt ? (new Date().getTime() - new Date(dto.lastClientResponseAt).getTime()) / 1000 : null,
      timeSinceLastClinicResponse: dto.lastClinicResponseAt ? (new Date().getTime() - new Date(dto.lastClinicResponseAt).getTime()) / 1000 : null,
      timeSinceCreated: dto.createdAt ? (new Date().getTime() - new Date(dto.createdAt).getTime()) / 1000 : 0,
      resolvedAt: dto.resolvedAt ? new Date(dto.resolvedAt) : undefined,
      newMessage: dto.newMessage,
      latestMessage: dto.latestMessage ? this.messageAdapter.run(dto.latestMessage) : undefined,
      multiplePotentialClients: dto.multiplePotentialClients,
      couldntAttemptMatch: dto.couldntAttemptMatch,
      failoverToSms: dto.failoverToSms,
      practice: dto.practice ? this.practiceAdapter.run(dto.practice) : undefined,
      messages: dto.messages ? dto.messages.map(message => this.messageAdapter.run(message)) : undefined,
      previousConversationCount: dto.previousConversationCount,
      queueMode: dto.queueMode,
      readStatus: dto.readStatus,
      syncFailures: dto.syncFailures,
      tags: dto.tags ? dto.tags.map(tag => this.tagAdapter.run(tag)) : []
    };
  }
}
