import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Client } from 'src/app/models/Client';

@Component({
  selector: 'client-list-item',
  templateUrl: './client-list-item.component.html',
  styleUrls: ['./client-list-item.component.scss']
})
export class ClientListItemComponent implements OnInit {
  @Input() client?: Client;
  @Output() clientSelected = new EventEmitter<Client>();

  constructor() { }

  ngOnInit(): void {
  }

  selectClient(): void {
    this.clientSelected.emit(this.client);
  }

}
