<ng-container *ngIf="comment">
    <div class="comment">
        <div class="avatar">
            <user-avatar [responsive]="true" [user]="comment.author"></user-avatar>
        </div>
        <div class="comment-details">
            <div class="meta">
                <ng-container *ngIf="comment.author">
                    <div>{{ comment.author.firstName }} {{ comment.author.lastName }}</div>
                </ng-container>
                <div>{{ comment.createdAt | dateOrDiff:refresh }}</div>
            </div>
            <ng-container *ngIf="comment.content">
                <div class="message">
                    <quill-view [content]="comment.content" format="object" theme="snow"></quill-view>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>
