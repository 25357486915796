import { Injectable } from '@angular/core';
import { ProcedureDto } from '../interfaces/dto/procedure.dto';
import { Procedure } from '../models/Procedure';
import { ProcedureItemAdapter } from './procedure-item.adapter';

@Injectable({
  providedIn: 'root'
})
export class ProcedureAdapter {
    constructor(private procedureItemAdapter: ProcedureItemAdapter) {}

    run(dto: ProcedureDto): Procedure {
        return {
            name: dto.name,
            type: dto.type,
            items: dto.items.map(item => this.procedureItemAdapter.run(item)),
            itemNumber: dto.itemNumber,
            totalPrice: dto.totalPrice,
        };
    }
}
