<ng-container *ngIf="productRequest">
  <div class="reject-product-request">
    <div class="input-row">
      <div class="label">Message for client explaining rejection:</div>
      <input pInputText type="text" [(ngModel)]="rejectionReason">
    </div>
    <ng-container *ngIf="validationError">
      <div class="error">{{ validationError }}</div>
    </ng-container>
    <div class="checkbox-row">
      <p-checkbox [(ngModel)]="notifyClient" [binary]="true"></p-checkbox>
      <div class="label">Notify client that this request has been rejected</div>
    </div>
    <div class="button-row">
      <p-button (click)="submit()" label="Submit" styleClass="p-button-primary"></p-button>
    </div>
  </div>
</ng-container>
