import {Component, Input, OnInit} from '@angular/core';
import {ProductRequest} from '../../../models/ProductRequest';
import {Store} from '@ngrx/store';
import {AppState} from '../../../state/reducers';
import {ApproveProductRequest} from '../../state/actions';
import {ProductRequestItem} from '../../../models/ProductRequestItem';
import {ProductRequestApprovalStatus} from "../../../enums/product-request-approval-status";

@Component({
  selector: 'approve-product-request',
  templateUrl: './approve-product-request.component.html',
  styleUrls: ['./approve-product-request.component.scss']
})
export class ApproveProductRequestComponent implements OnInit{
  @Input() productRequest?: ProductRequest;
  approvalItems: ProductRequestItem[] = [];
  approvedItemIds: number[] = [];
  rejectedItemIds: number[] = [];
  approvedCount: number[] = [];
  validationErrors: string[] = [];
  notifyClient = true;
  rejectionReason = '';

  constructor(private store: Store<AppState>) {
  }

  ngOnInit(): void {
    if (this.productRequest) {
      this.approvalItems = [...this.productRequest.items.filter(item => item.requiresApproval)];
      this.approvedCount = [...this.approvalItems.map(item => 0)];
      this.approvedItemIds = [
        ...this.approvalItems
          .filter(item => item.approvalStatus === ProductRequestApprovalStatus.APPROVED)
          .map(item => item.id)
      ];
      this.rejectedItemIds = [
        ...this.approvalItems
          .filter(item => item.approvalStatus === ProductRequestApprovalStatus.REJECTED)
          .map(item => item.id)
      ];

      this.prefilRejectionReason();
    }
  }

  submit(): void {
    this.validationErrors = [];
    this.validateRejectionReason();

    if (this.validationErrors.length > 0) {
      return;
    }

    if (this.productRequest) {
      this.store.dispatch(ApproveProductRequest({
        productRequest: this.productRequest,
        notifyClient: this.notifyClient,
        rejectionReason: this.rejectionReason
      }));
    }
  }

  validateRejectionReason(): void {
    if (this.rejectedItemIds.length > 0 && this.rejectionReason.trim().length === 0 && this.notifyClient) {
      this.validationErrors.push('You must enter a message to send to the client');
    }
  }

  prefilRejectionReason(): void {
    if (this.productRequest) {
      this.rejectionReason = this.productRequest.items
        .filter((item) => item.approvalStatus === ProductRequestApprovalStatus.REJECTED && item.rejectionReason)
        .map(item => item.rejectionReason)
        .join(', ');
    }
  }
}
