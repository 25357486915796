<ng-container *ngIf="loading">
  <div class="loading">
    <p-progressSpinner
      [style]="{ width: '40px', height: '40px' }"
      styleClass="custom-spinner"
      strokeWidth="4"
    ></p-progressSpinner>
  </div>
</ng-container>
<ng-container *ngIf="!loading && formSubmissions.length > 0">
  <div class="sub-list-headings">
    <div class="form-name">{{'forms.components.submission_list.form' | translate}}</div>
    <ng-container *ngIf="!client?.pmsId">
      <div class="client-name">{{'forms.components.submission_list.client' | translate}}</div>
    </ng-container>
    <ng-container *ngIf="!client?.pmsId">
      <div class="created">{{'forms.components.submission_list.sent' | translate}}</div>
    </ng-container>
    <div class="completed">{{'forms.components.submission_list.status' | translate}}</div>
    <ng-container *ngIf="!client?.pmsId">
      <div class="actions"></div>
    </ng-container>
  </div>

  <div class="subs">
    <ng-container *ngFor="let submission of formSubmissions">
      <div class="form-sub">
        <form-submission-list-item
          [client]="client"
          [formSubmission]="submission"
        ></form-submission-list-item>
      </div>
    </ng-container>
  </div>

  <ng-container *ngIf="!client?.pmsId">
  <div class="pagination-row" >
    <div class="clear"></div>
    <div class="results-count">
      {{'forms.components.submission_list.showing_submissions' | translate: {
        start: page * perPage - perPage + 1,
        end: totalItems > perPage ? page * perPage : totalItems,
        total: totalItems
      } }}
    </div>

    <div class="pagination">
      <ng-container *ngIf="showPreviousPageLink()">
        <div class="previous" (click)="previousPage()">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path
              fill="currentColor"
              d="M447.1 256C447.1 273.7 433.7 288 416 288H109.3l105.4 105.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448s-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L109.3 224H416C433.7 224 447.1 238.3 447.1 256z"
            />
          </svg>
        </div>
      </ng-container>
      <ng-container *ngIf="showPreviousPageLink() || showNextPageLink()">
        <div class="page">
          {{ this.page }}
        </div>
      </ng-container>
      <ng-container *ngIf="showNextPageLink()">
        <div class="next" (click)="nextPage()">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path
              fill="currentColor"
              d="M438.6 278.6l-160 160C272.4 444.9 264.2 448 256 448s-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L338.8 288H32C14.33 288 .0016 273.7 .0016 256S14.33 224 32 224h306.8l-105.4-105.4c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160C451.1 245.9 451.1 266.1 438.6 278.6z"
            />
          </svg>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="client">
  <div class="form-btn" >
    <p-button
      [label]="'forms.components.submission_list.view_forms_for' | translate: {name: client.fullName}"
      (click)="goToAllForms()"
    ></p-button>
  </div>
</ng-container>
</ng-container>
<ng-container *ngIf="!loading && formSubmissions.length === 0">
  <div class="no-results">{{'forms.components.submission_list.no_submissions' | translate}}</div>
</ng-container>
