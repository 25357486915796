<div class="status-holder" (clickOutside)="closeFlyout()">
  <ng-container *ngIf="resolved">
    <capsule [small]="true" [label]="'conversation.components.status.resolved' | translate" backgroundColor="'var(--surface-500)'"></capsule>
  </ng-container>
  <ng-container *ngIf="!resolved">
    <ng-container *ngIf="currentStatus">
        <capsule [small]="true" class="clickable" (click)="toggleFlyout()" [label]="'conversation.status.' + formatStatusKey(currentStatus.name) | translate" [backgroundColor]="currentStatus.color"></capsule>
    </ng-container>
    <ng-container *ngIf="!currentStatus">
        <capsule [small]="true" class="clickable" (click)="toggleFlyout()" [label]="'conversation.components.status.not_set' | translate" [backgroundColor]="'var(--surface-400)'"></capsule>
    </ng-container>
    <p-listbox [class.active]="flyoutActive" [options]="statuses" [ngModel]="currentStatus" (ngModelChange)="onStatusChange($event)" (onClick)="handleStatusClick($event)" optionLabel="name">
        <ng-template let-status pTemplate="item">
            <div class="status-item">
                <div class="swatch" [style.background-color]="status.color"></div>
                <div class="label">{{ 'conversation.status.' + formatStatusKey(status.name) | translate}}</div>
            </div>
        </ng-template>
    </p-listbox>
  </ng-container>
</div>

