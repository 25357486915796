import { PaymentStatus } from '../enums/payment-status';
import { IPaymentStatus } from '../interfaces/payment-status.interface';

export const paymentStatus: IPaymentStatus[] = [
  {
    id: PaymentStatus.EXPIRED,
    labelKey: 'enums.payment_status.expired',
    color: 'var(--alert-color)'
  },
  {
    id: PaymentStatus.FUNDS_HELD,
    labelKey: 'enums.payment_status.funds_held',
    color: 'var(--cyan-400)'
  },
  {
    id: PaymentStatus.HOLD_EXPIRED,
    labelKey: 'enums.payment_status.hold_expired',
    color: 'var(--alert-color)'
  },
  {
    id: PaymentStatus.PENDING,
    labelKey: 'enums.payment_status.pending',
    color: 'var(--orange-400)'
  },
  {
    id: PaymentStatus.QUEUED,
    labelKey: 'enums.payment_status.queued',
    color: 'var(--third-color)'
  },
  {
    id: PaymentStatus.PAID,
    labelKey: 'enums.payment_status.paid',
    color: 'var(--aquamarine-400)'
  },
  {
    id: PaymentStatus.COMPLETE,
    labelKey: 'enums.payment_status.complete',
    color: 'var(--aquamarine-400)'
  },
  {
    id: PaymentStatus.REFUNDED,
    labelKey: 'enums.payment_status.refunded',
    color: 'var(--aquamarine-400)'
  },
  {
    id: PaymentStatus.REFUND,
    labelKey: 'enums.payment_status.refund',
    color: 'var(--secondary-color)'
  },
  {
    id: PaymentStatus.CANCELLED,
    labelKey: 'enums.payment_status.cancelled',
    color: 'var(--surface-400)'
  }
];
