import { Component, Input, OnInit } from '@angular/core';
import { Procedure } from 'src/app/models/Procedure';

@Component({
  selector: 'procedure',
  templateUrl: './procedure.component.html',
  styleUrls: ['./procedure.component.scss']
})
export class ProcedureComponent implements OnInit {
  @Input() procedure?: Procedure;

  constructor() { }

  ngOnInit(): void {
  }

}
