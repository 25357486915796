import { createFeatureSelector, createSelector } from "@ngrx/store";
import { getUser } from "../../auth/state/selectors";
import { User } from "../../models/User";
import { STATE_KEY } from "../../state/reducers";
import { getUsersState } from "../../users/state/selectors";
import { ViewersState } from "./reducer";

export const getViewersState = createFeatureSelector<ViewersState>(STATE_KEY.VIEWERS);


export const getViewers = createSelector(
  getViewersState,
  (state: ViewersState) => state.viewers
);
  

export const getViewersExludingSelf = createSelector(
  getViewersState,
  getUser,
  (state: ViewersState, user: User | null) => state.viewers.filter((viewer) => viewer.id !== user?.id)
);
  