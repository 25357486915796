import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { takeWhile } from "rxjs/operators";
import { AppState } from "../../../state/reducers";
import { Observable, Subscription } from "rxjs";
import { User } from "../../../models/User";
import { getUser } from "../../../auth/state/selectors";
import { AuthService } from "../../../auth/auth.service";
import { GetUnreadHelpPosts } from "../../../auth/state/actions";
import {
  getAvailablePractices,
  getCurrentPractice,
} from "../../../practices/state/selectors";
import { Practice } from "../../../models/Practice";
import { getMainNavState } from "src/app/state/selectors";
import {
  CloseMainNav,
  OpenMainNav,
  ToggleMainNav,
} from "src/app/state/actions";
import { EnvironmentService } from "../../../services/environment.service";
import { PMS } from "../../../enums/pms";
import { UpdateConversationsFilters } from "../../../conversation/state/actions";
import { defaultConversationFilters } from "../../../constants/default-conversation-filters.constants";
import { UpdatePaymentFilters } from "../../../payments/state/actions";
import { defaultPaymentFilters } from "../../../constants/default-payment-filters.constants";
import { Role } from "../../../enums/role";
import { PaymentType } from "../../../enums/payment-type";
import { GetTemplates } from "../../../templates/state/actions";
import {
  ChangeMediaFolder,
  SetMediaFilters,
  SetMediaPage,
} from "../../../media/state/actions";
import { GetMediaFolders } from "../../../media/state/actions";
import { GetMedia } from "../../../media/state/actions";
import { mediaSettings } from "../../../constants/media-settings";
import { practiceHasFeature } from "../../../helpers/practice-has-feature";
import { PracticeFeature } from "../../../enums/practice-feature";
import { isMessagingEnabled } from "../../../helpers/is-messaging-enabled";
import { Theme } from "../../../enums/theme";

@Component({
  selector: "navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.scss"],
})
export class NavigationComponent implements OnInit, OnDestroy {
  private hasTouch = false;
  @Input() navOpen = false;
  @ViewChild("hint1") hint1?: any;
  @ViewChild("hint2") hint2?: any;
  @ViewChild("navContainer") private navContainer: ElementRef | undefined;
  alive = true;
  version = "";
  activeRoute = "";
  user$?: Observable<User | null>;
  navClosing = false;
  aboutOpen = false;
  user?: User;
  practices$?: Observable<Practice[] | null>;
  practice$?: Observable<Practice | null>;
  practiceSub?: Subscription;
  practice?: Practice;
  logo: string;
  settingsLink = "";
  notificationLink = "";
  profileLink = "";
  helpLink = "";
  PMS = PMS;
  device = "desktop";
  resizeTimeout: any;
  practiceContactDetailsOpen = false;
  userMenuOpen = false;
  showTemplatesLink = false;
  showProductRequests = false;
  showFormSubmissions = false;
  showForms = false;
  messagingEnabled = false;
  paymentListToggle = false;
  theme: string = Theme.DigitalPractice;
  constructor(
    private router: Router,
    private store: Store<AppState>,
    private authService: AuthService,
    private environmentService: EnvironmentService,
  ) {
    this.logo = this.environmentService.get("appLogo");
    this.device = this.getDevice();
    this.version = this.environmentService.get("appVersion");
  }

  ngOnInit(): void {
    this.subscribeToCurrentUser();
    this.subscribeToCurrentPractice();
    this.subscribeToAvailablePractices();
    this.subscribeToMainNavState();
    this.activeRoute = this.router.url;

    let listener: () => void;

    window.addEventListener(
      "touchstart",
      (listener = () => {
        this.hasTouch = true;
        window.removeEventListener("touchstart", listener);
      }),
    );

    this.getSettingsLink();
    this.getProfileLink();
    this.getHelpLink();
    this.scrollToTop();
    this.getNotificationLink();
  }

  ngOnDestroy(): void {
    this.alive = false;
    this.practiceSub?.unsubscribe();
  }

  @HostListener("window:resize")
  handleResize(): void {
    clearTimeout(this.resizeTimeout);
    this.resizeTimeout = setTimeout(() => {
      this.device = this.getDevice();
    }, 100);
  }

  getDevice(): string {
    if (window.innerWidth <= 1050) {
      return "mobile";
    }

    return "desktop";
  }

  getNotificationLink(): void {
    this.notificationLink = `${this.environmentService.get("authUrl")}/user/notifications`;
  }

  getSettingsLink(): void {
    this.settingsLink = `${this.environmentService.get("authUrl")}/clinics`;
  }

  getProfileLink(): void {
    this.profileLink = `${this.environmentService.get("authUrl")}/user/profile`;
  }

  getHelpLink(): void {
    this.helpLink = `${this.environmentService.get("helpUrl")}/learn/section/digital-practice`;
  }

  subscribeToMainNavState(): void {
    this.store
      .pipe(select(getMainNavState))
      .pipe(takeWhile(() => this.alive))
      .subscribe((navState) => {
        this.navOpen = navState;
      });
  }

  toggleNav(event: MouseEvent): void {
    if (this.hasTouch) {
      // Only toggle nav onclick on touch devices
      this.store.dispatch(ToggleMainNav());

      this.closePracticeContactDetails();
      this.closeUserMenu();
    }
  }

  openNav(event: MouseEvent): void {
    if (!this.hasTouch && !this.navClosing && !this.navOpen) {
      // Only open nav on mouseover on non-touch devices
      this.store.dispatch(OpenMainNav());
      this.scrollToTop();
    }
  }

  closeNav(): void {
    this.store.dispatch(CloseMainNav());
    this.navClosing = true;

    this.closePracticeContactDetails();
    this.closeUserMenu();

    setTimeout(() => {
      this.navClosing = false;
    }, 250);
  }

  scrollToTop(): void {
    if (this.navContainer) {
      this.navContainer.nativeElement.scrollTo({ top: 0, behavior: "smooth" });
    }
  }

  navigate(location: string, $event: MouseEvent): void {
    if (this.navOpen) {
      // Only navigate if the nav is open
      $event.stopPropagation();
      this.router.navigateByUrl(location);
      this.closeNav();
    }
  }

  subscribeToCurrentUser(): void {
    this.user$ = this.store
      .pipe(select(getUser))
      .pipe(takeWhile(() => this.alive));

    this.user$.subscribe((user) => {
      if (user) {
        this.user = user;
      }
    });
  }

  subscribeToCurrentPractice(): void {
    this.practice$ = this.store
      .pipe(select(getCurrentPractice))
      .pipe(takeWhile(() => this.alive));

    this.practiceSub = this.practice$.subscribe((practice) => {
      if (practice) {
        this.practice = practice;
        if(practice){
          this.theme = practice.theme ? practice.theme : Theme.DigitalPractice;
        }
        
        this.getSettingsLink();
        this.updateShowProductRequestsLink();
        this.updateShowFormsLink();
        this.fetchPracticeTemplates();
        this.updateMessagingEnable();
        this.updateEnabled();
      }
    });
  }

  subscribeToAvailablePractices(): void {
    this.practices$ = this.store
      .pipe(select(getAvailablePractices))
      .pipe(takeWhile(() => this.alive));
  }

  updateMessagingEnable(): void {
    this.messagingEnabled = isMessagingEnabled(this.practice);
  }

  handleAboutClick(): void {
    this.toggleAbout();
    this.closeNav();
  }

  toggleAbout(): void {
    this.aboutOpen = !this.aboutOpen;
  }

  handleClickOutside(event: Element): void {
    if (
      this.navOpen &&
      !event.matches(".mobile-header .nav") &&
      !event.closest(".mobile-header .nav.button")
    ) {
      this.closeNav();
    }
  }

  handleGoToHelpCenter(): void {
    setTimeout(() => {
      this.store.dispatch(GetUnreadHelpPosts());
    }, 5000);
  }

  openPracticeContactDetails(event: any): void {
    event.stopPropagation();
    this.practiceContactDetailsOpen = true;
    this.scrollToTop();
  }

  closePracticeContactDetails(): void {
    this.practiceContactDetailsOpen = false;
  }

  openUserMenu(event: any): void {
    event.stopPropagation();
    this.userMenuOpen = true;
  }

  closeUserMenu(): void {
    this.userMenuOpen = false;
  }

  logout(): void {
    if (this.user) {
      this.authService.logout();
    }
  }

  handleNavBackClick($event: MouseEvent): void {
    $event.stopPropagation();
    this.closePracticeContactDetails();
    this.closeUserMenu();
  }

  navigateToConversations($event: MouseEvent): void {
    if (this.navOpen) {
      // Only navigate if the nav is open
      $event.stopPropagation();
      this.closeNav();
      this.store.dispatch(
        UpdateConversationsFilters({
          filters: defaultConversationFilters,
        }),
      );
    }
  }

  navigateToPayments($event: MouseEvent): void {
    if (this.navOpen) {
      // Only navigate if the nav is open
      $event.stopPropagation();
      this.closeNav();

      let types: PaymentType[] = [];
      if (this.practice && isMessagingEnabled(this.practice)) {
        types = [PaymentType.STANDARD];
      }

      this.store.dispatch(
        UpdatePaymentFilters({
          filters: {
            ...defaultPaymentFilters,
            type: types,
          },
        }),
      );
    }
  }

  navigateToMedia($event: MouseEvent): void {
    if (this.activeRoute === "/media") {
      this.store.dispatch(ChangeMediaFolder({ folder: null, direction: "up" }));
      this.store.dispatch(SetMediaPage({ page: 1 }));
      this.store.dispatch(
        SetMediaFilters({
          filters: {
            types: [],
          },
        }),
      );
      this.store.dispatch(GetMediaFolders());
      this.store.dispatch(GetMedia());
    } else {
      this.navigate("/media", $event);
    }
  }

  updateShowProductRequestsLink(): void {
    this.showProductRequests = false;
    if (
      this.practice &&
      practiceHasFeature(this.practice, PracticeFeature.PRODUCT_REQUESTS)
    ) {
      this.showProductRequests = true;
    }
  }

  updateShowFormsLink(): void {
    this.showForms = false;
    this.showFormSubmissions = false;

    if (
      this.practice &&
      practiceHasFeature(this.practice, PracticeFeature.FORMS)
    ) {
      this.showForms = true;
      this.showFormSubmissions = true;
    }
  }

  fetchPracticeTemplates(): void {
    this.store.dispatch(
      GetTemplates({ syncWith360: false, includeArchived: true }),
    );
  }

  updateEnabled(): void {
    this.paymentListToggle = practiceHasFeature(this.practice, PracticeFeature.PAYMENT_LIST_VIEW);
  }
}
