<ng-container *ngIf="productRequest">
  <div class="dispense-product-request">
    <div class="checkbox-row">
      <p-checkbox [(ngModel)]="notifyClient" [binary]="true"></p-checkbox>
      <div class="label">{{'product_requests.dispense.notify' | translate}}</div>
    </div>
    <div class="button-row">
      <p-button (click)="submit()" [label]="'product_requests.dispense.submit' | translate" styleClass="p-button-primary"></p-button>
    </div>
  </div>
</ng-container>
