import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FullPageComponent } from './full-page/full-page.component';
import { FullPageSidebarComponent } from './full-page-sidebar/full-page-sidebar.component';
import { AppRoutingModule } from '../app-routing.module';
import { ToastModule } from 'primeng/toast';
import { UiModule } from '../ui/ui.module';
import {UtilsModule} from "../utils/utils.module";
import { ConversationModule } from '../conversation/conversation.module';
import {LetDirective, PushPipe} from "@ngrx/component";



@NgModule({
  declarations: [FullPageComponent, FullPageSidebarComponent],
  imports: [
    CommonModule,
    AppRoutingModule,
    UtilsModule,
    ToastModule,
    PushPipe,
    LetDirective,
    UiModule,
    ConversationModule
  ]
})
export class LayoutsModule { }
