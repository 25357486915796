<div class="btn-wrap">
  <ng-container *ngIf="unsyncedPayments">
    <div class="pms-sync-warning" tooltipPosition="top" tooltipStyleClass="tooltip-error" [pTooltip]="'ui.payment_history.count_unsynced_payments' | translate: {count: unsyncedPayments}">
      <i class="pi pi-exclamation-triangle"></i>
    </div>
  </ng-container>
  <ng-container *ngIf="paymentLinkToggle">
    <button pButton [label]="'ui.payment_history.payment_links' | translate" class="p-button-fourth p-button-sm" [disabled]="!client || !client.payments || client.payments.length === 0 || !enabled" (click)="showModal()"></button>
  </ng-container>
</div>
<ng-container *ngIf="client && practice">
  <p-dialog
    [header]="'ui.payment_history.payment_link_history' | translate"
    [(visible)]="modalActive"
    [modal]="true"
    [style]="{width: practice.sites && practice.sites.length > 1 ? '700px' : '600px', 'min-height': '200px'}"
    [baseZIndex]="10000"
    [draggable]="false"
    [resizable]="false"
  >
    <div class="wrapper">
      <div class="top-section">
        <div class="row title">
          <div class="cell">{{'ui.payment_history.amount' | translate}}</div>
          <div class="cell">{{'ui.payment_history.sent' | translate}}</div>
          <div class="cell">{{'ui.payment_history.status' | translate}}</div>
          <div class="cell">{{'ui.payment_history.date' | translate}}</div>
          <ng-container *ngIf="reconcilePaymentsAgainstInvoice">
            <div class="cell">Invoice Number</div>
          </ng-container>
          <ng-container *ngIf="practice.sites && practice.sites.length > 1">
            <div class="cell">{{'ui.payment_history.site' | translate}}</div>
          </ng-container>
        </div>
        <ng-container *ngFor="let payment of client.payments">
          <div class="row">
            <div class="cell">{{ payment.amount | penceToPounds : practiceCurrency?.currencySymbol }}</div>
            <div class="cell">{{ payment.createdAt | dfnsFormat:'dd/MM/yy' }}</div>

            <div class="cell">
              {{ (payment | getPaymentStatus).labelKey | translate }}
              <ng-container *ngIf="paymentSyncEnabled && (payment | getPaymentStatus).id === paymentStatus.PAID">
                <div class="pms-sync-warning-modal" tooltipPosition="top" tooltipStyleClass="tooltip-error" [pTooltip]="'ui.payment_history.unsynced_payment' | translate">
                  <i class="pi pi-exclamation-triangle"></i>
                </div>
              </ng-container>
            </div>

            <div class="cell">{{ payment | getPaymentStatusChangeDate | dfnsFormat:'dd/MM/yy' }}</div>
            <ng-container *ngIf="reconcilePaymentsAgainstInvoice">
              <div class="cell">{{ payment.invoiceNumber ? payment.invoiceNumber : 'N/A' }}</div>
            </ng-container>
            <ng-container *ngIf="practice.sites && practice.sites.length > 1">
              <div class="cell site">{{ payment.site ? payment.site.name : ('ui.payment_history.n_a' | translate) }}</div>
            </ng-container>
          </div>
        </ng-container>
      </div>
      <div class="bottom-btn-row">
        <p-button [label]="'ui.payment_history.view_all' | translate: {name: client.fullName}" (click)="goToAllPayments()"></p-button>
      </div>
    </div>
  </p-dialog>
</ng-container>
