import { Pipe, PipeTransform } from '@angular/core';
import {ProductRequestItem} from '../models/ProductRequestItem';
import {itemCanBeDispensed} from '../helpers/item-can-be-dispensed';
import {ProductRequest} from '../models/ProductRequest';

@Pipe({name: 'itemCanBeDispensed'})
export class ItemCanBeDispensedPipe implements PipeTransform {
  transform(productRequest: ProductRequest, item: ProductRequestItem): boolean {
    return itemCanBeDispensed(productRequest, item);
  }
}
