import { DEFAULT_CURRENCY_CODE, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

import { DateFnsModule } from 'ngx-date-fns';
import { CookieService } from 'ngx-cookie-service';
import { SocketIoModule } from 'ngx-socket-io';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { environment } from '../environments/environment';

import { LayoutsModule } from './layouts/layouts.module';
import { UtilsModule } from './utils/utils.module';

import { reducers } from './state/reducers';
import { AuthEffects } from './auth/state/effects';
import { ConversationEffects } from './conversation/state/effects';
import { MessagesEffects } from './messages/state/effects';
import { PracticeEffects } from './practices/state/effects';
import { PaymentsEffects } from './payments/state/effects';
import { UserEffects } from './users/state/effects';
import { ConversationsEffects } from './waiting-room/state/effects';
import { ClientEffects } from './clients/state/effects';

import { AttachTokenInterceptor } from './interceptors/attach-token.interceptor';
import { SystemEffects } from './state/effects';
import { WindowRef } from './services/window.service';
import { init } from '@sentry/angular-ivy';
import { createErrorHandler } from '@sentry/angular-ivy';
import { CurrencyPipe } from '@angular/common';
import { NotificationEffects } from './notifications/state/effects';
import { NotificationsModule } from './notifications/notifications.module';
import { UiModule } from './ui/ui.module';
import { JoyrideModule } from 'ngx-joyride';
import { PendingChangesGuard } from './guards/pending-changes.guard';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { PatientEffects } from './patients/state/effects';
import { CampaignEffects } from './campaigns/state/effects';
import {SearchEffects} from './search/state/effects';
import {WhatsappTemplatesEffects} from './templates/state/effects';
import { DialogsModule } from './dialogs/dialogs.module';
import {MediaEffects} from './media/state/effects';
import {LetDirective , PushPipe} from '@ngrx/component';
import { ProductRequestEffects } from './product-requests/state/effects';
import {DashboardEffects} from './dashboard/state/effects';
import {FormsEffects} from './forms/state/effects';
import { ViewersEffects } from './viewers/state/effects';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

init({
  dsn: 'https://b11e35ce228f45d0bff37be44f6d22f4@o460384.ingest.sentry.io/5780780' ,
  environment: environment.name,
  integrations: [],
  tracesSampleRate: 0.0,
});

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastModule,
    StoreModule.forRoot(reducers, {}),
    EffectsModule.forRoot([
      AuthEffects,
      CampaignEffects,
      ClientEffects,
      ConversationEffects,
      ConversationsEffects,
      DashboardEffects,
      FormsEffects,
      MediaEffects,
      MessagesEffects,
      NotificationEffects,
      PracticeEffects,
      PaymentsEffects,
      PatientEffects,
      ProductRequestEffects,
      SystemEffects,
      SearchEffects,
      UserEffects,
      WhatsappTemplatesEffects,
      ViewersEffects,
    ]),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NotificationsModule,
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    LayoutsModule,
    UtilsModule,
    DateFnsModule.forRoot(),
    SocketIoModule,
    JoyrideModule.forRoot(),
    PushPipe,
    LetDirective ,
    UiModule,
    DialogsModule,
    NgxGoogleAnalyticsModule.forRoot(environment.gtag_code)
  ],
  providers: [
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: AttachTokenInterceptor, multi: true },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'GBP' },
    MessageService,
    WindowRef,
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false,
      }),
    },
    CurrencyPipe,
    {
      provide: Window,
      useValue: window
    },
    PendingChangesGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private translateService: TranslateService) {
    const defaultLanguage = localStorage.getItem('isoCode') || 'en';
    translateService.setDefaultLang(defaultLanguage);
    translateService.use(defaultLanguage);
    this.setLanguage(defaultLanguage);
  }
  setLanguage(lang: string) {
    document.documentElement.setAttribute('lang', lang); // Updates <html lang="xx">
  }
}
