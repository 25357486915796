<ng-container *ngIf="productRequest">
  <div class="dispense-product-request">
    <div class="checkbox-row">
      <p-checkbox [(ngModel)]="notifyClient" [binary]="true"></p-checkbox>
      <div class="label">Notify client that the requested products are ready for collection</div>
    </div>
    <div class="button-row">
      <p-button (click)="submit()" label="Submit" styleClass="p-button-primary"></p-button>
    </div>
  </div>
</ng-container>
