import { PaymentType } from "../enums/payment-type";
import { Payment } from "../models/Payment";
import { hasPaymentAuthorisationExpired } from "./has-payment-authorisation-expired";

export function canPaymentBeResent(payment: Payment): boolean {
    if (payment.type === PaymentType.AUTOMATIC || payment.type === PaymentType.ONLINE || payment.type === PaymentType.FORM) {
        return false;
    }

    if (payment.resentAt) {
        return false;
    }

    if (payment.queued) {
        return false;
    }

    if (payment.refundOfId) {
        return false;
    }

    if (
        payment.syncedToPmsAt ||
        (!payment.paidAt && !payment.authorisedAt) ||
        hasPaymentAuthorisationExpired(payment)
    ) {
        return true;
    }

    return false;
}
