<div class="search" (clickOutside)="searchFocused = false">
  <div class="search-input">
    <img [src]="'searchIcon' | themeIconUrl: theme" class="search-icon" (click)="doSearch(currentSearchType)">
    <input pInputText #searchInput (keyup)="$event.key == 'Enter' ? doSearch(currentSearchType) : void()" (focus)="searchFocused = true" type="text" [(ngModel)]="searchString" [placeholder]="'ui.search_bar.search_digital_practice' | translate" class="p-inputtext-search" [style.width]="inputWidth">
    <ng-container *ngIf="searchString.length > 0">
      <img src="assets/images/icons/cross.svg" class="cross-icon" (click)="clearSearch()">
    </ng-container>
  </div>
  <ng-container *ngIf="searchString.length > 0 && searchFocused">
    <div class="search-helper">
      <div class="search-helper-heading" [innerHTML]="'ui.search_bar.search' | translate: {text: searchString}">
      </div>
      <div class="search-helper-buttons">
        <ng-container *ngIf="messagingEnabled">
          <div class="search-helper-button" (click)="doSearch(searchType.CONVERSATIONS)">{{'ui.search_bar.conversations' | translate}}</div>
        </ng-container>
        <div class="search-helper-button" (click)="doSearch(searchType.CLIENTS)">{{'ui.search_bar.clients' | translate}}</div>
        <ng-container *ngIf="paymentsEnabled && paymentSearchToggle">
          <div class="search-helper-button" (click)="doSearch(searchType.PAYMENTS)">{{'ui.search_bar.payments' | translate}}</div>
        </ng-container>
        <ng-container *ngIf="productRequestsEnabled && messagingEnabled">
          <div class="search-helper-button" (click)="doSearch(searchType.PRODUCT_REQUESTS)">{{'ui.search_bar.product_requests' | translate}}</div>
        </ng-container>
        <ng-container *ngIf="formsEnabled && messagingEnabled">
          <div class="search-helper-button" (click)="doSearch(searchType.FORM_SUBMISSIONS)">{{'ui.search_bar.form_submissions' | translate}}</div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
