import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'icon-vertical-split',
  templateUrl: './vertical-split.component.html',
  styleUrls: ['./vertical-split.component.scss']
})
export class VerticalSplitComponent implements OnInit {
  @Input() color: string = 'var(--surface-0)';

  constructor() { }

  ngOnInit(): void {
  }

}
