<div class="wrap">
    <p-dialog
        header="An Error Occurred"
        [visible]="true"
        [modal]="true"
        [style]="{width: '400px'}"
        [baseZIndex]="10000"
        [draggable]="false"
        [resizable]="false"
        styleClass="no-practice-dialog"
    >
        <div class="message"><strong>Could not connect to Digital Practice</strong><br>Please check your internet connection and try again</div>
        <div class="btn-row"><button pButton label="Reload page" class="pm-button-primary" (click)="refresh()"></button></div>
    </p-dialog>
</div>
