import { createFeatureSelector, createSelector } from '@ngrx/store';
import { STATE_KEY } from 'src/app/state/reducers';
import { ClientState } from './reducer';

export const getClientState = createFeatureSelector<ClientState>(STATE_KEY.CLIENTS);

export const getClients = createSelector(
    getClientState,
    (state: ClientState) => state.clients,
);

export const isClientSearchLoading = createSelector(
    getClientState,
    (state: ClientState) => state.clientSearchLoading,
);

export const hasClientSearchError = createSelector(
    getClientState,
    (state: ClientState) => state.clientSearchError,
);

export const getFilterClients = createSelector(
    getClientState,
    (state: ClientState) => state.filterClients,
);

export const getClientProductRequestHistory = createSelector(
  getClientState,
  (state: ClientState) => state.clientProductRequests,
);

export const getInsightsClient = createSelector(
  getClientState,
  (state: ClientState) => state.insightsClient,
);

export const isInsightPMSClientNotExist = createSelector(
  getClientState,
  (state: ClientState) => state.isInsightPMSClientNotExist,
);

export const getInsightsClientLoading = createSelector(
  getClientState,
  (state: ClientState) => state.insightsClientLoading,
);


export const getInsightPMSClient = createSelector(
  getClientState,
  (state: ClientState) => state.insightClient,
);

export const getClientListClients = createSelector(
  getClientState,
  (state: ClientState) => state.clientListView.clients,
);

export const getClientsListLoading = createSelector(
  getClientState,
  (state: ClientState) => state.clientListView.loading,
);

export const getClientListTotalClients = createSelector(
  getClientState,
  (state: ClientState) => state.clientListView.totalItems,
);

export const getNewClientDepositOpen = createSelector(
  getClientState,
  (state: ClientState) => state.newClientDepositOpen,
);

export const getNewClientDepositAlreadyExists = createSelector(
  getClientState,
  (state: ClientState) => state.newClientDepositAlreadyExists,
);
