import { Pipe, PipeTransform } from '@angular/core';
import {Conversation} from '../models/Conversation';

@Pipe({name: 'getConversationLatestMessagePipe'})
export class GetConversationLatestMessagePipe implements PipeTransform {
    transform(conversation: Conversation): string {
      if (!conversation.latestMessage) {
        return '(No messages)';
      }

      let str = '';

      if (conversation.latestMessage.content.length > 0) {
        str = conversation.latestMessage.content;
      }

      conversation.latestMessage.attachments?.forEach((attachment) => {
        if (attachment.mime.includes('image')) {
          str += '(Image attached)';
          return;
        }

        if (attachment.mime.includes('video')) {
          str += '(Video attached)';
          return;
        }

        if (attachment.mime.includes('audio')) {
          str += '(Audio attached)';
          return;
        }

        if (attachment.mime.includes('pdf')) {
          str += '(PDF attached)';
          return;
        }

        str += '(File attached)';
      });

      return str;
    }
}
