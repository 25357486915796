<ng-container *ngIf="watchers">
    <div class="watchers-wrap" (clickOutside)="handleClickOutside()">
        <div class="watchers" [class.highlight]="authUserIsWatching()" (click)="openFlyout()">
            <i class="pi pi-eye"></i> {{ watchers.length }}
        </div>
        <div class="flyout" [class.active]="flyoutOpen" (click)="handleFlyoutClick($event)">
            <div class="wrap">
                <watcher-context-menu [watchers]="watchers" [authUser]="authUser$ | async" [allUsers]="(filteredUsers$ | async) ?? []" (watch)="handleWatch($event)" (unwatch)="handleUnwatch($event)"></watcher-context-menu>
            </div>
        </div>
    </div>
</ng-container>
