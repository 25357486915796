  <div class="template-selector">
    <div class="template-filter">
      <div class="search-input">
        <img [src]="'searchIcon' | themeIconUrl: theme" class="search-icon">
        <input pInputText (keyup)="setFilteredTemplates()" type="text" [(ngModel)]="templateFilterValue"
          placeholder="Search..." class="p-inputtext-search">
        <ng-container *ngIf="templateFilterValue.length > 0">
          <img src="assets/images/icons/cross.svg" class="cross-icon" (click)="clearFilter()">
        </ng-container>
      </div>
      <div class="filter-label">
        <span>Type:</span>
        <select class="primary" [(ngModel)]="selectedTemplateType" (ngModelChange)="applyTemplateTypeFilter()">
          <option [value]="['Standard', 'Campaign']">All</option>
          <option *ngFor="let templateType of templateTypes" [value]="templateType">{{ templateType |
            prettifyTemplateType }}</option>
        </select>
      </div>
      <div class="filter-label" [ngStyle]="{
          'visibility': (includeMediaFilter) ? 'visible' : 'hidden'
        }">
        <span>Media:</span>
        <select class="primary" [(ngModel)]="selectedMediaType" (ngModelChange)="applyMediaTypeFilter()">
          <option [value]="['Image', 'Video', 'PDF', 'undefined']">All</option>
          <option *ngFor="let mediaType of mediaTypes" [value]="mediaType">{{ mediaType }}</option>
        </select>
      </div>
    </div>
    <ng-container *ngFor="let template of filteredTemplates">
      <div class="template">
        <div class="template-content">
          <div class="template-title">
            <p>{{ template.name }} {{" "}}<span class="template-type" [class]="getTemplateTypeClass(template.type)">
                ({{ template.type | prettifyTemplateType }})
              </span>
            </p>
          </div>
          <div class="template-text" [innerText]="template.body"></div>
        </div>
        <div class="col">
          <div class="select-row">
            <i class="pi pi-image" [ngClass]="{ 'opacity-100': template.mediaType === 'Image' }"></i>
            <i class="pi pi-video" [ngClass]="{ 'opacity-100': template.mediaType === 'Video' }"></i>
            <i class="pi pi-file-pdf" [ngClass]="{ 'opacity-100': template.mediaType === 'PDF' }"></i>
            <button pButton label="Select" class="p-button-success p-button-sm"
              (click)="selectTemplate(template)"></button>
          </div>
          <ng-container *ngIf="template.status === approved">
            <a [href]="consentLink" target="_blank">
              <div class="consent" >
                <span>Consent not required</span>
                <i class="pi pi-question-circle"></i>
              </div>
            </a>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="filteredTemplates.length === 0">
      <div class="no-template">
        <span>Unfortunately no templates could be found with those filters, please try another or reset</span>
        <button pButton label="Reset" class="p-button-fourth p-button-sm" (click)="clearFilter()"></button>
      </div>
    </ng-container>
  </div>
  <div class="bottom-row">
    <div class="left"></div>
    <div class="center">
      <a [href]="helpLink + '/category/faq/post/how-do-i-add-client-message-templates'" target="_blank"><img [src]="'questionMarkIcon' | themeIconUrl: theme" height="20" class="help-icon" alt="" > Need help?</a>
    </div>
    <div class="right">
    </div>
  </div>
