import { ProductRequestApprovalStatus } from "../enums/product-request-approval-status";

export function prettifyProductRequestApprovalStatus(status: ProductRequestApprovalStatus): string {
  switch (status) {
    case ProductRequestApprovalStatus.APPROVED:
      return 'helpers.prettify_product_request_apporval_status.approved';
    case ProductRequestApprovalStatus.REJECTED:
      return 'helpers.prettify_product_request_apporval_status.rejected';
    case ProductRequestApprovalStatus.PENDING:
      return 'helpers.prettify_product_request_apporval_status.pending';
    case ProductRequestApprovalStatus.NOT_REQUIRED:
      return 'helpers.prettify_product_request_apporval_status.not_required';
  }
}
