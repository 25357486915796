<div class="filter-item" [class.active]="open" [class.highlight]="selectedValues.length > 0" (clickOutside)="close()">
  <div class="toggle" (click)="toggle()">
    <ng-container *ngIf="selectedValues.length === 0">
      <i [class]="icon"></i>
    </ng-container>
    <ng-container *ngIf="selectedValues.length > 0">
      <i class="pi pi-check"></i>
    </ng-container>
    <span class="label">{{ selectedValues | getFilterLabel:(label ?? ('ui.filter_item_multiple.label' | translate)) }}</span> <dropdown-icon></dropdown-icon>
  </div>
  <div class="dropdown mobile-modal">
    <ng-container *ngFor="let option of options">
      <div class="dropdown-item">
        <label [for]="option.id" class="label">{{ option.label }}</label>
        <p-checkbox name="status" [ngModel]="selectedValues" (onChange)="update(option)" [value]="option" [inputId]="option.id"></p-checkbox>
      </div>
    </ng-container>
  </div>
</div>
