import { Component, Input, OnInit } from '@angular/core';
import { Notification } from 'src/app/models/Notification';
import { NotificationAttachmentType } from 'src/app/enums/notification-attachment-type';
import { AppState } from 'src/app/state/reducers';
import { Store } from '@ngrx/store';
import { HideNotification, MarkNotificationAsRead } from '../../state/actions';
import { Practice } from 'src/app/models/Practice';
import { Router } from '@angular/router';

@Component({
  selector: 'notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  @Input() notification?: Notification;
  @Input() practice: Practice | null = null;
  attachmentTypes = NotificationAttachmentType;
  dismissTimeout?: any;

  constructor(private store: Store<AppState>, private router: Router) {
  }

  ngOnInit(): void {
    this.startDismissTimer();
  }

  handleMouseOver(): void {
    if (this.dismissTimeout) {
      clearTimeout(this.dismissTimeout);
    }
  }

  handleMouseOut(): void {
    this.startDismissTimer();
  }

  startDismissTimer(): void {
    this.dismissTimeout = setTimeout(() => {
      this.dismiss();
    }, 10000);
  }

  navigateAndDismiss(): void {
    this.dismiss();
    if (this.notification) {
      this.store.dispatch(MarkNotificationAsRead({notification: this.notification}));
      this.router.navigateByUrl(this.notification.link);
    }
  }

  dismiss(): void {
    if (this.notification) {
      this.store.dispatch(HideNotification({notification: this.notification}));
    }
  }
}
