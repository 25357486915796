import { Injectable } from '@angular/core';
import {catchError, map, mergeMap, reduce} from 'rxjs/operators';
import {combineLatest, merge, Observable, of} from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  accessToken: string | null = null;
  jwt: string | null = null;

  constructor(
    private authService: AuthService,
    private cookieService: CookieService,
    private environmentService: EnvironmentService
  ) {
  }

  retrieveAccessToken(): string {
    return this.cookieService.get('access_token');
  }

  retrieveJwt(): string {
    return this.cookieService.get(this.environmentService.get('jwtPrefix') + 'JWT');
  }

  retrieveCoreJwt(): string {
    return this.cookieService.get(this.environmentService.get('jwtPrefix') + 'CORE_JWT');
  }

  checkTokens(): Observable<boolean> {
    if (this.retrieveJwt().length > 0 && this.retrieveCoreJwt().length > 0) {
      return of(true);
    }

    if (this.retrieveAccessToken().length > 0) {
      return this.newJwts();
    }

    return this.refreshToken();
  }

  private newJwts(): Observable<boolean> {
    const jwtOb: Observable<boolean> = this.authService.getJwt().pipe(
      map(() => {
        return true;
      }),
      catchError(() => {
        return of(false);
      })
    );

    const coreJwtOb: Observable<boolean> = this.authService.getCoreJwt().pipe(
      map(() => {
        return true;
      }),
      catchError(() => {
        return of(false);
      })
    );

    return combineLatest([jwtOb, coreJwtOb]).pipe(
      map(([a, b]) => a && b)
    );
  }

  private refreshToken(): Observable<boolean> {
    return this.authService.refreshToken().pipe(
      mergeMap(() => this.newJwts()),
      catchError(() => {
        return of(false);
      })
    );
  }
}
