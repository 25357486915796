import { Injectable } from '@angular/core';
import {FullPayment} from '../models/FullPayment';
import {FullPaymentDto} from '../interfaces/dto/full-payment.dto';
import { parse } from "date-fns";

@Injectable({
  providedIn: 'root'
})
export class FullPaymentAdapter {
  constructor() {
  }

  run(dto: FullPaymentDto): FullPayment {
    return {
      id: dto.id,
      practiceId: dto.practice_id,
      statusId: dto.status_id,
      total: dto.total,
      callbackUrl: dto.callback_url,
      currencyIso: dto.currency_iso,
      sourceId: dto.source_id,
      paidAt: dto.paid_at ? parse(dto.paid_at, 'yyyy-MM-dd HH:mm:ss', new Date()) : null,
      expiresAt: dto.expires_at ? parse(dto.expires_at, 'yyyy-MM-dd HH:mm:ss', new Date()) : null,
      cancelledAt: dto.cancelled_at  ? parse(dto.cancelled_at, 'yyyy-MM-dd HH:mm:ss', new Date()) : null,
      createdAt: new Date(dto.created_at),
      updatedAt: new Date(dto.updated_at),
    };
  }
}
