import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormSubmission} from '../../interfaces/form-submission';
import {select, Store} from '@ngrx/store';
import {getCurrentPractice} from '../../../practices/state/selectors';
import {takeWhile} from 'rxjs/operators';
import {getFormSubmissions, getFormSubmissionsLoading, getFormSubmissionsTotalCount} from '../../state/selectors';
import {GetFormSubmissions} from '../../state/actions';
import {Observable} from 'rxjs';
import {Practice} from '../../../models/Practice';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'form-submission-list',
  templateUrl: './form-submission-list.component.html',
  styleUrls: ['./form-submission-list.component.scss']
})
export class FormSubmissionListComponent implements OnInit, OnDestroy{
  searchString: string | null = '';
  alive = true;
  loading = true;
  practice$?: Observable<Practice | null>;
  practice?: Practice;
  formSubmissions: FormSubmission[] = [];
  totalItems = 0;
  page = 1;
  perPage = 10;

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.subscribeToRouteParams();
    this.subscribeToCurrentPractice();
    this.subscribeToFormSubmissions();
    this.subscribeToFormSubmissionsLoading();
  }
  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  subscribeToCurrentPractice(): void {
    this.practice$ = this.store.pipe(select(getCurrentPractice)).pipe(
      takeWhile(() => this.alive)
    );

    this.practice$.subscribe((practice) => {
      if (practice) {
        this.practice = practice;
        this.getSubs();
      }
    });
  }

  subscribeToFormSubmissions(): void {
    this.store.pipe(select(getFormSubmissions)).pipe(
      takeWhile(() => this.alive)
    ).subscribe((submissions) => {
      this.formSubmissions = submissions;
    });

    this.store.pipe(select(getFormSubmissionsTotalCount)).pipe(
      takeWhile(() => this.alive)
    ).subscribe((count) => {
      this.totalItems = count;
    });
  }

  subscribeToFormSubmissionsLoading(): void {
    this.store.pipe(select(getFormSubmissionsLoading)).pipe(
      takeWhile(() => this.alive)
    ).subscribe((loading) => {
      this.loading = loading;
    });
  }

  subscribeToRouteParams(): void {
    this.route.queryParams
      .subscribe((params => {
        if (params.s) {
          if (this.searchString !== params.s) {
            this.searchString = params.s;
            this.getSubs();
          }
        }
      }));
  }

  showPreviousPageLink(): boolean {
    return this.page > 1;
  }

  showNextPageLink(): boolean {
    if (this.totalItems) {
      return this.totalItems > this.page * this.perPage;
    }

    return false;
  }

  previousPage(): void {
    this.page--;
    this.getSubs();
  }

  nextPage(): void {
    this.page++;
    this.getSubs();
  }

  getSubs(): void {
    if (this.practice) {
      this.store.dispatch(GetFormSubmissions({page: this.page, perPage: this.perPage, searchString: this.searchString}));
    }
  }
}
