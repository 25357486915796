import {AfterContentInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Store} from '@ngrx/store';
import {AppState} from '../../../state/reducers';
import {RenameMedia, RenameMediaFolder} from '../../state/actions';

@Component({
  selector: 'app-rename-media',
  templateUrl: './rename-media.component.html',
  styleUrls: ['./rename-media.component.scss']
})
export class RenameMediaComponent implements OnInit, AfterContentInit {
  @ViewChild('nameInput') nameInput?: ElementRef;
  form = new FormGroup({
    name: new FormControl('', Validators.required),
  });

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private store: Store<AppState>,
  ) { }

  ngOnInit(): void {
    this.form.controls.name.setValue(this.config.data.media.name);
  }

  ngAfterContentInit(): void {
    setTimeout(() => {
      if (this.nameInput) {
        this.nameInput.nativeElement.focus();
      }
    }, 50);
  }

  submit(): void {
    Object.keys(this.form.controls).forEach(field => {
      const control = this.form.get(field);
      control?.markAsTouched({ onlySelf: true });
    });

    if (this.config.data.media.id && this.form.valid && this.form.controls.name.value) {
      this.store.dispatch(RenameMedia({
        media: {
          id: this.config.data.media.id,
          name: this.form.controls.name.value,
        }
      }));

      this.ref.close();
    }
  }
}
