import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Patient } from 'src/app/models/Patient';

@Component({
  selector: 'patient-selector',
  templateUrl: './patient-selector.component.html',
  styleUrls: ['./patient-selector.component.scss']
})
export class PatientSelectorComponent implements OnInit {
  @Input() disabled?: boolean;
  @Input() patients?: Patient[];
  @Input() loading = false;
  @Output() patientSelected = new EventEmitter<Patient>();
  @Output() morePatients = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

  handlePatientSelected(patient: Patient): void {
    this.patientSelected.emit(patient);
  }

  showMorePatients(): void {
    this.morePatients.emit();
  }
}
