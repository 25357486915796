import { Pipe, PipeTransform } from '@angular/core';
import {TemplateType} from '../enums/template-type';
import {templateTypeCanHaveMedia} from '../helpers/template-type-can-have-media';

@Pipe({name: 'templateTypeCanHaveMedia'})
export class TemplateTypeCanHaveMediaPipe implements PipeTransform {
  transform(value: TemplateType): boolean {
    return templateTypeCanHaveMedia(value);
  }
}
