import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Contact } from "../../../models/Contact";
import { Practice } from "../../../models/Practice";
import { select, Store } from "@ngrx/store";
import { AppState } from "../../../state/reducers";
import {
  ClearNewConversationError,
  NewConversation,
} from "../../../conversation/state/actions";
import { getOpenConversations } from "../../../waiting-room/state/selectors";
import { takeWhile } from "rxjs/operators";
import { Observable, Subscription } from "rxjs";
import { Conversation } from "../../../models/Conversation";
import { Router } from "@angular/router";
import { Channel } from "src/app/enums/channel";
import { Client } from "src/app/models/Client";
import { NewConversationError } from "src/app/interfaces/new-conversation-error";
import {
  getConversation,
  getNewConversationError,
} from "src/app/conversation/state/selectors";
import { phone } from "phone";
import {
  getCurrentPractice,
  getPracticeTemplates,
} from "src/app/practices/state/selectors";
import { UntypedFormControl, Validators } from "@angular/forms";
import { GetPracticeTemplates } from "src/app/practices/state/actions";
import { practiceHasFeature } from "src/app/helpers/practice-has-feature";
import { PracticeFeature } from "src/app/enums/practice-feature";
import { getNewConversationPrefilledData } from "../../../dialogs/state/selectors";
import {
  getStandardAndCampaignTemplate,
  getStandardTypeTemplates,
  getTemplates,
} from "../../../templates/state/selectors";
import { Template } from "src/app/models/Template";
import { Media } from "../../../models/Media";
import { DialogTemplateSelectorComponent } from "../dialog-template-selector/dialog-template-selector.component";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { EnvironmentService } from "../../../services/environment.service";
import { phoneValidator } from "../../../helpers/phone-validator";
import { TemplateMergeFieldDo } from "../../../interfaces/template-merge-field.do.interface";
import { ConversationService } from "../../services/conversation.service";

interface ChannelOption {
  name: Channel;
  code: Channel;
  className: string;
  icon: string;
  inactive: boolean;
}

@Component({
  selector: "new-conversation",
  templateUrl: "./new-conversation.component.html",
  styleUrls: ["./new-conversation.component.scss"],
  providers: [DialogService],
})
export class NewConversationComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild("initialMessageInput")
  initialMessageInput?: ElementRef<HTMLInputElement>;
  @Input() practice?: Practice;
  @Output() conversationCreated = new EventEmitter();
  @Output() closed = new EventEmitter();
  step = 0;
  alive = true;
  loading = false;
  channels: ChannelOption[];
  selectedChannel: ChannelOption;
  selectedClient?: Client;
  selectedContact?: Contact;
  conversations$?: Observable<Conversation[]>;
  conversationsSub$?: Subscription;
  conversations?: Conversation[];
  error$?: Observable<NewConversationError | null>;
  error: NewConversationError | null = null;
  phoneNumber = "";
  phoneNumberIsValid = false;
  messagePhoneDirectly = false;
  initialMessage = new UntypedFormControl("");
  initialMessageRequired = false;
  messagingChannel = Channel;
  initialMessageTooLong = false;
  maxInitialMessageLength = 1450;
  remainingInitialMessageLength = this.maxInitialMessageLength;
  showTemplateDialog = false;
  templates$?: Observable<Template[]>;
  templates: Template[] = [];
  facebookLinked = false;
  instagramLinked = false;
  channel = Channel;
  minStep = 0;
  usingTemplate = false;
  selectedTemplate: Template | null = null;
  selectedTemplateMergeFields: TemplateMergeFieldDo[] | null = null;
  selectedTemplateContent: string | null = null;
  showInitialMessageError = false;
  resizeTimeout: any;
  device = "desktop";
  previewOpen = false;
  messagePreviewText: string | null = null;
  selectedMedia: Media | undefined = undefined;
  previewType: string | null = null;
  selectTemplateDialog?: DynamicDialogRef;
  practiceChannel: Channel = Channel.WHATSAPP;
  helpLink = "";
  buttonLink?: string;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    public dialogService: DialogService,
    private environmentService: EnvironmentService,
    private conversationService: ConversationService,
  ) {
    this.channels = [
      {
        name: Channel.WHATSAPP,
        code: Channel.WHATSAPP,
        className: Channel.WHATSAPP.toLowerCase(),
        icon: "WhatsApp.svg",
        inactive: false,
      },
      {
        name: Channel.SMS,
        code: Channel.SMS,
        className: Channel.SMS.toLowerCase(),
        icon: "SMS.svg",
        inactive: false,
      },
    ];

    this.selectedChannel = this.channels[0];
  }

  ngOnInit(): void {
    this.subscribeToConversations();
    this.subscribeToPractice();
    this.subscribeToErrors();
    this.subscribeToTemplates();
    this.subscribeToPrefilledData();
    this.store.dispatch(ClearNewConversationError());
    this.getHelpLink();
  }

  ngAfterViewInit(): void {
    this.initialMessage.valueChanges.subscribe((value) => {
      this.conversationService.initialMessage.setValue(
        this.initialMessage.value,
      );
      this.remainingInitialMessageLength =
        this.maxInitialMessageLength - value.length;
      if (this.remainingInitialMessageLength < 0) {
        this.initialMessageTooLong = true;
      } else {
        this.initialMessageTooLong = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.alive = false;
    this.loading = false;
    this.conversationsSub$?.unsubscribe();
  }

  @HostListener("window:resize")
  handleResize(): void {
    clearTimeout(this.resizeTimeout);
    this.resizeTimeout = setTimeout(() => {
      this.device = this.getDevice();
    }, 100);
  }

  getDevice(): string {
    if (window.innerWidth <= 640) {
      return "mobile";
    }

    return "desktop";
  }

  getHelpLink(): void {
    this.helpLink = `${this.environmentService.get(
      "helpUrl",
    )}/learn/section/digital-practice/category/faq/post/how-do-i-add-client-message-templates`;
  }

  togglePreview(): void {
    this.previewOpen = !this.previewOpen;
  }

  goToStep(step: number): void {
    // If attempting to select Facebook as a channel when the selected contact does not have a Facebook token, don't proceed
    if (step === 4) {
      this.stopUsingTemplate();
      this.messagePreviewText = null;
      this.selectedMedia = undefined;
      this.previewType = null;
      this.selectedTemplateContent = "";

      if (
        this.selectedChannel.code === Channel.FACEBOOK &&
        !this.facebookLinked
      ) {
        return;
      }

      if (
        this.selectedChannel.code === Channel.INSTAGRAM &&
        !this.instagramLinked
      ) {
        return;
      }
    }

    this.step = step;

    if (step === 4) {
      setTimeout(() => {
        this.initialMessageInput?.nativeElement.focus();
      }, 0);
    }
  }

  createConversation(): void {
    if (this.practice) {
      if (
        (this.initialMessageRequired && !this.initialMessage.valid) ||
        this.initialMessageTooLong
      ) {
        this.showInitialMessageError = true;
        this.initialMessage.markAsTouched();
        return;
      }
      this.loading = true;
      if (this.selectedClient && this.selectedContact) {
        this.store.dispatch(
          NewConversation({
            practice: this.practice,
            channel: this.selectedChannel.code,
            client: this.selectedClient,
            contact: this.selectedContact,
            payment: null,
            initialMessage: this.initialMessage.value,
            template: this.usingTemplate ? this.selectedTemplate : null,
            mergeFields: this.selectedTemplateMergeFields,
            buttonLink: this.buttonLink,
            selectedMedia: this.selectedMedia,
          }),
        );
      } else if (this.phoneNumber) {
        this.store.dispatch(
          NewConversation({
            practice: this.practice,
            channel: this.selectedChannel.code,
            client: null,
            contact: {
              name: "Phone",
              typeCode: "0",
              value: this.phoneNumber,
              facebookLinked: false,
              instagramLinked: false,
            },
            payment: null,
            initialMessage: this.initialMessage.value,
            template: this.usingTemplate ? this.selectedTemplate : null,
            mergeFields: this.selectedTemplateMergeFields,
            buttonLink: this.buttonLink,
            selectedMedia: this.selectedMedia,
          }),
        );
      }
    }
  }

  onChannelChange(channel: ChannelOption): void {
    this.selectedChannel = channel;

    if (
      this.selectedChannel.code === Channel.WHATSAPP ||
      this.selectedChannel.code === Channel.WHATSAPP360 ||
      this.selectedChannel.code === Channel.WHATSAPP360CLOUD
    ) {
      this.maxInitialMessageLength = 1450;
    }

    if (this.selectedChannel.code === Channel.SMS) {
      this.maxInitialMessageLength = 400;
    }

    this.remainingInitialMessageLength =
      this.maxInitialMessageLength - this.initialMessage.value.length;
  }

  selectClient(client: Client): void {
    this.selectedClient = client;

    if (this.conversations) {
      const conversations = this.conversations.filter((conv) => {
        return (
          conv &&
          conv.client &&
          conv.client.pmsId === this.selectedClient?.pmsId
        );
      });

      if (conversations.length > 0) {
        this.goToStep(3);
        this.step = 3;
      } else {
        this.goToStep(this.step + 1);
      }
    }
  }

  selectContact(contact: Contact): void {
    const phoneNumber = contact.e164 ? contact.e164 : contact.value;

    let result;
    if (this.practice) {
      result = phoneValidator(phoneNumber, this.practice?.country);
    }

    if (result && phone(result).isValid) {
      this.selectedContact = contact;
      if (contact.facebookLinked) {
        this.facebookLinked = true;
      }
      if (contact.instagramLinked) {
        this.instagramLinked = true;
      }
      this.goToStep(this.step + 1);
    } else {
      this.goToStep(5);
    }
  }

  subscribeToErrors(): void {
    this.error$ = this.store
      .pipe(select(getNewConversationError))
      .pipe(takeWhile(() => this.alive));

    this.error$.subscribe((error) => {
      this.error = error;
    });
  }

  subscribeToPrefilledData(): void {
    this.store
      .select(getNewConversationPrefilledData)
      .pipe(takeWhile(() => this.alive))
      .subscribe((prefillData) => {
        if (prefillData.client && prefillData.contact) {
          this.selectClient(prefillData.client);
          this.selectContact(prefillData.contact);
          this.minStep = 2;
        }
      });
  }

  subscribeToConversations(): void {
    this.conversations$ = this.store
      .select(getOpenConversations)
      .pipe(takeWhile(() => this.alive));

    this.conversationsSub$ = this.conversations$.subscribe((conversations) => {
      if (conversations) {
        this.conversations = conversations;
      }
    });
  }

  subscribeToPractice(): void {
    this.store
      .pipe(select(getCurrentPractice))
      .pipe(takeWhile(() => this.alive))
      .subscribe((practice) => {
        if (practice) {
          let channelCode = practice.whatsapp_channel;

          this.channels = [
            {
              name: Channel.WHATSAPP,
              code: channelCode,
              className: Channel.WHATSAPP.toLowerCase(),
              icon: "WhatsApp.svg",
              inactive: false,
            },
            ...this.channels.filter(
              (channelOpt) =>
                channelOpt.code !== Channel.WHATSAPP &&
                channelOpt.code !== Channel.WHATSAPP360 &&
                channelOpt.code !== Channel.WHATSAPP360CLOUD,
            ),
          ];
          this.selectedChannel = this.channels[0];

          if (!practiceHasFeature(practice, PracticeFeature.SMS_MESSAGING)) {
            this.channels = this.channels.filter(
              (channel) => channel.code !== Channel.SMS,
            );
          }

          if (
            practiceHasFeature(practice, PracticeFeature.FACEBOOK_MESSENGER)
          ) {
            this.channels.push({
              name: Channel.FACEBOOK,
              code: Channel.FACEBOOK,
              className: Channel.FACEBOOK.toLowerCase(),
              icon: "facebook-messenger.svg",
              inactive: false,
            });
          } else {
            this.channels = this.channels.filter(
              (channel) => channel.code !== Channel.FACEBOOK,
            );
          }

          if (practiceHasFeature(practice, PracticeFeature.INSTAGRAM)) {
            this.channels.push({
              name: Channel.INSTAGRAM,
              code: Channel.INSTAGRAM,
              className: Channel.INSTAGRAM.toLowerCase(),
              icon: "instagram.svg",
              inactive: false,
            });
          } else {
            this.channels = this.channels.filter(
              (channel) => channel.code !== Channel.INSTAGRAM,
            );
          }

          if (
            practiceHasFeature(practice, PracticeFeature.MESSAGE_DIRECT_NUMBERS)
          ) {
            this.setMessagePhoneDirectly(true);
          } else {
            this.setMessagePhoneDirectly(false);
          }

          if (
            practiceHasFeature(practice, PracticeFeature.FORCE_INITIAL_MESSAGE)
          ) {
            this.setInitialMessageRequired(true);
            this.initialMessage = new UntypedFormControl(
              this.initialMessage.value,
              Validators.required,
            );
          } else {
            this.setInitialMessageRequired(false);
            this.initialMessage = new UntypedFormControl(
              this.initialMessage.value,
            );
          }

          this.store.dispatch(GetPracticeTemplates());
        }
      });
  }

  subscribeToTemplates(): void {
    this.templates$ = this.store
      .pipe(select(getStandardAndCampaignTemplate))
      .pipe(takeWhile(() => this.alive));

    this.templates$.subscribe((templates) => {
      this.templates = templates;
    });
  }

  setInitialMessageRequired(required: boolean): void {
    this.initialMessageRequired = required;
  }

  setMessagePhoneDirectly(enabled: boolean): void {
    this.messagePhoneDirectly = enabled;
  }

  goToClientConversation(): void {
    if (this.error && this.error.existingConversation) {
      this.router.navigateByUrl(
        "conversations/" + this.error.existingConversation.id,
      );
    } else if (this.conversations) {
      const conversation = this.conversations.find((conv) => {
        return (
          conv &&
          conv.client &&
          conv.client.pmsId === this.selectedClient?.pmsId
        );
      });

      if (conversation) {
        this.router.navigateByUrl("conversations/" + conversation.id);
      }
    }

    this.clearError();
    this.closed.emit();
  }

  clearError(): void {
    this.store.dispatch(ClearNewConversationError());
    this.conversationCreated.emit();
  }

  validatePhoneInput(): void {
    let phoneResult = phone(this.phoneNumber);
    if (!phoneResult.isValid) {
      phoneResult = phone(this.phoneNumber, {
        country: this.practice?.country,
      });
    }

    this.phoneNumberIsValid = !!phoneResult.isValid;
  }

  validateKeyPress($event: KeyboardEvent): void {
    const regex = new RegExp("^[a-zA-Z]+$");
    const key = $event.key;

    if (
      key.length === 1 &&
      !$event.ctrlKey &&
      !$event.metaKey &&
      regex.test(key)
    ) {
      $event.preventDefault();
    }
  }

  usePhoneNumber(): void {
    let phoneResult = phone(this.phoneNumber, {
      country: this.practice?.country,
    });
    if (!phoneResult.isValid) {
      phoneResult = phone(this.phoneNumber);
    }

    if (!phoneResult.isValid || !phoneResult.phoneNumber) {
      this.phoneNumberIsValid = false;
      return;
    }

    this.phoneNumber = phoneResult.phoneNumber;
    this.goToStep(2);
  }

  handleBack(currentStep: number): void {
    switch (currentStep) {
      case 1:
        this.goToStep(0);
        this.selectedClient = undefined;
        this.phoneNumber = "";
        this.phoneNumberIsValid = false;
        break;

      case 2:
        if (!this.selectedClient) {
          this.goToStep(0);
          this.phoneNumber = "";
          this.phoneNumberIsValid = false;
        } else {
          this.goToStep(1);
        }
        break;

      case 3:
        this.goToStep(0);
        this.selectedClient = undefined;
        break;

      case 4:
        this.goToStep(2);
        break;
    }
  }

  clearInitialMessage(): void {
    this.initialMessage.setValue("");
  }

  openTemplatesModal(): void {
    this.showInitialMessageError = false;
    this.showTemplateDialog = true;
  }

  closeTemplatesModal(): void {
    this.showInitialMessageError = true;
    this.showTemplateDialog = false;
  }

  selectTemplate(): void {
    this.selectTemplateDialog = this.dialogService.open(
      DialogTemplateSelectorComponent,
      {
        header: "Select a template",
        modal: true,
        width: "1000px",
        baseZIndex: 10000,
        data: {
          templates: this.templates,
          client: this.selectedClient,
          buttonClass: "p-button-success",
          includeMediaFilter: true,
        },
      },
    );

    this.selectTemplateDialog.onClose.subscribe((result) => {
      if (result) {
        this.handleTemplateSelected(result);
      }
    });
  }

  handleTemplateSelected(event: {
    text: string;
    mergeFields: { placeholderId: string; content: string }[];
    template: Template;
    media?: Media;
    previewType?: string;
    buttonLink?: string;
  }): void {
    this.initialMessage.setValue(event.text);
    this.selectedTemplateContent = event.text;
    this.selectedTemplate = event.template;
    this.selectedTemplateMergeFields = event.mergeFields;
    this.selectedMedia = event.media;
    this.previewType = event.previewType || null;
    this.buttonLink = event.buttonLink;
    this.usingTemplate = true;
    this.closeTemplatesModal();
  }

  editTemplate(): void {
    this.usingTemplate = false;
    this.initialMessageInput?.nativeElement.focus();
  }

  cancelEdits(): void {
    this.usingTemplate = true;
    this.initialMessage.setValue(this.selectedTemplateContent);
  }

  stopUsingTemplate(): void {
    this.clearInitialMessage();
    this.usingTemplate = false;
    this.selectedTemplate = null;
    this.selectedTemplateMergeFields = null;
    this.buttonLink = undefined;
  }
}
