<ng-container *ngIf="message">
  <div class="message-info">
    <div class="message-type">
      <ng-container *ngIf="isCampaignMessage">
        Broadcast
      </ng-container>
      <ng-container *ngIf="!isCampaignMessage">
        {{ message.type | prettifyMessageType }}
      </ng-container>
    </div>
    <div class="bottom-row">
      <div class="date">
        <div class="cell">{{ message.createdAt | date:"dd/MM/yy" }}</div>
        <div class="cell">{{ message.createdAt | date:"HH:mm:ss" }}</div>
      </div>
      <div class="media-type">
        <i class="pi pi-image" [ngClass]="{ 'opacity-100': (mediaType === 'image') }"></i>
        <i class="pi pi-video" [ngClass]="{ 'opacity-100': (mediaType === 'video') }"></i>
        <i class="pi pi-file-pdf" [ngClass]="{ 'opacity-100': (mediaType === 'pdf') }"></i>
      </div>
    </div>
  </div>
  <message
    [message]="message"
    [hideTimeStamp]="true"
    [fullWidth]="true"
  ></message>
</ng-container>
