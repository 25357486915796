<div class="btn-wrap">
  <button pButton [label]="'ui.product_request_history.label' | translate" class="p-button-fourth p-button-sm" [disabled]="!client || productRequests.length === 0" (click)="showModal()"></button>
</div>
<ng-container *ngIf="client && productRequests.length > 0">
  <p-dialog
    [header]="'ui.product_request_history.product_requests' | translate"
    [(visible)]="modalActive"
    [modal]="true"
    [style]="{width: '700px', 'min-height': '200px'}"
    [baseZIndex]="10000"
    [draggable]="false"
    [resizable]="false"
  >
    <div class="wrapper">
      <div class="top-section">
        <div class="row title">
          <div class="cell">{{'ui.product_request_history.products' | translate}}</div>
          <div class="cell">{{'ui.product_request_history.date' | translate}}</div>
          <div class="cell">{{'ui.product_request_history.status' | translate}}</div>
        </div>
        <ng-container *ngFor="let productRequest of productRequests">
          <div class="row">
            <div class="cell">
              <ng-container *ngFor="let item of productRequest.items; let i = index;">
                {{ item.description }}<ng-container *ngIf="i < productRequest.items.length - 1">, </ng-container>
              </ng-container>
            </div>
            <div class="cell">{{ productRequest.createdAt | date : 'dd/MM/yy' }}</div>
            <div class="cell">{{ productRequest.status | prettifyProductRequestStatus | translate }}</div>
          </div>
        </ng-container>
      </div>
      <div class="bottom-btn-row">
        <p-button [label]="'ui.product_request_history.view_all' | translate: {name: client.fullName}" (click)="goToAllProductRequests()"></p-button>
      </div>
    </div>
  </p-dialog>
</ng-container>
