import { phone } from 'phone';

export function phoneValidator(searchString: string, country: string): string {
    let phoneNumber = phone(searchString);

    if (phoneNumber.isValid) {
        searchString = phoneNumber.phoneNumber;
    }

    phoneNumber = phone(searchString, {
        country
    });

    if (phoneNumber.isValid) {
        searchString = phoneNumber.phoneNumber;
    }

    return searchString;
}
