import { Payment } from '../models/Payment';
import {PaymentType} from '../enums/payment-type';
import {getPaymentStatus} from './get-payment-status';
import {PaymentStatus} from '../enums/payment-status';

export function canPaymentSyncBeRetried(payment: Payment): boolean {
  if (payment.type === PaymentType.ONLINE) {
    return false;
  }

  const status = getPaymentStatus(payment);

  const retryableStatuses = [
    PaymentStatus.REFUND,
    PaymentStatus.REFUNDED,
    PaymentStatus.PAID
  ];

  if (
    retryableStatuses.includes(status.id) &&
    !payment.syncedToPmsAt
  ) {
    return true;
  }

  return false;
}
