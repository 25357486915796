import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { first, Observable, Subscription } from 'rxjs';
import { User } from '../../../models/User';
import { Practice } from '../../../models/Practice';
import { Conversation } from '../../../models/Conversation';
import { defaultConversationFilters } from '../../../constants/default-conversation-filters.constants';
import { ConversationSortBy } from '../../../enums/ConversationSortBy';
import { SortDirection } from '../../../enums/sort-direction';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../state/reducers';
import {
  getCurrentPractice,
  getPracticeStaff,
} from '../../../practices/state/selectors';
import { takeWhile } from 'rxjs/operators';
import {
  getIsConversationPdfDownloadInProgress,
  getTotalSearchedConversationsCount,
} from '../../state/selectors';
import { ConversationsFilterDto } from '../../../interfaces/dto/conversations-filter.dto';
import { ActivatedRoute } from '@angular/router';
import { dateFilterOptions } from '../../../constants/date-filter-options.constants';
import { EnvironmentService } from '../../../services/environment.service';
import { Client } from 'src/app/models/Client';
import { GetFilterClients } from 'src/app/clients/state/actions';
import { getFilterClients } from 'src/app/clients/state/selectors';
import { getPreviewConversationId, getTags } from '../../../state/selectors';
import {
  SetConversationPreviewOpen,
  SetPreviewConversationId,
} from '../../../state/actions';
import {
  ClearPreviousConversations,
  GetConversation,
  SetCurrentConversation,
} from '../../state/actions';
import { GetMessages } from '../../../messages/state/actions';
import { defaultPaymentFilters } from '../../../constants/default-payment-filters.constants';
import { Tag } from '../../../models/Tag';
import { getUser } from '../../../auth/state/selectors';
import { GetViewers } from '../../../viewers/state/actions';
import { ViewerType } from '../../../enums/viewers-type';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'conversation-list-component',
  templateUrl: './conversation-list.component.html',
  styleUrls: ['./conversation-list.component.scss'],
})
export class ConversationListComponent implements OnInit, OnDestroy {
  @Output() getConversations = new EventEmitter<ConversationsFilterDto>();
  @Input() conversations?: Conversation[];
  @Input() totalConversationsCount = 0;
  @Input() loading = false;
  alive = true;
  allUsers$?: Observable<User[]>;
  allUsers: User[] = [];
  allClients$?: Observable<Client[]>;
  allClients: Client[] = [];
  allTags: Tag[] = [];
  notTagged: any = {
    id: 0,
    name: '', //translated
  };
  authUser$?: Observable<User | null>;
  authUser?: User;
  authUserSub?: Subscription;
  practice$?: Observable<Practice | null>;
  practiceSub$?: Subscription;
  practice?: Practice | null;
  conversationCount$?: Observable<number>;
  pdfDownloading = false;
  statusFilter = defaultConversationFilters.status;
  dateFilter: { start: string; end: string } | string | null =
    defaultConversationFilters.date;
  ownersFilter: number[] = defaultConversationFilters.owner;
  assigneesFilter: number[] = defaultConversationFilters.assignee;
  clientsFilter: number[] = defaultConversationFilters.client;
  tagsFilter: number[] = defaultConversationFilters.tag;
  sortBy: ConversationSortBy = defaultConversationFilters.sortBy;
  sortDir: SortDirection = defaultConversationFilters.sortDir;
  page = defaultConversationFilters.page;
  conversationsPerPage = this.environmentService.get('conversationsPerPage');
  selectedConversations: Conversation[] = [];
  selectedConversationIds: string[] = [];
  previewConversationId$: Observable<string | null>;
  nextPageTimeout: NodeJS.Timeout | undefined = undefined;
  debounceDuration = 300;
  initialFetch = false;

  constructor(
    private store: Store<AppState>,
    private environmentService: EnvironmentService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
  ) {
    this.previewConversationId$ = this.store
      .select(getPreviewConversationId)
      .pipe(takeWhile(() => this.alive));
  }

  ngOnInit(): void {
    console.log('Hi this tag',this.allTags)
    this.subscribeToCurrentPractice();
    this.subscribeToPracticeStaff();
    this.subscribeToConversationCount();
    this.subscribeToConversationPdfDownloadInProgress();
    this.subscribeToRouteParams();
    this.subscribeToTags();
    this.subscribeToAuthUser();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  subscribeToRouteParams(): void {
    this.route.queryParams.subscribe((params) => {
      if (params.page) {
        this.page = JSON.parse(params.page);
      } else {
        this.page = defaultPaymentFilters.page;
      }

      if (params.sortDir) {
        this.sortDir = JSON.parse(params.sortDir);
      } else {
        this.sortDir = defaultPaymentFilters.sortDir;
      }

      if (!this.initialFetch) {
        this.initialFetch = true;
        this.fetchConversations();
      }
    });
  }

  subscribeToCurrentPractice(): void {
    this.practice$ = this.store
      .pipe(select(getCurrentPractice))
      .pipe(takeWhile(() => this.alive));

    this.practiceSub$ = this.practice$.subscribe((practice) => {
      this.practice = practice;
      this.fetchConversations();
    });
  }

  subscribeToAuthUser(): void {
    this.authUser$ = this.store.pipe(select(getUser)).pipe(
      takeWhile(() => this.alive)
    );

    this.authUserSub = this.authUser$.subscribe(user => {
      if (user) {
        this.authUser = user;
      }
    });
  }

  subscribeToPracticeStaff(): void {
    this.allUsers$ = this.store
      .pipe(select(getPracticeStaff))
      .pipe(takeWhile(() => this.alive));
    this.allUsers$.subscribe((users) => {
      this.allUsers = [
        ...users.filter((u) => u.id === '0')
        .sort((a, b) => a.lastName.localeCompare(b.lastName))
        .map((u: any) => {
          return {...u, name: u.fullName};
        }),
        ...users.filter((u) => u.id !== '0')
        .sort((a, b) => a.lastName.localeCompare(b.lastName))
        .map((u: any) => {
          return {...u, name: u.fullName};
        }),
      ];
    });
  }

  subscribeToTags(): void {
    this.store.select(getTags).pipe(
      takeWhile(() => this.alive),
    ).subscribe((tags) => {
      this.translateService.get('conversation.components.list.not_tagged').subscribe(notTaggedText=> {
        this.allTags = tags;
        this.notTagged.name = notTaggedText;
        this.allTags = [...[this.notTagged], ...this.allTags];
      })
    });
  }

  subscribeToConversationCount(): void {
    this.conversationCount$ = this.store
      .select(getTotalSearchedConversationsCount)
      .pipe(takeWhile(() => this.alive));

    this.conversationCount$.subscribe((count) => {
      this.totalConversationsCount = count;
    });
  }

  subscribeToConversationPdfDownloadInProgress(): void {
    this.store
      .select(getIsConversationPdfDownloadInProgress)
      .pipe(takeWhile(() => this.alive))
      .subscribe((isLoading) => {
        this.pdfDownloading = isLoading;
      });
  }

  showPreviousPageLink(): boolean {
    return this.page > 1;
  }

  showNextPageLink(): boolean {
    return this.totalConversationsCount > this.page * this.conversationsPerPage;
  }

  debounceFetchConversations(): void {
    // Clear any existing debounce timeout
    if (this.nextPageTimeout) {
      clearTimeout(this.nextPageTimeout);
    }

    this.nextPageTimeout = setTimeout(() => {
      this.fetchConversations();
    }, this.debounceDuration);
  }

  previousPage(): void {
    this.page--;
    this.debounceFetchConversations();
  }

  nextPage(): void {
    this.page++;
    this.debounceFetchConversations();
  }

  fetchConversations(): void {
    if (this.practice) {
      this.getConversations.emit({
        status: this.statusFilter,
        owner: this.ownersFilter,
        assignee: this.assigneesFilter,
        tag: this.tagsFilter,
        client: [],
        date: this.dateFilter,
        sortBy: this.sortBy,
        sortDir: this.sortDir,
        page: this.page,
      });
    }
  }

  handleFiltersUpdated($event: any): void {
    this.statusFilter = $event.status;
    this.dateFilter = $event.date;
    this.ownersFilter = $event.owner;
    this.tagsFilter = $event.tag;
    this.assigneesFilter = $event.assignee;
    this.sortBy = $event.sortBy;
    this.sortDir = $event.sortDir;
    this.page = 1;

    this.fetchConversations();
  }

  handleToggleConversation(conversation: Conversation): void {
    if (
      !!this.selectedConversationIds.find((selConversationId) => selConversationId === conversation.id)
    ) {
      this.selectedConversationIds = this.selectedConversationIds.filter((selConversationId) => selConversationId !== conversation.id);
      this.selectedConversations = this.selectedConversations.filter((selConversation) => selConversation.id !== conversation.id);
    } else {
      this.selectedConversationIds.push(conversation.id);
      this.selectedConversations.push(conversation);
    }
  }

  handleBulkActionFired(): void {
    this.selectedConversationIds = [];
    this.selectedConversations = [];
  }

  handleConversationClicked(conversation: Conversation): void {
    this.store.dispatch(SetPreviewConversationId({ id: conversation.id }));
    this.store.dispatch(SetCurrentConversation({ id: conversation.id }));
    this.store.dispatch(GetConversation({ id: conversation.id }));
    this.store.dispatch(GetMessages({ conversationId: conversation.id }));
    this.store.dispatch(ClearPreviousConversations());
    this.store.dispatch(SetConversationPreviewOpen({ open: true }));
  }
}
