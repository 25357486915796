<div class="filters">
  <div class="top">
    <div class="left">
      <div class="filter-item filter-toggle" [class.active]="mobileFiltersOpen" (click)="toggleMobileFilters()">
        <div class="toggle">
          <ng-container *ngIf="!mobileFiltersOpen">
            <i class="pi pi-filter"></i>
          </ng-container>
          <ng-container *ngIf="mobileFiltersOpen">
            <i class="pi pi-times"></i>
          </ng-container>
          <div>Filters</div>
        </div>
      </div>
      <div class="pipe"></div>
      <div class="filter-item clear-all" (click)="clearFilters()">
        Reset
      </div>
    </div>
    <div class="right" [class.active]="mobileFiltersOpen">
      <div class="filters-group">
        <ng-container *ngTemplateOutlet="filters"></ng-container>
      </div>
      <div class="pipe"></div>
      <div class="filter-item clear-all" (click)="clearFilters()">
        Reset
      </div>
    </div>
    <div class="right">
      <!-- <product-request-sort></product-request-sort> -->
    </div>
  </div>
</div>

<ng-template #filters>
  <ng-container *ngIf="!hideFilters">
    <ng-container *ngFor="let filter of config.filters">
      <ng-container *ngIf="filter.type === filterType.CREATED_AT">
        <filter-created-at [options]="filter.options" [selected]="selectedValues[filterType.CREATED_AT][0]" (valueSelected)="handleFilterChange(filterType.CREATED_AT, $event)"></filter-created-at>
      </ng-container>
      <ng-container *ngIf="filter.type === filterType.STATUS">
        <filter-status [options]="filter.options" [selected]="selectedValues[filterType.STATUS]" (valueSelected)="handleFilterChange(filterType.STATUS, $event)"></filter-status>
      </ng-container>
      <ng-container *ngIf="filter.type === filterType.CLIENT">
        <filter-client [preSelected]="selectedValues[filterType.CLIENT]" (valueSelected)="handleFilterChange(filterType.CLIENT, $event)"></filter-client>
      </ng-container>
      <ng-container *ngIf="filter.type === filterType.OWNER">
        <filter-owner [preSelected]="selectedValues[filterType.OWNER]" (valueSelected)="handleFilterChange(filterType.OWNER, $event)"></filter-owner>
      </ng-container>
      <ng-container *ngIf="filter.type === filterType.APPROVAL_STATUS">
        <filter-approval-status [options]="filter.options" [selected]="selectedValues[filterType.APPROVAL_STATUS]" (valueSelected)="handleFilterChange(filterType.APPROVAL_STATUS, $event)"></filter-approval-status>
      </ng-container>
      <ng-container *ngIf="filter.type === filterType.ACTIVE_DEPOSIT">
        <filter-active-deposit [options]="filter.options" [selected]="selectedValues[filterType.ACTIVE_DEPOSIT]" (valueSelected)="handleFilterChange(filterType.ACTIVE_DEPOSIT, $event)"></filter-active-deposit>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<div class="modal-bg" [class.active]="modalBackground"></div>
