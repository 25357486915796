import {TemplateType} from '../enums/template-type';

export function templateTypeCanHaveMedia(type: TemplateType): boolean {
  const validTypes = [
    TemplateType.Campaign,
    TemplateType.CampaignWithPayment,
    TemplateType.Standard,
    TemplateType.ReminderAppointment,
    TemplateType.ReminderParasiticide,
    TemplateType.ReminderPayment,
    TemplateType.ReminderVaccination,
    TemplateType.ReminderPreOperation,
    TemplateType.ReminderPostOperation,
    TemplateType.ReminderHealthCarePlan,
    TemplateType.ReminderHealthCarePlanFollowUp,
  ];

  return !!validTypes.includes(type);
}
