<form class="move-media" [formGroup]="form">
  <p [innerHTML]="'media.move_media.description' | translate: {name:this.config.data.media.name}"></p>
  <div class="input-row">
    <select class="primary" formControlName="destinationFolder" name="" id="">
      <option value="">{{'media.move_media.please_select' | translate}}</option>
      <option value="0" [disabled]="this.config.data.media.folderId === null">{{'media.move_media.root_directory' | translate}}</option>
      <ng-container *ngFor="let folder of (folders$ | ngrxPush)">
        <option value="{{ folder.id }}" [disabled]="folder.id === this.config.data.media.folderId">{{ folder.name }}</option>
      </ng-container>
    </select>
    <ng-container *ngIf="form.controls.destinationFolder.invalid && (form.controls.destinationFolder.dirty || form.controls.destinationFolder.touched) && form.controls.destinationFolder.errors?.['required']">
      <div class="error">{{'media.move_media.destination_folder_error' | translate}}</div>
    </ng-container>
  </div>
  <div class="btn-row">
    <p-button (click)="submit()" [label]="'media.move_media.move' | translate" styleClass="p-button-primary"></p-button>
  </div>
</form>
