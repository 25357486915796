import { Pipe, PipeTransform } from '@angular/core';
import { format, formatDistanceToNow, isToday } from 'date-fns';


@Pipe({name: 'dateOrDiff'})
export class DateOrDiffPipe implements PipeTransform {
  transform(value: Date | undefined, refresh?: Date): string {
    if (!value) {
      return 'N/A';
    }

    if (isToday(value)) {
        return formatDistanceToNow(value) + ' ago';
    }

    return format(value, "dd/MM/yy HH:mm");
  }
}
