import { SortBy } from "../enums/sort-by";
import { SortDirection } from "../enums/sort-direction";
import { SortByOption } from "../interfaces/sort-by-option.interface";

export const productRequestSortByOptions: SortByOption[] = [
  {
    label: 'constants.sort_options.newest_first',
    sortBy: SortBy.CREATED_AT,
    sortDir: SortDirection.DESC
  },
  {
    label: 'constants.sort_options.oldest_first',
    sortBy: SortBy.CREATED_AT,
    sortDir: SortDirection.ASC
  }
];
