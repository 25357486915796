<notifications [notifications]="(notifications$ | async) || []"></notifications>
<toast></toast>
<whats-new-dialog></whats-new-dialog>
<system-dialogs></system-dialogs>
<ng-container *ngIf="(user$ | async) && (practices$ | async) && (practices$ | async)!.length == 0">
    <dialog-no-practices></dialog-no-practices>
</ng-container>
<ng-container *ngIf="!(healthy$ | async) || !(websocketHealthy$ | async)">
  <dialog-no-connection></dialog-no-connection>
</ng-container>
<router-outlet></router-outlet>
