import {Pipe, PipeTransform} from '@angular/core';
import {Payment} from '../models/Payment';
import {canPaymentSyncBeRetried} from '../helpers/can-payment-sync-be-retried';

@Pipe({name: 'canPaymentSyncBeRetried'})
export class CanPaymentSyncBeRetriedPipe implements PipeTransform {
  transform(payment: Payment): boolean {
    return canPaymentSyncBeRetried(payment);
  }
}
