import { Pipe, PipeTransform } from "@angular/core";

export enum TemplateSubmissionStatus {
  Submitted = 'submitted',
  Approved = 'approved',
  Rejected = 'rejected',
  Disabled = 'disabled',
  Pending = 'pending',
  Flagged = 'flagged',
  Paused = 'paused',
  In_Appeal = 'in_appeal',
}

const enumToTranslation: Record<TemplateSubmissionStatus, string> = {
  [TemplateSubmissionStatus.Approved]: 'enums.template_submission_status.approved',
  [TemplateSubmissionStatus.Disabled]: 'enums.template_submission_status.disabled',
  [TemplateSubmissionStatus.Flagged]: 'enums.template_submission_status.flagged',
  [TemplateSubmissionStatus.In_Appeal]: 'enums.template_submission_status.in_appeal',
  [TemplateSubmissionStatus.Paused]: 'enums.template_submission_status.paused',
  [TemplateSubmissionStatus.Pending]: 'enums.template_submission_status.pending',
  [TemplateSubmissionStatus.Rejected]: 'enums.template_submission_status.rejected',
  [TemplateSubmissionStatus.Submitted]: 'enums.template_submission_status.submitted',
}

@Pipe({name: 'templateSubmissionStatusTranslationKey'})
export class TemplateSubmissionStatusTranslationKeyPipe implements PipeTransform {
  transform(value: TemplateSubmissionStatus): string {
    return enumToTranslation[value];
  }
}
