import { Component } from '@angular/core';
import {select, Store} from "@ngrx/store";
import {Router} from "@angular/router";
import { TitleService } from '../../../services/title.service';

@Component({
  selector: 'app-form-submissions',
  templateUrl: './form-submissions.component.html',
  styleUrls: ['./form-submissions.component.scss']
})
export class FormSubmissionsPage {

  constructor(
    private store: Store,
    private router: Router,
    private titleService: TitleService,
  ) {
    this.titleService.setTranslatedTitle('forms.pages.submissions.title');
  }
}
