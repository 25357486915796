import { Pipe, PipeTransform } from '@angular/core';
import { TemplateSubmissionStatus } from '../enums/template-submission-status';
import { prettifyTemplateSubmissionType } from '../helpers/prettify-template-submission-status';

@Pipe({name: 'prettifyTemplateSubmissionType'})
export class PrettifyTemplateSubmissionTypePipe implements PipeTransform {
  transform(value: TemplateSubmissionStatus): string {
    return prettifyTemplateSubmissionType(value);
  }
}
