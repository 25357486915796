import { Pipe, PipeTransform } from '@angular/core';
import { format, formatDistanceToNow, isBefore, isToday } from 'date-fns';
import { FilterOption } from '../interfaces/filter-option.interface';


@Pipe({name: 'getFilterLabel'})
export class GetFilterLabelPipe implements PipeTransform {
  transform(value: FilterOption[], type: string): string {
    let label = '';

    if (value.length === 0) {
      return type;
    }

    if (value.length > 0) {
      label = `${type}: ${value[0].label}`;
    }
    
    if (value.length > 1) {
      label = `${label} +${value.length-1}`;
    }

    return label;
  }
}
