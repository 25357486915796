import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { NotificationComponent } from './components/notification/notification.component';
import { UiModule } from '../ui/ui.module';
import { RouterModule } from '@angular/router';
import {UtilsModule} from '../utils/utils.module';
import {LetDirective, PushPipe} from "@ngrx/component";

@NgModule({
  declarations: [NotificationsComponent, NotificationComponent],
  imports: [
    CommonModule,
    UiModule,
    UtilsModule,
    PushPipe,
    LetDirective,
    RouterModule,
  ],
  exports: [NotificationsComponent, NotificationComponent]
})
export class NotificationsModule { }
