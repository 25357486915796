
import { Action, createReducer, on } from '@ngrx/store';
import {Payment} from '../../models/Payment';
import * as actions from './actions';
import {ClearPayments} from "./actions";

export interface PaymentsState {
  newPaymentRequestOpen: boolean;
  paymentRequestOpen: boolean;
  paymentRequestFailed: boolean;
  paymentRequestLoading: boolean;
  payments: Payment[] | null;
  totalPaymentCount: number;
  syncingPayments: Payment[];
}

export const initialState: PaymentsState = {
  newPaymentRequestOpen: false,
  paymentRequestOpen: false,
  paymentRequestFailed: false,
  paymentRequestLoading: false,
  payments: null,
  totalPaymentCount: 0,
  syncingPayments: []
};

const paymentsReducer = createReducer(
  initialState,
  on(actions.OpenPaymentRequest, (state, payload) => ({
    ...state,
    paymentRequestOpen: true
  })),
  on(actions.ClosePaymentRequest, (state, payload) => ({
    ...state,
    paymentRequestOpen: false
  })),
  on(actions.OpenNewPaymentRequest, (state, payload) => ({
    ...state,
    newPaymentRequestOpen: true
  })),
  on(actions.CloseNewPaymentRequest, (state, payload) => ({
    ...state,
    newPaymentRequestOpen: false
  })),
  on(actions.SubmitPaymentConversationRequest, (state, payload) => ({
    ...state,
    paymentRequestLoading: true
  })),
  on(actions.SubmitPaymentRequest, (state, payload) => ({
    ...state,
    paymentRequestLoading: true
  })),
  on(actions.SubmitPaymentRequestSuccess, (state, payload) => ({
    ...state,
    newPaymentRequestOpen: false,
    paymentRequestOpen: false,
    paymentRequestLoading: false
  })),
  on(actions.SubmitPaymentRequestFailed, (state, payload) => ({
    ...state,
    paymentRequestFailed: true,
    paymentRequestLoading: false
  })),
  on(actions.GetPaymentsSuccess, (state, payload) => ({
    ...state,
    payments: payload.payments,
    totalPaymentCount: payload.total
  })),
  on(actions.ClearPayments, (state, payload) => ({
    ...state,
    payments: [],
    totalPaymentCount: 0
  })),
  on(actions.RetrySavePaymentToPms, (state, payload) => ({
    ...state,
    syncingPayments: [
      ...state.syncingPayments,
      payload.payment
    ]
  })),
  on(actions.RetrySavePaymentToPmsSuccess, (state, payload) => {
    let payments = null;

    if (state.payments) {
      payments = [
        ...state.payments.filter(payment => payment.id !== payload.payment.id),
        payload.payment
      ];
    };

    return {
      ...state,
      syncingPayments: state.syncingPayments.filter((p) => p.id !== payload.payment.id),
      payments
    };
  }),
  on(actions.RetrySavePaymentToPmsFailed, (state, payload) => {
    let payments: Payment[] | null = null;

    if (state.payments) {
      payments = [
        ...state.payments.filter(payment => payment.id !== payload.payment.id),
        {
          ...payload.payment,
          lastSyncAttempt: new Date(),
        },
      ];

      payments.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());
    };

    return {
      ...state,
      syncingPayments: state.syncingPayments.filter((p) => p.id !== payload.payment.id),
      payments
    };
  }),
);

export function reducer(state: PaymentsState | undefined, action: Action): PaymentsState {
  return paymentsReducer(state, action);
}
