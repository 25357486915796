<div class="controls">
    <notification-list-controls [showAll]="showAll" (emitShowAll)="handleShowAllChange()"></notification-list-controls>
</div>
<div class="notifications-list">
    <ng-container *ngFor="let notification of todaysNotifications">
        <div class="notification">
            <notifications-list-item [notification]="notification" (clickedLink)="handleLinkClicked()"></notifications-list-item>
        </div>
    </ng-container>
    <ng-container *ngIf="yesterdaysNotifications.length > 0 && (olderNotifications.length > 0 || todaysNotifications.length > 0)">
        <div class="subheading">
            Yesterday
        </div>
    </ng-container>
    <ng-container *ngFor="let notification of yesterdaysNotifications">
        <div class="notification">
            <notifications-list-item [notification]="notification" (clickedLink)="handleLinkClicked()"></notifications-list-item>
        </div>
    </ng-container>
    <ng-container *ngIf="olderNotifications.length > 0 && (yesterdaysNotifications.length > 0 || todaysNotifications.length > 0)">
        <div class="subheading">Older</div>
    </ng-container>
    <ng-container *ngFor="let notification of olderNotifications">
        <div class="notification">
            <notifications-list-item [notification]="notification" (clickedLink)="handleLinkClicked()"></notifications-list-item>
        </div>
    </ng-container>
    <ng-container *ngIf="totalNotificationCount > notifications.length">
      <div class="load-more" (click)="loadMore()">Load older notifications</div>
    </ng-container>
    <ng-container *ngIf="totalNotificationCount > 0 && totalNotificationCount === notifications.length">
        <div class="last-thirty-days-message">That's all your notifications from the last 30 days</div>
    </ng-container>
    <ng-container *ngIf="totalNotificationCount === 0">
        <div class="no-notifications">No notifications yet, check back later</div>
    </ng-container>
</div>
