<div class="watcher-context-menu">
    <div class="header">
        <ng-container *ngIf="isWatching">
            <div class="watch-row" (click)="stopWatching($event)">
                <i class="pi pi-eye"></i> 
                <div class="label">Stop watching</div>
            </div>
        </ng-container>
        <ng-container *ngIf="!isWatching">
            <div class="watch-row" (click)="startWatching($event)">
                <i class="pi pi-eye"></i>
                <div class="label">Start watching</div>
            </div>
        </ng-container>
    </div>
    <div class="watchers">
        <div class="heading">Watching</div>
        <ng-container *ngIf="watchers.length === 0">
            <div class="no-watchers">
                No watchers yet
            </div>
        </ng-container>
        <ng-container *ngIf="watchers.length > 0">
            <div class="watcher-list">
                <ng-container *ngFor="let watcher of watchers">
                    <div class="watcher-row">
                        <div class="left">
                            <user-avatar [user]="watcher" size="small"></user-avatar>
                            <div class="name">{{ watcher.firstName }} {{ watcher.lastName }}</div>
                        </div>
                        <div class="right">
                            <i class="pi pi-eye-slash" (click)="removeWatcher($event, watcher)"></i>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </div>
    <div class="add-row">
        <div class="link" (click)="toggleAddWatcherForm()">Add watchers</div>
        <div class="add-form" [class.active]="showAddWatchersForm">
            <p-listbox [ngModel]="user" (onClick)="handleUserClick($event)" [options]="allUsers" [filter]="true" optionLabel="fullName" [listStyle]="{'max-height':'156px'}">
                <ng-template let-user pTemplate="item">
                    <div class="user-item">
                        <div class="left">
                            <user-avatar [user]="user" size="small"></user-avatar>
                            <div class="label">{{ user.firstName }} {{ user.lastName }}</div>
                        </div>
                        <div class="right">
                            <i class="pi pi-eye"></i>
                        </div>
                    </div>
                </ng-template>
            </p-listbox>
        </div>
    </div>
</div>