import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { format, formatDistanceToNow, isToday } from 'date-fns';


@Pipe({name: 'dateOrDiff'})
export class DateOrDiffPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: Date | undefined, refresh?: Date): string {
    if (!value) {
      return 'N/A';
    }

    if (isToday(value)) {
        return this.translateService.instant('pipes.date_or_diff.time_ago', {time: formatDistanceToNow(value)});
    }

    return format(value, "dd/MM/yy HH:mm");
  }
}
