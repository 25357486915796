<ng-container *ngIf="previewSrc">
  <div class="image-preview">
    <img [src]="previewSrc" />
  </div>
</ng-container>
<ng-container *ngIf="filename">
  <div class="file-name">{{ filename }}</div>
</ng-container>

<div class="input-row">
  <div class="message-box-wrapper">
    <textarea
      #textAreaElement
      placeholder="Caption"
      [class.invalid]="messageInvalid"
      pInputTextarea
      [rows]="1"
      [autoResize]="true"
      (onResize)="textareaResize($event)"
      [(ngModel)]="caption"
      (ngModelChange)="onMessageInputChange($event)"
    >
    </textarea>

    <div class="character-count" [class.invalid]="messageTooLong">{{ remainingMessageLength }}</div>
  </div>

  <div class="send-icon" tooltipPosition="left" pTooltip="Send to client">
    <div class="mobile-tip" [class.highlight]="messageTooLong">To client</div>
      <svg width="37" height="24" viewBox="0 0 37 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="action-icon"  (click)="submit()">
        <path d="M33.0116 11.8795C33.2105 11.8032 33.0838 11.5142 32.8531 11.5178L9.19899 11.8839C8.8322 11.8896 8.5344 12.0703 8.41607 12.3591L4.5266 21.8495C4.3692 22.2336 4.89407 22.6779 5.31449 22.5164L33.0116 11.8795Z" stroke="currentColor" stroke-width="2"/>
        <path d="M33.0254 11.642C33.2251 11.7154 33.1046 12.0038 32.8738 12.0047L9.21673 12.1044C8.84021 12.106 8.53213 11.9242 8.41119 11.6291L4.51646 2.1258C4.36021 1.74453 4.87718 1.30199 5.29817 1.45663L33.0254 11.642Z" stroke="currentColor" stroke-width="2"/>
      </svg>
    </div>
  </div>





