import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { Practice } from 'src/app/models/Practice';
import { getCurrentPractice } from 'src/app/practices/state/selectors';
import { AppState } from 'src/app/state/reducers';
import {ClearNewConversationError} from "../../../../conversation/state/actions";
import { EnvironmentService } from '../../../../services/environment.service';

@Component({
  selector: 'create-conversation-action-bar',
  templateUrl: './create-conversation-action-bar.component.html',
  styleUrls: ['./create-conversation-action-bar.component.scss']
})
export class CreateConversationActionBarComponent implements OnInit, OnDestroy {
  alive = true;
  showNewConversationModal = false;
  practice$?: Observable<Practice | null>;
  helpLink = '';
  

  constructor(private store: Store<AppState>, private environmentService: EnvironmentService) { }

  ngOnInit(): void {
    this.subscribeToCurrentPractice();
    this.getHelpLink();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  subscribeToCurrentPractice(): void {
    this.practice$ = this.store.pipe(select(getCurrentPractice)).pipe(takeWhile(() => this.alive));
  }

  getHelpLink(): void {
    this.helpLink = `${this.environmentService.get('helpUrl')}/learn/section/digital-practice`;
  }

  openNewConversationModal(): void {
    this.showNewConversationModal = true;
  }

  handleConversationCreated(): void {
    this.showNewConversationModal = false;
    this.store.dispatch(ClearNewConversationError());
  }

  handleNewConversationClosed(): void {
    this.showNewConversationModal = false;
  }
}
