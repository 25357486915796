import { TemplateArchiveStatus } from '../enums/template-archive-status';
import { TemplateStatus } from '../enums/template-status';
import { TemplateSubmissionStatus } from '../enums/template-submission-status';
import { TemplateType } from '../enums/template-type';
import { Template } from '../models/Template';

export function isTemplateSuitableForCampaigns(template: Template): boolean {
    if (![TemplateType.Campaign, TemplateType.CampaignWithPayment, TemplateType.Welcome].includes(template.type)) {
        return false;
    }

    if (template.type === TemplateType.Welcome && template.active !== TemplateStatus.ACTIVE) {
        return false;
    }

    if (template.status !== TemplateSubmissionStatus.Approved) {
        return false;
    }

    if (template.archived !== TemplateArchiveStatus.NOT_ARCHIVED) {
        return false;
    }

    return true;
}
