<p-dialog
  [(visible)]="showNewConversation"
  [modal]="true"
  [style]="{width: '800px'}"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
  (onHide)="handleNewConversationDialogClosed()"
  >
 <p-header>
     <div class="dialog-header">
      {{'dialogs.new_conversation' | translate}}
    </div>
  </p-header>
  <ng-container *ngIf="showNewConversation">
    <new-conversation [practice]="practice" (conversationCreated)="handleNewConversationDialogClosed()" (closed)="handleNewConversationDialogClosed()"></new-conversation>
  </ng-container>
  <div class="dialog-footer">
    <div class="bottom-row">
      <div class="left"></div>
      <div class="center">
        <a [href]="helpLink + '/category/faq/post/how-do-i-add-client-message-templates'" target="_blank"><img [src]="'questionMarkIcon' | themeIconUrl: theme" height="20" class="help-icon" alt="" >{{'dialogs.need_help' | translate}}</a>
      </div>
      <div class="right">
      </div>
    </div>
  </div>
</p-dialog>


<p-dialog
  [header]="'dialogs.new_product_request' | translate"
  [(visible)]="productRequestOpen"
  [modal]="true"
  [style]="{width: '450px'}"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
  (onHide)="handleProductRequestDialogClosed()"
>
  <ng-container *ngIf="productRequestOpen">
    <new-product-request></new-product-request>
  </ng-container>
</p-dialog>

<p-dialog
  [header]="'dialogs.product_request_id' | translate: {id: currentProductRequest?.id}"
  [(visible)]="productRequestDetailOpen"
  [modal]="true"
  [style]="{width: '700px'}"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
  styleClass="no-padding"
  (onHide)="handleProductRequestDetailDialogClosed()"
>
  <ng-container *ngIf="productRequestDetailOpen && currentProductRequest">
    <product-request-detail [productRequest]="currentProductRequest"></product-request-detail>
  </ng-container>
</p-dialog>

<p-dialog
  [header]="'dialogs.approve_product_request' | translate"
  [(visible)]="approveProductRequestOpen"
  [modal]="true"
  [style]="{width: '500px'}"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
  (onHide)="handleApproveProductRequestDialogClosed()"
>
  <ng-container *ngIf="approveProductRequestOpen && productRequestToApprove">
    <approve-product-request [productRequest]="productRequestToApprove"></approve-product-request>
  </ng-container>
</p-dialog>

<p-dialog
  [header]="'dialogs.reject_product_request' | translate"
  [(visible)]="rejectProductRequestOpen"
  [modal]="true"
  [style]="{width: '500px'}"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
  (onHide)="handleRejectProductRequestDialogClosed()"
>
  <ng-container *ngIf="rejectProductRequestOpen && productRequestToReject">
    <reject-product-request [productRequest]="productRequestToReject"></reject-product-request>
  </ng-container>
</p-dialog>

<p-dialog
  [header]="'dialogs.product_request_payment_details' | translate"
  [(visible)]="createProductRequestPaymentOpen"
  [modal]="true"
  [style]="{width: '750px'}"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
  (onHide)="handleProductRequestCreatePaymentDialogClosed()"
>
  <ng-container *ngIf="createProductRequestPaymentOpen && productRequestToCreatePayment">
    <product-request-create-payment [productRequest]="productRequestToCreatePayment"></product-request-create-payment>
  </ng-container>
</p-dialog>

<p-dialog
  [header]="'dialogs.product_request_payment_paid' | translate"
  [(visible)]="productRequestPaidConfirmationOpen"
  [modal]="true"
  [style]="{width: '500px'}"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
  (onHide)="handleProductRequestForPaidConfirmationDialogClosed()"
>
  <ng-container *ngIf="productRequestPaidConfirmationOpen && productRequestForPaidConfirmation">
    <confirm-product-request-paid [productRequest]="productRequestForPaidConfirmation" [context]="productRequestPaidConfirmationContext"></confirm-product-request-paid>
  </ng-container>
</p-dialog>

<p-dialog
  [header]="'dialogs.product_request_dispensed' | translate"
  [(visible)]="productRequestDispenseOpen"
  [modal]="true"
  [style]="{width: '500px'}"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
  (onHide)="handleDispenseProductRequestDialogClosed()"
>
  <ng-container *ngIf="productRequestDispenseOpen && productRequestForDispense">
    <dispense-product-request [productRequest]="productRequestForDispense"></dispense-product-request>
  </ng-container>
</p-dialog>

<p-dialog
  [header]="'dialogs.new_client_deposit' | translate"
  [(visible)]="showNewClientDeposit"
  [modal]="true"
  [style]="{width: '450px'}"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
  (onHide)="handleNewClientDepositClosed()"
>
  <ng-container *ngIf="showNewClientDeposit">
    <new-client-deposit></new-client-deposit>
  </ng-container>
</p-dialog>

<p-dialog
  [header]="'dialogs.new_form_request' | translate"
  [(visible)]="showNewFormRequest"
  [modal]="true"
  [style]="{width: '450px'}"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
  (onHide)="handleNewFormRequestClosed()"
>
  <ng-container *ngIf="showNewFormRequest">
    <new-form-request></new-form-request>
  </ng-container>
</p-dialog>
