import { Injectable } from '@angular/core';
import { BasicClient } from '../models/BasicClient';
import { Client } from '../models/Client';

@Injectable({
  providedIn: 'root'
})
export class ClientToBasicClientAdapter {
  constructor() {
  }

  run(client: Client): BasicClient {
    return {
        pmsId: client.pmsId,
        title: client.title,
        firstName: client.firstName,
        lastName: client.lastName,
        id: client.id,
        phone: client.phone
    };
  }
}
