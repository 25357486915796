<ng-container *ngIf="notification">
    <div class="notification" [class.read]="notification.read">
        <div class="bar" [ngStyle]="{'backgroundColor': notification.barColor}"></div>
        <main (click)="navigateToNotificationLink(notification)">
            <div class="message">{{ notification.message }}</div>
            <div class="descr">{{ notification.description }}</div>
            <div class="time-ago">
                {{ notification.createdAt | diffForHumans:refresh }}
                <ng-container *ngIf="(currentPractice$ | async)?.id !== notification.practice.id">
                    <span class="practice-name">
                        {{ notification.practice.name }}
                    </span>
                </ng-container>
            </div>
            <ng-container *ngIf="notification.note">
                <quill-view [content]="notification.note" format="object" theme="snow"></quill-view>
            </ng-container>
        </main>
        <aside>
            <ng-container *ngIf="notification.actioner && notification?.actioner?.firstName && notification?.actioner?.lastName; else noActioner">
                <user-avatar [user]="notification.actioner" size="small"></user-avatar>
              </ng-container>
              <ng-template #noActioner>
                <img src="/assets/images/dp-logo-small.png" width="30">
              </ng-template>
            <ng-container *ngIf="notification.read">
                <checkbox-circle [selected]="notification.read" (toggled)="handleReadToggle()" [pTooltip]="'ui.notifications_list_item.mark_unread' | translate"></checkbox-circle>
            </ng-container>
            <ng-container *ngIf="!notification.read">
                <checkbox-circle [selected]="notification.read" (toggled)="handleReadToggle()" [pTooltip]="'ui.notifications_list_item.mark_read' | translate"></checkbox-circle>
            </ng-container>
        </aside>
    </div>
</ng-container>
