import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {Payment} from '../models/Payment';
import {PaymentDto} from '../interfaces/dto/payment.dto';
import {catchError, map, tap} from 'rxjs/operators';
import {PaymentAdapter} from '../adapters/payment.adapter';
import {EnvironmentService} from '../services/environment.service';
import {HttpClient} from '@angular/common/http';
import { PaymentFilterDto } from '../interfaces/dto/payment-filter.dto';
import { Practice } from '../models/Practice';
import { ClientAdapter } from '../adapters/client.adapter';
import { Channel } from '../enums/channel';
import {Client} from '../models/Client';
import {Contact} from '../models/Contact';
import { PaymentRequest } from 'src/app/interfaces/payment-request';
import {DashboardStatsDto} from "../interfaces/dto/dashboard-stats.dto";
import {DashboardStats} from "../interfaces/dashboard-stats.interface";
import {StatsAdapter} from "../adapters/stats.adapter";

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private environmentService: EnvironmentService,
    private http: HttpClient,
    private statsAdapter: StatsAdapter,
  ) { }

  getDashboardStats(practice: Practice | null): Observable<DashboardStats> {
    if (!practice) {
      return of();
    }

    const url = this.environmentService.get('backendUrl') + `/practices/${practice.id}/stats`;

    return this.http.get<DashboardStatsDto>(url, {withCredentials: true}).pipe(
      map((response: DashboardStatsDto) => {
        return this.statsAdapter.run(response);
      })
    );
  }
}
