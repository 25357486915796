<ng-container *ngIf="attachment">
  <ng-container *ngIf="attachment.mime.includes('image')">
    <div class="attachment-img" #imageWrap>
      <ng-container *ngIf="!revealed && !outbound">
        <div class="overlay"></div>
      </ng-container>
      <a target="_blank" [href]="attachment.location"><img [src]="attachment.location" [class.blur]="!revealed && !outbound" alt="" (load)="loaded()"></a>
    </div>
    <button pButton *ngIf="!outbound" class="image-toggler p-button-ghost" [label]="revealed ? ('ui.message_attachment.hide' | translate) : ('ui.message_attachment.reveal' | translate)" (click)="toggleReveal()"></button>
  </ng-container>
  <ng-container *ngIf="attachment.mime.includes('video')">
    <div class="video-wrap">
      <ng-container *ngIf="!revealed && !outbound">
        <div class="overlay"></div>
      </ng-container>
      <video [src]="attachment.location" class="attachment-video" [class.blur]="!revealed && !outbound" #videoElement controls (loadedmetadata)="loaded();"></video>
    </div>
    <button pButton *ngIf="!outbound" class="image-toggler p-button-ghost" [label]="revealed ? ('ui.message_attachment.hide' | translate) : ('ui.message_attachment.reveal' | translate)" (click)="toggleReveal()"></button>
  </ng-container>
  <ng-container *ngIf="attachment.mime.includes('audio')">
    <audio class="attachment-audio" controls (loadedmetadata)="loaded();">
      <source [src]="attachment.location" type="audio/mpeg">
    </audio>
  </ng-container>
  <ng-container *ngIf="attachment.mime.includes('pdf')">
    <a target="_blank" [href]="attachment.location" class="attachment-link">
      <i class="pi pi-file-pdf"></i><span class="link-text">{{ attachment.filename || ('ui.message_attachment.download_pdf' | translate) }}</span>
    </a>
  </ng-container>
  <ng-container *ngIf="!attachment.mime.includes('image') && !attachment.mime.includes('video') && !attachment.mime.includes('pdf') && !attachment.mime.includes('audio')">
    <a target="_blank" [href]="attachment.location" class="attachment-link">{{'ui.message_attachment.download_attachment' | translate}}</a>
  </ng-container>
</ng-container>
