import {ProductRequestStatus} from '../enums/product-request-status';
import {ProductRequest} from '../models/ProductRequest';
import {Practice} from '../models/Practice';
import {practiceHasFeature} from './practice-has-feature';
import {PracticeFeature} from '../enums/practice-feature';
import {ProductRequestApprovalStatus} from '../enums/product-request-approval-status';
import { PracticeConfigInterface } from '../interfaces/practice-config.interface';
import { PracticeConfig } from '../enums/practice-config';

export function getAvailableProductRequestStatusDestinations(
  productRequest: ProductRequest,
  practice: Practice | null,
  config: PracticeConfigInterface | null
): ProductRequestStatus[] {

  let destinations: ProductRequestStatus[] = [];
  switch (productRequest.status) {
    case ProductRequestStatus.AWAITING_APPROVAL:
      destinations = [ProductRequestStatus.CANCELLED];

      if (practice?.userPermissions.canAuthoriseProductRequests) {
        if (productRequest.items.find(item => item.approvalStatus !== ProductRequestApprovalStatus.REJECTED)) {
          // If there are any items that are not already rejected, we can move it to approved
          destinations.push(ProductRequestStatus.READY_TO_PAY);
        }

        if (
          !productRequest.items.find(item => item.approvalStatus === ProductRequestApprovalStatus.APPROVED) &&
          !productRequest.items.find(item => item.approvalStatus === ProductRequestApprovalStatus.NOT_REQUIRED)
        ) {
          // If there are no items already approved/not required, we can move it to rejected
          destinations.push(ProductRequestStatus.REJECTED);
        }
      } else {
        // user does not have permission to approve
        if (!productRequest.items.find(item => item.approvalStatus !== ProductRequestApprovalStatus.REJECTED)) {
          // If all items are rejected, we can move it to rejected
          destinations.push(ProductRequestStatus.REJECTED);
        }

        if (
          (
            productRequest.items.find(item => item.approvalStatus === ProductRequestApprovalStatus.APPROVED) ||
            productRequest.items.find(item => item.approvalStatus === ProductRequestApprovalStatus.NOT_REQUIRED)
          ) && !productRequest.items.find(item => item.approvalStatus === ProductRequestApprovalStatus.PENDING)
        ) {
          // If at least one item is approved or not required, and none are pending, we can move it to approved
          destinations.push(ProductRequestStatus.READY_TO_PAY);
        }
      }

      return destinations;

    case ProductRequestStatus.READY_TO_PAY:
      return [
        ProductRequestStatus.AWAITING_PAYMENT,
        ProductRequestStatus.PAID,
        ProductRequestStatus.CANCELLED,
      ];

    case ProductRequestStatus.AWAITING_PAYMENT:
      return [
        ProductRequestStatus.PAID,
        ProductRequestStatus.CANCELLED,
      ];

    case ProductRequestStatus.PAID:
      return [ProductRequestStatus.FULFILLED, ProductRequestStatus.CANCELLED];

    case ProductRequestStatus.FULFILLED:
      return [
        ProductRequestStatus.COMPLETE,
        ProductRequestStatus.CANCELLED,
      ];

    case ProductRequestStatus.COMPLETE:
      return [];

    case ProductRequestStatus.CANCELLED:
      return [];

    case ProductRequestStatus.REJECTED:
      return [];

  }

  return [];
}