import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { MessageService } from 'primeng/api';
import { ExportConversationAsPdf } from '../../../conversation/state/actions';
import { AppState } from '../../../state/reducers';

@Component({
  selector: 'toast-save-conversation-pms-error',
  templateUrl: './toast-save-conversation-pms-error.component.html',
  styleUrls: ['./toast-save-conversation-pms-error.component.scss']
})
export class ToastSaveConversationPmsErrorComponent {
  private eventCount: number = 0;
  constructor(private store: Store<AppState>, private messageService: MessageService, private gaService: GoogleAnalyticsService) {}


  downloadPdf(conversationId: number) {
    this.eventCount++;
    this.store.dispatch(ExportConversationAsPdf({conversationId: conversationId}));
    this.gaService.event('toast-save-conversation-pms-error', 'export-conversation-as-pdf', JSON.stringify({ eventCount: this.eventCount }));
    this.clearToast();
  }

  clearToast() { 
    this.messageService.clear("save-conversation-pms-error");
  }
}
