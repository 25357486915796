import {Component, Input} from '@angular/core';
import {ProductRequest} from '../../../models/ProductRequest';
import {Store} from '@ngrx/store';
import {AppState} from '../../../state/reducers';
import {ProductRequestStatus} from '../../../enums/product-request-status';
import {ConfirmProductRequestPaid} from '../../state/actions';
import {ProductRequestPaidContext} from '../../../enums/product-request-paid-context.enum';

@Component({
  selector: 'confirm-product-request-paid',
  templateUrl: './confirm-product-request-paid.component.html',
  styleUrls: ['./confirm-product-request-paid.component.scss']
})
export class ConfirmProductRequestPaidComponent {
  @Input() productRequest?: ProductRequest;
  @Input() context: ProductRequestPaidContext = ProductRequestPaidContext.PAID;
  contextOptions = ProductRequestPaidContext;
  productRequestStatus = ProductRequestStatus;
  paidConfirmed = false;
  error = false;

  constructor(private store: Store<AppState>) {
  }

  submit(): void {
    if (!this.paidConfirmed) {
      this.error = true;
      return;
    }

    if (this.productRequest) {
      this.store.dispatch(ConfirmProductRequestPaid({productRequest: this.productRequest}));
    }
  }
}
