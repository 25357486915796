import {Component, Input, OnInit} from '@angular/core';
import {MessageStatus} from '../../../enums/message-status';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../state/reducers';
import {getCurrentPractice} from '../../../practices/state/selectors';
import { takeWhile } from 'rxjs';
import { Practice } from '../../../models/Practice';
import {Theme} from "../../../enums/theme";

@Component({
  selector: 'message-status',
  templateUrl: './message-status.component.html',
  styleUrls: ['./message-status.component.scss']
})
export class MessageStatusComponent implements OnInit {
  @Input() status?: MessageStatus;
  alive = true;
  theme: string = Theme.DigitalPractice;

  constructor( private store: Store<AppState>,) { 
    this.subscribeToCurrentPractice();
  }

  ngOnInit(): void {
  }

  subscribeToCurrentPractice(): void {
    this.store
      .pipe(select(getCurrentPractice))
      .pipe(takeWhile(() => this.alive))
      .subscribe((practice) => {
        if(practice){
          this.theme = practice.theme ? practice.theme : Theme.DigitalPractice;
        }
      });
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

}
