import { Pipe, PipeTransform } from "@angular/core";

export enum TemplateConsentRequiredOption {
    REQUIRED = 'Required',
    NOT_REQUIRED = 'Not Required'
}

const enumToTranslation: Record<TemplateConsentRequiredOption, string> = {
  [TemplateConsentRequiredOption.REQUIRED]: 'enums.template_consent_required_option.required',
  [TemplateConsentRequiredOption.NOT_REQUIRED]: 'enums.template_consent_required_option.not_required',
}

@Pipe({name: 'templateConsentRequiredOptionTranslationKey'})
export class TemplateConsentRequiredOptionTranslationKeyPipe implements PipeTransform {
  transform(value: TemplateConsentRequiredOption): string {
    return enumToTranslation[value];
  }
}
