import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Observable, takeWhile } from 'rxjs';
import { ClearClient, ClearConversation, ClearCurrentConversation, ClearPatient, ClearPatientHistory, ClearStaffComments, ResetShareClientToPms, ResetSharePatientToPms } from '../../../conversation/state/actions';
import { ClearMessages } from '../../../messages/state/actions';
import { ToggleConversationPreviewEnabled } from '../../../state/actions';
import { AppState } from '../../../state/reducers';
import { isConversationPreviewEnabled } from '../../../state/selectors';

@Component({
  selector: 'split-panel-button',
  templateUrl: './split-panel-button.component.html',
  styleUrls: ['./split-panel-button.component.scss']
})
export class SplitPanelButtonComponent implements OnInit, OnDestroy {
  alive = true;
  active$: Observable<boolean>;
  active = false;
  resizeTimeout: any;
  device = 'desktop';

  constructor(private store: Store<AppState>, private gaService: GoogleAnalyticsService) {
    this.active$ = this.store.pipe(select(isConversationPreviewEnabled)).pipe(takeWhile(() => this.alive));

    this.active$.subscribe(active => this.active = active);
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.alive = false;
  }


  @HostListener('window:resize')
  handleResize(): void {
    clearTimeout(this.resizeTimeout);
    this.resizeTimeout = setTimeout(() => {
      this.device = this.getDevice();
    }, 100);
  }

  getDevice(): string {
    if (window.innerWidth <= 1600) {
      return 'mobile';
    }

    return 'desktop';
  }

  handleClick(): void {
    this.store.dispatch(ToggleConversationPreviewEnabled());

    if (this.active) {
      window.localStorage.setItem('preview-pane', '1');
      this.gaService.event('preview-pane', 'open');
    } else {
      window.localStorage.setItem('preview-pane', '0');
      this.gaService.event('preview-pane', 'closed');
    }

    if (this.active) {
      this.store.dispatch(ClearCurrentConversation());
      this.store.dispatch(ClearClient());
      this.store.dispatch(ClearPatient());
      this.store.dispatch(ClearConversation());
      this.store.dispatch(ClearStaffComments());
      this.store.dispatch(ClearMessages());
      this.store.dispatch(ResetShareClientToPms());
      this.store.dispatch(ResetSharePatientToPms());
      this.store.dispatch(ClearPatientHistory());
    }
  }
}
