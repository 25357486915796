import { Injectable } from '@angular/core';
import { ReminderDto } from '../interfaces/dto/reminder.dto';
import { Reminder } from '../models/Reminder';

@Injectable({
  providedIn: 'root'
})
export class ReminderAdapter {
  run(dto: ReminderDto): Reminder {
    return {
        ...dto,
        createdAt: dto.createdAt ? new Date(dto.createdAt) : null,
        dueDate: new Date(dto.dueDate),
    };
  }
}
