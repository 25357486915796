import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { getCurrentPractice } from '../../../practices/state/selectors';
import { takeWhile } from 'rxjs/operators';
import { AppState } from '../../../state/reducers';
import { Practice } from '../../../models/Practice';
import { getCurrencies } from '../../../state/selectors';
import { Currency } from '../../../models/Currency';
import { FormFieldPrefillOption } from '../../../enums/form-field-prefill-option.enum';
import { ClearCurrentForm, CreateForm, GetForm, UpdateForm } from '../../state/actions';
import { fieldTypeHasOptions } from '../../../helpers/field-type-has-options';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Form } from '../../interfaces/form';
import { getCurrentForm } from '../../state/selectors';
import { PageUpdateDto } from '../../interfaces/dto/page-update.dto';
import { FieldUpdateDto } from '../../interfaces/dto/field-update.dto';
import { OptionUpdateDto } from '../../interfaces/dto/option-update.dto';
import { CurrencyCode } from '../../../enums/currency-code';
import { MessageService } from 'primeng/api';
import { fieldTypeHasDefaultOption } from '../../../helpers/field-type-has-default-option';
import { fieldTypeHasDefault } from '../../../helpers/field-type-has-default';
import { FormFieldType } from '../../../enums/form-field-type.enum';
import { ContentUpdateDto } from '../../interfaces/dto/content-update.dto';
import { QuillEditorComponent, QuillModules } from 'ngx-quill';
import 'vhd-quill-mention-2';
import 'vhd-quill-emoji/dist/quill-emoji.js';

@Component({
  selector: 'app-form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.scss']
})
export class FormBuilderPage implements OnInit, OnDestroy {
  alive = true;
  practice: Practice | null = null;
  currencies: Currency[] = [];
  practiceCurrency?: Currency;
  loading = false;
  isCurrentFormLoaded = false;
  formId: number | null = null;
  currentForm: Form | null = null;
  baseForm = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
    intro: new UntypedFormControl('', [Validators.required]),
    estimatedCompletionDuration: new UntypedFormControl('', [Validators.required]),
    pages: this.formBuilder.array([]),
    takePayment: new UntypedFormControl(false),
    paymentAmount: new UntypedFormControl(''),
    paymentCurrency: new UntypedFormControl(''),
    paymentDescription: new UntypedFormControl(''),
    paymentExpiresAfter: new UntypedFormControl(3),
    paymentPreAuth: new UntypedFormControl(false),
  });
  form: UntypedFormGroup;
  formFieldType = FormFieldType;
  inputTypes = Object.values(FormFieldType);
  expiryOptions = [
    { amount: 1, label: '1 Day' },
    { amount: 3, label: '3 Days' },
    { amount: 7, label: '7 Days' },
    { amount: 14, label: '14 Days' },
    { amount: 30, label: '30 Days' },
  ];
  prefillOptions = Object.values(FormFieldPrefillOption);
  @ViewChild(QuillEditorComponent, { static: true }) editor?: QuillEditorComponent;
  quillModules: QuillModules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['link'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'script': 'super' }, { 'script': 'sub' }],
      ['clean']
    ],
  }


  constructor(
    private store: Store<AppState>,
    private formBuilder: UntypedFormBuilder,
    private titleService: Title,
    private route: ActivatedRoute,
    private messageService: MessageService
  ) {
    const title = 'Digital Practice | Form Builder';
    this.titleService.setTitle(title);
    this.form = this.baseForm;
    this.addPage();
    this.formId = this.route.snapshot.paramMap.get('id') ? Number(this.route.snapshot.paramMap.get('id')) : null;
  }

  ngOnInit(): void {
    this.subscribeToCurrentPractice();
    this.subscribeToCurrencies();
  }

  ngOnDestroy(): void {
    this.alive = false;
    this.store.dispatch(ClearCurrentForm());
  }

  subscribeToCurrentPractice(): void {
    this.store.pipe(select(getCurrentPractice)).pipe(
      takeWhile(() => this.alive)
    ).subscribe(practice => {
      this.practice = practice;
      if(!this.isCurrentFormLoaded){
        this.getCurrentForm();
      }
      this.form.controls.paymentExpiresAfter.setValue(practice ? practice.paymentLinkExpiry : 3),
      this.setPracticeCurrency();
    });
  }

  getCurrentForm(): void {
    if (this.formId && this.practice) {
      this.loading = true;
      this.store.dispatch(GetForm({ formId: this.formId }));
      this.subscribeToCurrentForm();
    }
  }

  subscribeToCurrentForm(): void {
    this.store.pipe(select(getCurrentForm)).pipe(
      takeWhile(() => this.alive)
    ).subscribe(form => {
      if (form) {
        this.currentForm = form;
        this.prefillForm();
        this.loading = false;
        this.isCurrentFormLoaded = true;
      }
    });
  }

  subscribeToCurrencies(): void {
    this.store.pipe(select(getCurrencies)).pipe(
      takeWhile(() => this.alive)
    ).subscribe(currencies => {
      this.currencies = currencies;
      this.setPracticeCurrency();
    });
  }

  setPracticeCurrency(): void {
    if (this.currencies.length && this.practice) {
      this.practiceCurrency = this.currencies.find((currency) => currency.currencyCode === this.practice?.currency);
      this.form.controls.paymentCurrency.setValue(this.practiceCurrency?.currencyCode);
    }
  }

  pages(): UntypedFormArray {
    return this.form.get('pages') as UntypedFormArray;
  }

  addPage(manual = false): void {
    const pages = this.pages();
    if (pages) {
      pages.push(this.newPage());
      this.addField(pages.length - 1, manual);
      this.addContent(pages.length - 1, manual);
    }
  }

  newPage(): UntypedFormGroup {
    return this.formBuilder.group({
      id: new UntypedFormControl(null),
      heading: new UntypedFormControl('', Validators.required),
      intro: new UntypedFormControl('', Validators.required),
      fields: this.formBuilder.array([]),
      contents: this.formBuilder.array([])
    });
  }

  removePage(index: number): void {
    const pages = this.pages();
    if (pages) {
      pages.removeAt(index);
    }
  }

  fields(pageIndex: number): UntypedFormArray {
    return this.pages().at(pageIndex).get('fields') as UntypedFormArray;
  }

  contents(pageIndex: number): UntypedFormArray {
    return this.pages().at(pageIndex).get('contents') as UntypedFormArray;
  }

  addField(pageIndex: number, manual = false): void {
    const fields = this.fields(pageIndex);
    if (fields) {
      fields.push(this.newField());
      this.addOption(pageIndex, fields.length - 1);
    }

    if (manual) {
      this.touchAllFields();
    }
  }

  addContent(pageIndex: number, manual = false): void {
    const contents = this.contents(pageIndex);
    if (contents) {
      contents.push(this.newContent());
    }

    if (manual) {
      this.touchAllFields();
    }
  }

  newField(): UntypedFormGroup {
    return this.formBuilder.group({
      id: new UntypedFormControl(null),
      type: new UntypedFormControl(FormFieldType.TEXT, Validators.required),
      label: new UntypedFormControl('', Validators.required),
      url: new UntypedFormControl(''),
      description: new UntypedFormControl(''),
      prefill: new UntypedFormControl(false),
      prefillWith: new UntypedFormControl(FormFieldPrefillOption.CLIENT_NAME),
      readonly: new UntypedFormControl(false),
      sensitive: new UntypedFormControl(false),
      placeholder: new UntypedFormControl(''),
      default: new UntypedFormControl(''),
      required: new UntypedFormControl(true),
      options: this.formBuilder.array([]),
    });
  }

  newContent(): UntypedFormGroup {
    return this.formBuilder.group({
      id: new UntypedFormControl(null),
      content: new UntypedFormControl(''),
    });
  }

  removeField(pageIndex: number, index: number): void {
    const fields = this.fields(pageIndex);
    if (fields) {
      fields.removeAt(index);
    }
  }

  removeContent(pageIndex: number, index: number): void {
    const contents = this.contents(pageIndex);
    if (contents) {
      contents.removeAt(index);
    }
  }

  options(pageIndex: number, fieldIndex: number): UntypedFormArray {
    return this.fields(pageIndex).at(fieldIndex).get('options') as UntypedFormArray;
  }

  addOption(pageIndex: number, fieldIndex: number): void {
    const options = this.options(pageIndex, fieldIndex);
    if (options) {
      options.push(this.newOption(pageIndex, fieldIndex, options.length - 1));

      setTimeout(() => {
        this.updateFieldOptionValidators(pageIndex, fieldIndex);
      }, 0);
    }
  }

  newOption(pageIndex: number, fieldIndex: number, optionIndex: number): UntypedFormGroup {
    return this.formBuilder.group({
      id: new UntypedFormControl(null),
      label: new UntypedFormControl(''),
      value: new UntypedFormControl(''),
    });
  }

  removeOption(pageIndex: number, fieldIndex: number, index: number): void {
    const options = this.options(pageIndex, fieldIndex);
    if (options) {
      options.removeAt(index);
    }
  }

  submit(): void {
    this.touchAllFields();

    if (!this.form.valid) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Some fields are invalid, please ensure you have set up this form correctly.',
        life: 5000
      });
    }

    const pages = this.pages();
    if (this.form.valid && this.practice) {
      const formPages: PageUpdateDto[] = Object.keys(pages.controls).map((pagekey, pageIndex) => {
        const page = pages.get(pagekey) as UntypedFormArray;

        const pageFields: FieldUpdateDto[] = [];
        const fields = this.fields(pageIndex);
        Object.keys(fields.controls).forEach((fieldString, fieldIndex) => {
          const fieldObject = fields.get(fieldString);
          if (fieldObject) {
            const fieldOptions: OptionUpdateDto[] = [];

            if (fieldTypeHasOptions(fieldObject.get('type')?.value)) {
              const options = this.options(pageIndex, fieldIndex);
              Object.keys(options.controls).forEach((option, optionIndex) => {
                const optionObject = options.get(option);
                if (optionObject) {
                  fieldOptions.push({
                    id: optionObject.get('id')?.value || undefined,
                    order: optionIndex,
                    label: optionObject.get('label')?.value,
                    value: optionObject.get('value')?.value,
                    default:
                      fieldTypeHasDefaultOption(fieldObject.get('type')?.value) ?
                        optionIndex === Number(fieldObject.get('default')?.value) :
                        false,
                  });
                }
              });
            }
            pageFields.push({
              id: fieldObject.get('id')?.value || undefined,
              default:
                fieldTypeHasDefault(fieldObject.get('type')?.value) ?
                  fieldObject.get('default')?.value :
                  undefined,
              label: fieldObject.get('label')?.value || '',
              url: fieldObject.get('url')?.value || '',
              description: fieldObject.get('description')?.value || '',
              order: fieldIndex,
              prefill: fieldObject.get('type')?.value === FormFieldType.HIDDEN ? true : fieldObject.get('prefill')?.value,
              prefillWith: fieldObject.get('prefillWith')?.value,
              placeholder: fieldObject.get('placeholder')?.value || '',
              readonly: fieldObject.get('readonly')?.value,
              required: fieldObject.get('required')?.value,
              sensitive: fieldObject.get('sensitive')?.value,
              type: fieldObject.get('type')?.value || FormFieldType.TEXT,
              options: fieldOptions,
            });
          }
        });

        const contentsArray: ContentUpdateDto[] = [];
        const contents = this.contents(pageIndex);
        Object.keys(contents.controls).forEach((contentString, contentIndex) => {
          const contentObject = contents.get(contentString);

          if (contentObject?.get("content")) {
            contentsArray.push({
              id: contentObject.get('id')?.value || undefined,
              content: contentObject.get('content')?.value || '',
              order: contentIndex,
            });
          }
        });

        return {
          id: page.get('id')?.value || undefined,
          heading: page.get('heading')?.value,
          intro: page.get('intro')?.value,
          order: pageIndex,
          fields: pageFields,
          contents: contentsArray,
        };
      });


      this.loading = true;

      if (this.formId) {
        this.store.dispatch(UpdateForm({
          dto: {
            id: Number(this.formId),
            name: this.form.controls.name?.value || '',
            intro: this.form.controls.intro?.value || '',
            estimatedCompletionDuration: this.form.controls.estimatedCompletionDuration?.value || '',
            practiceId: Number(this.practice.coreId),
            takePayment: this.form.controls.takePayment?.value || false,
            paymentAmount: this.form.controls.paymentAmount?.value || 0,
            paymentCurrency: this.form.controls.paymentCurrency?.value as CurrencyCode,
            paymentDescription: this.form.controls.paymentDescription?.value || '',
            paymentExpiresAfter: this.form.controls.paymentExpiresAfter?.value || '',
            paymentPreAuth: this.form.controls.paymentPreAuth?.value || false,
            pages: formPages,
          }
        }));
      } else {
        this.store.dispatch(CreateForm({
          dto: {
            name: this.form.controls.name?.value || '',
            intro: this.form.controls.intro?.value || '',
            estimatedCompletionDuration: this.form.controls.estimatedCompletionDuration?.value || '',
            practiceId: Number(this.practice.coreId),
            takePayment: this.form.controls.takePayment?.value || false,
            paymentAmount: this.form.controls.paymentAmount?.value || 0,
            paymentCurrency: this.form.controls.paymentCurrency?.value as CurrencyCode,
            paymentDescription: this.form.controls.paymentDescription?.value || '',
            paymentExpiresAfter: this.form.controls.paymentExpiresAfter?.value || '',
            paymentPreAuth: this.form.controls.paymentPreAuth?.value || false,
            pages: formPages,
          }
        }));
      }
    }
  }

  isValidPaymentAmount(): boolean {
    let isValidFormat = false;
    if (this.form.get('paymentAmount')?.value) {
      const regex = new RegExp(/^\d+\.?\d{0,2}$/, 'g');
      isValidFormat = regex.test(this.form.get('paymentAmount')?.value.toString());
    }

    return isValidFormat;
  }

  paymentAmountValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!this.form) {
        return null;
      }

      if (this.form.get('takePayment')?.value === false) {
        return null;
      }

      const amountErrors = [];

      if (!this.form.get('paymentAmount')?.value) {
        amountErrors.push('Amount requested is a required field');
      }

      if (this.form.get('paymentAmount') && this.form.get('paymentAmount')?.value && this.practiceCurrency) {
        if (this.form.get('paymentAmount')?.value > 999999.99) {
          amountErrors.push(`Amount requested cannot be more than ${this.practiceCurrency.currencySymbol}999,999.99`);
        }

        if (this.form.get('paymentAmount')?.value < (this.practiceCurrency.minimumCharge || 1)) {
          amountErrors.push(`Amount requested cannot be less than ${this.practiceCurrency.currencySymbol}${this.practiceCurrency.minimumCharge}`);
        }

        if (!this.isValidPaymentAmount()) {
          amountErrors.push('Amount requested format is invalid');
        }
      }

      return amountErrors.length > 0 ? amountErrors.map((err) => ({ message: err })) : null;
    };
  }

  takePaymentToggled(): void {
    setTimeout(() => {
      if (this.form.controls.takePayment.value === true) {
        this.form.controls.paymentAmount.setValidators([this.paymentAmountValidator()]);
        this.form.controls.paymentDescription.setValidators([Validators.required]);
      } else {
        this.form.controls.paymentAmount.setValidators(null);
        this.form.controls.paymentDescription.setValidators(null);
      }

      this.form.controls.paymentAmount.updateValueAndValidity();
      this.form.controls.paymentDescription.updateValueAndValidity();
    }, 0);
  }

  prefillForm(): void {
    if (this.currentForm) {
      this.form = this.baseForm;

      this.form.controls.name.setValue(this.currentForm.name);
      this.form.controls.intro.setValue(this.currentForm.intro);
      this.form.controls.estimatedCompletionDuration.setValue(this.currentForm.estimatedCompletionDuration);
      this.form.controls.takePayment.setValue(this.currentForm.takePayment);
      this.form.controls.paymentAmount.setValue(this.currentForm.paymentAmount);
      this.form.controls.paymentCurrency.setValue(this.currentForm.paymentCurrency);
      this.form.controls.paymentDescription.setValue(this.currentForm.paymentDescription);
      this.form.controls.paymentExpiresAfter.setValue(this.currentForm.paymentExpiresAfter);
      this.form.controls.paymentPreAuth.setValue(this.currentForm.paymentPreAuth);

      this.currentForm.formPages?.forEach((formPage, pageIndex) => {
        if (pageIndex > 0) {
          this.addPage();
        }

        const currentPage = this.pages().at(pageIndex);
        currentPage.get('id')?.setValue(formPage.id);
        currentPage.get('heading')?.setValue(formPage.heading);
        currentPage.get('intro')?.setValue(formPage.intro);

        formPage.pageFields?.forEach((field, fieldIndex) => {
          if (fieldIndex > 0) {
            this.addField(pageIndex);
          }

          let fieldDefault = field.default;
          if (
            (field.type === FormFieldType.CHECKBOX || field.type === FormFieldType.AGREE_TO_TERMS) &&
            field.default === 'true'
          ) {
            fieldDefault = true;
          }

          const currentField = this.fields(pageIndex).at(fieldIndex);
          currentField.get('id')?.setValue(field.id);
          currentField.get('type')?.setValue(field.type);
          currentField.get('label')?.setValue(field.label);
          currentField.get('url')?.setValue(field.url);
          currentField.get('description')?.setValue(field.description);
          currentField.get('prefill')?.setValue(field.prefill);
          currentField.get('prefillWith')?.setValue(field.prefillWith);
          currentField.get('readonly')?.setValue(field.readonly);
          currentField.get('sensitive')?.setValue(field.sensitive);
          currentField.get('placeholder')?.setValue(field.placeholder);
          currentField.get('default')?.setValue(fieldDefault);
          currentField.get('required')?.setValue(field.required);

          if(field.type){
            currentField.get('type')?.disable()
          }

          field.fieldOptions?.forEach((option, optionIndex) => {
            if (optionIndex > 0) {
              this.addOption(pageIndex, fieldIndex);
            }

            const currentOption = this.options(pageIndex, fieldIndex).at(optionIndex);
            currentOption.get('id')?.setValue(option.id);
            currentOption.get('label')?.setValue(option.label);
            currentOption.get('value')?.setValue(option.value);

            if (field.defaultOptionId && option.id === field.defaultOptionId) {
              currentField.get('default')?.setValue(optionIndex);
            }
          });
        });

        formPage.pageContents?.forEach((pageContent, contentIndex) => {
          if (contentIndex > 0) {
            this.addContent(pageIndex);
          }

          const currentContent = this.contents(pageIndex).at(contentIndex);
          currentContent.get('id')?.setValue(pageContent.id);
          currentContent.get('content')?.setValue(pageContent.content);
        });
      });
    }
  }

  typeChanged(pageIndex: number, fieldIndex: number): void {
    this.fields(pageIndex).at(fieldIndex).get('description')?.reset()
    setTimeout(() => {
      this.updateFieldOptionValidators(pageIndex, fieldIndex);
    }, 0);
  }

  updateFieldOptionValidators(pageIndex: number, fieldIndex: number): void {
    const field = this.fields(pageIndex).at(fieldIndex);

    if (fieldTypeHasOptions(field.get('type')?.value || '')) {
      for (const control of this.options(pageIndex, fieldIndex).controls) {
        control.get('value')?.setValidators([Validators.required]);
        control.get('value')?.updateValueAndValidity();
        control.get('label')?.setValidators([Validators.required]);
        control.get('label')?.updateValueAndValidity();
      }
    } else {
      for (const control of this.options(pageIndex, fieldIndex).controls) {
        control.get('value')?.setValidators(null);
        control.get('value')?.updateValueAndValidity();
        control.get('label')?.setValidators(null);
        control.get('label')?.updateValueAndValidity();
      }
    }
  }

  private touchAllFields(): void {
    Object.keys(this.form.controls).forEach(field => {
      const control = this.form.get(field);
      control?.markAsTouched({ onlySelf: true });
    });

    const pages = this.pages();
    Object.keys(pages.controls).forEach((pageKey, pageIndex) => {
      const page = pages.get(pageKey) as UntypedFormArray;
      if (page) {
        Object.keys(page.controls).forEach(pageField => {
          page.get(pageField)?.markAsTouched({ onlySelf: true });
        });

        const fields = this.fields(pageIndex);
        Object.keys(fields.controls).forEach((fieldString, fieldIndex) => {
          const fieldObject = fields.get(fieldString) as UntypedFormArray;
          if (fieldObject) {
            Object.keys(fieldObject.controls).forEach(subField => {
              fieldObject.get(subField)?.markAsTouched({ onlySelf: true });
            });

            const options = this.options(pageIndex, fieldIndex);
            Object.keys(options.controls).forEach((option) => {
              const optionObject = options.get(option) as UntypedFormArray;
              if (optionObject) {
                Object.keys(optionObject.controls).forEach(subOption => {
                  optionObject.get(subOption)?.markAsTouched({ onlySelf: true });
                });
              }
            });
          }
        });

        const contents = this.contents(pageIndex);
        Object.keys(contents.controls).forEach((contentString) => {
          const contentObject = contents.get(contentString) as UntypedFormArray;
          if (contentObject) {
            Object.keys(contentObject.controls).forEach(subContent => {
              contentObject.get(subContent)?.markAsTouched({ onlySelf: true });
            });
          }
        });
      }
    });
  }
}
