import { Payment } from '../models/Payment';
import {PaymentType} from '../enums/payment-type';

export function canPaymentBeCompleted(payment: Payment): boolean {
    if (payment.type === PaymentType.ONLINE) {
      return false;
    }

    if (payment.cancelledAt) {
        return false;
    }

    if (payment.syncedToPmsAt) {
        return false;
    }

    if (!payment.paidAt) {
        return false;
    }

    return true;

}
