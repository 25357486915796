import { SortBy } from "../enums/sort-by";
import { SortDirection } from "../enums/sort-direction";
import { SortByOption } from "../interfaces/sort-by-option.interface";

export const productRequestSortByOptions: SortByOption[] = [
  {
    label: 'Newest first', 
    sortBy: SortBy.CREATED_AT, 
    sortDir: SortDirection.DESC
  },
  {
    label: 'Oldest first', 
    sortBy: SortBy.CREATED_AT, 
    sortDir: SortDirection.ASC
  }
];