import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClientAdapter } from '../adapters/client.adapter';
import { PatientAdapter } from '../adapters/patient.adapter';
import { ClientWithPatientsDto } from '../interfaces/dto/client-with-patients.dto';
import { ClientDto } from '../interfaces/dto/client.dto';
import { Client } from '../models/Client';
import { Patient } from '../models/Patient';
import { Practice } from '../models/Practice';
import { EnvironmentService } from '../services/environment.service';
import {FullPayment} from '../models/FullPayment';
import {FullPaymentDto} from '../interfaces/dto/full-payment.dto';
import {FullPaymentAdapter} from '../adapters/full-payment.adapter';
import {ProductRequest} from '../models/ProductRequest';
import {ProductRequestDto} from '../interfaces/dto/product-request.dto';
import {ProductRequestAdapter} from '../adapters/product-request.adapter';
import {ClientInsightsDto} from "../interfaces/dto/client-insights.dto";
import {ClientInsights} from "../models/ClientInsights";
import {ClientInsightAdapter} from "../adapters/client-insight.adapter";
import { FilterSelection } from '../interfaces/filter-selection.interface';
import { SortByOption } from '../interfaces/sort-by-option.interface';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(
    private environmentService: EnvironmentService,
    private http: HttpClient,
    private clientAdapter: ClientAdapter,
    private clientInsightAdapter: ClientInsightAdapter,
    private patientAdapter: PatientAdapter,
    private fullPaymentAdapter: FullPaymentAdapter,
    private productRequestAdapter: ProductRequestAdapter,
  ) { }

  getClient(clientId: string, patientPage: number = 1): Observable<{client: Client, patients: Patient[]}> {
    const url = this.environmentService.get('backendUrl') + `/clients/${clientId}?patientPage=${patientPage}`;
    return this.http.get<ClientWithPatientsDto>(url, {withCredentials: true}).pipe(
      map((response: ClientWithPatientsDto) => {
        return {
          client: this.clientAdapter.run(response),
          patients: response.patients ? response.patients.map(patient => this.patientAdapter.run(patient)) : []
        };
      })
    );
  }

  getClientByPmsId(clientPmsId: string, practiceId: string, patientPage: number = 1): Observable<{client: Client, patients: Patient[]}> {
    const url = this.environmentService.get('backendUrl') + `/clients/pmsId/${clientPmsId}?patientPage=${patientPage}&practiceId=${practiceId}`;
    return this.http.get<ClientWithPatientsDto>(url, {withCredentials: true}).pipe(
      map((response: ClientWithPatientsDto) => {
        return {
          client: this.clientAdapter.run(response),
          patients: response.patients ? response.patients.map(patient => this.patientAdapter.run(patient)) : []
        };
      })
    );
  }

  allClients(practice: Practice, search?: string, limit?: number): Observable<Client[]> {
    let url = this.environmentService.get('backendUrl') + `/practices/${practice.id}/clients-local?`;

    if (search) {
      url += `&s=${search}`;
    }

    if (limit) {
      url += `&limit=${limit}`;
    }

    return this.http.get<ClientDto[]>(url, {withCredentials: true}).pipe(
      map((response: ClientDto[]) => {
        return response.map(client => this.clientAdapter.run(client));
      })
    );
  }

  findClients(searchString: string, practiceId: string): Observable<Client[]> {
    const url = this.environmentService.get('backendUrl') + `/practices/${practiceId}/clients`;
    return this.http.get<ClientDto[]>(url, {params: {
      search: searchString
    }, withCredentials: true}).pipe(
      map((response: ClientDto[]) => {
        return response.map(client => this.clientAdapter.run(client));
      })
    );
  }

  shareToPms(client: Client): Observable<boolean> {
    const url = this.environmentService.get('backendUrl') + `/clients/${client.id}/share-to-pms`;

    return this.http.get<{success: boolean}>(url, {withCredentials: true}).pipe(
      map((response: {success: boolean}) => {
        return response.success;
      })
    );
  }

  shareToPmsWithPmsId(practiceId: string, clientPmsId: string, patientPmsId: string): Observable<boolean> {
    const url = this.environmentService.get('backendUrl') + `/practices/${practiceId}/share-by-pms-id/${clientPmsId}/${patientPmsId}`;

    return this.http.get<{success: boolean}>(url, {withCredentials: true}).pipe(
      map((response: {success: boolean}) => {
        return response.success;
      })
    );
  }

  getClientPaymentHistory(clientId: string): Observable<FullPayment[]> {
    const url = this.environmentService.get('backendUrl') + `/clients/${clientId}/payment-history`;
    return this.http.get<FullPaymentDto[]>(url, {withCredentials: true}).pipe(
      map((response: FullPaymentDto[]) => {
        return response.map(payment => this.fullPaymentAdapter.run(payment));
      })
    );
  }

  getProductRequestHistory(client: Client): Observable<ProductRequest[]> {
    const url = this.environmentService.get('backendUrl') + `/clients/${client.id}/product-request-history`;
    return this.http.get<ProductRequestDto[]>(url, {withCredentials: true}).pipe(
      map((response: ProductRequestDto[]) => {
        return response.map(productRequest => this.productRequestAdapter.run(productRequest));
      })
    );
  }

  getInsights(clientId: string, practice: Practice | null): Observable<ClientInsights> {
    if (!practice) {
      throw new Error('Practice not set');
    }

    const url = this.environmentService.get('backendUrl') + `/clients/${clientId}/insights?practiceId=${practice.id}`;
    return this.http.get<ClientInsightsDto>(url, {withCredentials: true}).pipe(
      map((response: ClientInsightsDto) => {
        return this.clientInsightAdapter.run(response);
      })
    );
  }

  addManualDeposit(client: ClientInsights, practice: Practice | null): Observable<ClientInsights> {
    if (!practice) {
      throw new Error('Practice not set');
    }

    const url = this.environmentService.get('backendUrl') + `/clients/${client.id}/add-manual-deposit?practiceId=${practice.id}`;
    return this.http.get<ClientInsightsDto>(url, {withCredentials: true}).pipe(
      map((response: ClientInsightsDto) => {
        return this.clientInsightAdapter.run(response);
      })
    );
  }

  takeDeposit(client: ClientInsights, practice: Practice | null): Observable<ClientInsights> {
    if (!practice) {
      throw new Error('Practice not set');
    }

    const url = this.environmentService.get('backendUrl') + `/clients/${client.id}/take-deposit?practiceId=${practice.id}`;
    return this.http.get<ClientInsightsDto>(url, {withCredentials: true}).pipe(
      map((response: ClientInsightsDto) => {
        return this.clientInsightAdapter.run(response);
      })
    );
  }

  refundDeposit(client: ClientInsights, practice: Practice | null): Observable<ClientInsights> {
    if (!practice) {
      throw new Error('Practice not set');
    }

    if (!client.depositPayment) {
      throw new Error('No deposit on this clients account');
    }

    const url = this.environmentService.get('backendUrl') + `/clients/${client.id}/refund-deposit?practiceId=${practice.id}`;
    return this.http.post<ClientInsightsDto>(url, {
      paymentId: client.depositPayment.id
    }, {withCredentials: true}).pipe(
      map((response: ClientInsightsDto) => {
        return this.clientInsightAdapter.run(response);
      })
    );
  }

  createNewClientDeposit(client: Client, practice: Practice | null): Observable<{ client: Client, depositAlreadyExists: boolean }> {
    if (!practice) {
      throw new Error('Practice not set');
    }

    const url = this.environmentService.get('backendUrl') + `/clients/create-new-deposit?practiceId=${practice.id}`;
    return this.http.post<{ client: ClientDto, depositAlreadyExists: boolean }>(url, {
      clientPmsId: client.pmsId.toString(),
      clientTitle: client.title,
      clientFirstName: client.firstName,
      clientLastName: client.lastName,
    }, {withCredentials: true}).pipe(
      map((response: { client: ClientDto, depositAlreadyExists: boolean }) => {
        return {
          client: this.clientAdapter.run(response.client),
          depositAlreadyExists: response.depositAlreadyExists
        };
      })
    );
  }

  getClientListClients(practiceId: string, searchString: string | null, filters: FilterSelection, sortBy: SortByOption, page: number): Observable<{
    items: Client[],
    total: number
  }> {
    const url = this.environmentService.get('backendUrl') + `/clients/list`;
    return this.http.post<{
      items: ClientDto[],
      total: number
    }>(url, {
      searchString,
      filters,
      sortBy,
      page,
      perPage: this.environmentService.get('clientsPerPage')
    },
    {
      params: {
        practiceId
      },
      withCredentials: true
    }).pipe(
      map((response: {
        items: ClientDto[],
        total: number
      }) => {
        return {
          items: response.items.map(item => this.clientAdapter.run(item)),
          total: response.total
        };
      })
    );
  }
}
