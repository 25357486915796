export enum DateFilterShorthand {
  ANYTIME = 'any',
  ONE_DAY = '1d',
  THREE_DAYS = '3d',
  SEVEN_DAYS = '7d',
  FOURTEEN_DAYS = '14d',
  ONE_MONTH = '1m',
  TWO_MONTHS = '2m',
  SIX_MONTHS = '6m',
  ONE_YEAR = '1y',
  CUSTOM = 'custom',
}
