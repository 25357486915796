import * as actions from './actions';
import { Action, createReducer, on } from '@ngrx/store';
import { User } from '../../models/User';

export interface ViewersState {
  viewers: User[];
}

export const initialState: ViewersState = {
  viewers: [],
};

const viewersReducer = createReducer(
  initialState,
  on(actions.GetViewersSuccess, (state, payload) => {
    return {
      ...state,
      viewers: payload.viewers,
    };
  }),
  on(actions.ClearViewers, (state) => {
    return {
      ...state,
      viewers: [],
    };
  })
);

export function reducer(
  state: ViewersState | undefined,
  action: Action
): ViewersState {
  return viewersReducer(state, action);
}
