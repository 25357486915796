import {Action, createReducer, on} from '@ngrx/store';
import {DashboardStats} from '../../interfaces/dashboard-stats.interface';
import * as actions from './actions';
import {StatsGroupBy} from "../../enums/stats-group-by";

export interface DashboardState {
  loading: boolean;
  stats: DashboardStats;
}

export const initialState: DashboardState = {
  loading: true,
  stats: {
    uniqueClientsCumulative: [],
    newConversationsCumulative: [],
    resolvedConversationsCumulative: [],
    paymentVolumeCumulative: [],
    paymentsCumulative: [],
    paymentCount7Days: 0,
    paymentVolume7Days: 0,
    avgResponseTime: 0,
    chartGroupBy: StatsGroupBy.month,
  },
};

const dashboardReducer = createReducer(
  initialState,
  on(actions.GetDashboardStats, (state, payload) => ({
    ...state,
    loading: true,
  })),
  on(actions.GetDashboardStatsSuccess, (state, payload) => ({
    ...state,
    loading: false,
    stats: payload.stats
  })),
);

export function reducer(state: DashboardState | undefined, action: Action): DashboardState {
  return dashboardReducer(state, action);
}
