import {CurrencyCode} from '../enums/currency-code';
import {CurrencyPipe} from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

export function getClientBalance(
  currencyPipe: CurrencyPipe,
  value: string,
  currencyCode: CurrencyCode = CurrencyCode.GBP,
  currencySymbol: string = '£',
  translateService: TranslateService
): string {
    const amount = Number(value);

    if (amount > 0) {
      return translateService.instant('helpers.get_client_balance.in_debt', {amount: currencyPipe.transform(value, currencyCode, 'symbol-narrow')});
    }

    if (amount < 0) {
      return translateService.instant('helpers.get_client_balance.in_credit', {
        amount: currencyPipe.transform(value.replace('-', ''), currencyCode, 'symbol-narrow')
      });
    }

    return currencySymbol + '0.00';
}
