import { Component } from '@angular/core';
import {select, Store} from '@ngrx/store';
import {getCurrentPractice} from '../../../practices/state/selectors';
import {takeWhile} from 'rxjs/operators';
import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {Practice} from '../../../models/Practice';
import {Observable} from 'rxjs';
import {SearchForms} from '../../state/actions';
import {getForms} from '../../state/selectors';
import {Form} from '../../interfaces/form';

@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss']
})
export class FormsPage {
  alive = true;
  practice$?: Observable<Practice | null>;
  practice?: Practice;
  forms$?: Observable<Form[]>;
  loading  = false;
  search = '';

  constructor(
    private store: Store,
    private router: Router,
    private titleService: Title,
  ) {
    this.subscribeToCurrentPractice();
    this.subscribeToForms();

    const title = 'Digital Practice | Forms';
    this.titleService.setTitle(title);
  }

  subscribeToCurrentPractice(): void {
    this.practice$ = this.store.pipe(select(getCurrentPractice)).pipe(
      takeWhile(() => this.alive)
    );

    this.practice$.subscribe((practice) => {
      if (practice) {
        this.practice = practice;
        this.store.dispatch(SearchForms({search: this.search}));
      }
    });
  }

  subscribeToForms(): void {
    this.forms$ = this.store.pipe(select(getForms)).pipe(
      takeWhile(() => this.alive)
    );
  }

  newForm(): void {
    this.router.navigateByUrl('forms/build');
  }
}
