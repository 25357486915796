<ng-container *ngIf="client">
    <div class="client-details">
        <div class="top-row">
            <div class="name">
                <img class="icon" src="assets/images/icons/icon-client.svg" alt="">
                <div class="client" (click)="handleClientClick(client.id)">
                  <div class="text">{{ client.title }} {{ client.firstName }} {{ client.lastName }}</div>
                  <ng-container *ngIf="this.clientInsight">
                    <i class="pi pi-external-link" tooltipPosition="right" [pTooltip]="'ui.client_details.view_client' | translate"></i>
                  </ng-container>
                </div>
            </div>
            <div class="email">
                <ng-container *ngIf="client.email">
                    <a href="mailto:{{ client.email }}">{{ client.email }}</a>
                </ng-container>
            </div>
        </div>

        <div class="phone-row">
            <ng-container *ngIf="!client.incomplete">
                <div class="col">
                    <ng-container *ngIf="client.phone">
                        <div class="phone">{{ client.phone }}</div>
                    </ng-container>
                </div>
                <ng-container *ngIf="!changeDisabled">
                    <div class="col">
                        <button
                            [disabled]="disabled"
                            pButton
                            [label]="'ui.client_details.change_client' | translate"
                            class="p-button-fourth p-button-sm change-client-btn"
                            (click)="changeClient()"
                        ></button>
                    </div>
                </ng-container>
                <div class="col">
                    <ng-container *ngIf="clientSite">
                        <div class="site">{{ clientSite }}</div>
                    </ng-container>
                </div>
            </ng-container>
            <ng-container *ngIf="client.incomplete">
                <ng-container *ngIf="!changeDisabled">
                    <div class="col">
                        <button
                            [disabled]="disabled"
                            pButton
                            [label]="'ui.client_details.change_client' | translate"
                            class="p-button-fourth p-button-sm change-client-btn"
                            (click)="changeClient()"
                        ></button>
                    </div>
                </ng-container>
            </ng-container>
        </div>

        <div class="details-row">
            <ng-container *ngIf="!client.incomplete">
                <div class="detail">
                    <div class="label">{{'ui.client_details.client_id' | translate}}</div>
                    <div class="value">
                        {{ client.pmsDisplayId ?? client.pmsId }} <copy-to-clipboard [text]="client.pmsDisplayId ?? client.pmsId"></copy-to-clipboard>
                    </div>
                </div>
                <div class="detail">
                    <div class="label">{{'ui.client_details.next_appointment' | translate}}</div>
                    <div class="value">
                        {{ (client.nextAppointmentAt | date:'dd/MM/yy') || '-'
                        }}
                    </div>
                </div>
                <ng-container *ngIf="balanceEnabled">
                    <div class="detail">
                        <div class="label">{{'ui.client_details.client_balance' | translate}}</div>
                        <div
                            class="value"
                            [class.red]="client.accountStatus && (client.accountStatus | parseInt) > 0"
                        >
                            {{ client.accountStatus ? (client.accountStatus |
                            clientBalance : practiceCurrency?.currencyCode :
                            practiceCurrency?.currencySymbol) : '-' }}
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="accountTypeEnabled">
                    <div class="detail">
                        <div class="label">{{'ui.client_details.account_type' | translate}}</div>
                        <div class="value">{{ client.accountType || '-' }}</div>
                    </div>
                </ng-container>
                <ng-container *ngIf="lastTxAmountEnabled">
                    <div class="detail">
                        <div class="label">{{'ui.client_details.last_transaction_amount' | translate}}</div>
                        <div class="value">
                            {{ (client.lastTransactionAmount | currency :
                            practiceCurrency?.currencyCode : 'symbol-narrow') ||
                            '-' }}
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="lastTxDateEnabled">
                    <div class="detail">
                        <div class="label">{{'ui.client_details.last_transaction_date' | translate}}</div>
                        <div class="value">
                            {{ (client.lastTransactionAt | date:'dd/MM/yy') ||
                            '-' }}
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="depositHeldEnabled">
                    <div class="detail">
                        <div class="label">{{'ui.client_details.deposit_held' | translate}}</div>
                        <div class="value">
                            {{ client.depositHeld ? ('ui.client_details.yes' | translate) : ('ui.client_details.no' | translate) }}
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="client.incomplete">
                <div class="error">
                    {{'ui.client_details.error' | translate}}
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>
