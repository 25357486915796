import {Component, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {getCurrentFormSubmission} from '../../state/selectors';
import {takeWhile} from 'rxjs/operators';
import { FormSubmission } from '../../interfaces/form-submission';
import {GetFormSubmission} from '../../state/actions';
import {getCurrentPractice} from "../../../practices/state/selectors";
import {Practice} from "../../../models/Practice";
import {paymentStatus} from "../../../constants/payment-statuses.constants";
import {getCurrencies} from "../../../state/selectors";
import {Currency} from "../../../models/Currency";
import { FormSubmissionData } from '../../interfaces/form-submission-data';
import { FormPage } from '../../interfaces/form-page';
import { PageField } from '../../interfaces/page-field';

@Component({
  selector: 'app-form-submission',
  templateUrl: './form-submission.component.html',
  styleUrls: ['./form-submission.component.scss']
})
export class FormSubmissionPage implements OnInit, OnDestroy {
  alive = true;
  formSubmission: FormSubmission | null = null;
  uuid: string | null = null;
  practice: Practice | null = null;
  currencies: Currency[] = [];
  practiceCurrency?: Currency;

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private titleService: Title,
  ) {
    const title = 'Digital Practice | Form Submission';
    this.titleService.setTitle(title);

    this.uuid = this.route.snapshot.paramMap.get('uuid');
    this.getSub();
  }

  ngOnInit(): void {
    this.subscribeToCurrentPractice();
    this.subscribeToCurrencies();
    this.subscribeToFormSubmissions();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  subscribeToCurrentPractice(): void {
    this.store.pipe(select(getCurrentPractice)).pipe(
      takeWhile(() => this.alive)
    ).subscribe((practice) => {
      if (practice) {
        this.practice = practice;
        this.getSub();
        this.setPracticeCurrency();
      }
    });
  }

  subscribeToCurrencies(): void {
    this.store.pipe(select(getCurrencies)).pipe(
      takeWhile(() => this.alive)
    ).subscribe(currencies => {
      this.currencies = currencies;
      this.setPracticeCurrency();
    });
  }

  setPracticeCurrency(): void {
    if (this.currencies.length > 0 && this.practice) {
      this.practiceCurrency = this.currencies.find((currency) => currency.currencyCode === this.practice?.currency);
    }
  }

  subscribeToFormSubmissions(): void {
    this.store.pipe(select(getCurrentFormSubmission)).pipe(
      takeWhile(() => this.alive)
    ).subscribe((submission) => {
      if (submission) {
        const submissionClone = JSON.parse(JSON.stringify(submission));

        if(submissionClone.completedAt){
          const availableFormIds = submissionClone.formSubmissionData.map((formSubmission: FormSubmissionData)=>formSubmission.fieldId.toString());

          submissionClone.form.formPages = submissionClone.form.formPages?.filter((formPage: FormPage)=>{
            let isFieldExists = false;
            formPage.pageFields?.forEach((pageField)=>{
              if(availableFormIds.includes(pageField.id.toString())){
                isFieldExists = true;
              }
            })
            return isFieldExists;
          }).map((formPage: FormPage)=>{
            formPage.pageFields = formPage.pageFields?.filter((pageField: PageField)=> !(pageField.deletedAt && !availableFormIds.includes(pageField.id.toString())) );
            return formPage;
          });
        }
        this.formSubmission = submissionClone;
      }
    });
  }

  getSub(): void {
    if (this.uuid && this.practice) {
      this.store.dispatch(GetFormSubmission({uuid: this.uuid}));
    }
  }
}
