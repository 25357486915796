import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../../models/User';

@Component({
  selector: 'top-bar-message',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit, OnChanges {
  @Input() viewers?: User[];
  @Input() type?: string;
  message: string = '';
  userMessage: string = '';
  href: string = '';
  isProductRequest: boolean = false;
  showToolTip: boolean = false;
  userTextKey = '';
  messageTextKey = '';
  translationParams = {};

  constructor(private router: Router) {}

  ngOnInit(): void {
      this.buildMessage();
      this.subscribeToUrl();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.viewers) {
      this.buildMessage();
    }
  }

  subscribeToUrl(): void {
    this.href = this.router.url;
    if (this.href.includes('product-requests')) {
      this.isProductRequest = true;
    } else {
      this.isProductRequest = false;
    }
  }

  private buildMessage(): void {
    if (this.viewers) {
      if (this.viewers.length > 0 && this.viewers.length === 1) {
        this.userTextKey = 'ui.top_bar.one_user';
        this.messageTextKey = 'ui.top_bar.one_message';
        this.translationParams = {firstName: this.viewers[0]?.firstName, lastName: this.viewers[0]?.lastName, type: this.type};
      } else if (this.viewers.length === 2) {
        this.userTextKey = 'ui.top_bar.two_users';
        this.messageTextKey = 'ui.top_bar.two_messages';
        this.translationParams = {
          firstName1: this.viewers[0]?.firstName,
          lastName1: this.viewers[0]?.lastName,
          firstName2: this.viewers[0]?.firstName,
          lastName2: this.viewers[0]?.lastName,
          type: this.type
        };
      } else {
        const remainingUsers = this.viewers.length - 2;
        this.userTextKey = 'ui.top_bar.more_users';
        this.messageTextKey = 'ui.top_bar.more_messages';
        this.translationParams = {
          firstName1: this.viewers[0]?.firstName,
          lastName1: this.viewers[0]?.lastName,
          firstName2: this.viewers[0]?.firstName,
          lastName2: this.viewers[0]?.lastName,
          type: this.type,
          other: remainingUsers === 1 ? 'other' : 'others'
        };
      }
    }
  }
}
