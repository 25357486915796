import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConversationStatus } from 'src/app/models/ConversationStatus';

@Component({
  selector: 'conversation-status',
  templateUrl: './conversation-status.component.html',
  styleUrls: ['./conversation-status.component.scss']
})
export class ConversationStatusComponent implements OnInit {
  @Input() currentStatus?: ConversationStatus;
  @Input() statuses: ConversationStatus[] = [];
  @Input() resolved?: boolean;
  @Output() statusChange = new EventEmitter<ConversationStatus>();
  flyoutActive = false;

  constructor() { }

  ngOnInit(): void {
  }

  onStatusChange(newStatus: ConversationStatus): void {
    this.statusChange.emit(newStatus);
    this.toggleFlyout();
  }

  toggleFlyout(): void {
    this.flyoutActive = !this.flyoutActive;
  }

  closeFlyout(): void {
    this.flyoutActive = false;
  }

  handleStatusClick(event: {option: {id: string}}): void {
    if (this.currentStatus && this.currentStatus.id === event.option.id) {
      this.toggleFlyout();
    }
  }
}
