<ng-container *ngIf="!noModal">
  <ng-container *ngIf="!searchInProgress">
      <div class="title">
          {{'ui.client_selector.search_client' | translate}}
      </div>
      <div class="input-row">
          <input pInputText (paste)="validateSearchInput()" (keyup)="$event.key == 'Enter' ? doSearch() : validateSearchInput()" type="text" [disabled]="!!disabled" [(ngModel)]="searchString" [placeholder]="placeholder | clientSearchPlaceholderTranslationKey | translate" #searchInputModal>
      </div>
      <div class="btn-row">
          <button pButton [label]="'ui.client_selector.search' | translate" [disabled]="!!disabled || !searchValid" (click)="doSearch()"></button>
      </div>
  </ng-container>

  <ng-container *ngIf="!disabled">
      <p-dialog
          [header]="'ui.client_selector.select_client' | translate"
          [(visible)]="searchInProgress"
          [modal]="true"
          [style]="{width: '500px', height: '400px'}"
          [baseZIndex]="10000"
          [draggable]="false"
          [resizable]="false"
      >
        <div class="input-row inline">
            <input pInputText (paste)="validateSearchInput()" (keyup)="$event.key == 'Enter' ? doSearch() : validateSearchInput()" type="text" [disabled]="!!disabled" [(ngModel)]="searchString" [placeholder]="placeholder | clientSearchPlaceholderTranslationKey | translate">
            <button pButton [label]="'ui.client_selector.search' | translate" [disabled]="!!disabled  || !searchValid" (click)="doSearch()"></button>
        </div>
        <client-selector-results-list [clients]="(clients$ | async) || []" (clientSelected)="handleClientSelected($event)" (cancel)="handleCancel()"></client-selector-results-list>
      </p-dialog>
  </ng-container>
</ng-container>

<ng-container *ngIf="noModal">
  <div class="input-row inline">
    <input pInputText (paste)="validateSearchInput()" (keyup)="$event.key == 'Enter' ? doSearch() : validateSearchInput()" type="text" [disabled]="!!disabled" [(ngModel)]="searchString" [placeholder]="placeholder | clientSearchPlaceholderTranslationKey | translate" #searchInput>
    <button pButton [label]="'ui.client_selector.search' | translate" [disabled]="!!disabled || !searchValid" (click)="doSearch()"></button>
  </div>
  <ng-container *ngIf="searched">
    <client-selector-results-list [clients]="(clients$ | async) || []" (clientSelected)="handleClientSelected($event)" (cancel)="handleCancel()"></client-selector-results-list>
  </ng-container>
</ng-container>
