import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { Logout } from 'src/app/auth/state/actions';
import { getUser } from 'src/app/auth/state/selectors';
import { User } from 'src/app/models/User';
import { AppState } from 'src/app/state/reducers';
import {SetTourMode} from '../../../state/actions';
import {JoyrideService} from 'ngx-joyride';
import { EnvironmentService } from 'src/app/services/environment.service';

@Component({
  selector: 'dialog-no-practices',
  templateUrl: './dialog-no-practices.component.html',
  styleUrls: ['./dialog-no-practices.component.scss']
})
export class DialogNoPracticesComponent implements OnInit, OnDestroy {
  alive = true;
  user?: User;
  user$?: Observable<User | null>;
  message = '';

  constructor(
    private authService: AuthService,
    private store: Store<AppState>,
    private router: Router,
    private readonly joyrideService: JoyrideService,
    private environment: EnvironmentService
  ) { }

  ngOnInit(): void {
    this.subscribeToCurrentUser();
    this.setMessageFromEnv();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  setMessageFromEnv(): void {
    this.message = this.environment.get('noPracticesMessage');
  }

  subscribeToCurrentUser(): void {
    this.user$ = this.store.pipe(select(getUser)).pipe(
      takeWhile(() => this.alive)
    )

    this.user$.subscribe((user) => {
      if (user) {
        this.user = user;
      }
    });
  }

  logout(): void {
    if (this.user) {
      this.store.dispatch(SetTourMode({tourMode: false}));
      this.joyrideService.closeTour();

      this.authService.logout();
    }
  }
}
