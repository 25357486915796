import { Payment } from "../models/Payment";
import { hasPaymentAuthorisationExpired } from "./has-payment-authorisation-expired";

export function canPaymentBeCancelled(payment: Payment): boolean {
    if (payment.paidAt) {
        return false;
    }

    if (payment.cancelledAt) {
        return false;
    }

    if (payment.refundOfId) {
        return false;
    }

    if (payment.expiresAt < new Date()) {
        return false;
    }

    if (hasPaymentAuthorisationExpired(payment)) {
        return false;
    }

    return true;
}