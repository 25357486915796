import { Pipe, PipeTransform } from '@angular/core';
import { format, formatDistanceToNow, isToday } from 'date-fns';

@Pipe({name: 'penceToPounds'})
export class PenceToPounds implements PipeTransform {
  transform(value: number, currencySymbol: string = '£'): string {
    let str = '';

    if (value < 0) {
      value = 0 - value;

      str += '-';
    }

    str += currencySymbol + (value / 100).toFixed(2);

    return str;
  }
}
