import { ProductRequestStatus } from '../enums/product-request-status';

export function prettifyProductRequestStatus(status: ProductRequestStatus): string {
    switch (status) {
        case ProductRequestStatus.AWAITING_APPROVAL:
            return 'Awaiting Approval';
        case ProductRequestStatus.REJECTED:
            return 'Rejected';
        case ProductRequestStatus.READY_TO_PAY:
            return 'Ready to Invoice';
        case ProductRequestStatus.AWAITING_PAYMENT:
            return 'Awaiting Payment';
        case ProductRequestStatus.PAID:
            return 'Paid & Awaiting Fulfilment';
        case ProductRequestStatus.FULFILLED:
            return 'Dispensed';
        case ProductRequestStatus.COMPLETE:
            return 'Complete';
        case ProductRequestStatus.CANCELLED:
            return 'Cancelled';
    }
}
