import { Pipe, PipeTransform } from '@angular/core';
import { ClientInvoiceDto } from '../interfaces/dto/client-invoices.dto';
import { Currency } from '../models/Currency';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';

@Pipe({name: 'getInvoiceLabel'})
export class GetInvoiceLabelPipe implements PipeTransform {
  constructor(private datePipe: DatePipe, private sanitizer: DomSanitizer) {}
  transform(invoice: ClientInvoiceDto , practiceCurrency? : Currency, selectedLabel: boolean = false): SafeHtml {
    const formattedDate = this.datePipe.transform(invoice.invoiceDate, 'dd/MM/yy, HH:mm') || invoice.invoiceDate;
    const titleBase = `${invoice.invoiceNumber ? invoice.invoiceNumber : invoice.id} - ${ (practiceCurrency) ? practiceCurrency.currencySymbol : '£'}${invoice.invoiceTotal.toFixed(2)}`;
  
    let invoiceLabelText = `${titleBase} - ${formattedDate}`;
    if (selectedLabel && invoice && invoice?.invoiceTotal > invoice?.invoiceDueAmount ){
      invoiceLabelText = `${invoiceLabelText}  <br><br> Outstanding balance for this invoice: ${ (practiceCurrency) ? practiceCurrency.currencySymbol : '£'}${invoice.invoiceDueAmount.toFixed(2)}`;
    }
    // Use DomSanitizer to return safe HTML
    return this.sanitizer.bypassSecurityTrustHtml(invoiceLabelText);
  }
}
