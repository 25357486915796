import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { Client } from 'src/app/models/Client';
import { ClientState } from '../../../clients/state/reducer';
import { hasClientSearchError, isClientSearchLoading } from '../../../clients/state/selectors';

@Component({
  selector: 'client-selector-results-list',
  templateUrl: './client-selector-results-list.component.html',
  styleUrls: ['./client-selector-results-list.component.scss']
})
export class ClientSelectorResultsListComponent implements OnInit, OnDestroy {
  @Input() clients?: Client[];
  @Output() cancel = new EventEmitter();
  @Output() clientSelected = new EventEmitter();
  alive = true;
  loading$?: Observable<boolean>;
  error$?: Observable<boolean>;

  constructor(private store: Store<ClientState>) { }

  ngOnInit(): void {
    this.subscribeToClientSearchLoading();
    this.subscribeToClientSearchError();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  cancelSearch(): void {
    this.cancel.emit();
  }

  handleClientSelected(client: Client): void {
    this.clientSelected.emit(client);
  }

  subscribeToClientSearchLoading(): void {
    this.loading$ = this.store.pipe(select(isClientSearchLoading)).pipe(takeWhile(() => this.alive));
  }

  subscribeToClientSearchError(): void {
    this.error$ = this.store.pipe(select(hasClientSearchError)).pipe(takeWhile(() => this.alive));
  }
}
