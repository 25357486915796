<button pButton [label]="'patients.patient_history.clinical_history' | translate" class="p-button-fourth p-button-sm" (click)="showModal()" [disabled]="disabled"></button>
<ng-container *ngIf="patient">
    <p-dialog
        [header]="'patients.patient_history.clinical_history' | translate"
        [(visible)]="modalActive"
        [modal]="true"
        [style]="{width: '800px', 'min-height': '50vh'}"
        [baseZIndex]="10000"
        [draggable]="false"
        [resizable]="false"
    >
        <div class="dialog-content">
            <ng-container *ngIf="loading">
                <div class="loading">
                    <p-progressSpinner [style]="{width: '24px', height: '24px'}" styleClass="custom-spinner" strokeWidth="4"></p-progressSpinner>
                    <div class="label">{{'patients.patient_history.loading' | translate}}</div>
                </div>
            </ng-container>
            <ng-container *ngIf="!loading">
                <ng-container *ngIf="patientHistory">
                    <ng-container *ngIf="patientHistory.length > 0">
                        <ng-container *ngFor="let item of patientHistory">
                            <div class="history-item">
                                <patient-history-item [historyItem]="item"></patient-history-item>
                            </div>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="patientHistory.length == 0">
                        {{'patients.patient_history.no_history' | translate}}
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
    </p-dialog>
</ng-container>
