<div class="tag-manager" (clickOutside)="showManageTags = false">
  <div class="toggle" (click)="showManageTags = !showManageTags">
    {{'conversation.components.tag_manager.tags' | translate}}
    <dropdown-icon></dropdown-icon>
  </div>
  <ng-container *ngIf="showManageTags">
    <div class="tag-manager-dropdown">
      <p-listbox [options]="allTags" [filter]="true" [filterPlaceHolder]="'conversation.components.tag_manager.placeholder' | translate" styleClass="no-pad tags-selector" optionLabel="translatedName" [listStyle]="{'max-height':'300px', 'min-width': '200px'}">
        <ng-template let-tag pTemplate="item">
          <label class="tag-item" [for]="'tag-manager-' + tag.id" [class.disabled]="(tagsLoading$ | ngrxPush)">
            <div class="label">
              {{ 'tags.' + formatTagsKey(tag.name) | translate }}
            </div>
            <div class="right">
              <p-checkbox (onChange)="tagsChange()" [(ngModel)]="selectedTags" [value]="tag" [inputId]="'tag-manager-' + tag.id"></p-checkbox>
            </div>
          </label>
        </ng-template>
      </p-listbox>
    </div>
  </ng-container>
</div>
