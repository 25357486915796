<ng-container *ngIf="tag">
  <div
    class="avatar with-icon"
    [class.small]="size === 'small'"
    [class.x-small]="size === 'x-small'"
    [style.background-color]="backgroundColor"
  >
    <div class="initials">
      <ng-container *ngIf="tag.name">{{ tag.name[0] }}</ng-container>
    </div>
  </div>
</ng-container>
