import { createAction, props } from '@ngrx/store';
import {Template} from '../../models/Template';
import {TemplateType} from '../../enums/template-type';
import { TemplateCategory } from '../../enums/template-category';
import { TemplateFilterType } from '../../enums/template-filter-type';
import { TemplateConsentRequiredOption } from '../../enums/template-consent-required-option';
import { TemplateSubmissionStatus } from '../../enums/template-submission-status';
import { TemplateStatus } from '../../enums/template-status';
import {TemplatePlaceholderCreateDto} from '../../interfaces/dto/template-placeholder-create.dto';
import { TemplateArchiveStatus } from '../../enums/template-archive-status';
import {MediaType} from "../../enums/media-type";
import { User } from '../../models/User';
import {WhatsappTemplateCategory} from "../../enums/whatsapp-template-category";
import { TemplateCategoryStatus } from '../../enums/template-category-status';
import {DefaultWhatsappTemplate} from "../../models/DefaultWhatsappTemplate";
import {TemplateCreateDto} from "../interfaces/template-create.dto.interface";
import {TemplateEditDto} from "../interfaces/template-edit.dto.interface";

export const GetDefaultTemplates = createAction(
  '[Templates] Get Default Templates'
);

export const GetDefaultTemplatesSuccess = createAction(
  '[Templates] Get Default Templates Success',
  props<{templates: DefaultWhatsappTemplate[]}>()
);

export const GetTemplates = createAction(
  '[Templates] Get Templates',
  props<{syncWith360: boolean, includeArchived: boolean}>()
);

export const GetTemplatesSuccess = createAction(
  '[Templates] Get Templates Success',
  props<{templates: Template[]}>()
);

export const GetCampaignTemplates = createAction(
  '[Templates] Get Campaign Templates'
);

export const GetActivePaymentTemplate = createAction(
  '[Templates] Get Active Payment Template'
);

export const GetActivePaymentTemplateSuccess = createAction(
  '[Templates] Get Active Payment Template Success',
  props<{template: Template | null}>()
);

export const UpdateTemplateActive = createAction(
  '[Templates] Update Template Active',
  props<{template: Template}>()
);

export const UpdateTemplateActiveSuccess = createAction(
  '[Templates] Update Template Active Success',
  props<{template: Template}>()
);

export const DeleteTemplate = createAction(
  '[Templates] Delete Template',
  props<{template: Template}>()
);

export const DeleteTemplateSuccess = createAction(
  '[Templates] Delete Template Success',
  props<{template: Template}>()
);

export const UnarchiveTemplate = createAction(
  '[Templates] Unarchive Template',
  props<{template: Template}>()
);

export const UnarchiveTemplateSuccess = createAction(
  '[Templates] Unarchive Template Success',
  props<{template: Template}>()
);

export const SetTemplateToType = createAction(
  '[Templates] Set Template Type',
  props<{template: Template, templateType: TemplateType}>()
);

export const SetTemplateToTypeSuccess = createAction(
  '[Templates] Set Template Type Success',
  props<{updatedTemplates: Template[]}>()
);

export const CreateTemplate = createAction(
  '[Templates] Create Template',
  props<{ dto: TemplateCreateDto }>()
);

export const CreateTemplateSuccess = createAction(
  '[Templates] Create Template Success',
  props<{template: Template}>()
);

export const UpdateTemplate = createAction(
  '[Templates] Update Template',
  props<{ dto: TemplateEditDto }>()
);

export const UpdateTemplateSuccess = createAction(
  '[Templates] Update Template Success',
  props<{template: Template}>()
);

export const TemplateActionFailed = createAction(
  '[Templates] Action Failed',
);

export const ChangeTab = createAction(
  '[Templates] Change Tab',
  props<{category: TemplateCategory}>()
);

export const UpdateTemplateFilterType = createAction(
  '[Templates] Update Type Filter',
  props<{option: TemplateType}>()
);

export const UpdateTemplateFilterConsentRequired = createAction(
  '[Templates] Update Consent Required Filter',
  props<{option: TemplateConsentRequiredOption}>()
);

export const UpdateTemplateFilterSubmissionStatus = createAction(
  '[Templates] Update Submission Status Filter',
  props<{option: TemplateSubmissionStatus}>()
);

export const UpdateTemplateFilterStatus = createAction(
  '[Templates] Update Status Filter',
  props<{option: TemplateStatus}>()
);

export const UpdateTemplateFilterArchiveStatus = createAction(
  '[Templates] Update Archive Status Filter',
  props<{option: TemplateArchiveStatus}>()
);

export const UpdateTemplateFilterCategoryStatus = createAction(
  '[Templates] Update Category Status Filter',
  props<{option: WhatsappTemplateCategory}>()
);

export const SetTemplateSearchTerm = createAction(
  '[Templates] Set Template Search Term',
  props<{option: string}>()
);

export const ClearTemplateFilters = createAction(
  '[Templates] Clear Filters'
);


