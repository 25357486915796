<p>Are you sure you want to delete the folder called <strong>{{ config.data.folder.name }}</strong>?</p>
<p><span class="warning">When deleting a folder, you will delete ALL files and folders within this folder permanently.</span></p>
<p>If you'd like to delete this folder, please enter the name of the folder you are deleting below:</p>

<form class="delete-folder" [formGroup]="form">
  <div class="input-row">
    <div class="label">Name:</div>
    <input #nameInput pInputText class="p-inputtext" type="text" formControlName="name" maxlength="50">
    <ng-container *ngIf="(form.controls.name.invalid && (form.controls.name.touched) && form.controls.name.errors?.['required'])">
      <div class="error">You must enter the name of the folder you are deleting.</div>
    </ng-container>
    <ng-container *ngIf="config.data.folder.name !== form.controls.name.value && form.controls.name.touched">
      <div class="error">Folder name does not match</div>
    </ng-container>
  </div>
  <div class="btn-row">
    <p-button (click)="submit()" label="Delete Permanently" styleClass="p-button-primary"></p-button>
  </div>
</form>  