<ng-container *ngIf="disabled">
  <div class="wrapper">
    <user-avatar [size]="size" [user]="selected"></user-avatar>
  </div>
</ng-container>

<ng-container *ngIf="!disabled">
  <div class="wrapper" (clickOutside)="closeFlyout()">
      <user-avatar class="clickable" [size]="size" [user]="selected" (click)="toggleFlyout()"></user-avatar>
      <div class="flyout" [class.active]="flyoutActive">
        <user-changer-list [selected]="selected" [users$]="users$" [groups$]="groups$" [authUser]="authUser$ | async" [modalOnMobile]="true" (userChange)="handleUserChange($event)" (currentUserSelected)="handleCurrentUserSelected()" (clickModalBackground)="handleClickModalBackground()"></user-changer-list>
      </div>
  </div>
</ng-container>
