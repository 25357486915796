import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { TokenService } from '../services/token.service';
import { mergeMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { EnvironmentService } from '../services/environment.service';

@Injectable()
export class AttachTokenInterceptor implements HttpInterceptor {
  constructor(
    private cookieService: CookieService,
    private tokenService: TokenService,
    private router: Router,
    private environmentService: EnvironmentService,
    private location: Location
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // If this isn't a request to the backend or forms
    if (!req.url.includes(this.environmentService.get('backendUrl')) && !req.url.includes(this.environmentService.get('formsApiUrl'))) {
      return next.handle(req);
    }

    if (
      req.url.includes('oauth/token') ||
      req.url.includes('auth/request-token') ||
      req.url.includes('healthy') ||
      req.url.includes('logout')
    ) {
      return next.handle(req);
    }

    return this.tokenService.checkTokens().pipe(
      tap(response => {
        if (!response) {
          let returnUrl = '';
          if (!this.location.path().includes('auth')) {
            returnUrl = this.location.path();

            if (window.location.hash) {
              returnUrl += window.location.hash;
            }
          }
          this.router.navigate(['auth/login'], { queryParams: { returnUrl }});
        }
      }),
      mergeMap(() => {
        let token = '';
        if (req.url.includes(this.environmentService.get('backendUrl'))) {
          token = this.cookieService.get(this.environmentService.get('jwtPrefix') + 'JWT');
        } else if (req.url.includes(this.environmentService.get('formsApiUrl'))) {
          token = this.cookieService.get(this.environmentService.get('jwtPrefix') + 'CORE_JWT');
        }

        const authReq = !!token ? req.clone({
          setHeaders: { Authorization: 'Bearer ' + token },
        }) : req;
        return next.handle(authReq);
      })
    );
  }
}
