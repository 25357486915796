import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import * as PaymentsActions from './actions';
import {PaymentsService} from '../payments.service';
import {getCurrentPractice} from '../../practices/state/selectors';
import {Store} from '@ngrx/store';
import {AppState} from '../../state/reducers';
import {Payment} from '../../models/Payment';
import {of} from 'rxjs';
import * as ConversationActions from '../../conversation/state/actions';
import { Noop } from '../../state/actions';
import { TranslatedMessageService } from '../../helpers/translated-message-service';
import {CashUp, RefundPayment, RetrySavePaymentToPmsFailed, UpdatePaymentFilters} from './actions';
import {SearchType} from "../../enums/search-type";
import {buildUrlParamsFromPaymentFilters} from "../../helpers/build-url-params-from-payment-filters";
import * as SearchActions from "../../search/state/actions";
import {Router} from "@angular/router";
import {practiceHasFeature} from "../../helpers/practice-has-feature";
import {PracticeFeature} from "../../enums/practice-feature";
import { exportPaymentAsCsv } from '../../helpers/export-payments-as-csv';
import { PaymentStatus } from '../../enums/payment-status';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class PaymentsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private router: Router,
    private paymentsService: PaymentsService,
    private messageService: TranslatedMessageService,
    private translateService: TranslateService,
  ) {
  }

  submitPaymentRequest$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentsActions.SubmitPaymentRequest),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      return this.paymentsService.createPayment(
        action.request.description,
        action.request.message,
        action.request.amount,
        action.request.expiresAfter,
        action.conversationId,
        action.clientId,
        action.patientId,
        practice?.id,
        action.siteId,
        true,
        action.request.authOnly,
        action.request.paymentReminderEnabled,
        action.request.paymentInvoice,
        action.request.paymentInvoiceNumber
      )
        .pipe(
          map((result: Payment) => {
            this.messageService.addTranslated({
              severity: 'success',
              summary: 'Success',
              detail: 'Payment request created successfully',
              life: 10000
            });
            return PaymentsActions.SubmitPaymentRequestSuccess({payment: result});
          })
        );
    }),
    catchError((error) => {
      this.messageService.addTranslated({
        severity: 'error',
        summary: 'Error',
        detail: 'An error occurred when creating this payment request - ' + (error.error ? error.error.error : ''),
        life: 10000
      });
      return of(PaymentsActions.SubmitPaymentRequestFailed());
    })
  ));

  submitPaymentRequestSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentsActions.SubmitPaymentRequestSuccess),
    mergeMap((action) => {
      if (action.payment.conversationId) {
        this.store.dispatch(ConversationActions.SetConversationAsRead({conversationId: action.payment.conversationId}));
      }

      return of();
    }),
  ), { dispatch: false });

  submitPaymentConversationRequest$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentsActions.SubmitPaymentConversationRequest),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      return this.paymentsService.createPaymentFromClient(
        action.request,
        action.client,
        action.contact,
        action.channel,
        practice
      )
        .pipe(
          map((result: Payment) => {
            this.messageService.addTranslated({
              severity: 'success',
              summary: 'Success',
              detail: 'Payment request created successfully',
              life: 10000
            });
            return PaymentsActions.SubmitPaymentRequestSuccess({payment: result});
          })
        );
    }),
    catchError((error) => {
      this.messageService.addTranslated({
        severity: 'error',
        summary: 'Error',
        detail: 'An error occurred when creating this payment request - ' + (error.error ? error.error.error : ''),
        life: 10000
      });
      return of(PaymentsActions.SubmitPaymentRequestFailed());
    })
  ));

  updatePaymentFilters$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentsActions.UpdatePaymentFilters),
    withLatestFrom(this.store.select(getCurrentPractice)),
    tap(([action, practice]) => {
      let url = `payments?`;
      const params = buildUrlParamsFromPaymentFilters(action.filters);
      url += params;
      this.router.navigateByUrl(url);
    }),
    map(([action, practice]) => {
      return PaymentsActions.GetPayments({filters: action.filters});
    })
  ));

  getPayments$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentsActions.GetPayments),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      return this.paymentsService.getPayments(action.filters, practice)
        .pipe(
          map((result: { payments: Payment[], total: number }) => {
            return PaymentsActions.GetPaymentsSuccess({...result});
          })
        );
    })
  ));

  resendPayments$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentsActions.ResendPaymentLink),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      return this.paymentsService.resendPayment(action.payment, practice, action.channel)
        .pipe(
          tap(() => {
            this.messageService.addTranslated({
              severity: 'success',
              summary: 'payments.effects.payment_resent.title',
              detail: 'payments.effects.payment_resent.detail',
              life: 5000
            });
          }),
          map(() => {
            return Noop();
          })
        );
    })
  ));

  refundPayment$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentsActions.RefundPayment),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      return this.paymentsService.refundPayment(action.payment, practice)
        .pipe(
          tap(() => {
            this.messageService.addTranslated({
              severity: 'success',
              summary: 'payments.effects.payment_refunded.title',
              detail: 'payments.effects.payment_refunded.detail',
              life: 5000
            });
          }),
          map(() => {
            return Noop();
          }),
          catchError(() => {
            this.messageService.addTranslated({
              severity: 'error',
              summary: 'payments.effects.refund_payment_error.title',
              detail: 'payments.effects.refund_payment_error.detail',
              life: 5000
            });
            return of();
          })
        );
    })
  ));
  openPaymentRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentsActions.openPaymentRequestForClient),
      switchMap((action) => {
        return of(
          PaymentsActions.SetPaymentClient({ client: action.client }),
        );
      })
    )
  );
  closePaymentRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentsActions.closePaymentRequestForClient),
      switchMap((action) => {
        return of(
          PaymentsActions.SetPaymentClient({}),
        );
      })
    )
  );

  cancelPayments$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentsActions.CancelPaymentLinks),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      return this.paymentsService.cancelPayments(action.payments, practice)
        .pipe(
          tap(() => {
            this.messageService.addTranslated({
              severity: 'success',
              summary: 'payments.effects.payments_cancelled.title',
              detail: 'payments.effects.payments_cancelled.detail',
              life: 5000
            });
          }),
          map(() => {
            return Noop();
          })
        );
    })
  ));

  retrySavePayments$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentsActions.RetrySavePaymentToPms),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      return this.paymentsService.retrySavePayment(action.payment, practice)
        .pipe(
          tap(() => {
            this.messageService.addTranslated({
              severity: 'success',
              summary: 'payments.effects.payment_saved.title',
              detail: 'payments.effects.payment_saved.detail',
              life: 5000
            });
          }),
          map((result: Payment) => {
            return PaymentsActions.RetrySavePaymentToPmsSuccess({payment: result});
          }),
          catchError(() => {
            this.messageService.addTranslated({
              severity: 'error',
              summary: 'payments.effects.save_payment_error.title',
              detail: 'payments.effects.save_payment_error.detail',
              life: 5000
            });
            return of(PaymentsActions.RetrySavePaymentToPmsFailed({payment: action.payment}));
          })
        );
    }),
  ));

  markPaymentsAsComplete$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentsActions.MarkPaymentsAsComplete),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      return this.paymentsService.markAsComplete(action.payments, practice)
        .pipe(
          tap(() => {
            this.messageService.addTranslated({
              severity: 'success',
              summary: 'payments.effects.payments_updated.title',
              detail: 'payments.effects.payments_updated.detail',
              life: 5000
            });
          }),
          map(() => {
            return PaymentsActions.MarkPaymentsAsCompleteSuccess({payments: action.payments});
          }),
          catchError(() => {
            this.messageService.addTranslated({
              severity: 'error',
              summary: 'payments.effects.update_payments_error.title',
              detail: 'payments.effects.update_payments_error.detail',
              life: 5000
            });
            return of(PaymentsActions.MarkPaymentsAsCompleteFailed({payments: action.payments}));
          })
        );
    }),
  ));

  capturePayments$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentsActions.CapturePayments),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      return this.paymentsService.capturePayments(action.payments, practice)
        .pipe(
          tap(() => {
            this.messageService.addTranslated({
              severity: 'success',
              summary: 'payments.effects.payment_completed.title',
              detail: 'payments.effects.payment_completed.detail',
              life: 5000
            });
          }),
          map(() => {
            return PaymentsActions.PaymentsCaptureSuccess({payments: action.payments});
          }),
          catchError(() => {
            this.messageService.addTranslated({
              severity: 'error',
              summary: 'payments.effects.payment_failed_error.title',
              detail: 'payments.effects.payment_failed_error.detail',
              life: 5000
            });
            return of(PaymentsActions.PaymentsCaptureFailed({payments: action.payments}));
          })
        );
    }),
  ));

  cashUpPayments$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentsActions.CashUp),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      return this.paymentsService.cashUp(action.filters, practice)
        .pipe(
          map((payments: Payment[]) => {
            return PaymentsActions.CashUpSuccess({payments, includeCompleted: action.filters.statuses.includes(PaymentStatus.COMPLETE)});
          }),
          catchError(() => {
            return of(PaymentsActions.CashUpFailed());
          })
        );
    }),
  ));

  cashUpSuccessPayments$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentsActions.CashUpSuccess),
    mergeMap((action) => {
      exportPaymentAsCsv(action.payments, false, this.translateService);
      return of(Noop());
    }),
  ));
}
