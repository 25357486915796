import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import { map } from 'rxjs/operators';
import { CommentAdapter } from '../adapters/comment.adapter';
import { ConversationStatusAdapter } from '../adapters/conversation-status.adapter';
import { ConversationAdapter } from '../adapters/conversation.adapter';
import { CommentDto } from '../interfaces/dto/comment.dto';
import { ConversationPatchDto } from '../interfaces/dto/conversation-patch.dto';
import { ConversationStatusDto } from '../interfaces/dto/conversation-status.dto';
import { ConversationDto } from '../interfaces/dto/conversation.dto';
import { Client } from '../models/Client';
import { Comment } from '../models/Comment';
import { Conversation } from '../models/Conversation';
import { ConversationStatus } from '../models/ConversationStatus';
import { Patient } from '../models/Patient';
import { Practice } from '../models/Practice';
import { EnvironmentService } from '../services/environment.service';
import { Contact } from '../models/Contact';
import { Payment } from '../models/Payment';
import {ConversationsFilterDto} from '../interfaces/dto/conversations-filter.dto';
import { CampaignMessageDto } from '../interfaces/dto/campaign-message.dto';
import { CampaignMessageAdapter } from '../adapters/campaign-message.adapter';
import { CampaignMessage } from '../models/CampaignMessage';
import { isConversationDto } from '../helpers/is-conversation-dto';
import {ReadStatus} from '../enums/read-status';
import { ClientToBasicClientAdapter } from '../adapters/client-to-basic-client.adapter';
import {Tag} from "../models/Tag";
import {Template} from "../models/Template";
import { UserAdapter } from '../adapters/user.adapter';
import { UserDto } from '../interfaces/dto/user.dto';
import { User } from '../models/User';
import { Media } from '../models/Media';

@Injectable({
  providedIn: 'root'
})
export class ConversationService {

  constructor(
    private environmentService: EnvironmentService,
    private http: HttpClient,
    private conversationAdapter: ConversationAdapter,
    private conversationStatusAdapter: ConversationStatusAdapter,
    private clientToBasicClientAdapter: ClientToBasicClientAdapter,
    private commentAdapter: CommentAdapter,
    private campaignMessageAdapter: CampaignMessageAdapter,
    private userAdapter: UserAdapter,
  ) { }

  getConversation(id: string): Observable<Conversation> {
    const url = this.environmentService.get('backendUrl') + `/conversations/${id}`;
    return this.http.get<ConversationDto>(url, {withCredentials: true}).pipe(
      map((response: ConversationDto) => {
        return this.conversationAdapter.run(response);
      })
    );
  }

  getConversationPreview(id: string): Observable<Conversation> {
    const url = this.environmentService.get('backendUrl') + `/conversations/${id}/preview`;
    return this.http.get<ConversationDto>(url, {withCredentials: true}).pipe(
      map((response: ConversationDto) => {
        return this.conversationAdapter.run(response);
      })
    );
  }

  getConversationStatuses(): Observable<ConversationStatus[]> {
    const url = this.environmentService.get('backendUrl') + `/conversation-statuses`;
    return this.http.get<ConversationStatusDto[]>(url, {withCredentials: true}).pipe(
      map((response: ConversationStatusDto[]) => {
        return response.map(status => this.conversationStatusAdapter.run(status));
      })
    );
  }

  updateConversation(id: number, data: ConversationPatchDto): Observable<Conversation> {
    const url = this.environmentService.get('backendUrl') + `/conversations/${id}`;
    return this.http.patch<ConversationDto>(url, data, {withCredentials: true}).pipe(
      map((response: ConversationDto) => {
        return this.conversationAdapter.run(response);
      })
    );
  }

  sendToPms(id: number): Observable<any> {
    const url = this.environmentService.get('backendUrl') + `/conversations/${id}/send-to-pms`;
    return this.http.get(url, {withCredentials: true});
  }

  downloadPdf(id: number): Observable<Blob> {
    const url = this.environmentService.get('backendUrl') + `/conversations/${id}/download-pdf`;

    const headers = new HttpHeaders({ 'Content-Type': 'application/json', responseType : 'blob'});
    return this.http.get<Blob>(url, { headers, responseType : 'blob' as 'json'});
  }

  downloadPdfs(conversations: Conversation[]): Observable<Blob> {
    const url = this.environmentService.get('backendUrl') + `/conversations/download-pdfs`;

    const headers = new HttpHeaders({ 'Content-Type': 'application/json', responseType : 'blob'});
    return this.http.post<Blob>(url, {ids: conversations.map((conversation) => conversation.id)}, { headers, responseType : 'blob' as 'json'});
  }

  setClient(conversationId: number, client: Client, practice: Practice): Observable<Conversation> {
    const url = this.environmentService.get('backendUrl') + `/conversations/${conversationId}/set-client`;
    const clientInfo = this.clientToBasicClientAdapter.run(client);
    return this.http.post<ConversationDto>(url, {client: clientInfo, practiceId: practice.id}, {withCredentials: true}).pipe(
      map((response: ConversationDto) => {
        return this.conversationAdapter.run(response);
      })
    );
  }

  setPatient(conversationId: number, patient: Patient, client: Client, practice: Practice): Observable<Conversation> {
    const url = this.environmentService.get('backendUrl') + `/conversations/${conversationId}/set-patient`;
    const clientInfo = this.clientToBasicClientAdapter.run(client);
    return this.http.post<ConversationDto>(url, {patient, client: clientInfo, practiceId: practice.id}, {withCredentials: true}).pipe(
      map((response: ConversationDto) => {
        return this.conversationAdapter.run(response);
      })
    );
  }

  createConversation(
    practiceId: string,
    channel: string,
    client: Client | null,
    contact: Contact | null,
    payment: Payment | null,
    initialMessage: string = '',
    template: Template | null = null,
  ): Observable<Conversation> {
    const url = this.environmentService.get('backendUrl') + `/practices/${practiceId}/conversations/`;
    return this.http.put<ConversationDto>(url, {
      channel,
      client,
      contact,
      payment,
      initialMessage,
      templateId: template ? template.id : null,
    }, {withCredentials: true}).pipe(
      map((response: ConversationDto) => {
        return this.conversationAdapter.run(response);
      })
    );
  }

  getComments(conversationId: string): Observable<Comment[]> {
    const url = this.environmentService.get('backendUrl') + `/conversations/${conversationId}/comments`;
    return this.http.get<CommentDto[]>(url, {withCredentials: true}).pipe(
      map((response: CommentDto[]) => {
        return response.map(comment => this.commentAdapter.run({
          ...comment,
          content: JSON.parse(comment.content)
        }));
      })
    );
  }

  addWatcher(conversationId: string, userId: string): Observable<void> {
    const url = this.environmentService.get('backendUrl') + `/conversations/${conversationId}/watchers/${userId}`;
    return this.http.post<{success: boolean}>(url, {}, {withCredentials: true}).pipe(
      map(() => {
        return;
      })
    );
  }

  removeWatcher(conversationId: string, userId: string): Observable<void> {
    const url = this.environmentService.get('backendUrl') + `/conversations/${conversationId}/watchers/${userId}`;
    return this.http.delete<{success: boolean}>(url, {withCredentials: true}).pipe(
      map(() => {
        return;
      })
    );
  }

  getPreviousConversations(conversationId: string, skip: number): Observable<Array<Conversation|CampaignMessage>> {
    const url = this.environmentService.get('backendUrl') + `/conversations/${conversationId}/previous-conversations?skip=${skip}`;
    return this.http.get<Array<ConversationDto|CampaignMessageDto>>(url, {withCredentials: true}).pipe(
      map((response: Array<ConversationDto|CampaignMessageDto>) => {
        return response.map(responseItem => {
          if (isConversationDto(responseItem)) {
            return this.conversationAdapter.run(responseItem);
          } else {
            return this.campaignMessageAdapter.run(responseItem);
          }
        });
      })
    );
  }

  refreshClient(conversationId: string): Observable<boolean> {
    const url = this.environmentService.get('backendUrl') + `/conversations/${conversationId}/refresh-client`;
    return this.http.get<boolean>(url, {withCredentials: true}).pipe(
      map((response: boolean) => {
        return response;
      })
    );
  }

  searchConversations(practiceId: string, filters: ConversationsFilterDto): Observable<{ conversations: Conversation[], total: number }> {
    const url = this.environmentService.get('backendUrl') + `/practices/${practiceId}/conversations/search`;
    return this.http.post<{ conversations: ConversationDto[], total: number }>(url, filters, {withCredentials: true}).pipe(
      map((response: { conversations: ConversationDto[], total: number }) => {
        const conversations = response.conversations.map((item) => this.conversationAdapter.run(item));
        return { conversations, total: response.total };
      })
    );
  }

  markConversationsRead(practiceId: string, conversations: Conversation[]): Observable<void> {
    const url = this.environmentService.get('backendUrl') + `/practices/${practiceId}/conversations/update-read-status`;
    return this.http.post<any>(url, {
      conversationIds: conversations.map((conversation) => conversation.id),
      readStatus: ReadStatus.READ
    }, {withCredentials: true}).pipe(
      map((response: any) => {
        return;
      })
    );
  }

  markConversationsUnread(practiceId: string, conversations: Conversation[]): Observable<void> {
    const url = this.environmentService.get('backendUrl') + `/practices/${practiceId}/conversations/update-read-status`;
    return this.http.post<any>(url, {
      conversationIds: conversations.map((conversation) => conversation.id),
      readStatus: ReadStatus.UNREAD
    }, {withCredentials: true}).pipe(
      map((response: any) => {
        return;
      })
    );
  }

  blockSender(practiceId: string, conversations: Conversation[]): Observable<void> {
    const url = this.environmentService.get('backendUrl') + `/practices/${practiceId}/block-senders/`;
    return this.http.post<any>(url, {conversationIds: conversations.map(c => c.id)}, {withCredentials: true}).pipe(
      map((response: any) => {
        return;
      })
    );
  }

  convertToSMS(conversation: Conversation, resendAll: boolean): Observable<void> {
    const url = this.environmentService.get('backendUrl') + `/conversations/${conversation.id}/convert-to-sms`;
    return this.http.post<any>(url, {resendAll}, {withCredentials: true}).pipe(
      map((response: any) => {
        return;
      })
    );
  }

  setTags(conversation: Conversation, tags: Tag[]): Observable<void> {
    const url = this.environmentService.get('backendUrl') + `/conversations/${conversation.id}/set-tags`;
    return this.http.post<any>(url, { tagIds: tags.map((t) => t.id) }, {withCredentials: true}).pipe(
      map((response: any) => {
        return;
      })
    );
  }
}
