import { Component, Input, OnInit } from '@angular/core';
import { format } from 'date-fns';
import { enUS, fr, es, de, it, fi, sv, pt, nn } from 'date-fns/locale';
import { TranslateService } from '@ngx-translate/core';
import { Locale } from 'date-fns';
@Component({
  selector: 'app-message-date',
  templateUrl: './message-date.component.html',
  styleUrl: './message-date.component.scss'
})
export class MessageDateComponent implements OnInit {
  @Input() createdAt!: Date;
  formattedDate: string = '';

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    this.updateDate();
    // Listen to language changes
    this.translate.onLangChange.subscribe(() => this.updateDate());
  }

  updateDate(): void {
    const lang = this.translate.currentLang || 'en';
    const locales: { [key: string]: Locale } = { en: enUS, fr, es, de, it, fi, sv, pt, nn };

    this.formattedDate = format(new Date(this.createdAt), 'EEEE, do MMM', {
      locale: locales[lang] || enUS
    });
  }
}
