import { Action, createReducer, on } from '@ngrx/store';
import * as actions from './actions';
import { Template } from '../../models/Template';
import { TemplateCategory } from '../../enums/template-category';
import { TemplateFilterType } from '../../enums/template-filter-type';
import { TemplateFilterSelection } from '../../interfaces/template-filter-selection';
import { TemplateArchiveStatus } from '../../enums/template-archive-status';
import {GetDefaultTemplatesSuccess} from "./actions";
import {DefaultWhatsappTemplate} from "../../models/DefaultWhatsappTemplate";

export interface TemplateState {
  defaultTemplates: DefaultWhatsappTemplate[];
  templates: Template[];
  templatesLoading: boolean;
  activePaymentTemplate: Template | null;
  activeTemplateTab: TemplateCategory;
  filters: TemplateFilterSelection;
}

export const initialState: TemplateState = {
  defaultTemplates: [],
  templates: [],
  templatesLoading: true,
  activePaymentTemplate: null,
  activeTemplateTab: TemplateCategory.STANDARD,
  filters: {
    [TemplateFilterType.SEARCH_TERM]: '',
    [TemplateFilterType.TYPE]: [],
    [TemplateFilterType.CONSENT_REQUIRED]: [],
    [TemplateFilterType.SUBMISSION_STATUS]: [],
    [TemplateFilterType.STATUS]: [],
    [TemplateFilterType.ARCHIVE_STATUS]: [
      TemplateArchiveStatus.NOT_ARCHIVED
    ],
    [TemplateFilterType.CATERGORY]: [],
  }
};

const templateReducer = createReducer(
  initialState,
  on(actions.GetDefaultTemplatesSuccess, (state, payload) => ({
    ...state,
    defaultTemplates: payload.templates,
  })),
  on(actions.GetTemplates, (state) => ({
    ...state,
    templatesLoading: true,
  })),
  on(actions.GetTemplatesSuccess, (state, payload) => ({
    ...state,
    templates: payload.templates,
    templatesLoading: false,
  })),
  on(actions.UpdateTemplateActive, (state) => ({
    ...state,
    templatesLoading: true,
  })),
  on(actions.UpdateTemplateActiveSuccess, (state, payload) => ({
    ...state,
    templatesLoading: false,
    templates: [
      ...state.templates.map((template) => {
        if (template.id === payload.template.id) {
          return {
            ...template,
            active: payload.template.active
          };
        }

        return template;
      }),
    ],
  })),
  on(actions.CreateTemplate, (state, payload) => ({
    ...state,
    templatesLoading: true,
  })),
  on(actions.CreateTemplateSuccess, (state, payload) => ({
    ...state,
    templatesLoading: false,
    templates: [
      payload.template,
      ...state.templates
    ],
  })),
  on(actions.UpdateTemplate, (state, payload) => ({
    ...state,
    templatesLoading: true,
  })),
  on(actions.UpdateTemplateSuccess, (state, payload) => ({
    ...state,
    templatesLoading: false,
    templates: [
      payload.template,
      ...state.templates.filter((t) => t.id !== payload.template.id)
    ],
  })),
  on(actions.DeleteTemplate, (state, payload) => ({
    ...state,
    templatesLoading: true,
  })),
  on(actions.DeleteTemplateSuccess, (state, payload) => ({
    ...state,
    templatesLoading: false,
    templates: [
      ...state.templates.map((template: Template) => {
        if (template.id === payload.template.id) {
          return {
            ...template,
            archived: TemplateArchiveStatus.ARCHIVED
          };
        }

        return template;
      })
    ],
  })),
  on(actions.UnarchiveTemplate, (state, payload) => ({
    ...state,
    templatesLoading: true,
  })),
  on(actions.UnarchiveTemplateSuccess, (state, payload) => ({
    ...state,
    templatesLoading: false,
    templates: [
      ...state.templates.map((template: Template) => {
        if (template.id === payload.template.id) {
          return {
            ...template,
            archived: TemplateArchiveStatus.NOT_ARCHIVED
          };
        }

        return template;
      })
    ],
  })),
  on(actions.SetTemplateToType, (state, payload) => ({
    ...state,
    templatesLoading: true,
  })),
  on(actions.SetTemplateToTypeSuccess, (state, payload) => ({
    ...state,
    templatesLoading: false,
    templates: [
      ...state.templates.map((template) => {
        const updatedTpl = payload.updatedTemplates.find((updatedTemplate) => updatedTemplate.id === template.id);
        if (updatedTpl) {
          return updatedTpl;
        }

        return template;
      })
    ],
  })),
  on(actions.TemplateActionFailed, (state) => ({
    ...state,
    templatesLoading: false,
  })),
  on(actions.GetActivePaymentTemplateSuccess, (state, payload) => ({
    ...state,
    activePaymentTemplate: payload.template
  })),
  on(actions.ChangeTab, (state, payload) => ({
    ...state,
    activeTemplateTab: payload.category,
    filters: initialState.filters
  })),
  on(actions.UpdateTemplateFilterType, (state, payload) => ({
    ...state,
    filters: {
      ...state.filters,
      [TemplateFilterType.TYPE]: state.filters[TemplateFilterType.TYPE].includes(payload.option) ? [
        ...state.filters[TemplateFilterType.TYPE].filter((val) => val !== payload.option)
      ] : [
        ...state.filters[TemplateFilterType.TYPE],
        payload.option
      ]
    }
  })),
  on(actions.UpdateTemplateFilterConsentRequired, (state, payload) => ({
    ...state,
    filters: {
      ...state.filters,
      [TemplateFilterType.CONSENT_REQUIRED]: state.filters[TemplateFilterType.CONSENT_REQUIRED].includes(payload.option) ? [
        ...state.filters[TemplateFilterType.CONSENT_REQUIRED].filter((val) => val !== payload.option)
      ] : [
        ...state.filters[TemplateFilterType.CONSENT_REQUIRED],
        payload.option
      ]
    }
  })),
  on(actions.UpdateTemplateFilterSubmissionStatus, (state, payload) => ({
    ...state,
    filters: {
      ...state.filters,
      [TemplateFilterType.SUBMISSION_STATUS]: state.filters[TemplateFilterType.SUBMISSION_STATUS].includes(payload.option) ? [
        ...state.filters[TemplateFilterType.SUBMISSION_STATUS].filter((val) => val !== payload.option)
      ] : [
        ...state.filters[TemplateFilterType.SUBMISSION_STATUS],
        payload.option
      ]
    }
  })),
  on(actions.UpdateTemplateFilterStatus, (state, payload) => ({
    ...state,
    filters: {
      ...state.filters,
      [TemplateFilterType.STATUS]: state.filters[TemplateFilterType.STATUS].includes(payload.option) ? [
        ...state.filters[TemplateFilterType.STATUS].filter((val) => val !== payload.option)
      ] : [
        ...state.filters[TemplateFilterType.STATUS],
        payload.option
      ]
    }
  })),
  on(actions.UpdateTemplateFilterArchiveStatus, (state, payload) => ({
    ...state,
    filters: {
      ...state.filters,
      [TemplateFilterType.ARCHIVE_STATUS]: state.filters[TemplateFilterType.ARCHIVE_STATUS].includes(payload.option) ? [
        ...state.filters[TemplateFilterType.ARCHIVE_STATUS].filter((val) => val !== payload.option)
      ] : [
        ...state.filters[TemplateFilterType.ARCHIVE_STATUS],
        payload.option
      ]
    }
  })),
  on(actions.UpdateTemplateFilterCategoryStatus, (state, payload) => ({
    ...state,
    filters: {
      ...state.filters,
      [TemplateFilterType.CATERGORY]: state.filters[TemplateFilterType.CATERGORY].includes(payload.option) ? [
        ...state.filters[TemplateFilterType.CATERGORY].filter((val) => val !== payload.option)
      ] : [
        ...state.filters[TemplateFilterType.CATERGORY],
        payload.option
      ]
    }
  })),
  on(actions.SetTemplateSearchTerm, (state, {option}) => ({
    ...state,
    filters: {
      ...state.filters,
      [TemplateFilterType.SEARCH_TERM]: option
    }
  })),
  on(actions.ClearTemplateFilters, (state) => ({
    ...state,
    filters: initialState.filters
  })),
);

export function reducer(state: TemplateState | undefined, action: Action): TemplateState {
  return templateReducer(state, action);
}
