import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../state/reducers';
import { Observable, takeWhile } from 'rxjs';
import { MediaFolder } from '../../../models/MediaFolder';
import { getMediaFolderTree, getMediaFolderTreeExcludingSelectedFoldersChildren } from '../../state/selectors';
import { MoveFolder, SetFolderToMove } from '../../state/actions';

@Component({
  selector: 'move-folder',
  templateUrl: './move-folder.component.html',
  styleUrls: ['./move-folder.component.scss']
})
export class MoveMediaFolderComponent implements OnInit, OnDestroy {
  alive = true;
  form = new FormGroup({
    destinationFolder: new FormControl('', Validators.required),
  });
  folders$: Observable<MediaFolder[]>;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private store: Store<AppState>,
    ) {
      this.folders$ = this.store.pipe(select(getMediaFolderTreeExcludingSelectedFoldersChildren)).pipe(takeWhile(() => this.alive));
    }

  ngOnInit(): void {
    this.store.dispatch(SetFolderToMove({
      folder: this.config.data.folder
    }));
  }

  ngOnDestroy(): void {
    this.store.dispatch(SetFolderToMove({
      folder: null
    }));
    this.alive = false;
  }

  submit(): void {
    Object.keys(this.form.controls).forEach(field => {
      const control = this.form.get(field);
      control?.markAsTouched({ onlySelf: true });
    });

    if (this.config.data.folder.id && this.form.valid && this.form.controls.destinationFolder.value) {
      this.store.dispatch(MoveFolder({
        folder: this.config.data.folder,
        destinationFolderId: Number(this.form.controls.destinationFolder.value)
      }));
      
      this.ref.close();
    }
  }
}
