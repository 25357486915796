<ng-container *ngIf="productRequest">
  <div class="approve-product-request">
    <ng-container *ngIf="rejectedItemIds.length > 0">
      <div class="input-row">
        <div class="label">Message for client explaining rejected items:</div>
        <input pInputText type="text" [(ngModel)]="rejectionReason">
      </div>
    </ng-container>
    <ng-container *ngIf="validationErrors.length > 0">
      <ng-container *ngFor="let validationError of validationErrors">
        <div class="error">{{ validationError }}</div>
      </ng-container>
    </ng-container>
    <div class="checkbox-row">
      <p-checkbox [(ngModel)]="notifyClient" [binary]="true"></p-checkbox>
      <div class="label">Notify client about this update</div>
    </div>
    <div class="button-row">
      <p-button (click)="submit()" label="Submit" styleClass="p-button-primary"></p-button>
    </div>
  </div>
</ng-container>
