import { paymentStatus } from "../constants/payment-statuses.constants";
import { PaymentStatus } from "../enums/payment-status";
import { IPaymentStatus } from "../interfaces/payment-status.interface";
import { Payment } from "../models/Payment";
import { hasPaymentAuthorisationExpired } from "./has-payment-authorisation-expired";

export function getPaymentStatus(payment: Payment): IPaymentStatus {
    const fallback = {
        id: PaymentStatus.NA,
        label: 'N/A',
        color: 'var(--surface-400)'
    }

    if (payment.cancelledAt) {
        return paymentStatus.find(status => status.id === PaymentStatus.CANCELLED) ?? fallback;
    }

    if (payment.refundOfId) {
        return paymentStatus.find(status => status.id === PaymentStatus.REFUND) ?? fallback;
    }

    if (payment.refundedAt) {
        return paymentStatus.find(status => status.id === PaymentStatus.REFUNDED) ?? fallback;
    }

    if (payment.syncedToPmsAt) {
        return paymentStatus.find(status => status.id === PaymentStatus.COMPLETE) ?? fallback;
    }

    if (payment.paidAt) {
        return paymentStatus.find(status => status.id === PaymentStatus.PAID) ?? fallback;
    }

    if (payment.queued) {
        return paymentStatus.find(status => status.id === PaymentStatus.QUEUED) ?? fallback;
    }

    if (payment.expiresAt.getTime() < new Date().getTime() && !payment.authorisedAt) {
        return paymentStatus.find(status => status.id === PaymentStatus.EXPIRED) ?? fallback;
    }

    if (payment.authorisedAt) {
        //If the payment was authorised over 7 days ago...
        if (hasPaymentAuthorisationExpired(payment)) {
            return paymentStatus.find(status => status.id === PaymentStatus.HOLD_EXPIRED) ?? fallback;
        } else {
            return paymentStatus.find(status => status.id === PaymentStatus.FUNDS_HELD) ?? fallback;
        }
    }
    
    return paymentStatus.find(status => status.id === PaymentStatus.PENDING) ?? fallback;
}