import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommentsPanelComponent } from './components/comments-panel/comments-panel.component';
import { CommentComponent } from './components/comment/comment.component';
import { UiModule } from '../ui/ui.module';
import { DateFnsModule } from 'ngx-date-fns';
import { AddCommentFormComponent } from './components/add-comment-form/add-comment-form.component';
import { FormsModule } from '@angular/forms';
import { UtilsModule } from '../utils/utils.module';
import {LetDirective, PushPipe} from '@ngrx/component';



@NgModule({
  declarations: [CommentsPanelComponent, CommentComponent, AddCommentFormComponent],
  imports: [
    CommonModule,
    PushPipe,
    LetDirective,
    UiModule,
    UtilsModule,
    DateFnsModule,
    FormsModule
  ],
  exports: [CommentsPanelComponent]
})
export class CommentsModule { }
