<ng-container *ngIf="practice">
    <ng-container *ngIf="step === 0">
        <div class="subtitle">{{'product_requests.new.search_client' | translate}}</div>
        <client-selector
            [noModal]="true"
            (clientSelected)="selectClient($event)"
        ></client-selector>
    </ng-container>

    <ng-container *ngIf="step === 1">
        <div class="subtitle">{{'product_requests.new.select_contact' | translate}}</div>
        <ng-container *ngIf="selectedClient">
            <div class="contact-selector-wrap">
                <div class="client-details">
                    <div class="name">
                        {{ selectedClient.title }}
                        {{ selectedClient.firstName }}
                        {{ selectedClient.lastName }} ({{
                            selectedClient.pmsDisplayId ?? selectedClient.pmsId
                        }})
                    </div>
                    <div class="address">
                        {{
                            selectedClient.address1
                                ? selectedClient.address1 + ","
                                : ""
                        }}
                        {{
                            selectedClient.address2
                                ? selectedClient.address2 + ","
                                : ""
                        }}
                        {{
                            selectedClient.address3
                                ? selectedClient.address3 + ","
                                : ""
                        }}
                        {{
                            selectedClient.postcode
                                ? selectedClient.postcode + ","
                                : ""
                        }}
                        {{ selectedClient.state }}
                    </div>
                </div>
                <ng-container
                    *ngFor="let contact of this.selectedClient.mobileContacts"
                >
                    <div class="contact-selector">
                        <div class="label">
                            {{ contact.name }}: {{ contact.value }}
                        </div>
                        <button
                            pButton
                            [label]="'product_requests.new.select' | translate"
                            class="p-button-sm p-button-ghost"
                            (click)="selectContact(contact)"
                        ></button>
                    </div>
                </ng-container>
            </div>
        </ng-container>
        <div class="next-step">
            <div class="back" (click)="goBackTo(0)">
                <img src="assets/images/icons/back.svg" alt="" />
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="step === 2">
        <div class="subtitle">
           {{'product_requests.new.select_channel' | translate}}
        </div>

        <div class="channel-dropdown-wrap">
            <p-dropdown
                [options]="channels"
                [(ngModel)]="currentChannelOption"
                optionLabel="name"
                optionDisabled="inactive"
                class="channel-dropdown"
            >
                <ng-template pTemplate="selectedItem">
                    <div
                        class="channel-item channel-item-selected {{
                            currentChannelOption.className
                        }}"
                        *ngIf="currentChannelOption"
                    >
                        <img
                            [src]="
                                'assets/images/icons/' +
                                currentChannelOption.icon
                            "
                            class="channel-icon"
                        />
                        <div>{{ currentChannelOption.name }}</div>
                    </div>
                </ng-template>
                <ng-template let-channel pTemplate="item">
                    <div class="channel-item {{ channel.className }}">
                        <img
                            [src]="'assets/images/icons/' + channel.icon"
                            class="channel-icon"
                        />
                        <div>{{ channel.name }}</div>
                    </div>
                </ng-template>
            </p-dropdown>
        </div>

        <div class="next-step">
            <div class="back" (click)="goBackTo(1)">
                <img src="assets/images/icons/back.svg" alt="" />
            </div>
            <p-button
                (click)="goToPatientStep()"
                label="{{ 'ui.new_conversation.next' | translate}}"
                styleClass="p-button-primary"
            ></p-button>
        </div>
    </ng-container>

    <ng-container *ngIf="step === 3">
        <div class="subtitle">{{'product_requests.new.select_patient' | translate}}</div>
        <div class="patient-select">
            <ng-container *ngIf="patients.length > 0 || !patientsLoading">
                <patient-selector
                    [disabled]="false"
                    [loading]="patientsLoading"
                    [patients]="patients"
                    (patientSelected)="selectPatient($event)"
                    (morePatients)="handleMorePatients()"
                ></patient-selector>
            </ng-container>
            <ng-container *ngIf="patientsLoading">
                <div class="patients-loading">
                    <p-progressSpinner
                        [style]="{ width: '24px', height: '24px' }"
                        styleClass="custom-spinner"
                        strokeWidth="4"
                    ></p-progressSpinner>
                </div>
            </ng-container>
        </div>
        <div class="next-step">
            <div class="back" (click)="goBackTo(2)">
                <img src="assets/images/icons/back.svg" alt="" />
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="step === 4 && selectedClient">
        <form [formGroup]="productForm" class="product-request-form">
            <div class="input-row assignee">
                <div class="label">{{'product_requests.new.assign_colleague' | translate}}</div>
                <user-changer
                    [selected]="selectedAssignee"
                    (userChange)="handleAssigneeChange($event)"
                ></user-changer>
            </div>

            <div class="items" formArrayName="items">
                <ng-container
                    *ngFor="let item of items()?.controls; let i = index"
                    [formGroupName]="i"
                >
                    <div class="item">
                        <div class="item-info">
                            <textarea
                                pInputTextarea
                                formControlName="name"
                                [placeholder]="'product_requests.new.enter_product' | translate"
                                class="item-name"
                                maxlength="200"
                            ></textarea>
                            <p-inputNumber
                                class="item-count"
                                formControlName="count"
                                [showButtons]="true"
                                [min]="1"
                                buttonLayout="vertical"
                                spinnerMode="vertical"
                                inputId="vertical"
                                decrementButtonClass="p-button-secondary"
                                incrementButtonClass="p-button-secondary"
                                incrementButtonIcon="pi pi-plus"
                                decrementButtonIcon="pi pi-minus"
                            >
                            </p-inputNumber>
                        </div>
                        <ng-container
                            *ngIf="
                                item.get('name')?.invalid &&
                                (item.get('name')?.dirty ||
                                    item.get('name')?.touched)
                            "
                        >
                            <div class="error">
                              {{'product_requests.new.must_enter_name' | translate}}
                            </div>
                        </ng-container>
                        <ng-container
                            *ngIf="
                                item.get('count')?.invalid &&
                                (item.get('count')?.dirty ||
                                    item.get('count')?.touched)
                            "
                        >
                            <ng-container
                                *ngIf="item.get('count')?.errors?.['required']"
                            >
                                <div class="error">
                                  {{'product_requests.new.enter_amount' | translate}}
                                </div>
                            </ng-container>
                            <ng-container
                                *ngIf="item.get('count')?.errors?.['max']"
                            >
                                <div class="error">
                                  {{'product_requests.new.requested_amount' | translate}}
                                </div>
                            </ng-container>
                        </ng-container>
                        <div class="input-row item-auth-row">
                            <div class="item-auth">
                                <div class="label">
                                  {{'product_requests.new.require_authorization' | translate}}
                                </div>
                                <p-selectButton
                                    [options]="approvalRequiredOptions"
                                    formControlName="approvalRequired"
                                    optionLabel="label"
                                    optionValue="value"
                                ></p-selectButton>
                            </div>
                            <ng-container *ngIf="items().length > 1">
                                <div class="remove" (click)="removeItem(i)">
                                  {{'product_requests.new.remove' | translate}}
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
            </div>

            <div class="add-item" (click)="addItem()">
                <i class="pi pi-plus"></i>
                {{'product_requests.new.add_item' | translate}}
            </div>
        </form>

        <div class="next-step">
            <div class="back" (click)="goBackTo(3)">
                <img src="assets/images/icons/back.svg" alt="" />
            </div>
            <p-button
                (click)="submit()"
                [label]="'product_requests.new.create_request' | translate"
                styleClass="p-button-primary"
            ></p-button>
        </div>
    </ng-container>

    <ng-container *ngIf="step === 5">
        <div class="subtitle">
          {{'product_requests.new.phone_number_invalid' | translate}}</div>
        <p class="descr">
          {{'product_requests.new.please' | translate}}</p>

        <div class="next-step">
            <div>
                <div class="back" (click)="goBackTo(0)">
                    <img src="assets/images/icons/back.svg" alt="" />
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>
