import {Component, Input, OnInit} from '@angular/core';
import {ProductRequest} from '../../../models/ProductRequest';
import {Store} from '@ngrx/store';
import {AppState} from '../../../state/reducers';
import {PaymentRequest} from '../../../interfaces/payment-request';
import {CreateProductRequestPayment, RejectProductRequest} from "../../state/actions";

@Component({
  selector: 'product-request-create-payment',
  templateUrl: './product-request-create-payment.component.html',
  styleUrls: ['./product-request-create-payment.component.scss']
})
export class ProductRequestCreatePaymentComponent implements OnInit {
  @Input() productRequest?: ProductRequest;
  paymentDescription = '';

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    if (this.productRequest) {
      this.paymentDescription = this.productRequest.items.map((item) => {
        return `${item.approvedQty}x ${item.approvedItem ?? item.description}`;
      }).join(', ');
    }
  }

  handlePaymentRequested(paymentRequest: PaymentRequest): void {
    if (this.productRequest) {
      this.store.dispatch(CreateProductRequestPayment({
        productRequest: this.productRequest,
        paymentRequest,
      }));
    }
  }
}
