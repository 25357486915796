<ng-container *ngIf="historyItem">
    <div class="title-row">
        <div class="title">
            {{ historyItem.date | dfnsFormat:"dd/MM/yyyy"}}<ng-container *ngIf="historyItem.location">{{'patients.patient_history_item.visit_at' | translate: {location: historyItem.location} }}</ng-container>
            <ng-container *ngIf="historyItem.vet"> {{'patients.patient_history_item.with' | translate: {location: historyItem.vet} }}</ng-container>
        </div>
    </div>
    <div class="notes-and-procedures">
        <div class="col">
            <div class="subtitle">{{'patients.patient_history_item.notes' | translate}}</div>
            <div class="notes">
                <ng-container *ngIf="historyItem.history && historyItem.history.length > 0">
                    <ng-container *ngFor="let note of historyItem.history">
                        <div class="note" [innerHtml]="note"></div>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="!historyItem.history || historyItem.history.length === 0">
                  {{'patients.patient_history_item.no_notes' | translate}}
                </ng-container>
            </div>
        </div>
        <div class="col">
            <div class="subtitle">{{'patients.patient_history_item.invoice' | translate}}</div>
            <ng-container *ngIf="historyItem.procedures && historyItem.procedures.length > 0">
                <div class="procedures">
                    <ng-container *ngFor="let procedure of historyItem.procedures">
                        <div class="procedure">
                            <procedure [procedure]="procedure"></procedure>
                        </div>
                    </ng-container>
                </div>
                <ng-container *ngIf="historyItem.charge">
                    <div class="total-row">
                        <div class="label">{{'patients.patient_history_item.grand_total' | translate}}</div>
                        <div class="value">{{ historyItem.charge | currency : practiceCurrency?.currencyCode : 'symbol-narrow' }}</div>
                    </div>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="!historyItem.procedures || historyItem.procedures.length == 0">
              {{'patients.patient_history_item.no_invoice' | translate}}
            </ng-container>
        </div>
    </div>
</ng-container>
