import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Conversation} from '../../../models/Conversation';
import {Dropdown} from 'primeng/dropdown';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../state/reducers';
import {
  BlockSenderClose,
  BlockSenderOpen,
  ExportConversationsAsPdfs,
  MarkConversationsAsRead,
  MarkConversationsAsUnread
} from '../../../conversation/state/actions';
import {getIsBlockSenderOpen} from '../../../conversation/state/selectors';
import {takeWhile} from 'rxjs/operators';

interface BatchAction {
  slug: string;
  label: string;
  disabled: boolean;
}

@Component({
  selector: 'conversation-bulk-actions',
  templateUrl: './conversation-bulk-actions.component.html',
  styleUrls: ['./conversation-bulk-actions.component.scss']
})
export class ConversationBulkActionsComponent implements OnInit, OnDestroy {
  @ViewChild('dropdown') dropdown?: Dropdown;
  @Output() actionFired = new EventEmitter();
  @Input() selectedConversations: Conversation[] = [];
  alive = true;
  batchActions: BatchAction[] = [
    {slug: 'mark-read', label: 'Mark as read', disabled: false},
    {slug: 'mark-unread', label: 'Mark as unread', disabled: false},
    {slug: 'export', label: 'Export as PDF', disabled: false},
    {slug: 'block', label: 'Block sender', disabled: false},
  ];
  selectedAction: BatchAction | null = null;
  blockSenderOpen = false;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.subscribeToBlockSenderOpen();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  handleAction(action?: BatchAction): void {
    if (action) {
      switch (action.slug) {
        case 'mark-read':
          this.store.dispatch(MarkConversationsAsRead({conversations: this.selectedConversations}));
          break;
        case 'mark-unread':
          this.store.dispatch(MarkConversationsAsUnread({conversations: this.selectedConversations}));
          break;
        case 'export':
          this.store.dispatch(ExportConversationsAsPdfs({conversations: this.selectedConversations}));
          break;
        case 'block':
          this.store.dispatch(BlockSenderOpen());
          return;
      }

      this.handleActionFired();
    }
  }

  closeBlockSender(): void {
    this.store.dispatch(BlockSenderClose());
    this.handleActionFired();
  }

  subscribeToBlockSenderOpen(): void {
    this.store
      .pipe(select(getIsBlockSenderOpen))
      .pipe(takeWhile(() => this.alive))
      .subscribe((isOpen) => {
        this.blockSenderOpen = isOpen;

        if (!isOpen) {
          this.handleActionFired();
        }
      });
  }

  handleActionFired(): void {
    this.actionFired.emit();

    setTimeout(() => {
      if (this.dropdown) {
        this.dropdown.clear();
      }
    }, 0);
  }
}
