import {Pipe, PipeTransform} from '@angular/core';
import {fieldTypeHasOptions} from '../helpers/field-type-has-options';
import {FormFieldType} from "../enums/form-field-type.enum";

@Pipe({name: 'fieldTypeHasOptions'})
export class FieldTypeHasOptionsPipe implements PipeTransform {
  transform(value: string): boolean {
    return fieldTypeHasOptions(value);
  }
}
