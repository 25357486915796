<div class="conversation-list-filters">
  <div class="top">
    <div class="left">
      <div class="filter-item filter-toggle" [class.active]="mobileFiltersOpen" (click)="toggleMobileFilters()">
        <div class="toggle">
          <ng-container *ngIf="!mobileFiltersOpen">
            <i class="pi pi-filter"></i>
          </ng-container>
          <ng-container *ngIf="mobileFiltersOpen">
            <i class="pi pi-times"></i>
          </ng-container>
          <div>{{'conversation.components.filters.filters' | translate}}</div>
        </div>
      </div>
      <div class="pipe"></div>
      <div class="filter-item clear-all" (click)="clearFilters()">
        {{'conversation.components.filters.reset' | translate}}
      </div>
    </div>
    <div class="center" [class.active]="mobileFiltersOpen">
      <div class="filters-group">
        <ng-container *ngTemplateOutlet="filters"></ng-container>
      </div>
      <div class="pipe"></div>
      <div class="filter-item clear-all" (click)="clearFilters()">
        {{'conversation.components.filters.reset' | translate}}
      </div>
    </div>
    <div class="right">
      <div class="filter-item with-dropdown filter single" [class.active]="sortByOpen" (clickOutside)="sortByOpen = false">
        <div class="toggle" (click)="toggleSortByDropdown()">
          <i class="pi pi-sort-alt"></i> {{ selectedSortBy.label | translate }} <dropdown-icon></dropdown-icon>
        </div>
        <div class="dropdown no-checkbox">
          <ng-container *ngFor="let sortByOption of sortByOptions">
            <div class="dropdown-item" (click)="sortByChanged(sortByOption)">
              <div class="label" [class.active]="selectedSortBy === sortByOption">{{ sortByOption.label | translate }}</div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #filters>
  <div class="filter-item with-dropdown filter highlight" [class.active]="statusFilterOpen" (clickOutside)="statusFilterOpen = false; this.recalcModalBackground()">
    <div class="toggle" (click)="toggleStatusDropdown()">
      <i class="pi pi-check"></i> {{ (selectedStatus) | conversationFilterStatusTranslationKey | translate  }} <dropdown-icon></dropdown-icon>
    </div>
    <div class="dropdown mobile-modal no-checkbox">
      <ng-container *ngFor="let statusOption of statusOptions">
        <div class="dropdown-item" (click)="statusChanged(statusOption)">
          <div class="label" [class.active]="selectedStatus === statusOption">{{ statusOption | conversationFilterStatusTranslationKey | translate }}</div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="filter-item with-dropdown filter" [class.active]="dateFilterOpen" [class.highlight]="selectedDate !== defaultSelectedDate" (clickOutside)="closeDateFilter($event); this.recalcModalBackground()">
    <div class="toggle" (click)="toggleDateDropdown()">
      <ng-container *ngIf="!selectedDate">
        <i class="pi pi-calendar"></i>
      </ng-container>
      <ng-container *ngIf="selectedDate">
        <i class="pi pi-check"></i>
      </ng-container>
      {{ selectedDate ? (selectedDate.label | translate) : ('conversation.components.filters.started' | translate) }} <dropdown-icon></dropdown-icon>
    </div>
    <div class="dropdown mobile-modal no-checkbox">
      <ng-container *ngFor="let dateOption of dateOptions">
        <div class="dropdown-item" (click)="dateChanged(dateOption)">
          <div class="label" [class.active]="selectedDate?.id === dateOption.id">{{ dateOption.label | translate }}</div>
        </div>
      </ng-container>
      <ng-container *ngIf="customRangeOpen">
        <div class="dropdown-calendar">
          <p-calendar (onSelect)="rangeSelected()" [(ngModel)]="rangeDates" [inline]="true" [firstDayOfWeek]="1" selectionMode="range" inputId="range"></p-calendar>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="filter-item with-dropdown filter" [class.active]="assigneeFilterOpen" [class.highlight]="selectedAssignees.length > 0" (clickOutside)="assigneeFilterOpen = false; this.recalcModalBackground()">
    <div class="toggle" (click)="toggleAssigneeDropdown()">
      <ng-container *ngIf="selectedAssignees.length == 0 || !this.selectedAssigneeLabel">
        <i class="pi pi-user"></i>
        {{'conversation.components.filters.assignee' | translate}}
      </ng-container>
      <ng-container *ngIf="selectedAssignees.length > 0 && this.selectedAssigneeLabel">
        <i class="pi pi-check"></i>
        {{ selectedAssigneeLabel }}
      </ng-container>
      <dropdown-icon></dropdown-icon>
    </div>
    <div class="dropdown low-padding mobile-modal">
      <p-listbox [options]="allUsers" [filter]="true" [filterPlaceHolder]="'conversation.components.filters.find_users_placeholder' | translate" styleClass="no-pad" optionLabel="fullName" [listStyle]="{'max-height':'300px', 'min-width': '300px'}">
        <ng-template let-user pTemplate="item">
          <div class="user-item">
            <label [for]="'assignee-'+user.id" class="label">
              <user-avatar [user]="user" size="small"></user-avatar>
              <div class="label-text">{{ user.firstName }} {{ user.lastName }}</div>
              <ng-container *ngIf="authUser && authUser.id === user.id">
                <span class="me">{{'conversation.components.filters.me' | translate}}</span>
              </ng-container>
            </label>
            <div class="right">
              <p-checkbox name="assignees" (onChange)="assigneesChanged()"  [(ngModel)]="selectedAssignees" [value]="user.id" [inputId]="'assignee-'+user.id"></p-checkbox>
            </div>
          </div>
        </ng-template>
      </p-listbox>
    </div>
  </div>
  <div class="filter-item with-dropdown filter" [class.active]="ownerFilterOpen" [class.highlight]="selectedOwners.length > 0" (clickOutside)="ownerFilterOpen = false; this.recalcModalBackground()">
    <div class="toggle" (click)="toggleOwnerDropdown()">
      <ng-container *ngIf="selectedOwners.length == 0 || !this.selectedOwnerLabel">
        <i class="pi pi-user"></i>
        {{'conversation.components.filters.owner' | translate}}
      </ng-container>
      <ng-container *ngIf="selectedOwners.length > 0 && this.selectedOwnerLabel">
        <i class="pi pi-check"></i>
        {{ selectedOwnerLabel }}
      </ng-container>
      <dropdown-icon></dropdown-icon>
    </div>
    <div class="dropdown low-padding mobile-modal">
      <p-listbox [options]="allUsers" [filter]="true" [filterPlaceHolder]="'conversation.components.filters.find_users_placeholder' | translate" styleClass="no-pad" optionLabel="fullName" [listStyle]="{'max-height':'300px', 'min-width': '200px'}">
        <ng-template let-user pTemplate="item">
          <div class="user-item">
            <label [for]="'owner-'+user.id" class="label">
              <user-avatar [user]="user" size="small"></user-avatar>
              <div class="label-text">{{ user.firstName }} {{ user.lastName }}</div>
              <ng-container *ngIf="authUser && authUser.id === user.id">
                <span class="me">{{'conversation.components.filters.me' | translate}}</span>
              </ng-container>
            </label>
            <div class="right">
              <p-checkbox name="owners" (onChange)="ownersChanged()" [(ngModel)]="selectedOwners" [value]="user.id" [inputId]="'owner-'+user.id"></p-checkbox>
            </div>
          </div>
        </ng-template>
      </p-listbox>
    </div>
  </div>

  <ng-container *ngIf="practice && (practice.userRole === superAdmin || practice.userRole === analyst)">
    <div class="filter-item with-dropdown filter" [class.active]="tagFilterOpen" [class.highlight]="selectedTags.length > 0" (clickOutside)="tagFilterOpen = false; this.recalcModalBackground()">
      <div class="toggle" (click)="toggleTagDropdown()">
        <ng-container *ngIf="selectedTags.length == 0 || !this.selectedTagLabel">
          {{'conversation.components.filters.tag' | translate}}
        </ng-container>
        <ng-container *ngIf="selectedTags.length > 0 && this.selectedTagLabel">
          <i class="pi pi-check"></i>
          {{ selectedTagLabel }}
        </ng-container>
        <dropdown-icon></dropdown-icon>
      </div>
      <div class="dropdown low-padding mobile-modal">
        <p-listbox [options]="allTags" [filter]="true" [filterPlaceHolder]="'conversation.components.filters.find_tags_placeholder' | translate" styleClass="no-pad " optionLabel="translatedName" [listStyle]="{'max-height':'300px', 'min-width': '300px'}">
          <ng-template let-tag pTemplate="item">
            <div class="tag-item">
              <label [for]="'tag-'+tag.id" class="label">
                <tag-avatar [tag]="tag" size="small"></tag-avatar>
                <div class="label" *ngIf="tag.id">
                  {{ 'tags.' + formatTagsKey(tag.name) | translate }}
                </div>
                <div class="label" *ngIf="!tag.id">
                  {{ tag.name}}
                </div>
              </label>
              <div class="right">
                <p-checkbox name="tag.name" (onChange)="tagsChanged()" [(ngModel)]="selectedTags"  [value]="tag.id" [inputId]="'tag-'+tag.id"></p-checkbox>
              </div>
            </div>
          </ng-template>
        </p-listbox>
      </div>
    </div>
  </ng-container>

</ng-template>

<div class="modal-bg" [class.active]="modalBackground"></div>
