import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, takeWhile } from 'rxjs';
import { AppState } from '../../state/reducers';
import { isConversationPreviewOpen } from '../../state/selectors';

@Component({
  selector: 'app-full-page-sidebar',
  templateUrl: './full-page-sidebar.component.html',
  styleUrls: ['./full-page-sidebar.component.scss']
})
export class FullPageSidebarComponent implements OnInit, OnDestroy {
  alive = true;
  showConversationPreview$: Observable<boolean>;

  constructor(private store: Store<AppState>) { 
    this.showConversationPreview$ = this.store.select(isConversationPreviewOpen).pipe(
      takeWhile(() => this.alive)
    );
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

}
