import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { AppState } from "../../../state/reducers";
import { ProductRequest } from "../../../models/ProductRequest";

import {
  AddProductRequestComment,
  AddProductRequestCommentSuccess,
  GetProductRequestClient,
  GetProductRequestComments,
  GetProductRequestHistory,
  GetProductRequestPatient,
  GetProductRequestPatientHistory,
  MoveProductRequest,
  UpdateProductRequestApproval,
  UpdateProductRequestAssignee,
  UpdateProductRequestItemApproval,
  UpdateProductRequestItemFulfilled,
  UpdateProductRequestOwner,
} from "../../state/actions";
import {
  getCurrentProductRequestClient,
  getCurrentProductRequestClientLoading,
  getCurrentProductRequestPatient,
  getCurrentProductRequestPatientHistory,
  getCurrentProductRequestPatientHistoryLoading,
  getCurrentProductRequestPatientLoading,
  getProductRequestComments,
  getProductRequestCommentsLoading,
  getProductRequestDetailContext,
  getProductRequestHistory,
  getProductRequestHistoryLoading,
} from "../../state/selectors";
import { takeWhile } from "rxjs/operators";
import { Comment } from "../../../models/Comment";
import { WebsocketService } from "../../../conversation/websocket.service";
import { CommentAdapter } from "../../../adapters/comment.adapter";
import { BehaviorSubject, interval, Observable, Subscription } from "rxjs";
import { ProductRequestStatus } from "../../../enums/product-request-status";
import { getAvailableProductRequestStatusDestinations } from "../../../helpers/get-available-product-request-status-destinations";
import {
  getCurrentPractice,
  getCurrentPracticeConfig,
} from "../../../practices/state/selectors";
import { ProductRequestItem } from "../../../models/ProductRequestItem";
import { ProductRequestApprovalStatus } from "../../../enums/product-request-approval-status";
import { ProductRequestDetailContext } from "../../../enums/product-request-detail-context";
import { itemCanBeDispensed } from "../../../helpers/item-can-be-dispensed";
import { Client } from "../../../models/Client";
import { Patient } from "../../../models/Patient";
import { HistoryItem } from "../../../models/HistoryItem";
import { Group } from "../../../models/Group";
import { User } from "../../../models/User";
import { PracticeConfigInterface } from "../../../interfaces/practice-config.interface";
import { Practice } from "../../../models/Practice";
import { AuditLog } from "../../../models/AuditLog";
import { Router } from "@angular/router";
import { getUser } from "../../../auth/state/selectors";
import { UserAdapter } from "../../../adapters/user.adapter";
import { GetPracticeStaff } from "../../../practices/state/actions";
import { PracticeFeature } from "../../../enums/practice-feature";
import { practiceHasFeature } from "../../../helpers/practice-has-feature";
import {
  ClearViewers,
  GetViewers,
  GetViewersSuccess,
} from "../../../viewers/state/actions";
import { ViewerType } from "../../../enums/viewers-type";
import { getViewersExludingSelf } from "../../../viewers/state/selectors";
import { Role } from "../../../enums/role";
import { CookieService } from "ngx-cookie-service";
import { ClearPatients } from "../../../patients/state/actions";
import {
  isClientShared,
  isPatientShared,
} from "../../../conversation/state/selectors";
import { PMS } from "../../../enums/pms";
import {
  GetPatientHistory,
  RemovePatientFromConversation,
  ShareClientToPms,
  SharePatientToPms,
} from "../../../conversation/state/actions";
import Delta from "quill-delta";

@Component({
  selector: "product-request-detail",
  templateUrl: "./product-request-detail.component.html",
  styleUrls: ["./product-request-detail.component.scss"],
})
export class ProductRequestDetailComponent implements OnInit, OnDestroy {
  @Input() productRequest?: ProductRequest;
  users: User[] = [];
  topBarMessage = "";
  getUserSocket$?: Subscription;
  removeUserSocket$?: Subscription;
  currentContext: ProductRequestDetailContext | null = null;
  alive = true;
  practice: Practice | null = null;
  config: PracticeConfigInterface | null = null;
  contexts = ProductRequestDetailContext;
  approvalStatus = ProductRequestApprovalStatus;
  status = ProductRequestStatus;
  comments: Comment[] = [];
  commentsLoading = true;
  history: AuditLog[] = [];
  historyLoading = true;
  socketComments$?: Subscription;
  activeTab = 1;
  userCanApprove = false;
  showingStatusActions = false;
  statusOptions: ProductRequestStatus[] = [];
  approvedItemIds: number[] = [];
  rejectedItemIds: number[] = [];
  rejectionReasons: string[] = [];
  approvedCount: number[] = [];
  approvedItem: string[] = [];
  fulfilledCount: number[] = [];
  errors: string[][] = [];
  client?: Client;
  patient?: Patient;
  patientHistory: HistoryItem[] = [];
  patientHistoryLoading = false;
  patientLoading = true;
  clientLoading = true;
  authUser$?: Observable<User | null>;
  authUser?: User;
  userSocket$?: Subscription;
  showUsers = false;
  viewers$: Observable<User[] | null> = new BehaviorSubject(null);
  viewers: User[] = [];
  lastSeenUpdates$?: Subscription;
  impersonator: string = "";
  canProceed: boolean = true;
  patientShared$?: Observable<boolean>;
  clientShared$?: Observable<boolean>;
  sharePatientLoading = false;
  shareClientLoading = false;

  constructor(
    public store: Store<AppState>,
    private websocketService: WebsocketService,
    private commentAdapter: CommentAdapter,
    private userAdapter: UserAdapter,
    private router: Router,
    private cookieService: CookieService,
  ) {}

  ngOnInit(): void {
    if (this.productRequest) {
      this.store.dispatch(ClearPatients());
      this.store.dispatch(
        GetProductRequestComments({ productRequestId: this.productRequest.id }),
      );
      this.store.dispatch(
        GetProductRequestHistory({ productRequestId: this.productRequest.id }),
      );
      this.store.dispatch(
        GetProductRequestClient({ clientId: this.productRequest.client.id }),
      );

      if (this.productRequest.patient.id) {
        this.store.dispatch(
          GetProductRequestPatient({
            patientId: this.productRequest.patient.id.toString(),
          }),
        );
      }
      this.checkForImpersonator();
      this.subscribeToAuthUser();
      this.subscribeToContext();
      this.subscribeToComments();
      this.subscribeToHistory();
      this.subscribeToClient();
      this.subscribeToPatient();
      this.subscribeToPatientHistory();
      this.subscribeToPractice();
      this.subscribeToPracticeConfig();
      this.subscribeToViewers();
      this.subscribeToPatientShared();
      this.subscribeToClientShared();

      this.websocketService.onConnect$.subscribe((connected) => {
        let sendUser = true;
        if (this.impersonator || this.practice?.userRole === Role.SUPER_ADMIN) {
          sendUser = false;
        }
        if (connected && this.productRequest && this.authUser) {
          this.websocketService.joinProductRequest(
            this.productRequest.id.toString(),
            this.authUser.id,
            sendUser,
          );
        }
      });

      this.store.dispatch(
        GetViewers({
          id: this.productRequest.id.toString(),
          modelType: ViewerType.PRODUCT_REQUEST,
        }),
      );

      this.client = this.productRequest.client;
      this.patient = this.productRequest.patient;

      this.approvedCount = [
        ...this.productRequest.items.map((item) => item.approvedQty),
      ];
      this.fulfilledCount = [
        ...this.productRequest.items.map((item) => item.fulfilledQty),
      ];
      this.rejectionReasons = [
        ...this.productRequest.items.map((item) => item.rejectionReason ?? ""),
      ];
      this.approvedItem = [
        ...this.productRequest.items.map((item) => item.approvedItem ?? ""),
      ];

      this.errors = [...this.productRequest.items.map((item) => [])];

      this.approvedItemIds = [
        ...this.productRequest.items
          .filter(
            (item) =>
              item.approvalStatus === ProductRequestApprovalStatus.APPROVED,
          )
          .map((item) => item.id),
      ];

      this.rejectedItemIds = [
        ...this.productRequest.items
          .filter(
            (item) =>
              item.approvalStatus === ProductRequestApprovalStatus.REJECTED,
          )
          .map((item) => item.id),
      ];

      this.lastSeenUpdates$ = interval(300000)
        .pipe(takeWhile(() => this.alive))
        .subscribe(() => {
          if (this.productRequest && this.authUser) {
            this.websocketService.updateLastSeen(
              this.productRequest.id.toString(),
              this.authUser.id,
              ViewerType.PRODUCT_REQUEST,
            );
          }
        });

      this.websocketService
        .getViewersFromProductRequest()
        .pipe(takeWhile(() => this.alive))
        .subscribe((data) => {
          const viewers = data.map((viewer) => this.userAdapter.run(viewer));
          this.store.dispatch(GetViewersSuccess({ viewers }));
        });
    }

    this.socketComments$ = this.websocketService
      .getProductRequestComments()
      .subscribe((data) => {
        this.store.dispatch(
          AddProductRequestCommentSuccess({
            comment: this.commentAdapter.run({
              ...data,
              content: JSON.parse(data.content),
            }),
          }),
        );
      });
  }

  ngOnDestroy(): void {
    this.alive = false;

    if (this.productRequest && this.authUser) {
      this.websocketService.leaveProductRequest(
        this.productRequest.id.toString(),
        this.authUser.id,
      );
    }
    this.socketComments$?.unsubscribe();
    this.getUserSocket$?.unsubscribe();
    this.removeUserSocket$?.unsubscribe();
    this.lastSeenUpdates$?.unsubscribe();
    this.store.dispatch(ClearViewers());
  }

  subscribeToAuthUser(): void {
    this.authUser$ = this.store
      .pipe(select(getUser))
      .pipe(takeWhile(() => this.alive));

    this.authUser$.subscribe((user) => {
      if (user) {
        this.authUser = user;
        this.store.dispatch(GetPracticeStaff());
      }
    });
  }

  handleSharePatientToPms(patient: Patient): void {
    if (
      this.practice?.pms === PMS.PROVET ||
      this.practice?.pms === PMS.ASCEND ||
      this.practice?.pms === PMS.EZYVET
    ) {
      if (patient.url) {
        window.open(patient.url, "_blank")?.focus();
      }
      return;
    }

    this.sharePatientLoading = true;
    this.store.dispatch(SharePatientToPms({ patient }));
  }

  handleShareClientToPms(client: Client): void {
    if (
      this.practice?.pms === PMS.PROVET ||
      this.practice?.pms === PMS.ASCEND ||
      this.practice?.pms === PMS.EZYVET
    ) {
      if (client.url) {
        window.open(client.url, "_blank")?.focus();
      }
      return;
    }

    this.shareClientLoading = true;
    this.store.dispatch(ShareClientToPms({ client }));
  }

  checkForImpersonator(): void {
    const impersonator = this.cookieService.get("impersonate");
    if (impersonator) {
      this.impersonator = impersonator;
    }
  }

  subscribeToViewers(): void {
    this.viewers$ = this.store
      .pipe(select(getViewersExludingSelf))
      .pipe(takeWhile(() => this.alive));

    this.viewers$.subscribe((viewers) => {
      if (viewers) {
        this.viewers = viewers;
      }
    });
  }

  subscribeToPatientShared(): void {
    this.patientShared$ = this.store
      .pipe(select(isPatientShared))
      .pipe(takeWhile(() => this.alive));
  }

  subscribeToClientShared(): void {
    this.clientShared$ = this.store
      .pipe(select(isClientShared))
      .pipe(takeWhile(() => this.alive));
  }

  subscribeToContext(): void {
    this.store
      .pipe(select(getProductRequestDetailContext))
      .pipe(takeWhile(() => this.alive))
      .subscribe((context) => {
        setTimeout(() => {
          this.currentContext = context;
          console.log("context :>> ", context);

          if (this.productRequest) {
            if (this.currentContext === ProductRequestDetailContext.REJECT) {
              this.rejectedItemIds = [
                ...this.rejectedItemIds,
                ...this.productRequest.items
                  .filter(
                    (item) =>
                      item.approvalStatus ===
                      ProductRequestApprovalStatus.PENDING,
                  )
                  .map((item) => item.id),
              ];
            }

            if (this.currentContext === ProductRequestDetailContext.APPROVE) {
              this.approvedItemIds = [
                ...this.approvedItemIds,
                ...this.productRequest.items
                  .filter(
                    (item) =>
                      item.approvalStatus ===
                      ProductRequestApprovalStatus.PENDING,
                  )
                  .map((item) => item.id),
              ];
            }
          }
        }, 0);
      });
  }

  handleCommentSent(message: Delta): void {
    if (this.productRequest) {
      this.store.dispatch(
        AddProductRequestComment({
          productRequestId: this.productRequest.id,
          message: JSON.stringify(message),
        }),
      );
    }
  }

  subscribeToComments(): void {
    this.store
      .select(getProductRequestComments)
      .pipe(takeWhile(() => this.alive))
      .subscribe((comments) => {
        this.comments = comments;
      });

    this.store
      .select(getProductRequestCommentsLoading)
      .pipe(takeWhile(() => this.alive))
      .subscribe((loading) => {
        this.commentsLoading = loading;
      });
  }

  subscribeToHistory(): void {
    this.store
      .select(getProductRequestHistory)
      .pipe(takeWhile(() => this.alive))
      .subscribe((history) => {
        this.history = history;
      });

    this.store
      .select(getProductRequestHistoryLoading)
      .pipe(takeWhile(() => this.alive))
      .subscribe((loading) => {
        this.historyLoading = loading;
      });
  }

  subscribeToClient(): void {
    this.store
      .select(getCurrentProductRequestClientLoading)
      .pipe(takeWhile(() => this.alive))
      .subscribe((loading) => {
        this.clientLoading = loading;
        this.sharePatientLoading = false;
        this.shareClientLoading = false;
      });

    this.store
      .select(getCurrentProductRequestClient)
      .pipe(takeWhile(() => this.alive))
      .subscribe((client) => {
        if (client) {
          this.client = client;
        }
      });
  }

  subscribeToPatient(): void {
    this.store
      .select(getCurrentProductRequestPatientLoading)
      .pipe(takeWhile(() => this.alive))
      .subscribe((loading) => {
        this.patientLoading = loading;
        this.sharePatientLoading = false;
        this.shareClientLoading = false;
      });

    this.store
      .select(getCurrentProductRequestPatient)
      .pipe(takeWhile(() => this.alive))
      .subscribe((patient) => {
        if (patient) {
          this.patient = patient;
        }
      });
  }

  subscribeToPatientHistory(): void {
    this.store
      .select(getCurrentProductRequestPatientHistoryLoading)
      .pipe(takeWhile(() => this.alive))
      .subscribe((loading) => {
        this.patientHistoryLoading = loading;
      });

    this.store
      .select(getCurrentProductRequestPatientHistory)
      .pipe(takeWhile(() => this.alive))
      .subscribe((history) => {
        this.patientHistory = history;
      });
  }

  subscribeToPractice(): void {
    this.store
      .pipe(select(getCurrentPractice))
      .pipe(takeWhile(() => this.alive))
      .subscribe((practice) => {
        this.practice = practice;
        this.showUsers = practiceHasFeature(
          practice,
          PracticeFeature.SHOW_TEAM_LOCATION,
        );
        this.setUpStatusOptions();
        this.setUpUserCanApprove();
      });
  }

  subscribeToPracticeConfig(): void {
    this.store
      .pipe(select(getCurrentPracticeConfig))
      .pipe(takeWhile(() => this.alive))
      .subscribe((config) => {
        this.config = config;
        this.setUpStatusOptions();
      });
  }

  setUpStatusOptions(): void {
    if (this.practice && this.config && this.productRequest) {
      this.statusOptions = getAvailableProductRequestStatusDestinations(
        this.productRequest,
        this.practice,
        this.config,
      );
    }
  }

  isProductStatusPaid(): boolean {
    return this.productRequest?.status === ProductRequestStatus.PAID;
  }

  setUpUserCanApprove(): void {
    if (this.practice && this.productRequest) {
      this.userCanApprove = !!(
        this.practice &&
        this.practice.userPermissions.canAuthoriseProductRequests &&
        this.productRequest.status === ProductRequestStatus.AWAITING_APPROVAL
      );
    }
  }

  gotToTab(tab: number): void {
    this.activeTab = tab;
  }

  toggleStatusActions(): void {
    this.setUpStatusOptions();
    this.setUpUserCanApprove();
    this.showingStatusActions = !this.showingStatusActions;
  }

  moveToStatus(status: ProductRequestStatus): void {
    if (this.productRequest) {
      this.store.dispatch(
        MoveProductRequest({ productRequest: this.productRequest, status }),
      );
    }
  }

  redirectToClient(clientId: string): void {
    this.router.navigateByUrl(`/clients/${clientId}`);
  }

  handleApproveClicked(item: ProductRequestItem, index: number): void {
    this.errors[index] = [];

    if (!this.approvedItemIds.includes(item.id)) {
      this.approvedItemIds = [...this.approvedItemIds, item.id];
    }

    this.rejectedItemIds = [
      ...this.rejectedItemIds.filter((id) => id !== item.id),
    ];
  }

  handleRejectClicked(item: ProductRequestItem, index: number): void {
    this.errors[index] = [];

    if (!this.rejectedItemIds.includes(item.id)) {
      this.rejectedItemIds = [...this.rejectedItemIds, item.id];
    }

    this.approvedItemIds = [
      ...this.approvedItemIds.filter((id) => id !== item.id),
    ];
  }

  handleSaveOrCancelItem(event: boolean, index: number) {
    this.canProceed = event;
  }

  handleUpdateItem(item: ProductRequestItem, index: number): void {
    if (this.userCanApprove) {
      this.errors[index] = [];
      const errors = this.validateItemUpdate(item, index);

      if (errors.length > 0) {
        this.errors[index] = errors;
        return;
      }

      this.store.dispatch(
        UpdateProductRequestItemApproval({
          productRequestItem: item,
          approvalStatus: this.approvedItemIds.find((id) => id === item.id)
            ? ProductRequestApprovalStatus.APPROVED
            : ProductRequestApprovalStatus.REJECTED,
          approvedCount: this.approvedCount[index],
          rejectionReason: this.rejectionReasons[index],
          approvedItem: this.approvedItem[index],
        }),
      );
    }

    if (this.productRequest && itemCanBeDispensed(this.productRequest, item)) {
      this.errors[index] = [];
      this.store.dispatch(
        UpdateProductRequestItemFulfilled({
          productRequestItem: item,
          fulfilledCount: this.fulfilledCount[index],
        }),
      );
    }
  }

  validateItemUpdate(item: ProductRequestItem, index: number): string[] {
    const errors = [];

    if (this.productRequest?.status === ProductRequestStatus.PAID) {
      if (item.fulfilledQty === 0) {
        errors.push("You must dispense at least one of this item.");
      }
    } else {
      if (this.approvedItemIds.find((id) => id === item.id)) {
        // item is set to approved

        if (this.approvedCount[index] < 1) {
          errors.push("You must approve at least one item.");
        }

        if (
          this.approvedItem[index] === null ||
          this.approvedItem[index]?.trim() === ""
        ) {
          errors.push("You must enter the name of the approved item.");
        }
      }

      if (this.rejectedItemIds.find((id) => id === item.id)) {
        // item is set to rejected

        if (
          this.rejectionReasons[index] === null ||
          this.rejectionReasons[index]?.trim() === ""
        ) {
          errors.push("You must enter a reason for rejecting this product.");
        }
      }

      if (
        !this.approvedItemIds.find((id) => id === item.id) &&
        !this.rejectedItemIds.find((id) => id === item.id) &&
        item.approvalStatus !== ProductRequestApprovalStatus.NOT_REQUIRED
      ) {
        errors.push("You must either reject or approve this product.");
      }
    }

    if (!this.canProceed) {
      errors.push("You must save or cancel changes made for this item.");
    }

    return errors;
  }

  handleRevertItemChanges(item: ProductRequestItem, i: number): void {
    if (this.productRequest) {
      this.approvedCount = [
        ...this.approvedCount.map((count, index) => {
          if (index === i) {
            return item.approvedQty;
          }

          return count;
        }),
      ];

      this.fulfilledCount = [
        ...this.fulfilledCount.map((count, index) => {
          if (index === i) {
            return item.fulfilledQty;
          }

          return count;
        }),
      ];

      this.errors[i] = [];

      this.rejectionReasons = [
        ...this.rejectionReasons.map((reason, index) => {
          if (index === i) {
            return item.rejectionReason ?? "";
          }

          return reason;
        }),
      ];

      this.approvedItem = [
        ...this.approvedItem.map((reason, index) => {
          if (index === i) {
            return item.approvedItem ?? "";
          }

          return reason;
        }),
      ];

      this.approvedItemIds = [
        ...this.approvedItemIds.filter((id) => id !== item.id),
      ];

      this.rejectedItemIds = [
        ...this.rejectedItemIds.filter((id) => id !== item.id),
      ];

      if (item.approvalStatus === ProductRequestApprovalStatus.APPROVED) {
        this.approvedItemIds.push(item.id);
      } else if (
        item.approvalStatus === ProductRequestApprovalStatus.REJECTED
      ) {
        this.rejectedItemIds.push(item.id);
      }
    }
  }

  validateItemDispensed(): boolean {
    let isReadyToBeDispensed = true;
    const itemStatuses = [
      ProductRequestApprovalStatus.APPROVED,
      ProductRequestApprovalStatus.NOT_REQUIRED,
    ];
    this.productRequest?.items.forEach((item, index) => {
      this.errors[index] = [];
      if (
        itemStatuses.includes(item.approvalStatus) &&
        item.fulfilledQty === 0
      ) {
        this.errors[index] = ["You must fill quantity of approved items"];
      }
      isReadyToBeDispensed = false;
    });
    return isReadyToBeDispensed;
  }

  submit(): void {
    if (this.productRequest) {
      if (this.isProductStatusPaid()) {
        if (this.validateItemDispensed()) {
          return;
        }
        this.moveToStatus(this.statusOptions[0]);
        return;
      }
      let anyErrors = false;
      this.productRequest.items.forEach((item, index) => {
        this.errors[index] = [];
        const errors = this.validateItemUpdate(item, index);

        if (errors.length > 0) {
          this.errors[index] = errors;
          anyErrors = true;
        }
      });

      if (anyErrors) {
        return;
      }

      if (this.currentContext === ProductRequestDetailContext.DISPENSE) {
        this.moveToStatus(ProductRequestStatus.FULFILLED);
      } else {
        const approvalCounts = this.productRequest.items.map((item, index) => {
          return {
            itemId: item.id,
            approvedQty: this.approvedCount[index],
            approvedItem: this.approvedItem[index],
          };
        });

        const rejectionReasons = this.productRequest.items.map(
          (item, index) => {
            return {
              itemId: item.id,
              reason: this.rejectionReasons[index],
            };
          },
        );

        this.store.dispatch(
          UpdateProductRequestApproval({
            productRequest: this.productRequest,
            approvalCounts,
            rejectionReasons,
          }),
        );
      }
    }
  }

  handlePatientHistoryOpened(patientId: string): void {
    this.store.dispatch(GetProductRequestPatientHistory({ patientId }));
  }

  handleOwnerChange(newOwner: User): void {
    if (this.productRequest) {
      this.store.dispatch(
        UpdateProductRequestOwner({
          owner: newOwner,
          productRequest: this.productRequest,
        }),
      );
    }
  }

  handleAssigneeChange(newAssignee: User | Group): void {
    if (this.productRequest) {
      this.store.dispatch(
        UpdateProductRequestAssignee({
          assignee: newAssignee,
          productRequest: this.productRequest,
        }),
      );
    }
  }

  trackItem(index: number, item: ProductRequestItem): number | undefined {
    return item ? item.id : undefined;
  }
}