import { Pipe, PipeTransform } from "@angular/core";

export enum Channel {
  WHATSAPP = "WhatsApp",
  WHATSAPP360 = "WhatsApp360",
  WHATSAPP360CLOUD = "WhatsApp360Cloud",
  SMS = "SMS",
  INTERNAL = "Internal",
  FACEBOOK = "Facebook",
  INSTAGRAM = "Instagram",
}

const enumToTranslation: Record<Channel, string> = {
  [Channel.FACEBOOK]: 'enums.channel.facebook',
  [Channel.INSTAGRAM]: 'enums.channel.instagram',
  [Channel.INTERNAL]: 'enums.channel.internal',
  [Channel.SMS]: 'enums.channel.sms',
  [Channel.WHATSAPP]: 'enums.channel.whatsapp',
  [Channel.WHATSAPP360]: 'enums.channel.whatsapp360',
  [Channel.WHATSAPP360CLOUD]: 'enums.channel.whatsapp360cloud',
}

@Pipe({name: 'channelTranslationKey'})
export class ChannelTranslationKeyPipe implements PipeTransform {
  transform(value: Channel): string {
    return enumToTranslation[value];
  }
}
