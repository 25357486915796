import { Observable, of } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { EnvironmentService } from "./services/environment.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { OneSignal } from "onesignal-ngx";
import { Store } from "@ngrx/store";
import { AppState } from "./state/reducers";
import {
  SetOneSignalNotificationsEnabled,
  SetOneSignalUserId,
} from "./state/actions";
import { AuthService } from "./auth/auth.service";
import { CurrencyDto } from "./interfaces/dto/currency.dto";
import { Practice } from "./models/Practice";
import { Tag } from "./models/Tag";
import { TagDto } from "./interfaces/dto/tag.dto";

@Injectable({
  providedIn: "root",
})
export class AppService {
  constructor(
    private environmentService: EnvironmentService,
    private http: HttpClient,
    private oneSignal: OneSignal,
    private store: Store<AppState>,
    private authService: AuthService,
  ) {}

  doHealthCheck(): Observable<boolean> {
    const url = this.environmentService.get("backendUrl") + `/healthy`;
    return this.http.get<boolean>(url, { withCredentials: true }).pipe(
      switchMap((response) => of(response)),
      catchError((response) => of(false)),
    );
  }

  async initialiseOneSignal(): Promise<void> {
    await this.oneSignal.init({
      appId: this.environmentService.get("oneSignal.appId"),
      safari_web_id: this.environmentService.get("oneSignal.safariWebId"),
      promptOptions: {
        slidedown: {
          prompts: [
            {
              type: "push",
              autoPrompt: false,
              text: {
                actionMessage:
                  "Digital Practice can push notifications to your device so you don't miss important updates!",
                acceptButton: "Allow",
              },
            },
          ],
        },
      },
    });

    await this.oneSignal.isPushNotificationsEnabled(async (isEnabled) => {
      this.getAndStoreOneSignalUserId();

      if (!isEnabled) {
        this.oneSignal.showSlidedownPrompt();
      }

      this.handleNotificationPreference(isEnabled);
    });

    this.oneSignal.on("subscriptionChange", async (isSubscribed: boolean) => {
      this.getAndStoreOneSignalUserId();
      this.handleNotificationPreference(isSubscribed);
    });
  }

  private async getAndStoreOneSignalUserId(): Promise<void> {
    const userId = await this.oneSignal.getUserId();
    if (userId) {
      this.store.dispatch(SetOneSignalUserId({ userId }));
    }
  }

  private handleNotificationPreference(enabled: boolean): void {
    if (enabled) {
      this.handleNotificationsEnabled();
    } else {
      this.handleNotificationsDisabled();
    }
  }

  private handleNotificationsEnabled(): void {
    this.authService.updateUserDevices();
    this.store.dispatch(SetOneSignalNotificationsEnabled({ enabled: true }));
  }

  private handleNotificationsDisabled(): void {
    this.authService.removeUserDevice();
    this.store.dispatch(SetOneSignalNotificationsEnabled({ enabled: false }));
  }

  getCurrencies(): Observable<CurrencyDto[]> {
    const url = this.environmentService.get("backendUrl") + `/currencies`;
    return this.http
      .get<CurrencyDto[]>(url, { withCredentials: true })
      .pipe(switchMap((response) => of(response)));
  }

  getTags(): Observable<TagDto[]> {
    const url = this.environmentService.get("backendUrl") + `/tags`;
    return this.http
      .get<TagDto[]>(url, { withCredentials: true })
      .pipe(switchMap((response) => of(response)));
  }
}
