import { Injectable } from '@angular/core';
import {TagDto} from '../interfaces/dto/tag.dto';
import {Tag} from '../models/Tag';

@Injectable({
  providedIn: 'root'
})
export class TagAdapter {
  run(data: TagDto): Tag {
    return {
      id: data.id,
      name: data.name
    };
  }
}
