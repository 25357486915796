<div class="row">
    <!-- <div class="col icon">
        <img src="assets/images/icons/icon-client.svg" alt="">
    </div> -->
    <div class="col">
        <div class="header">
            <ng-container *ngIf="!couldntAttemptMatch && (multiplePotentialClients && multiplePotentialClients > 1)">
                <div class="heading">
                    <img src="../../../../assets/images/icons/icon-client.svg" class="icon">
                    <div class="text">{{'ui.no_client_panel.count_records' | translate: {count: multiplePotentialClients} }}</div>
                </div>
            </ng-container>
            <ng-container *ngIf="!couldntAttemptMatch && (!multiplePotentialClients || multiplePotentialClients <= 1)">
              <ng-container *ngIf="user$ | async">
                  <client-not-recognised [user]="user$ | async"></client-not-recognised>
              </ng-container>
              <ng-container *ngIf="!(user$ | async)">
                <div class="heading">
                    <img src="../../../../assets/images/icons/icon-client.svg" class="icon">
                    <div class="text">{{'ui.no_client_panel.select_client' | translate}}</div>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="couldntAttemptMatch">
              <div class="heading">
                <img src="../../../../assets/images/icons/icon-client.svg" class="icon">
                <div class="text">{{'ui.no_client_panel.unmatched' | translate}}</div>
                <div class="error">
                  {{'ui.no_client_panel.error' | translate}}
                  <ng-container *ngIf="!reloadingClient">
                    <span class="reload-client" (click)="reloadClient()">{{'ui.no_client_panel.try_again' | translate}}</span>
                  </ng-container>
                  <ng-container *ngIf="reloadingClient">
                    <p-progressSpinner [style]="{width: '16px', height: '16px'}" styleClass="pink-spinner" strokeWidth="6"></p-progressSpinner>
                  </ng-container>
                </div>
              </div>
            </ng-container>
        </div>
        <client-selector [disabled]="disabled" (clientSelected)="updateClient($event)" [searchString]="multiplePotentialClients > 1 ? phoneStr : ''" #clientSelector></client-selector>
    </div>
</div>
