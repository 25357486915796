import { Component, Input, OnInit } from '@angular/core';
import { ConversationUser } from 'src/app/models/ConversationUser';

@Component({
  selector: 'client-not-recognised',
  templateUrl: './client-not-recognised.component.html',
  styleUrls: ['./client-not-recognised.component.scss']
})
export class ClientNotRecognisedComponent implements OnInit {
  @Input() user?: ConversationUser | null;

  constructor() { }

  ngOnInit(): void {
  }

}
