import {DateFilterOption} from '../interfaces/date-filter-option.interface';
import {DateFilterShorthand} from '../enums/date-filter-shorthand';
import { FilterOptionType } from '../enums/filter-option-type';

export const dateFilterOptions: DateFilterOption[] = [
  {
    id: DateFilterShorthand.ANYTIME,
    label: 'constants.date_filter_options.anytime',
    type: FilterOptionType.STANDARD
  },
  {
    id: DateFilterShorthand.ONE_DAY,
    label: 'constants.date_filter_options.one_day',
    type: FilterOptionType.STANDARD
  },
  {
    id: DateFilterShorthand.THREE_DAYS,
    label: 'constants.date_filter_options.three_days',
    type: FilterOptionType.STANDARD
  },
  {
    id: DateFilterShorthand.SEVEN_DAYS,
    label: 'constants.date_filter_options.seven_days',
    type: FilterOptionType.STANDARD
  },
  {
    id: DateFilterShorthand.FOURTEEN_DAYS,
    label: 'constants.date_filter_options.fourteen_days',
    type: FilterOptionType.STANDARD
  },
  {
    id: DateFilterShorthand.ONE_MONTH,
    label: 'constants.date_filter_options.one_month',
    type: FilterOptionType.STANDARD
  },
  {
    id: DateFilterShorthand.TWO_MONTHS,
    label: 'constants.date_filter_options.two_months',
    type: FilterOptionType.STANDARD
  },
  {
    id: DateFilterShorthand.SIX_MONTHS,
    label: 'constants.date_filter_options.six_months',
    type: FilterOptionType.STANDARD
  },
  {
    id: DateFilterShorthand.ONE_YEAR,
    label: 'constants.date_filter_options.one_year',
    type: FilterOptionType.STANDARD
  },
  {
    id: DateFilterShorthand.CUSTOM,
    label: 'constants.date_filter_options.custom',
    type: FilterOptionType.DATE_RANGE,
  }
];
