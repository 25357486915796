import { Component, Input } from '@angular/core';
import { ProductRequest } from '../../../models/ProductRequest';

@Component({
  selector: 'product-request-status',
  templateUrl: './product-request-status.component.html',
  styleUrls: ['./product-request-status.component.scss']
})
export class ProductRequestStatusComponent {
  @Input() productRequest: ProductRequest | null = null;
}
