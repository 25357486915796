import { Pipe, PipeTransform } from '@angular/core';
import { MergeFieldContent } from '../enums/merge-field-content';
import { prettifyTemplateContent } from '../helpers/prettify-template-content';

@Pipe({name: 'prettifyTemplateContent'})
export class PrettifyTemplateContentPipe implements PipeTransform {
  transform(value: MergeFieldContent | string): string {
    return prettifyTemplateContent(value as MergeFieldContent);
  }
}
