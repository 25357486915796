import { Injectable } from '@angular/core';
import { Feature } from '../models/Feature';
import { FeatureDto } from '../interfaces/dto/feature.dto';

@Injectable({
  providedIn: 'root'
})
export class FeatureAdapter {
  constructor() {
  }

  run(dto: FeatureDto): Feature {
    return {
      ...dto
    };
  }
}
