<div class="conversation-bulk-actions">
  <p-dropdown
    #dropdown
    [options]="batchActions"
    [disabled]="selectedConversations.length === 0"
    [(ngModel)]="selectedAction"
    (ngModelChange)="handleAction($event)"
    [placeholder]="'ui.conversation_bulk_actions.actions' | translate"
    styleClass="actions-selector"
    optionLabel="label"
  >
  </p-dropdown>
</div>

<p-dialog
  [header]="'ui.conversation_bulk_actions.block_sender' | translate"
  [(visible)]="blockSenderOpen"
  [modal]="true"
  [style]="{width: '400px'}"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
  [closable]="false"
  (close)="closeBlockSender()"
>
  <block-sender (closed)="closeBlockSender()" [conversations]="selectedConversations"></block-sender>
</p-dialog>
