import {createFeatureSelector, createSelector} from '@ngrx/store';
import {AppState, STATE_KEY} from '../../state/reducers';
import {MessagesState} from './reducer';
import {MessageStatus} from '../../enums/message-status';

export const getMessagesState = createFeatureSelector<AppState, MessagesState>(STATE_KEY.MESSAGES);

export const getMessages = createSelector(
    getMessagesState,
    (state: MessagesState) => {
      if (state.messages) {
        const sortable = [...state.messages];

        return sortable.sort((a, b) => {
          return a.createdAt.getTime() - b.createdAt.getTime();
        });
      }

      return state.messages;
    },
);

export const getMessagesReversed = createSelector(
  getMessagesState,
  (state: MessagesState) => {
    if (state.messages) {
      const sortable = [...state.messages];

      const queued = sortable
        .filter((message) => message.status === MessageStatus.queued)
        .sort((a, b) => {
          return b.createdAt.getTime() - a.createdAt.getTime();
        });

      const sent = sortable
        .filter((message) => message.status !== MessageStatus.queued)
        .sort((a, b) => {
          return b.createdAt.getTime() - a.createdAt.getTime();
        });

      return [
        ...queued,
        ...sent
      ];
    }

    return state.messages;
  },
);

export const isSendingFile = createSelector(
  getMessagesState,
  (state: MessagesState) => state.fileSending
);

export const isSendingAudio = createSelector(
  getMessagesState,
  (state: MessagesState) => state.audioSending
);

export const isSendingVideo = createSelector(
  getMessagesState,
  (state: MessagesState) => state.videoSending
);

export const isVideoUploading = createSelector(
  getMessagesState,
  (state: MessagesState) => state.videoUploading
);

export const isVideoProcessing = createSelector(
  getMessagesState,
  (state: MessagesState) => state.videoProcessing
);

export const getVideoUploadPercentage = createSelector(
  getMessagesState,
  (state: MessagesState) => state.videoUploadProgress
);

export const getVideoCompressionJobId = createSelector(
  getMessagesState,
  (state: MessagesState) => state.videoCompressionJobId
);
