<ng-container *ngIf="productRequest">
  <div class="product-request-card" (click)="openDetail()">
    <ng-container *ngIf="updating">
      <div class="updating-overlay">
        <p-progressSpinner [style]="{width: '32px', height: '32px'}" strokeWidth="6"></p-progressSpinner>
      </div>
    </ng-container>
    <div class="left">
      <div class="client">
        <ng-container *ngIf="productRequest.client">
          {{ productRequest.client.fullName }}
        </ng-container>
        <ng-container *ngIf="!productRequest.client">
          {{'product_requests.card.not_set' | translate}}
        </ng-container>
      </div>

      <div class="patient">
        <ng-container *ngIf="productRequest.patient">
          {{ productRequest.patient.name }}
          <ng-container *ngIf="productRequest.patient.breed">
            - {{ productRequest.patient.breed }}
          </ng-container>
          <ng-container *ngIf="productRequest.patient.species">
            ({{ productRequest.patient.species }})
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!productRequest.patient">
          {{'product_requests.card.not_set' | translate}}
        </ng-container>
      </div>

      <div class="product-name">
        <ng-container *ngIf="productRequest.items.length > 0">
          {{ productRequest.items[0].requestedQty }}x {{ productRequest.items[0].approvedItem ?? productRequest.items[0].description }}
        </ng-container>
        <ng-container *ngIf="productRequest.items.length == 2">
          <div class="additional-items">
            {{'product_requests.card.plus_one_item' | translate}}
          </div>
        </ng-container>
        <ng-container *ngIf="productRequest.items.length > 2">
          <div class="additional-items">
            {{'product_requests.card.plus_count_item' | translate: {count: productRequest.items.length-1 } }}
          </div>
        </ng-container>
      </div>

      <div class="approval-status">
        <product-request-approval-status [productRequest]="productRequest"></product-request-approval-status>
      </div>
    </div>
    <div class="right">
      <div class="created-date">
        {{ productRequest.createdAt | diffForHumans:refresh }}
      </div>
      <div class="owner">
        <div class="label">{{'product_requests.card.owner' | translate}}</div>
        <user-avatar [user]="productRequest.owner" size="small" unsetInitials="-"></user-avatar>
      </div>
      <div class="assignee">
        <div class="label">{{'product_requests.card.assignee' | translate}}</div>
        <user-avatar [user]="productRequest.assignee" size="small" unsetInitials="-"></user-avatar>
      </div>
    </div>
  </div>
</ng-container>
