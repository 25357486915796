import { format } from "date-fns";
import { Payment } from "../models/Payment";
import {saveAs} from 'file-saver';
import { TranslateService } from "@ngx-translate/core";
import { inject } from "@angular/core";

export function exportPaymentAsCsv(payments: Payment[], includeCompletedAt: boolean, translateService: TranslateService, reconcilePaymentsAgainstInvoiceEnabled: boolean = false) {
    const dateFormat = 'dd/MM/yyyy HH:mm:ss';
    const headers = translateService.instant('helpers.export_payment_csv.headers');

    if (reconcilePaymentsAgainstInvoiceEnabled) {
      headers.splice( 1, 0, 'Invoice Number');
    }

    if (includeCompletedAt) {
      headers.push(translateService.instant('helpers.export_payment_csv.completed_at'));
    }

    const csv = payments.map((payment) => {
      const item = [
        payment.id,
        reconcilePaymentsAgainstInvoiceEnabled ? (payment?.invoiceNumber ?? '-') : undefined,
        payment.client?.pmsId ?? '-',
        payment.client ? payment.client.firstName + ' ' + payment.client.lastName : '-',
        payment.owner?.fullName ?? '-',
        (payment.amount / 100).toFixed(2),
        format(payment.createdAt, dateFormat),
        payment.authOnly,
        payment.authorisedAt ? format(payment.authorisedAt, dateFormat) : '-',
        payment.paidAt ? format(payment.paidAt, dateFormat) : '-',
      ];

      if (includeCompletedAt) {
        item.push(payment.syncedToPmsAt ? format(payment.syncedToPmsAt, dateFormat) : '-');
      }

      return item.filter((value) => value !== undefined).join(',');
    });

    csv.unshift(headers.join(','));
    const csvArray = csv.join('\r\n');

    const blob = new Blob([csvArray], {type: 'text/csv' });
    saveAs(blob, `payments-export-${format(new Date(), 'yyyy-MM-dd-HH-mm-ss')}.csv`);
}
