<ng-container *ngIf="loading && !depositAlreadyExistsForClient">
  <p-progressSpinner [style]="{width: '40px', height: '40px', margin: '40px auto', display: 'block'}" styleClass="custom-spinner" strokeWidth="4"></p-progressSpinner>
</ng-container>
<ng-container *ngIf="!loading && !depositAlreadyExistsForClient">
  <ng-container *ngIf="step === 0">
    <div class="subtitle">{{'ui.new_client_deposit.search' | translate}}</div>
    <client-selector [noModal]="true" (clientSelected)="selectClient($event)"></client-selector>
  </ng-container>
  <ng-container *ngIf="step === 1">
    <ng-container *ngIf="selectedClient">
      <div class="client"><span class="client-label">{{'ui.new_client_deposit.client' | translate}}:</span> {{ selectedClient.fullName }}</div>
    </ng-container>
    <label for="confirm" class="checkbox">
      <p-checkbox [(ngModel)]="confirmed" id="confirm" [binary]="true"></p-checkbox>
      {{'ui.new_client_deposit.confirm' | translate}}
    </label>
    <ng-container *ngIf="errorKey">
      <div class="error">{{ errorKey | translate }}</div>
    </ng-container>
    <div class="btn-row">
      <p-button (click)="submit()" [label]="'ui.new_client_deposit.add_deposit' | translate" styleClass="p-button-secondary p-button-smalltext"></p-button>
    </div>
  </ng-container>
</ng-container>
<ng-container *ngIf="depositAlreadyExistsForClient">
  <div class="subtitle">{{'ui.new_client_deposit.deposit_on_file' | translate}}</div>
  <div class="btn-row">
    <p-button (click)="viewClient()" [label]="'ui.new_client_deposit.view_client' | translate" styleClass="p-button-secondary p-button-smalltext"></p-button>
  </div>
</ng-container>
