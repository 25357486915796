<ng-container *ngIf="productRequest">
  <div class="confirm-product-request-paid">
    <div class="heading">Please confirm that the requested products have been paid for.</div>
    <div class="checkbox-row">
      <p-checkbox [(ngModel)]="paidConfirmed" [binary]="true" inputId="confirm"></p-checkbox>
      <ng-container *ngIf="context === contextOptions.PAID">
        <label class="label" for="confirm">By confirming, the payment link sent for this product request will be cancelled and the request will be moved to '{{ productRequestStatus.PAID | prettifyProductRequestStatus }}'.</label>
      </ng-container>
      <ng-container *ngIf="context === contextOptions.SKIP_PAYMENT">
        <label class="label" for="confirm">By confirming, this product request will be moved to '{{ productRequestStatus.PAID | prettifyProductRequestStatus }}' and no payment link will be sent to the client.</label>
      </ng-container>
    </div>
    <ng-container *ngIf="error">
      <div class="error">You must confirm that this product request has been paid for</div>
    </ng-container>
    <div class="button-row">
      <p-button (click)="submit()" label="Submit" styleClass="p-button-primary"></p-button>
    </div>
  </div>
</ng-container>
