import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Event, NavigationEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { filter, takeWhile } from 'rxjs/operators';
import { ActionBar } from 'src/app/enums/action-bars';
import {select, Store} from '@ngrx/store';
import {getCurrentPractice} from '../../../practices/state/selectors';
import {isMessagingEnabled} from '../../../helpers/is-messaging-enabled';
import {AppState} from '../../../state/reducers';

@Component({
  selector: 'mobile-header-action-bar',
  templateUrl: './mobile-header-action-bar.component.html',
  styleUrls: ['./mobile-header-action-bar.component.scss']
})
export class MobileHeaderActionBarComponent implements OnInit, OnDestroy {
  @Input() headerElement?: HTMLDivElement;
  @Output() actionSelected = new EventEmitter();
  alive = true;
  actionBars = ActionBar;
  showActionBar: ActionBar | null = ActionBar.CREATE_CONVERSATION;
  showingCreateActions = false;

  constructor(private router: Router, private store: Store<AppState>) {
    this.subscribeToRouter();
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  subscribeToRouter(): void {
    this.setHeaderBar(this.router.url);
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.setHeaderBar(event.url);
      }
    });
  }

  setHeaderBar(url: string): void {
    this.showActionBar = null;

    if (url.includes('/conversations/')) {
      this.showActionBar = ActionBar.MANAGE_CONVERSATION;
      return;
    }
  }

  toggleCreateMenu(): void {
    this.showingCreateActions = !this.showingCreateActions;
  }

  handleActionSelected(): void {
    this.actionSelected.emit();
  }
}
