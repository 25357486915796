<ng-container *ngIf="conversation">
  <div class="conversation-preview">
    <div class="top-bar" [ngClass]="{'add-padding': viewers.length > 0 && showUsers}">
      <div class="left">
        <a pButton (click)="goToConversation()" [label]="'conversation.components.preview.open_conversation' | translate" class="p-button-first"></a>
      </div>
      <div class="right">
        <ng-container *ngIf="!!conversation.resolvedAt">
          <div class="date">
            <span class="label">{{'conversation.components.preview.resolved' | translate}}</span>
            <span>{{ conversation.resolvedAt | date:'dd/MM/yy HH:mm' }}</span>
          </div>
        </ng-container>
        <div>
          <conversation-resolver (resolveConversation)="resolveConversation($event)" [disabled]="!!conversation.resolvedAt" buttonClass="p-button-success"></conversation-resolver>
        </div>
      </div>
      <ng-container *ngIf="viewers.length > 0 && showUsers">
        <top-bar-message [viewers]="viewers" [type]="'conversation'"></top-bar-message>
      </ng-container>
    </div>
    <ng-container *ngIf="practice && (practice.userRole === superAdmin || practice.userRole === analyst)">
      <div class="tags">
        <div class="title-wrap">
          <conversation-tag-manager
          [allTags]="allTags"
          [selectedTags]="selectedTags"
          (tagsChanged)="handleTagsChanged($event)"
          ></conversation-tag-manager>
        </div>
        <ng-container *ngIf="conversation.tags.length > 0">
          <conversation-tags [tags]="conversation.tags"></conversation-tags>
        </ng-container>
      </div>
    </ng-container>
    <div class="main">
      <conversation-messages
        [conversation]="conversation"
        [conversationContact]="contact || null"
        [client]="conversation.client ?? null"
        [messages$]="messages$"
        [disabled]="chatIsDisabled"
        [awaitingResponse]="chatIsAwaitingResponse"
        [paymentsFailedToSync]="0"
        version="list"
        [showConversationActions]="false"
        [withAudioTooltip]="false"
      ></conversation-messages>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!conversation">
  <div class="no-conversation-selected">
    {{'conversation.components.preview.no_conversation_selected' | translate}}
  </div>
</ng-container>
