import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState, STATE_KEY } from 'src/app/state/reducers';
import { PracticesState } from './reducer';
import { User } from '../../models/User';

export const authUser = (state: AppState) => state.auth.user;
export const getPracticesState = createFeatureSelector<PracticesState>(STATE_KEY.PRACTICES);

export const getCurrentPractice = createSelector(
    getPracticesState,
    (state: PracticesState) => state.currentPractice,
);

export const getCurrentPracticeConfig = createSelector(
  getPracticesState,
  (state: PracticesState) => state.config,
);

export const getCurrentPracticeSites = createSelector(
  getPracticesState,
  (state: PracticesState) => state.currentPractice ? state.currentPractice.sites : [],
);

export const getPracticeTemplates = createSelector(
  getPracticesState,
  (state: PracticesState) => state.templates,
);

export const getAvailablePractices = createSelector(
    getPracticesState,
    (state: PracticesState) => state.availablePractices,
);

export const getPracticeStaff = createSelector(
    getPracticesState,
    (state: PracticesState) => state.staff,
);

export const getPracticeStaffWithoutUnassigned = createSelector(
  authUser,
  getPracticesState,
  (aUser: User|null, state: PracticesState) => {
    let staff: User[] = [];
    if (aUser) {
      const me = state.staff.find(u => u.id === aUser.id);
      if (me) {
        staff.push(me);
      }

      staff = [
        ...staff,
        ...state.staff.filter(u => u.id !== aUser.id).sort((a, b) => a.firstName.localeCompare(b.firstName)),
      ];
    } else {
      staff = [];
    }

    return staff;
  },
);

export const getPracticeStaffWithUnassigned = createSelector(
  authUser,
  getPracticesState,
  (aUser: User|null, state: PracticesState) => {
    let staff: User[] = [];
    if (aUser) {
      const me = state.staff.find(u => u.id === aUser.id);
      if (me) {
        staff.push(me);
      }

      staff = [
        ...staff,
        ...state.staff.filter(u => u.id !== aUser.id).sort((a, b) => a.firstName.localeCompare(b.firstName)),
      ];
    } else {
      staff = [];
    }

    return [
      {
        id: '0',
        firstName: 'Unassigned',
        lastName: '',
        fullName: 'Unassigned',
        email: '',
        phone: '',
      },
      ...staff
    ];
  },
);

export const getPracticeGroups = createSelector(
  getPracticesState,
  (state: PracticesState) => state.groups
);
