import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Patient } from 'src/app/models/Patient';
import { select, Store } from '@ngrx/store';
import { getCurrentPractice } from '../../../practices/state/selectors';
import { takeWhile } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { Practice } from '../../../models/Practice';
import { AppState } from '../../../state/reducers';
import {HistoryItem} from '../../../models/HistoryItem';
import { practiceHasFeature } from 'src/app/helpers/practice-has-feature';
import { PracticeFeature } from 'src/app/enums/practice-feature';

@Component({
  selector: 'patient-actions',
  templateUrl: './patient-actions.component.html',
  styleUrls: ['./patient-actions.component.scss']
})
export class PatientActionsComponent implements OnInit, OnDestroy {
  @Input() patient?: Patient;
  @Input() patientHistoryLoading = false;
  @Input() patientHistory: HistoryItem[] = [];
  @Output() patientHistoryOpened = new EventEmitter<string>();
  alive = true;
  notesEnabled = false;
  remindersEnabled = false;
  historyEnabled = false;
  practice$?: Observable<Practice | null>;
  practiceSub$?: Subscription;
  practice?: Practice | null;

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
    this.subscribeToCurrentPractice();
  }

  ngOnDestroy(): void {
    this.alive = false;
    this.practiceSub$?.unsubscribe();
  }

  handlePatientHistoryOpened(patientId: string): void {
    this.patientHistoryOpened.emit(patientId);
  }

  subscribeToCurrentPractice(): void {
    this.practice$ = this.store.pipe(select(getCurrentPractice)).pipe(
      takeWhile(() => this.alive)
    );

    this.practiceSub$ = this.practice$.subscribe((practice) => {
      this.practice = practice;
      this.updateFeatures();
    });
  }

  updateFeatures(): void {
    this.remindersEnabled = practiceHasFeature(this.practice, PracticeFeature.PATIENT_REMINDERS);
    this.notesEnabled = practiceHasFeature(this.practice, PracticeFeature.PATIENT_NOTES);
    this.historyEnabled = practiceHasFeature(this.practice, PracticeFeature.PATIENT_HISTORY);
  }
}
