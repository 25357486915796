import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotificationAdapter } from '../adapters/notification.adapter';
import { Notification } from '../models/Notification';
import { EnvironmentService } from '../services/environment.service';
import { NotificationDto } from './dto/notification.dto';
import { AddNotification, ShowNotification } from './state/actions';
import { NotificationsState } from './state/reducer';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(
    private store: Store<NotificationsState>,
    private http: HttpClient,
    private notificationAdapter: NotificationAdapter,
    private environmentService: EnvironmentService
  ) {}

  handleNewNotification(notificationDto: NotificationDto, showToast: boolean): void {
    const notification: Notification = this.notificationAdapter.run(notificationDto);
    this.store.dispatch(AddNotification({notification}));
    if (showToast) {
      this.store.dispatch(ShowNotification({notification}));
    }
  }

  getNotifications(userId: number, page: number, perPage: number, unreadOnly: boolean): Observable<{notifications: Notification[], count: number}> {
    const url = this.environmentService.get('backendUrl') + `/users/${userId}/notifications?page=${page}&perPage=${perPage}&unreadOnly=${unreadOnly}`;
    return this.http.get<{notifications: NotificationDto[], count: number}>(url, {withCredentials: true}).pipe(
      map((response: {notifications: NotificationDto[], count: number}) => {
        return {
          notifications: response.notifications.map((notification: NotificationDto) => this.notificationAdapter.run(notification)),
          count: response.count
        };
      })
    );
  }

  markAsRead(notificationId: number): Observable<boolean>  {
    const url = this.environmentService.get('backendUrl') + `/notifications/${notificationId}/mark-as-read`;
    return this.http.patch<{success: boolean}>(url, {withCredentials: true}).pipe(
      map((response: {success: boolean}) => {
        return response.success;
      })
    );
  }
  markAsUnread(notificationId: number): Observable<boolean> {
    const url = this.environmentService.get('backendUrl') + `/notifications/${notificationId}/mark-as-unread`;
    return this.http.patch<{success: boolean}>(url, {withCredentials: true}).pipe(
      map((response: {success: boolean}) => {
        return response.success;
      })
    );
  }

  markAllAsRead(userId: number): Observable<boolean> {
    const url = this.environmentService.get('backendUrl') + `/users/${userId}/notifications/mark-as-read`;
    return this.http.patch<{success: boolean}>(url, {withCredentials: true}).pipe(
      map((response: {success: boolean}) => {
        return response.success;
      })
    );
  }
}
