<ng-container *ngIf="productRequest">
  <div class="confirm-product-request-paid">
    <div class="heading">{{'product_requests.confirm_paid.please_confirm' | translate}}</div>
    <div class="checkbox-row">
      <p-checkbox [(ngModel)]="paidConfirmed" [binary]="true" inputId="confirm"></p-checkbox>
      <ng-container *ngIf="context === contextOptions.PAID">
        <label class="label" for="confirm">{{'product_requests.confirm_paid.cancel_payment' | translate: {status: (productRequestStatus.PAID | prettifyProductRequestStatus | translate)} }}</label>
      </ng-container>
      <ng-container *ngIf="context === contextOptions.SKIP_PAYMENT">
        <label class="label" for="confirm">{{'product_requests.confirm_paid.confirm_payment' | translate: {status: (productRequestStatus.PAID | prettifyProductRequestStatus | translate)} }}</label>
      </ng-container>
    </div>
    <ng-container *ngIf="error">
      <div class="error">{{'product_requests.confirm_paid.must_confirm' | translate}}</div>
    </ng-container>
    <div class="button-row">
      <p-button (click)="submit()" [label]="'product_requests.confirm_paid.submit' | translate" styleClass="p-button-primary"></p-button>
    </div>
  </div>
</ng-container>
