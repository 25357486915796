import { createAction, props } from '@ngrx/store';
import { Client } from 'src/app/models/Client';
import { Practice } from 'src/app/models/Practice';
import {ProductRequest} from '../../models/ProductRequest';
import {ClientInsights} from "../../models/ClientInsights";
import { FilterSelection } from '../../interfaces/filter-selection.interface';
import { SortByOption } from '../../interfaces/sort-by-option.interface';

export const GetClientSearchResults = createAction(
    '[Client] Get Client Search Results',
    props<{searchString: string}>()
);

export const GetClientSearchResultsSuccess = createAction(
    '[Client] Get Client Search Results - Success',
    props<{clients: Client[]}>()
);

export const GetClientSearchResultsFail = createAction(
    '[Client] Get Client Search Results - Fail'
);

export const StartClientSearchLoading = createAction(
    '[Client] Start Client Search Loading'
);

export const StopClientSearchLoading = createAction(
    '[Client] Stop Client Search Loading'
);

export const GetFilterClients = createAction(
    '[Client] Get Filter Clients',
    props<{practice: Practice, search?: string, limit?: number}>()
);

export const GetFilterClientsSuccess = createAction(
    '[Client] Get Filter Clients - Success',
    props<{clients: Client[]}>()
);

export const GetFilterClientsFail = createAction(
    '[Client] Get Filter Clients - Fail'
);

export const GetClientProductRequests = createAction(
  '[Client] Get Client Product Request History',
  props<{client: Client}>()
);

export const GetClientProductRequestsSuccess = createAction(
  '[Client] Get Client Product Request History Success',
  props<{productRequests: ProductRequest[]}>()
);

export const ClearClientProductRequests = createAction(
  '[Client] Clear Client Product Request History'
);

export const GetClientInsights = createAction(
  '[Client] Get Client Insights',
  props<{clientId: string}>()
);

export const GetClientInsightsSuccess = createAction(
  '[Client] Get Client Insights Success',
  props<{client: ClientInsights}>()
);

export const GetClientInsightsFailure = createAction(
  '[Client] Get Client Insights Failure',
  props<{clientId: string}>()
);

export const ClearClientInsights = createAction(
  '[Client] Clear Client Insights'
);

export const GetClientPMSInsight = createAction(
  '[Client} Get Client PMS Insight',
  props<{page: number, clientPmsId: string, practiceId: string}>()
);

export const GetClientPMSInsightSuccess = createAction(
  '[Client} Get Client PMS Insight Success',
  props<{client: Client | null}>()
);

export const GetClientPMSInsightFailure = createAction(
  '[Client} Get Client PMS Insight Error',
  props<{client: Client | null}>()
);

export const ClearClientPMSInsight = createAction(
  '[Client] Clear Client PMS Insight'
);

export const AddManualDeposit = createAction(
  '[Client] Add Client Deposit',
  props<{client: ClientInsights}>()
);

export const AddManualDepositSuccess = createAction(
  '[Client] Add Client Deposit Success',
  props<{client: ClientInsights}>()
);

export const AddManualDepositFailure = createAction(
  '[Client] Add Client Deposit Failure',
  props<{client: ClientInsights}>()
);

export const RefundDeposit = createAction(
  '[Client] Refund Client Deposit',
  props<{client: ClientInsights}>()
);

export const RefundDepositSuccess = createAction(
  '[Client] Refund Client Deposit Success',
  props<{client: ClientInsights}>()
);

export const RefundDepositFailure = createAction(
  '[Client] Refund Client Deposit Failure',
  props<{client: ClientInsights}>()
);

export const TakeDeposit = createAction(
  '[Client] Take Client Deposit',
  props<{client: ClientInsights}>()
);

export const TakeDepositSuccess = createAction(
  '[Client] Take Client Deposit Success',
  props<{client: ClientInsights}>()
);

export const TakeDepositFailure = createAction(
  '[Client] Take Client Deposit Failure',
  props<{client: ClientInsights}>()
);

export const GetClientListClients = createAction(
  '[Client] Get Client List Clients',
  props<{practiceId: string, searchString: string | null, filters: FilterSelection, sortBy: SortByOption, page: number}>()
);

export const GetClientListClientsSuccess = createAction(
  '[Client] Get Client List Clients - Success',
  props<{clients: Client[], total: number}>()
);

export const OpenNewClientDeposit = createAction(
  '[Client] Open New Client Deposit'
);

export const CloseNewClientDeposit = createAction(
  '[Client] Close New Client Deposit'
);

export const CreateNewClientDeposit = createAction(
  '[Client] Create New Client Deposit',
  props<{client: Client}>()
);

export const CreateNewClientDepositSuccess = createAction(
  '[Client] Create New Client Deposit Success',
  props<{client: Client}>()
);

export const CreateNewClientDepositFailure = createAction(
  '[Client] Create New Client Deposit Failure',
  props<{client: Client}>()
);

export const CreateNewClientDepositAlreadyExists = createAction(
  '[Client] Create New Client Deposit Already Exists',
  props<{client: Client}>()
);
