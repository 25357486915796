import { Practice } from "../models/Practice";
import { User } from "../models/User";

export function addSignature(message: string, user?: User | null, practice?: Practice | null): string {
    if (!practice || !user || !practice.signature || practice.signature === '') {
        return message;
    }

    let userInitials = user.firstName ? user.firstName[0] : '';
    userInitials += user.lastName ? user.lastName[0] : '';

    const signature = practice.signature
    .replace('{{ userName }}', user.fullName)
    .replace('{{ userInitials }}', userInitials)
    .replace('{{ practiceName }}', practice.name);

    return `${message}\n\n${signature}`;
}
