import { Pipe, PipeTransform } from '@angular/core';
import { formatDistanceToNow } from 'date-fns';
import { MessageType } from '../enums/message-type';
import { Message } from '../models/Message';
import { TranslateService } from '@ngx-translate/core';


@Pipe({name: 'getLastConsentDatetime'})
export class GetLastConsentDateTimePipe implements PipeTransform {
    constructor(private translateService: TranslateService) {}

    transform(messages: Message[] | null): string {
        if (!messages || messages.length === 0) {
            return this.translateService.instant('pipes.get_last_consent_date_time.never');
        }

        let consentMessages = messages.filter(message => {
            return message.type === MessageType.INITIAL_CONSENT_REQUEST || message.type === MessageType.REOPEN_CONSENT_REQUEST;
        });

        if (consentMessages.length === 0) {
            return this.translateService.instant('pipes.get_last_consent_date_time.never');
        }

        consentMessages = consentMessages.sort((a, b) => {
            return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        });
        return this.translateService.instant('pipes.get_last_consent_date_time.time_ago', {time: formatDistanceToNow(new Date(consentMessages[0].createdAt))});
    }
}
