import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterOption } from '../../../interfaces/filter-option.interface';
import { dateFilterOptions } from '../../../constants/date-filter-options.constants';
import { DateFilterShorthand } from '../../../enums/date-filter-shorthand';
import { FilterOptionType } from '../../../enums/filter-option-type';

@Component({
  selector: 'filter-created-at',
  templateUrl: './filter-created-at.component.html',
  styleUrls: ['./filter-created-at.component.scss']
})
export class FilterCreatedAtComponent {
  open = false;
  @Input() selected: FilterOption | null = null;
  @Input() options: FilterOption[] = dateFilterOptions;
  @Output() valueSelected = new EventEmitter<FilterOption[]>();

  handleSelected(option: FilterOption): void {
    if (option.type === FilterOptionType.STANDARD) {
      this.selected = option;
      this.valueSelected.emit([this.selected]);
    }
  }

  handleDateRangeSelected(range: {start: Date, end: Date}): void {
    this.selected = {
      id: DateFilterShorthand.CUSTOM,
      label: 'Within range',
      type: FilterOptionType.DATE_RANGE,
      range: range
    };
    this.valueSelected.emit([this.selected])
  }
}
