import { Injectable } from '@angular/core';
import { CommentDto } from '../interfaces/dto/comment.dto';
import { Comment } from '../models/Comment';
import { UserAdapter } from './user.adapter';

@Injectable({
  providedIn: 'root'
})
export class CommentAdapter {
    constructor(private userAdapter: UserAdapter) {}

    run(dto: CommentDto): Comment {
        return {
            ...dto,
            author: this.userAdapter.run(dto.author),
            content: dto.content,
            createdAt: new Date(dto.createdAt),
            updatedAt: new Date(dto.updatedAt),
        };
    }
}
