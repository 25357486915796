import {ProductRequestItem} from '../models/ProductRequestItem';
import {ProductRequestStatus} from '../enums/product-request-status';
import {ProductRequestApprovalStatus} from '../enums/product-request-approval-status';
import {ProductRequest} from '../models/ProductRequest';

export function itemCanBeDispensed(productRequest: ProductRequest, item: ProductRequestItem): boolean {
  const allowedStatuses = [
    ProductRequestStatus.PAID,
  ];

  if (
    allowedStatuses.includes(productRequest.status) &&
    (
      item.approvalStatus === ProductRequestApprovalStatus.APPROVED ||
      item.approvalStatus === ProductRequestApprovalStatus.NOT_REQUIRED
    )
  ) {
    return true;
  }

  return false;
}
