import {MessageType} from '../enums/message-type';

export function prettifyMessageType(type: MessageType): string {
  switch (type) {
    case MessageType.STANDARD:
      return 'Standard';
    case MessageType.AUTORESPONSE:
      return 'Automatic reply';
    case MessageType.PAYMENT_LINK:
      return 'Payment Link';
    case MessageType.PAYMENT_COMPLETE:
      return 'Payment Complete';
    case MessageType.INITIAL_CONSENT_REQUEST:
      return 'Initial Consent Request';
    case MessageType.REOPEN_CONSENT_REQUEST:
      return 'Reinitialise Conversation';
    case MessageType.VERIFICATION_REQUEST:
      return 'Verification Request';
    case MessageType.VERIFICATION_COMPLETE:
      return 'Verification Complete';
    case MessageType.ATTACHMENT_IMAGE:
      return 'Attachment (Image)';
    case MessageType.ATTACHMENT_AUDIO:
      return 'Attachment (Audio)';
    case MessageType.ATTACHMENT_FILE:
      return 'Attachment (File)';
    case MessageType.ATTACHMENT_VIDEO:
      return 'Attachment (Video)';
    case MessageType.WELCOME:
      return 'Welcome';
    case MessageType.PAYMENT_REFUNDED:
      return 'Refund Notification';
    case MessageType.PAYMENT_AUTHORISED:
      return 'Payment Authorised';
    case MessageType.PRODUCT_REQUEST_REJECTED:
      return 'Product Request Rejected';
    case MessageType.PRODUCT_REQUEST_APPROVED:
      return 'Product Request Approved';
    case MessageType.PRODUCT_REQUEST_PARTIALLY_APPROVED:
      return 'Product Request Approved';
    case MessageType.PRODUCT_REQUEST_PAID:
      return 'Product Request Paid';
    case MessageType.PRODUCT_REQUEST_DISPENSED:
      return 'Product Request Dispensed';
    default:
      return 'N/A';
  }
}
