<p-toast key="save-conversation-pms-error" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="container">
            <i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
            <div>
                <h1>{{message.summary}}.</h1>
                <p>{{message.detail}}.</p>
                <span class="download-link" (click)="downloadPdf(message.data.conversationId)">Click here to download Conversation #{{message.data.conversationId}} as PDF.</span>
            </div>
        </div>
    </ng-template>
</p-toast>

