<div class="link" (click)="showModal()">Multiple plans</div>
<ng-container *ngIf="careplans">
  <p-dialog
    header="Care Plan Details"
    [(visible)]="modalActive"
    [modal]="true"
    [style]="{width: '400px'}"
    [baseZIndex]="10000"
    [draggable]="false"
    [resizable]="false"
  >
    <div class="dialog-content">
      <ng-container *ngFor="let item of careplans">
        <p class="careplan-item">{{ item }}</p>
      </ng-container>
    </div>
  </p-dialog>
</ng-container>
