import {Injectable} from '@angular/core';
import {PaymentDto} from '../interfaces/dto/payment.dto';
import {Payment} from '../models/Payment';
import {Client} from '../models/Client';
import {UserAdapter} from './user.adapter';
import {SiteAdapter} from './site.adapter';
import {addDays, parseISO} from 'date-fns';
import {PaymentType} from '../enums/payment-type';

@Injectable({
  providedIn: 'root'
})
export class PaymentAdapter {
  constructor(private userAdapter: UserAdapter, private siteAdapter: SiteAdapter) {}

  run(dto: PaymentDto, client?: Client): Payment {
    let type = PaymentType.STANDARD;

    if (dto.type === 'AUTOMATIC_APPOINTMENT' || dto.type === 'AUTOMATIC_ADHOC') {
      type = PaymentType.AUTOMATIC;
    }

    if (dto.type === 'ONLINE') {
      type = PaymentType.ONLINE;
    }

    if (dto.type === 'FORM') {
      type = PaymentType.FORM;
    }

    if (dto.type === 'BROADCAST') {
      type = PaymentType.BROADCAST;
    }

    return {
      ...dto,
      client: client ? client : null,
      owner: dto.owner ? this.userAdapter.run(dto.owner) : null,
      site: dto.site ? this.siteAdapter.run(dto.site) : null,
      createdAt: new Date(dto.createdAt),
      updatedAt: new Date(dto.updatedAt),
      expiresAt: new Date(dto.expiresAt),
      cancelledAt: dto.cancelledAt ? new Date(dto.cancelledAt) : null,
      refundedAt: dto.refundedAt ? new Date(dto.refundedAt) : null,
      refundOfId: dto.refundOfId ? dto.refundOfId : null,
      paidAt: dto.paidAt ? new Date(dto.paidAt) : null,
      syncedToPmsAt: dto.syncedToPmsAt ? new Date(dto.syncedToPmsAt) : null,
      resentAt: dto.resentAt ? new Date(dto.resentAt) : null,
      authOnly: dto.authOnly,
      authorisedAt: dto.authorisedAt ? parseISO(dto.authorisedAt) : null,
      authorisationExpiresAt: dto.authorisedAt ? addDays(parseISO(dto.authorisedAt), 7) : null,
      type,
      cancelAuthAt: dto.cancelAuthAt ? new Date(dto.cancelAuthAt) : null,
      existingConversationId: dto.existingConversationId || null,
      lastSyncAttempt: dto.lastSyncAttempt || null,
      version: dto.version || null,
      invoiceId: dto?.invoiceId || null,
      invoiceNumber: dto?.invoiceNumber || null
    };
  }
}
