<form class="add-media" [formGroup]="form">
  <div class="input-row">
    <div class="label">File:</div>
    <p-fileUpload
      #fileUpload
      chooseIcon="pi-file"
      chooseLabel="Upload a PDF, Image or Video (max 60 secs)"
      class="input-upload"
      mode="basic"
      name="image"
      accept="image/jpeg,image/jpg,image/png,video/*,application/pdf"
      invalidFileSizeMessageSummary="Invalid file size,"
      invalidFileTypeMessageSummary="Invalid file type,"
      invalidFileTypeMessageDetail="allowed file types: jpeg, jpg, png, pdf, mp4, mov, wmv, 3gp, flv."
      [auto]="true"
      [customUpload]="true"
      (uploadHandler)="uploadFile($event)"
    ></p-fileUpload>
    <ng-container *ngIf="form.controls.asset.invalid && (form.controls.asset.dirty || form.controls.asset.touched) && form.controls.asset.errors?.['required']">
      <div class="error">You must upload a file.</div>
    </ng-container>
    <ng-container *ngIf="previewSrc">
      <div class="image-preview">
        <img [src]="previewSrc" />
      </div>
    </ng-container>
    <ng-container *ngIf="!previewSrc && form.controls.asset && form.controls.asset.value && form.controls.asset.value.name">
      <div class="file-name">{{ form.controls.asset.value.name }}</div>
    </ng-container>
  </div>
  <div class="input-row">
    <div class="label">Name:</div>
    <input pInputText class="p-inputtext" type="text" formControlName="name" maxlength="50">
    <ng-container *ngIf="form.controls.name.invalid && (form.controls.name.dirty || form.controls.name.touched) && form.controls.name.errors?.['required']">
      <div class="error">You must enter a name for this file.</div>
    </ng-container>
  </div>
  <ng-container *ngIf="showVideoLengthWarning">
    <div class="warning">Videos longer than 60 seconds will be cut down to 60 seconds long.</div>
  </ng-container>
  <div class="btn-row">
    <p-button (click)="submit()" label="Add" styleClass="p-button-primary"></p-button>
  </div>
</form>
