import { Component, Input } from '@angular/core';
import { Practice } from '../../../models/Practice';
import { Currency } from '../../../models/Currency';

@Component({
  selector: 'payment-screen-preview',
  templateUrl: './payment-screen-preview.component.html',
  styleUrl: './payment-screen-preview.component.scss'
})
export class PaymentScreenPreviewComponent {
  @Input() practice?: Practice;
  @Input() description?: string;
  @Input() practiceCurrency?: Currency;
  @Input() amount?: string;
  @Input() authOnly: boolean = false;
  @Input() paymentMessage?: string;
}
