import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as SearchActions from './actions';
import {map, mergeMap, tap, withLatestFrom} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {AppState} from '../../state/reducers';
import {SearchService} from '../search.service';
import {Router} from '@angular/router';
import {Payment} from '../../models/Payment';
import {Conversation} from '../../models/Conversation';
import {getCurrentPractice} from '../../practices/state/selectors';
import {Noop} from '../../state/actions';
import {SearchType} from '../../enums/search-type';
import {buildUrlParamsFromConversationFilters} from "../../helpers/build-url-params-from-conversation-filters";
import {buildUrlParamsFromPaymentFilters} from "../../helpers/build-url-params-from-payment-filters";

@Injectable()
export class SearchEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private searchService: SearchService,
    private router: Router,
  ) {
  }

  updateConversationSearchFilters$ = createEffect(() => this.actions$.pipe(
    ofType(SearchActions.UpdateConversationSearchFilters),
    tap((action) => {
      if (action.searchString !== '') {
        let url = `search?s=${encodeURIComponent(action.searchString)}&searchType=${SearchType.CONVERSATIONS}`;
        const params = buildUrlParamsFromConversationFilters(action.filters);
        url += params;
        this.router.navigateByUrl(url);
      }
    }),
    map((action) => {
      return SearchActions.DoConversationSearch({searchString: action.searchString, filters: action.filters});
    })
  ));

  updatePaymentSearchFilters$ = createEffect(() => this.actions$.pipe(
    ofType(SearchActions.UpdatePaymentSearchFilters),
    tap((action) => {
      if (action.searchString !== '') {
        let searchString = action.filters.searchTerm ? action.filters.searchTerm : action.searchString;
        let url = `search?s=${encodeURIComponent(searchString)}&searchType=${SearchType.PAYMENTS}`;
        const params = buildUrlParamsFromPaymentFilters(action.filters);
        url += params;
        this.router.navigateByUrl(url);
      }
    }),
    map((action) => {
      return SearchActions.DoPaymentsSearch({searchString: action.searchString, filters: action.filters});
    })
  ));

  doConversationSearch$ = createEffect(() => this.actions$.pipe(
    ofType(SearchActions.DoConversationSearch),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => this.searchService.doConversationSearch(action.searchString, action.filters, practice?.id)
      .pipe(
        map((result: { conversations: Conversation[], total: number }) => {
          return SearchActions.DoConversationsSearchSuccess({result});
        })
      )
    )
  ));

  doPaymentSearch$ = createEffect(() => this.actions$.pipe(
    ofType(SearchActions.DoPaymentsSearch),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => this.searchService.doPaymentSearch(action.searchString, action.filters, practice?.id)
      .pipe(
        map((result: { payments: Payment[], total: number }) => {
          return SearchActions.DoPaymentSearchSuccess({result});
        })
      )
    )
  ));
}
