<div class="comments-panel">
    <div class="section-title" [class.show-shim]="showTopShim">
        <ng-container *ngIf="title">
            <h1>{{ title }}</h1>
        </ng-container>
        <ng-container *ngIf="!(fullScreen$ | ngrxPush)">
            <p (click)="enterFullScreen()" class="fullscreen-action">{{'comments.panel.view_fullscreen' | translate}}</p>
        </ng-container>
        <ng-container *ngIf="(fullScreen$ | ngrxPush)">
            <p (click)="exitFullScreen()"  class="fullscreen-action">{{'comments.panel.exit_fullscreen' | translate}}</p>
        </ng-container>
    </div>
    <div class="comments" #commentContainer (scroll)="handleScroll()">
        <ng-container *ngIf="comments && comments.length > 0">
            <ng-container *ngFor="let comment of comments">
                <comment #commentsList [users]="(users$ | async) || []" [groups]="(groups$ | async) || []" [comment]="comment"></comment>
            </ng-container>
        </ng-container>
    </div>
    <div class="send-comment" [class.show-shim]="showBottomShim">
        <add-comment-form [disabled]="disabled" [conversation]="conversation" (commentSent)="handleCommentSent($event)"></add-comment-form>
    </div>
</div>
