<div class="filter-item" [class.active]="open" [class.highlight]="selectedValue" (clickOutside)="close()">
  <div class="toggle" (click)="toggle()">
    <ng-container *ngIf="!selectedValue">
      <i [class]="icon"></i>
    </ng-container>
    <ng-container *ngIf="selectedValue">
      <i class="pi pi-check"></i>
    </ng-container>
    {{ selectedValue ? selectedValue.label : (label ?? ('ui.filter_item.label' | translate) )}} <dropdown-icon></dropdown-icon>
  </div>
  <div class="dropdown mobile-modal no-checkbox">
    <ng-container *ngFor="let option of options">
      <div class="dropdown-item" (click)="update(option)">
        <div class="label" [class.active]="selectedValue && selectedValue.id === option.id">{{ option.label }}</div>
      </div>
    </ng-container>
    <ng-container *ngIf="dateRangeOpen">
      <div class="dropdown-calendar">
        <p-calendar #rangeCalendar (onSelect)="rangeSelected()" [(ngModel)]="rangeDates" [inline]="true" [firstDayOfWeek]="1" selectionMode="range" inputId="range"></p-calendar>
      </div>
    </ng-container>
  </div>
</div>
