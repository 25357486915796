import { Pipe, PipeTransform } from '@angular/core';
import { format, formatDistanceToNow, isBefore, isToday } from 'date-fns';


@Pipe({name: 'dateInPast'})
export class DateInPastPipe implements PipeTransform {
  transform(value: Date | string): boolean {
    if (typeof value === 'string') {
      value = new Date(value);
    }

    if (isBefore(value, new Date())) {
      return true;
    }

    return false;
  }
}
