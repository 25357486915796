import { Payment } from '../models/Payment';
import {PaymentType} from '../enums/payment-type';
import { Practice } from '../models/Practice';
import { PracticeFeature } from '../enums/practice-feature';
import { practiceHasFeature } from './practice-has-feature';

export function canPaymentBeRefunded(payment: Payment, practice: Practice): boolean {
    if (payment.type === PaymentType.ONLINE) {
      return false;
    }
    
    if (payment.invoiceId && !practiceHasFeature(practice, PracticeFeature.ALLOW_REFUNDS_FOR_INVOICE_PAYMENTS)) {
        return false;
    }

    if (payment.refundedAt) {
        return false;
    }

    if (payment.refundOfId) {
        return false;
    }

    if (payment.paidAt) {
        return true;
    }

    return false;

}
