<ng-container *ngIf="practices.length > 1">
    <div class="search">
        <img [src]="'searchIcon' | themeIconUrl: theme" class="search-icon">
        <input pInputText type="text" [placeholder]="'ui.practice_selector.search_practice' | translate" class="input-search"
            [style.width]="inputWidth" (keyup)="setFilteredPractices()" type="text" [(ngModel)]="practiceFilterValue">
        <ng-container *ngIf="practiceFilterValue.length > 0">
            <img src="assets/images/icons/cross.svg" class="cross-icon" (click)="clear()">
        </ng-container>
    </div>
    <div class="heading">
      {{'ui.practice_selector.change_practice' | translate}} <span>
           {{filteredPracticesTextKey | translate: filteredPracticesTextParams}}
        </span>
    </div>
    <div class="wrap">
        <ng-container *ngFor="let practice of filteredPractices">
            <ng-container *ngIf="practice.id !== currentPractice?.id">
                <ng-container *ngIf="practice">
                    <div class="practice" (click)="handlePracticeChange(practice)">{{ practice.name }}</div>
                </ng-container>
                <ng-container *ngIf="!practice">
                    {{'ui.practice_selector.no_practices_try_again' | translate}}
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
</ng-container>
