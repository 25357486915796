import * as actions from './actions';
import { Action, createReducer, on } from '@ngrx/store';
import { Patient } from 'src/app/models/Patient';
import {SearchFormsSuccess} from "../../forms/state/actions";

export interface PatientState {
  patients: Patient[];
  page: number;
}

export const initialState: PatientState = {
    patients: [],
    page: 0
};

const patientReducer = createReducer(
  initialState,
  on(actions.GetPatientsSuccess, (state, payload) => {
    if (payload.replace) {
      return {
        ...state,
        patients: [
          ...payload.patients
        ]
      };
    }

    return {
      ...state,
      patients: [
        ...state.patients,
        ...payload.patients
      ]
    };
  }),
  on(actions.ClearPatients, (state, payload) => ({
    ...state,
    patients: []
  })),
  on(actions.IncrementPatientPage, (state, payload) => ({
    ...state,
    page: state.page + 1
  })),
  on(actions.ResetPatientPage, (state, payload) => ({
    ...state,
    page: 0
  })),
);

export function reducer(state: PatientState | undefined, action: Action): PatientState {
  return patientReducer(state, action);
}
