import { Component } from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Message} from '../../../models/Message';
import {CampaignMessage} from '../../../models/CampaignMessage';
import {isCampaignMessage} from '../../../helpers/is-campaign-message';

@Component({
  selector: 'app-dialog-message-preview',
  templateUrl: './dialog-message-preview.component.html',
  styleUrls: ['./dialog-message-preview.component.scss']
})
export class DialogMessagePreviewComponent {
  message?: Message | CampaignMessage;
  isCampaignMessage = false;
  mediaType: string | null = null;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
    this.message = this.config.data.message;

    if (this.message) {
      this.isCampaignMessage = !!isCampaignMessage(this.message);

      if (this.message.attachments && this.message.attachments.length > 0) {
        if (this.message.attachments[0].mime.includes('image')) {
          this.mediaType = 'image';
        }

        if (this.message.attachments[0].mime.includes('video')) {
          this.mediaType = 'video';
        }

        if (this.message.attachments[0].mime.includes('pdf')) {
          this.mediaType = 'pdf';
        }
      }
    }
  }
}
