import { Pipe, PipeTransform } from "@angular/core";

export enum PaymentStatus {
  EXPIRED = 'Expired',
  PENDING = 'Pending',
  QUEUED = 'Queued',
  PAID = 'Paid',
  COMPLETE = 'Complete',
  CANCELLED = 'Cancelled',
  REFUNDED = 'Refunded',
  REFUND = 'Refund',
  FUNDS_HELD = 'Funds Held',
  HOLD_EXPIRED = 'Hold Expired',
  NA = 'N/A'
}

const enumToTranslation: Record<PaymentStatus, string> = {
  [PaymentStatus.CANCELLED]: 'enums.payment_status.cancelled',
  [PaymentStatus.COMPLETE]: 'enums.payment_status.complete',
  [PaymentStatus.EXPIRED]: 'enums.payment_status.expired',
  [PaymentStatus.FUNDS_HELD]: 'enums.payment_status.funds_held',
  [PaymentStatus.HOLD_EXPIRED]: 'enums.payment_status.hold_expired',
  [PaymentStatus.NA]: 'enums.payment_status.n_a',
  [PaymentStatus.PAID]: 'enums.payment_status.paid',
  [PaymentStatus.PENDING]: 'enums.payment_status.pending',
  [PaymentStatus.QUEUED]: 'enums.payment_status.queued',
  [PaymentStatus.REFUND]: 'enums.payment_status.refund',
  [PaymentStatus.REFUNDED]: 'enums.payment_status.refunded',
}

@Pipe({name: 'paymentStatusTranslationKey'})
export class PaymentStatusTranslationKeyPipe implements PipeTransform {
  transform(value: PaymentStatus): string {
    return enumToTranslation[value];
  }
}
