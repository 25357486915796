import { Pipe, PipeTransform } from "@angular/core";

export enum CustomMergeFieldType {
  Text = 'Text',
  Currency = 'Currency',
}

const enumToTranslation: Record<CustomMergeFieldType, string> = {
  [CustomMergeFieldType.Text]: 'enums.custom_merge_field_type.text',
  [CustomMergeFieldType.Currency]: 'enums.custom_merge_field_type.currency',
}

@Pipe({name: 'customMergeFieldTypeTranslationKey'})
export class CustomMergeFieldTypeTranslationKeyPipe implements PipeTransform {
  transform(value: CustomMergeFieldType): string {
    return enumToTranslation[value];
  }
}
