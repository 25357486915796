import { Component } from '@angular/core';
import {select, Store} from "@ngrx/store";
import {Router} from "@angular/router";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-form-submissions',
  templateUrl: './form-submissions.component.html',
  styleUrls: ['./form-submissions.component.scss']
})
export class FormSubmissionsPage {

  constructor(
    private store: Store,
    private router: Router,
    private titleService: Title,
  ) {
    const title = 'Digital Practice | Form Submissions';
    this.titleService.setTitle(title);
  }
}
