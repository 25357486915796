import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../../models/User';

@Component({
  selector: 'top-bar-message',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit, OnChanges {
  @Input() viewers?: User[];
  @Input() type?: string;
  message: string = '';
  userMessage: string = '';
  href: string = '';
  isProductRequest: boolean = false;
  showToolTip: boolean = false;

  constructor(private router: Router) {}

  ngOnInit(): void {
      this.buildMessage();
      this.subscribeToUrl();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.viewers) {
      this.buildMessage();
    }
  }

  subscribeToUrl(): void {
    this.href = this.router.url;
    if (this.href.includes('product-requests')) {
      this.isProductRequest = true;
    } else {
      this.isProductRequest = false;
    }
  }

  private buildMessage(): void {
    if (this.viewers) {
      if (this.viewers.length > 0 && this.viewers.length === 1) {
        this.userMessage = `${this.viewers[0]?.firstName} ${this.viewers[0]?.lastName}`;
        this.message = `is currently in this ${this.type}`;
      } else if (this.viewers.length === 2) {
        this.userMessage = `${this.viewers[0]?.firstName} ${this.viewers[0]?.lastName} and ${this.viewers[1]?.firstName} ${this.viewers[1]?.lastName}`;
        this.message = `are currently in this ${this.type}`;
      } else {
        const remainingUsers = this.viewers.length - 2;
        this.userMessage = `${this.viewers[0]?.firstName} ${this.viewers[0]?.lastName} and ${this.viewers[1]?.firstName} ${this.viewers[1]?.lastName}`;
        this.message = `and ${remainingUsers} ${remainingUsers === 1 ? 'other' : 'others'} are currently in this ${this.type}`;
      }
    }
  }
}
