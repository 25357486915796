export enum Role {
  SUPER_ADMIN = 'SuperAdmin',
  COMPANY_ADMIN = 'CompanyAdmin',
  SYSTEM_ADMIN = 'SystemAdmin',
  VET = 'Vet',
  RECEPTIONIST = 'Receptionist',
  NURSE = 'Nurse',
  HELPDESK = 'Helpdesk',
  ANALYST = 'Analyst',
  NONE = 'None',
}
