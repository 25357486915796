import { Component, Input, OnInit } from '@angular/core';
import { Tag } from '../../../models/Tag';

@Component({
  selector: 'conversation-tags',
  templateUrl: './conversation-tags.component.html',
  styleUrls: ['./conversation-tags.component.scss']
})
export class ConversationTagsComponent implements OnInit {
  @Input() tags: Tag[] = [];

  constructor() { }

  ngOnInit(): void {
  }
  formatTagsKey(tagsName?: string): string {
    if (!tagsName) return 'UNKNOWN_TAG'; // Default fallback
    return tagsName.toLowerCase().replace(/[^a-z0-9]+/g, '_'); // Converts "With client" → "WITH_CLIENT"
  }
}
