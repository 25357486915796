import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { EnvironmentService } from '../services/environment.service';
import {Practice} from "../models/Practice";
import { MediaAddDto } from '../interfaces/dto/media-add.dto';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class VideoService {

  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService,
    private translateService: TranslateService,
  ) { }

  createVideoCompressionJob(conversationId: number, filename: string, caption: string): Observable<{id: string, url: string}> {
    const url = this.environmentService.get('backendUrl') + `/video/compression-jobs`;
    return this.http.post<{id: string, url: string}>(url, {
      conversationId,
      filename,
      caption
    }, {withCredentials: true});
  }

  createMediaLibraryVideoCompressionJob(practice: Practice | null, media: MediaAddDto): Observable<{id: string, url: string}> {
    if (!practice) {
      return this.translateService.get('video.service.practice_not_set').pipe(
        switchMap((translatedMessage: string) => throwError(() => new Error(translatedMessage)))
      );
    }

    const url = this.environmentService.get('backendUrl') + `/video/media-library-compression-jobs`;
    return this.http.post<{id: string, url: string}>(url, {
      practiceId: practice.id,
      name: media.name,
      folderId: media.parentId
    }, {withCredentials: true});
  }

  uploadVideo(url: string, file: File): Observable<HttpEvent<any>> {
    return this.http.put(url, file, {
      withCredentials: false,
      reportProgress: true,
      observe: 'events'
    });
  }

  notifyVideoUploadComplete(id: string): Observable<any> {
    const url = this.environmentService.get('backendUrl') + `/video/compression-jobs/${id}/mark-as-ready`;
    return this.http.post(url, {}, {withCredentials: true}).pipe(
      map((response) => {})
    );
  }
}
