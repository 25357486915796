import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from 'src/app/models/User';
import { Group } from '../../../models/Group';
import { isUser } from '../../../helpers/is-user';

@Component({
  selector: 'user-changer-list',
  templateUrl: './user-changer-list.component.html',
  styleUrls: ['./user-changer-list.component.scss']
})
export class UserChangerListComponent implements OnInit {
  @Input() filter = true;
  @Input() selected?: User | Group | null;
  @Input() users$?: Observable<User[]>;
  @Input() groups$?: Observable<Group[]>;
  @Input() authUser?: User | null;
  @Input() modalOnMobile = true;
  @Output() clickModalBackground = new EventEmitter();
  @Output() userChange = new EventEmitter<User|Group>();
  @Output() currentUserSelected = new EventEmitter();
  @ViewChild('listbox') listbox?: ElementRef;
  users: User[] = [];
  groups: Group[] = [];
  options: any[] = [];
  isUser = isUser;

  constructor() { }

  ngOnInit(): void {
    this.users$?.subscribe((users) => {
      this.users = users;
      this.updateOptions();
    });

    this.groups$?.subscribe((groups) => {
      this.groups = groups;
      this.updateOptions();
    });
  }

  updateOptions(): void {
    this.options = [
      ...this.users.filter((u) => u.id === '0').map((u: any) => {
        return {...u, name: u.fullName};
      }),
      ...this.groups,
      ...this.users.filter((u) => u.id !== '0').map((u: any) => {
        return {...u, name: u.fullName};
      })
    ];
  }

  handleUserChange(changedTo: User | Group): void {
    this.userChange.emit(changedTo);
  }

  handleUserClick(event: {option: User|Group}): void {
    if (this.selected && event.option.id === this.selected.id && isUser(this.selected) === isUser(event.option)) {
      this.currentUserSelected.emit();
    }
  }

  handleWrapClicked(event: Event): void {
    event.stopPropagation();
    if ((event.target as HTMLElement).classList.contains('wrap')) {
      this.clickModalBackground.emit();
    }
  }
}
