import { Component, Input, OnInit } from '@angular/core';
import { Client } from 'src/app/models/Client';
@Component({
  selector: 'financial-notes',
  templateUrl: './financial-notes.component.html',
  styleUrls: ['./financial-notes.component.scss']
})
export class FinancialNotesComponent implements OnInit {
  @Input() client?: Client;
  @Input() enabled = false;
  modalActive = false;

  constructor() {}

  ngOnInit(): void {}

  showModal(): void {
    this.modalActive = true;
  }

  hideModal(): void {
    this.modalActive = false;
  }
}
