import { Pipe, PipeTransform } from "@angular/core";

export enum SnoozeQuickReplyContent {
  SNOOZE_1_HOURS = "1 Hour",
  SNOOZE_4_HOURS = "4 Hours",
  SNOOZE_8_HOURS = "8 Hours",
  SNOOZE_24_HOURS = "24 Hours",
}

const enumToTranslation: Record<SnoozeQuickReplyContent, string> = {
  [SnoozeQuickReplyContent.SNOOZE_1_HOURS]: 'enums.snooze_quick_reply.1_hour',
  [SnoozeQuickReplyContent.SNOOZE_4_HOURS]: 'enums.snooze_quick_reply.4_hours',
  [SnoozeQuickReplyContent.SNOOZE_8_HOURS]: 'enums.snooze_quick_reply.8_hours',
  [SnoozeQuickReplyContent.SNOOZE_24_HOURS]: 'enums.snooze_quick_reply.24_hours',
}

@Pipe({name: 'snoozeQuickReplyContentTranslationKey'})
export class SnoozeQuickReplyContentTranslationKeyPipe implements PipeTransform {
  transform(value: SnoozeQuickReplyContent): string {
    return enumToTranslation[value];
  }
}
