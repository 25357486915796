import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { Practice } from 'src/app/models/Practice';
import { SetSelectedPractice } from 'src/app/practices/state/actions';
import {
  getAvailablePractices,
  getCurrentPractice,
} from 'src/app/practices/state/selectors';
import { AppState } from 'src/app/state/reducers';
import { Theme } from "../../../enums/theme";
@Component({
  selector: 'practice-selector',
  templateUrl: './practice-selector.component.html',
  styleUrls: ['./practice-selector.component.scss'],
})
export class PracticeSelectorComponent implements OnInit, OnDestroy {
  alive = true;
  practices$?: Observable<Practice[] | null>;
  practices: Practice[] = [];
  currentPractice: Practice | null = null;
  filteredPractices: Practice[] = [];
  practiceFilterValue = '';
  theme: string = Theme.DigitalPractice;
  @Input() inputWidth = ''; // for mobile, input at 100%
  @Output() practiceChanged = new EventEmitter();

  constructor(private store: Store<AppState>, private router: Router) {}

  ngOnInit(): void {
    this.subscribeToAvailablePractices();
    this.subscribeToCurrentPractice();
    this.setFilteredPractices();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  subscribeToCurrentPractice(): void {
    this.store
      .pipe(select(getCurrentPractice))
      .pipe(takeWhile(() => this.alive))
      .subscribe((practice) => {
        this.currentPractice = practice;
        if(practice){
          this.theme = practice.theme ? practice.theme : Theme.DigitalPractice;
        }
      });

  }

  subscribeToAvailablePractices(): void {
    this.practices$ = this.store
      .pipe(select(getAvailablePractices))
      .pipe(takeWhile(() => this.alive));
    this.practices$.subscribe((practices) => {
      if (practices) {
        this.practices = practices;
      }
    });
  }

  handlePracticeChange(practice: Practice): void {
    this.practiceChanged.emit();
    this.store.dispatch(SetSelectedPractice({ practice: practice }));
    this.router.navigateByUrl('/day-list');
    this.setFilteredPractices();
  }

  getFilteredPracticesText(): string {
    if (
      this.filteredPractices.length === 0 ||
      (this.filteredPractices.length === 1 &&
        this.currentPractice &&
        this.filteredPractices.includes(this.currentPractice))
    ) {
      return 'No practices found';
    } else {
      let count = this.filteredPractices.length;
      if (
        this.currentPractice &&
        this.filteredPractices.includes(this.currentPractice)
      ) {
        count--;
      }
      const plural = count === 1 ? 'practice' : 'practices';
      return `(Showing ${count} ${plural})`;
    }
  }

  setFilteredPractices(): void {
    if (this.practiceFilterValue === '') {
      this.filteredPractices = this.practices.filter(
        (practice) => practice.id !== this.currentPractice?.id
      ).sort((a, b) => a.name.localeCompare(b.name));
      return;
    }

    this.filteredPractices = this.practices.filter((practice) =>
      practice.name
        ?.toLowerCase()
        .includes(this.practiceFilterValue.toLowerCase())
    ).sort((a, b) => a.name.localeCompare(b.name));;
  }

  clear(): void {
    this.practiceFilterValue = '';
    this.setFilteredPractices();
  }
}
