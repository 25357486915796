import {Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {User} from '../../../models/User';
import {SortDirection} from '../../../enums/sort-direction';
import {ConversationSortBy} from '../../../enums/ConversationSortBy';
import {defaultConversationFilters} from '../../../constants/default-conversation-filters.constants';
import {ActivatedRoute} from '@angular/router';
import {SearchType} from '../../../enums/search-type';
import {DateFilterOption} from '../../../interfaces/date-filter-option.interface';
import {dateFilterOptions} from '../../../constants/date-filter-options.constants';
import {ConversationFilterStatus} from 'src/app/enums/conversation-status';
import {Client} from '../../../../app/models/Client';
import {DateFilterShorthand} from "../../../enums/date-filter-shorthand";
import { Tag } from '../../../models/Tag';
import { Role } from '../../../enums/role';
import { Observable, takeWhile } from 'rxjs';
import { Practice } from '../../../models/Practice';
import { AppState } from '../../../state/reducers';
import { select, Store } from '@ngrx/store';
import { getCurrentPractice } from '../../../practices/state/selectors';
import { FilterOptionType } from '../../../enums/filter-option-type';

interface SortByOption {
  label: string;
  sortBy: ConversationSortBy;
  sortDir: SortDirection;
}

@Component({
  selector: 'conversation-list-filters',
  templateUrl: './conversation-list-filters.component.html',
  styleUrls: ['./conversation-list-filters.component.scss']
})
export class ConversationListFiltersComponent implements OnInit, OnChanges, OnDestroy {
  @Input() allUsers: User[] = [];
  @Input() allClients: Client[] = [];
  @Input() allTags: Tag[] = [];
  @Input() authUser?: User | null;
  @Output() filtersUpdated = new EventEmitter<any>();
  alive = true;
  statusFilterOpen = false;
  statusOptions: string[] = [
    ConversationFilterStatus.ALL,
    ConversationFilterStatus.ACTIVE,
    ConversationFilterStatus.RESOLVED,
    ConversationFilterStatus.WITH_CLIENT,
    ConversationFilterStatus.WITH_CLINIC,
  ];
  selectedStatus = defaultConversationFilters.status;
  ownerFilterOpen = false;
  selectedOwners: number[] = defaultConversationFilters.owner;
  selectedOwnerLabel: string = '';
  assigneeFilterOpen = false;
  selectedAssignees: number[] = defaultConversationFilters.assignee;
  selectedAssigneeLabel = '';
  selectedClients: number[] = defaultConversationFilters.client;
  clientFilterOpen = false;
  selectedClientLabel = '';
  selectedTags: number[] = defaultConversationFilters.tag;
  tagFilterOpen = false;
  selectedTagLabel = '';
  dateOptions: DateFilterOption[] = dateFilterOptions;
  defaultSelectedDate = null;
  selectedDate: DateFilterOption | null = this.defaultSelectedDate;
  dateFilter: {start: string, end: string} | null | string = defaultConversationFilters.date;
  dateFilterOpen = false;
  customRangeOpen = false;
  rangeDates: Date[] = [new Date(), new Date()];
  device = 'desktop';
  resizeTimeout: any;
  sortByOpen = false;
  sortByOptions: SortByOption[] = [
    {label: 'Newest first', sortBy: ConversationSortBy.LAST_RESPONSE_AT, sortDir: SortDirection.DESC},
    {label: 'Oldest first', sortBy: ConversationSortBy.LAST_RESPONSE_AT, sortDir: SortDirection.ASC},
  ];
  defaultSortBy = this.sortByOptions[0];
  selectedSortBy = this.defaultSortBy;
  mobileFiltersOpen = false;
  modalBackground = false;
  practice$: Observable<Practice | null>;
  practice: Practice | null = null;
  superAdmin: Role = Role.SUPER_ADMIN;
  analyst: Role = Role.ANALYST;
  initialFetch = false;

  constructor(private route: ActivatedRoute, public store: Store<AppState>) {
    this.practice$ = this.store.pipe(select(getCurrentPractice)).pipe(takeWhile(() => this.alive));
  }

  ngOnInit(): void {
    this.device = this.getDevice();
    this.subscribeToRouteParams();
    this.subscribeToPractice();
  }

  ngOnChanges(): void {
    this.generateOwnerLabel();
    this.generateAssigneeLabel();
    this.generateClientLabel();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  @HostListener('window:resize')
  handleResize(): void {
    clearTimeout(this.resizeTimeout);
    this.resizeTimeout = setTimeout(() => {
      this.device =  this.getDevice();
    }, 100);
  }

  getDevice(): string {
    if (window.innerWidth <= 420) {
      return 'small-mobile';
    }

    return 'desktop';
  }

  subscribeToPractice(): void {
    this.practice$.subscribe(practice => {
      if (practice) {
        this.practice = practice;
      }
    });
  }

  subscribeToRouteParams(): void {
    this.route.queryParams
      .subscribe((params => {
        if ((params.s && params.type && params.type.toLowerCase() === SearchType.CONVERSATIONS) || !params.s) {
          if (params.owner) {
            this.selectedOwners = JSON.parse(params.owner);
          } else {
           this.selectedOwners = defaultConversationFilters.owner;
          }

          if (params.assignee) {
            this.selectedAssignees = JSON.parse(params.assignee);
          } else {
           this.selectedAssignees = defaultConversationFilters.assignee;
          }

          if (params.client) {
            this.selectedClients = JSON.parse(params.client);
          } else {
           this.selectedClients = defaultConversationFilters.client;
          }

          if (params.status) {
            this.selectedStatus = JSON.parse(params.status);
          } else {
           this.selectedStatus = defaultConversationFilters.status;
          }

          if (params.sortBy && params.sortDir) {
            const paramOption = this.sortByOptions.find(
              (option: SortByOption) =>
                option.sortBy === JSON.parse(params.sortBy) &&
                option.sortDir === JSON.parse(params.sortDir)
            );
            if (paramOption) {
              this.selectedSortBy = paramOption;
            }
          } else {
           this.selectedSortBy = this.defaultSortBy;
          }

          if (params.start && params.end) {
            this.dateFilter = {start: JSON.parse(params.start), end: JSON.parse(params.end)};
            this.rangeDates = [new Date(JSON.parse(params.start)), new Date(JSON.parse(params.end))];
            this.selectedDate = {
              id: DateFilterShorthand.CUSTOM,
              label: 'Within range',
              type: FilterOptionType.DATE_RANGE
            };
          } else if (params.date) {
            const filter = this.dateOptions.find((opt) => opt.id === JSON.parse(params.date)) || this.defaultSelectedDate;
            this.selectedDate = filter;
            this.dateFilter = filter?.id || null;
          } else {
            this.selectedDate = this.defaultSelectedDate;
            this.dateFilter = defaultConversationFilters.date;
          }

          this.generateOwnerLabel();
          this.generateAssigneeLabel();
          this.generateClientLabel();
          
          if (!this.initialFetch) {
            this.initialFetch = true;
            this.handleFiltersChanged();
          }
        }
      })
    );
  }

  clearFilters(): void {
    this.selectedStatus = defaultConversationFilters.status;
    this.selectedOwners = [];
    this.selectedAssignees = [];
    this.selectedClients = [];
    this.selectedDate = this.defaultSelectedDate;
    this.dateFilter = defaultConversationFilters.date;
    this.selectedSortBy = this.defaultSortBy;
    this.customRangeOpen = false;
    this.selectedTags = [];
    this.handleFiltersChanged();
  }

  toggleOwnerDropdown(): void {
    this.ownerFilterOpen = !this.ownerFilterOpen;
    this.recalcModalBackground();
  }

  toggleClientDropdown(): void {
    this.clientFilterOpen = !this.clientFilterOpen;
    this.recalcModalBackground();
  }

  toggleTagDropdown(): void {
    this.tagFilterOpen = !this.tagFilterOpen;
    this.recalcModalBackground();
  }

  toggleAssigneeDropdown(): void {
    this.assigneeFilterOpen = !this.assigneeFilterOpen;
    this.recalcModalBackground();
  }

  ownersChanged(): void {
    this.generateOwnerLabel();
    this.handleFiltersChanged();
  }

  tagsChanged(): void {
    this.generateTagLabel();
    this.handleFiltersChanged();
  }

  clientsChanged(): void {
    this.generateClientLabel();
    this.handleFiltersChanged();
  }

  assigneesChanged(): void {
    this.generateAssigneeLabel();
    this.handleFiltersChanged();
  }

  generateClientLabel(): void {
    if (this.selectedClients.length == 0) {
      this.selectedClientLabel = '';
    } else {
      this.selectedClientLabel = 'Client: ' + this.allClients.find(client => client.id == this.selectedClients[0].toString())?.fullName || '';
    }

    if (this.selectedClients.length > 1) {
      this.selectedClientLabel = this.selectedClientLabel + ' +' + (this.selectedClients.length-1);
    }
  }

  generateAssigneeLabel(): void {
    if (this.selectedAssignees.length == 0) {
      this.selectedAssigneeLabel = '';
    } else {
      this.selectedAssigneeLabel = 'Assignee: ' + this.allUsers.find(user => user.id == this.selectedAssignees[0].toString())?.fullName || '';
    }

    if (this.selectedAssignees.length > 1) {
      this.selectedAssigneeLabel = this.selectedAssigneeLabel + ' +' + (this.selectedAssignees.length-1);
    }
  }

  generateOwnerLabel(): void {
    if (this.selectedOwners.length == 0) {
      this.selectedOwnerLabel = '';
    } else {
      this.selectedOwnerLabel = 'Owner: ' + this.allUsers.find(user => user.id == this.selectedOwners[0].toString())?.fullName || '';
    }

    if (this.selectedOwners.length > 1) {
      this.selectedOwnerLabel = this.selectedOwnerLabel + ' +' + (this.selectedOwners.length-1);
    }
  }

  generateTagLabel(): void {
    if (this.selectedTags.length == 0) {
      this.selectedTagLabel = '';
    } else {
      this.selectedTagLabel = 'Tag: ' + this.allTags.find(tag => tag.id == this.selectedTags[0].toString())?.name || '';
    }

    if (this.selectedTags.length > 1) {
      this.selectedTagLabel = this.selectedTagLabel + ' +' + (this.selectedTags.length-1);
    }
  }

  toggleDateDropdown(): void {
    this.dateFilterOpen = !this.dateFilterOpen;

    if (this.selectedDate === this.dateOptions.find((opt) => opt.id === DateFilterShorthand.CUSTOM)) {
      this.customRangeOpen = true;
    }
  }

  closeDateFilter(target: any): void {
    if (target.className && typeof target.className.includes !== 'undefined' && (target.className.includes('p-datepicker') || target.className.includes('p-yearpicker') || target.className.includes('p-monthpicker'))) {
      // Don't close in this case - bug where it calls click outside even though we are using the datepicker inside the filter dropdown
      return;
    }

    this.dateFilterOpen = false;

    if (this.device === 'small-mobile' && this.customRangeOpen) {
      this.customRangeOpen = false;
    }
  }

  dateChanged(dateOption: DateFilterOption): void {
    if (this.selectedDate === dateOption) {
      // No need to do anything
      return;
    }

    this.customRangeOpen = false;
    this.selectedDate = dateOption;

    let filter = null;

    if (dateOption.id === DateFilterShorthand.CUSTOM) {
      this.customRangeOpen = true;
      filter = {
        start: new Date(this.rangeDates[0]).toISOString(),
        end: new Date(this.rangeDates[1]).toISOString()
      };
    } else {
      filter = dateOption.id;
    }

    this.dateFilter = filter;
    this.handleFiltersChanged();
  }

  rangeSelected(): void {
    this.dateFilter = null;
    if (this.rangeDates[0] !== null && this.rangeDates[1] !== null) {
      this.dateFilter = {start: new Date(this.rangeDates[0]).toISOString(), end: new Date(this.rangeDates[1]).toISOString()};
    }

    this.handleFiltersChanged();
  }

  toggleStatusDropdown(): void {
    this.statusFilterOpen = !this.statusFilterOpen;
  }

  statusChanged(statusOption: string): void {
    this.selectedStatus = statusOption;
    this.handleFiltersChanged();
  }

  toggleSortByDropdown(): void {
    this.sortByOpen = !this.sortByOpen;
  }

  sortByChanged(sortByOption: SortByOption): void {
    this.selectedSortBy = sortByOption;
    this.handleFiltersChanged();
  }

  handleFiltersChanged(): void {
    this.filtersUpdated.emit({
      status: this.selectedStatus,
      owner: this.selectedOwners,
      client: this.selectedClients,
      tag: this.selectedTags,
      assignee: this.selectedAssignees,
      date: this.dateFilter,
      sortBy: this.selectedSortBy.sortBy,
      sortDir: this.selectedSortBy.sortDir,
    });
  }

  toggleMobileFilters() {
    this.mobileFiltersOpen = !this.mobileFiltersOpen;
  }

  recalcModalBackground() {
    if (!this.ownerFilterOpen && !this.assigneeFilterOpen && !this.clientFilterOpen && !this.statusFilterOpen && !this.dateFilterOpen) {
      this.modalBackground = false;
    } else {
      this.modalBackground = true;
    }
  }
}
