import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {AppState} from '../../state/reducers';
import {Router} from '@angular/router';
import * as TemplateActions from './actions';
import {getCurrentPractice} from '../../practices/state/selectors';
import {catchError, map, mergeMap, withLatestFrom} from 'rxjs/operators';
import {TemplateService} from '../templates.service';
import {Template} from '../../models/Template';
import {MessageService} from 'primeng/api';
import {Noop} from '../../state/actions';
import {of} from 'rxjs';
import {DefaultWhatsappTemplate} from "../../models/DefaultWhatsappTemplate";

@Injectable()
export class WhatsappTemplatesEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private router: Router,
    private templateService: TemplateService,
    private messageService: MessageService
  ) {}

  getTemplates$ = createEffect(() => this.actions$.pipe(
    ofType(TemplateActions.GetTemplates),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      return this.templateService.getTemplates(action.syncWith360, practice, action.includeArchived)
        .pipe(
          map((result: Template[]) => {
            return TemplateActions.GetTemplatesSuccess({templates: result});
          }),
          catchError(() => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred when getting templates',
              life: 5000
            });
            return of(TemplateActions.TemplateActionFailed());
          })
        );
    })
  ));

  getDefaultTemplates$ = createEffect(() => this.actions$.pipe(
    ofType(TemplateActions.GetDefaultTemplates),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      return this.templateService.getDefaults(practice)
        .pipe(
          map((result: DefaultWhatsappTemplate[]) => {
            return TemplateActions.GetDefaultTemplatesSuccess({templates: result});
          }),
          catchError(() => {
            return of(Noop);
          })
        );
    })
  ));

  getCampaignTemplates$ = createEffect(() => this.actions$.pipe(
    ofType(TemplateActions.GetCampaignTemplates),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      return this.templateService.getTemplates(false, practice, true)
        .pipe(
          map((result: Template[]) => {
            return TemplateActions.GetTemplatesSuccess({templates: result});
          }),
          catchError(() => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred when getting templates',
              life: 5000
            });
            return of(TemplateActions.TemplateActionFailed());
          })
        );
    })
  ));

  getActiveTemplate$ = createEffect(() => this.actions$.pipe(
    ofType(TemplateActions.GetActivePaymentTemplate),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      return this.templateService.getActivePaymentTemplate(practice)
        .pipe(
          map((result: Template | null) => {
            return TemplateActions.GetActivePaymentTemplateSuccess({template: result});
          })
        );
    })
  ));

  updateTemplateActive$ = createEffect(() => this.actions$.pipe(
    ofType(TemplateActions.UpdateTemplateActive),
    mergeMap((action) => {
      return this.templateService.updateTemplateActive(action.template)
        .pipe(
          map((result: Template) => {
            return TemplateActions.UpdateTemplateActiveSuccess({template: result});
          }),
          catchError(() => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred when updating this template',
              life: 5000
            });
            return of(TemplateActions.TemplateActionFailed());
          })
        );
    })
  ));

  setTemplateToType$ = createEffect(() => this.actions$.pipe(
    ofType(TemplateActions.SetTemplateToType),
    mergeMap((action) => {
      return this.templateService.updateTemplateType(action.template, action.templateType)
        .pipe(
          map((result: Template[]) => {
            return TemplateActions.SetTemplateToTypeSuccess({updatedTemplates: result});
          }),
          catchError(() => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred when updating this template',
              life: 5000
            });
            return of(TemplateActions.TemplateActionFailed());
          })
        );
    })
  ));

  deleteTemplate$ = createEffect(() => this.actions$.pipe(
    ofType(TemplateActions.DeleteTemplate),
    mergeMap((action) => {
      return this.templateService.deleteTemplate(action.template)
        .pipe(
          map((result) => {
            return TemplateActions.DeleteTemplateSuccess({template: action.template});
          }),
          catchError(() => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred when deleting this template',
              life: 5000
            });
            return of(TemplateActions.TemplateActionFailed());
          })
        );
    })
  ));

  unarchiveTemplate$ = createEffect(() => this.actions$.pipe(
    ofType(TemplateActions.UnarchiveTemplate),
    mergeMap((action) => {
      return this.templateService.unarchiveTemplate(action.template)
        .pipe(
          map((result) => {
            return TemplateActions.UnarchiveTemplateSuccess({template: action.template});
          }),
          catchError(() => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred when restoring this template',
              life: 5000
            });
            return of(TemplateActions.TemplateActionFailed());
          })
        );
    })
  ));

  createTemplate$ = createEffect(() => this.actions$.pipe(
    ofType(TemplateActions.CreateTemplate),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      return this.templateService.createTemplate(practice, action.dto)
        .pipe(
          map((result: Template) => {
            return TemplateActions.CreateTemplateSuccess({template: result});
          }),
          catchError(() => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred when creating the template',
              life: 5000
            });
            return of(TemplateActions.TemplateActionFailed());
          })
        );
    })
  ));

  updateTemplate$ = createEffect(() => this.actions$.pipe(
    ofType(TemplateActions.UpdateTemplate),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      return this.templateService.updateTemplate(practice, action.dto)
        .pipe(
          map((result: Template) => {
            return TemplateActions.UpdateTemplateSuccess({template: result});
          }),
          catchError(() => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred when updating the template, please try again later',
              life: 5000
            });
            return of(TemplateActions.TemplateActionFailed());
          })
        );
    })
  ));
}
