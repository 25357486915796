import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree, Router, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {TokenService} from '../services/token.service';
import {UserService} from '../users/user.service';
import {CookieService} from 'ngx-cookie-service';
import {isMessagingEnabled} from '../helpers/is-messaging-enabled';
import {practiceHasFeature} from '../helpers/practice-has-feature';
import {PracticeFeature} from '../enums/practice-feature';

@Injectable({
  providedIn: 'root'
})
export class PracticeHasPaymentsGuard  {
  constructor(private userService: UserService, private cookieService: CookieService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.userService.getUserPractices().pipe(
      map((response) => {
        let practice;

        const cookiePractice = this.cookieService.get('messaging-selected-practice');

        if (cookiePractice) {
          practice = response.find(actionPractice => actionPractice.id === cookiePractice);
        }

        if (!practice && response.length > 0) {
          practice = response[0];
        }

        if (!practiceHasFeature(practice, PracticeFeature.PAYMENTS)) {
          // Payments feature not enabled

          if (isMessagingEnabled(practice)) {
            this.router.navigateByUrl('/day-list');
          } else {
            this.router.navigateByUrl('/setup-issue');
          }

          return false;
        }

        return true;
      })
    );
  }
}
