import { createAction, props } from "@ngrx/store";
import { ViewerType } from "../../enums/viewers-type";
import { User } from "../../models/User";

export const GetViewers = createAction(
    '[Viewers] Get Viewers',
    props<{id: string, modelType: ViewerType}>()
);

export const GetViewersSuccess = createAction(
    '[Viewers] Get Viewers Success',
    props<{viewers: User[]}>()
);

export const ClearViewers = createAction(
    '[Viewers] Clear Viewers'
);
