import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Socket } from 'ngx-socket-io';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class SocketService extends Socket {
  constructor(private environmentService: EnvironmentService, private cookieService: CookieService) {
    super({ url: environmentService.get('websocketUrl'), options: {
      transports: ['websocket']
    }});
  }

  emit(eventName: string, data: any): any {
    const output = JSON.stringify({
      ...data,
      jwt: this.cookieService.get(this.environmentService.get('jwtPrefix') + 'JWT')
    });
    return super.emit(eventName, output);
  }
}
