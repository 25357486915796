import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { HistoryItemAdapter } from '../adapters/history-item.adapter';
import { PatientAdapter } from '../adapters/patient.adapter';
import { HistoryItemDto } from '../interfaces/dto/history-item.dto';
import { PatientDto } from '../interfaces/dto/patient.dto';
import { HistoryItem } from '../models/HistoryItem';
import { Patient } from '../models/Patient';
import { EnvironmentService } from '../services/environment.service';

@Injectable({
  providedIn: 'root'
})
export class PatientService {

  constructor(
    private environmentService: EnvironmentService,
    private http: HttpClient,
    private patientAdapter: PatientAdapter,
    private historyItemAdapter: HistoryItemAdapter
  ) { }

  getPatient(patientId: string): Observable<Patient> {
    const url = this.environmentService.get('backendUrl') + `/patients/${patientId}`;
    return this.http.get<PatientDto>(url, {withCredentials: true}).pipe(
      map((response: PatientDto) => {
        return this.patientAdapter.run(response);
      })
    );
  }

  shareToPms(patient: Patient): Observable<boolean> {
    const url = this.environmentService.get('backendUrl') + `/patients/${patient.id}/share-to-pms`;
    return this.http.get<{success: boolean}>(url, {withCredentials: true}).pipe(
      map((response: {success: boolean}) => {
        return response.success;
      })
    );
  }

  getHistory(patientId: string, skip: number, limit: number): Observable<HistoryItem[]> {
    const url = this.environmentService.get('backendUrl') + `/patients/${patientId}/history`;
    return this.http.post<HistoryItemDto[]>(url, {skip, limit}, {withCredentials: true}).pipe(
      map((response: HistoryItemDto[]) => {
        return response.map(item => this.historyItemAdapter.run(item));
      })
    );
  }
}
