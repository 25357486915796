import {createFeatureSelector, createSelector} from '@ngrx/store';
import {AppState, STATE_KEY} from '../../state/reducers';
import {ProductRequestState} from './reducer';
import {ProductRequestStatus} from '../../enums/product-request-status';

export const getProductRequestState = createFeatureSelector<AppState, ProductRequestState>(STATE_KEY.PRODUCT_REQUESTS);

export const isProductRequestOpen = createSelector(
  getProductRequestState,
  (state: ProductRequestState) => state.productRequestOpen
);

export const getProductRequestClient = createSelector(
  getProductRequestState,
  (state: ProductRequestState) => state.productRequestClient
);

export const getProductRequestContact = createSelector(
  getProductRequestState,
  (state: ProductRequestState) => state.productRequestContact
);

export const getProductRequestPatient = createSelector(
  getProductRequestState,
  (state: ProductRequestState) => state.productRequestPatient
);

export const getProductRequestChannel = createSelector(
  getProductRequestState,
  (state: ProductRequestState) => state.productRequestChannel
);

export const getProductRequestPatients = createSelector(
  getProductRequestState,
  (state: ProductRequestState) => state.productRequestPatients
);

export const IsProductRequestPatientsLoading = createSelector(
  getProductRequestState,
  (state: ProductRequestState) => state.patientsLoading
);

export const getProductRequestsList = createSelector(
  getProductRequestState,
  (state: ProductRequestState) => state.productRequestsList
);

export const getProductRequestsListLoading = createSelector(
  getProductRequestState,
  (state: ProductRequestState) => state.productRequestsListLoading
);

export const getProductRequestsListTotalItems = createSelector(
  getProductRequestState,
  (state: ProductRequestState) => state.productRequestsListTotalItems
);

export const getProductRequests = createSelector(
  getProductRequestState,
  (state: ProductRequestState) => state.productRequests
);

export const getAwaitingApprovalProductRequests = createSelector(
  getProductRequestState,
  (state: ProductRequestState) => state.productRequests.filter((pr) => pr.status === ProductRequestStatus.AWAITING_APPROVAL)
);

export const getReadyToPayProductRequests = createSelector(
  getProductRequestState,
  (state: ProductRequestState) => state.productRequests.filter((pr) => pr.status === ProductRequestStatus.READY_TO_PAY)
);

export const getAwaitingPaymentProductRequests = createSelector(
  getProductRequestState,
  (state: ProductRequestState) => state.productRequests.filter((pr) => pr.status === ProductRequestStatus.AWAITING_PAYMENT)
);

export const getPaidProductRequests = createSelector(
  getProductRequestState,
  (state: ProductRequestState) => state.productRequests.filter((pr) => pr.status === ProductRequestStatus.PAID)
);

export const getCompleteProductRequests = createSelector(
  getProductRequestState,
  (state: ProductRequestState) => state.productRequests.filter((pr) => pr.status === ProductRequestStatus.COMPLETE)
);

export const getFulfilledProductRequests = createSelector(
  getProductRequestState,
  (state: ProductRequestState) => state.productRequests.filter((pr) => pr.status === ProductRequestStatus.FULFILLED)
);

export const getCancelledProductRequests = createSelector(
  getProductRequestState,
  (state: ProductRequestState) => state.productRequests.filter((pr) => pr.status === ProductRequestStatus.CANCELLED)
);

export const getRejectedProductRequests = createSelector(
  getProductRequestState,
  (state: ProductRequestState) => state.productRequests.filter((pr) => pr.status === ProductRequestStatus.REJECTED)
);

export const getProductRequestComments = createSelector(
  getProductRequestState,
  (state: ProductRequestState) => state.productRequestComments
);

export const getProductRequestCommentsLoading = createSelector(
  getProductRequestState,
  (state: ProductRequestState) => state.productRequestCommentsLoading
);

export const getProductRequestHistory = createSelector(
  getProductRequestState,
  (state: ProductRequestState) => state.productRequestHistory
);

export const getProductRequestHistoryLoading = createSelector(
  getProductRequestState,
  (state: ProductRequestState) => state.productRequestHistoryLoading
);

export const getProductRequestApprovalOpen = createSelector(
  getProductRequestState,
  (state: ProductRequestState) => state.productRequestApprovalOpen
);

export const getProductRequestForApproval = createSelector(
  getProductRequestState,
  (state: ProductRequestState) => state.productRequestForApproval
);

export const getProductRequestRejectionOpen = createSelector(
  getProductRequestState,
  (state: ProductRequestState) => state.productRequestRejectionOpen
);

export const getProductRequestForRejection = createSelector(
  getProductRequestState,
  (state: ProductRequestState) => state.productRequestForRejection
);

export const getProductRequestPaymentCreateOpen = createSelector(
  getProductRequestState,
  (state: ProductRequestState) => state.productRequestPaymentCreateOpen
);

export const getProductRequestForPaymentCreate = createSelector(
  getProductRequestState,
  (state: ProductRequestState) => state.productRequestForPaymentCreate
);

export const getProductRequestPaidConfirmationOpen = createSelector(
  getProductRequestState,
  (state: ProductRequestState) => state.productRequestPaidConfirmationOpen
);

export const getProductRequestPaidConfirmationContext = createSelector(
  getProductRequestState,
  (state: ProductRequestState) => state.productRequestPaidConfirmationContext
);

export const getProductRequestForPaidConfirmation = createSelector(
  getProductRequestState,
  (state: ProductRequestState) => state.productRequestForPaidConfirmation
);

export const getDispenseProductRequestOpen = createSelector(
  getProductRequestState,
  (state: ProductRequestState) => state.productRequestDispenseOpen
);

export const getProductRequestForDispense = createSelector(
  getProductRequestState,
  (state: ProductRequestState) => state.productRequestForDispense
);

export const getProductRequestDetailOpen = createSelector(
  getProductRequestState,
  (state: ProductRequestState) => state.productRequestDetailOpen
);

export const getProductRequestDetailContext = createSelector(
  getProductRequestState,
  (state: ProductRequestState) => state.productRequestDetailContext
);

export const getCurrentProductRequest = createSelector(
  getProductRequestState,
  (state: ProductRequestState) => state.currentProductRequest
);

export const getUpdatingProductRequests = createSelector(
  getProductRequestState,
  (state: ProductRequestState) => state.updatingProductRequests
);

export const getCurrentProductRequestClient = createSelector(
  getProductRequestState,
  (state: ProductRequestState) => state.currentProductRequestClient
);

export const getCurrentProductRequestClientLoading = createSelector(
  getProductRequestState,
  (state: ProductRequestState) => state.currentProductRequestClientLoading
);

export const getCurrentProductRequestPatient = createSelector(
  getProductRequestState,
  (state: ProductRequestState) => state.currentProductRequestPatient
);

export const getCurrentProductRequestPatientLoading = createSelector(
  getProductRequestState,
  (state: ProductRequestState) => state.currentProductRequestPatientLoading
);

export const getCurrentProductRequestPatientHistory = createSelector(
  getProductRequestState,
  (state: ProductRequestState) => state.currentProductRequestPatientHistory
);

export const getCurrentProductRequestPatientHistoryLoading = createSelector(
  getProductRequestState,
  (state: ProductRequestState) => state.currentProductRequestPatientHistoryLoading
);
