import { Injectable } from '@angular/core';
import { WhatsappTemplates } from '../enums/whatsapp-templates';

@Injectable({
  providedIn: 'root'
})
export class WhatsappTemplateService {
  constructor() {
  }

  newTicket(practiceName: string): string {
    return WhatsappTemplates.new_ticket.replace('{{ practice }}', practiceName);
  }

  reinitialise(): string {
    return WhatsappTemplates.
      reinitialise;
  }
}
