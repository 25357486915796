<ng-container *ngIf="showNewConversationModal">
    <p-dialog
        header="New Conversation"
        [(visible)]="showNewConversationModal"
        [modal]="true"
        [style]="{width: '540px'}"
        [baseZIndex]="10000"
        [draggable]="false"
        [resizable]="false"
    >
    <p-header>
        <div class="dialog-header">
          New Conversation 
        </div>
      </p-header>
        <new-conversation [practice]="(practice$ | async) || undefined" (conversationCreated)="handleConversationCreated()" (closed)="handleNewConversationClosed()"></new-conversation>
        <div class="bottom-row">
          <div class="left"></div>
          <div class="center">
            <a [href]="helpLink + '/category/faq/post/how-do-i-add-client-message-templates'" target="_blank"><img
                src="/assets/images/icons/question_mark.png" height="20" class="help-icon" alt=""> Need help?</a>
          </div>
          <div class="right"></div>
        </div>
    </p-dialog>
</ng-container>

<p-button (click)="openNewConversationModal()" styleClass="p-button-fifth p-button-fullwidth p-button-center p-button-hide-label">
    <img alt="logo" class="icon" src="/assets/images/icons/ticket-white.svg" />
    <span class="p-ml-2">New conversation</span>
</p-button>
