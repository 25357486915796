<form class="rename-folder" [formGroup]="form">
  <div class="input-row">
    <div class="label">{{'media.rename_folder.name' | translate}}</div>
    <input #nameInput pInputText class="p-inputtext" type="text" formControlName="name" maxlength="50">
    <ng-container *ngIf="form.controls.name.invalid && (form.controls.name.dirty || form.controls.name.touched) && form.controls.name.errors?.['required']">
      <div class="error">{{'media.rename_folder.must_enter_name' | translate}}</div>
    </ng-container>
  </div>
  <div class="btn-row">
    <p-button (click)="submit()" [label]="'media.rename_folder.rename' | translate" styleClass="p-button-primary"></p-button>
  </div>
</form>
