import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {Injectable} from '@angular/core';
import {combineLatest, distinctUntilChanged, firstValueFrom, merge, Observable, of} from 'rxjs';
import {UserService} from '../users/user.service';
import {CookieService} from 'ngx-cookie-service';
import {map, mergeMap, tap, withLatestFrom} from 'rxjs/operators';
import {ConversationService} from '../conversation/conversation.service';
import {Role} from '../enums/role';
import {getCurrentPractice} from '../practices/state/selectors';
import {Store} from '@ngrx/store';
import {AppState} from '../state/reducers';

@Injectable({
  providedIn: 'root'
})
export class IsAdminGuard  {
  constructor(
    private userService: UserService,
    private store: Store<AppState>,
    private cookieService: CookieService,
    private router: Router
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const userPractices = this.userService.getUserPractices()
      .pipe(
        withLatestFrom(this.store.select(getCurrentPractice)),
        map(([practices, currentPractice]) => {
          return {
            practices,
            currentPractice
          };
        })
      );

    return userPractices.pipe(map((practiceObject) => {
      if (practiceObject.practices[0].userRole === Role.SUPER_ADMIN) {
        return true;
      }

      if (
        practiceObject.currentPractice &&
        (practiceObject.currentPractice.userRole === Role.SYSTEM_ADMIN || practiceObject.currentPractice.userRole === Role.COMPANY_ADMIN)
      ) {
        return true;
      }

      return false;
    }));
  }
}
