import { Pipe, PipeTransform } from "@angular/core";

export enum PaymentType {
    STANDARD = 'Standard',
    AUTOMATIC = 'Automatic',
    BROADCAST = 'Broadcast',
    ONLINE = 'Online',
    FORM = 'Form',
}

const enumToTranslation: Record<PaymentType, string> = {
  [PaymentType.AUTOMATIC]: 'enums.payment_type.automatic',
  [PaymentType.BROADCAST]: 'enums.payment_type.broadcast',
  [PaymentType.FORM]: 'enums.payment_type.form',
  [PaymentType.ONLINE]: 'enums.payment_type.online',
  [PaymentType.STANDARD]: 'enums.payment_type.standard',
}

@Pipe({name: 'paymentTypeTranslationKey'})
export class PaymentTypeTranslationKeyPipe implements PipeTransform {
  transform(value: PaymentType): string {
    return enumToTranslation[value];
  }
}
