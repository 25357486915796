<ng-container *ngIf="user && isUser(user) && user.avatarUrl">
  <div class="avatar image" [style.background-image]="'url(\''+user.avatarUrl+'\')'" [class.responsive]="responsive" [class.small]="size === 'small'" [class.x-small]="size === 'x-small'" alt=""></div>
</ng-container>

<ng-container *ngIf="user && isUser(user) && !user.avatarUrl && user.id !== '0'">
    <div class="avatar" [class.small]="size === 'small'" [class.x-small]="size === 'x-small'" [class.responsive]="responsive" [style.background-color]="backgroundColor">
        <div class="initials">
            <ng-container *ngIf="user.firstName && user.firstName[0]">{{ user.firstName[0] }}</ng-container>
            <ng-container *ngIf="user.lastName && user.lastName[0]">{{ user.lastName[0] }}</ng-container>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="!user || user.id === '0'">
    <div class="avatar unset" [class.responsive]="responsive" [class.small]="size === 'small'" [class.x-small]="size === 'x-small'">
        <div class="initials">{{ unsetInitials }}</div>
    </div>
</ng-container>

<ng-container *ngIf="user && !isUser(user)">
  <div class="avatar with-icon" [class.small]="size === 'small'" [class.x-small]="size === 'x-small'" [style.background-color]="backgroundColor">
    <img src="/assets/images/icons/group.svg" alt="" class="icon">
    <div class="initials">
      {{ user.name[0] }}
    </div>
  </div>
</ng-container>
