import {FormFieldPrefillOption} from '../enums/form-field-prefill-option.enum';

export function prettifyFormFieldPrefillOption(value: FormFieldPrefillOption): string {
  switch (value) {
    case FormFieldPrefillOption.CLIENT_NAME:
      return 'helpers.prettify_form_field_prefill_option.client_name';
    case FormFieldPrefillOption.CLIENT_EMAIL:
      return 'helpers.prettify_form_field_prefill_option.client_email';
    case FormFieldPrefillOption.CLIENT_PHONE:
      return 'helpers.prettify_form_field_prefill_option.client_phone';
    case FormFieldPrefillOption.CLIENT_POSTCODE:
      return 'helpers.prettify_form_field_prefill_option.client_postcode';
    case FormFieldPrefillOption.CLIENT_PMS_ID:
      return 'helpers.prettify_form_field_prefill_option.client_id';
    case FormFieldPrefillOption.PRACTICE_NAME:
      return 'helpers.prettify_form_field_prefill_option.practice_name';
    case FormFieldPrefillOption.PATIENT_NAME:
      return 'helpers.prettify_form_field_prefill_option.patient_name';
    case FormFieldPrefillOption.PATIENT_PMS_ID:
      return 'helpers.prettify_form_field_prefill_option.patient_id';
  }
}
