import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { User } from '../models/User';
import { UserDto } from '../interfaces/dto/user.dto';
import { EnvironmentService } from '../services/environment.service';
import { map, takeWhile, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { AppState } from '../state/reducers';
import * as Actions from './state/actions';
import { OauthToken } from './interfaces/oauth-token';
import { TokenRequestParams } from './interfaces/token-request-params';
import { UserAdapter } from '../adapters/user.adapter';
import {ClearAvailablePractices, GetUserPractices} from '../practices/state/actions';
import { getUser } from './state/selectors';
import { Location } from '@angular/common';
import {GetUnreadHelpPosts, Logout} from './state/actions';
import { getOneSignalUserId } from '../state/selectors';
import {Router} from '@angular/router';
import {HelpPost} from '../interfaces/help-post.interface';
import {HelpPostDto} from '../interfaces/dto/help-post.dto';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  oneSignalUserId: string | null = null;
  user: User | null = null;

  constructor(
    private router: Router,
    private http: HttpClient,
    private environmentService: EnvironmentService,
    private cookieService: CookieService,
    private userAdapter: UserAdapter,
    private store: Store<AppState>,
    private location: Location
  ) {
    this.subscribeToOneSignal();
  }

  hasJwtCookie(): boolean {
    return this.cookieService.check(this.environmentService.get('jwtPrefix') + 'JWT');
  }

  getUser(): Observable<User> {
    return this.http.get<UserDto>(this.environmentService.get('backendUrl') + '/auth/user').pipe(
      map((response: UserDto) => this.userAdapter.run(response))
    );
  }

  getAndStoreUser(): void {
    this.getUser().subscribe((user) => {
      this.user = user;
      this.storeUser(user);
      this.updateUserDevices();
    }, (e) => {
      console.log(e);
    });
  }

  getUserUnreadHelpPosts(): Observable<HelpPostDto[]> {
    return this.http.get<HelpPostDto[]>(this.environmentService.get('authUrl') + '/api/v1/learn/section/digital-practice/latest-posts', {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${this.cookieService.get('access_token')}`
      }
    });
  }

  markHelpPostsSeen(): Observable<boolean> {
    return this.http.get<boolean>(this.environmentService.get('authUrl') + '/api/v1/learn/section/digital-practice/mark-latest-read', {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${this.cookieService.get('access_token')}`
      }
    });
  }

  recoverSession(): boolean {
    this.store.pipe(select(getUser)).subscribe((user) => {
      if (user) {
        this.store.dispatch(GetUserPractices());
      }
    });

    if (!this.location.path().includes('auth/callback')) {
      this.getAndStoreUser();
      this.store.dispatch(GetUnreadHelpPosts());
    }

    return true;
  }

  storeUser(user: any): void {
    this.store.dispatch(Actions.StoreUser({user}));
  }

  refreshToken(): Observable<OauthToken>  {
    return this.http.post<OauthToken>(this.environmentService.get('authUrl') + '/api/v1/oauth/token', {
      grant_type: 'refresh_token',
      client_id: this.environmentService.get('authClientId'),
    }, {withCredentials: true});
  }

  getToken(params: TokenRequestParams): Observable<OauthToken>  {
    return this.http.post<OauthToken>(this.environmentService.get('authUrl') + '/api/v1/oauth/token', params, {withCredentials: true});
  }

  getJwt(login?: boolean): Observable<Response> {
    let route = '/auth/request-token';
    if (login) {
      route += '?login=1';
    }
    return this.http.get<Response>(this.environmentService.get('backendUrl') + route, {withCredentials: true});
  }

  getCoreJwt(): Observable<Response> {
    return this.http.get<Response>(this.environmentService.get('authUrl') + '/api/v1/auth/jwt', {withCredentials: true});
  }

  logout(): void {
    this.doLogout().subscribe(() => {
      setTimeout(() => {
        this.router.navigate(['/auth/login']);
      }, 0);
      this.store.dispatch(ClearAvailablePractices());
      setTimeout(() => {
        this.store.dispatch(Logout());
      }, 1000);
    }, (e) => {
      console.log(e);
    });
  }

  doLogout(): Observable<Response> {
    this.removeUserDevice();
    return this.http.get<Response>(this.environmentService.get('authUrl') + '/api/v1/auth/logout', {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${this.cookieService.get('access_token')}`
      }
    });
  }

  removeUserDevice(): void {
    if (this.oneSignalUserId && this.user) {
      this.http.delete<Response>(this.environmentService.get('backendUrl') + '/users/' + this.user.id + '/devices/' + this.oneSignalUserId, {withCredentials: true}).subscribe((response) => {});
    }
  }

  updateUserDevices(): void {
    if (this.oneSignalUserId && this.user) {
      this.http.post<Response>(this.environmentService.get('backendUrl') + '/users/' + this.user.id + '/devices', {
        deviceId: this.oneSignalUserId
      }, {withCredentials: true}).subscribe((response) => {});
    }
  }

  subscribeToOneSignal(): void {
    this.store.pipe(select(getOneSignalUserId)).subscribe((oneSignalUserId: string | null) => {
      this.oneSignalUserId = oneSignalUserId;
    });
  }
}
