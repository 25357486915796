import { Injectable, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from '../state/reducers';
import { UpdatePracticeAccentColor } from '../practices/state/actions';
import { Observable, takeWhile } from 'rxjs';
import { Practice } from '../models/Practice';
import { getCurrentPractice } from '../practices/state/selectors';
import { Theme } from '../enums/theme';
@Injectable({
  providedIn: 'root'
})

export class ThemeService  {
  private root = document.documentElement;
  alive = true;
  practice$?: Observable<Practice | null>;
  practice?: Practice;
  theme?: string = Theme.DigitalPractice;

  constructor(private store: Store<AppState>,) {
    this.subscribeToCurrentPractice();
  }

  subscribeToCurrentPractice(): void {
    this.practice$ = this.store
      .pipe(select(getCurrentPractice))
      .pipe(takeWhile(() => this.alive));

    this.practice$.subscribe((practice) => {
      if (practice) {
        this.practice = practice;
        this.theme = practice.theme;
        this.updateThemeVariables(this.theme);
      }
    });
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  updateThemeVariables(theme: string | undefined): void {
    this.theme = theme;
    if(theme){
      this.applyTheme(theme);
    }
  
    if (theme === Theme.DigitalPractice) {
      document.body.classList.add('digital-practice-theme');
      document.body.classList.remove('provet-theme');
    
      if(this.practice && this.practice.accentColor == "440197"){
        this.store.dispatch(UpdatePracticeAccentColor({ id: this.practice.id, newAccentColor: "FFFFFF" }));
      }
      
    } else if(theme === Theme.Provet) {
      document.body.classList.remove('digital-practice-theme');
      document.body.classList.add('provet-theme');
     
      if(this.practice){
        if(this.practice.accentColor != "440197")
        this.store.dispatch(UpdatePracticeAccentColor({ id: this.practice.id, newAccentColor: "440197" }));
       
      }
      
    }
  }

  private applyTheme(theme: string) {

    if (theme === Theme.DigitalPractice) {
      this.root.style.setProperty('--surface-a', "#ffffff");
      this.root.style.setProperty('--text-color', "#4F4F4F");
      this.root.style.setProperty('--surface-200', "#EEEEEE");
      this.root.style.setProperty('--primary-color', "#554382");
      this.root.style.setProperty('--secondary-color', "var(--pink-500)");
      this.root.style.setProperty('--fourth-color', "#EAF2FD");
      this.root.style.setProperty('--aquamarine-400', "#2DA782");
      this.root.style.setProperty('--aquamarine-300', "#4EB992");
      this.root.style.setProperty('--text-color-three', "#00344A");
      this.root.style.setProperty('--nav-text-color', "#4F4F4F");
      this.root.style.setProperty('--hover-text-color', "#4F4F4F");
      this.root.style.setProperty('--text-color-four', "#828282");
      this.root.style.setProperty('--vhd-color', "#A50060");
      this.root.style.setProperty('--alert-color', "#EB5757");
      this.root.style.setProperty('--surface-text-color', "#EB5757");
      this.root.style.setProperty('--third-color', "#2F80ED");
      this.root.style.setProperty('--filter-button-text-color', "#0C1A3D");
      this.root.style.setProperty('--action-button-text-color', "#FFFFFF");
      this.root.style.setProperty('--pms-icon-active-color', "#4EB992");
      this.root.style.setProperty('--pms-icon-inactive-color', "#EB5757");
      this.root.style.setProperty('--table-heading-color', "#00344A");
      this.root.style.setProperty('--more-action-text-color', "#00344A");
      this.root.style.setProperty('--button-ghost-color', "#554382");
      this.root.style.setProperty('--button-ghost-background-color', "transparent");
      this.root.style.setProperty('--button-ghost-active-color', "#554382");
      this.root.style.setProperty('--button-ghost-active-background-color', "#EEEEEE");
      this.root.style.setProperty('--tag-text-color', "#00344A");
      this.root.style.setProperty('--tag-bg-color', "#F2F2F2");
      this.root.style.setProperty('--resolve-button-text-color', "#FFFFFF");
      this.root.style.setProperty('--nav-logo-text-color', "#0C1A3D");
      this.root.style.setProperty('--tag-text-color','#EEEEEEE');
      this.root.style.setProperty('--back-icon-color','#4F4F4F');
      this.root.style.setProperty('--mention-text-color', '#4F4F4F');
      this.root.style.setProperty('--mention-bg-color','#FFFFFF');

    } else if(theme === Theme.Provet) {
      this.root.style.setProperty('--surface-a', "#440197");
      this.root.style.setProperty('--surface-200', "#9068FE");
      this.root.style.setProperty('--primary-color', "#9068FE");
      this.root.style.setProperty('--secondary-color', "#9068FE");
      this.root.style.setProperty('--fourth-color', "#ECE3FF");
      this.root.style.setProperty('--aquamarine-400', "#7CB1FF");
      this.root.style.setProperty('--aquamarine-300', "#4E71DE");
      this.root.style.setProperty('--text-color-three', "#7CB1FF");
      this.root.style.setProperty('--nav-text-color', "#FFFFFF");
      this.root.style.setProperty('--hover-text-color', "#FFFFFF");
      this.root.style.setProperty('--text-color-four', "#E5E0E0");
      this.root.style.setProperty('--vhd-color', "#440197");
      this.root.style.setProperty('--alert-color', "#9068F3");
      this.root.style.setProperty('--surface-text-color', "#4F4F4F");
      this.root.style.setProperty('--third-color', "#0C1A3D");
      this.root.style.setProperty('--action-button-text-color', "#0C1A3D");
      this.root.style.setProperty('--pms-icon-active-color', "#FFFFFF");
      this.root.style.setProperty('--pms-icon-inactive-color', "#FFFFFF");
      this.root.style.setProperty('--table-heading-color', "#0C1A3D");
      this.root.style.setProperty('--more-action-text-color', "#4F4F4F");
      this.root.style.setProperty('--button-ghost-color', "#0C1A3D");
      this.root.style.setProperty('--button-ghost-background-color', "#7CB1FF");
      this.root.style.setProperty('--button-ghost-active-color', "#0C1A3D");
      this.root.style.setProperty('--button-ghost-active-background-color', "#7CB1FF");
      this.root.style.setProperty('--tag-text-color', "#F2F2F2");
      this.root.style.setProperty('--tag-bg-color', "#7CB1FF");
      this.root.style.setProperty('--resolve-button-text-color', "#0C1A3D");
      this.root.style.setProperty('--nav-logo-text-color', "#FFFFFF");
      this.root.style.setProperty('--tag-text-color','#FFFFFF');
      this.root.style.setProperty('--back-icon-color','#FFFFFF');
      this.root.style.setProperty('--mention-text-color', '#FFFFFF');
      this.root.style.setProperty('--mention-bg-color','#9068FE');
    }
    
  }

  getThemeIcons(iconName : string , theme:  string | undefined): string {
    let iconUrl = "";
    switch (iconName) {
      case "conversation":
        iconUrl = theme === Theme.DigitalPractice ? "/assets/images/icons/nav/conversation.svg" : "/assets/images/icons/nav/provet/conversation.svg";
        break;
      case "searchList":
        iconUrl = theme === Theme.DigitalPractice ? "/assets/images/icons/nav/search-list.svg" : "/assets/images/icons/nav/provet/search-list.svg";
        break;
      case "bullhorn":
        iconUrl = theme === Theme.DigitalPractice? "/assets/images/icons/nav/bullhorn.svg" : "/assets/images/icons/nav/provet/bullhorn.svg";
        break;
      case "payment":
        iconUrl = theme === Theme.DigitalPractice ? "/assets/images/icons/nav/creditcard.svg" : "/assets/images/icons/nav/provet/creditcard.svg";
        break;
      case "formSubmissions":
        iconUrl = theme === Theme.DigitalPractice ? "/assets/images/icons/icon-form-submissions.svg" : "/assets/images/icons/nav/provet/icon-form-submissions.svg";
        break;
      case "productRequest":
        iconUrl = theme === Theme.DigitalPractice ? "/assets/images/icons/nav/icon-medicine.svg" : "/assets/images/icons/nav/provet/icon-medicine.svg";
        break;
      case "image":
        iconUrl = theme === Theme.DigitalPractice ? "/assets/images/icons/nav/image.svg" : "/assets/images/icons/nav/provet/image.svg";
        break;
      case "chart":
        iconUrl = theme === Theme.DigitalPractice ? "/assets/images/icons/nav/chart.svg" : "/assets/images/icons/nav/provet/chart.svg";
        break;
      case "messagePenRegular":
        iconUrl = theme === Theme.DigitalPractice ? "/assets/images/icons/nav/message-pen-regular.svg" : "/assets/images/icons/nav/provet/message-pen-regular.svg";
        break;
      case "iconForm":
        iconUrl = theme === Theme.DigitalPractice ? "/assets/images/icons/icon-form.svg" : "/assets/images/icons/nav/provet/icon-form.svg";
        break;
      case "clinicSetting":
        iconUrl = theme === Theme.DigitalPractice ? "/assets/images/icons/nav/cog.svg" : "/assets/images/icons/nav/provet/cog.svg";
        break;
      case "searchIcon":
        iconUrl = theme === Theme.DigitalPractice ? "/assets/images/icons/search.svg" : "/assets/images/icons/provet/search.svg";
        break;
      case "contactDetails":
        iconUrl = theme === Theme.DigitalPractice ? "/assets/images/icons/nav/contact-details.svg" : "/assets/images/icons/nav/provet/contact-details.svg";
        break;
      case "helpIcon":
        iconUrl = theme === Theme.DigitalPractice ? "/assets/images/icons/nav/help.svg" : "/assets/images/icons/nav/provet/help.svg";
        break;
      case "checkMessageIcon":
        iconUrl = theme === Theme.DigitalPractice ? "/assets/images/icons/check-blue.svg" : "/assets/images/icons/provet/check-message-icon.svg";
        break;
      case "questionMarkIcon":
        iconUrl = theme === Theme.DigitalPractice ? "/assets/images/icons/question_mark.png" : "/assets/images/icons/provet/question_mark_lilac.svg";
        break;
      default:
        iconUrl = "/assets/images/icons/nav/cog.svg";
        break;
    }

    return iconUrl;
  }

  getSvgIconColor(theme:  string | undefined): string {
    switch (theme) {
      case Theme.DigitalPractice:
        return "#2F80ED";
      case Theme.Provet:
        return "#0C1A3D";
      default:
        return "#2F80ED";
    }
  }

  resetTheme() { 
    this.applyTheme(Theme.DigitalPractice);
  }

}
