import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FilterOption } from '../../../interfaces/filter-option.interface';

@Component({
  selector: 'filter-item-multiple',
  templateUrl: './filter-item-multiple.component.html',
  styleUrls: ['./filter-item-multiple.component.scss']
})
export class FilterItemMultipleComponent {
  @Input() label: string = 'Filter';
  @Input() icon: string = 'pi pi-check';
  @Input() options: FilterOption[] = [];
  @Input() selectedValues: FilterOption[] = [];
  @Output() optionSelected = new EventEmitter<FilterOption>();
  open = false;

  toggle(): void {
    this.open = !this.open;
  }

  update(option: FilterOption): void {
    this.optionSelected.emit(option);
  }

  close(): void {
    this.open = false;
  }
}