import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Media} from '../../../models/Media';
import {Practice} from '../../../models/Practice';
import {Store} from '@ngrx/store';
import {AppState} from '../../../state/reducers';
import { MenuItem } from 'primeng/api';
import {canDeleteMedia} from "../../../helpers/can-delete-media";
import { Role } from '../../../enums/role';
import { canRenameMedia } from '../../../helpers/can-rename-media';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'media-grid-item',
  templateUrl: './media-grid-item.component.html',
  styleUrls: ['./media-grid-item.component.scss']
})
export class MediaGridItemComponent implements OnInit {
  @Input() media?: Media;
  @Input() practice?: Practice;
  @Input() deletable = true;
  @Input() selector = false;
  @Input() manage = true;
  @Output() selected = new EventEmitter<Media>();
  @Output() move = new EventEmitter<Media>();
  @Output() rename = new EventEmitter<Media>();
  @Output() delete = new EventEmitter<Media>();
  systemAdmin = Role.SYSTEM_ADMIN;
  superAdmin = Role.SUPER_ADMIN;
  menuItems: MenuItem[] = [];

  constructor(private store: Store<AppState>, private translateService: TranslateService) { }

  ngOnInit(): void {
    this.translateService.get('media.media_grid_item').subscribe(translations => {
      this.menuItems = [...this.menuItems, {
        label: translations['move'],
        icon: 'pi pi-file-export',
        command: (event) => {
          this.handleMove();
        }
      }];

      if (this.practice && canRenameMedia(this.practice.userRole)) {
        this.menuItems = [...this.menuItems, {
          label: translations['rename'],
          icon: 'pi pi-pencil',
          command: (event) => {
            this.handleRename();
          }
        }];
      }

      if (this.practice && canDeleteMedia(this.practice)) {
        this.menuItems = [...this.menuItems, {
          label: translations['delete'],
          icon: 'pi pi-trash',
          command: (event) => {
            this.handleDelete();
          }
        }];
      }
    });
  }

  select(): void {
    if (this.selector && this.media) {
      this.selected.emit(this.media);
    }
  }

  handleMove(): void {
    if (this.media) {
      this.move.emit(this.media);
    }
  }

  handleDelete(): void {
    if (this.media) {
      this.delete.emit(this.media);
    }
  }

  handleRename(): void {
    if (this.media) {
      this.rename.emit(this.media);
    }
  }
}
