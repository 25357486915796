import { FilterOptionType } from '../enums/filter-option-type';
import { ProductRequestStatus } from '../enums/product-request-status';
import { prettifyProductRequestStatus } from '../helpers/prettify-product-request-status';
import { FilterOption } from '../interfaces/filter-option.interface';

export const productRequestStatusFilterOptions: FilterOption[] = [
  {
    id: ProductRequestStatus.AWAITING_APPROVAL,
    label: prettifyProductRequestStatus(ProductRequestStatus.AWAITING_APPROVAL),
    type: FilterOptionType.STANDARD
  },
  {
    id: ProductRequestStatus.READY_TO_PAY,
    label: prettifyProductRequestStatus(ProductRequestStatus.READY_TO_PAY),
    type: FilterOptionType.STANDARD
  },
  {
    id: ProductRequestStatus.AWAITING_PAYMENT,
    label: prettifyProductRequestStatus(ProductRequestStatus.AWAITING_PAYMENT),
    type: FilterOptionType.STANDARD
  },
  {
    id: ProductRequestStatus.PAID,
    label: prettifyProductRequestStatus(ProductRequestStatus.PAID),
    type: FilterOptionType.STANDARD
  },
  {
    id: ProductRequestStatus.FULFILLED,
    label: prettifyProductRequestStatus(ProductRequestStatus.FULFILLED),
    type: FilterOptionType.STANDARD
  },
  {
    id: ProductRequestStatus.COMPLETE,
    label: prettifyProductRequestStatus(ProductRequestStatus.COMPLETE),
    type: FilterOptionType.STANDARD
  },
  {
    id: ProductRequestStatus.CANCELLED,
    label: prettifyProductRequestStatus(ProductRequestStatus.CANCELLED),
    type: FilterOptionType.STANDARD
  },
  {
    id: ProductRequestStatus.REJECTED,
    label: prettifyProductRequestStatus(ProductRequestStatus.REJECTED),
    type: FilterOptionType.STANDARD
  }
];
