<div class="btn-wrap">
  <button
    pButton
    [label]="'ui.form_request.form_requests' | translate"
    class="p-button-fourth p-button-sm"
    (click)="showModal()"
  ></button>
</div>
<ng-container>
  <p-dialog
    [header]="'ui.form_request.form_requests' | translate"
    [(visible)]="modalActive"
    [modal]="true"
    [style]="{ width: '700px', 'min-height': '200px' }"
    [baseZIndex]="10000"
    [draggable]="false"
    [resizable]="false"
  >
    <div class="wrapper">
      <div class="top-section">
        <div class="row title">
          <form-submission-list [client]="client"></form-submission-list>
        </div>
      </div>
    </div>
  </p-dialog>
</ng-container>
