<ng-container *ngIf="useInTour">
  <p-button [disabled]="disabled" styleClass="p-button-secondary p-button-fullwidth p-button-smalltext" (click)="emitTogglePaymentRequest(true)" joyrideStep="step12" [stepContent]="step12Content" stepPosition="left" (prev)="handleStepToConversationAndCloseActionsPopup()" (next)="handleStepToCloseActionsPopup()">
    <ng-container *ngTemplateOutlet="buttonContents"></ng-container>
  </p-button>
</ng-container>
<ng-container *ngIf="!useInTour">
  <p-button [disabled]="disabled" styleClass="p-button-secondary p-button-fullwidth p-button-smalltext" (click)="emitTogglePaymentRequest(true)">
    <ng-container *ngTemplateOutlet="buttonContents"></ng-container>
  </p-button>
</ng-container>

<p-dialog
  [header]="'ui.payment_request.payment_request' | translate"
  (onHide)="emitTogglePaymentRequest(false)"
  [(visible)]="paymentRequestOpen"
  [modal]="true"
  [style]="{width: '800px'}"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
>
  <payment-request-form
    [balanceEnabled]="balanceEnabled"
    [paymentRequestLoading]="paymentRequestLoading"
    [paymentRequestFailed]="paymentRequestFailed"
    [client]="client"
    [patient]="patient"
    [disabled]="disabled"
    [outstanding]="outstanding"
    [channel]="channel"
    (paymentRequested)="handlePaymentRequested($event)"
  ></payment-request-form>
</p-dialog>

<ng-template #step12Content>
  <div class="tour-item">
    <div class="top lilac"><img src="/assets/images/icons/tour/pay.svg" height="111" alt="" class="icon"></div>
    <div class="content">
      <div class="title">{{'ui.payment_request.send_request' | translate}}</div>
      <div class="description">{{'ui.payment_request.description' | translate}}</div>
    </div>
  </div>
</ng-template>

<ng-template #buttonContents>
  <ng-container *ngIf="disabled">
    <img alt="logo" src="assets/images/icons/creditcard.svg" />
  </ng-container>
  <ng-container *ngIf="!disabled">
    <img alt="logo" src="assets/images/icons/creditcard-white.svg" />
  </ng-container>
  <span class="p-ml-1">{{'ui.payment_request.payment_request' | translate}}</span>
</ng-template>
