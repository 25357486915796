<div class="sort-by" [class.active]="open" (clickOutside)="open = false">
  <div class="toggle" (click)="toggleOpen()">
    <i class="pi pi-sort-alt"></i> {{ currentSortBy.label }} <dropdown-icon></dropdown-icon>
  </div>
  <div class="dropdown no-checkbox">
    <ng-container *ngFor="let option of options">
      <div class="dropdown-item" (click)="update(option)">
        <div class="label" [class.active]="currentSortBy === option">{{ option.label }}</div>
      </div>
    </ng-container>
  </div>
</div>