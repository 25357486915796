import { Pipe, PipeTransform } from '@angular/core';
import { getPaymentExpiryDate } from '../helpers/get-payment-expiry-date';
import { Payment } from '../models/Payment';

@Pipe({name: 'getPaymentExpiry'})
export class GetPaymentExpiryPipe implements PipeTransform {
  transform(payment: Payment): Date | null {
    return getPaymentExpiryDate(payment);
  }
}
