<div class="btn-row">
    <div class="col" [class.hidden]="!remindersEnabled">
        <reminders [patient]="patient" [disabled]="!remindersEnabled"></reminders>
    </div>
    <div class="col" [class.hidden]="!notesEnabled">
        <patient-notes [patient]="patient" [disabled]="!notesEnabled"></patient-notes>
    </div>
    <div class="col" [class.hidden]="!historyEnabled">
        <patient-history [patient]="patient" [patientHistory]="patientHistory" [loading]="patientHistoryLoading" (patientHistoryOpened)="handlePatientHistoryOpened($event)"  [disabled]="!patient"></patient-history>
    </div>
</div>
