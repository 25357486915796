import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FilterOption } from '../../../interfaces/filter-option.interface';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../state/reducers';
import { getCurrentPractice, getPracticeStaff } from '../../../practices/state/selectors';
import { GetFilterClients } from '../../../clients/state/actions';
import { getFilterClients } from '../../../clients/state/selectors';
import { Observable, Subscription, takeWhile } from 'rxjs';
import { FilterOptionType } from '../../../enums/filter-option-type';
import { GetPracticeStaff } from '../../../practices/state/actions';
import { User } from '../../../models/User';
import { getUser } from '../../../auth/state/selectors';

@Component({
  selector: 'filter-owner',
  templateUrl: './filter-owner.component.html',
  styleUrls: ['./filter-owner.component.scss']
})
export class FilterOwnerComponent implements OnInit, OnDestroy {
  @Input() preSelected: FilterOption[] = [];
  @Output() valueSelected = new EventEmitter<FilterOption[]>();
  authUser$?: Observable<User | null>;
  authUser?: User;
  authUserSub?: Subscription;
  selected: FilterOption[] = [];
  alive = true;
  open = false;
  options: FilterOption[] = [];

  constructor(private store: Store<AppState>) {
    this.store.pipe(select(getCurrentPractice)).subscribe(practice => {
      if (practice) {
        this.store.dispatch(GetPracticeStaff());
      }
    });
  

    this.store.pipe(select(getPracticeStaff)).pipe(takeWhile(() => this.alive)).subscribe(users => {
      this.options = [
        ...users
          .filter((u) => u.id === '0')
          .sort((a, b) => a.lastName.localeCompare(b.lastName))
          .map((u: any) => {
            return {
              id: u.id,
              label: u.fullName,
              type: FilterOptionType.USER,
              model: u,
            };
          }),
        ...users
          .filter((u) => u.id !== '0')
          .sort((a, b) => a.lastName.localeCompare(b.lastName))
          .map((u: any) => {
            return {
              id: u.id,
              label: u.fullName,
              type: FilterOptionType.USER,
              model: u,
            };
          }),
      ];

      this.selected = this.options.filter(option => this.preSelected.find(item => item.id === option.id));
    });
  }

  ngOnInit(): void {
    this.subscribeToAuthUser();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  subscribeToAuthUser(): void {
    this.authUser$ = this.store.pipe(select(getUser)).pipe(
      takeWhile(() => this.alive)
    );

    this.authUserSub = this.authUser$.subscribe(user => {
      if (user) {
        this.authUser = user;
      }
    });
  }

  handleSelected(option: FilterOption): void {
    if (this.selected.includes(option)) {
      this.selected = this.selected.filter(selected => selected.id !== option.id);
    } else {
      this.selected = [
        ...this.selected,
        option
      ];
    }

    this.valueSelected.emit(this.selected);
  }
}
