import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {ProductRequestLayout} from '../../../enums/product-request-layout';
import {AppState} from '../../../state/reducers';
import {Router} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { takeWhile } from 'rxjs';

@Component({
  selector: 'layout-switcher',
  templateUrl: './layout-switcher.component.html',
  styleUrls: ['./layout-switcher.component.scss']
})
export class LayoutSwitcherComponent implements OnInit, OnDestroy {
  alive = true;
  @Input() layout: ProductRequestLayout = ProductRequestLayout.KANBAN;
  layoutOptions: { value: ProductRequestLayout; label: string }[] = [];
  selectedLayout = ProductRequestLayout.KANBAN;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.translateService.get('enums.product_request_layout').pipe(takeWhile(_ => this.alive)).subscribe(layouts => {
      this.layoutOptions = [
        { value: ProductRequestLayout.LIST, label: layouts['list'] },
        { value: ProductRequestLayout.KANBAN, label: layouts['kanban'] }
      ];
    })
    this.selectedLayout = this.layout;
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  setLayout(layout: ProductRequestLayout): void {
    console.log(layout)
    if (this.layout !== layout) {
      switch (layout) {
        case ProductRequestLayout.KANBAN:
          this.router.navigateByUrl('product-requests/board');
          break;
        case ProductRequestLayout.LIST:
          this.router.navigateByUrl('product-requests/list');
          break;
      }
    }
  }
}
