<p [innerHTML]="'media.delete_folder.confirm_delete_message' | translate: {name: config.data.folder.name}"></p>
<p><span class="warning">{{'media.delete_folder.delete_warning' | translate }}</span></p>
<p>{{'media.delete_folder.enter_name' | translate }}</p>

<form class="delete-folder" [formGroup]="form">
  <div class="input-row">
    <div class="label">{{'media.delete_folder.name' | translate }}</div>
    <input #nameInput pInputText class="p-inputtext" type="text" formControlName="name" maxlength="50">
    <ng-container *ngIf="(form.controls.name.invalid && (form.controls.name.touched) && form.controls.name.errors?.['required'])">
      <div class="error">{{'media.delete_folder.enter_name_error' | translate }}</div>
    </ng-container>
    <ng-container *ngIf="config.data.folder.name !== form.controls.name.value && form.controls.name.touched">
      <div class="error">{{'media.delete_folder.name_mismatch' | translate }}</div>
    </ng-container>
  </div>
  <div class="btn-row">
    <p-button (click)="submit()" [label]="'media.delete_folder.delete_permanently' | translate" styleClass="p-button-primary"></p-button>
  </div>
</form>
