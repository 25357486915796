import {ConversationSortBy} from '../enums/ConversationSortBy';
import {SortDirection} from '../enums/sort-direction';
import {ConversationsFilterDto} from '../interfaces/dto/conversations-filter.dto';
import {subDays} from 'date-fns';
import { ConversationFilterStatus } from '../enums/conversation-status';

export const defaultConversationFilters: ConversationsFilterDto = {
    status: ConversationFilterStatus.ALL,
    owner: [],
    assignee: [],
    client: [],
    tag: [],
    date: null,
    sortBy: ConversationSortBy.LAST_RESPONSE_AT,
    sortDir: SortDirection.DESC,
    page: 1
};

