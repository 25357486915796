import * as actions from './actions';
import { Action, createReducer, on } from '@ngrx/store';
import { Client } from '../../models/Client';
import { Contact } from '../../models/Contact';
import { Channel } from '../../enums/channel';

export interface DialogState {
  newConversationOpen: boolean;
  newConversationPrefilledClient: Client | null;
  newConversationPrefilledContact: Contact | null;
  newConversationPrefilledChannel: Channel | null;
}

export const initialState: DialogState = {
  newConversationOpen: false,
  newConversationPrefilledClient: null,
  newConversationPrefilledContact: null,
  newConversationPrefilledChannel: null,
};

const dialogReducer = createReducer(
  initialState,  
  on(actions.OpenNewConversation, (state, props) => ({
    ...state,
    newConversationOpen: true,
    newConversationPrefilledClient: props.client || null,
    newConversationPrefilledContact: props.contact || null,
    newConversationPrefilledChannel: props.channel || null,
  })),
  on(actions.CloseNewConversation, (state) => ({
    ...state,
    newConversationOpen: false,
    newConversationPrefilledClient: null,
    newConversationPrefilledContact: null,
    newConversationPrefilledChannel: null,
  })),
);

export function reducer(state: DialogState | undefined, action: Action): DialogState {
  return dialogReducer(state, action);
}
