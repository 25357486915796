<form class="move-media" [formGroup]="form">
  <p>This will move <strong>{{ this.config.data.media.name }}</strong> into the following folder:</p>
  <div class="input-row">
    <select class="primary" formControlName="destinationFolder" name="" id="">
      <option value="">Please select...</option>
      <option value="0" [disabled]="this.config.data.media.folderId === null">Root directory</option>
      <ng-container *ngFor="let folder of (folders$ | ngrxPush)">
        <option value="{{ folder.id }}" [disabled]="folder.id === this.config.data.media.folderId">{{ folder.name }}</option>
      </ng-container>
    </select>
    <ng-container *ngIf="form.controls.destinationFolder.invalid && (form.controls.destinationFolder.dirty || form.controls.destinationFolder.touched) && form.controls.destinationFolder.errors?.['required']">
      <div class="error">You must select a destination folder</div>
    </ng-container>
  </div>
  <div class="btn-row">
    <p-button (click)="submit()" label="Move" styleClass="p-button-primary"></p-button>
  </div>
</form>
