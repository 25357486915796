import { MediaBrowser } from "../interfaces/media-browser.interface";

export const defaultMediaBrowser: MediaBrowser = {
    media: [],
    totalMediaCount: 0,
    mediaLoading: true,
    currentFolder: null,
    folders: [],
    foldersLoading: true,
    folderBreadcrumbs: [],
    folderToMove: null,
    mediaToMove: null,
    filters: {
        types: []
    },
    currentPage: 1
}