import { Pipe, PipeTransform } from '@angular/core';
import {MessageType} from '../enums/message-type';
import {prettifyMessageType} from '../helpers/prettify-message-type';

@Pipe({name: 'prettifyMessageType'})
export class PrettifyMessageTypePipe implements PipeTransform {
  transform(value: MessageType): string {
    return prettifyMessageType(value);
  }
}
