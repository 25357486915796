<ng-container *ngIf="patient">
    <div class="client-details">
        <div class="top-row">
            <div class="name">
                <img src="assets/images/icons/icon-paw.svg" class="icon" />
                <div class="text">{{ patient.name }}</div>
            </div>
            <div class="species-row">
                <div class="species">{{ patient.species }}</div>
                <ng-container *ngIf="!changeDisabled">
                    <button
                        [disabled]="disabled"
                        pButton
                        [label]="'patients.patient_details.change_patient' | translate"
                        (click)="changePatient()"
                        class="p-button-fourth p-button-sm change-patient-btn"
                    ></button>
                </ng-container>
            </div>
        </div>
        <div class="date-row">
            <div class="date">
                <span class="label">{{'patients.patient_details.last_seen' | translate}}</span>
                <ng-container *ngIf="patient.lastSeenAt">
                    <span
                        >{{ patient.lastSeenAt | dfnsFormat:'dd/MM/yyyy'
                        }}</span
                    >
                </ng-container>
                <ng-container *ngIf="!patient.lastSeenAt">
                    <span>{{'patients.patient_details.n_a' | translate}}</span>
                </ng-container>
            </div>
        </div>
        <div class="details-row">
            <ng-container *ngIf="!patient.incomplete">
                <div class="detail">
                    <div class="label">{{'patients.patient_details.age' | translate}}</div>
                    <div class="value">{{ patient.age || '-' }}</div>
                </div>
                <ng-container *ngIf="weightEnabled">
                    <div class="detail">
                        <div class="label">{{'patients.patient_details.weight' | translate}}</div>
                        <div class="value">{{ patient.weight || '-' }}</div>
                    </div>
                </ng-container>
                <div class="detail">
                    <div class="label">{{'patients.patient_details.sex' | translate}}</div>
                    <div class="value">{{ patient.sex || '-' }}</div>
                </div>
                <div class="detail">
                    <div class="label">{{'patients.patient_details.breed' | translate}}</div>
                    <div class="value">{{ patient.breed || '-' }}</div>
                </div>
                <ng-container *ngIf="insuranceEnabled">
                    <div class="detail">
                        <div class="label">{{'patients.patient_details.insurance' | translate}}</div>
                        <div class="value">
                            {{ patient.insurance || ('patients.patient_details.care_plan' | translate) }}
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="careplansEnabled">
                    <div class="detail">
                        <div class="label">{{'patients.patient_details.care_plan' | translate}}</div>
                        <div class="value">
                            <ng-container
                                *ngIf="!patient.careplan || (isArray(patient.careplan) && patient.careplan.length === 0)"
                            >
                                -
                            </ng-container>
                            <ng-container
                                *ngIf="patient.careplan && isArray(patient.careplan) && patient.careplan.length > 1"
                            >
                                <patient-careplans
                                    [careplans]="getCareplans()"
                                ></patient-careplans>
                            </ng-container>
                            <ng-container
                                *ngIf="patient.careplan && (!isArray(patient.careplan) || (isArray(patient.careplan) && patient.careplan.length === 1))"
                            >
                                {{ patient.careplan }}
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="patient.incomplete">
                <div class="error">
                  {{'patients.patient_details.error' | translate}}
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>
