import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../../state/reducers';
import { FilterConfig } from '../../../interfaces/filter-config.interface';
import { FilterType } from '../../../enums/filter-type';
import { FilterOption } from '../../../interfaces/filter-option.interface';
import { FilterSelection } from '../../../interfaces/filter-selection.interface';
import { emptyFilters } from '../../../constants/empty-filters.constants';

@Component({
  selector: 'filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit, OnDestroy {
  @Input() config: FilterConfig = {
    filters: []
  };
  @Input() selectedValues: FilterSelection = emptyFilters;
  @Output() filterSelected = new EventEmitter<{type: FilterType, values: FilterOption[]}>();
  @Output() filtersCleared = new EventEmitter();
  alive = true;
  device = 'desktop';
  mobileFiltersOpen = false;
  modalBackground = false;
  filterType = FilterType;
  hideFilters = false;

  constructor(private route: ActivatedRoute, private store: Store<AppState>) {}

  ngOnInit(): void {
    this.device = this.getDevice();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  toggleMobileFilters(): void {
    this.mobileFiltersOpen = !this.mobileFiltersOpen;
  }

  getDevice(): string {
    if (window.innerWidth <= 420) {
      return 'small-mobile';
    }

    return 'desktop';
  }

  handleFilterChange(type: FilterType, values: FilterOption[]): void {
    this.filterSelected.emit({type, values});
  }

  clearFilters(): void {
    this.filtersCleared.emit();

    // force filters to re-render
    this.hideFilters = true;

    setTimeout(() => {
      this.hideFilters = false;
    }, 0);
  }
}
