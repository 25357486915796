import {Component, OnDestroy, OnInit} from '@angular/core';
import {MarkHelpPostsAsSeen} from '../../../auth/state/actions';
import {select, Store} from '@ngrx/store';
import {getUnreadHelpPosts} from '../../../auth/state/selectors';
import {takeWhile} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {HelpPost} from '../../../interfaces/help-post.interface';
import {AppState} from '../../../state/reducers';

@Component({
  selector: 'whats-new-dialog',
  templateUrl: './whats-new-dialog.component.html',
  styleUrls: ['./whats-new-dialog.component.scss']
})
export class WhatsNewDialogComponent implements OnInit, OnDestroy {
  alive = true;
  modalOpen = true;
  unreadHelpPosts$?: Observable<HelpPost[]>;
  unreadHelpPosts: HelpPost[] = [];

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
    this.subscribeToUnreadHelpPosts();
  }

  ngOnDestroy(): void{
    this.alive = false;
  }

  closeNewFeatures(): void {
    this.modalOpen = false;
    this.store.dispatch(MarkHelpPostsAsSeen());
  }

  subscribeToUnreadHelpPosts(): void {
    this.unreadHelpPosts$ = this.store.pipe(select(getUnreadHelpPosts)).pipe(
      takeWhile(() => this.alive)
    );

    this.unreadHelpPosts$.subscribe((posts) => {
      this.unreadHelpPosts = posts;
    });
  }
}
