import { Pipe, PipeTransform } from '@angular/core';
import {Practice} from '../models/Practice';
import {Role} from '../enums/role';
import {practiceHasFeature} from '../helpers/practice-has-feature';
import {PracticeFeature} from '../enums/practice-feature';

@Pipe({name: 'canViewMedia'})
export class CanViewMediaPipe implements PipeTransform {
  transform(practice: Practice, userRole: Role): boolean {
    return userRole !== Role.ANALYST;
  }
}
