import { Injectable } from '@angular/core';
import { mapProductRequestStatus } from '../helpers/map-product-request-status';
import { ProductRequestDto } from '../interfaces/dto/product-request.dto';
import { ProductRequest } from '../models/ProductRequest';
import { UserAdapter } from './user.adapter';
import {ClientAdapter} from './client.adapter';
import {PatientAdapter} from './patient.adapter';
import {ProductRequestItemAdapter} from './product-request-item.adapter';
import {isUserDto} from '../helpers/is-user-dto';
import {GroupAdapter} from './group.adapter';

@Injectable({
  providedIn: 'root'
})
export class ProductRequestAdapter {
  constructor(private userAdapter: UserAdapter, private groupAdapter: GroupAdapter, private clientAdapter: ClientAdapter, private patientAdapter: PatientAdapter, private productRequestItemAdapter: ProductRequestItemAdapter) {
  }

  run(dto: ProductRequestDto): ProductRequest {
    let assignee;
    if (dto.assignee) {
      if (isUserDto(dto.assignee)) {
        assignee = this.userAdapter.run(dto.assignee);
      } else {
        assignee = this.groupAdapter.run(dto.assignee);
      }
    }

    return {
      ...dto,
      owner: dto.owner ? this.userAdapter.run(dto.owner) : null,
      assignee: dto.assignee ? this.userAdapter.run(dto.assignee) : null,
      client: this.clientAdapter.run(dto.client),
      patient: this.patientAdapter.run(dto.patient),
      recipient: this.userAdapter.run(dto.recipient),
      status: mapProductRequestStatus(dto.status),
      items: dto.items.map(item => this.productRequestItemAdapter.run(item)).sort((a, b) => a.id - b.id),
      cancelledAt: dto.cancelledAt ? new Date(dto.cancelledAt) : null,
      createdAt: new Date(dto.createdAt),
      updatedAt: new Date(dto.updatedAt),
    };
  }
}
