import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { FilterOption } from '../../../interfaces/filter-option.interface';
import { FilterOptionType } from '../../../enums/filter-option-type';
import {User} from "../../../models/User";

@Component({
  selector: 'filter-item-typeahead',
  templateUrl: './filter-item-typeahead.component.html',
  styleUrls: ['./filter-item-typeahead.component.scss']
})
export class FilterItemTypeaheadComponent implements OnInit {
  open = false;
  @Output() filterUpdated = new EventEmitter<string>();
  @Input() label = 'Filter';
  @Input() icon = 'pi pi-check';
  @Input() searchLabel = 'Find items';
  @Input() options: FilterOption[] = [];
  @Input() authUser?: User | null;
  @Input() selectedValues: FilterOption[] = [];
  @Output() optionSelected = new EventEmitter<FilterOption>();
  filterOptionType = FilterOptionType;
  filterText = '';
  filterTimeout: any;

  ngOnInit(): void {
  }

  toggle(): void {
    this.open = !this.open;
  }

  update(option: FilterOption): void {
    this.optionSelected.emit(option);
  }

  close(): void {
    this.open = false;
  }

  handleFilterChange(): void {
    clearTimeout(this.filterTimeout);
    this.filterTimeout = setTimeout(() => {
      this.filterUpdated.emit(this.filterText);
    }, 250);
  }
}
