import { Pipe, PipeTransform } from "@angular/core";

export enum CashUpType {
  TODAY = 'today',
  SINCE_LAST = 'sinceLast',
  CUSTOM = 'custom',
}

const enumToTranslation: Record<CashUpType, string> = {
  [CashUpType.CUSTOM]: 'enums.cash_up_type.custom',
  [CashUpType.SINCE_LAST]: 'enums.cash_up_type.since_last',
  [CashUpType.TODAY]: 'enums.cash_up_type.today'
}

@Pipe({name: 'CashUpTypeTranslationKey'})
export class CashUpTypeTranslationKeyPipe implements PipeTransform {
  transform(value: CashUpType): string {
    return enumToTranslation[value];
  }
}
