import {Component, EventEmitter, OnDestroy, OnInit, Output, Input} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {getActiveForms, getForms} from '../../../forms/state/selectors';
import {takeWhile} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Form} from '../../../forms/interfaces/form';
import {AppState} from '../../../state/reducers';
import {Conversation} from "../../../models/Conversation";

@Component({
  selector: 'form-selector',
  templateUrl: './form-selector.component.html',
  styleUrls: ['./form-selector.component.scss']
})
export class FormSelectorComponent implements OnInit, OnDestroy {
  @Output() formSelected = new EventEmitter<Form>();
  alive = true;
  loading = true;
  forms: Form[] = [];
  @Input() isRegistered: any;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.subscribeToForms();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  subscribeToForms(): void {
    this.store.pipe(select(getActiveForms)).pipe(
      takeWhile(() => this.alive)
    ).subscribe((forms) => {
      this.loading = false;
      this.forms = forms;
    });
  }

  selectForm(form: Form): void {
    this.formSelected.emit(form);
  }
}
