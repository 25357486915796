import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree, Router, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {TokenService} from '../services/token.service';
import {UserService} from '../users/user.service';
import {CookieService} from 'ngx-cookie-service';
import {isMessagingEnabled} from '../helpers/is-messaging-enabled';
import {UpdatePaymentFilters} from "../payments/state/actions";
import {defaultPaymentFilters} from "../constants/default-payment-filters.constants";
import {PaymentType} from "../enums/payment-type";
import {Store} from "@ngrx/store";
import {AppState} from "../state/reducers";

@Injectable({
  providedIn: 'root'
})
export class PracticeHasMessagingGuard  {
  constructor(private userService: UserService, private cookieService: CookieService, private router: Router, private store: Store<AppState>,) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.userService.getUserPractices().pipe(
      map((response) => {
        let practice;

        const cookiePractice = this.cookieService.get('messaging-selected-practice');

        if (cookiePractice) {
          practice = response.find(actionPractice => actionPractice.id === cookiePractice);
        }

        if (!practice && response.length > 0) {
          practice = response[0];
        }

        if (isMessagingEnabled(practice)) {
          return true;
        }

        this.store.dispatch(UpdatePaymentFilters({
          filters: {
            ...defaultPaymentFilters,
          }
        }));

        return false;
      })
    );
  }
}
