import { createAction, props } from '@ngrx/store';
import { Patient } from 'src/app/models/Patient';

export const GetPatientsSuccess = createAction(
    '[Patients] Get Patients Success',
    props<{patients: Patient[], replace: boolean}>()
);

export const ClearPatients = createAction(
    '[Patients] Clear Patients'
);

export const IncrementPatientPage = createAction(
    '[Patients] Change Patient Page'
);

export const ResetPatientPage = createAction(
    '[Patients] Reset Patient Page'
);


