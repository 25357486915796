import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SearchType} from '../../../enums/search-type';
import {UpdateConversationSearchFilters, UpdatePaymentSearchFilters,} from '../../../search/state/actions';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../state/reducers';
import {ActivatedRoute, Router} from '@angular/router';
import {defaultConversationFilters} from '../../../constants/default-conversation-filters.constants';
import {defaultPaymentFilters} from '../../../constants/default-payment-filters.constants';
import {Practice} from '../../../models/Practice';
import {takeWhile} from 'rxjs';
import {getCurrentPractice} from '../../../practices/state/selectors';
import {phoneValidator} from '../../../helpers/phone-validator';
import {practiceHasFeature} from '../../../helpers/practice-has-feature';
import {PracticeFeature} from '../../../enums/practice-feature';
import {isMessagingEnabled} from '../../../helpers/is-messaging-enabled';
import { Theme } from '../../../enums/theme';
@Component({
  selector: 'search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit, OnDestroy {
  @ViewChild('searchInput') searchInput?: ElementRef;
  @Input() inputWidth = '';
  alive = true;
  searchString = '';
  searchType = SearchType;
  searchFocused = false;
  practice: Practice | null = null;
  paymentsEnabled = false;
  productRequestsEnabled = false;
  currentSearchType = SearchType.CONVERSATIONS;
  messagingEnabled = false;
  formsEnabled = false;
  paymentSearchToggle = false;
  theme: string = Theme.DigitalPractice;
  constructor(private store: Store<AppState>, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.subscribeToCurrentPractice();
    this.subscribeToCurrentSearch();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  updateFeatures(): void {
    this.paymentsEnabled = practiceHasFeature(this.practice, PracticeFeature.PAYMENTS);
    this.productRequestsEnabled = practiceHasFeature(this.practice, PracticeFeature.PRODUCT_REQUESTS);
    this.paymentSearchToggle = (practiceHasFeature(this.practice, PracticeFeature.PAYMENT_SEARCH));
  }

  updateMessagingEnabled(): void {
    if (this.practice) {
      this.messagingEnabled = this.practice.messagingEnabled;
    }
  }

  subscribeToCurrentSearch(): void {
    this.route.queryParams
      .subscribe(params => {
        if (params.s) {
          this.searchString = params.s;
        }

        if (params.searchType) {
          this.currentSearchType = params.searchType;
        }
      }
    );
  }

  subscribeToCurrentPractice(): void {
    this.store.pipe(select(getCurrentPractice)).pipe(takeWhile(() => this.alive)).subscribe(practice => {
      if (practice) {
        this.practice = practice;
        if(practice){
          this.theme = practice.theme ? practice.theme : Theme.DigitalPractice;
        }
        this.updateFeatures();
        this.updateMessagingEnabled();
        this.updateFormsEnabled();

        if (!isMessagingEnabled(this.practice) && this.currentSearchType === SearchType.CONVERSATIONS) {
          this.currentSearchType = SearchType.CLIENTS;
        }
      }
    });
  }

  doSearch(type: SearchType): void {
    if (this.searchInput) {
      this.searchInput.nativeElement.blur();
      this.searchFocused = false;
    }

    this.currentSearchType = type;

    if (this.searchString.length > 0) {
      const country = this.practice ? this.practice.country : 'GBR';

      const searchString = phoneValidator(this.searchString, country);

      switch (type) {
        case SearchType.CONVERSATIONS:
          this.store.dispatch(UpdateConversationSearchFilters({
            searchString,
            filters: defaultConversationFilters
          }));
          break;

        case SearchType.PAYMENTS:
          this.store.dispatch(UpdatePaymentSearchFilters({
            searchString,
            filters: defaultPaymentFilters
          }));
          break;

        case SearchType.PRODUCT_REQUESTS:
          const url = `search?s=${encodeURIComponent(searchString)}&searchType=${SearchType.PRODUCT_REQUESTS}`;
          this.router.navigateByUrl(url);
          break;

        case SearchType.CLIENTS:
          const clientSearchUrl = `search?s=${encodeURIComponent(searchString)}&searchType=${SearchType.CLIENTS}`;
          this.router.navigateByUrl(clientSearchUrl);
          break;

        case SearchType.FORM_SUBMISSIONS:
          const formSearchUrl = `search?s=${encodeURIComponent(searchString)}&searchType=${SearchType.FORM_SUBMISSIONS}`;
          this.router.navigateByUrl(formSearchUrl);
          break;
      }
    }
  }

  clearSearch(): void {
    this.searchString = '';
  }

  void(): void {
    return;
  }

  updateFormsEnabled(): void {
    this.formsEnabled = false;

    if (
      this.practice && practiceHasFeature(this.practice, PracticeFeature.FORMS)
    ) {
      this.formsEnabled = true;
    }
  }
}
