import { Injectable } from '@angular/core';
import { PatientDto } from '../interfaces/dto/patient.dto';
import { Patient } from '../models/Patient';
import { ReminderAdapter } from './reminder.adapter';

@Injectable({
  providedIn: 'root'
})
export class PatientAdapter {
  constructor(private reminderAdapter: ReminderAdapter) {}
  run(dto: PatientDto): Patient {
    return {
        ...dto,
        careplan: dto.carePlan,
        reminders: dto.reminders ?
          dto.reminders.map(reminder => this.reminderAdapter.run(reminder)).sort((a, b) => {
              if (!a.dueDate) {
                return -1;
              }

              if (!b.dueDate) {
                return 1;
              }

              return a.dueDate.getTime() - b.dueDate.getTime();
          }) : [],
        lastSeenAt: dto.lastSeenAt ? new Date(dto.lastSeenAt) : null
    };
  }
}
