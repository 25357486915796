import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { Comment } from 'src/app/models/Comment';
import { User } from 'src/app/models/User';
import { Group } from '../../../models/Group';

@Component({
  selector: 'comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent implements OnInit, OnDestroy {
  @Input() comment?: Comment;
  @Input() users?: User[];
  @Input() groups?: Group[];
  refresh = new Date();
  alive = true;

  constructor() { }

  ngOnInit(): void {
    interval(60000).pipe(takeWhile(() => this.alive)).subscribe(() => {
      this.refresh = new Date();
    });
  }

  ngOnDestroy(): void {
    this.alive = false;
  }
}
