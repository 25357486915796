<ng-container *ngIf="productRequest">
  <div class="product-request-item" (click)="openDetail()">
    <ng-container *ngIf="updating">
      <div class="updating-overlay">
        <p-progressSpinner [style]="{width: '32px', height: '32px'}" strokeWidth="6"></p-progressSpinner>
      </div>
    </ng-container>
    <div class="created-date">
      {{ productRequest.createdAt | diffForHumans:refresh }}
    </div>
    <div class="client-patient-details">
      <div class="client">
        <ng-container *ngIf="productRequest.client">
          {{ productRequest.client.fullName }}
        </ng-container>
        <ng-container *ngIf="!productRequest.client">
          Not Set
        </ng-container>
      </div>
      <div class="patient">
        <ng-container *ngIf="productRequest.patient">
          {{ productRequest.patient.name }}
          <ng-container *ngIf="productRequest.patient.breed">
            - {{ productRequest.patient.breed }}
          </ng-container>
          <ng-container *ngIf="productRequest.patient.species">
            ({{ productRequest.patient.species }})
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!productRequest.patient">
          Not Set
        </ng-container>
      </div>
    </div>
    <div class="product-name">
      <ng-container *ngIf="productRequest.items.length > 0">
        <div class="name">
          {{ productRequest.items[0].requestedQty }}x {{ productRequest.items[0].approvedItem ?? productRequest.items[0].description }}
        </div>
      </ng-container>
      <ng-container *ngIf="productRequest.items.length == 2">
        <div class="additional-items">
          + 1 item
        </div>
      </ng-container>
      <ng-container *ngIf="productRequest.items.length > 2">
        <div class="additional-items">
          + {{ productRequest.items.length-1 }} items
        </div>
      </ng-container>
    </div>
    <div class="approval-status">
      <product-request-approval-status [productRequest]="productRequest"></product-request-approval-status>
    </div>
    <div class="owner">
      <div class="label">Owner</div>
        <user-avatar [user]="productRequest.owner" unsetInitials="-"></user-avatar>
    </div>
    <div class="assignee">
      <div class="label">Assignee</div>
      <user-avatar [user]="productRequest.assignee" unsetInitials="-"></user-avatar>
    </div>
    <div class="status">
      <div class="row"><product-request-status [productRequest]="productRequest"></product-request-status></div>
    </div>
  </div>
</ng-container>
