import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { PaymentRequest } from 'src/app/interfaces/payment-request';
import {Channel} from '../../../enums/channel';
import {Client} from '../../../models/Client';
import {Patient} from '../../../models/Patient';
import {OpenProductRequest} from '../../../product-requests/state/actions';
import {Store} from '@ngrx/store';
import {AppState} from '../../../state/reducers';
import {Contact} from '../../../models/Contact';

@Component({
  selector: 'conversation-actions',
  templateUrl: './conversation-actions.component.html',
  styleUrls: ['./conversation-actions.component.scss']
})
export class ConversationActionsComponent implements OnInit {
  @Output() paymentRequest = new EventEmitter<PaymentRequest>();
  @Output() togglePaymentRequest = new EventEmitter<boolean>();
  @Output() stepToConversation = new EventEmitter();
  @Output() stepToCloseActionsPopup = new EventEmitter();
  @Input() paymentRequestLoading = false;
  @Input() paymentRequestOpen = false;
  @Input() paymentRequestFailed = false;
  @Input() paymentRequestDisabled = false;
  @Input() paymentsFeatureEnabled = false;
  @Input() productRequestFeatureEnabled = false;
  @Input() balanceEnabled = false;
  @Input() outstanding = '0';
  @Input() channel: Channel = Channel.WHATSAPP;
  @Input() client?: Client;
  @Input() patient?: Patient;
  @Input() contact?: Contact;

  constructor(
    private store: Store<AppState>,
  ) {}

  ngOnInit(): void {
  }

  handlePaymentRequest(payment: PaymentRequest): void {
    this.paymentRequest.emit(payment);
  }

  handleTogglePaymentRequest(open: boolean): void {
    this.togglePaymentRequest.emit(open);
  }

  handleStepToConversation(): void {
    this.stepToConversation.emit();
  }

  handleStepToCloseActionsPopup(): void {
    this.stepToCloseActionsPopup.emit();
  }

  startProductRequest(): void {
    this.store.dispatch(OpenProductRequest({client: this.client, contact: this.contact, patient: this.patient, channel: this.channel}));
  }
}
