import { WhatsappTemplateCategory } from "../enums/whatsapp-template-category";

export function prettifyTemplateCategory(category: WhatsappTemplateCategory): string {
  switch (category) {
    case WhatsappTemplateCategory.MARKETING:
      return 'Marketing';
    case WhatsappTemplateCategory.UTILITY:
      return 'Utility';
    default:
      return 'N/A';
  }
}
