<ng-container *ngIf="modalOnMobile">
    <div class="wrap" (click)="handleWrapClicked($event)">
        <ng-container *ngTemplateOutlet="listbox"></ng-container>
    </div>
</ng-container>
<ng-container *ngIf="!modalOnMobile">
    <ng-container *ngTemplateOutlet="listbox"></ng-container>
</ng-container>

<ng-template #listbox>
    <p-listbox #listbox [ngModel]="selected" (ngModelChange)="handleUserChange($event)" (onClick)="handleUserClick($event)" [options]="options" [filter]="filter" optionLabel="name" [listStyle]="{'max-height':'215px'}" styleClass="mention-user-search">
        <ng-template let-item pTemplate="item">
            <div class="user-item">
                <user-avatar [user]="item" size="small"></user-avatar>
                <div class="label">
                    {{ item.name === 'Unassigned' ? ('ui.user_changer_list.unassigned' | translate) : item.name}}
                    <ng-container *ngIf="authUser && authUser.id === item.id">
                        <span class="me">{{'ui.user_changer_list.me' | translate}}</span>
                    </ng-container>
                </div>
            </div>
        </ng-template>
    </p-listbox>
</ng-template>
