<ng-container *ngIf="error$ | async">
    <div class="error">
        <div class="message">{{ (error$ | async)!.errorMessage }}</div>
        <ng-container *ngIf="(error$ | async)!.existingConversation">
            <div class="btn-row">
                <a
                    pButton
                    (click)="goToClientConversation()"
                    [label]="'ui.new_conversation.go_to_conversation' | translate"
                    class="p-button-first"
                ></a>
            </div>
        </ng-container>
    </div>
</ng-container>
<ng-container *ngIf="loading && !(error$ | async)">
    <p-progressSpinner
        [style]="{
            width: '40px',
            height: '40px',
            margin: '40px auto',
            display: 'block',
        }"
        styleClass="custom-spinner"
        strokeWidth="4"
    ></p-progressSpinner>
</ng-container>
<ng-container *ngIf="!loading && !(error$ | async)">
    <ng-container *ngIf="step === 0">
        <div class="subtitle">{{'ui.new_conversation.search_client' | translate}}</div>
        <client-selector
            [noModal]="true"
            (clientSelected)="selectClient($event)"
        ></client-selector>
        <ng-container *ngIf="messagePhoneDirectly">
            <div class="subtitle">{{'ui.new_conversation.enter_phone' | translate}}</div>
            <div class="phone-input">
                <input
                    pInputText
                    (paste)="validatePhoneInput()"
                    (keydown)="validateKeyPress($event)"
                    (keyup)="
                        $event.key == 'Enter'
                            ? usePhoneNumber()
                            : validatePhoneInput()
                    "
                    maxlength="20"
                    type="text"
                    [(ngModel)]="phoneNumber"
                    [placeholder]="'ui.new_conversation.phone_number' | translate"
                />
                <button
                    pButton
                    [label]="'ui.new_conversation.next' | translate"
                    [disabled]="!phoneNumberIsValid"
                    (click)="usePhoneNumber()"
                ></button>
            </div>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="step === 1">
        <div class="subtitle">{{'ui.new_conversation.select_contact' | translate}}</div>
        <ng-container *ngIf="selectedClient">
            <div class="contact-selector-wrap">
                <div class="client-details">
                    <div class="name">
                        {{ selectedClient.title }}
                        {{ selectedClient.firstName }}
                        {{ selectedClient.lastName }} ({{
                            selectedClient.pmsDisplayId ?? selectedClient.pmsId
                        }})
                    </div>
                    <div class="address">
                        {{
                            selectedClient.address1
                                ? selectedClient.address1 + ","
                                : ""
                        }}
                        {{
                            selectedClient.address2
                                ? selectedClient.address2 + ","
                                : ""
                        }}
                        {{
                            selectedClient.address3
                                ? selectedClient.address3 + ","
                                : ""
                        }}
                        {{
                            selectedClient.postcode
                                ? selectedClient.postcode + ","
                                : ""
                        }}
                        {{ selectedClient.state }}
                    </div>
                </div>
                <ng-container
                    *ngFor="let contact of this.selectedClient.mobileContacts"
                >
                    <div class="contact-selector">
                        <div class="label">
                            {{ contact.name }}: {{ contact.value }}
                        </div>
                        <button
                            pButton
                            [label]="'ui.new_conversation.select' | translate"
                            class="p-button-sm p-button-ghost"
                            (click)="selectContact(contact)"
                        ></button>
                    </div>
                </ng-container>
            </div>
        </ng-container>
        <div class="next-step">
            <ng-container *ngIf="minStep < 1">
                <div class="back" (click)="handleBack(1)">
                    <img src="assets/images/icons/back.svg" alt="" />
                </div>
            </ng-container>
        </div>
    </ng-container>

    <ng-container *ngIf="step === 2">
        <div class="step-2">
            <div class="subtitle">{{'ui.new_conversation.select_channel' | translate}}</div>

            <p-dropdown
                [options]="channels"
                [ngModel]="selectedChannel"
                (ngModelChange)="onChannelChange($event)"
                optionLabel="name"
                optionDisabled="inactive"
                class="channel-dropdown"
            >
                <ng-template pTemplate="selectedItem">
                    <div
                        class="channel-item {{
                            selectedChannel.className
                        }} channel-item-selected"
                        [class.facebook-linked]="facebookLinked"
                        [class.instagram-linked]="instagramLinked"
                        *ngIf="selectedChannel"
                    >
                        <img
                            [src]="
                                'assets/images/icons/' + selectedChannel.icon
                            "
                            class="channel-icon"
                        />
                        <div>{{ selectedChannel.name }}</div>
                    </div>
                </ng-template>
                <ng-template let-channel pTemplate="item">
                    <div
                        class="channel-item {{ channel.className }}"
                        [class.facebook-linked]="facebookLinked"
                        [class.instagram-linked]="instagramLinked"
                    >
                        <img
                            [src]="'assets/images/icons/' + channel.icon"
                            class="channel-icon"
                        />
                        <div>{{ channel.name }}</div>
                    </div>
                </ng-template>
            </p-dropdown>

            <ng-container
                *ngIf="
                    !facebookLinked && selectedChannel.code === channel.FACEBOOK
                "
            >
                <div class="no-facebook-link">
                   {{'ui.new_conversation.no_facebook_link' | translate}}
                </div>
            </ng-container>

            <ng-container
                *ngIf="
                    !instagramLinked &&
                    selectedChannel.code === channel.INSTAGRAM
                "
            >
                <div class="no-instagram-link">
                  {{'ui.new_conversation.no_instagram_link' | translate}}
                </div>
            </ng-container>
        </div>
        <div class="next-step">
            <div>
                <ng-container *ngIf="minStep < 2">
                    <div class="back" (click)="handleBack(2)">
                        <img src="assets/images/icons/back.svg" alt="" />
                    </div>
                </ng-container>
            </div>
            <button
                pButton
                (click)="goToStep(3)"
                [label]="'ui.new_conversation.next_step' | translate"
                class="p-button-first"
            ></button>
        </div>
    </ng-container>
    <ng-container *ngIf="step === 3 && showPatientSelector">
        <div class="subtitle">{{'ui.new_conversation.select_patient' | translate}}</div>
        <div class="patient-select">
            <ng-container *ngIf="patients.length > 0 || !patientsLoading">
                <patient-selector
                    [disabled]="false"
                    [loading]="patientsLoading"
                    [patients]="patients"
                    (patientSelected)="selectPatient($event)"
                    (morePatients)="handleMorePatients()"
                ></patient-selector>
            </ng-container>
            <ng-container *ngIf="patientsLoading">
                <div class="patients-loading">
                    <p-progressSpinner
                        [style]="{ width: '24px', height: '24px' }"
                        styleClass="custom-spinner"
                        strokeWidth="4"
                    ></p-progressSpinner>
                </div>
            </ng-container>
        </div>
        <div class="next-step">
            <div>
                    <div class="back" (click)="handleBack(4)">
                        <img src="assets/images/icons/back.svg" alt="" />
                    </div>

            </div>
            <button
                pButton
                (click)="goToStep(5)"
                label="Next Step"
                class="p-button-first"
            ></button>
        </div>
    </ng-container>

    <ng-container *ngIf="step === 4">
        <div class="subtitle">{{'ui.new_conversation.conversation_open' | translate}}</div>
        <p class="descr warn">{{'ui.new_conversation.conversation_open_client' | translate}}</p>

        <div class="next-step">
            <div>
                <ng-container *ngIf="minStep < 4">
                    <div class="back" (click)="handleBack(4)">
                        <img src="assets/images/icons/back.svg" alt="" />
                    </div>
                </ng-container>
            </div>
            <button
                pButton
                (click)="goToClientConversation()"
                [label]="'ui.new_conversation.go_to_conversation' | translate"
                class="p-button-first"
            ></button>
        </div>
    </ng-container>

    <ng-container *ngIf="step === 5">
        <div class="edit-campaign-form-wrap">
            <div
                [ngClass]="
                    device === 'desktop'
                        ? 'edit-campaign-form'
                        : device === 'mobile' && previewOpen == false
                          ? 'mobile-edit-campaign-form'
                          : 'mobile-edit-campaign-form-none'
                "
                [ngStyle]="{
                    width:
                        device === 'desktop' && selectedTemplate === null
                            ? '100%'
                            : '',
                    'padding-right':
                        device === 'desktop' && selectedTemplate === null
                            ? '0px'
                            : '',
                    'border-right':
                        device === 'desktop' && selectedTemplate === null
                            ? 'none'
                            : '',
                }"
            >
                <div class="input-row-initial">
                    <div class="subtitle">{{'ui.new_conversation.initial_message' | translate}}</div>
                    <ng-container
                        *ngIf="device === 'mobile' && previewOpen == false"
                    >
                        <div class="preview-link" (click)="togglePreview()">
                          {{'ui.new_conversation.preview' | translate}}
                        </div>
                    </ng-container>
                </div>
                <div class="input-row">
                    <p class="descr">
                      {{'ui.new_conversation.first_message' | translate}}
                        <ng-container *ngIf="initialMessageRequired"
                            >{{'ui.new_conversation.required' | translate}}</ng-container
                        >
                        <ng-container *ngIf="!initialMessageRequired"
                            >{{'ui.new_conversation.optional' | translate}}</ng-container
                        >
                    </p>
                </div>
                <div class="input-row">
                    <textarea
                        type="text"
                        class="initial-message-input"
                        [readOnly]="usingTemplate"
                        pInputTextarea
                        [formControl]="initialMessage"
                        #initialMessageInput
                    ></textarea>
                </div>
                <div class="only-weekdays">
                    <div class="under-initial-message">
                        <div>
                            <ng-container
                                *ngIf="
                                    templates.length > 0 &&
                                    !usingTemplate &&
                                    !selectedTemplate
                                "
                            >
                                <div
                                    class="use-template-link"
                                    (click)="selectTemplate()"
                                >
                                {{'ui.new_conversation.use_template' | translate}}
                                </div>
                            </ng-container>
                            <ng-container *ngIf="usingTemplate">
                                <div
                                    class="use-template-link"
                                    (click)="editTemplate()"
                                >
                                {{'ui.new_conversation.edit_template' | translate}}
                                </div>
                            </ng-container>
                            <ng-container
                                *ngIf="selectedTemplate && !usingTemplate"
                            >
                                <div
                                    class="use-template-link"
                                    (click)="cancelEdits()"
                                >
                                {{'ui.new_conversation.cancel_edits' | translate}}
                                </div>
                            </ng-container>
                        </div>
                        <div
                            class="character-count"
                            [class.invalid]="initialMessageTooLong"
                        >
                            {{ remainingInitialMessageLength }}
                        </div>
                    </div>
                </div>
                <div class="disclaimer">
                    <ng-container
                        *ngIf="
                            initialMessageRequired &&
                            initialMessage.invalid &&
                            (initialMessage.dirty || initialMessage.touched) &&
                            showInitialMessageError
                        "
                    >
                        <div class="error">
                          {{'ui.new_conversation.enter_initial_message' | translate}}
                        </div>
                    </ng-container>
                    <ng-container *ngIf="initialMessageTooLong">
                        <div class="error">{{'ui.new_conversation.too_long' | translate}}</div>
                    </ng-container>
                </div>
            </div>
            <ng-container *ngIf="selectedTemplate">
                <div
                    [ngClass]="
                        device === 'desktop'
                            ? 'edit-campaign-preview'
                            : device === 'mobile' && previewOpen == true
                              ? 'mobile-edit-campaign-preview'
                              : 'mobile-edit-campaign-preview-none'
                    "
                >
                    <conversation-preview
                        [selectedMedia]="selectedMedia"
                        [previewType]="previewType"
                    ></conversation-preview>
                </div>
            </ng-container>
        </div>
        <div class="btn-row">
            <div class="buttons-left">
                <div class="left">
                    <ng-container *ngIf="minStep < 5">
                        <div class="back" (click)="handleBack(5)">
                            <img src="assets/images/icons/back.svg" alt="" />
                        </div>
                    </ng-container>
                </div>
                <div>
                    <ng-container
                        *ngIf="
                            !usingTemplate &&
                            selectedTemplate &&
                            (selectedChannel.code === channel.WHATSAPP360 ||
                                selectedChannel.code ===
                                    channel.WHATSAPP360CLOUD)
                        "
                    >
                        <a [href]="helpLink" target="_blank">
                            <span
                                >{{'ui.new_conversation.edit_require_consent' | translate}}
                                <i class="pi pi-question-circle"></i
                            ></span>
                        </a>
                    </ng-container>
                    <ng-container
                        *ngIf="device === 'mobile' && previewOpen == true"
                    >
                        <div class="preview-link" (click)="togglePreview()">
                          {{'ui.new_conversation.close_preview' | translate}}
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="buttons-right">
                <button
                    pButton
                    (click)="createConversation()"
                    [label]="'ui.new_conversation.start_conversation' | translate"
                    class="p-button-first"
                ></button>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="step === 6">
        <div class="subtitle">{{'ui.new_conversation.phone_invalid' | translate}}</div>
        <p class="descr">{{'ui.new_conversation.select_valid_number' | translate}}</p>

        <div class="next-step">
            <div>
                <div class="back" (click)="handleBack(2)">
                    <img src="assets/images/icons/back.svg" alt="" />
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>
