import { createAction, props } from '@ngrx/store';
import {Conversation} from '../../models/Conversation';
import {User} from '../../models/User';

export const GetConversations = createAction(
    '[Conversations] Get Conversations',
    props<{practiceId: string}>()
);

export const GetConversationsSuccess = createAction(
    '[Conversations] Get Conversations - Success',
    props<{conversations: Conversation[]}>()
);

export const GetResolvedConversations = createAction(
  '[Conversations] Get Resolved Conversations',
  props<{practiceId: string}>()
);

export const GetResolvedConversationsSuccess = createAction(
  '[Conversations] Get Resolved Conversations - Success',
  props<{conversations: Conversation[]}>()
);

export const AddTourConversations = createAction(
  '[Conversations] Add Tour Conversation',
  props<{user: User}>()
);

export const ClearConversations = createAction(
  '[Conversations] Clear Conversations',
);

export const ToggleCollapsableSection = createAction(
  '[Conversations] Toggle Collapsable Section',
  props<{index: number}>()
);

export const LoadMoreOtherConversations = createAction(
  '[Conversations] Load More Other Conversations',
);
