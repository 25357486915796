import { Pipe, PipeTransform } from '@angular/core';
import {prettifyFormInputType} from '../helpers/prettify-form-input-type';
import {FormFieldPrefillOption} from "../enums/form-field-prefill-option.enum";
import {prettifyFormFieldPrefillOption} from "../helpers/prettify-form-field-prefill-option";
import {FormFieldType} from "../enums/form-field-type.enum";

@Pipe({name: 'prettifyFormFieldPrefillOption'})
export class PrettifyFormFieldPrefillOption implements PipeTransform {
  transform(value: string): string {
    return prettifyFormFieldPrefillOption(value as FormFieldPrefillOption);
  }
}
