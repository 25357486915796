import { ProductRequestStatus } from '../enums/product-request-status';

export function prettifyProductRequestStatus(status: ProductRequestStatus): string {
    switch (status) {
      case ProductRequestStatus.AWAITING_APPROVAL:
        return 'helpers.prettify_product_request_status.awaiting_approval';
      case ProductRequestStatus.REJECTED:
        return 'helpers.prettify_product_request_status.rejected';
      case ProductRequestStatus.READY_TO_PAY:
        return 'helpers.prettify_product_request_status.ready_to_pay';
      case ProductRequestStatus.AWAITING_PAYMENT:
        return 'helpers.prettify_product_request_status.awaiting_payment';
      case ProductRequestStatus.PAID:
        return 'helpers.prettify_product_request_status.paid';
      case ProductRequestStatus.FULFILLED:
        return 'helpers.prettify_product_request_status.fulfilled';
      case ProductRequestStatus.COMPLETE:
        return 'helpers.prettify_product_request_status.complete';
      case ProductRequestStatus.CANCELLED:
        return 'helpers.prettify_product_request_status.cancelled';
    }
}
