import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterOption } from '../../../interfaces/filter-option.interface';

@Component({
  selector: 'filter-status',
  templateUrl: './filter-status.component.html',
  styleUrls: ['./filter-status.component.scss']
})
export class FilterStatusComponent {
  @Input() selected: FilterOption[] = [];
  @Input() options: FilterOption[] = [];
  @Output() valueSelected = new EventEmitter<FilterOption[]>();
  open = false;

  handleSelected(option: FilterOption): void {
    if (this.selected.includes(option)) {
      this.selected = this.selected.filter(selected => selected.id !== option.id);
    } else {
      this.selected = [
        ...this.selected,
        option
      ];
    }
    
    this.valueSelected.emit(this.selected);
  }
}

