import { User } from '../models/User';
import { UserDto } from '../interfaces/dto/user.dto';
import { Injectable } from '@angular/core';
import { GroupAdapter } from './group.adapter';

@Injectable({
  providedIn: 'root'
})
export class UserAdapter {
  constructor(private groupAdapter: GroupAdapter) {
  }

  run(dto: UserDto): User {
    return {
      ...dto,
      fullName: dto.firstName + (dto.lastName ? ' ' + dto.lastName : ''),
      tourComplete: !!dto.tourComplete,
      groups: dto.groups ? dto.groups.map((group) => this.groupAdapter.run(group)) : []
    };
  }
}
