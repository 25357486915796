import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Message as MessageInterface } from 'src/app/models/Message';
import {MessageAttachment} from '../../../models/MessageAttachment';
import {MessageButtonType} from '../../../enums/message-button-type';
import {EnvironmentService} from "../../../services/environment.service";
import { Store, select } from '@ngrx/store';
import { getTemplates } from '../../../templates/state/selectors';
import { Template } from '../../../models/Template';

@Component({
  selector: 'message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class Message implements OnInit {
  @Input() message?: MessageInterface;
  @Input() channel?: string;
  @Input() showDate?: boolean;
  @Input() touchScreen = false;
  @Input() hideTimeStamp = false;
  @Input() fullWidth = false;
  @Output() loadedAttachment = new EventEmitter();
  @Output() delete = new EventEmitter<string>();
  buttonType = MessageButtonType;
  showErrorInfoLink = false;
  helpLink = '';
  template:Template|null=null;

  constructor(private environmentService: EnvironmentService, private store: Store) { }

  ngOnInit(): void {
    if (
      this.message &&
      this.message.error &&
      this.message.content.includes('Error: the client has sent an unsupported message type')
    ) {
      this.showErrorInfoLink = true;
    }
  let standardTemplates$ = this.store.pipe(
    select(getTemplates)
  );
  standardTemplates$.subscribe((templates) => {
    return templates.filter((template) => {
      if (parseInt(template.id) == this.message?.templateId) {
        this.template = template;
      }
    });
  });
    this.helpLink = `${this.environmentService.get('helpUrl')}/learn/section/digital-practice/category/faq/post/error-the-client-has-sent-an-unsupported-message-type`;

  }

  getHelpLink(): void {
  }

  loadedAttached(): void {
    this.loadedAttachment.emit();
  }

  trackAttachment(index: number, attachment: MessageAttachment): number | undefined {
    return attachment ? attachment.id : undefined;
  }

  deleteMessage(id: string): void {
    if (confirm('Are you sure you want to delete this message?')) {
      this.delete.emit(id);
    }
  }
}
