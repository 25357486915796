import { Injectable } from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {Practice} from '../models/Practice';
import {PaymentDto} from '../interfaces/dto/payment.dto';
import {map, switchMap} from 'rxjs/operators';
import {EnvironmentService} from '../services/environment.service';
import {HttpClient} from '@angular/common/http';
import {Template} from '../models/Template';
import {WhatsappTemplateAdapter} from '../adapters/whatsapp-template.adapter';
import {WhatsappTemplateDto} from '../interfaces/dto/whatsapp-template.dto';
import {TemplateType} from '../enums/template-type';
import {TemplatePlaceholderCreateDto} from '../interfaces/dto/template-placeholder-create.dto';
import {MediaType} from '../enums/media-type';
import {DefaultTemplateAdapter} from '../adapters/default-template.adapter';
import {DefaultWhatsappTemplateDto} from '../interfaces/dto/default-whatsapp-template.dto';
import { TemplateSubmissionStatus } from '../enums/template-submission-status';
import { User } from '../models/User';
import {WhatsappTemplateCategory} from '../enums/whatsapp-template-category';
import {DefaultWhatsappTemplate} from '../models/DefaultWhatsappTemplate';
import {TemplateCreateDto} from "./interfaces/template-create.dto.interface";
import {TemplateEditDto} from "./interfaces/template-edit.dto.interface";
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  constructor(
    private environmentService: EnvironmentService,
    private http: HttpClient,
    private whatsappTemplateAdapter: WhatsappTemplateAdapter,
    private defaultTemplateAdapter: DefaultTemplateAdapter,
    private translateService: TranslateService,
  ) { }

  getTemplates(syncWith360: boolean, practice: Practice | null, includeArchived: boolean = false): Observable<Template[]> {
    if (!practice) {
      return of([]);
    }

    const url = this.environmentService.get('backendUrl') + `/practices/${practice.id}/whatsapp-templates`;

    return this.http.get<{ templates: WhatsappTemplateDto[] }>(url, {
      withCredentials: true,
      params: {
        syncWith360,
        includeArchived
      }
    }).pipe(
      map((response: { templates: WhatsappTemplateDto[] }) => {
        const templates = response.templates.map((template) => {
          return this.whatsappTemplateAdapter.run(template);
        });

        return templates;
      })
    );
  }

  getDefaults(practice: Practice | null): Observable<DefaultWhatsappTemplate[]> {
    if (!practice) {
      return of([]);
    }

    const url = this.environmentService.get('backendUrl') + `/whatsapp-templates/get-defaults/${practice.id}`;

    return this.http.get<{ templates: DefaultWhatsappTemplate[] }>(url, {
      withCredentials: true,
    }).pipe(
      map((response: { templates: DefaultWhatsappTemplate[] }) => {
        return response.templates;
      })
    );
  }

  updateTemplateActive(template: Template): Observable<Template> {
    const url = this.environmentService.get('backendUrl') + `/whatsapp-templates/${template.id}`;

    return this.http.patch<{ template: WhatsappTemplateDto }>(url, {active: !template.active}, {withCredentials: true}).pipe(
      map((response: { template: WhatsappTemplateDto }) => {
        return this.whatsappTemplateAdapter.run(response.template);
      })
    );
  }

  deleteTemplate(template: Template): Observable<Template> {
    const url = this.environmentService.get('backendUrl') + `/whatsapp-templates/${template.id}`;

    return this.http.delete<void>(url, {withCredentials: true}).pipe(
      map(() => {
        return template;
      })
    );
  }

  createTemplate(practice: Practice | null, dto: TemplateCreateDto): Observable<Template> {
    if (!practice) {
      return this.translateService.get('templates.service.practice_not_set').pipe(
        switchMap((translatedMessage: string) => throwError(() => new Error(translatedMessage)))
      );
    }

    const url = this.environmentService.get('backendUrl') + `/practices/${practice.id}/whatsapp-templates`;

    return this.http.post<{ template: WhatsappTemplateDto }>(url, {
      name: dto.name,
      body: dto.body,
      buttonText: dto.buttonText,
      buttonLink: dto.buttonLink,
      customButton: dto.customButton,
      addQuickReplies: dto.addQuickReplies,
      quickReply: dto.quickReply,
      quickReply2: dto.quickReply2,
      type: dto.templateType,
      placeholders: dto.placeholders,
      attachMedia: dto.attachMedia,
      mediaType: dto.mediaType,
      mediaExampleId: dto.mediaExampleId,
      saveDefaultMedia: dto.saveDefaultMedia,
      submitToMeta: dto.submitToMeta,
      category: dto.category,
      practiceId: practice.id,
      authorId: dto.author.id,
    }, {withCredentials: true}).pipe(
      map((response: { template: WhatsappTemplateDto }) => {
        return this.whatsappTemplateAdapter.run(response.template);
      })
    );
  }

  unarchiveTemplate(template: Template): Observable<Template> {
    const url = this.environmentService.get('backendUrl') + `/whatsapp-templates/${template.id}`;

    return this.http.patch<void>(url, {
      deletedAt: null
    }, {withCredentials: true}).pipe(
      map(() => {
        return template;
      })
    );
  }

  updateTemplateType(template: Template, templateType: TemplateType): Observable<Template[]> {
    const url = this.environmentService.get('backendUrl') + `/whatsapp-templates/${template.id}/set-type-active`;

    return this.http.post<{ templates: WhatsappTemplateDto[] }>(url, {type: templateType}, {withCredentials: true}).pipe(
      map((response: { templates: WhatsappTemplateDto[] }) => {
        const templates = response.templates.map((tpl) => {
          return this.whatsappTemplateAdapter.run(tpl);
        });

        return templates;
      })
    );
  }

  getActivePaymentTemplate(practice: Practice | null): Observable<Template | null> {
    if (!practice) {
      return of(null);
    }

    const url = this.environmentService.get('backendUrl') + `/practices/${practice.id}/active-payment-whatsapp-template`;

    return this.http.get<{ template: WhatsappTemplateDto | null }>(url, {withCredentials: true}).pipe(
      map((response: { template: WhatsappTemplateDto | null }) => {
        if (response.template) {
          return this.whatsappTemplateAdapter.run(response.template);
        }

        return null;
      })
    );
  }

  updateTemplate(practice: Practice | null, dto: TemplateEditDto): Observable<Template> {
    if (!practice) {
      return this.translateService.get('templates.service.practice_not_set').pipe(
        switchMap((translatedMessage: string) => throwError(() => new Error(translatedMessage)))
      );
    }

    const url = this.environmentService.get('backendUrl') + `/practices/${practice.id}/whatsapp-templates/${dto.templateId}`;

    return this.http.patch<{ template: WhatsappTemplateDto }>(url, {
      name: dto.name,
      body: dto.body,
      buttonText: dto.buttonText,
      buttonLink: dto.buttonLink,
      customButton: dto.customButton,
      addQuickReplies: dto.addQuickReplies,
      quickReply: dto.quickReply,
      quickReply2: dto.quickReply2,
      type: dto.templateType,
      placeholders: dto.placeholders,
      attachMedia: dto.attachMedia,
      mediaType: dto.mediaType,
      mediaExampleId: dto.mediaExampleId,
      saveDefaultMedia: dto.saveDefaultMedia,
      submitToMeta: dto.submitToMeta,
      category: dto.category,
      status: dto.status,
      practiceId: practice.id,
    }, {withCredentials: true}).pipe(
      map((response: { template: WhatsappTemplateDto }) => {
        return this.whatsappTemplateAdapter.run(response.template);
      })
    );
  }
}
