import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { addYears } from 'date-fns';
import { CookieService } from 'ngx-cookie-service';
import { filter, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { Practice } from 'src/app/models/Practice';
import { User } from 'src/app/models/User';
import { Noop } from 'src/app/state/actions';
import { AppState } from 'src/app/state/reducers';
import { UserService } from 'src/app/users/user.service';
import * as PracticeActions from './actions';
import { getCurrentPractice } from './selectors';
import { Group } from '../../models/Group';
import {Template} from '../../interfaces/template';
import { PracticeConfig } from '../../enums/practice-config';
import { PracticeConfigInterface } from '../../interfaces/practice-config.interface';

@Injectable()
export class PracticeEffects {
  constructor(
    private actions$: Actions,
    private userService: UserService,
    private store: Store<AppState>,
    private cookieService: CookieService
  ) {}

  getStaff$ = createEffect(() => this.actions$.pipe(
    ofType(PracticeActions.GetPracticeStaff),
    withLatestFrom(this.store.select(getCurrentPractice)),
    filter(([action, practice]) => practice !== null),
    mergeMap(([action, practice]) => this.userService.getPracticeStaff(practice!.id)
      .pipe(
        map((result: User[]) => {
          return PracticeActions.GetPracticeStaffSuccess({users: result});
        })
      )
    )
  ));

  getGroups$ = createEffect(() => this.actions$.pipe(
    ofType(PracticeActions.GetPracticeGroups),
    withLatestFrom(this.store.select(getCurrentPractice)),
    filter(([action, practice]) => practice !== null),
    mergeMap(([action, practice]) => this.userService.getPracticeGroups(practice!.id)
      .pipe(
        map((result: Group[]) => {
          return PracticeActions.GetPracticeGroupsSuccess({groups: result});
        })
      )
    )
  ));

  getTemplates$ = createEffect(() => this.actions$.pipe(
    ofType(PracticeActions.GetPracticeTemplates),
    withLatestFrom(this.store.select(getCurrentPractice)),
    filter(([action, practice]) => practice !== null),
    mergeMap(([action, practice]) => this.userService.getPracticeTemplates(practice!.id)
      .pipe(
        map((result: Template[]) => {
          return PracticeActions.GetPracticeTemplatesSuccess({templates: result});
        })
      )
    )
  ));

  getUserPractices$ = createEffect(() => this.actions$.pipe(
    ofType(PracticeActions.GetUserPractices),
    mergeMap(() => this.userService.getUserPractices()
      .pipe(
        map((result: Practice[]) => {
          return PracticeActions.GetUserPracticesSuccess({practices: result});
        })
      )
    )
  ));

  getUserPracticesSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(PracticeActions.GetUserPracticesSuccess),
    tap((action) => {
      let practice;

      const cookiePractice = this.cookieService.get('messaging-selected-practice');

      if (cookiePractice) {
        practice = action.practices.find(actionPractice => actionPractice.id === cookiePractice);
      }

      if (!practice && action.practices.length > 0) {
        practice = action.practices[0];
      }

      if (practice) {
        return this.store.dispatch(PracticeActions.SetSelectedPractice({
          practice
        }));
      }

      // This is real bad.
      return this.store.dispatch(Noop());
    })
  ), {dispatch: false});

  setCurrentPractice$ = createEffect(() => this.actions$.pipe(
    ofType(PracticeActions.SetSelectedPractice),
    tap((action) => {
      if (action.practice.id) {
        this.store.dispatch(PracticeActions.GetPracticeStaff());
        this.store.dispatch(PracticeActions.GetPracticeConfig());
        const expires = addYears(new Date(), 10);
        this.cookieService.set('messaging-selected-practice', action.practice.id, expires, '/');
      }
    })
  ), {dispatch: false});

  getConfig$ = createEffect(() => this.actions$.pipe(
    ofType(PracticeActions.GetPracticeConfig),
    withLatestFrom(this.store.select(getCurrentPractice)),
    filter(([action, practice]) => practice !== null),
    mergeMap(([action, practice]) => this.userService.getPracticeConfig(practice!.id)
      .pipe(
        map((result: PracticeConfigInterface) => {
          return PracticeActions.GetPracticeConfigSuccess({config: result});
        })
      )
    )
  ));
}
