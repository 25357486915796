import { Injectable } from '@angular/core';
import { PracticeDto } from '../interfaces/dto/practice.dto';
import { Practice } from '../models/Practice';
import { FeatureAdapter } from './feature.adapter';
import {SiteAdapter} from './site.adapter';
import {Role} from '../enums/role';

@Injectable({
  providedIn: 'root'
})
export class PracticeAdapter {
  constructor(private featureAdapter: FeatureAdapter, private siteAdapter: SiteAdapter) {
  }

  run(dto: PracticeDto): Practice {
    return {
      ...dto,
      sites: dto.sites ? dto.sites.map((site) => this.siteAdapter.run(site)) : [],
      features: dto.features ? dto.features.map((feature) => this.featureAdapter.run(feature)) : [],
      userRole: dto.userRole ?? Role.NONE,
      userPermissions: dto.userPermissions ?? {},
    };
  }
}
