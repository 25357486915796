import {PaymentFilterDto} from '../interfaces/dto/payment-filter.dto';
import {PaymentSortBy} from '../enums/payment-sort-by';
import {SortDirection} from '../enums/sort-direction';
import {PaymentType} from '../enums/payment-type';

export const defaultPaymentFilters: PaymentFilterDto = {
  status: [],
  owner: [],
  client: [],
  type: [],
  created: null,
  sortBy: PaymentSortBy.SENT_AT,
  sortDir: SortDirection.DESC,
  page: 1
};
