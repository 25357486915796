import {FormFieldPrefillOption} from '../enums/form-field-prefill-option.enum';

export function prettifyFormFieldPrefillOption(value: FormFieldPrefillOption): string {
  switch (value) {
    case FormFieldPrefillOption.CLIENT_NAME:
      return 'Client Name';
    case FormFieldPrefillOption.CLIENT_EMAIL:
      return 'Client Email';
    case FormFieldPrefillOption.CLIENT_PHONE:
      return 'Client Phone';
    case FormFieldPrefillOption.CLIENT_POSTCODE:
      return 'Client Postcode';
    case FormFieldPrefillOption.CLIENT_PMS_ID:
      return 'Client ID';
    case FormFieldPrefillOption.PRACTICE_NAME:
        return 'Practice Name';      
    case FormFieldPrefillOption.PATIENT_NAME:
      return 'Patient Name';
    case FormFieldPrefillOption.PATIENT_PMS_ID:
      return 'Patient ID';
  }
}
