<div class="wrap">
    <p-dialog 
        header="An Error Occurred"
        [visible]="true"
        [modal]="true"
        [style]="{width: '400px'}"
        [baseZIndex]="10000"
        [draggable]="false" 
        [resizable]="false"
        styleClass="no-practice-dialog"
    >
        <div class="message">{{ message }}</div>
        <div class="btn-row"><button pButton label="Log Out" class="pm-button-primary" (click)="logout()"></button></div>
    </p-dialog> 
</div>