import { Injectable } from "@angular/core";
import { ClientDto } from "../interfaces/dto/client.dto";
import { Client } from "../models/Client";
import { PaymentAdapter } from "./payment.adapter";

@Injectable({
  providedIn: "root",
})
export class ClientAdapter {
  constructor(private paymentAdapter: PaymentAdapter) {}

  run(dto: ClientDto): Client {
    let fullName = "(no name)";
    if (
      (dto.firstName && dto.firstName.length > 0) ||
      (dto.lastName && dto.lastName.length > 0)
    ) {
      fullName =
        (dto.firstName ?? "") +
        (dto.firstName && dto.lastName ? " " : "") +
        (dto.lastName ?? "");
    }

    return {
      ...dto,
      fullName,
      fullNameWithPmsId: `${fullName} (${dto.pmsDisplayId ?? dto.pmsId})`,
      payments: dto.payments
        ? dto.payments
            .map((payment) => this.paymentAdapter.run(payment))
            .sort((a, b) => {
              return b.createdAt.getTime() - a.createdAt.getTime();
            })
        : [],
      nextAppointmentAt: dto.nextAppointmentAt
        ? new Date(dto.nextAppointmentAt)
        : undefined,
      lastTransactionAt: dto.lastTransactionAt
        ? new Date(dto.lastTransactionAt)
        : undefined,
    };
  }
}
