import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {FormSubmission} from '../../interfaces/form-submission';

@Component({
  selector: 'form-submission-list-item',
  templateUrl: './form-submission-list-item.component.html',
  styleUrls: ['./form-submission-list-item.component.scss']
})
export class FormSubmissionListItemComponent {
  @Input() formSubmission?: FormSubmission;
  actionsOpen = false;

  constructor(
    private router: Router,
    private store: Store,
  ) {}

  toggleActions(): void {
    this.actionsOpen = !this.actionsOpen;
  }

  viewSub(): void {
    if (this.formSubmission) {
      this.router.navigateByUrl(`/forms/submissions/${this.formSubmission.uuid}`);
    }
  }
}
