import { Injectable } from '@angular/core';
import {SiteDto} from '../interfaces/dto/site.dto';
import {Site} from '../models/Site';
import {parseISO} from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class SiteAdapter {
  run(dto: SiteDto): Site {
    return {
      id: dto.id,
      name: dto.name,
      practiceId: dto.practiceId,
      vetbookerResourceId: dto.vetbookerResourceId,
      clinicCode: dto.clinicCode,
      createdAt: parseISO(dto.createdAt),
    };
  }
}
