import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { MarkAllNotificationsAsRead } from 'src/app/notifications/state/actions';
import { AppState } from 'src/app/state/reducers';

@Component({
  selector: 'notification-list-controls',
  templateUrl: './notification-list-controls.component.html',
  styleUrls: ['./notification-list-controls.component.scss']
})
export class NotificationListControlsComponent implements OnInit {
  @Input() showAll = false;
  @Output() emitShowAll = new EventEmitter<boolean>();

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
  }

  handleShowAllChange() {
    this.emitShowAll.emit();
  }

  markAllAsRead() {
    this.store.dispatch(MarkAllNotificationsAsRead());
  }
}
