import { PaymentStatus } from "../enums/payment-status";
import { Payment } from "../models/Payment";
import { getPaymentStatus } from "./get-payment-status";

export function getPaymentStatusChangeDate(payment: Payment): Date | null {
    if (getPaymentStatus(payment).label === PaymentStatus.EXPIRED) {
        return payment.expiresAt;
    }
    
    if (getPaymentStatus(payment).label === PaymentStatus.PAID) {
        return payment.paidAt;
    }
    
    if (getPaymentStatus(payment).label === PaymentStatus.COMPLETE) {
        return payment.syncedToPmsAt;
    }

    if (getPaymentStatus(payment).label === PaymentStatus.REFUNDED) {
        return payment.refundedAt;
    }
    
    if (getPaymentStatus(payment).label === PaymentStatus.REFUND) {
        return payment.createdAt;
    }
    
    if (getPaymentStatus(payment).label === PaymentStatus.CANCELLED) {
        return payment.cancelledAt;
    }

    if (getPaymentStatus(payment).label === PaymentStatus.FUNDS_HELD) {
        return payment.authorisedAt ?? null;
    }

    if (getPaymentStatus(payment).label === PaymentStatus.HOLD_EXPIRED) {
        return payment.authorisationExpiresAt ?? null;
    }
    
    return null;
}