<ng-container *ngIf="folder">
  <div #folderCard class="folder-grid-item">
    <div class="left" (click)="handleClick()">
      <i class="pi pi-folder"></i> {{ folder.name }}
    </div>
    <div #dots class="right">
      <ng-container *ngIf="manage">
        <i class="pi pi-ellipsis-v"></i>
      </ng-container>
    </div>
  </div>
  <ng-container *ngIf="manage">
    <p-contextMenu [target]="dots" [model]="menuItems" appendTo="body" triggerEvent="click"></p-contextMenu>
    <p-contextMenu [target]="folderCard" [model]="menuItems" appendTo="body"></p-contextMenu>
  </ng-container>
</ng-container>
