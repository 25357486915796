import { PaymentStatus } from '../enums/payment-status';
import { IPaymentStatus } from '../interfaces/payment-status.interface';

export const paymentStatus: IPaymentStatus[] = [
  {
    id: PaymentStatus.EXPIRED,
    label: 'Expired',
    color: 'var(--alert-color)'
  },
  {
    id: PaymentStatus.FUNDS_HELD,
    label: 'Funds Held',
    color: 'var(--cyan-400)'
  },
  {
    id: PaymentStatus.HOLD_EXPIRED,
    label: 'Hold Expired',
    color: 'var(--alert-color)'
  },
  {
    id: PaymentStatus.PENDING,
    label: 'Pending',
    color: 'var(--orange-400)'
  },
  {
    id: PaymentStatus.QUEUED,
    label: 'Queued',
    color: 'var(--third-color)'
  },
  {
    id: PaymentStatus.PAID,
    label: 'Paid',
    color: 'var(--aquamarine-400)'
  },
  {
    id: PaymentStatus.COMPLETE,
    label: 'Complete',
    color: 'var(--aquamarine-400)'
  },
  {
    id: PaymentStatus.REFUNDED,
    label: 'Refunded',
    color: 'var(--aquamarine-400)'
  },
  {
    id: PaymentStatus.REFUND,
    label: 'Refund',
    color: 'var(--secondary-color)'
  },
  {
    id: PaymentStatus.CANCELLED,
    label: 'Cancelled',
    color: 'var(--surface-400)'
  }
];
