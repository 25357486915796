import { Payment } from '../models/Payment';
import {PaymentType} from '../enums/payment-type';

export function canPaymentBeRefunded(payment: Payment): boolean {
    if (payment.type === PaymentType.ONLINE) {
      return false;
    }

    if (payment.refundedAt) {
        return false;
    }

    if (payment.refundOfId) {
        return false;
    }

    if (payment.paidAt) {
        return true;
    }

    return false;

}
