import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { ProductRequest } from '../../../models/ProductRequest';
import {GoToProductRequestDetail} from '../../state/actions';
import {Store} from '@ngrx/store';
import {AppState} from '../../../state/reducers';
import {interval, takeWhile} from "rxjs";

@Component({
  selector: 'product-request-list-item',
  templateUrl: './product-request-list-item.component.html',
  styleUrls: ['./product-request-list-item.component.scss'],
})
export class ProductRequestListItemComponent implements OnInit, OnDestroy {
  alive = true;
  @Input() productRequest: ProductRequest | null = null;
  @Input() updating = false;
  refresh = new Date();

  constructor(private store: Store<AppState>) {
  }

  ngOnInit(): void {
    interval(30000).pipe(takeWhile(() => this.alive)).subscribe(() => {
      this.refresh = new Date();
    });
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  openDetail(): void {
    if (this.productRequest) {
      this.store.dispatch(GoToProductRequestDetail({productRequest: this.productRequest}));
    }
  }
}
