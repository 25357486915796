import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../state/reducers';
import { Observable, takeWhile } from 'rxjs';
import { MediaFolder } from '../../../models/MediaFolder';
import { getMediaFolderTree } from '../../state/selectors';
import { MoveMedia } from '../../state/actions';

@Component({
  selector: 'move-media',
  templateUrl: './move-media.component.html',
  styleUrls: ['./move-media.component.scss']
})
export class MoveMediaComponent implements OnInit, OnDestroy {
  alive = true;
  form = new FormGroup({
    destinationFolder: new FormControl('', Validators.required),
  });
  folders$: Observable<MediaFolder[]>;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private store: Store<AppState>,
    ) {
      this.folders$ = this.store.pipe(select(getMediaFolderTree)).pipe(takeWhile(() => this.alive));
    }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  submit(): void {
    Object.keys(this.form.controls).forEach(field => {
      const control = this.form.get(field);
      control?.markAsTouched({ onlySelf: true });
    });

    if (this.config.data.media.id && this.form.valid && this.form.controls.destinationFolder.value) {
      this.store.dispatch(MoveMedia({
        media: this.config.data.media,
        destinationFolderId: Number(this.form.controls.destinationFolder.value)
      }));
      
      this.ref.close();
    }
  }
}
