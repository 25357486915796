import { Component, Input, OnInit } from '@angular/core';
import { Patient } from 'src/app/models/Patient';
import { Nl2brPipe } from 'src/app/pipes/nl2br.pipe';

@Component({
  selector: 'patient-notes',
  templateUrl: './patient-notes.component.html',
  styleUrls: ['./patient-notes.component.scss']
})
export class PatientNotesComponent implements OnInit {
  @Input() patient?: Patient;
  @Input() disabled?: boolean;
  modalActive = false;

  constructor() { }

  ngOnInit(): void {
  }

  showModal(): void {
    this.modalActive = true;
  }

  hideModal(): void {
    this.modalActive = false;
  }
}
