import { TemplateType } from '../enums/template-type';
import { Template } from '../models/Template';

export function isStandardTemplate(template: Template): boolean {
    if ([
        TemplateType.Standard,
        TemplateType.Custom,
        TemplateType.Campaign,
        TemplateType.CampaignWithPayment
    ].includes(template.type)) {
        return true;
    }

    return false;
}
