import { Injectable } from '@angular/core';
import {of, takeUntil} from 'rxjs';
import { catchError, filter, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { ClientService } from 'src/app/clients/client.service';
import { Client } from 'src/app/models/Client';
import { Patient } from 'src/app/models/Patient';
import { AppState } from 'src/app/state/reducers';
import { getPatientPage } from './selectors';
import * as PatientActions from './actions';
import * as ConversationActions from '../../conversation/state/actions';
import { getConversationClient } from 'src/app/conversation/state/selectors';
import {ClearPatients} from "./actions";

@Injectable()
export class PatientEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private clientService: ClientService,
  ) {
  }

  getPatientPage$ = createEffect(() => this.actions$.pipe(
    ofType(PatientActions.IncrementPatientPage),
    withLatestFrom(this.store.select(getPatientPage), this.store.select(getConversationClient)),
    mergeMap(([action, patientPage, client]) => {
      if (client) {
        return this.clientService.getClient(client.id, patientPage).pipe(
          tap((result: { client: Client, patients: Patient[] }) => {
            this.store.dispatch(PatientActions.GetPatientsSuccess({patients: result.patients, replace: false}));
          }),
          map((result: { client: Client, patients: Patient[] }) => {
            return ConversationActions.GetClientSuccess({client: result.client});
          }),
          takeUntil(this.actions$.pipe(ofType(PatientActions.ClearPatients))),
          catchError(() => {
            return of(ConversationActions.GetClientFailed());
          }),
        );
      }
      return of(ConversationActions.GetClientFailed());
    })
  ));
}
