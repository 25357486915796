import { Payment } from "../models/Payment";

export function hasPaymentAuthorisationExpired(payment: Payment): boolean {
    if (!payment.authorisedAt) {
        return false;
    }

    if (payment.cancelAuthAt && new Date().getTime() > payment.cancelAuthAt.getTime()) {
        return true;
    }

    if (new Date().getTime() > (payment.authorisedAt.getTime() + 604800000)) {
        return true;
    }

    return false;
}