import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { generateColour } from '../../../helpers/user-color-generator';
import { Tag } from '../../../models/Tag';

@Component({
  selector: 'tag-avatar',
  templateUrl: './tag-avatar.component.html',
  styleUrls: ['./tag-avatar.component.scss']
})
export class TagAvatarComponent implements OnInit, OnChanges {
  @Input() tag?: Tag;
  @Input() size = 'medium';
  @Input() responsive = false;
  @Input() unsetInitials = '+';
  backgroundColor?: string;

  constructor() { }

  ngOnInit(): void {
    this.generateColor();
  }

  ngOnChanges(): void {
    this.generateColor();
  }

  generateColor(): void {
    let name = '';
    
    if (this.tag) {
      name = this.tag.name;
    }

    this.backgroundColor = generateColour(name);
  }
}
