import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProductRequestsRoutingModule } from './product-requests-routing.module';
import { ProductRequestsBoardPage } from './pages/product-requests-board/product-requests-board.component';
import { LayoutSwitcherComponent } from './components/layout-switcher/layout-switcher.component';
import { LetDirective, PushPipe } from '@ngrx/component';
import { UiModule } from '../ui/ui.module';
import { ProductRequestKanbanComponent } from './components/product-request-kanban/product-request-kanban.component';
import { ProductRequestListComponent } from './components/product-request-list/product-request-list.component';
import { NewProductRequestComponent } from './components/new-product-request/new-product-request.component';
import { ProductRequestListItemComponent } from './components/product-request-list-item/product-request-list-item.component';
import { UtilsModule } from '../utils/utils.module';
import { ProductRequestStatusComponent } from './components/product-request-status/product-request-status.component';
import { ProductRequestApprovalStatusComponent } from './components/product-request-approval-status/product-request-approval-status.component';
import { ProductRequestFiltersComponent } from './components/product-request-filters/product-request-filters.component';
import { PatientsModule } from '../patients/patients.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProductRequestSortComponent } from './components/product-request-sort/product-request-sort.component';
import { ProductRequestCardComponent } from './components/product-request-card/product-request-card.component';
import { ProductRequestDetailComponent } from './components/product-request-detail/product-request-detail.component';
import {CommentsModule} from '../comments/comments.module';
import {ProductRequestsListPage} from './pages/product-requests-list/product-requests-list.component';
import { ApproveProductRequestComponent } from './components/approve-product-request/approve-product-request.component';
import { RejectProductRequestComponent } from './components/reject-product-request/reject-product-request.component';
import { ProductRequestCreatePaymentComponent } from './components/product-request-create-payment/product-request-create-payment.component';
import { ConfirmProductRequestPaidComponent } from './components/confirm-product-request-paid/confirm-product-request-paid.component';
import { DispenseProductRequestComponent } from './components/dispense-product-request/dispense-product-request.component';
import { FormatPipeModule } from 'ngx-date-fns';
import { DirectivesModule } from '../directives/directives.module';
import { ProductRequestItemRowComponent } from './components/product-request-item-row/product-request-item-row.component';


@NgModule({
  declarations: [
    ProductRequestsBoardPage,
    ProductRequestsListPage,
    LayoutSwitcherComponent,
    ProductRequestKanbanComponent,
    ProductRequestListComponent,
    NewProductRequestComponent,
    ProductRequestListItemComponent,
    ProductRequestStatusComponent,
    ProductRequestApprovalStatusComponent,
    ProductRequestFiltersComponent,
    ProductRequestSortComponent,
    ProductRequestCardComponent,
    ProductRequestDetailComponent,
    ProductRequestCardComponent,
    ApproveProductRequestComponent,
    RejectProductRequestComponent,
    ProductRequestCreatePaymentComponent,
    ConfirmProductRequestPaidComponent,
    DispenseProductRequestComponent,
    ProductRequestItemRowComponent
  ],
  imports: [
    CommonModule,
    ProductRequestsRoutingModule,
    PushPipe,
    LetDirective,
    FormsModule,
    UiModule,
    UtilsModule,
    PatientsModule,
    FormsModule,
    ReactiveFormsModule,
    CommentsModule,
    FormatPipeModule,
    DirectivesModule
  ],
  exports: [
    NewProductRequestComponent,
    ApproveProductRequestComponent,
    RejectProductRequestComponent,
    ProductRequestCreatePaymentComponent,
    ProductRequestListComponent,
    ProductRequestDetailComponent,
    ConfirmProductRequestPaidComponent,
    DispenseProductRequestComponent
  ]
})
export class ProductRequestsModule { }
