import { Pipe, PipeTransform } from '@angular/core';
import { getPaymentStatusChangeDate } from '../helpers/get-payment-status-change-date';
import { Payment } from '../models/Payment';

@Pipe({name: 'getPaymentStatusChangeDate'})
export class GetPaymentStatusChangeDatePipe implements PipeTransform {
  transform(payment: Payment): Date | null {
    return getPaymentStatusChangeDate(payment);
  }
}
