import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { HistoryItem } from 'src/app/models/HistoryItem';
import { Observable, Subscription } from 'rxjs';
import { Practice } from '../../../models/Practice';
import { Currency } from '../../../models/Currency';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../state/reducers';
import { getCurrentPractice } from '../../../practices/state/selectors';
import { takeWhile } from 'rxjs/operators';
import { getCurrencies } from '../../../state/selectors';

@Component({
  selector: 'patient-history-item',
  templateUrl: './patient-history-item.component.html',
  styleUrls: ['./patient-history-item.component.scss']
})
export class PatientHistoryItemComponent implements OnInit, OnDestroy {
  @Input() historyItem?: HistoryItem;
  alive = true;
  practice$?: Observable<Practice | null>;
  practiceSub$?: Subscription;
  practice?: Practice | null;
  currencies: Currency[] = [];
  practiceCurrency?: Currency;

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
    this.subscribeToCurrentPractice();
    this.subscribeToCurrencies();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  subscribeToCurrentPractice(): void {
    this.practice$ = this.store.pipe(select(getCurrentPractice)).pipe(
      takeWhile(() => this.alive)
    );

    this.practiceSub$ = this.practice$.subscribe((practice) => {
      this.practice = practice;
      this.setPracticeCurrency();
    });
  }

  subscribeToCurrencies(): void {
    this.store.pipe(select(getCurrencies)).pipe(
      takeWhile(() => this.alive)
    ).subscribe(currencies => {
      this.currencies = currencies;
      this.setPracticeCurrency();
    });
  }

  setPracticeCurrency(): void {
    if (this.currencies.length && this.practice) {
      this.practiceCurrency = this.currencies.find((currency) => currency.currencyCode === this.practice?.currency);
    }
  }
}
