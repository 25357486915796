export async function resizeImage(file: File, maxSize: number): Promise<File> {
  if (file.type.indexOf('image') !== -1 && file.type.indexOf('gif') === -1) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.src = await new Promise((resolve, reject) => {
      const fr = new FileReader();
      fr.onload = () => {
        if (fr.result) {
          resolve(fr.result.toString());
        } else {
          resolve('');
        }
      };
      fr.onerror = reject;
      fr.readAsDataURL(file);
    });

    await img.decode();

    // calculate new size
    const ratio = Math.min(maxSize / img.width, maxSize / img.height);
    const width = img.width * ratio + .5 || 0;
    const height = img.height * ratio + .5 || 0;
    // resize the canvas to the new dimensions
    canvas.width = width;
    canvas.height = height;
    // scale & draw the image onto the canvas
    if (ctx) {
      ctx.imageSmoothingEnabled = true;
      ctx.drawImage(img, 0, 0, width, height);
      const blob = await new Promise(resolve => canvas.toBlob(resolve, 'image/jpeg'));
      return Promise.resolve(new File([blob as BlobPart], file.name, {type: 'image/jpeg'}));
    } else {
      return Promise.resolve(file);
    }
  } else {
    return Promise.resolve(file);
  }
}
