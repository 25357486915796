<ng-container *ngIf="procedure">
    <div class="title">
        {{ procedure.name }}
    </div>
    <div class="items">
        <ng-container *ngFor="let item of procedure.items">
            <div class="item">
                <procedure-item [item]="item"></procedure-item>
            </div>
        </ng-container>
    </div>
</ng-container>
