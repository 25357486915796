import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map } from 'rxjs';
import { User } from '../../models/User';
import { ViewersService } from '../viewer.service';
import * as ViewerActions from './actions';

@Injectable()
export class ViewersEffects {
  constructor(
    private actions$: Actions,
    private viewersService: ViewersService
  ) {}

  getViewers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ViewerActions.GetViewers),
      mergeMap((action) => {
        return this.viewersService.getViewers(action.id, action.modelType).pipe(
          map((result: User[]) => {
            return ViewerActions.GetViewersSuccess({ viewers: result });
          })
        )
      }
      )
    )
  );
}
