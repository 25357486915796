import { Pipe, PipeTransform } from "@angular/core";

export enum ClientSearchPlaceholder {
  RXWORKS = "Last name, last name with patient, phone, email or client ID",
  ROBOVET = "Phone, email or client ID",
  MERLIN = "Last name, phone, email or client ID",
  TELEOS = "Last name, last name with patient, phone, email or client ID",
  PROVET = "Last name, phone, email or client ID",
  EZYVET = "Last name, phone, email or client ID",
  VETIT = "First name and last name, email or phone",
}

// not allowed duplicated values
const enumToTranslation: Record<ClientSearchPlaceholder, string> = {
  [ClientSearchPlaceholder.EZYVET]: 'enums.client_search_placeholder.ezyvet',
  [ClientSearchPlaceholder.ROBOVET]: 'enums.client_search_placeholder.robovet',
  [ClientSearchPlaceholder.TELEOS]: 'enums.client_search_placeholder.teleos',
  [ClientSearchPlaceholder.VETIT]: 'enums.client_search_placeholder.vetit',
}

@Pipe({name: 'clientSearchPlaceholderTranslationKey'})
export class ClientSearchPlaceholderTranslationKeyPipe implements PipeTransform {
  transform(value: ClientSearchPlaceholder): string {
    return enumToTranslation[value];
  }
}
