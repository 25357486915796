import {DashboardStatsDto} from '../interfaces/dto/dashboard-stats.dto';
import {DashboardStats} from '../interfaces/dashboard-stats.interface';
import {Injectable} from '@angular/core';
import {StatsGroupBy} from "../enums/stats-group-by";

@Injectable({
  providedIn: 'root'
})
export class StatsAdapter {
  run(dto: DashboardStatsDto): DashboardStats {
    return {
      uniqueClientsCumulative: [
        ...dto.uniqueClientsCumulative.map((stat) => {
          return {
            count: stat.count,
            date: new Date(stat.date),
          };
        }),
      ],
      newConversationsCumulative: [
        ...dto.newConversationsCumulative.map((stat) => {
          return {
            count: stat.count,
            date: new Date(stat.date),
          };
        }),
      ],
      resolvedConversationsCumulative: [
        ...dto.resolvedConversationsCumulative.map((stat) => {
          return {
            count: stat.count,
            date: new Date(stat.date),
          };
        }),
      ],
      paymentVolumeCumulative: [
        ...dto.paymentVolumeCumulative.map((stat) => {
          return {
            amount: stat.amount / 100,
            date: new Date(stat.date),
          };
        }),
      ],
      paymentsCumulative: [
        ...dto.paymentsCumulative.map((stat) => {
          return {
            count: stat.count,
            date: new Date(stat.date),
          };
        }),
      ],
      paymentCount7Days: dto.paymentCount7Days,
      paymentVolume7Days: dto.paymentVolume7Days / 100,
      avgResponseTime: dto.avgResponseTime,
      chartGroupBy: dto.chartGroupBy,
    };
  }
}
