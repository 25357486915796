import {FormFieldType} from "../enums/form-field-type.enum";

export function prettifyFormInputType(value: FormFieldType): string {
  switch (value) {
    case FormFieldType.CHECKBOX:
      return 'helpers.prettify_form_input_type.checkbox';
    case FormFieldType.AGREE_TO_TERMS:
      return 'helpers.prettify_form_input_type.agree_to_terms';
    case FormFieldType.MULTI_CHECKBOX:
      return 'helpers.prettify_form_input_type.multiple_checkboxes';
    case FormFieldType.TEXT:
      return 'helpers.prettify_form_input_type.text';
    case FormFieldType.EMAIL:
      return 'helpers.prettify_form_input_type.email';
    case FormFieldType.TEXTAREA:
      return 'helpers.prettify_form_input_type.text_area';
    case FormFieldType.SELECT:
      return 'helpers.prettify_form_input_type.select';
    case FormFieldType.RADIO:
      return 'helpers.prettify_form_input_type.radio';
    case FormFieldType.DATE:
      return 'helpers.prettify_form_input_type.date';
    case FormFieldType.HIDDEN:
      return 'helpers.prettify_form_input_type.hidden';
  }
}
