import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { select, Store } from "@ngrx/store";
import { combineLatest, forkJoin, Observable, Subscription } from "rxjs";
import { takeWhile, withLatestFrom } from "rxjs/operators";
import { PracticeFeature } from "src/app/enums/practice-feature";
import { practiceHasFeature } from "src/app/helpers/practice-has-feature";
import { Patient } from "src/app/models/Patient";
import { Practice } from "src/app/models/Practice";
import { getCurrentPractice } from "src/app/practices/state/selectors";
import { AppState } from "src/app/state/reducers";
import { HistoryItem } from "../../../models/HistoryItem";
import { PMS } from "../../../enums/pms";
import { concatLatestFrom } from "@ngrx/effects";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "patient-panel",
  templateUrl: "./patient-panel.component.html",
  styleUrls: ["./patient-panel.component.scss"],
})
export class PatientPanelComponent implements OnInit, OnDestroy {
  @Input() patient?: Patient;
  @Input() disabled?: boolean;
  @Input() shareLoading = false;
  @Input() shared = false;
  @Input() shareDisabled = false;
  @Input() changeDisabled = false;
  @Input() patientHistoryLoading = false;
  @Input() patientHistory: HistoryItem[] = [];
  @Output() patientChanged = new EventEmitter();
  @Output() shareToPms = new EventEmitter<Patient>();
  @Output() patientHistoryOpened = new EventEmitter<string>();
  alive = true;
  practice$?: Observable<Practice | null>;
  practiceSub$?: Subscription;
  practice?: Practice | null;
  shareEnabled = false;
  shareLabel = "";

  constructor(private store: Store<AppState>, private translateService: TranslateService) {}

  ngOnInit(): void {
    if (this.shareLoading === false) {
      this.shared = false;
    }
    this.subscribeToCurrentPractice();
    this.translateService.get('patients.patient_panel.share_to_pms').pipe(takeWhile(_ => this.alive)).subscribe(label => {
      this.shareLabel = label;
    })
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  subscribeToCurrentPractice(): void {
    this.practice$ = this.store
      .pipe(select(getCurrentPractice))
      .pipe(takeWhile(() => this.alive));

      this.practiceSub$ = this.practice$.subscribe((practice) => {
        this.practice = practice;
        const keys = [
          'patients.patient_panel.share_to_pms',
          'patients.patient_panel.open_in',
          'patients.patient_panel.share_to',
        ];
        const params = {
          'patients.patient_panel.share_to_pms': {},
          'patients.patient_panel.open_in': {pms: this.practice?.pms},
          'patients.patient_panel.share_to': {pms: this.practice?.pms}
        }
        this.translateService.get(keys, params).subscribe(labels => {
          this.updateShareEnabled();
          this.updateShareLabel(labels);
        })
    });
  }

  handlePatientChanged(): void {
    this.patientChanged.emit();
  }

  emitShareToPms(): void {
    if (this.patient && !this.disabled) {
      this.shareToPms.emit(this.patient);
    }
  }

  handlePatientHistoryOpened(patientId: string): void {
    this.patientHistoryOpened.emit(patientId);
  }

  updateShareEnabled(): void {
    this.shareEnabled = false;

    if (
      !this.shareDisabled &&
      this.practice &&
      this.practice.pms !== PMS.ROBOVET &&
      practiceHasFeature(this.practice, PracticeFeature.PATIENT_SHARE_TO_PMS) &&
      ((this.practice.pms !== PMS.PROVET &&
        this.practice.pms !== PMS.ASCEND &&
        this.practice.pms !== PMS.EZYVET) ||
        ((this.practice.pms === PMS.PROVET ||
          this.practice.pms === PMS.ASCEND ||
          this.practice.pms === PMS.EZYVET) &&
          this.patient?.url))
    ) {
      this.shareEnabled = true;
    }
  }

  updateShareLabel(labels: {[key: string]: string}): void {
    this.shareLabel = labels['patients.patient_panel.share_to_pms'];

    if (this.practice) {
      switch (this.practice.pms) {
        case PMS.PROVET:
        case PMS.ASCEND:
        case PMS.EZYVET:
          this.shareLabel = labels['patients.patient_panel.open_in'];
          break;

        default:
          this.shareLabel = labels['patients.patient_panel.share_to'];
      }
    }
  }
}
