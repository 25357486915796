import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { Client } from 'src/app/models/Client';
import {select, Store} from '@ngrx/store';
import {getCurrentPractice} from '../../../practices/state/selectors';
import {takeWhile} from 'rxjs/operators';
import {practiceHasFeature} from '../../../helpers/practice-has-feature';
import {PracticeFeature} from '../../../enums/practice-feature';
import {AppState} from '../../../state/reducers';
import {Practice} from '../../../models/Practice';

@Component({
  selector: 'client-notes',
  templateUrl: './client-notes.component.html',
  styleUrls: ['./client-notes.component.scss']
})
export class ClientNotesComponent implements OnInit, OnDestroy {
  @Input() client?: Client;
  alive = true;
  modalActive = false;
  notesEnabled = false;
  financialEnabled = false;
  practice: Practice | null = null;
  disabled = true;

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
    this.subscribeToCurrentPractice();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  subscribeToCurrentPractice(): void {
    this.store.pipe(select(getCurrentPractice)).pipe(
      takeWhile(() => this.alive)
    ).subscribe(practice => {
      this.practice = practice;
      this.updateFeatures();
      this.calculateDisabled();
    });
  }

  updateFeatures(): void {
    this.notesEnabled = practiceHasFeature(this.practice, PracticeFeature.CLIENT_NOTES);
    this.financialEnabled = practiceHasFeature(this.practice, PracticeFeature.FINANCIAL_NOTES);
  }

  showModal(): void {
    this.modalActive = true;
  }

  hideModal(): void {
    this.modalActive = false;
  }

  calculateDisabled(): void {
    if (!this.client) {
      this.disabled = true;
      return;
    }

    if (!this.client.clientNotes && !this.client.financialNotes) {
      this.disabled = true;
      return;
    }

    if (!this.notesEnabled && !this.client.financialNotes) {
      this.disabled = true;
      return;
    }

    if (!this.financialEnabled && !this.client.clientNotes) {
      this.disabled = true;
      return;
    }

    this.disabled = false;
  }
}
