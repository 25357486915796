import {Pipe, PipeTransform} from '@angular/core';
import {ProductRequest} from '../models/ProductRequest';
import {ProductRequestItem} from '../models/ProductRequestItem';
import {ProductRequestStatus} from '../enums/product-request-status';
import {ProductRequestApprovalStatus} from '../enums/product-request-approval-status';


@Pipe({name: 'shouldShowDispensedCount'})
export class ShouldShowDispensedCountPipe implements PipeTransform {
  transform(productRequest: ProductRequest, item: ProductRequestItem): boolean {
    const allowedStatuses = [
      ProductRequestStatus.FULFILLED,
      ProductRequestStatus.COMPLETE,
    ];

    if (
      allowedStatuses.includes(productRequest.status) &&
      (
        item.approvalStatus === ProductRequestApprovalStatus.APPROVED ||
        item.approvalStatus === ProductRequestApprovalStatus.NOT_REQUIRED
      )
    ) {
      return true;
    }

    return false;
  }
}
