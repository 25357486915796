import { Injectable } from '@angular/core';
import { PriorityDto } from '../interfaces/dto/priority.dto';
import { Priority } from '../models/Priority';

@Injectable({
  providedIn: 'root'
})
export class PriorityAdapter {
  run(dto: PriorityDto): Priority {
    return {
      ...dto,
      createdAt: new Date(dto.createdAt),
      updatedAt: new Date(dto.updatedAt),
    };
  }
}