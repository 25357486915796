import { Pipe, PipeTransform } from '@angular/core';
import { canPaymentBeCompleted } from '../helpers/can-payment-by-completed';
import { Payment } from '../models/Payment';

@Pipe({name: 'canPaymentBeCompleted'})
export class CanPaymentBeCompletedPipe implements PipeTransform {
  transform(payment: Payment): boolean {
    return canPaymentBeCompleted(payment);
  }
}
