import { Component } from '@angular/core';

@Component({
  selector: 'toast',
  templateUrl: './toasts.component.html',
  styleUrls: ['./toasts.component.scss']
})
export class ToastsComponent {
  constructor() {}


}
