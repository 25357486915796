import { Component, Input } from '@angular/core';
import { ProductRequestStatus } from '../../../enums/product-request-status';
import { ProductRequest } from '../../../models/ProductRequest';

@Component({
  selector: 'product-request-approval-status',
  templateUrl: './product-request-approval-status.component.html',
  styleUrls: ['./product-request-approval-status.component.scss']
})
export class ProductRequestApprovalStatusComponent {
  @Input() productRequest: ProductRequest | null = null;
  status = ProductRequestStatus;
}
