<ng-container *ngIf="form">
  <div class="form-list-item">
    <div class="name">
      <ng-container *ngIf="form.archivedAt">
        <i class="pi pi-inbox" tooltipPosition="right"pTooltip="Archived"></i>
      </ng-container>
      {{ form.name }}
    </div>
    <div class="take-payment">
      {{ form.takePayment ? 'Yes' : 'No' }}
    </div>
    <div class="actions">
      <div class="actions-opener" (clickOutside)="actionsOpen = false" (click)="toggleActions()"><img src="assets/images/icons/dots.svg" alt=""></div>
      <ng-container *ngIf="actionsOpen">
        <div class="actions-dropdown">
          <div class="action" (click)="editForm()">
            Edit
          </div>
          <ng-container *ngIf="form.archivedAt">
            <div class="action" (click)="archive(false)">
              Restore from Archive
            </div>
          </ng-container>
          <ng-container *ngIf="!form.archivedAt">
            <div class="action" (click)="archive()">
              Archive
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
