import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { Store } from '@ngrx/store';
import { dateFilterOptions } from '../../../constants/date-filter-options.constants';
import { defaultProductRequestFilters } from '../../../constants/default-product-request-filters.constants';
import { SearchType } from '../../../enums/search-type';
import { AppState } from '../../../state/reducers';
import { FilterOptionType } from '../../../enums/filter-option-type';
import { FilterOption } from '../../../interfaces/filter-option.interface';
import { FilterConfig } from '../../../interfaces/filter-config.interface';
import { FilterType } from '../../../enums/filter-type';
import { productRequestStatusFilterOptions } from '../../../constants/product-request-status-filter-options.constants';
import { productRequestApprovalStatusFilterOptions } from '../../../constants/product-request-approval-status-filter-options.constants';
import { FilterSelection } from '../../../interfaces/filter-selection.interface';
import { Location } from '@angular/common';
import { format } from 'date-fns';

@Component({
  selector: 'product-request-filters',
  templateUrl: './product-request-filters.component.html',
  styleUrls: ['./product-request-filters.component.scss']
})
export class ProductRequestFiltersComponent implements OnInit, OnDestroy {
  @Output() filtersUpdated = new EventEmitter();
  alive = true;
  filters: FilterConfig = {
    filters: [
      {
        type: FilterType.CREATED_AT,
        options: dateFilterOptions
      },
      {
        type: FilterType.STATUS,
        options: productRequestStatusFilterOptions
      },
      {
        type: FilterType.OWNER,
        options: []
      },
      {
        type: FilterType.APPROVAL_STATUS,
        options: productRequestApprovalStatusFilterOptions
      }
    ]
  };
  selectedValues: FilterSelection = {
    ...defaultProductRequestFilters
  };

  constructor(private route: ActivatedRoute, private location: Location, private store: Store<AppState>, private router: Router) {}

  ngOnInit(): void {
    this.subscribeToRouteParams();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  handleFilterSelected(data: {type: FilterType, values: FilterOption[]}): void {
    this.selectedValues = {
      ...this.selectedValues,
      [data.type]: data.values
    };

    this.navigate();

    this.filtersUpdated.emit(this.selectedValues);
  }

  handleFiltersCleared(): void {
    this.selectedValues = {
      ...defaultProductRequestFilters
    };

    this.navigate();

    this.filtersUpdated.emit(this.selectedValues);
  }

  navigate(): void {
    let queryParams: any = {};

    Object.keys(this.selectedValues)
      .forEach((key) => {
        if (this.selectedValues[key as FilterType].map(value => value.type).includes(FilterOptionType.DATE_RANGE)) {
          if (this.selectedValues[key as FilterType][0].range) {
            queryParams = {
              ...queryParams,
              [key]: 'custom',
              [`${key}_start`]: format(this.selectedValues[key as FilterType][0].range!.start, 'yyyy-MM-dd'),
              [`${key}_end`]: format(this.selectedValues[key as FilterType][0].range!.end, 'yyyy-MM-dd'),
            };
            return;
          }
        }

        queryParams = {
          ...queryParams,
          [key]: this.selectedValues[key as FilterType].map(value => value.id),
        };
      });

    this.router.navigate([], {
      queryParams,
      queryParamsHandling: 'merge'
    });
  }

  subscribeToRouteParams(): void {
    this.route.queryParamMap
      .subscribe((params => {
        Object.values(FilterType).forEach(filterType => {
          if (params.getAll(filterType)) {
            const filterConfig = this.filters.filters.find(filter => filter.type === filterType);

            if (filterConfig) {
              if (filterConfig.options.length > 0) {
                const selected = params.getAll(filterType);
                this.selectedValues = {
                  ...this.selectedValues,
                  [filterType]: filterConfig.options.filter(status => {
                    return selected.includes(status.id);
                  })
                };

                if (params.get(filterType + '_start')) {
                  if (this.selectedValues[filterType][0]) {
                    this.selectedValues[filterType][0].range = {
                      start: new Date(params.get(filterType + '_start') ?? ''),
                      end: new Date(params.get(filterType + '_end') ?? '')
                    };
                  }
                }
              } else {
                const selected = params.getAll(filterType);
                this.selectedValues = {
                  ...this.selectedValues,
                  [filterType]: selected.map((item: string) => ({
                    id: Number(item),
                  }))
                };
              }
            }
          } else {
            this.selectedValues[filterType] = [...defaultProductRequestFilters[filterType]];
          }
        });

        this.filtersUpdated.emit(this.selectedValues);
      })
    );
  }
}
