import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {takeWhile} from 'rxjs/operators';
import {PracticeFeature} from 'src/app/enums/practice-feature';
import {practiceHasFeature} from 'src/app/helpers/practice-has-feature';
import {Client} from 'src/app/models/Client';
import {Practice} from 'src/app/models/Practice';
import {getCurrentPractice} from 'src/app/practices/state/selectors';
import {AppState} from 'src/app/state/reducers';

@Component({
  selector: 'client-actions',
  templateUrl: './client-actions.component.html',
  styleUrls: ['./client-actions.component.scss']
})
export class ClientActionsComponent implements OnInit, OnDestroy {
  @Input() client?: Client;
  @Output() paymentHistoryOpen = new EventEmitter();
  alive = true;
  notesEnabled = false;
  paymentsEnabled = false;
  productRequestsEnabled = false;
  practice$?: Observable<Practice | null>;
  practice: Practice | null = null;

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
    this.subscribeToCurrentPractice();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  subscribeToCurrentPractice(): void {
    this.store.pipe(select(getCurrentPractice)).pipe(
      takeWhile(() => this.alive)
    ).subscribe(practice => {
      this.practice = practice;
      this.updateFeatures();
    });
  }

  updateFeatures(): void {
    this.notesEnabled = practiceHasFeature(this.practice, PracticeFeature.CLIENT_NOTES) || practiceHasFeature(this.practice, PracticeFeature.FINANCIAL_NOTES);
    this.paymentsEnabled = practiceHasFeature(this.practice, PracticeFeature.PAYMENTS);
    this.productRequestsEnabled = practiceHasFeature(this.practice, PracticeFeature.PRODUCT_REQUESTS);
  }

  paymentHistoryOpened(): void {
    this.paymentHistoryOpen.emit();
  }
}
