import * as actions from './actions';
import { Action, createReducer, on } from '@ngrx/store';
import { Client } from 'src/app/models/Client';
import {ProductRequest} from '../../models/ProductRequest';
import {ClientInsights} from '../../models/ClientInsights';
import { ClientListViewState } from '../../interfaces/client-list-view-state.interface';

export interface ClientState {
  clients: Client[];
  clientSearchLoading: boolean;
  clientSearchError: boolean;
  filterClients: Client[];
  clientProductRequests: ProductRequest[];
  insightsClient: ClientInsights | null;
  insightsClientLoading: boolean;
  clientListView: ClientListViewState;
  newClientDepositOpen: boolean;
  newClientDepositAlreadyExists: Client | null;
  insightClient : Client | null; 
  isInsightPMSClientNotExist : boolean;
}

export const initialState: ClientState = {
  clients: [],
  clientSearchLoading: false,
  clientSearchError: false,
  filterClients: [],
  clientProductRequests: [],
  insightsClient: null,
  insightsClientLoading: true,
  clientListView: {
    clients: [],
    totalItems: null,
    loading: false
  },
  newClientDepositOpen: false,
  newClientDepositAlreadyExists: null,
  insightClient: null,
  isInsightPMSClientNotExist: false,
};

const conversationReducer = createReducer(
  initialState,
  on(actions.GetClientSearchResultsSuccess, (state, payload) => ({
    ...state,
    clients: payload.clients
  })),
  on(actions.StartClientSearchLoading, (state, payload) => ({
    ...state,
    clientSearchLoading: true,
    clientSearchError: false
  })),
  on(actions.StopClientSearchLoading, (state, payload) => ({
    ...state,
    clientSearchLoading: false
  })),
  on(actions.GetClientSearchResultsFail, (state, payload) => ({
    ...state,
    clientSearchLoading: false,
    clientSearchError: true
  })),
  on(actions.GetFilterClientsSuccess, (state, payload) => ({
    ...state,
    filterClients: payload.clients,
  })),
  on(actions.GetClientProductRequestsSuccess, (state, payload) => ({
    ...state,
    clientProductRequests: payload.productRequests,
  })),
  on(actions.ClearClientProductRequests, (state, payload) => ({
    ...state,
    clientProductRequests: [],
  })),
  on(actions.GetClientInsights, (state, payload) => ({
    ...state,
    insightsClientLoading: true,
  })),
  on(actions.GetClientInsightsSuccess, (state, payload) => ({
    ...state,
    insightsClient: payload.client,
    insightsClientLoading: false,
  })),
  on(actions.GetClientInsightsFailure, (state, payload) => ({
    ...state,
    insightsClientLoading: false,
  })),
  on(actions.ClearClientInsights, (state, payload) => ({
    ...state,
    insightsClient: null,
  })),
  on(actions.GetClientPMSInsight, (state, payload) => ({
    ...state,
    insightsClientLoading: true,
    isInsightPMSClientNotExist: false,
  })),
  on(actions.GetClientPMSInsightSuccess, (state, payload) => ({
    ...state,
    insightClient: payload.client,
    isInsightPMSClientNotExist: false,
    insightsClientLoading: false,
  })),
  on(actions.GetClientPMSInsightFailure, (state, payload) => ({
    ...state,
    insightClient: payload.client,
    isInsightPMSClientNotExist: true,
    insightsClientLoading: false,
  })),
  on(actions.ClearClientPMSInsight, (state, payload) => ({
    ...state,
    insightClient: null,
    isInsightPMSClientNotExist: false,
    insightsClientLoading: false,
  })),
  on(actions.AddManualDeposit, (state, payload) => ({
    ...state,
    insightsClientLoading: true,
  })),
  on(actions.AddManualDepositSuccess, (state, payload) => ({
    ...state,
    insightsClient: payload.client,
    insightsClientLoading: false,
  })),
  on(actions.AddManualDepositFailure, (state, payload) => ({
    ...state,
    insightsClientLoading: false,
  })),
  on(actions.RefundDeposit, (state, payload) => ({
    ...state,
    insightsClientLoading: true,
  })),
  on(actions.RefundDepositSuccess, (state, payload) => ({
    ...state,
    insightsClient: payload.client,
    insightsClientLoading: false,
  })),
  on(actions.RefundDepositFailure, (state, payload) => ({
    ...state,
    insightsClientLoading: false,
  })),
  on(actions.TakeDeposit, (state, payload) => ({
    ...state,
    insightsClientLoading: true,
  })),
  on(actions.TakeDepositSuccess, (state, payload) => ({
    ...state,
    insightsClient: payload.client,
    insightsClientLoading: false,
  })),
  on(actions.TakeDepositFailure, (state, payload) => ({
    ...state,
    insightsClientLoading: false,
  })),
  on(actions.GetClientListClients, (state, payload) => ({
    ...state,
    clientListView: {
      ...state.clientListView,
      loading: true
    }
  })),
  on(actions.GetClientListClientsSuccess, (state, payload) => ({
    ...state,
    clientListView: {
      ...state.clientListView,
      loading: false,
      clients: payload.clients,
      totalItems: payload.total
    }
  })),
  on(actions.OpenNewClientDeposit, (state, payload) => ({
    ...state,
    newClientDepositOpen: true,
    newClientDepositAlreadyExists: null,
  })),
  on(actions.CloseNewClientDeposit, (state, payload) => ({
    ...state,
    newClientDepositOpen: false,
  })),
  on(actions.CreateNewClientDepositSuccess, (state, payload) => ({
    ...state,
    newClientDepositOpen: false,
  })),
  on(actions.CreateNewClientDepositFailure, (state, payload) => ({
    ...state,
    newClientDepositOpen: false,
  })),
  on(actions.CreateNewClientDepositAlreadyExists, (state, payload) => ({
    ...state,
    newClientDepositAlreadyExists: payload.client,
  })),
);

export function reducer(state: ClientState | undefined, action: Action): ClientState {
  return conversationReducer(state, action);
}
