import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dropdown-icon',
  templateUrl: './dropdown-icon.component.html',
  styleUrls: ['./dropdown-icon.component.scss']
})
export class DropdownIconComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
}
