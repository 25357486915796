import { Pipe, PipeTransform } from "@angular/core";

export enum ConversationFilterStatus {
    ACTIVE = 'Active',
    RESOLVED = 'Resolved',
    ALL = 'All',
    WITH_CLIENT = 'With Client',
    WITH_CLINIC = 'With Clinic',
}

const enumToTranslation: Record<ConversationFilterStatus, string> = {
  [ConversationFilterStatus.ACTIVE]: 'enums.conversation_status.active',
  [ConversationFilterStatus.ALL]: 'enums.conversation_status.all',
  [ConversationFilterStatus.RESOLVED]: 'enums.conversation_status.resolved',
  [ConversationFilterStatus.WITH_CLIENT]: 'enums.conversation_status.with_client',
  [ConversationFilterStatus.WITH_CLINIC]: 'enums.conversation_status.with_clinic',
}

@Pipe({name: 'conversationFilterStatusTranslationKey'})
export class ConversationFilterStatusTranslationKeyPipe implements PipeTransform {
  transform(value: ConversationFilterStatus): string {
    return enumToTranslation[value];
  }
}
