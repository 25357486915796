<div class="form-submission-page">
  <div class="constrainer">
    <ng-container *ngIf="!formSubmission">
      <div class="loading">
        <p-progressSpinner [style]="{width: '40px', height: '40px'}" styleClass="custom-spinner" strokeWidth="4"></p-progressSpinner>
      </div>
    </ng-container>
    <ng-container *ngIf="formSubmission">
      <div class="form-submission">
        <div class="header">
          <div class="form-name">
            <div class="name">{{ formSubmission.form.name }}</div>
            <div class="completed">
              <ng-container *ngIf="!formSubmission.completedAt">
                <capsule [label]="'forms.pages.submission.pending' | translate" backgroundColor="var(--orange-400)" [small]="true"></capsule>
              </ng-container>
              <ng-container *ngIf="formSubmission.completedAt">
                <capsule [label]="'forms.pages.submission.complete' | translate" backgroundColor="var(--success-color)" [small]="true"></capsule>
              </ng-container>
            </div>
            <ng-container *ngIf="formSubmission.completedAt">
              <div class="complete-text">
                {{'forms.pages.submission.completed' | translate}} {{ formSubmission.completedAt ? (formSubmission.completedAt | date: ('forms.pages.submission.date_format' | translate)) : '-' }}
              </div>
            </ng-container>
          </div>
          <ng-container *ngIf="formSubmission.clientName">
            <div class="client-name">
              {{ formSubmission.clientName }}
            </div>
          </ng-container>
        </div>

        <ng-container *ngFor="let page of formSubmission.form.formPages">
          <div class="page">
            <ng-container *ngIf="page.pageContents">
              <ng-container *ngFor="let field of page.pageContents">
                <quill-view [content]="field.content" format="html" theme="snow"></quill-view>
              </ng-container>
            </ng-container>
            <div class="page-heading">{{ page.heading }}</div>

            <div class="fields">
              <ng-container *ngFor="let field of page.pageFields">
                  <div class="field">
                    <div class="label">
                      {{ field | getSubmittedFieldLabel : formSubmission.formSubmissionData }}
                      <span *ngIf="field.deletedAt">{{'forms.pages.submission.archived' | translate}}</span>
                    </div>
                    <div class="value">
                      {{ field | getSubmittedFieldValue : formSubmission.formSubmissionData }}
                    </div>
                  </div>
              </ng-container>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="formSubmission.paymentId && formSubmission.paymentAmount">
          <div class="page">
            <div class="page-heading">{{'forms.pages.submission.payment' | translate}}</div>
            <div class="fields">
              <div class="field">
                <div class="label">
                  {{'forms.pages.submission.status' | translate}}
                </div>
                <div class="value">
                  <ng-container *ngIf="formSubmission.completedAt">
                    {{'forms.pages.submission.paid' | translate}}
                  </ng-container>
                  <ng-container *ngIf="!formSubmission.completedAt">
                    {{'forms.pages.submission.unpaid' | translate}}
                  </ng-container>
                </div>
              </div>
              <div class="field">
                <div class="label">
                  {{'forms.pages.submission.payment_amount' | translate}}
                </div>
                <div class="value">
                  {{ formSubmission.paymentAmount | penceToPounds : practiceCurrency?.currencySymbol }}
                </div>
              </div>
              <div class="field">
                <div class="label">
                  {{'forms.pages.submission.pre_authorization' | translate}}
                </div>
                <div class="value">
                  {{ formSubmission.paymentAuthOnly }}
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>
