import { TemplateSubmissionStatus } from "../enums/template-submission-status";

export function prettifyTemplateSubmissionType(type: TemplateSubmissionStatus): string {
  switch (type) {
    case TemplateSubmissionStatus.Approved:
      return 'helpers.prettify_template_submission_status.approved';
    case TemplateSubmissionStatus.Disabled:
      return 'helpers.prettify_template_submission_status.disabled';
    case TemplateSubmissionStatus.Flagged:
      return 'helpers.prettify_template_submission_status.flagged';
    case TemplateSubmissionStatus.In_Appeal:
      return 'helpers.prettify_template_submission_status.in_appeal';
    case TemplateSubmissionStatus.Pending:
      return 'helpers.prettify_template_submission_status.pending';
    case TemplateSubmissionStatus.Rejected:
      return 'helpers.prettify_template_submission_status.rejected';
    case TemplateSubmissionStatus.Submitted:
      return 'helpers.prettify_template_submission_status.submitted';
    case TemplateSubmissionStatus.Paused:
      return 'helpers.prettify_template_submission_status.paused';
    default:
      return 'helpers.prettify_template_submission_status.n_a';
  }
}
