import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {takeWhile} from 'rxjs';
import {ProductRequest} from '../../../models/ProductRequest';
import {getCurrentPractice, getCurrentPracticeConfig} from '../../../practices/state/selectors';
import {AppState} from '../../../state/reducers';
import {
  GetProductRequests,
  HandleCannotMoveProductRequest,
  MoveProductRequest,
  OpenProductRequestDetail
} from '../../state/actions';
import {
  getAwaitingApprovalProductRequests,
  getAwaitingPaymentProductRequests,
  getCancelledProductRequests,
  getCompleteProductRequests,
  getFulfilledProductRequests,
  getPaidProductRequests, getProductRequests,
  getReadyToPayProductRequests,
  getRejectedProductRequests,
  getUpdatingProductRequests
} from '../../state/selectors';
import {ProductRequestStatus} from '../../../enums/product-request-status';
import {Practice} from '../../../models/Practice';
import {
  getAvailableProductRequestStatusDestinations
} from '../../../helpers/get-available-product-request-status-destinations';
import {ActivatedRoute} from '@angular/router';
import { PracticeConfigInterface } from '../../../interfaces/practice-config.interface';

interface ProductRequestColumn {
  status: ProductRequestStatus;
  requests: ProductRequest[];
}

@Component({
  selector: 'product-request-kanban',
  templateUrl: './product-request-kanban.component.html',
  styleUrls: ['./product-request-kanban.component.scss'],
})
export class ProductRequestKanbanComponent implements OnInit, OnDestroy {
  alive = true;
  columns: ProductRequestColumn[] = [
    {
      status: ProductRequestStatus.AWAITING_APPROVAL,
      requests: [],
    },
    {
      status: ProductRequestStatus.REJECTED,
      requests: [],
    },
    {
      status: ProductRequestStatus.READY_TO_PAY,
      requests: [],
    },
    {
      status: ProductRequestStatus.AWAITING_PAYMENT,
      requests: [],
    },
    {
      status: ProductRequestStatus.PAID,
      requests: [],
    },
    {
      status: ProductRequestStatus.FULFILLED,
      requests: [],
    },
    {
      status: ProductRequestStatus.COMPLETE,
      requests: [],
    },
    {
      status: ProductRequestStatus.CANCELLED,
      requests: [],
    },
  ];
  currentlyDragging: ProductRequest | null = null;
  availableColumns: ProductRequestStatus[] = [];
  practice: Practice | null = null;
  updatingProductRequestIds: number[] = [];
  openId: number | null = null;
  allRequests: ProductRequest[] = [];
  config: PracticeConfigInterface | null = null;

  constructor(private store: Store<AppState>, private route: ActivatedRoute) {
    this.store
      .pipe(select(getCurrentPractice))
      .pipe(takeWhile(() => this.alive))
      .subscribe((practice) => {
        if (practice) {
          this.practice = practice;
          this.store.dispatch(GetProductRequests({ practiceId: practice.id }));
        }
      });

    this.store
      .pipe(select(getCurrentPracticeConfig))
      .pipe(takeWhile(() => this.alive))
      .subscribe((config: PracticeConfigInterface | null) => {
        if (config) {
          this.config = config;
        }
      });
  }

  ngOnInit(): void {
    this.subscribeToQueryParams();
    this.subscribeToCurrentPractice();
    this.subscribeToProductRequests();
    this.subscribeToProductRequestsUpdating();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  subscribeToQueryParams(): void {
    this.route.queryParams.subscribe((params) => {
      if (params.id) {
        this.openId = params.id;
        this.openFromQueryParam();
      }
    });
  }

  subscribeToCurrentPractice(): void {
    this.store
      .pipe(select(getCurrentPractice))
      .pipe(takeWhile(() => this.alive))
      .subscribe((practice) => {
        if (practice) {
          this.practice = practice;
        }
      });
  }

  subscribeToProductRequestsUpdating(): void {
    this.store
      .pipe(select(getUpdatingProductRequests))
      .pipe(takeWhile(() => this.alive))
      .subscribe((requests) => {
        this.updatingProductRequestIds = requests.map((request) => request.id);
      });
  }

  subscribeToProductRequests(): void {
    this.store
      .select(getProductRequests)
      .pipe(takeWhile(() => this.alive))
      .subscribe((requests) => {
        this.allRequests = requests;
        this.openFromQueryParam();
      });

    this.store
      .select(getAwaitingApprovalProductRequests)
      .pipe(takeWhile(() => this.alive))
      .subscribe((requests) => {
        const column = this.findColumn(ProductRequestStatus.AWAITING_APPROVAL);
        if (column) {
          column.requests = requests;
        }
      });

    this.store
      .select(getReadyToPayProductRequests)
      .pipe(takeWhile(() => this.alive))
      .subscribe((requests) => {
        const column = this.findColumn(ProductRequestStatus.READY_TO_PAY);
        if (column) {
          column.requests = requests;
        }
      });

    this.store
      .select(getAwaitingPaymentProductRequests)
      .pipe(takeWhile(() => this.alive))
      .subscribe((requests) => {
        const column = this.findColumn(ProductRequestStatus.AWAITING_PAYMENT);
        if (column) {
          column.requests = requests;
        }
      });

    this.store
      .select(getPaidProductRequests)
      .pipe(takeWhile(() => this.alive))
      .subscribe((requests) => {
        const column = this.findColumn(ProductRequestStatus.PAID);
        if (column) {
          column.requests = requests;
        }
      });

    this.store
      .select(getFulfilledProductRequests)
      .pipe(takeWhile(() => this.alive))
      .subscribe((requests) => {
        const column = this.findColumn(ProductRequestStatus.FULFILLED);
        if (column) {
          column.requests = requests;
        }
      });

    this.store
      .select(getCompleteProductRequests)
      .pipe(takeWhile(() => this.alive))
      .subscribe((requests) => {
        const column = this.findColumn(ProductRequestStatus.COMPLETE);
        if (column) {
          column.requests = requests;
        }
      });

    this.store
      .select(getCancelledProductRequests)
      .pipe(takeWhile(() => this.alive))
      .subscribe((requests) => {
        const column = this.findColumn(ProductRequestStatus.CANCELLED);
        if (column) {
          column.requests = requests;
        }
      });

    this.store
      .select(getRejectedProductRequests)
      .pipe(takeWhile(() => this.alive))
      .subscribe((requests) => {
        const column = this.findColumn(ProductRequestStatus.REJECTED);
        if (column) {
          column.requests = requests;
        }
      });
  }

  drop(status: ProductRequestStatus): void {
    if (!this.currentlyDragging) {
      return;
    }

    if (this.currentlyDragging.status === status) {
      this.resetDragging();
      return;
    }

    if (this.availableColumns.includes(status)) {
      this.store.dispatch(
        MoveProductRequest({ productRequest: this.currentlyDragging, status })
      );
    }
    
    if (!this.availableColumns.includes(status)) {
      this.store.dispatch(
        HandleCannotMoveProductRequest({
          productRequest: this.currentlyDragging,
          status,
        })
      );
    }

    this.resetDragging();
  }

  resetDragging(): void {
    this.availableColumns = [];
    this.currentlyDragging = null;
  }

  dragStart(productRequest: ProductRequest): void {
    this.currentlyDragging = productRequest;
    this.availableColumns = this.getAvailableColumns(productRequest);
  }

  dragEnd(productRequest: ProductRequest): void {
    setTimeout(() => {
      this.availableColumns = [];
      this.currentlyDragging = null;
    }, 0);
  }

  findColumn(status: ProductRequestStatus): ProductRequestColumn | null {
    return this.columns.find((col) => col.status === status) || null;
  }

  getAvailableColumns(productRequest: ProductRequest): ProductRequestStatus[] {
    return getAvailableProductRequestStatusDestinations(
      productRequest,
      this.practice,
      this.config
    );
  }

  private openFromQueryParam(): void {
    if (this.openId) {
      const matchingRequest = this.allRequests.find(
        (request) => Number(request.id) === Number(this.openId)
      );
      if (matchingRequest) {
        this.store.dispatch(
          OpenProductRequestDetail({ productRequest: matchingRequest })
        );
        this.openId = null;
      }
    }
  }
}
