<div class="resolve-holder" (clickOutside)="closeResolveActions()">
  <ng-container *ngIf="resolveActions.length == 0">
    <p-button label="Resolve" (onClick)="resolve()" iconPos="right" [disabled]="disabled" [icon]="showingResolveActions ? 'pi pi-chevron-up' : 'pi pi-chevron-down'" [styleClass]="buttonClass"></p-button>
  </ng-container>
  <ng-container *ngIf="resolveActions.length > 0">
    <p-button label="Resolve" (onClick)="toggleResolveActions()" iconPos="right" [disabled]="disabled" [icon]="showingResolveActions ? 'pi pi-chevron-up' : 'pi pi-chevron-down'" [styleClass]="buttonClass"></p-button>
    <ng-container *ngIf="showingResolveActions">
      <div class="resolve-actions">
        <ng-container *ngFor="let resolveAction of resolveActions">
          <div class="resolve-action" (click)="resolveAction.command()">{{ resolveAction.label }}</div>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</div>

<p-dialog
  [(visible)]="confirmResolveOpen"
  [modal]="true"
  [style]="{width: '450px'}"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
>
  <p-header>
    <div class="dialog-header-notification">
      Queued Messages
    </div>
  </p-header>
  <div class="dialog-content">
  <p>
    Queued messages will not be saved, please copy and paste anything you want to keep into the Summary field or Team Chat
  </p>
    <div class="btn-row">
      <div class="buttons-left">
        <p-button label="Cancel" (click)="cancelResolve()" styleClass="p-button-secondary"></p-button>
      </div>
      <div class="buttons-right">
        <p-button label="Resolve anyway" (click)="confirmResolve(sendToPms, downloadPdf)" styleClass="p-button-primary"></p-button>
      </div>
    </div>
  </div>
</p-dialog>