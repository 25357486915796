import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ProductRequest} from '../../../models/ProductRequest';
import {ProductRequestItem} from '../../../models/ProductRequestItem';
import {ProductRequestApprovalStatus} from '../../../enums/product-request-approval-status';
import {ProductRequestDetailContext} from '../../../enums/product-request-detail-context';

@Component({
  selector: 'product-request-item-row',
  templateUrl: './product-request-item-row.component.html',
  styleUrls: ['./product-request-item-row.component.scss']
})
export class ProductRequestItemRowComponent {
  @Output() approveClicked = new EventEmitter<ProductRequestItem>();
  @Output() rejectClicked = new EventEmitter<ProductRequestItem>();
  @Output() revertItemChanges = new EventEmitter<ProductRequestItem>();
  @Output() updateItem = new EventEmitter<ProductRequestItem>();
  @Input() userCanApprove?: boolean;
  @Input() productRequest?: ProductRequest;
  @Input() currentContext?: ProductRequestDetailContext | null;
  @Input() item?: ProductRequestItem;
  @Input() errors: string[] = [];
  @Input() isRejected?: boolean;
  @Input() isApproved?: boolean;

  @Input() fulfilledCount!: number;
  @Output() fulfilledCountChange = new EventEmitter<number>();

  @Input() approvedCount!: number;
  @Output() approvedCountChange = new EventEmitter<number>();

  @Input() rejectionReason!: string;
  @Output() rejectionReasonChange = new EventEmitter<string>();

  @Input() approvedItem!: string;
  @Output() approvedItemChange = new EventEmitter<string>();

  @Output() saveOrCancelItem = new EventEmitter<boolean>();

  approvalStatus = ProductRequestApprovalStatus;
  productRequestDetailContext= ProductRequestDetailContext; 
  isUpdating = false;

  handleApproveClicked(item: ProductRequestItem): void {
    this.isUpdating = true;
    this.saveOrCancelItem.emit(false);
    this.approveClicked.emit(item);
  }

  handleRejectClicked(item: ProductRequestItem): void {
    this.isUpdating = true;
    this.saveOrCancelItem.emit(false);
    this.rejectClicked.emit(item);
  }

  handleApprovedCountChange(): void {
    this.isUpdating = true;
    this.saveOrCancelItem.emit(false);
    this.approvedCountChange.emit(this.approvedCount);
  }

  handleRejectionReasonChange(): void {
    setTimeout(() =>  {
      this.isUpdating = true;
      this.saveOrCancelItem.emit(false);
      this.rejectionReasonChange.emit(this.rejectionReason);
    }, 0);
  }

  handleApprovedItemChange(): void {
    setTimeout(() =>  {
      this.isUpdating = true;
      this.saveOrCancelItem.emit(false);
      this.approvedItemChange.emit(this.approvedItem);
    }, 0);
  }

  handleFulfilledCountChange(): void {
    setTimeout(() =>  {
      this.isUpdating = true;
      this.saveOrCancelItem.emit(false);
      this.fulfilledCountChange.emit(this.fulfilledCount);
    }, 0);
  }

  handleRevertItemChanges(item: ProductRequestItem): void {
    setTimeout(() =>  {
      this.isUpdating = false;
      this.saveOrCancelItem.emit(true);
      this.revertItemChanges.emit(item);
    }, 0);
  }

  handleUpdateItem(item: ProductRequestItem): void {
    setTimeout(() =>  {
      this.isUpdating = false;
      this.saveOrCancelItem.emit(true);
      this.updateItem.emit(item);
    }, 0);
  }
}