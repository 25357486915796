import { Injectable } from '@angular/core';
import {Template} from '../interfaces/template';
import {TemplateDto} from '../interfaces/dto/template.dto';

@Injectable({
  providedIn: 'root'
})
export class TemplateAdapter {
  run(data: TemplateDto): Template {
    return {
      title: data.title ? data.title : undefined,
      text: data.text
    };
  }
}
