import { TemplateType } from "../enums/template-type";

export function prettifyTemplateType(type: TemplateType): string {
  switch (type) {
    case TemplateType.Custom:
      return "Custom";
    case TemplateType.Campaign:
      return "Broadcast";
    case TemplateType.CampaignWithPayment:
      return "Broadcast with Payment";
    case TemplateType.NewTicket:
      return "New conversation";
    case TemplateType.Reinitialise:
      return "Reinitialise";
    case TemplateType.FormRequest:
      return "Form request";
    case TemplateType.PaymentRequest:
      return "Payment request";
    case TemplateType.PaymentPaid:
      return "Payment paid";
    case TemplateType.PaymentRefunded:
      return "Payment refunded";
    case TemplateType.PreAuthorisationSuccess:
      return "Pre-auth success";
    case TemplateType.Notification:
      return "System notification";
    case TemplateType.ProductRequestUpdate:
      return "Product Request Update";
    case TemplateType.Welcome:
      return "Welcome";
    case TemplateType.Standard:
      return "Standard";
    case TemplateType.ReminderSnoozeResponse:
      return "Reminder Snooze Response";
    case TemplateType.ReminderAppointment:
      return "Appointment Reminder";
    case TemplateType.ReminderVaccination:
      return "Vaccination Reminder";
    case TemplateType.ReminderParasiticide:
      return "Parasiticide Reminder";
    case TemplateType.ReminderPayment:
      return "Payment Reminder";
    case TemplateType.ReminderPreOperation:
      return "Pre-op Reminder";
    case TemplateType.ReminderPostOperation:
      return "Post-op Reminder";
    case TemplateType.ReminderHealthCarePlan:
      return "Healthcare Plan Reminder";
    case TemplateType.ReminderHealthCarePlanFollowUp:
      return "Healthcare Plan Reminder Follow Up";
    case TemplateType.ManagePreferencesResponse:
      return "Manage Preferences";
    default:
      return "N/A";
  }
}
