import { PMS } from '../enums/pms';
import { ReconcilePaymentsAgainstInvoiceOptions } from '../enums/reconcile-payments-against-invoice-options';
import {Practice} from '../models/Practice';

export function reconcilePaymentsAgainstInvoice(practice?: Practice | null): boolean {
  if (!practice) {
    return false; 
  }

  const { reconcilePaymentsAgainstInvoice, pms } = practice;
  
  return (
    reconcilePaymentsAgainstInvoice !== ReconcilePaymentsAgainstInvoiceOptions.NEVER &&
    reconcilePaymentsAgainstInvoice !== undefined &&
    pms === PMS.PROVET
  );
}
