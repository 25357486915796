import {DepositHistory} from '../interfaces/deposit-history';
import {DepositHistoryDto} from '../interfaces/dto/deposit-history.dto';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DepositHistoryAdapter {
  run(item: DepositHistoryDto): DepositHistory {
    return {
      ...item,
      createdAt: new Date(item.createdAt),
      updatedAt: new Date(item.updatedAt)
    };
  }
}
