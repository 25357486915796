import { Injectable } from '@angular/core';
import { mapProductRequestApprovalStatus } from '../helpers/map-product-request-approval-status';
import { UserAdapter } from './user.adapter';
import {ProductRequestItemDto} from '../interfaces/dto/product-request-item.dto';
import {ProductRequestItem} from '../models/ProductRequestItem';

@Injectable({
  providedIn: 'root'
})
export class ProductRequestItemAdapter {
  constructor(private userAdapter: UserAdapter) {
  }

  run(dto: ProductRequestItemDto): ProductRequestItem {
    return {
      ...dto,
      approvalUpdatedBy: dto.approvalUpdatedBy ? this.userAdapter.run(dto.approvalUpdatedBy) : null,
      approvalUpdatedAt: dto.approvalUpdatedAt ? new Date(dto.approvalUpdatedAt) : null,
      createdBy: dto.createdBy ? this.userAdapter.run(dto.createdBy) : null,
      approvalStatus: mapProductRequestApprovalStatus(dto.approvalStatus)
    };
  }
}
