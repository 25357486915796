import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  constructor(private titleService: Title, private translateService: TranslateService) {}

  public setTranslatedTitle(key: string): void {
    this.translateService.get(key).subscribe(title => {
      this.titleService.setTitle(title);
    })
  }
}
