import { Component, EventEmitter, Output } from '@angular/core';
import { SortBy } from '../../../enums/sort-by';
import { SortDirection } from '../../../enums/sort-direction';
import {ActivatedRoute, Router} from '@angular/router';
import { Location } from '@angular/common';
import { SearchType } from '../../../enums/search-type';
import { SortByOption } from '../../../interfaces/sort-by-option.interface';
import { productRequestSortByOptions } from '../../../constants/product-request-sort-by-options.constants';

@Component({
  selector: 'product-request-sort',
  templateUrl: './product-request-sort.component.html',
  styleUrls: ['./product-request-sort.component.scss']
})
export class ProductRequestSortComponent {
  @Output() sortUpdated = new EventEmitter<SortByOption>();
  open = false;
  options: SortByOption[] = productRequestSortByOptions;
  currentSortBy: SortByOption = this.options[0];
  defaultSortBy: SortByOption = this.options[0];

  constructor(private route: ActivatedRoute, private router: Router) {}

  toggleOpen(): void {
    this.open = !this.open;
  }

  update(sortBy: SortByOption): void {
    this.currentSortBy = sortBy;
    this.open = false;
    this.sortUpdated.emit(this.currentSortBy);

    this.router.navigate([], {
      queryParams: {
        sortBy: JSON.stringify(this.currentSortBy.sortBy),
        sortDir: JSON.stringify(this.currentSortBy.sortDir),
      },
      queryParamsHandling: 'merge'
    });
  }

  subscribeToRouteParams(): void {
    this.route.queryParams
      .subscribe((params => {
        if ((params.s && params.type && params.type.toLowerCase() === SearchType.PRODUCT_REQUESTS) || !params.s) {
          if (params.sortBy && params.sortDir) {
            const paramOption = this.options.find(
              (option: SortByOption) =>
                option.sortBy === JSON.parse(params.sortBy) &&
                option.sortDir === JSON.parse(params.sortDir)
            );
            if (paramOption) {
              this.currentSortBy = paramOption;
              this.sortUpdated.emit(this.currentSortBy);
            }
          } else {
           this.currentSortBy = this.defaultSortBy;
          }
        }
      })
    );
  }

  // buildQueryParams(): string {
  //   let queryParams: string[] = [];
  //
  //   if (this.location.path().includes('?')) {
  //     queryParams = [
  //       ...this.location.path().split('?')[1].split('&')
  //     ];
  //   }
  //
  //   return [
  //     ...queryParams.filter(param => !param.includes('sortBy=') && !param.includes('sortDir=')),
  //     `sortBy=${JSON.stringify(this.currentSortBy.sortBy)}`,
  //     `sortDir=${JSON.stringify(this.currentSortBy.sortDir)}`,
  //   ].join('&');
  // }
}
