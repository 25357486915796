import { Pipe, PipeTransform } from '@angular/core';
import { addDays, format, formatDistanceToNow, isAfter } from 'date-fns';

@Pipe({name: 'paymentAuthoriseExpired'})
export class PaymentAuthoriseExpiredPipe implements PipeTransform {
  transform(value: Date | string | undefined): boolean {
    if (!value) {
        return true;
    }

    if (typeof value === 'string') {
      value = new Date(value);
    }

    if (isAfter(new Date(), addDays(value, 7))) {
      return true;
    }

    return false;
  }
}
