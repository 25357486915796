import { Pipe, PipeTransform } from '@angular/core';
import { diffForHumans } from '../helpers/diff-for-humans';


@Pipe({name: 'diffForHumans'})
export class DiffForHumansPipe implements PipeTransform {
  transform(date: Date | string | undefined | null, refresh?: Date): string {
    if (!date) {
      return 'N/A';
    }

    if (typeof date === 'string') {
      date = new Date(date);
    }

    return diffForHumans(date);
  }
}
