import { createFeatureSelector, createSelector } from '@ngrx/store';
import { STATE_KEY } from 'src/app/state/reducers';
import {FormsState} from './reducer';
import {ProductRequestState} from "../../product-requests/state/reducer";
import {getProductRequestState} from "../../product-requests/state/selectors";

export const getFormsState = createFeatureSelector<FormsState>(STATE_KEY.FORMS);

export const isNewFormRequestOpen = createSelector(
  getFormsState,
  (state: FormsState) => state.newFormRequestOpen,
);

export const getFormRequestClient = createSelector(
  getFormsState,
  (state: FormsState) => state.formRequestClient
);

export const getFormRequestPatients = createSelector(
  getFormsState,
  (state: FormsState) => state.formRequestPatients
);

export const IsFormRequestPatientsLoading = createSelector(
  getFormsState,
  (state: FormsState) => state.patientsLoading
);

export const getFormRequestContact = createSelector(
  getFormsState,
  (state: FormsState) => state.formRequestContact
);

export const getFormRequestChannel = createSelector(
  getFormsState,
  (state: FormsState) => state.formRequestChannel
);

export const getForms = createSelector(
  getFormsState,
  (state: FormsState) => {
    return [
      ...state.forms.filter(f => !f.archivedAt).sort((a, b) => a.name.localeCompare(b.name)),
      ...state.forms.filter(f => !!f.archivedAt).sort((a, b) => a.name.localeCompare(b.name)),
    ];
  }
);

export const getActiveForms = createSelector(
  getFormsState,
  (state: FormsState) => state.forms.filter(form => !form.archivedAt)
);

export const getCurrentForm = createSelector(
  getFormsState,
  (state: FormsState) => state.currentForm
);

export const getCurrentFormToSend = createSelector(
  getFormsState,
  (state: FormsState) => state.formToSend
);

export const getCreateFormSubmissionInProgress = createSelector(
  getFormsState,
  (state: FormsState) => state.createFormSubmissionInProgress
);


export const getFormSubmissions = createSelector(
  getFormsState,
  (state: FormsState) => state.formSubmissions
);

export const getFormSubmissionsTotalCount = createSelector(
  getFormsState,
  (state: FormsState) => state.formSubmissionsTotalCount
);

export const getFormSubmissionsLoading = createSelector(
  getFormsState,
  (state: FormsState) => state.formSubmissionsLoading
);

export const getCurrentFormSubmission = createSelector(
  getFormsState,
  (state: FormsState) => state.currentFormSubmission
);
