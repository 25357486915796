import {Practice} from '../models/Practice';
import {Role} from '../enums/role';

export function canDeleteMedia(practice: Practice): boolean {
  if (
    practice.userRole === Role.SYSTEM_ADMIN ||
    practice.userRole === Role.COMPANY_ADMIN ||
    practice.userRole === Role.SUPER_ADMIN
  ) {
    return true;
  }

  return false;
}
