import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, mergeMap, tap } from 'rxjs/operators';
import { AppState } from 'src/app/state/reducers';
import { UserService } from '../user.service';
import * as UserActions from './actions';
import { User } from '../../models/User';
import { Router } from '@angular/router';
import { Noop, SetTourMode } from '../../state/actions';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private userService: UserService,
    private router: Router,
  ) {
  }

  completeTour$ = createEffect(() => this.actions$.pipe(
    ofType(UserActions.CompleteTour),
    mergeMap((action) => this.userService.completeTour(action.user)
      .pipe(
        tap(() => {
          this.store.dispatch(SetTourMode({tourMode: false}));
          this.store.dispatch(UserActions.CompleteTourSuccess());
        }),
        map((result: User) => {
          this.router.navigateByUrl('day-list');
          return Noop();
        }
      )
    ))
  ));
}
