import { ProductRequestStatus } from '../enums/product-request-status';

export function mapProductRequestStatus(status: string): ProductRequestStatus {
    switch (status) {
        case 'AWAITING_APPROVAL':
            return ProductRequestStatus.AWAITING_APPROVAL;
        case 'REJECTED':
            return ProductRequestStatus.REJECTED;
        case 'READY_TO_PAY':
            return ProductRequestStatus.READY_TO_PAY;
        case 'AWAITING_PAYMENT':
            return ProductRequestStatus.AWAITING_PAYMENT;
        case 'PAID':
            return ProductRequestStatus.PAID;
        case 'COMPLETE':
            return ProductRequestStatus.COMPLETE;
        case 'FULFILLED':
            return ProductRequestStatus.FULFILLED;
        case 'CANCELLED':
            return ProductRequestStatus.CANCELLED;
    }

    return ProductRequestStatus.AWAITING_APPROVAL;
}
