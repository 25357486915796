<ng-container *ngIf="media">
  <ng-container *ngIf="manage">
    <p-contextMenu [target]="mediaCard" [model]="menuItems" appendTo="body"></p-contextMenu>
    <p-contextMenu [target]="dots" [model]="menuItems" appendTo="body" triggerEvent="click"></p-contextMenu>
  </ng-container>
  <div #mediaCard class="media-grid-item" (click)="select()" [class.selectable]="selector">
    <div class="media-header">
      <ng-container *ngIf="media.mime.includes('image/')">
        <div class="media-icon"><i class="pi pi-image"></i></div>
      </ng-container>
      <ng-container *ngIf="media.mime.includes('video/')">
        <div class="media-icon"><i class="pi pi-video"></i></div>
      </ng-container>
      <ng-container *ngIf="media.mime.includes('application/')">
        <div class="media-icon"><i class="pi pi-file-pdf"></i></div>
      </ng-container>

      <div class="media-name">
        {{media.name}}
      </div>

      <div class="dots" #dots>
        <ng-container *ngIf="manage">
          <i class="pi pi-ellipsis-v"></i>
        </ng-container>
      </div>
    </div>
    <div class="media-preview">
      <ng-container *ngIf="media.mime.includes('image/')">
        <ng-container *ngIf="!selector">
          <a [href]="media.signedUrl" target="_blank">
            <img [src]="media.signedUrl" alt="">
          </a>
        </ng-container>
        <ng-container *ngIf="selector">
          <img [src]="media.signedUrl" alt="">
        </ng-container>
      </ng-container>
      <ng-container *ngIf="media.mime.includes('video/')">
        <video [controls]="!selector" preload="metadata">
          <source [src]="media.signedUrl" [type]="media.mime">
        </video>
      </ng-container>
      <ng-container *ngIf="media.mime.includes('application/')">
        <ng-container *ngIf="!selector">
          <a [href]="media.signedUrl" target="_blank">
            <i class="pi pi-file-pdf"></i>
          </a>
        </ng-container>
        <ng-container *ngIf="selector">
          <i class="pi pi-file-pdf"></i>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-container>
