import {Component, Input} from '@angular/core';
import {ProductRequest} from '../../../models/ProductRequest';
import {Store} from '@ngrx/store';
import {AppState} from '../../../state/reducers';
import {DispenseProductRequest} from '../../state/actions';

@Component({
  selector: 'dispense-product-request',
  templateUrl: './dispense-product-request.component.html',
  styleUrls: ['./dispense-product-request.component.scss']
})
export class DispenseProductRequestComponent {
  @Input() productRequest?: ProductRequest;
  notifyClient = true;

  constructor(private store: Store<AppState>) {
  }

  submit(): void {
    if (this.productRequest) {
      this.store.dispatch(DispenseProductRequest({productRequest: this.productRequest, notifyClient: this.notifyClient}));
    }
  }
}
