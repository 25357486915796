<button pButton label="Patient notes" class="p-button-fourth p-button-sm" [disabled]="!patient || !patient.patientNotes || disabled" (click)="showModal()"></button>
<ng-container *ngIf="patient && patient.patientNotes">
    <p-dialog
        header="Patient Notes"
        [(visible)]="modalActive"
        [modal]="true"
        [style]="{width: '500px', 'min-height': '50vh'}"
        [baseZIndex]="10000"
        [draggable]="false"
        [resizable]="false"
    >
        <div [innerHtml]="patient.patientNotes | nl2br"></div>
    </p-dialog>
</ng-container>
