import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Patient } from 'src/app/models/Patient';

@Component({
  selector: 'patient-list-item',
  templateUrl: './patient-list-item.component.html',
  styleUrls: ['./patient-list-item.component.scss']
})
export class PatientListItemComponent implements OnInit {
  @Input() disabled?: boolean;
  @Input() patient?: Patient;
  @Output() patientSelected = new EventEmitter<Patient>();

  constructor() { }

  ngOnInit(): void {
  }

  selectPatient(): void {
    if (this.patient) {
      this.patientSelected.emit(this.patient);
    }
  }
}
