import { Pipe, PipeTransform } from '@angular/core';
import { canPaymentBeCancelled } from '../helpers/can-payment-be-cancelled';
import { Payment } from '../models/Payment';

@Pipe({name: 'canPaymentBeCancelled'})
export class CanPaymentBeCancelledPipe implements PipeTransform {
  transform(payment: Payment): boolean {
    return canPaymentBeCancelled(payment);
  }
}
