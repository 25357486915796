import { FilterType } from '../enums/filter-type';
import { FilterSelection } from '../interfaces/filter-selection.interface';

export const defaultProductRequestFilters: FilterSelection = {
  [FilterType.CREATED_AT]: [],
  [FilterType.STATUS]: [],
  [FilterType.CLIENT]: [],
  [FilterType.OWNER]: [],
  [FilterType.APPROVAL_STATUS]: [],
  [FilterType.ACTIVE_DEPOSIT]: [],
};
