import {createAction, props} from '@ngrx/store';
import {HelpPost} from "../../interfaces/help-post.interface";

export const StoreAccessToken = createAction(
  '[Auth] Store Access Token',
  props<{accessToken: string}>()
);

export const StoreJwt = createAction(
  '[Auth] Store Jwt',
  props<{jwt: string}>()
);

export const StoreUser = createAction(
  '[Auth] Store User',
  props<{user: any}>()
);

export const RemoveUser = createAction(
  '[Auth] Remove User'
);

export const Logout = createAction(
  '[Auth] Logout'
);

export const MarkHelpPostsAsSeen = createAction(
  '[Auth] Mark Help Posts As Seen',
);

export const GetUnreadHelpPosts = createAction(
  '[Auth] Get Unread Help Count',
);

export const StoreUnreadPosts = createAction(
  '[Auth] Store Unread Help Posts',
  props<{posts: HelpPost[]}>()
);
