import { createAction, props } from '@ngrx/store';
import { ProductRequestLayout } from '../../enums/product-request-layout';
import { ProductRequest } from '../../models/ProductRequest';
import {Client} from '../../models/Client';
import {Contact} from '../../models/Contact';
import {Patient} from '../../models/Patient';
import {ProductRequestItem} from '../../interfaces/product-request-item';
import {ProductRequestItem as ProductRequestItemModel} from '../../models/ProductRequestItem';
import {User} from '../../models/User';
import {Comment} from '../../models/Comment';
import {ProductRequestStatus} from '../../enums/product-request-status';
import { FilterSelection } from '../../interfaces/filter-selection.interface';
import { SortByOption } from '../../interfaces/sort-by-option.interface';
import {PaymentRequest} from '../../interfaces/payment-request';
import {Channel} from '../../enums/channel';
import {ProductRequestApprovalStatus} from '../../enums/product-request-approval-status';
import {ProductRequestDetailContext} from "../../enums/product-request-detail-context";
import {FullPayment} from "../../models/FullPayment";
import {HistoryItem} from "../../models/HistoryItem";
import {Group} from "../../models/Group";
import {AuditLog} from "../../models/AuditLog";
import {ProductRequestPaidContext} from "../../enums/product-request-paid-context.enum";

export const OpenProductRequest = createAction(
  '[Products Requests] Open Product Request',
  props<{client?: Client, contact?: Contact, patient?: Patient, channel?: Channel}>()
);

export const CloseProductRequest = createAction(
  '[Products Requests] Close Product Request'
);

export const SetProductRequestClient = createAction(
  '[Products Requests] Set Product Request Client',
  props<{client?: Client}>()
);

export const SetProductRequestClientSuccess = createAction(
  '[Products Requests] Set Product Request Client Success',
  props<{client: Client}>()
);

export const GetPatientsSuccess = createAction(
  '[Products Requests] Product Request get Patients Success',
  props<{patients: Patient[], replace: boolean}>()
);

export const GetMorePatients = createAction(
  '[Products Requests] Get More Product Request Patients',
  props<{page: number}>()
);

export const SetProductRequestContact = createAction(
  '[Products Requests] Set Product Request Contact',
  props<{contact?: Contact}>()
);

export const SetProductRequestPatient = createAction(
  '[Products Requests] Set Product Request Patient',
  props<{patient?: Patient}>()
);

export const SetProductRequestChannel = createAction(
  '[Products Requests] Set Product Request Channel',
  props<{channel?: Channel}>()
);

export const CreateProductRequest = createAction(
  '[Products Requests] Create Product Request',
  props<{
    client: Client,
    contact: Contact,
    patient: Patient,
    assignee: User | Group | null,
    items: ProductRequestItem[],
    channel: Channel,
  }>()
);

export const CreateProductRequestSuccess = createAction(
  '[Products Requests] Create Product Request Success',
  props<{ productRequest: ProductRequest }>()
);

export const CreateProductRequestFailure = createAction(
  '[Products Requests] Create Product Request Failure',
);

export const GetProductRequests = createAction(
    '[Product Requests] Get Product Requests',
    props<{practiceId: string}>()
);

export const GetProductRequestsSuccess = createAction(
    '[Product Requests] Get Product Requests - Success',
    props<{productRequests: ProductRequest[]}>()
);

export const GetProductRequestsForList = createAction(
  '[Product Requests] Get Product Requests For List',
  props<{practiceId: string, searchString: string | null, filters: FilterSelection, sortBy: SortByOption, page: number}>()
);

export const GetProductRequestsForListSuccess = createAction(
  '[Product Requests] Get Product Requests For List - Success',
  props<{productRequests: ProductRequest[], total: number}>()
);

export const SetProductRequestLayout = createAction(
  '[Product Requests] Set Product Request Layout',
  props<{layout: ProductRequestLayout}>()
);

export const ClearProductRequestFilters = createAction(
  '[Product Requests] Clear Product Request Filters'
);

export const GoToProductRequestDetail = createAction(
  '[Products Requests] Go To Product Request Detail',
  props<{ productRequest: ProductRequest }>()
);

export const OpenProductRequestDetail = createAction(
  '[Products Requests] Open Product Request Detail',
  props<{ productRequest: ProductRequest, context?: ProductRequestDetailContext }>()
);

export const CloseProductRequestDetail = createAction(
  '[Products Requests] Close Product Request Detail'
);

export const MoveProductRequest = createAction(
  '[Products Requests] Move Product Request',
  props<{ productRequest: ProductRequest, status: ProductRequestStatus }>()
);

export const HandleMoveProductRequest = createAction(
  '[Products Requests] Handle Move Product Request',
  props<{ productRequest: ProductRequest, status: ProductRequestStatus }>()
);

export const HandleCannotMoveProductRequest = createAction(
  '[Products Requests] Handle Cannot Move Product Request',
  props<{ productRequest: ProductRequest, status: ProductRequestStatus }>()
);

export const MoveProductRequestSuccess = createAction(
  '[Products Requests] Move Product Request Success',
  props<{ productRequest: ProductRequest }>()
);

export const MoveProductRequestFailure = createAction(
  '[Products Requests] Move Product Request Failure',
  props<{ productRequest: ProductRequest }>()
);

export const StartUpdatingProductRequest = createAction(
  '[Products Requests] Start Updating Product Request',
  props<{ productRequest: ProductRequest }>()
);

export const StopUpdatingProductRequest = createAction(
  '[Products Requests] Stop Updating Product Request',
  props<{ productRequest: ProductRequest }>()
);

export const OpenProductRequestApproval = createAction(
  '[Products Requests] Open Product Request Approval',
  props<{ productRequest: ProductRequest }>()
);

export const CloseProductRequestApproval = createAction(
  '[Products Requests] Close Product Request Approval'
);

export const UpdateProductRequestApproval = createAction(
  '[Products Requests] Update Product Request Approval',
  props<{
    productRequest: ProductRequest,
    approvalCounts: {itemId: number, approvedQty: number, approvedItem: string | null}[],
    rejectionReasons: {itemId: number, reason: string | null}[],
  }>()
);

export const UpdateProductRequestApprovalSuccess = createAction(
  '[Products Requests] Update Product Request Approval Success',
  props<{ productRequest: ProductRequest }>()
);

export const UpdateProductRequestItemApproval = createAction(
  '[Products Requests] Update Product Request Item Approval',
  props<{
    productRequestItem: ProductRequestItemModel,
    approvalStatus: ProductRequestApprovalStatus,
    approvedCount: number,
    rejectionReason: string | null,
    approvedItem: string | null,
  }>()
);

export const UpdateProductRequestItemApprovalSuccess = createAction(
  '[Products Requests] Update Product Request Item Approval Success',
  props<{ productRequestItem: ProductRequestItemModel }>()
);

export const UpdateProductRequestItemFulfilled = createAction(
  '[Products Requests] Update Product Request Item Fulfilled',
  props<{
    productRequestItem: ProductRequestItemModel,
    fulfilledCount: number,
  }>()
);

export const UpdateProductRequestItemFulfilledSuccess = createAction(
  '[Products Requests] Update Product Request Item Fulfilled Success',
  props<{ productRequestItem: ProductRequestItemModel }>()
);

export const ApproveProductRequest = createAction(
  '[Products Requests] Approve Product Request',
  props<{ productRequest: ProductRequest, notifyClient: boolean, rejectionReason: string, }>()
);

export const ApproveProductRequestSuccess = createAction(
  '[Products Requests] Approve Product Request Success',
  props<{ productRequest: ProductRequest }>()
);

export const OpenProductRequestRejection = createAction(
  '[Products Requests] Open Product Request Rejection',
  props<{ productRequest: ProductRequest }>()
);

export const CloseProductRequestRejection = createAction(
  '[Products Requests] Close Product Request Rejection'
);

export const RejectProductRequest = createAction(
  '[Products Requests] Reject Product Request',
  props<{ productRequest: ProductRequest, rejectionReason: string, notifyClient: boolean }>()
);

export const RejectProductRequestSuccess = createAction(
  '[Products Requests] Reject Product Request Success',
  props<{ productRequest: ProductRequest }>()
);

export const OpenProductRequestPaymentCreate = createAction(
  '[Products Requests] Open Product Request Payment Create',
  props<{ productRequest: ProductRequest }>()
);

export const CloseProductRequestPaymentCreate = createAction(
  '[Products Requests] Close Product Request Payment Create'
);

export const CreateProductRequestPayment = createAction(
  '[Products Requests] Create Product Request Payment',
  props<{ productRequest: ProductRequest, paymentRequest: PaymentRequest }>()
);

export const CreateProductRequestPaymentSuccess = createAction(
  '[Products Requests] Create Product Request Payment Success',
  props<{ productRequest: ProductRequest }>()
);

export const OpenProductRequestPaidConfirmation = createAction(
  '[Products Requests] Open Product Request Paid Confirmation',
  props<{ productRequest: ProductRequest, context: ProductRequestPaidContext }>()
);

export const CloseProductRequestPaidConfirmation = createAction(
  '[Products Requests] Close Product Request Paid Confirmation'
);

export const ConfirmProductRequestPaid = createAction(
  '[Products Requests] Confirm Product Request Paid',
  props<{ productRequest: ProductRequest }>()
);

export const ConfirmProductRequestPaidSuccess = createAction(
  '[Products Requests] Confirm Product Request Paid Success',
  props<{ productRequest: ProductRequest }>()
);

export const OpenDispenseProductRequest = createAction(
  '[Products Requests] Open Dispense Product Request',
  props<{ productRequest: ProductRequest }>()
);

export const CloseDispenseProductRequest = createAction(
  '[Products Requests] Close Dispense Product Request'
);

export const DispenseProductRequest = createAction(
  '[Products Requests] Dispense Product Request',
  props<{ productRequest: ProductRequest, notifyClient: boolean }>()
);

export const DispenseProductRequestSuccess = createAction(
  '[Products Requests] Dispense Product Request Success',
  props<{ productRequest: ProductRequest }>()
);

export const HandleProductRequestUpdate = createAction(
  '[Products Requests] Handle Product Request Update',
  props<{ productRequest: ProductRequest }>()
);

export const GetProductRequestComments = createAction(
  '[Products Requests] Get Product Request Comments',
  props<{productRequestId: number}>()
);

export const GetProductRequestCommentsSuccess = createAction(
  '[Products Requests] Get Product Request Comments Success',
  props<{productRequestId: number, comments: Comment[]}>()
);

export const AddProductRequestComment = createAction(
  '[Products Requests] Add Product Request Comment',
  props<{productRequestId: number, message: string}>()
);

export const AddProductRequestCommentSuccess = createAction(
  '[Products Requests] Add Product Request Comment Success',
  props<{comment: Comment}>()
);

export const GetProductRequestHistory = createAction(
  '[Products Requests] Get Product Request History',
  props<{productRequestId: number}>()
);

export const GetProductRequestHistorySuccess = createAction(
  '[Products Requests] Get Product Request History Success',
  props<{productRequestId: number, history: AuditLog[]}>()
);

export const GetProductRequestClient = createAction(
  '[Product Request] Get Client',
  props<{clientId: string}>()
);

export const GetProductRequestClientSuccess = createAction(
  '[Product Request] Get Client - Success',
  props<{client: Client}>()
);

export const GetProductRequestClientPaymentHistory = createAction(
  '[Product Request] Get Client Payment History',
  props<{clientId: string}>()
);

export const GetProductRequestClientPaymentHistorySuccess = createAction(
  '[Product Request] Get Client Payment History Success',
  props<{payments: FullPayment[]}>()
);

export const GetProductRequestClientFailed = createAction(
  '[Product Request] Get Client - Fail'
);

export const GetProductRequestPatient = createAction(
  '[Product Request] Get Patient',
  props<{patientId: string}>()
);

export const GetProductRequestPatientSuccess = createAction(
  '[Product Request] Get Patient - Success',
  props<{patient: Patient}>()
);

export const GetProductRequestPatientFailed = createAction(
  '[Product Request] Get Patient - Fail'
);

export const GetProductRequestPatientHistory = createAction(
  '[Product Request] Get Patient History',
  props<{patientId: string}>()
);

export const GetProductRequestPatientHistorySuccess = createAction(
  '[Product Request] Get Patient History - Success',
  props<{historyItems: HistoryItem[]}>()
);

export const UpdateProductRequestAssignee = createAction(
  '[Products Requests] Update Product Request Assignee',
  props<{
    productRequest: ProductRequest,
    assignee: User | Group | null,
  }>()
);

export const UpdateProductRequestAssigneeSuccess = createAction(
  '[Products Requests] Update Product Request Assignee Success',
  props<{ productRequest: ProductRequest }>()
);

export const UpdateProductRequestOwner = createAction(
  '[Products Requests] Update Product Request Owner',
  props<{
    productRequest: ProductRequest,
    owner: User,
  }>()
);

export const UpdateProductRequestOwnerSuccess = createAction(
  '[Products Requests] Update Product Request Owner Success',
  props<{ productRequest: ProductRequest }>()
);
