import { Pipe, PipeTransform } from '@angular/core';
import { formatDistanceToNow } from 'date-fns';
import { MessageType } from '../enums/message-type';
import { Message } from '../models/Message';


@Pipe({name: 'getLastConsentDatetime'})
export class GetLastConsentDateTimePipe implements PipeTransform {
    transform(messages: Message[] | null): string {
        if (!messages || messages.length === 0) {
            return 'never';
        }

        let consentMessages = messages.filter(message => {
            return message.type === MessageType.INITIAL_CONSENT_REQUEST || message.type === MessageType.REOPEN_CONSENT_REQUEST;
        });

        if (consentMessages.length === 0) {
            return 'never';
        }

        consentMessages = consentMessages.sort((a, b) => {
            return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        });

        return formatDistanceToNow(new Date(consentMessages[0].createdAt)) + ' ago';
    }
}
