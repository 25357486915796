import {FormFieldType} from "../enums/form-field-type.enum";

export function prettifyFormInputType(value: FormFieldType): string {
  switch (value) {
    case FormFieldType.CHECKBOX:
      return 'Checkbox';
    case FormFieldType.AGREE_TO_TERMS:
      return 'Agree to Terms';
    case FormFieldType.MULTI_CHECKBOX:
      return 'Multiple Checkboxes';
    case FormFieldType.TEXT:
      return 'Text';
    case FormFieldType.EMAIL:
      return 'Email';
    case FormFieldType.TEXTAREA:
      return 'Text area';
    case FormFieldType.SELECT:
      return 'Select';
    case FormFieldType.RADIO:
      return 'Radio';
    case FormFieldType.DATE:
      return 'Date';
    case FormFieldType.HIDDEN:
      return 'Hidden';
  }
}
