<ng-container *ngIf="formSubmission">
  <div class="form-list-item">
    <div class="form-name">
      <span class="label">{{'forms.components.submission_list_item.form' | translate}}</span>{{ formSubmission.form.name }}
    </div>
    <ng-container *ngIf="!client?.pmsId">
      <div class="client-name">
        <span class="label">{{'forms.components.submission_list_item.client' | translate}}</span
        >{{ formSubmission.clientName || ('forms.components.submission_list_item.unmatched' | translate) }}
      </div>
      <div class="created">
        <span class="label">{{'forms.components.submission_list_item.sent' | translate}}</span
        >{{ formSubmission.createdAt | date: ('forms.components.submission_list_item.date_format' | translate) }}
      </div>
    </ng-container>
    <div class="completed">
      <div class="capsule">
        <ng-container *ngIf="!formSubmission.completedAt">
          <capsule
            [label]="'forms.components.submission_list_item.pending' | translate"
            backgroundColor="var(--orange-400)"
            [small]="true"
          ></capsule>
        </ng-container>
        <ng-container *ngIf="formSubmission.completedAt">
          <capsule
            [label]="'forms.components.submission_list_item.complete' | translate"
            backgroundColor="var(--success-color)"
            [small]="true"
          ></capsule>
        </ng-container>
      </div>
    </div>
    <ng-container *ngIf="!client?.pmsId">
    <div class="actions" >
      <div
        class="actions-opener"
        (clickOutside)="actionsOpen = false"
        (click)="toggleActions()"
      >
        <img src="assets/images/icons/dots.svg" alt="" />
      </div>
      <ng-container *ngIf="actionsOpen">
        <div class="actions-dropdown">
          <div class="action" (click)="viewSub()">{{'forms.components.submission_list_item.view' | translate}}</div>
        </div>
      </ng-container>
    </div>
  </ng-container>
  </div>
</ng-container>
