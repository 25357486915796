<div class="controls">
    <notification-list-controls [showAll]="showAll" (emitShowAll)="handleShowAllChange()"></notification-list-controls>
</div>
<div class="notifications-list">
    <ng-container *ngFor="let notification of todaysNotifications">
        <div class="notification">
            <notifications-list-item [notification]="notification" (clickedLink)="handleLinkClicked()"></notifications-list-item>
        </div>
    </ng-container>
    <ng-container *ngIf="yesterdaysNotifications.length > 0 && (olderNotifications.length > 0 || todaysNotifications.length > 0)">
        <div class="subheading">
            {{'ui.notifications_list.yesterday'| translate}}
        </div>
    </ng-container>
    <ng-container *ngFor="let notification of yesterdaysNotifications">
        <div class="notification">
            <notifications-list-item [notification]="notification" (clickedLink)="handleLinkClicked()"></notifications-list-item>
        </div>
    </ng-container>
    <ng-container *ngIf="olderNotifications.length > 0 && (yesterdaysNotifications.length > 0 || todaysNotifications.length > 0)">
        <div class="subheading">{{'ui.notifications_list.older'| translate}}</div>
    </ng-container>
    <ng-container *ngFor="let notification of olderNotifications">
        <div class="notification">
            <notifications-list-item [notification]="notification" (clickedLink)="handleLinkClicked()"></notifications-list-item>
        </div>
    </ng-container>
    <ng-container *ngIf="totalNotificationCount > notifications.length">
      <div class="load-more" (click)="loadMore()">{{'ui.notifications_list.load_older'| translate}}</div>
    </ng-container>
    <ng-container *ngIf="totalNotificationCount > 0 && totalNotificationCount === notifications.length">
        <div class="last-thirty-days-message">{{'ui.notifications_list.all_notifications'| translate}}</div>
    </ng-container>
    <ng-container *ngIf="totalNotificationCount === 0">
        <div class="no-notifications">{{'ui.notifications_list.no_notifications'| translate}}</div>
    </ng-container>
</div>
