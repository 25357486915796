import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { User } from 'src/app/models/User';

@Component({
  selector: 'watcher-context-menu',
  templateUrl: './watcher-context-menu.component.html',
  styleUrls: ['./watcher-context-menu.component.scss']
})
export class WatcherContextMenuComponent implements OnInit, OnChanges {
  @Input() watchers: User[] = [];
  @Input() authUser: User | null = null;
  @Input() allUsers: User[] = [];
  @Output() watch = new EventEmitter<User>();
  @Output() unwatch = new EventEmitter<User>();
  showAddWatchersForm = false;
  isWatching = false;
  user = null;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.checkIfWatching();
  }

  checkIfWatching(): void {
    if (this.watchers.find(watcher => this.authUser !== null && watcher.id === this.authUser.id)) {
      this.isWatching = true;
      return;
    }

    this.isWatching = false;
  }

  stopWatching(event: MouseEvent): void {
    if (this.authUser) {
      event.stopPropagation();
      this.isWatching = false;
      this.unwatch.emit(this.authUser);
    }
  }

  startWatching(event: MouseEvent): void {
    if (this.authUser) {
      event.stopPropagation();
      this.isWatching = true;
      this.watch.emit(this.authUser);
    } 
  }

  removeWatcher(event: MouseEvent, user: User): void {
    event.stopPropagation();
    if (this.authUser && user.id == this.authUser.id) {
      this.isWatching = false;
    }
    this.unwatch.emit(user);
  }

  handleUserClick(event: {originalEvent: PointerEvent, option: User}) {
    event.originalEvent.stopPropagation();
    if (this.authUser && event.option.id == this.authUser.id) {
      this.isWatching = true;
    }
    this.watch.emit(event.option);
  }

  toggleAddWatcherForm(): void {
    this.showAddWatchersForm = !this.showAddWatchersForm;
  }
}
