<form class="move-folder" [formGroup]="form">
  <p>This will move <strong>{{ this.config.data.folder.name }}</strong> including all the media and folders underneath it into the following folder:</p>
  <div class="input-row">
    <select class="primary" formControlName="destinationFolder" name="" id="">
      <option value="">Please select...</option>
      <option value="0">Root directory</option>
      <ng-container *ngFor="let folder of (folders$ | ngrxPush)">
        <option value="{{ folder.id }}">{{ folder.name }}</option>
      </ng-container>
    </select>
    <ng-container *ngIf="form.controls.destinationFolder.invalid && (form.controls.destinationFolder.dirty || form.controls.destinationFolder.touched) && form.controls.destinationFolder.errors?.['required']">
      <div class="error">You must select a destination folder</div>
    </ng-container>
  </div>
  <div class="btn-row">
    <p-button (click)="submit()" label="Move" styleClass="p-button-primary"></p-button>
  </div>
</form>
